var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"qr-code",style:(("background-color: " + _vm.theme_color))},[(_vm.showEmpty)?_c('van-empty',{attrs:{"description":"券码不存在，请联系客服"}}):_vm._e(),(!_vm.loading && !_vm.showEmpty)?[_c('div',{staticClass:"showcode"},[_c('div',{staticClass:"showcode-main"},[_c('van-image',{staticClass:"showcode-logo",attrs:{"lazy-load":"","fit":"cover","src":_vm.logo_url}}),_c('div',{staticClass:"showcode-img"},[(
              _vm.coupon_format === 'qr_code' || _vm.coupon_format === 'qr_bar_code'
            )?_c('qrcode-vue',{attrs:{"value":_vm.coupon_code + '',"size":180,"level":"H"}}):_vm._e(),(
              _vm.coupon_format === 'bar_code' || _vm.coupon_format === 'qr_bar_code'
            )?_c('barcode',{attrs:{"value":_vm.coupon_code + '',"width":1}},[_vm._v(" 条形码生成失败，请联系客服 ")]):_vm._e()],1),(_vm.item_code === 'ADDOIL')?_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
            event_id: 'code_refresh_button',
            namespace: _vm.namespace,
          }),expression:"{\n            event_id: 'code_refresh_button',\n            namespace,\n          }"}],staticClass:"refresh",on:{"click":_vm.refresh}},[_c('img',{attrs:{"src":"https://cdn.xiangshuheika.com/static/oil/refresh.png","alt":""}}),_vm._v(" 刷新 ")]):_vm._e(),(_vm.item_code === 'ADDOIL' && _vm.expire_time)?_c('div',{staticClass:"oil-expire-time"},[_vm._v(" 核销码过期时间为"+_vm._s(_vm.expire_time)+"，过期后请重新刷新 ")]):_c('div',[_c('div',{staticClass:"showcode-name"},[_vm._v(_vm._s(_vm.info.item_name))]),_c('div',{staticClass:"showcode-code"},[_vm._v(_vm._s(_vm.coupon_code))])]),_c('div',{staticClass:"showcode-tips",style:(("color: " + _vm.theme_color))},[(_vm.item_code === 'ADDOIL')?_c('span',[_vm._v(" "+_vm._s(_vm.$getConfigValue("SHENGXIN_OIL_TIPS"))+" ")]):_c('span',[_vm._v(_vm._s(_vm.info.tips))])]),_c('div',{staticClass:"showcode-line"},[_c('span',{staticClass:"semicircle",style:(("background: " + _vm.theme_color))}),_c('span',{staticClass:"semicircle2",style:(("background: " + _vm.theme_color))})]),_c('div',{staticClass:"showcode-explain"},[_c('div',{staticClass:"showcode-explain-title"},[_vm._v("- 使用条款 -")]),_c('div',{directives:[{name:"replace",rawName:"v-replace.day",value:(_vm.order.coupon_days),expression:"order.coupon_days",modifiers:{"day":true}}],staticClass:"showcode-explain-content",domProps:{"innerHTML":_vm._s(_vm.info.instruction)}})])],1)])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }