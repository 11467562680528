
<template>
  <div class="promotion-container">
    <div
      v-if="isLogin"
      class="promotion-logout"
      @click="logout"
      v-ans="{ event_id: 'promotion_logout' }"
    >
      退出登录
    </div>
    <div class="promotion-card">
      <div class="title">
        <template v-if="isLogin">{{ userInfo.unique_id | maskStr }}</template>
        <template v-else>登录后领取</template>
        <span class="tag">VIP尊享</span>
      </div>
      <div
        v-ans="{ event_id: 'promotion_login_btn' }"
        v-if="!isLogin"
        class="login-btn"
        @click="login"
      >
        登录
      </div>
      <van-image
        v-else
        class="promotion-member-icon"
        :src="require('@/assets/images/promotion_member_icon.png')"
      ></van-image>
    </div>
    <div class="promotion-body">
      <van-empty
        v-if="!isLogin"
        :image="require('@/assets/images/promotion_empty.png')"
        :image-size="118"
        description="请登录后领取"
      />
      <router-view v-else :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
import LoginDialog from "@/components/loginKeepDialog";
import { mapMutations, mapState } from "vuex";
import { storage } from "@/utils";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["namespace", "source", "userInfo", "token"]),
    isLogin() {
      return !!this.token;
    },
  },
  methods: {
    ...mapMutations(["changeState"]),

    async login() {
      await LoginDialog.show({
        title: "手机号登录",
        className: "promotion-login",
      });
      this.$router.replace({ path: "/promotion/card" });
    },
    async logout() {
      await this.$confirm({
        title: "退出登录",
        message: "确定要退出登录吗？",
      });
      storage.removeItem("token");
      storage.removeItem("login_phone_number");
      this.changeState({
        token: "",
        ptoken: "",
        isMember: false,
        memberInfo: {},
        userInfo: {},
      });
      this.$router.replace({ path: "/promotion/card" });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!storage.getItem("token") && to.path !== "/promotion/card") {
      next({ path: "/promotion/card" });
    } else {
      next();
    }
  },
};
</script>

<style lang="less" scoped>
.promotion-container {
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f4f5f7 url("~@/assets/images/promotion_login_bg.png") no-repeat
    top/100%;
  padding: 50px 15px 30px;
}

.promotion-card {
  position: relative;
  margin: 0 7px;
  padding: 20px 23px 0 42px;
  height: 104px;
  box-sizing: border-box;
  border-radius: 14px;
  background: url("~@/assets/images/promotion_card_bg.png") no-repeat
    center/contain;
  display: flex;
}
.title {
  color: #fff;
  font-size: 17px;
  line-height: 24px;
  height: 24px;
}
.tag {
  padding: 2px 4px;
  font-size: 11px;
  color: #ff5900;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, #fff9e3 100%);
  border-radius: 4px;
  margin-left: 5px;
  vertical-align: 2px;
}
.login-btn {
  margin-top: 7px;
  margin-left: auto;
  width: 82px;
  border-radius: 9999px;
  line-height: 33px;
  height: 33px;
  font-size: 14px;
  color: #f7511a;
  text-align: center;
  background: #fff;
  font-weight: bold;
}

.promotion-body {
  background: #fff;
  border-radius: 14px;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
  margin-top: -16px;
  padding: 13px 13px 20px;
  /deep/ .van-empty__description {
    margin-top: 10px;
    color: #666;
  }
  /deep/ .van-empty {
    padding-top: 50px;
  }
}

.promotion-member-icon {
  width: 94px;
  height: 90px;
  position: absolute;
  right: 20px;
  top: -13px;
}

.promotion-logout {
  position: absolute;
  right: 22px;
  top: 16px;
  color: #666;
  line-height: 17px;
}
</style>

<style lang="less">
.promotion-login {
  padding-bottom: 40px;
  .form-submit .van-button {
    background: linear-gradient(to top, #ff341b 0%, #ff9c5e 100%) !important;
  }
}
</style>