import request from "@/utils/request";

export const getIndexCategory = params => {
  return request({
    url: "/api/mall/categories",
    method: "get",
    params
  });
};

export const getIndexClassList = params => {
  return request({
    url: "/api/mall/products_by_title",
    method: "get",
    params
  });
};

//获取支付方式
export const getPaymethod = data => {
  const useragent = navigator.userAgent.toLowerCase();
  if (useragent.match(/MicroMessenger/i) == "micromessenger") {
    data.env = "wx_gzh";
  }
  // 微信小程序内直接返回支付方式
  if (navigator.userAgent.indexOf("miniProgram") !== -1) {
    return Promise.resolve([
      {
        channel: "wechat_miniapp",
        desc: "微信小程序内支付",
        icon: "https://cdn.xiangshuheika.com/static/pay/wechat.png",
        is_fold: false,
        name: "微信支付",
        tips: null
      }
    ]);
  }
  return request({
    url: "/api/xpay/get_paymethod",
    method: "post",
    data
  });
};

//获取套餐信息
export const getPackageList = params => {
  params.only_member = "1";
  return request({
    url: "/api/core/get_membership_packages",
    method: "get",
    params
  }).then(res => {
    // 为了隐藏套餐在前端页面展示，切不能影响已开通的会员
    // 排序为-999的套餐 不展示
    res.data = res.data.filter(item => item.order !== 9999090)
    res.data.forEach(item => {
      item.price = item.item_price !== undefined ? item.item_price : item.price;
    });
    return res;
  });
};
//获取套餐信息

// 获取用户多卡信息
export const getMutilCard = params => {
  return request({
    url: "/api/core/get_user_multi_card_info",
    method: "get",
    params
  });
};
// 根据租户组获取用户多卡信息
export const getMutilCardByNg = params => {
  return request({
    url: "/api/core/get_user_multi_card_info_limit_ng",
    method: "get",
    params
  });
};

// 查询话费充值渠道面值状态
export const queryMobileOperator = params => {
  return request({
    url: "/api/mq/mobile_operator_maintain",
    method: "get",
    params
  });
};

// 获取跳转url
export const queryH5JumpUrl = params => {
  return request({
    url: "/api/mall/h5_url",
    method: "get",
    params
  });
};
// 活动状态
export const seckillActivity = data => {
  return request({
    url: "/api/activity/seckill_activity",
    method: "post",
    data
  });
};
// 检查券码是否可以展示
export const checkCouponCanbeDisplay = params => {
  return request({
    url: "/api/mq/check_coupon_can_be_displayed",
    method: "get",
    params
  });
};

// 检查券码是否多次提交
export const checkHasSubmitWorkOrder = params => {
  return request({
    url: "/api/core/check_user_has_already_submitted_work_order",
    method: "get",
    params
  });
};

// 创建售后工单
export const createWorkerFlow = data => {
  return request({
    url: "/api/mq/create_benefit_writeoff_aftersale_flow",
    method: "post",
    data
  });
};

// 获取售后工单
export const getWorkFlow = params => {
  return request({
    url: "/api/mq/get_benefit_writeoff_aftersale_flow",
    method: "get",
    params
  });
};

// 获取工单配置
export const getWorkFlowConfig = params => {
  return request({
    url: "/api/mq/get_benefit_writeoff_aftersale_flow_config_by_product_tag",
    method: "get",
    params
  });
};
// 取消工单
export const canWorkFlow = params => {
  return request({
    url: "/api/mq/cancel_benefit_writeoff_aftersale_flow",
    method: "get",
    params
  });
};
// 上传图片
export const uploadFlowPicture = data => {
  return request({
    url: "/api/mq/upload_benefit_writeoff_aftersale_flow_picture",
    method: "post",
    data
  });
};

// 验证用户是否可以参加某兑换码批次的兑换
export const validateCashingCodeBatch = params => {
  return request({
    url: "/api/core/validate_cashing_code_batch_redemption",
    method: "get",
    params
  });
};

// 获取兑换码
export const getUserCashingCode = params => {
  return request({
    url: "/api/core/get_user_cashing_code",
    method: "get",
    params
  });
};

// 查询丽呈多卡接口
export const getOtherCards = () =>
  request({ url: "/api/core/get_all_lc_member_infos", method: "get" });

// 查询用户充值绑定的账号
export const queryUserRechargeAccount = params => {
  return request({
    url: "/api/core/user_recharge_account_v2",
    method: "get",
    params
  }).catch(() => {
    return {
      data: []
    };
  });
};
// 爱奇艺充值
export const benefitIQYBenefit = params => {
  return request({
    url: "/api/core/kukai_benefit_activate",
    method: "post",
    data: params
  });
};

// 手机号拉起支付宝签约
export const signByPhone = params => {
  return request({
    url: "/api/login/get_sign_url_by_phone",
    method: "post",
    data: params
  });
};

// 查询会员内有无jdplus订单
export const fetchJdPlusOrder = () => {
  return request({
    url: "/api/mq/benefit_order_query_all",
    method: "post"
  });
};
