<template>
  <div class="wrap" v-if="!loading">
    <div class="header">
      <div class="card-time">
        <div class="time">有效期至 {{ time(card_info.card_expiry_date) }}</div>
        <div class="action">
          <span @click="$router.push('/help')">客服</span> ｜
          <span @click="$router.push('/order')">我的订单</span>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-desc">VIP尊享权益 每月专享</div>
      <div class="content" v-if="userInfo.card_status === 'active'">
        <div class="card" v-for="item of benefit_list" :key="item.type">
          <div class="tip">x{{ item.amount + item.amount_reserved }}次</div>
          <img :src="item.icon_url" />
          <div class="msg">
            <p class="title">{{ item.title }}</p>
            <p>价值:¥{{ item.price }}</p>
          </div>
          <div class="btn" @click="jump(item)">去使用</div>
        </div>
      </div>
      <div v-else-if="userInfo.card_status === 'expired'" class="card-past">
        <van-image :src="require('../../../assets/images/yh-empty.png')" />
        <p>您的权益已过期</p>
      </div>
      <div v-else-if="userInfo.card_status === 'refund'" class="card-past">
        <van-image :src="require('../../../assets/images/yh-empty.png')" />
        <p>您的权益已退款</p>
      </div>
      <img
        class="emoji"
        src="https://common-h5-dev.xiangshuheika.com/static/upload/202437579af4386e6848aa875f13ef5052ba4d.png"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";

import { getUserBenefit } from "@/apis/home";
import { getIndexCategory } from "@/apis/product";
import { jsonSafeParse } from "@/utils";

export default {
  computed: {
    ...mapState(["namespace", "userInfo"]),
    time() {
      return (value) => {
        return dayjs(value).format("YYYY.MM.DD");
      };
    },
  },
  data() {
    return {
      card_info: {},
      benefit_list: [],
      loading: false,
    };
  },
  methods: {
    initDialog() {
      const message = this.$getConfigValue("LN_DIALOG_MESSAGE") || "";
      !!message &&
        this.$confirm({
          message: message,
          messageAlign: "left",
          showCancelButton: false,
        });
    },

    async init() {
      const CARD_CONFIG = jsonSafeParse(
        this.$getConfigValue("LN_CARD_CONFIG_SPLIT")
      );
      const splitIndex = CARD_CONFIG[this.userInfo.card_type];
      const loading = this.$loading("");
      try {
        this.loading = true;
        const { data } = await getUserBenefit({ namespace: this.namespace });
        const { data: category } = await getIndexCategory({
          namespace: this.namespace,
          opened_page: "member",
          source: "default",
        });
        // 根据权益展示顺序排序
        if (data.benefit_order) {
          const orders = data.benefit_order.split(",");
          data.benefit_list.sort((a, b) => {
            return orders.indexOf(a.type) - orders.indexOf(b.type);
          });
        }
        this.benefit_list = data.benefit_list.map((item) => {
          const arr = category.filter(
            (value) => value.display_code === item.type
          )[0];
          const price = arr?.desc.split("｜")[splitIndex]
            ? arr?.desc.split("｜")[splitIndex]
            : arr?.desc;
          return { ...item, price, title: arr?.name };
        });
        this.card_info = data;
      } catch (error) {
        console.log(error);
      } finally {
        loading.clear();
        this.loading = false;
      }
    },

    jump(item) {
      this.$router.push(`/benefitDetail?benefit_type=${item.type}`);
    },
  },

  mounted() {
    this.init();
    this.initDialog();
  },
};
</script>

<style scoped lang="less">
.wrap {
  min-height: 100vh;
  background: #594af2;
  box-sizing: border-box;
  overflow: hidden;
}

.header {
  margin-top: 16px;
  height: 160px;
  background: url("https://common-h5-dev.xiangshuheika.com/static/upload/202437ddd25c10d0814adba28c767bd2f17db7.png");
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  color: #ffffff;
  font-size: 11px;
  font-weight: 400;

  .card-time {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px;
    height: 39px;
    background: rgba(254, 231, 239, 0.3);
    border-radius: 11px;
    left: 20px;
    right: 20px;
    bottom: 9px;
  }
}

.card-past {
  height: 195px;
  border-radius: 5px;
  margin: 12px 12px 20px;
  overflow: hidden;

  p {
    color: white;
    text-align: center;
    line-height: 1.5;
    margin-top: 17px;
    font-size: 16px;
  }

  /deep/ .van-image {
    width: 80px;
    height: 80px;
    margin: 56px auto 14px;
  }
}

.main {
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  margin: 12px;
  padding: 11px 8px 21px 8px;
  background: linear-gradient(
    358deg,
    rgba(255, 255, 255, 0.6) 0%,
    #7993fe 61%,
    #9074ff 100%
  );
  border-radius: 17px;
  border: 2px solid #ffffff;

  .main-desc {
    text-align: center;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: bold;
    font-size: 18px;
    color: #fbfaff;
    line-height: 25px;
    text-shadow: 0px 2px 2px rgba(87, 0, 255, 0.5);
    margin-bottom: 11px;
  }

  .content {
    border-radius: 14px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;

    .card {
      box-sizing: border-box;
      text-align: center;
      border-radius: 12px;
      width: 159px;
      height: 154px;
      background: #fff;
      position: relative;
      margin-bottom: 9px;

      &:not(:nth-child(2n)) {
        margin-right: 12px;
      }

      .msg {
        font-family: PingFangSC, PingFang SC;

        .title {
          font-weight: 500;
          font-size: 11px;
          color: #333333;
          line-height: 16px;
          margin-top: 3px;
        }

        p {
          font-weight: 400;
          font-size: 9px;
          color: #666666;
          line-height: 16px;
        }
      }

      .btn {
        width: 94px;
        height: 28px;
        background: linear-gradient(121deg, #ffb348 0%, #ff6326 100%);
        border-radius: 14px;
        text-align: center;
        margin: 0 auto;
        margin-top: 6px;
        font-family: DingTalk, DingTalk;
        font-weight: normal;
        font-size: 12px;
        color: #ffffff;
        line-height: 28px;
      }

      img {
        margin-top: 15px;
        width: 57px;
        height: 57px;
      }

      .tip {
        height: 23px;
        width: 42px;
        left: -8px;
        position: absolute;
        background: url("https://common-h5-dev.xiangshuheika.com/static/upload/2024371c4d91de460c47d49c1d1b4263505d0c.png");
        background-size: 100%;
        background-repeat: no-repeat;
        color: #fff;
        padding-top: 2px;
        text-align: center;
        line-height: 15px;
        font-size: 10px;
        top: 3px;
      }
    }
  }

  .emoji {
    width: 70px;
    height: 64px;
    position: absolute;
    bottom: -30px;
    left: -6px;
  }
}
</style>