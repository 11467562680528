<template>
  <div class="wrap">
    <div
      class="wrap-tips"
      id="cwxp-tips"
      :class="`${animationStyle} ${iosStyle}`"
    >
      <div>向右滑返回</div>
    </div>
    <div class="visitor-wrap">
      <div class="content-wrap">
        <div class="select-wrap">
          <div
            class="select"
            v-for="item of packages"
            :key="item.item_code"
            @click="handleSelect(item)"
          >
            <div class="select-content" :class="isSelect(item.item_code)">
              <div class="select-desc">
                {{ cardMap[item.item_code].desc }}
              </div>
              <div class="select-name">{{ item.unit }}卡</div>
              <div class="select-num">
                <span>¥</span
                ><span class="price"
                  >{{ item.price
                  }}<span class="ori-price">¥{{ item.face_value }}</span>
                </span>
              </div>
              <div
                class="triangle"
                :style="{
                  display:
                    item.item_code === active.item_code ? 'block' : 'none',
                }"
              ></div>
            </div>
            <div class="select-footer" :class="isSelect(item.item_code)">
              立省 ¥{{ saveAmount(item.face_value, item.price) }}
            </div>
          </div>
        </div>
        <div class="sup-wrap">
          <div class="sup-name">12选1 每月可领取1次</div>
          <div class="sup-content">
            <img src="@/assets/images/xpcw-benefits.png" />
          </div>
          <div class="sup-amount">
            <div class="amount-desc">合计</div>
            <div class="sup-num">
              总价值<span class="sub">¥</span
              ><span class="num">{{ active.face_value }}</span>
            </div>
          </div>
        </div>
        <div ref="btnRef" class="buy-btn-wrap">
          <div
            class="buy-btn resizing"
            @click="submit"
            v-ans="{ event_id: 'visitor_click_buy' }"
          >
            立即购买<span>¥</span>{{ active.price }}
          </div>
          <div class="buy-text">
            购买前请阅读<a
              href="https://cdn.xiangshuheika.com/h5/agreement/cwxp.html"
              >《充值规则》</a
            >，购买激活之日起{{
              cardMap[active.item_code] ? cardMap[active.item_code].expire : ""
            }}天有效期
          </div>
        </div>
        <div class="stick-btn" v-show="isIntersecting">
          <div class="buy-btn resizing" @click="submit">
            立即购买<span>¥</span>{{ active.price }}
          </div>
          <div class="buy-text">
            购买前请阅读<a
              href="https://cdn.xiangshuheika.com/h5/agreement/cwxp.html"
              >《充值规则》</a
            >，购买激活之日起{{
              cardMap[active.item_code] ? cardMap[active.item_code].expire : ""
            }}天有效期
          </div>
        </div>
        <div class="login-text" @click="handleLogin" v-if="!isLogin">
          已是会员，立即登陆
        </div>
      </div>
      <div class="footer">
        <div class="tip">购买须知</div>
        <div class="desc" v-html="active.desc"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  computed,
  onUnmounted,
  watch,
} from "@vue/composition-api";
import { Decimal } from "decimal.js";

import loginKeepDialog from "@/components/loginKeepDialog";
import useVisitorCategory from "@/use/useVisitorCategory";
import { toPayV2 } from "@/helper/pay";
import { useStore } from "@/components/use/base";

const CARD_CONFIG = {
  CWXP06: {
    desc: "每月12选1 可领6次",
    expire: 180,
  },
  CWXP12: {
    desc: "每月12选1 可领12次",
    expire: 360,
  },
};

export default defineComponent({
  setup() {
    const store = useStore();
    const namespaceInfo = store.state.namespaceInfo;
    const isLogin = ref(!!store.state.token);
    const cardMap = reactive(CARD_CONFIG);
    const active = ref({});
    const select = ref("CWXP06");
    const btnRef = ref();
    const isIntersecting = ref(false);
    const timer = ref(null);
    const isTipVisible = ref(true);
    const isScroll = ref(false);

    const isIos = store.state.isiOS;

    const { fetchPackage, fetchPaymethod, currentMethod, packages } =
      useVisitorCategory();

    const isSelect = computed(() => (code) => {
      return active.value.item_code === code && "isSelect";
    });

    const iosStyle = computed(() => {
      if (isIos) return "ios-style";
      return "";
    });

    const saveAmount = computed(() => (ori, cur) => {
      return new Decimal(ori).minus(cur).toNumber();
    });

    onMounted(async () => {
      await Promise.all([fetchPackage(), fetchPaymethod()]);
      active.value = packages.value[0];
      listenBtn();
      document.addEventListener("touchmove", handleStart);
    });

    const handleStart = () => {
      timer.value && clearTimeout(timer.value);
      timer.value = setTimeout(() => {
        handleStop();
      }, 1000);
      isScroll.value = true;
      isTipVisible.value = false;
    };
    const handleStop = () => {
      isTipVisible.value = true;
      isScroll.value = false;
      clearTimeout(timer.value);
    };

    onUnmounted(() => {
      listenBtn(true);
      document.removeEventListener("touchmove", handleStart);
    });

    const listenBtn = (isUnMount = false) => {
      const io = new IntersectionObserver((entries) => {
        isIntersecting.value = !entries[0].isIntersecting;
      });
      io.observe(btnRef.value);
      if (isUnMount) io.unobserve(btnRef.value);
    };

    const handleSelect = (item) => {
      active.value = item;
    };

    const animationStyle = computed(() => {
      if (!isTipVisible.value && isScroll.value) return "start";
      if (isTipVisible.value && !isScroll.value) return "end";
      return "";
    });

    const handleLogin = async () => {
      await loginKeepDialog
        .show({
          title: "手机号验证",
          desc: "为保证您的会员权益及时到账，请先关联手机号",
          btnText: "完成",
          agreement: `<div style="text-align:center">购买则视为同意
        <a
          class="login-agreement-text"
          href="https://cdn.xiangshuheika.com/h5/agreement/pagj.html"
          >《服务协议》</a
        > </div>`,
        })
        .then(() => {
          isLogin.value = true;
        });
    };

    const submit = async () => {
      let url =
        (window.location.origin + window.location.pathname).replace(/\/$/, "") +
        `/#/index`;
      const return_url = encodeURIComponent(url);
      if (!store.state.token) {
        await loginKeepDialog
          .show({
            title: "手机号验证",
            desc: "为保证您的会员权益及时到账，请先关联手机号",
            btnText: "完成",
            agreement: `<div style="text-align:center">购买则视为同意
        <a
          class="login-agreement-text"
          href="https://cdn.xiangshuheika.com/h5/agreement/pagj.html"
          >《服务协议》</a
        > </div>`,
          })
          .then((userInfo) => {
            if (!userInfo.is_member) {
              toPayV2(active.value, currentMethod.value.channel, {
                partner_jump_url: return_url,
                order_desc: active.value.name,
              });
            }
          });
        return;
      }
      toPayV2(active.value, currentMethod.value.channel, {
        partner_jump_url: return_url,
        order_desc: active.value.name,
      });
    };

    return {
      submit,
      handleSelect,
      handleLogin,
      namespaceInfo,
      packages,
      cardMap,
      select,
      active,
      isSelect,
      saveAmount,
      btnRef,
      isIntersecting,
      isTipVisible,
      animationStyle,
      iosStyle,
      isLogin,
    };
  },
});
</script>

<style lang="less" scoped>
.visitor-wrap {
  background-image: url("../../../assets/images/xpcw-header.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: rgb(252, 195, 129);
  box-sizing: border-box;
  padding: 151px 11px 21px 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  .select-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 12px;
    .select {
      width: 163px;
      height: 91px;
      background: #ffffff;
      box-shadow: 0px 0px 5px 0px rgba(153, 153, 153, 0.3);
      border-radius: 8px;
      position: relative;
      .select-content {
        height: 72px;
        &.isSelect {
          border: 2px solid #ff7c00;
          border-bottom: none;
          border-radius: 8px 8px 0 0;
          background: linear-gradient(90deg, #fffefa 0%, #ffd7b5 100%);
        }
      }
      .triangle {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 2px;
        height: 0;
        transform: translate3d(-50%, 100%, 0);
        border-top: 5px solid #f88738;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        z-index: 12;
      }

      .select-desc {
        padding: 1px 3px;
        position: absolute;
        top: -8px;
        left: 0;
        background: #ff5001;
        box-shadow: 0px 2px 4px 2px #ffe3ca;
        border-radius: 6px 0px 6px 0px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 14px;
      }
      .select-name {
        text-align: center;
        font-weight: 500;
        margin-top: 12px;
        font-size: 14px;
        color: #333333;
        font-family: PingFangSC-Medium, PingFang SC;
        line-height: 20px;
      }
      .select-num {
        text-align: center;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff5001;
        line-height: 35px;

        .price {
          font-size: 35px;
          position: relative;
        }
        > span {
          font-size: 17px;
          margin-right: 2px;
        }
        .ori-price {
          position: absolute;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #b4b4b4;
          left: calc(100% + 2px);
          bottom: 0;
          text-decoration: line-through;
          line-height: 35px;
        }
      }
      .select-footer {
        text-align: center;
        height: 19px;
        background: #fff9eb;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 10px;
        font-weight: 400;
        color: #666666;
        line-height: 19px;
        border-radius: 0 0 8px 8px;
        &.isSelect {
          background: linear-gradient(
            90deg,
            #f6a148 0%,
            #f96c27 100%
          ) !important;
          color: #ffffff;
        }
      }
    }
  }

  .content-wrap {
    background: #ffecd8;
    border-radius: 17px;
    border: 1px solid #ffffff;
    padding: 16px 8px 14px 9px;

    .login-text {
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #0091ff;
      line-height: 17px;
      text-align: center;
      margin-top: 7px;
    }
  }

  .sup-wrap {
    background: #ffffff;
    border-radius: 14px;
    padding: 13px 22px 0px 25px;
    margin-bottom: 9px;
    .sup-name {
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      line-height: 25px;
      text-align: center;
    }
    .sup-content {
      margin: 0 -22px 0 -25px;
      > img {
        width: 100%;
      }
    }
    .sup-amount {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: self-end;
      padding-bottom: 9px;
      .amount-desc {
        font-size: 11px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
      .sup-num {
        font-size: 11px;
        font-weight: 400;
        color: #ff5001;
        line-height: 22px;

        .sub {
          margin: 0 2px;
          vertical-align: middle;
        }
        .num {
          font-size: 22px;
          font-weight: 600;
        }
      }
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        margin-bottom: 9px;
        background-image: linear-gradient(
          to right,
          #eaeaea 0,
          #eaeaea 50%,
          transparent 50%
        );
        background-size: 9px 1px;
        background-repeat: y-repeat;
      }
    }
  }
  .buy-btn-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .buy-btn {
    text-align: center;
    width: 280px;
    height: 53px;
    background: linear-gradient(360deg, #ff341b 0%, #ff9c5e 100%);
    font-family: PingFangSC-Medium, PingFang SC;
    border-radius: 27px;
    font-size: 27px;
    font-weight: 500;
    color: #ffffff;
    line-height: 53px;
    > span {
      font-size: 20px;
      line-height: 27px;
      vertical-align: middle;
      margin-left: 6px;
    }
  }

  .buy-text {
    margin-top: 9px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
    > a {
      color: #999999;
    }
  }
  .footer {
    position: relative;
    margin-top: 11px;
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    padding: 29px 8px 13px 10px;
    .tip {
      position: absolute;
      left: 0;
      top: 0;
      width: 60px;
      height: 21px;
      background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
      border-radius: 0px 0px 8px 0px;
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
      line-height: 21px;
      text-align: center;
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
    }
  }
}
.stick-btn {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  padding-bottom: 5px;
  .buy-text {
    margin-top: 6px;
  }
}

.wrap {
  position: relative;
  .wrap-tips {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 64px;
    height: 21px;
    background: #f96d26;
    border-radius: 4px;
    border: 1px solid #f96d26;
    top: 7px;
    left: 10px;
    z-index: 9;

    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    ::before {
      display: block;
      content: "";
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-right: 8px solid #f96d26;
      position: absolute;
      left: -12px;
    }
    &.start {
      left: -30px;
    }
  }
}
.start {
  animation: start linear 0.5s;
  @keyframes start {
    0% {
      left: 10px;
    }
    100% {
      left: -30px;
    }
  }
}
.end {
  animation: end linear 0.5s;
  @keyframes end {
    0% {
      left: -30px;
    }
    100% {
      left: 10px;
    }
  }
}

.ios-style {
  top: 70px !important;
}
</style>
