<template>
  <section class="aftermarket-result">
    <div class="aftermarket-status">提交成功</div>
    <div class="aftermarket-icon">
      <svg-icon iconClass="success-green" />
    </div>
    <div class="aftermarket-text">
      您的申请已提交，原有权益将不能使用，请知悉。
    </div>
    <p class="aftermarket-desc">
      工作时间（8:00-20:00）内，预计10分钟内审核完成
      非工作时间，预计24小时内审核完成，<span>请留意短信通知</span>
    </p>
    <van-button
      class="aftermarket-result-btn"
      v-ans="{
        event_id: 'benefit_aftersale_click_record-btn',
        order_number: $route.query.order_number,
      }"
      round
      block
      @click="toRecord"
      >查看详情</van-button
    >
    <van-button
      class="aftermarket-result-btn1"
      v-ans="{
        event_id: 'benefit_aftersale_click_to_order',
        order_number: $route.query.order_number,
      }"
      block
      round
      @click="toOrder"
      >返回订单页</van-button
    >
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  async mounted() {},
  methods: {
    toOrder() {
      const { order_number, fromUrl } = this.$route.query;
      if (fromUrl) {
        location.href = `${decodeURIComponent(
          fromUrl
        )}/orderDetail?order_number=${order_number}`;
      } else {
        this.$router.push(`/orderDetail?order_number=${order_number}`);
      }
    },
    toRecord() {
      this.$router.replace({
        path: "/afterMarket/record",
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.aftermarket-result {
  text-align: center;
  background: #f8f8f8;
  padding: 60px 20px 0;
  box-sizing: border-box;
  min-height: 100vh;
}
.aftermarket-status {
  font-size: 16px;
  color: #333;
  margin-bottom: 24px;
  line-height: 22px;
  font-weight: bold;
}

.aftermarket-icon {
  margin: 24px auto;
  font-size: 62px;
  .svg-icon {
    color: #00b25a;
  }
}

.aftermarket-text {
  line-height: 24px;
  margin-bottom: 12px;
  padding: 0 10px;
  font-size: 14px;
}

.aftermarket-desc {
  padding: 0 16px;
  line-height: 22px;
  margin-bottom: 44px;
  color: #999;
  span {
    color: #e12d2d;
  }
}

.aftermarket-result-btn {
  background-color: #2d3356;
  color: #fff;
}
</style>