import Vue from 'vue';
import store from '../../store';
import router from '../../router';
import Popup from './Popup';

let instance;

function isInDocument(element) {
  return document.body.contains(element);
}

function initInstance() {
  if (instance) {
    instance.$destroy();
  }

  instance = new (Vue.extend({
    ...Popup,
    store,
    router
  }))({
    el: document.createElement('div'),
    // avoid missing animation when first rendered
    propsData: {
      lazyRender: false,
    },
  });
  document.body.appendChild(instance.$el);

  instance.$on('input', (value) => {
    instance.value = value;
  });
}


function PopupCalendar(options) {
  return new Promise((resolve, reject) => {
    initInstance();
    if (!instance || !isInDocument(instance.$el)) {
    }

    Object.assign(instance, PopupCalendar.currentOptions, options, {
      resolve,
      reject,
    });
  });
}

PopupCalendar.show = (options) =>
  PopupCalendar({
    value: true,
    ...options,
  });

PopupCalendar.close = () => {
  if (instance) {
    instance.value = false;
    instance.$destroy();
    instance.$el.parentNode?.removeChild(instance.$el)
  }
};

PopupCalendar.currentOptions = {
  value: false,
  onComplete: () => { },
  close: () => {
    if (instance) {
      instance.value = false;
      // 弹窗关闭以后再消耗组件，否则动画效果会丢失
      setTimeout(() => {
        instance.$destroy();
        instance.$el.parentNode?.removeChild(instance.$el)
      }, 300)
    }
  }
}

export default PopupCalendar;