<template>
  <div class="wrap">
    <div class="header">
      <div class="title">
        <span>免费福利</span>
      </div>
    </div>
    <template v-if="imgList.length">
      <img
        :src="item.img"
        v-for="item of imgList"
        :key="item.url"
        @click="handleDraw(item.url)"
        class="img"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import Vue from "vue";

import { jsonSafeParse } from "@/utils";

const vm = Vue.prototype;

export default defineComponent({
  setup() {
    const imgList = ref([]);
    const handleDraw = (url) => {
      window.open(url);
    };

    onMounted(() => {
      const imgConfig = jsonSafeParse(
        vm.$getConfigValue("UNXP_FREE_BENEFIT_CONFIG"),
        []
      );
      imgList.value = imgConfig;
    });

    return {
      handleDraw,
      imgList,
    };
  },
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 17px 16px 16px 16px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;

  .img {
    margin-top: 3px;
    width: 100%;
    height: 120px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      display: flex;
      align-items: center;

      > span {
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
      }
    }
  }
}
</style>
