<template>
  <section v-show="!loading">
    <display
      page="open"
      ref="display"
      :memberPackage="memberPackage"
      :payMethod="payMethod"
    />
    <div class="fixed-height" />
    <div class="footer-fixed">
      <div
        class="footer-package"
        @click="toBuy"
        v-ans="{
          event_id: 'page_buy_button',
        }"
      >
        <div class="footer-left">
          限时特惠：
          <span class="gm-price">
            <span class="sub">¥</span>{{ memberPackage.price }}
          </span>
          <span class="gm-facevalue">¥{{ memberPackage.face_value }}</span>
        </div>
        <div class="footer-right">立即开通</div>
        <div class="discount">购卡后可赠送好友</div>
      </div>
      <div class="pagj-agreement">
        登录购买即视为同意
        <a href="https://cdn.xiangshuheika.com/h5/agreement/pagj.html"
          >《会员服务协议》</a
        >
      </div>
    </div>
    <van-dialog
      v-model="show"
      close-on-click-overlay
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="end-overlay">
        <van-image
          class="end-img"
          :src="require('../../assets/images/end_pingan_gj_icon.png')"
        />
        <div class="end-text">
          本次活动已结束，如已购买<br />
          请到已购买页面使用会员权益
        </div>
      </div>
    </van-dialog>
  </section>
</template>

<script>
import display from "./components/display";
import { getLogicUser } from "@/apis/home";
import loginKeepDialog from "@/components/loginKeepDialog";
import { getPackageList, getPaymethod } from "@/apis/product";
import { mapMutations, mapState } from "vuex";
import ans from "@/utils/ans";
import { toPayV2 } from "@/helper/pay";
export default {
  components: {
    display,
  },
  data() {
    return {
      show: this.$getConfigValue("HIDE_PAY_ENTRY"),
      loading: true,
      payMethod: {},
      memberPackage: {},
    };
  },
  async mounted() {
    const loading = this.$loading();
    try {
      await Promise.all([this.fetchPackage(), this.$refs.display.init()]);
    } finally {
      this.loading = false;
      loading.clear();
    }
  },
  computed: {
    ...mapState(["namespace", "token"]),
  },
  methods: {
    ...mapMutations(["changeState"]),
    async fetchPackage() {
      const [methods, res] = await Promise.all([
        getPaymethod({
          namespace: this.namespace,
          product_type: this.namespace.toUpperCase(),
          source: "default",
        }),
        getPackageList({
          namespace: this.namespace,
          source: this.source,
        }),
      ]);
      this.payMethod = methods.length ? methods[0] : {};
      this.memberPackage = res.data.length ? res.data[0] : {};
    },
    async toBuy() {
      if (!this.token) {
        this.$toast("您还未登录，请先登录");
        ans.track("page_login_clicklogin", { namespace: this.namespace });
        setTimeout(() => {
          loginKeepDialog
            .show({
              agreement: `登录领取即视为同意
        <a
          class="login-agreement-text"
          href="https://cdn.xiangshuheika.com/h5/agreement/pagj.html"
          >《会员服务协议》</a
        >`,
            })
            .then(() => {
              ans.track("page_login_success", { namespace: this.namespace });
            });
        }, 1000);
        return;
      }
      if (this.$getConfigValue("HIDE_PAY_ENTRY")) {
        this.show = true;
        return;
      }
      ans.track("page_visitor_click_buy", { namespace: this.namespace });
      const loading = this.$loading();
      try {
        // 生成一个新用户，使用新用户的token下单
        const res = await getLogicUser();
        this.changeState({ token: res.data.token });
        toPayV2(this.memberPackage, this.payMethod.channel, {
          user_token: res.data.token,
        });
      } finally {
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.footer-package {
  position: relative;
  display: flex;
  align-items: center;
  height: 49px;
  background: url("../../assets/images/pagj_btn_bg.png") no-repeat;
  background-size: 100%;
  .discount {
    position: absolute;
    left: 94px;
    top: -10px;
    width: max-content;
    color: #fff;
    line-height: 20px;
    height: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fc6f1a;
    padding: 0 5px;
  }
}

.footer-left {
  flex: 4;
  display: flex;
  align-items: baseline;
  color: #ffefd7;
  justify-content: center;
  font-size: 14px;
  .gm-price {
    margin-right: 5px;
    font-size: 24px;
    .sub {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .gm-facevalue {
    opacity: 0.8;
    text-decoration: line-through;
  }
}

.footer-right {
  flex: 3;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.pagj-agreement {
  margin-top: 8px;
  color: #999;
  padding-left: 4px;
  a {
    color: #4892f0;
  }
}

.footer-fixed {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 50px;
  padding: 20px 20px;
  box-sizing: border-box;
  background: #fff;
}

.fixed-height {
  height: 111px;
}

.end-overlay {
  padding: 30px;
  text-align: center;
}
.end-img {
  width: 200px;
  height: auto;
  margin: 0 auto;
}
.end-text {
  margin-top: 20px;
  line-height: 1.5;
  font-size: 14px;
}
</style>