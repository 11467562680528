import request from "@/utils/request"

//获取累计消费，节省，加油等信息
export const getUserConsumptionInfo = (params) => {
  return request({
    url: '/api/addoil/get_user_addoil_cumulative_consumption_info',
    method: 'get',
    params,
  })
}

//获取油站列表
export const getOilList = (data) => {
  return request({
    url: '/api/addoil/oil_station_list',
    method: 'post',
    data,
  })
}

//获取油站信息
export const getOilDetail = (params) => {
  return request({
    url: '/api/addoil/oil_name_list',
    method: 'get',
    params,
  })
}

//计算油站价格优惠
export const getOilPreferentialPrice = (data) => {
  return request({
    url: '/api/addoil/get_oil_station_designed_oil_name_preferential_price',
    method: 'post',
    data,
  })
}

//获取加油订单详情
export const getOilOrderDetail = (params) => {
  return request({
    url: '/api/addoil/get_addoil_order_detail',
    method: 'get',
    params,
  })
}

//获取加油订单核销码信息
export const getOilVerifyInfo = (params) => {
  return request({
    url: '/api/addoil/get_addoil_order_verify_info',
    method: 'get',
    params,
  })
}
