<template>
  <div class="refund-detail" v-if="pageshow">
    <refund-status-bar
      :waiting="order_info.refund_status === 'waiting'"
      :complete="
        order_info.refund_status === 'agreed' ||
        order_info.refund_status === 'rejected'
      "
      :error="order_info.refund_status === 'rejected'"
    />
    <div
      :class="
        order_info.refund_status === 'rejected'
          ? 'refund-hint-rejected'
          : 'refund-hint'
      "
    >
      {{ text }}
    </div>
    <div class="refund-info">
      <div class="refund-info-title">退款信息：</div>
      <div class="refund-info-item">
        <div class="refund-info-key">手机号</div>
        <div class="refund-info-value">
          {{ user_phone ? user_phone : order_info.phone_number }}
        </div>
      </div>
      <div class="refund-info-item">
        <div class="refund-info-key">订单号</div>
        <div class="refund-info-value">{{ order_info.order_number }}</div>
      </div>
      <div class="refund-info-item">
        <div class="refund-info-key">订单名称</div>
        <div class="refund-info-value">{{ order_info.order_name }}</div>
      </div>
      <div class="refund-info-item">
        <div class="refund-info-key">会员号</div>
        <div class="refund-info-value">{{ order_info.card_number }}</div>
      </div>
      <div class="refund-info-item">
        <div class="refund-info-key">会员周期</div>
        <div class="refund-info-value">
          {{ order_info.t_start }}至{{ order_info.t_expiration }}
        </div>
      </div>
      <div class="refund-info-item" v-if="behavior === 'view'">
        <div class="refund-info-key">提交时间</div>
        <div class="refund-info-value">{{ order_info.t_created }}</div>
      </div>
      <div class="refund-info-item" v-if="behavior === 'view'">
        <div
          class="refund-info-key"
          v-if="order_info.refund_status === 'waiting'"
        >
          预计审核时间
        </div>
        <div
          class="refund-info-key"
          v-if="
            order_info.refund_status === 'rejected' ||
            order_info.refund_status === 'agreed'
          "
        >
          审核时间
        </div>
        <div
          class="refund-info-value"
          v-if="order_info.refund_status === 'waiting'"
        >
          {{ order_info.t_audit }}前
        </div>
        <div
          class="refund-info-value"
          v-if="
            order_info.refund_status === 'rejected' ||
            order_info.refund_status === 'agreed'
          "
        >
          {{ order_info.t_audited }}
        </div>
      </div>
    </div>
    <div class="refund-money" v-if="order_info.refund_status !== 'rejected'">
      <div class="refund-info-item refund-money-important">
        <div
          class="refund-info-key"
          v-if="
            order_info.refund_status === 'waiting' || !order_info.refund_status
          "
        >
          预计退款金额
        </div>
        <div
          class="refund-info-key"
          v-if="order_info.refund_status === 'agreed'"
        >
          实际退款金额
        </div>
        <div class="refund-info-value">
          ¥{{ order_info.estimated_refund_amount }}
        </div>
      </div>
      <div class="refund-info-item">
        <div class="refund-info-key">付款金额</div>
        <div class="refund-info-value">¥{{ order_info.order_price }}</div>
      </div>
      <div class="refund-info-item" @click="showUse = !showUse">
        <div class="refund-info-key">已使用权益</div>
        <div class="refund-info-value">-¥{{ use_amount }}</div>
        <img
          :class="showUse ? 'refund-info-icon active' : 'refund-info-icon'"
          :src="require('../../assets/images/icon-right.png')"
        />
      </div>
      <div class="refund-info-item-info" v-if="showUse">
        <div
          class="refund-info-item"
          v-for="item in refund_info"
          :key="item.cost_name"
        >
          <div class="refund-info-key">{{ item.cost_name }}</div>
          <div class="refund-info-value">-¥{{ item.cost_amount }}</div>
        </div>
      </div>
    </div>
    <div class="refund-service" v-if="order_info.refund_status === 'rejected'">
      如有问题联系客服
    </div>
    <div class="refund-detail-height"></div>
    <div
      class="refund-detail-btn"
      v-if="behavior === 'submit' && refund_order_btn"
    >
      <div
        class="refund-detail-btn1"
        @click="toIndex"
        v-ans="{
          event_id: 'refund_detail_button1',
          namespace,
          order_number: $route.query.order_number,
        }"
      >
        我再想想
      </div>
      <div
        class="refund-detail-btn2"
        @click="confirm"
        v-ans="{
          event_id: 'refund_detail_button2',
          namespace,
          order_number: $route.query.order_number,
        }"
      >
        确认退款
      </div>
    </div>
  </div>
</template>

<script>
import { refundDetail, getrefundList } from "../../apis/refund";
import { getMemberInfo } from "../../apis";
import { formatTime } from "../../utils";
import { mapState } from "vuex";
import refundStatusBar from "@/components/refundStatusBar";
import * as dayjs from "dayjs";

export default {
  name: "refundDetail",
  data() {
    return {
      pageshow: false,
      refund_order: "",
      refund_order_btn: true,
      order_info: {},
      showUse: false,
      use_amount: "",
      refund_info: [],
      behavior: "",
      user_phone: "",
      member_info: {},
    };
  },
  components: {
    refundStatusBar,
  },
  computed: {
    ...mapState(["refund_detail", "namespace", "return_url", "path"]),
    text() {
      // 提交退款详情文案
      if (this.behavior !== "view") {
        return `提交退款申请后请勿再领权益，否则将会计入扣除金额。实际退款金额与实际到账金额为准，预计${this.$getConfigValue(
          "REFUND_DAY"
        )}天内(${dayjs()
          .add(7, "day")
          .format("YYYY-MM-DD HH:mm:ss")}前)审核完成。`;
      }

      // 查看退款详情文案
      if (
        this.behavior === "view" &&
        this.order_info.refund_status === "waiting"
      ) {
        return `提交退款申请后请勿再领权益，否则将会计入扣除金额。实际退款金额与实际到账金额为准，预计${
          dayjs(this.order_info.t_audit).startOf("day").diff(dayjs(), "day") + 1
        }天内(${dayjs(this.order_info.t_audit)
          .add(7, "day")
          .format("YYYY-MM-DD HH:mm:ss")}前)审核完成。`;
      }

      if (
        this.behavior === "view" &&
        this.order_info.refund_status === "agreed"
      ) {
        return "款项会原路退回至支付账户，一般1-3个工作日到账，以实际到账时间为准";
      }

      if (
        this.behavior === "view" &&
        this.order_info.refund_status === "rejected"
      ) {
        return this.order_info.remark;
      }
      return "";
    },
  },
  filters: {
    diffDay(start, endDate) {
      return dayjs(start).diff(endDate, "day");
    },
  },
  async mounted() {
    const { behavior, phone, order_number } = this.$route.query;
    //behavior=view 查看已提交的申请详情
    //behavior=submit  提交申请
    this.behavior = behavior;
    phone && (this.user_phone = phone);
    //查看已经提交过的审核详情
    if (behavior === "view") {
      this.order_info = this.refund_detail;
      this.order_info["t_audit"] = formatTime(
        this.refund_detail.t_created +
          Number(this.$getConfigValue("REFUND_DAY")) * 24 * 60 * 60 * 1000,
        true
      );
      this.order_info["t_created"] = formatTime(
        this.refund_detail.t_created,
        true
      );
      this.order_info["phone_number"] =
        this.order_info["phone_number"] === "0"
          ? ""
          : this.order_info["phone_number"];
      this.refund_info = this.refund_detail.refund_detail;
      if (this.order_info.refund_status === "agreed") {
        this.use_amount = this.refund_detail.actual_cost;
      } else {
        this.use_amount = this.refund_detail.estimated_cost;
      }
      this.pageshow = true;
      return;
    }
    //如果是提交表单过来的则调用接口查询信息
    const toast = this.$loading();
    try {
      let opts = { order_number };
      const detail = await refundDetail(opts);
      const res = await getrefundList({ audit_status: "waiting" });
      if (res.data.data_list.length) {
        const local = res.data.data_list.filter(
          (r) => r.order_number === detail.data.order_number
        );
        if (local.length) {
          this.refund_order_btn = false;
        }
      }
      this.order_info = detail.data;
      this.refund_info = [
        ...detail.data.partner_cost_data_list,
        ...detail.data.oak_cost_data_list,
      ];
      this.use_amount = detail.data.estimated_cost;
      this.pageshow = true;
      const memberInfo = await getMemberInfo();
      this.member_info = memberInfo;
    } finally {
      toast.clear();
    }
  },
  methods: {
    //确认退款
    async confirm() {
      const { reason, phone, order_number } = this.$route.query;
      let opts = {
        refund_reason: reason,
        phone_number: phone,
        is_save: "T",
        order_number,
      };
      const toast = this.$loading();
      try {
        const res = await refundDetail(opts);
        if (res.ok) {
          this.refund_order_btn = false;
          this.$router.push(`/refundResult?order_number=${order_number}`);
        } else {
          this.$router.push("/refundResult?status=refund_failed");
        }
      } finally {
        toast.clear();
      }
    },
    toService() {
      window.location.href = this.member_info.service_url;
    },
    toIndex() {
      if (this.return_url) {
        this.$jumpUrl(this.path?.replace("#", ""));
      }
    },
  },
};
</script>

<style lang='less' scoped>
.refund-detail {
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 1px;
}
.refund-hint {
  background-color: #ffe9cd;
  padding: 14px 15px;
  font-size: 13px;
  color: #333;
  line-height: 18px;
  display: flex;
  align-items: center;
}
.refund-hint-rejected {
  background-color: #ffe4dd;
  padding: 0 15px;
  min-height: 64px;
  font-size: 13px;
  color: #333;
  line-height: 18px;
  display: flex;
  align-items: center;
}
.refund-info {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
}
.refund-info-title {
  font-size: 16px;
  color: #333;
}
.refund-info-item {
  display: flex;
  font-size: 14px;
  color: #333;
  margin-top: 15px;
  position: relative;
}
.refund-info-key {
  width: 110px;
}
.refund-money {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 10px;
  padding: 1px 15px 15px;
}
.refund-money-title {
  font-size: 12px;
  color: #999;
}
.refund-money-important {
  color: #ff4242;
}
.refund-money-important .refund-info-value {
  font-weight: bold;
}
.refund-info-item-info .refund-info-item .refund-info-key {
  color: #999;
}
.refund-info-icon {
  width: 6px;
  height: 10px;
  position: absolute;
  right: 3px;
  top: 5px;
  transition: all linear 0.2s;
}
.refund-info-icon.active {
  transform: rotateZ(90deg);
}
.refund-detail-height {
  height: 65px;
}
.refund-detail-btn {
  box-sizing: border-box;
  background-color: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 9;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}
.refund-detail-btn1 {
  text-align: center;
  width: 158px;
  height: 49px;
  line-height: 49px;
  border-radius: 25px;
  background-color: #222;
  font-size: 18px;
  color: #ffd092;
  font-weight: bold;
}
.refund-detail-btn2 {
  box-sizing: border-box;
  text-align: center;
  width: 158px;
  height: 49px;
  line-height: 49px;
  border-radius: 25px;
  font-size: 18px;
  color: #999;
  border: 1px solid #999999;
}
.refunding-title {
  color: #333;
  font-size: 18px;
}
.refunding-desc {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
  margin-bottom: 15px;
}
.refunding-text {
  color: #0091ff;
}
.refund-service {
  text-align: center;
  font-size: 13px;
  color: #999;
  margin-top: 20px;
}
</style>
