<template>
  <div class="wrap">
    <div class="content" v-for="item of loopList" :key="item.text">
      <div class="img-wrap">
        <img :src="item.logo_url" class="avatar" />
        <img src="@/assets/images/unxp-loop-icon.png" class="icon" />
      </div>
      {{ item.text }}
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "@vue/composition-api";

import { queryLoopData } from "@/apis/home";
import { useStore } from "@/components/use/base";

export default defineComponent({
  setup() {
    const store = useStore();
    const namespace = store.state.namespace;

    const loopList = ref([]);

    const getLoopListData = async () => {
      const { data } = await queryLoopData({ activity_type: namespace });
      loopList.value = data.data_list;
    };

    onMounted(async () => {
      try {
        await getLoopListData();
      } catch (e) {
        console.log(e);
      }
    });

    return { loopList };
  },
});
</script>

<style scoped lang="less">
.wrap {
  display: flex;
  z-index: 1;
  left: 100%;
  animation: swiper 50s linear 1s;
}
.content {
  display: flex;
  align-items: center;
  background: #000000;
  border-radius: 12px;
  opacity: 0.7;
  height: 18px;
  padding: 0 6px 0 2px;
  white-space: nowrap;
  font-size: 10px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #ffffff;
  line-height: 18px;
  margin-right: 24px;
}
.img-wrap {
  width: 16px;
  height: 16px;
  margin-right: 3px;
  position: relative;
  > .avatar {
    width: 16px;
    height: 16px;
    background: #ffffff;
    border-radius: 50%;
  }
  > .icon {
    width: 9px;
    height: 9px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

@keyframes swiper {
  100% {
    transform: translateX(-200%);
  }
}
</style>
