<template>
  <div v-if="pageshow">
    <img class="apply-img" :src="applyPic" />
    <div
      :class="
        partner_data.data && !partner_data.is_offer
          ? 'apply-height'
          : 'apply-height2'
      "
    ></div>
    <div
      class="refund-apply-main"
      v-if="partner_data.data && !partner_data.is_offer"
    >
      <div
        class="refund-apply-btn refund-apply-btn2"
        v-ans="{
          event_id: 'refund_apply_button1',
          namespace,
          order_number: $route.query.order_number
        }"
        @click="toForm"
      >
        继续申请，永久注销VIP身份 &gt;
      </div>
      <div
        class="get-benefit-btn"
        v-if="['sy', 'xy'].includes(namespace)"
        v-ans="{
          event_id: 'refund_apply_button2',
          namespace,
          order_number: $route.query.order_number
        }"
        @click="getBenefit"
      >
        领取权益，并取消退款申请
      </div>
    </div>
    <div
      class="refund-apply-btn"
      v-else
      v-ans="{
        event_id: 'refund_apply_button1',
        namespace,
        order_number: $route.query.order_number
      }"
      @click="toForm"
    >
      继续申请，永久注销VIP身份 &gt;
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { sendOffer, queryOakLifeRenewable } from "../../apis/refund";
export default {
  name: "refundApply",
  data() {
    return {
      pageshow: false,
      retain: false,
      enable_renew: false,
    };
  },
  components: {},
  computed: {
    ...mapState(["refund_unique_id", "partner_data", "namespace"]),
    applyPic() {
      if (!this.retain) {
        return this.partner_data.data
          ? `https://cdn.xiangshuheika.com/static/${this.namespace}/offer1.png`
          : `https://cdn.xiangshuheika.com/static/${this.namespace}/noretain.png`;
      } else {
        if (this.partner_data.is_offer) {
          return `https://cdn.xiangshuheika.com/static/${this.namespace}/has_offer.png`;
        }
        return this.enable_renew
          ? `https://cdn.xiangshuheika.com/static/${this.namespace}/offer2-life.png`
          : `https://cdn.xiangshuheika.com/static/${this.namespace}/offer2.png`;
      }
    },
  },
  async mounted() {
    const { retain } = this.$route.query;
    this.retain = retain;
    // 小赢会员查询是否可开通省钱卡会员
    if (retain) {
      //查询省钱卡是否可续费
      const res3 = await queryOakLifeRenewable({
        unique_id: this.refund_unique_id,
      });
      this.enable_renew = res3.data.enable_renew;
    }
    this.pageshow = true;
  },
  methods: {
    //领取权益确认按钮
    async confirmHandle() {
      const { order_number } = this.$route.query;
      const toast = this.$loading();
      let opts = { order_number };
      if (!this.retain) {
        opts.offer_type = "shengbi1";
      } else {
        if (this.namespace === "xy") {
          opts.offer_type = "XY_LIFE12-member_init,year_card";
        } else {
          opts.offer_type = this.enable_renew ? "saveMoneyCard" : "shengbi2";
        }
      }
      //给用户发放权益
      try {
        const res = await sendOffer(opts);
        this.showDialog = false;
        if (res.data.partner_data.data) {
          this.$router.replace("/refundResult?status=benefit_success");
        } else {
          this.$router.replace(
            `/refundResult?status=benefit_failed&msg=${res.data.partner_data.msg}`
          );
        }
      } finally {
        toast.clear();
      }
    },

    toForm() {
      const { order_number, retain, phone, reason } = this.$route.query;
      if (retain === "twice") {
        this.$router.push(
          `/refundDetail?behavior=submit&order_number=${order_number}&phone=${phone}&reason=${reason}`
        );
        return;
      }
      //detainment:是否是挽留用户
      this.$router.push(
        `/refundForm?detainment=${this.partner_data.data}&order_number=${order_number}`
      );
    },
    getBenefit() {
      if (!this.enable_renew && this.namespace === "xy") {
        this.$toast("您的省钱卡开通次数已达上限");
        return;
      }
      this.$confirm({
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        message: `权益领取成功后，将不可再次申请退款，并计入权益使用成本，是否继续领取？`,
      })
        .then(() => {
          this.confirmHandle();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang='less' scoped>
.refund-apply-btn {
  position: fixed;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #fff;
  left: 0;
  bottom: 0;
  font-size: 13px;
  color: #333;
  text-decoration: underline;
  z-index: 9;
}
.refund-apply-main {
  position: fixed;
  width: 100%;
  text-align: center;
  background-color: #fff;
  padding-bottom: 10px;
  left: 0;
  bottom: 0;
  z-index: 9;
}
.refund-apply-btn2 {
  position: static;
  background-color: #f5f5f5;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
}
.get-benefit-btn {
  margin: 0 20px;
  height: 49px;
  line-height: 49px;
  border-radius: 25px;
  background-color: #222;
  font-size: 18px;
  color: #ffd092;
  font-weight: bold;
}
.no-apply {
  padding-top: 136px;
  text-align: center;
  font-size: 16px;
  color: #333;
}
.no-apply-img {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto 18px;
}
.apply-img {
  width: 100%;
}
.apply-height {
  height: 99px;
}
.apply-height2 {
  height: 40px;
}
</style>
