var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order"},[_c('div',{staticClass:"order-tab"},[_c('van-tabs',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"全部"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{directives:[{name:"ans",rawName:"v-ans",value:({
              event_id: 'order_list_click_tab',
              tab_name: '全部',
            }),expression:"{\n              event_id: 'order_list_click_tab',\n              tab_name: '全部',\n            }"}]},[_vm._v("全部")])]},proxy:true}])}),_c('van-tab',{attrs:{"title":"已完成"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{directives:[{name:"ans",rawName:"v-ans",value:({
              event_id: 'order_list_click_tab',
              tab_name: '已完成',
            }),expression:"{\n              event_id: 'order_list_click_tab',\n              tab_name: '已完成',\n            }"}]},[_vm._v("已完成")])]},proxy:true}])}),_c('van-tab',{attrs:{"title":"已取消"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{directives:[{name:"ans",rawName:"v-ans",value:({
              event_id: 'order_list_click_tab',
              tab_name: '已取消',
            }),expression:"{\n              event_id: 'order_list_click_tab',\n              tab_name: '已取消',\n            }"}]},[_vm._v("已取消")])]},proxy:true}])})],1)],1),(_vm.empty)?_c('van-empty',{staticClass:"empty",attrs:{"image":require('@/assets/images/order_empty.svg'),"description":"暂无最近订单"}}):_c('div',{staticClass:"orders"},_vm._l((_vm.list),function(item){return _c('div',{key:item.order_number,staticClass:"order-item"},[_c('div',{staticClass:"order-top"},[_c('div',{staticClass:"order-name ellipsis",on:{"click":function($event){return _vm.toBenefitDetail(item.type)}}},[_vm._v(" "+_vm._s(item.type === "hotel" ? "5-8折住酒店" : item.name)+" ")]),_c('div',{staticClass:"order-status"},[_vm._v(_vm._s(_vm.getStateText(item)))])]),_c('van-divider',{style:({ borderColor: '#F5F5F5', padding: '10px 0', margin: 0 })}),_c('div',{staticClass:"order-info",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('van-image',{staticClass:"order-image",attrs:{"lazy-load":"","fit":"contain","src":_vm.getImageUrl(item),"data-src":_vm.getImageUrl(item)}}),_c('div',{staticClass:"order-content"},[_c('div',{staticClass:"order-desc"},[_vm._v(" "+_vm._s(item.type === "hotel" ? "预订" : "")+_vm._s(item.desc || item.name)+" ")]),_c('div',{staticClass:"order-price"},[_c('sub',[_vm._v("¥")]),_vm._v(_vm._s(item.type === "ssaw" || item.type === "healthcare" ? "免费领取" : item.amount)+" "),_c('span',{staticClass:"order-time"},[_vm._v(_vm._s(_vm._f("formatDate")(item.t_created,"YYYY-MM-DD HH:mm:ss")))])])])],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }