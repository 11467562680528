<template>
  <van-image-preview
    class="image-preview"
    v-model="visible"
    :overlay-style="{ background: '#010101' }"
    :images="images"
    closeable
    @close="close"
    :start-position="index"
    :close-icon="require('@/assets/images/close.png')"
    @change="current = $event"
    close-icon-position="top-left"
  >
    <template>
      <div class="indicator" slot="index">
        <template v-if="images.length > 1">
          <span>{{ current + 1 }}</span
          >/{{ images.length }}
        </template>
      </div>
    </template>
  </van-image-preview>
</template>

<script>
import { ImagePreview } from "vant";

export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  model: {
    prop: "show",
    event: "change",
  },
  data() {
    return {
      current: 0,
      visible: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    images: Array,
  },
  methods: {
    close() {
      this.$emit("change", false);
    },
  },
  watch: {
    index(val) {
      this.current = val;
    },
    show(val) {
      this.visible = val;
    },
  },
};
</script>

<style lang="less" scoped>
.image-preview {
  /deep/ .van-image-preview__close-icon {
    padding: 4px;
    font-size: 16px;
  }
  /deep/ .van-image-preview__index {
    bottom: 70px;
    top: initial;
    .indicator {
      font-size: 11px;
      color: #fff;
      span {
        font-size: 16px;
      }
    }
  }
}
</style>
