var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-footer-fill"},[(
      _vm.toCouponUse ||
      _vm.showCancelButton ||
      _vm.showService ||
      _vm.showAfterMarket ||
      _vm.showIndex
    )?_c('div',{staticClass:"order-footer"},[(_vm.showCancelButton)?_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'order_detail_click_cancel',
        order_id: _vm.detail.order_number,
        order_type: _vm.detail.type,
        order_time: _vm.detail.t_created,
        namespace: _vm.namespace,
      }),expression:"{\n        event_id: 'order_detail_click_cancel',\n        order_id: detail.order_number,\n        order_type: detail.type,\n        order_time: detail.t_created,\n        namespace,\n      }"}],staticClass:"footer-btn",attrs:{"size":"small","round":""},on:{"click":_vm.cancelOrder}},[_vm._v(" "+_vm._s(_vm.detail.type === "hljyk" ? "取消预约" : "取消订单")+" ")]):_vm._e(),(_vm.showService)?_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'order_detail_click_qa',
        order_id: _vm.detail.order_number,
        order_type: _vm.detail.type,
        order_time: _vm.detail.t_created,
        namespace: _vm.namespace,
      }),expression:"{\n        event_id: 'order_detail_click_qa',\n        order_id: detail.order_number,\n        order_type: detail.type,\n        order_time: detail.t_created,\n        namespace,\n      }"}],staticClass:"footer-btn",on:{"click":_vm.toService}},[_vm._v(" 联系客服 ")]):_vm._e(),(_vm.showAfterMarket)?_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'benefit_detail_click_button4',
        order_id: _vm.detail.order_number,
        flow_status: _vm.flow.flow_status,
        namespace: _vm.namespace,
      }),expression:"{\n        event_id: 'benefit_detail_click_button4',\n        order_id: detail.order_number,\n        flow_status: flow.flow_status,\n        namespace,\n      }"}],staticClass:"footer-btn",attrs:{"size":"small","round":""},on:{"click":_vm.afterMarket}},[_vm._v(" "+_vm._s(_vm.afterMarketText)+" ")]):_vm._e(),(_vm.showIndex)?_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'order_detail_click_back',
        order_id: _vm.detail.order_number,
        order_type: _vm.detail.type,
        order_time: _vm.detail.t_created,
        namespace: _vm.namespace,
      }),expression:"{\n        event_id: 'order_detail_click_back',\n        order_id: detail.order_number,\n        order_type: detail.type,\n        order_time: detail.t_created,\n        namespace,\n      }"}],staticClass:"footer-btn",on:{"click":_vm.toIndex}},[_vm._v(" 返回首页 ")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }