<template>
  <section class="unxp" v-if="!fetching">
    <Header :card="card" />
    <section class="benefit" v-if="card.card_status === 'active'">
      <div class="page-benefits">
        <FreeBenefits />
        <component
          :is="item.display_code"
          :benefit="item"
          :key="idx"
          v-for="(item, idx) in catesgorys"
        />
      </div>
    </section>
    <div v-else-if="card.card_status === 'expired'" class="card-past">
      <van-image :src="require('@/assets/images/yh-empty.png')" />
      <p>您的权益已过期</p>
    </div>
    <div v-else-if="card.card_status === 'refund'" class="card-past">
      <van-image :src="require('@/assets/images/yh-empty.png')" />
      <p>您的权益已退款</p>
    </div>
    <div class="footer">
      <div class="item" @click="$router.push('/order')">
        <van-image
          class="img"
          :src="require('@/assets/images/icon-order2.png')"
        />
        我的订单
      </div>
      <div class="item" @click="$router.push('/service')">
        <van-image class="img" :src="require('@/assets/images/server.png')" />
        在线客服
      </div>
    </div>
  </section>
</template>

<script>
import { useStore } from "@/components/use/base";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import Vue from "vue";

import useMemberBenefitCategory from "@/use/useMemberBenefitCategory";

import Header from "./memberComponent/Header";
import FreeMediaBenefit from "./memberComponent/FreeMediaBenefit";
import FreeBenefits from "./memberComponent/FreeBenefits";
import SwipeFloor from "./memberComponent/SwipeFloor";
import FreeWelfare from "./memberComponent/FreeWelfare";

const vm = Vue.prototype;

let first = true;
export default defineComponent({
  components: {
    Header,
    FreeMediaBenefit,
    FreeBenefits,
    SwipeFloor,
    FreeWelfare,
  },
  setup() {
    const store = useStore();
    const fetching = ref(true);
    const firstInit = ref(first);

    const { card, catesgorys, getProductList } = useMemberBenefitCategory();

    const isActiveFloor = computed(() => {
      return (display_code) => {
        return display_code === "unxp_addbenefit_v2" ? "activeFloor" : "floor";
      };
    });

    onMounted(async () => {
      const loading = vm.$loading();
      try {
        await getProductList();
      } finally {
        fetching.value = false;
        loading.clear();
        first = false;
      }
    });
    return {
      fetching,
      card,
      user_phone: store.state.userInfo.login_phone_number,
      isActiveFloor,
      firstInit,
      catesgorys,
    };
  },
});
</script>


<style lang="less" scoped>
.unxp {
  padding: 12px;
}

.page-benefit {
  background: #fff;
  border-radius: 10px;
  padding: 16px 12px 0px;
  & + .page-benefit {
    margin-top: 12px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  .item {
    background-color: #fff;
    height: 55px;
    border-radius: 10px;
    width: 49%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #333;

    .img {
      width: 22px;
      height: 22px;
      margin-right: 4px;
    }
  }
}

.activity-overlay-image {
  width: 360px;
}

.activity-sidebar-entry {
  position: fixed;
  right: 0;
  top: 70%;
}

.activity-sidebar-entry-image {
  width: 72px;
}
</style>