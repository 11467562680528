const LANG_CONFIG = {
  title: {
    en: 'Airport Lounge Access',
    cn: "机场贵宾厅权益"
  },
  desc: {
    en: "1 member",
    cn: "1会员"
  },
  name: {
    en: "Name",
    cn: "姓名"
  },
  code: {
    en: "Barcode Reference Number",
    cn: '二维码参考编号'
  },
  start: {
    en: "Start Date",
    cn: "开始日期"
  },
  end: {
    en: "Expiry Date",
    cn: "截止日期"
  },
  conditions: {
    en: "SUMMARY OF CONDITIONS",
    cn: '用户须知'
  },
  list1: {
    en: '- QR code and Barcode reference number refreshes every 60 seconds',
    cn: "1.二维码和二维码参考编号每60秒刷新一次；"
  },
  list2: {
    en: "- Member's name presented oon this Airport Lounge Access must match the name presented on the member's boarding pass.In the event tht the names are mismatched due to differences between the Chinese name and the Pinyin name,the member should contact Joyful Journey customer services to make necessary changes before presenting the Airport Lounge Access to lounges again",
    cn: "2.会员在机场贵宾厅权益上的姓名需与登机牌上的姓名一致。若由于中文与拼音原因导致姓名不一致，会员需要联系悦途出行客服作必要修改后，方可再次出示机场贵宾厅权益以使用贵宾室；"
  },
  list3: {
    en: "- All accompanying guest(s) must enter the lounge and register with the member at the same time.Please note that once transaction has been made,lounge staff will not be able to void or make any changes on the same transaction.",
    cn: "3.所有随行宾客必须与会员一同登记进入贵宾室。请注意：到访记录一经登记，贵宾室员工将无法撤销或修改相关到访记录；"
  },
  list4: {
    en: "- The QR code is best accepted on a mobile device with maximum screen brightness",
    cn: "4.将移动设备的屏幕亮度调至最高以更好地扫描二维码；"
  },
  list5: {
    en: "- In the event that the QR code cannot be scanned,lounge staff may manually enter the 16-digit Barcode reference number above for validation.",
    cn: '5.若二维码无法扫描，贵宾室员工可以手动输入上方16位二维码参考编号以进行验证；'
  },
  list6: {
    en: "- For any questions,please contact Joyful Journey on TEL:(+86)4009209966[Service time 8:00-20:00(UTC+8)]",
    cn: "6.若有任何问题，请致电悦途出行客服：(+86)4009209966[服务时间：08:00-20:00(北京时间)]。"
  },
  list7: {
    en: "Click here for the full conditions of use.",
    cn: "点击此处以查看完整的使用条款。"
  }
}

export { LANG_CONFIG }