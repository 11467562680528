
import { Toast } from 'vant'
import { creatSign, storage } from '@/utils';
import store from '@/store';
import { ENV_FLAG, IS_PROD, agentConfig, baseApi, getBaseUrl, pay_api } from '@/config';
import pick from 'lodash.pick';
import { createPay } from '@/apis';
import qs from 'qs'

export const toPayV2 = async (memberPackage, channel, otherOpt = {}) => {
  const user_token = storage.getItem("token");
  if (!user_token) {
    Toast("你还未登录，请先登录");
    return;
  }

  if (!channel) {
    Toast("支付方式不存在");
    return;
  }
  if (!memberPackage.item_code) {
    Toast("套餐不存在");
    return;
  }
  const url = `${window.location.origin}/pages/pay_result/pay_result?category_type=vip`;

  const out_order_id = `${memberPackage.item_code.toUpperCase().replace(/\d/g, '')}${navigator.productSub
    }${new Date().getTime()}`;
  const return_url = encodeURIComponent(url);
  let date = new Date().getTime();

  const opts = {
    user_token: user_token,
    partner_jump_url: return_url,
    order_desc: encodeURIComponent(memberPackage.desc),
    benefit_source: "会员权益",
    platform: "web",
    item_code: memberPackage.item_code,
    channel,
    order_source: encodeURIComponent(store.state.namespaceInfo.name_cn),
    order_name: memberPackage.name,
    enable_auto_jump: 1,
    namespace: store.state.namespace,
    is_prod: IS_PROD,
    agent_id: agentConfig("common"),
    timestamp: date,
    source: store.state.source,
    out_order_id,
    project_url: encodeURIComponent(getBaseUrl(store.state.namespace)),
    envflag: ENV_FLAG(store.state.namespace),
    partner_url: return_url,
    openid: storage.getItem("openid"),
    ...otherOpt,
  };
  if (memberPackage.isRenew) {
    opts.isPaymentSigning = 1;
  }
  opts.sign = encodeURIComponent(
    creatSign(
      "common",
      pick(opts, [
        "platform",
        "item_code",
        "channel",
        "partner_jump_url",
        "agent_id",
        "timestamp",
        "user_token",
        "benefit_source",
        "activity",
      ])
    )
  );
  const useragent = navigator.userAgent;
  if (useragent.indexOf("miniProgram") !== -1) {
    // 获取下单参数，跳转到微信小程序进行支付
    const res = await createPay(opts);
    if (res.data.package === "prepay_id=None") {
      Toast("生成微信预支付订单失败");
      return;
    }
    const params = res.data;
    params.backUrl =
      location.href.split("#")[0] +
      `#/payResult?order_number=${params.order_number}&category_type=vip&order_source=miniapp`;
    wx.miniProgram.redirectTo({
      url: `${store.getters.getConfigValue(
        "WECHAT_MIN_PAY_URL"
      )}?payParam=${encodeURIComponent(JSON.stringify(params))}`,
    });
    return;
  }

  const PAY_API_PATH = store.getters.getConfigValue("PAY_API_PATH");
  const origin = PAY_API_PATH
    ? baseApi.common + PAY_API_PATH
    : pay_api;
  window.location.href = `${origin}?${qs.stringify(opts, {
    encode: false,
  })}`;
}