<template>
  <keep-alive exclude="hotelBook" v-if="!loading">
    <router-view :key="$route.fullPath"></router-view>
  </keep-alive>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import pickBy from "lodash.pickby";

export default {
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(["logining", "namespace", "hotel"]),
  },

  async mounted() {
    const { benefitType } = this.$route.query;
    this.changeState(
      pickBy(
        {
          benefitHotelType: benefitType,
        },
        (v) => v !== undefined
      )
    );

    await this.getHotelDays();

    if (!this.hotel.hotel_advance_range) {
      try {
        await this.getHotelInfo();
      } finally {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(["getHotelInfo", "getHotelDays"]),
    ...mapMutations(["changeState"]),
  },
};
</script>

<style scoped lang='less'>
</style>