<template>
  <div class="wrap">
    <div class="title">问大家<span>1.8万人也在问</span></div>
    <div class="card" v-for="card of cardList" :key="card.title">
      <div class="card-title">{{ card.title }}</div>
      <div class="content">
        <div class="item" v-for="item of card.children" :key="item.phone">
          <div class="item-title">
            {{ item.phone }}<span>{{ item.member }}</span>
          </div>
          <div class="item-content">
            {{ item.answer }}
          </div>
        </div>
      </div>
    </div>
    <div class="more">- 更多用户评价 -</div>
    <div class="more-card">
      <div class="item more-item" v-for="item of moreList" :key="item.phone">
        <div class="item-title">
          {{ item.phone }}<span>{{ item.member }}</span>
        </div>
        <div class="item-content">
          {{ item.answer }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import { useStore } from "@/components/use/base";

export default defineComponent({
  setup() {
    const store = useStore();

    const cardList = ref([]);
    const moreList = ref([]);

    onMounted(() => {
      moreList.value = JSON.parse(
        store.getters.getConfigValue("UNXP_QUESTION_MORE")
      );
      cardList.value = JSON.parse(
        store.getters.getConfigValue("UNXP_QUESTION_LIST")
      );
    });

    return { cardList, moreList };
  },
});
</script>

<style lang="less" scoped>
.wrap {
  background: #f6f6f6;
  padding: 20px 16px;
  min-height: 100vh;
  .title {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    margin-bottom: 20px;
    > span {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-left: 5px;
    }
  }
  .card {
    margin-block: 12px;
    border-radius: 8px;
    .card-title {
      padding: 11px 16px;
      background: linear-gradient(80deg, #fef7ef 0%, #fcecd5 100%);
      border-radius: 8px 8px 0px 0px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
    }
    .content {
      padding: 13px 16px 15px 16px;
      background: #ffffff;
      border-radius: 0 0 8px 8px;

      .item {
        margin-bottom: 21px;
      }
    }
  }
  .more {
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
    text-align: center;
    margin-top: 18px;
    margin-bottom: 8px;
  }
  .more-card {
    padding: 15px 16px;
    background: #ffffff;
    border-radius: 8px;
  }
  .item-title {
    margin-bottom: 7px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
    > span {
      font-size: 12px;
      font-weight: 400;
      color: #ac6428;
      line-height: 17px;
      margin-left: 5px;
    }
  }
  .item-content {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    word-break: break-all;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
  }
  .more-item {
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
}
</style>