<template>
  <van-swipe
    class="hotel-banner"
    :autoplay="3000"
    @change="current = $event"
    indicator-color="white"
  >
    <van-swipe-item v-for="(item, idx) in images" :key="idx">
      <van-image fit="cover" class="hotel-img" lazy-load :src="item" />
    </van-swipe-item>
    <template #indicator>
      <div class="custom-indicator-box">
        <div class="custom-indicator" v-if="images.length > 1">
          <div
            v-for="(item, index) in images"
            :key="index"
            :class="{
              'custom-indicator-item': true,
              active: index === current,
              active_1: index === current - 1,
              active_2: index === current - 2,
              active1: index === current + 1,
              active2: index === current + 2,
            }"
          ></div>
        </div>
      </div>
    </template>
  </van-swipe>
</template>

<script>
export default {
  props: {
    hotelId: Number,
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: 0,
    };
  },
  watch: {
    current(val) {
      const el = document.querySelector(".custom-indicator");
      if (val > 2) {
        el.style.transform = `translateX(-${(val - 2) * 10}px)`;
      } else if (val === 0) {
        el.style.transform = `translateX(0)`;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.hotel-banner {
  height: 184px;
  position: relative;
}
.custom-indicator-box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 18px;
  z-index: 100;
  width: 50px;
  overflow: hidden;
}
.custom-indicator {
  height: 6px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  transition: all 0.3s ease-in-out;
}

.custom-indicator-item {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  position: relative;
  padding: 0 2px;
  &::before {
    content: "";
    border-radius: 50%;
    background: #fff;
    opacity: 0.5;
    position: absolute;
    width: 3px;
    height: 3px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &.active {
    &::before {
      width: 6px;
      height: 6px;
      opacity: 1;
    }
  }
  &.active_1,
  &.active1 {
    &::before {
      width: 4px;
      height: 4px;
    }
  }
}
</style>