<template>
  <CommonPopup :show.sync="show" :showClose="false">
    <div class="wrap">
      <div class="title" v-if="takedownConfig.title">
        {{ takedownConfig.title }}
      </div>
      <div class="content" v-if="takedownConfig.content">
        {{ takedownConfig.content }}
      </div>
      <div class="time" v-if="takedownConfig.publishTime">
        {{ takedownConfig.publishTime }}
      </div>
      <div class="btn" @click="showTakedown(false)" v-if="cantClose">确认</div>
    </div>
  </CommonPopup>
</template>

<script>
import Vue from "vue";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import dayjs from "dayjs";

import CommonPopup from "@/components/CommonPopup";
import { getCurrentTime } from "@/apis/hotel";
import { jsonSafeParse } from "@/utils/index";
import { useStore } from "@/components/use/base";

const vm = Vue.prototype;

export default defineComponent({
  components: {
    CommonPopup,
  },
  props: {
    // 是否自动打开
    mountedShow: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const show = ref(false);
    const canShow = ref(false); // 根据配置判断是否展示弹窗
    const cantClose = ref(true); // 是否不能关闭，根据时间判断，可用此值判断能否行权
    const takedownConfig = ref({});
    const store = useStore();
    const namespace = store.state.namespace;

    const getTime = async () => {
      const config =
        jsonSafeParse(vm.$getConfigValue("TAKEDOWN_POPUP"))[namespace] || {};
      takedownConfig.value = config;
      if (!config.time) return;
      const { data } = await getCurrentTime();
      const ceaseDate = dayjs(config.time);
      const currentDate = dayjs(data.current_time);
      cantClose.value = currentDate.isBefore(ceaseDate);
    };

    const showPopup = () => {
      const showTakedown = vm.$getConfigValue("TAKEDOWN_POPUP_SHOW");
      canShow.value = showTakedown;
    };

    const showTakedown = (value = true) => {
      show.value = value;
    };

    onMounted(async () => {
      await getTime();
      showPopup();
      props.mountedShow && canShow.value && showTakedown();
    });

    return {
      show,
      cantClose,
      takedownConfig,
      canShow,
      showTakedown,
    };
  },
});
</script>

<style lang="less" scoped>
.wrap {
  background: linear-gradient(180deg, #fbead6 0%, #fff 50%);
  padding: 27px 24px;
  width: 320px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  .title {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .time {
    font-size: 14px;
    text-align: right;
  }
}

.btn {
  margin-top: 16px;
  background: linear-gradient(115deg, #efd2ae 0%, #ffe8c9 100%);
  border-radius: 30px;
  width: 100%;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}
</style>
