<template>
  <van-overlay :show="show" v-bind="$attrs">
    <div class="overlay" @click.stop>
      <slot></slot>
      <div class="overlay-close" @click="close">
        <svg-icon iconClass="close"></svg-icon>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  inheritAttrs:false,
  name: "oakvipOverlay",
  model: {
    prop: "show",
    event: "change",
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("change", false);
    },
  },
};
</script>

<style lang="less" scoped>
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.overlay-close {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 14px;
  line-height: 35px;
  text-align: center;
  margin-top: 50px;
}
</style>