import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['isMember', 'userInfo', 'isMiniProgram', 'isWebchat']),
  },
  methods: {
    ...mapActions(['fetchUserInfo']),
    async toDetail(item) {

      // 跳转到第三方页面
      if (item.jump_method === 'spu') {
        this.$router.push(`/benefitDetail?benefit_type=${item.product_code}`)
      } else if (item.jump_method === 'partner' || item.jump_method === 'h5') {
        if (!item.partner_jump_url) {
          return;
        }
        if (!this.isMember) {
          this.$toast("您还不是会员");
          return;
        }
        window.location.href = item.partner_jump_url;
      }
    },
  },
}