
export default {
  state: {
    isShowTabbar: false
  },
  mutations: {
    changePagjState(state, payload) {
      for (let k in payload) {
        state[k] = payload[k];
      }
    }
  }
}