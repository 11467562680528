<template>
  <div class="luck-draw-body">
    <van-image
      class="luck-draw-bg"
      :src="activityInfo.special_info.header_img"
    />
    <div class="drawlot luck-draw-component">
      <div class="drawlot-gif">
        <img
          ref="gifRef"
          :src="activityInfo.special_info.preview_gif"
          :rel:animated_src="activityInfo.special_info.gif"
          rel:auto_play="0"
          rel:rubbable="1"
        />
      </div>
      <div class="drawlot-btn-group">
        <div class="drawlot-btn" @click="drawHandleThrottle">
          试试手气抽一签 {{ remainingTimes > 0 ? `X${remainingTimes}` : "" }}
        </div>
        <div class="drawlot-btn2" @click="showMyPrize">查看我抽中的签</div>
      </div>
    </div>
    <transition name="mask">
      <div class="draw-mask" v-if="showDialog">
        <div class="draw-aperture draw-aperture1"></div>
        <div class="draw-aperture draw-aperture2"></div>
        <div class="draw-aperture draw-aperture3"></div>
        <div class="draw-aperture-circle draw-aperture-circle1"></div>
        <div class="draw-aperture-circle draw-aperture-circle2"></div>
        <div class="draw-aperture-circle draw-aperture-circle3"></div>
        <div class="draw-aperture-circle draw-aperture-circle4"></div>
        <div class="draw-sign">
          <img :src="activityInfo.special_info.single_prize" />
        </div>
      </div>
    </transition>
    <transition name="dialog" :duration="{ enter: 2000, leave: 300 }">
      <div class="dialog-prize-box" v-if="showDialog">
        <div class="dialog-prize">
          <van-image
            class="dialog-prize-text"
            v-if="activityReward.status !== 6"
            :src="require('../../assets/images/dialog-prize-item.png')"
          />
          <van-image
            class="dialog-prize-text"
            v-else
            :src="require('../../assets/images/dialog-prize-miss.png')"
          />
          <van-image
            :src="activityReward.img"
            class="dialog-prize-img"
            fit="cover"
            lazy-load
          />
          <div class="dialog-prize-btn">
            <div
              class="dialog-prize-btn0 dialog-prize-btn1"
              @click="openPrizePopup"
            >
              查看我所有签
            </div>
            <div
              class="dialog-prize-btn0 dialog-prize-btn2"
              @click="showDialog = false"
            >
              再来一签
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import throttle from "lodash.throttle";
import SuperGif from "@/utils/superGif";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import Vue from "vue";

const vm = Vue.prototype;

export default defineComponent({
  inheritAttrs: false,

  props: {
    beforeDraw: Function,
    afterDraw: Function,
    openPrizePopup: Function,
    remainingTimes: Number,
    activityReward: Object,
    activityInfo: Object,
  },
  setup(props) {
    const rub = ref(null);
    const count = ref(0);
    const showDialog = ref(false);
    const run = ref(false);
    const init = ref(false);
    const gifRef = ref(null);
    const startLucky = async () => {
      if (run.value || !init.value) {
        return;
      }
      const res = await props.beforeDraw();
      if (!res) {
        return;
      }
      run.value = true;
      rub.value.play();
    };

    const drawHandleThrottle = throttle(startLucky, 3000, { trailing: false });

    const showMyPrize = () => {
      showDialog.value = false;
      props.openPrizePopup();
    };

    onMounted(() => {
      const loading = vm.$loading();
      rub.value = new SuperGif({
        gif: gifRef.value,
        auto_play: false,
        max_width: 300,
        show_progress_bar: false,
        progressbar_height: 5,
        progressbar_background_color: "#FFE2A0",
        progressbar_foreground_color: "#A00003",
        on_end: () => {
          count.value++;
          if (count.value === 2) {
            rub.value.pause();
            run.value = false;
            count.value = 0;
            showDialog.value = true;
            props.afterDraw();
          }
        },
      });
      rub.value.load(() => {
        loading.clear();
        init.value = true;
      });
    });
    return {
      drawHandleThrottle,
      gifRef,
      showDialog,
      showMyPrize,
    };
  },
});
</script>

<style lang="less" scoped>
.luck-draw-body {
  margin-bottom: 20px;
}
.drawlot {
  box-sizing: border-box;
}
.drawlot-gif {
  width: 300px;
  margin: 0 auto;
}

.drawlot-btn {
  width: 240px;
  height: 61px;
  color: #c9352a;
  background: url("../../assets/images/draw-btn-bg.png") no-repeat;
  background-size: 100%;
  text-align: center;
  line-height: 61px;
  font-size: 20px;
  margin: 0 auto;
  font-weight: bold;
  margin: -60px auto 0;
  position: relative;
  z-index: 1;
}
.draw-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
}

.draw-sign {
  width: 50px;
  height: 184px;
  position: absolute;
  left: 162px;
  top: 140px;
  z-index: 100;
  transform: scale(0);
  opacity: 0.3;
  /deep/ img {
    width: 100%;
    height: 100%;
  }
}

.mask-enter-active {
  animation: draw-mask 3s;
  .draw-sign {
    animation: sign 1.2s;
  }
  .draw-aperture-circle1 {
    animation: circle 1.5s ease-in-out 0.3s forwards;
  }
  .draw-aperture-circle2 {
    animation: circle 1.5s ease-in-out 0.5s forwards;
  }
  .draw-aperture-circle3 {
    animation: circle 1.5s ease-in-out 0.7s forwards;
  }
  .draw-aperture-circle3 {
    animation: circle 1.5s ease-in-out 2s forwards;
  }
}

.draw-aperture {
  position: absolute;
  left: 114px;
  right: 100px;
  top: 160px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  z-index: 99;
  background: radial-gradient(rgba(#ffce88, 0.2), rgba(#ffce88, 0.1));
  filter: blur(20px);
}
.draw-aperture-circle {
  position: absolute;
  left: 135px;
  right: 100px;
  top: 180px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 99;
  background: radial-gradient(transparent 60%, rgba(#ffce88, 0.5) 100%);
  opacity: 0;
}

@keyframes draw-mask {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  30% {
    background: rgba(0, 0, 0, 0.7);
  }
  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}

@keyframes sign {
  0% {
    transform: translateY(20px);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }

  30% {
    transform: translateY(0);
  }

  65% {
    transform: rotate(20deg);
  }
  70% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-20deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(20deg);
  }
  90% {
    transform: rotate(0deg);
  }
  95% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg) scale(0);
    opacity: 1;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  50% {
    transform: scale(10);
  }
  51% {
    transform: scale(10);
  }
  100% {
    transform: scale(10);
  }
}

.dialog-prize-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 110;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-prize {
  width: 100vw;
  height: 85.83vw;
  box-sizing: border-box;
  background: url("../../assets/images/dialog-prize-bg.png") no-repeat;
  background-size: 100%;
  transition: all 0.3 cubic-bezier(1, 0.5, 0.8, 1);
}
.dialog-prize-img {
  width: 120px;
  height: 100px;
  margin: 0 auto 50px;
}

.dialog-prize-btn {
  display: flex;
  height: 34px;
  margin: 0 40px;
}

.dialog-prize-btn0 {
  flex: 1;
  text-align: center;
  font-size: 16px;
  line-height: 34px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.dialog-prize-btn1 {
  background-image: url("../../assets/images/dialog-prize-btn1.png");
  color: #d90011;
}
.dialog-prize-btn2 {
  background-image: url("../../assets/images/dialog-prize-btn2.png");
  color: #ffefd7;
}

.drawlot-btn2 {
  margin: 14px auto 0;
  height: 61px;
  width: 241px;
  background: url("../../assets/images/draw-btn-bg2.png") no-repeat;
  background-size: 100%;
  line-height: 61px;
  color: #ffecd7;
  font-size: 20px;
  text-align: center;
}

.dialog-enter-active {
  .dialog-prize {
    transition-delay: 1.7s !important;
  }
}
.dialog-enter-active,
.dialog-leave-active {
  .dialog-prize {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
}
.dialog-enter-to,
.dialog-leave {
  .dialog-prize {
    transform: scale(1);
  }
}
.dialog-enter,
.dialog-leave-to {
  .dialog-prize {
    transform: scale(0);
  }
}

.dialog-prize-text {
  height: 24px;
  width: max-content;
  /deep/ img {
    height: 24px;
    width: auto;
  }
  margin: 50px auto 30px;
}
</style>