var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('section',{class:[
    'benefit-detail',
    ("benefit-" + (_vm.benefitInfo.type)),
    _vm.benefitHeaderImage ? 'new-benefit-style' : '' ]},[[_c('benefit-header',{attrs:{"benefitHeaderImage":_vm.benefitHeaderImage,"benefit_type":_vm.benefit_type,"benefitInfo":_vm.benefitInfo}}),_c('benefit-body',{attrs:{"benefit_type":_vm.benefit_type,"benefitInfo":_vm.benefitInfo,"benefitHeaderImage":_vm.benefitHeaderImage,"skuInfo":_vm.skuInfo,"selectSku":_vm.selectSku,"images":_vm.images,"refresh":_vm.refresh,"updateSelectSku":function (e) { return (_vm.selectSku = e); },"rechargeAccounts":_vm.rechargeAccounts}}),_c('benefit-introduction',{attrs:{"benefitInfo":_vm.benefitInfo,"selectSku":_vm.selectSku}}),(!_vm.$getConfigValue('HIDE_BENEFIT_ORDER_ENTRY'))?_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'benefit_detail_click_button2',
        spu_id: _vm.benefitInfo.id,
        spu_name: _vm.benefitInfo.name,
        namespace: _vm.namespace,
        source: _vm.source,
      }),expression:"{\n        event_id: 'benefit_detail_click_button2',\n        spu_id: benefitInfo.id,\n        spu_name: benefitInfo.name,\n        namespace,\n        source,\n      }"}],staticClass:"to-order-btn",on:{"click":function($event){return _vm.$router.push('/order')}}}):_vm._e()]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }