<template>
   <div class='refund-from' v-if="pageshow">
    <div class="refund-info">
        <div class="refund-info-title">退款信息：</div>
        <van-field
            v-model="userphone"
            required
            label="手机号"
            placeholder="填写手机号"
        />
        <van-field
            v-model="userorder"
            required
            label="订单号"
            placeholder="填写会员订单号"
        />
    </div>
    <div class='refund-form-main'>
        <div class="refund-info-title">退款原因：</div>
        <div class='refund-title'>为了给您提供更好的会员服务，请告诉我们您的退款原因(单选)：</div>
        <div class='reason-item' v-for="(item, index) in reason_list" :key="item.type" @click="selected_index = index">
            <img class='reason-select-img' :src="require(`../../assets/images/${selected_index === index ? 'reason_selected' : 'select'}.png`)" />
            {{item.refund_reason}}
        </div>
        <van-field
            v-model="message"
            type="textarea"
            maxlength="100"
            show-word-limit
            placeholder="最少10个字，最多100字"
            class="refund-textarea"
        />
    </div>
    <div class="refund-height"></div>
    <div class='refund-form-btn-main'>
        <div 
            class='refund-form-btn' 
            @click="submitForm"
            v-ans="{
                event_id: 'refund_form_submit_click',
                namespace,
                order_number: $route.query.order_number,
            }"
        >
            提交申请
        </div>
    </div>
   </div>
</template>

<script>
import { verifyForm } from '../../utils'
import { getRefundReason } from '../../apis/refund'
import { getOrder } from "@/apis";
import { mapState } from "vuex";
export default {
   name: 'refundForm',
   data() {
      return {
          pageshow: false,
          userphone: '',
          userorder: '',
          reason_list: [],
          selected_index: null,
          message: '',
      }
   },
   components: {},
   computed: {
    ...mapState(["namespace", "partner_data"]),
  },
   async mounted () {
       const toast = this.$loading();
       try {
           const { order_number } = this.$route.query;
           const orderDetail = await getOrder({ order_number });
           //如果特定渠道，自动填充订单号
           if(['2_sqkxmxwt01_tgsqkxmxwt2101', '2_sqkcat1_tgsqkcat2109'].includes(orderDetail.data.res_list[0].confirmation)){
               this.userorder = order_number
           }
           //获取退款原因
            const res = await getRefundReason({namespace: this.namespace});
            this.reason_list = res.data;
            this.pageshow = true;
       } finally {
           toast.clear();
       }
   },
   methods: {
       //提交申请
       submitForm(){
           const { order_number } = this.$route.query;
           const verify_result = this.verify();
           if(verify_result){
               this.$toast(verify_result);
               return;
           }
           let refund_reason = this.reason_list[this.selected_index].refund_reason;
           if(this.selected_index === this.reason_list.length - 1){
               refund_reason = this.message;
           }
           if(this.reason_list[this.selected_index].next_step === 'offer2' && this.partner_data.data){
               this.$router.push(`/refundApply?retain=twice&order_number=${order_number}&phone=${this.userphone}&reason=${refund_reason}`);
               return;
           }
           this.$router.push(`/refundDetail?behavior=submit&phone=${this.userphone}&reason=${refund_reason}&order_number=${order_number}`)
       },

       //校验输入信息
       verify(){
           const { order_number } = this.$route.query;
           const verifyPhone = verifyForm.verifyPhone(this.userphone);
           if(verifyPhone){
               return verifyPhone
           };
           if(!this.userorder){
               return '请输入会员订单号'
           };
           if(this.selected_index === null){
               return '请选择退款原因后提交'
           };
           if(this.selected_index === this.reason_list.length - 1 && !this.message){
               return '请输入原因'
           };
           if(this.message.length < 10){
               return '请输入最少10字'
           }
           //判断可退款的订单号和用户填写的订单号是否一样
           if(order_number !== this.userorder.toUpperCase()){
               return '您的订单号有误，请在“我的订单”查找最近一笔会员订单号';
           };
       }
   }
}
</script>

<style lang='less' scoped>
.refund-info{
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
}
.refund-info-title{
    font-size: 16px;
    color: #333;
    margin-left: 5px;
}
.refund-from{
    height: 100%;
    background-color: #F5F5F5;
}
.refund-form-main{
    padding: 15px 15px 20px;
    background-color: #fff;
}
.refund-form-main .refund-info-title{
    margin-left: 0;
}
.refund-title{
    color: #999;
    font-size: 12px;
    margin-top: 10px;
}
.reason-item{
    margin-top: 20px;
    font-size: 14px;
    color: #333;
}
.reason-select-img{
    width: 15px;
    height: 15px;
    margin-right: 8px;
    transform: translateY(2px);
}
.other-reason{
    background-color: #F5F5F5;
    margin-top: 10px;
    border: none;
}
.other-reason .at-textarea__textarea{
    background-color: #F5F5F5;
    font-size: 12px;
    color: #999;
    line-height: 1.4;
}
.refund-form-btn-main{
    box-sizing: border-box;
    background-color: #fff;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 9;
    padding: 10px 17px;
}
.refund-form-btn{
    height: 49px;
    line-height: 49px;
    border-radius: 25px;
    background-color: #222;
    text-align: center;
    font-weight: bold;
    color: #FFD092;
    font-size: 16px;
}
.refund-textarea{
    background-color: #F5F5F5;
    margin-top: 10px;
    border-radius: 8px;
}
.refund-height{
    height: 60px;
}
</style>
