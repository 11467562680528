<template>
  <div
    class="park-list"
    v-if="!fetching"
    :style="`background-color: ${theme.bgColor}`"
  >
    <van-image
      class="park-header-img"
      :src="theme.headerImage"
      v-if="theme.headerImage"
    />
    <ul class="park-list-ul">
      <li
        v-for="park in list"
        class="park-item"
        :key="park.id"
        @click="toDetail(park)"
      >
        <van-image
          class="park-image"
          fit="cover"
          lazy-load
          :src="park.images[0].url"
        />
        <div class="park-info">
          <div class="park-name">
            {{ park.name }}
          </div>
          <van-row class="advantages">
            <van-col
              v-for="tag in park.tags"
              :key="tag.id"
              class="advantage-item"
              >{{ tag.name }}</van-col
            >
            <van-col class="advantage-item"
              >最早可预订日期{{ firstDay | formatDate("MM月DD日") }}</van-col
            >
          </van-row>
        </div>
        <div class="park-footer">
          <div class="park-map ellipsis">
            {{ park.address }}
          </div>
          <div class="park-price">
            <span v-if="park.price" class="park-face_value"
              >¥{{ park.ticket_price }}</span
            >
            <span class="member-text">折后价</span>
            <span class="hotel_real_price"
              ><small>¥</small>{{ park.price || park.ticket_price }}</span
            ><span class="start-text">起</span>
          </div>
        </div>
      </li>
    </ul>
    <div class="end" v-if="list.length">到底啦~</div>
  </div>
</template>

<script>
import { useRouter, useStore } from "@/components/use/base";
import { decimal, jsonSafeParse } from "@/utils";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import Vue from "vue";
import { getOfflineParkList } from "@/apis/park";
import { getActivityCoupon } from "@/apis";
import * as dayjs from "dayjs";
const vm = Vue.prototype;

export default defineComponent({
  setup() {
    const list = ref([]);
    const fetching = ref(true);
    const router = useRouter();
    const firstDay = dayjs().add(2, "days");
    onMounted(async () => {
      const params = {};
      const loading = vm.$loading();
      try {
        const res = await getOfflineParkList(params);
        const coupons = await getActivityCoupon({ scope: "park" });
        const couponList = coupons.data;
        list.value = res.data.data.map((item) => {
          const availableCoupon = couponList.filter((r) =>
            r.attrs.ticket_ids.includes(item.id)
          );
          availableCoupon.sort((a, b) => a.discount_rate - b.discount_rate);
          const coupon = availableCoupon[0];
          if (coupon) {
            item.price = decimal.times(item.ticket_price, coupon.discount_rate);
          }
          return item;
        });
      } finally {
        loading.clear();
        fetching.value = false;
      }
    });
    const toDetail = (data) => {
      router.push(`/offlineBenefit/park/detail?id=${data.id}`);
    };
    return {
      firstDay,
      fetching,
      list,
      toDetail,
      theme: jsonSafeParse(vm.$getConfigValue("PARK_LIST_THEME")),
    };
  },
});
</script>

<style lang="less" scoped>
.park-list {
  min-height: 100vh;
  .park-list-ul {
    padding: 12px;
  }
}
.park-header-img {
  height: 172px;
  margin-bottom: 17px;
}

.park-item {
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  position: relative;
  & + & {
    margin-top: 12px;
  }
}
.park-type-image {
  width: 54px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}
.park-image {
  height: 178px;
}
.park-name {
  font-size: 17px;
  line-height: 24px;
  color: #000;
  margin-bottom: 6px;
}
.park-info {
  padding: 12px 14px 20px 12px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.park-desc {
  color: #a3541e;
  font-size: 12px;
  line-height: 17px;
}

.advantage-item {
  height: 20px;
  line-height: 20px;
  border: 1px solid rgba(#874c21, 0.3);
  border-radius: 4px;
  color: #a3541e;
  margin-right: 4px;
  font-size: 10px;
  padding: 0 5px;
  .svg-icon {
    vertical-align: -0.5px;
    margin-right: 1px;
  }

  @colors: #f21500, #ff7700, #00b87a;
  each(range(length(@colors)), {
    &:nth-child(@{value}) {
      color: extract(@colors, @value);
      border-color: rgba(extract(@colors, @value), 0.9);
    }
  });
}

.advantages {
  margin-top: 8px;
}
.park-footer {
  height: 42px;
  background: url("~@/assets/images/hotel/hotel_map.png") no-repeat;
  background-size: contain;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
}
.park-map {
  text-indent: 15px;
  background: url("~@/assets/images/map-icon2.png") no-repeat left center;
  background-size: contain;
  color: #333;
  line-height: 13px;
  flex: 1;
}
.park-price {
  height: 33px;
  line-height: 33px;
  margin-bottom: 9px;
  flex: 1;
  text-align: right;
}

.member-text,
.start-text {
  color: #ff5001;
}
.hotel_real_price {
  font-size: 24px;
  font-weight: bold;
  color: #ff5001;
  padding: 0 2px;
  small {
    font-size: 0.5em;
  }
}
.park-face_value {
  line-height: 17px;
  color: #999;
  text-decoration: line-through;
  margin-right: 4px;
  font-size: 11px;
}
.room-unavailable {
  position: absolute;
  right: 14px;
  bottom: 12px;
  width: 72px;
  height: 72px;
  background: url("~@/assets/images/hotel/room_unavailable.png") no-repeat;
  background-size: 100%;
}

.end {
  padding: 28px 0;
  text-align: center;
  color: #fff;
}
</style>
