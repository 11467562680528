var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.fetching)?_c('div',{class:( _obj = { 'qm-member': true }, _obj[("qm-member-" + _vm.namespace)] = true, _obj )},[_c('div',{class:("qm-card " + _vm.firstCard + "-card-wrap")},[_c('van-swipe',{staticClass:"swiper-card",attrs:{"loop":false,"show-indicators":false},on:{"change":_vm.changeCard}},_vm._l((_vm.cardList),function(item,idx){return _c('van-swipe-item',{key:item.namespace,class:{
          'slide-prev': idx + 1 === _vm.active,
          'slide-active': idx === _vm.active,
          'slide-next': _vm.active + 1 === idx,
        }},[_c('div',{class:("qm-card-item " + (item.card_type.toLowerCase()) + "-card")},[_c('div',{staticClass:"card-exprie"},[_vm._v("到期时间："+_vm._s(item.t_expiration))])])])}),1)],1),_c('div',{class:("member-benefits member-benefits-" + _vm.namespace)},_vm._l((_vm.catesgoryList),function(item){return _c('div',{key:item.display_code,class:("member-benefit member-benefit-" + (item.display_code))},[_c('div',{class:("benefit-title " + (item.display_code) + "-img")}),_c('div',{staticClass:"benefit-list"},[(item.display_code === 'partner_privileges')?[_c('div',{staticClass:"parnter-list"},_vm._l((item.list),function(row,index){return _c('div',{key:row.desc,staticClass:"parnter-list-item"},[_c('van-image',{staticClass:"parnter-list-image",attrs:{"lazy-load":"","src":row.display_img_url}}),_c('div',{staticClass:"parnter-list-info ellipsis"},[_c('div',{staticClass:"parnter-title"},[_vm._v(_vm._s(row.desc))]),_c('div',{staticClass:"parnter-subtitle ellipsis"},[_vm._v(" "+_vm._s(row.selling_points)+" ")])]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
                  event_id: 'benefit_list_click',
                  sequence: index,
                  namespace: _vm.namespace,
                  spu_id: row.id,
                  spu_name: row.desc,
                }),expression:"{\n                  event_id: 'benefit_list_click',\n                  sequence: index,\n                  namespace,\n                  spu_id: row.id,\n                  spu_name: row.desc,\n                }"}],class:{
                  'parnter-btn': true,
                  'parnter-btn-disabled': row.is_cube,
                },style:(("background-image:url(" + (row.icon_img_url) + ")")),on:{"click":function($event){return _vm.toDetail(row)}}})],1)}),0)]:_vm._e(),(item.display_code === 'life_privileges')?_c('div',{staticClass:"life-list"},[_vm._l((item.list),function(row,index){return _c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
                event_id: 'benefit_list_click',
                sequence: index,
                namespace: _vm.namespace,
                spu_id: row.id,
                spu_name: row.desc,
              }),expression:"{\n                event_id: 'benefit_list_click',\n                sequence: index,\n                namespace,\n                spu_id: row.id,\n                spu_name: row.desc,\n              }"}],key:row.name,staticClass:"list-inner",on:{"click":function($event){return _vm.toDetail(row)}}},[_c('div',{staticClass:"life-list-item"},[_c('van-image',{staticClass:"life-list-image",attrs:{"src":row.display_img_url,"fit":"contain"}}),_c('div',{staticClass:"life-title"},[(
                      _vm.namespace === 'qmwallet1' ||
                      _vm.namespace === 'qmwallet2' ||
                      _vm.namespace === 'qmqb1'
                    )?_c('span',{staticClass:"life-title-number"},[_vm._v("权益"+_vm._s(index + 1))]):_vm._e(),_vm._v(" "+_vm._s(row.desc)+" "),(
                      _vm.namespace === 'qmwallet1' ||
                      _vm.namespace === 'qmwallet2' ||
                      _vm.namespace === 'qmqb1'
                    )?_c('span',{staticClass:"life-title-sellpoint"},[_vm._v(_vm._s(item.selling_points))]):_vm._e()]),_c('div',{class:{
                    'life-use': true,
                    'life-use-disabled':
                      row.benefit &&
                      (row.benefit.amount_available == 0 ||
                        row.benefit.amount_reserved +
                          row.benefit.amount_available ===
                          0),
                    'life-use-amount':
                      row.benefit && row.benefit.amount_available > 0,
                  }},[(row.benefit && row.benefit.amount_available > 0)?_c('div',{staticClass:"amount"},[_vm._v(" 剩"+_vm._s(row.benefit.amount_available + row.benefit.amount_reserved)+"次 ")]):_vm._e(),_c('div',{staticClass:"use"},[_vm._v(_vm._s(_vm.getBenefitText(row.benefit)))])])],1)])})],2):_vm._e()],2)])}),0),_c('div',{staticClass:"member-footer"},[_c('div',{staticClass:"footer-item footer-service",on:{"click":function($event){return _vm.$router.push('/service')}}},[_vm._v(" 专属客服 ")]),_c('div',{staticClass:"footer-item footer-help",on:{"click":function($event){return _vm.$router.push('/help')}}},[_vm._v(" 常见问题 ")])]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'benefit_detail_click_button2' }),expression:"{ event_id: 'benefit_detail_click_button2' }"}],staticClass:"to-order-btn",on:{"click":function($event){return _vm.$router.push('/order')}}},[_vm._v(" 我的订单 ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }