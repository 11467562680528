import {
  yioil_app_key,
  yioil_app_secret,
  yioil_ejiayou_link,
  yioil_plat
} from "@/config";
import cookie from "js-cookie";
import { hextob64, KEYUTIL, crypto } from "jsrsasign";
import router from "@/router";
import dayjs from "dayjs";
import md5 from "blueimp-md5";
import CryptoJS from "crypto-js";
import Vue from "vue";
import Decimal from "decimal.js";
import { Toast } from 'vant'

const vm = Vue.prototype;

const prefix = "common__";
export const loadJS = (url, callback) => {
  var script = document.createElement("script"),
    fn = callback || function () { };
  script.type = "text/javascript";
  //IE
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState == "loaded" || script.readyState == "complete") {
        script.onreadystatechange = null;
        fn();
      }
    };
  } else {
    //其他浏览器
    script.onload = function () {
      fn();
    };
  }
  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

export const storage = {
  setItem(name, ...rest) {
    cookie.set(`${prefix}${name}`, ...rest);
    localStorage.setItem(`${prefix}${name}`, ...rest);
  },
  removeItem(name) {
    cookie.remove(`${prefix}${name}`);
    localStorage.removeItem(`${prefix}${name}`);
  },
  getItem(name) {
    return (
      localStorage.getItem(`${prefix}${name}`) || cookie.get(`${prefix}${name}`)
    );
  }
};

export const totalMoney = (order_list = [], type) => {
  let num = 0;
  order_list.forEach(item => {
    if (type && item.status === type) {
      num += Number(item.user_commission_amt);
    }
    !type && (num += Number(item.user_commission_amt));
  });
  return num.toFixed(2);
};

//表单校验
export const verifyForm = {
  verifyName: function (_name) {
    if (!_name) {
      return "请输入姓名";
    }
    if (!/^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/.test(_name)) {
      return "请输入正确的姓名";
    }
    return;
  },
  verifyAlipay: function (str) {
    if (!str) {
      return "请输入手机号或邮箱";
    }
    if (
      /^1(3|4|5|6|7|8|9)\d{9}$/.test(str) ||
      /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/.test(
        str
      )
    ) {
      return;
    }
    return "请输入正确的手机号或邮箱";
  },
  verifyInteger: function (number) {
    if (!number && number !== 0) {
      return "请输入提现金额";
    }
    if (!/(^[1-9]\d*$)/.test(number)) {
      return "请输入大于1元的整数";
    }
  },
  verifyPhone: function (str) {
    if (!str) {
      return "请输入手机号";
    }
    if (/^1([3-9])\d{9}$/.test(str)) {
      return;
    }
    return "请输入正确的手机号";
  }
};

export const getStrFromJsonStr = (jsonStr, key) => {
  if (!jsonStr) {
    return "";
  }
  try {
    let json = {};
    if (typeof jsonStr === "string") {
      json = JSON.parse(jsonStr);
    } else if (typeof jsonStr === "object") {
      json = jsonStr;
    }
    return json[key] || "";
  } catch (e) {
    console.log(e);
    return "";
  }
};

export const formatTime = (data, showdetail) => {
  let format = "YYYY-MM-DD";
  if (showdetail) {
    format = "YYYY-MM-DD HH:mm:ss";
  }
  return dayjs(data).format(format);
};

export const prkConfig = namespace => {
  if (namespace === "common" || !namespace) {
    return Vue.prototype.$getConfigValue("COMMON_PRIVATE_KEY");
  }
  if (process.env.VUE_APP_RUNTIME_ENV !== "production") {
    return Vue.prototype.$getConfigValue("DEV_PRIVATE_KEY");
  }
  return Vue.prototype.$getConfigValue("PROJECT_PRIVATE_KEY");
};

export const creatSign = (namespace, data) => {
  let str = data;
  if (typeof data === "object") {
    str = `"${JSON.stringify(data)}"`;
  }
  let arr = str.split("");
  let src = "";
  arr.sort();
  for (let i = 0; i < arr.length; i++) {
    src += arr[i];
  }
  //先建立 key 对象, 构建 signature 实例, 传入 key 初始化 -> 签名
  const prk = `-----BEGIN PRIVATE KEY-----
  ${prkConfig(namespace)}
  -----END PRIVATE KEY-----`;
  let key = KEYUTIL.getKey(prk);
  // 创建 Signature 对象
  let signature = new crypto.Signature({ alg: "SHA1withRSA" });
  // 传入key实例, 初始化signature实例
  signature.init(key);
  // 传入待签明文
  signature.updateString(src);
  // 签名, 得到16进制字符结果
  let a = signature.sign();
  let sign = hextob64(a);
  return sign;
};

// 聚合跳转
// 如果需要跳转到小程序时，url传对象{url:'/xxx'}这种格式
// 有return_url参数，会跳转回原始页面
// 此方法挂载在vue原型上
export function jumpUrl(url, type = "navigateTo") {
  if (!url) {
    return;
  }

  const return_url = this.$$store.state.return_url;

  // 从小程序跳转过来的，如果需要跳转回小程序页面
  // 不是"/"开头的链接，表示小程序链接，使用小程序方法跳转
  if (
    this.$$store.state.isMiniProgram &&
    (return_url === "miniapp" ||
      (typeof url === "string" && !url.startsWith("/")))
  ) {
    if (typeof url === "string") {
      if (!url.startsWith("/")) {
        url = "/" + url;
      }
      url = { url };
    }
    if (this.$$store.state.isAlipay) {
      my[type](url);
      return;
    }
    wx.miniProgram[type](url);
    return;
  }
  if (url.startsWith("http")) {
    window.location.href = url;
    return;
  }
  if (!return_url) {
    router.push(url);
    return;
  }
  window.location.href = `${return_url}#${typeof url === "object" ? url.url : url
    }`;
}

function formatDate(day) {
  var d = new Date(day),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  return {
    date: [year, month, day].join("-"),
    weekends: [0, 6].indexOf(d.getDay()) > -1
  };
}
export function isHoliday(day) {
  const holidays = jsonSafeParse(Vue.prototype.$getConfigValue("HOLIDAYS"));
  var fd = formatDate(day);
  if (holidays[fd.date]) {
    return true;
  } else {
    return false;
  }
}

//腾讯地图坐标转百度地图坐标
export const qqMapTransBMap = (lng, lat) => {
  let x_pi = (3.14159265358979324 * 3000.0) / 180.0;
  let x = lng;
  let y = lat;
  let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
  let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
  let lngs = z * Math.cos(theta) + 0.0065;
  let lats = z * Math.sin(theta) + 0.006;
  return {
    longitude: lngs,
    latitude: lats
  };
};

export const creatRefuelLink = (latitude, longitude, userInfo) => {
  const user_phone = userInfo.user_phone;
  //生成时间戳
  const timestamp = new Date().getTime();
  //拼接key#timestamp#secret，md5加密生成sign
  const sign = md5(`${yioil_app_key}#${timestamp}#${yioil_app_secret}`);
  //拼接参数生成最后链接
  return `${yioil_ejiayou_link}?plat=${yioil_plat}&userPhone=${user_phone}&sign=${sign}&timestamp=${timestamp}&longitude=${longitude}&latitude=${latitude}`;
};

export const getNetworkType = () => {
  var common_cell = ["cellular", "2g", "3g", "4g", "5g", "3G/2G"];
  var user_agent = navigator.userAgent.toLowerCase();
  var connection = navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection || { type: "unknown" };
  var type;
  // 判断是否是微信内置浏览器
  var isWeixin = /micromessenger/.test(user_agent);
  if (isWeixin) {
    if (user_agent.indexOf("nettype") !== -1) {
      type = user_agent.match(/nettype\/\w+/)
        ? user_agent.match(/nettype\/\w+/)[0]
        : "nettype/unknow";
      type = type.replace("nettype/", "");
    } else {
      var weixinType = {
        "network_type:wifi": "wifi",
        "network_type:edge": "3G/2G",
        "network_type:fail": "fail",
        "network_type:wwan": "3G/2G"
      };
      document.addEventListener(
        "WeixinJSBridgeReady",
        function onBridgeReady() {
          WeixinJSBridge.invoke("getNetworkType", {}, function (e) {
            type = weixinType[e.err_msg];
          });
        }
      );
    }
  } else {
    type = (connection && connection.type) || "unknown";
  }
  // 对type 做统一处理
  if (type && common_cell.indexOf(type) !== -1) {
    return "cellular";
  } else if (type === "wifi") {
    return "wifi";
  }
  return "unknown";
};

const SECRET_KEY = CryptoJS.enc.Utf8.parse("0361026394589011");
const SECRET_IV = CryptoJS.enc.Utf8.parse("2091235546789187");

export function encrypt(data) {
  if (typeof data === "object") {
    try {
      data = JSON.stringify(data);
    } catch (error) {
      console.log("encrypt error:", error);
    }
  }
  const dataHex = CryptoJS.enc.Utf8.parse(data);
  const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.ciphertext.toString();
}

export function decrypt(data) {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(data);
  const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

// 获取项目名称

export const getProjectDivision = namespace => {
  if (namespace.includes("_life")) {
    return process.env.VUE_APP_RUNTIME_ENV === "production"
      ? "省钱卡"
      : "省钱卡(测试)";
  } else {
    if (process.env.VUE_APP_RUNTIME_ENV === "production") {
      return location.origin.includes("xy-h5.xiangshuheika.com") || namespace === 'xy'
        ? "小赢项目"
        : "通用项目";
    }
    return "通用项目(测试)";
  }
};

// 返回一个成功的promise
export const promiseSuccess = fn => {
  return async (...rest) => {
    try {
      const res = await fn(...rest);
      return res;
    } catch (e) {
      return Promise.resolve();
    }
  };
};

/**
 * json字符串解析成对象
 * @param {*} str
 * @returns {}
 */
export const jsonSafeParse = (str, jsonType = {}) => {
  if (!str || typeof str !== "string") {
    return jsonType;
  }

  try {
    return JSON.parse(str);
  } catch (e) {
    return jsonType;
  }
};

export const replaceDay = (text, value) => {
  if (!text || typeof text !== "string") {
    return text;
  }
  return text.replace(/{day}/g, value);
};

/**
 * 字符串脱敏
 * @param {*} str
 * @returns {string}
 */
export const maskStr = (str, reg = /(\d{3})\d+(\d{4})/) => {
  if (!str || typeof str !== "string") {
    return str;
  }
  return str.replace(reg, ($, $1, $2) => {
    if (!$1 || !$2) {
      return $;
    }
    return $1 + "*".repeat($.length - $1.length - $2.length) + $2;
  });
};

export const getCoundFromSellpoint = str => {
  const text = str.split("x")[1] || "";
  return Number(text.match(/(\d+)/)?.[0] || 0);
};

export const sleep = time => {
  return new Promise(resolve => setTimeout(resolve, time));
};

export const decimal = function (value1, value2, type = "minus") {
  if (typeof value1 !== "number" || typeof value2 !== "number") {
    return "";
  }
  return new Decimal(value1)[type](new Decimal(value2)).toNumber();
};

/**
 * 加法
 * @param  {...any} args
 * @returns {number}
 */
decimal.plus = (...args) => decimal(...args, "plus");

/**
 * 减法
 * @param  {...any} args
 * @returns {number}
 */
decimal.minus = (...args) => decimal(...args, "minus");
/**
 * 乘法
 * @param  {...any} args
 * @returns {number}
 */
decimal.times = (...args) => decimal(...args, "times");
/**
 * 除法
 * @param  {...any} args
 * @returns {number}
 */
decimal.div = (...args) => decimal(...args, "div");

/**
 * 固定像素转rem像素
 * @param {number} 设计图像素
 * @returns {number} rem像素
 */
export const pxToRemPx = px => {
  const w = document.documentElement.getBoundingClientRect().width / 10;
  return (px * w) / 37.5;
};

/**
 * 随机生成范围的的数字
 * @param {number} min 最小值
 * @param {number} max 最大值
 * @returns number
 */
export function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * 计算当前时间距离结束时间的工作日天数
 */
export const getWorkDays = (startDate = new Date(), endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const days = end.diff(start, "day");

}


/**
 * 计算n个工作日时间
 * @param {*} startDate 当前时间
 * @param {*} days 工作日天数
 */
export const getWorkDateByDay = (startDate = new Date(), days) => {
  let day = 0;
  let date
  for (let i = 0; i < days; i++) {
    date = dayjs(startDate).add(day, "day");
    if (date.day() === 6 || date.day() === 0) {
      i--;
    }
    day++
  }
  return date
}


/**
 * 获取支付宝打开h5的地址
 * @param {*} url 页面地址
 */
export const getInAlipayUrl = (url) => {
  url = encodeURIComponent(url);
  var sceme = `alipays://platformapi/startapp?appId=20000067&url=${url}`;
  return `https://ulink.alipay.com/?scheme=${encodeURIComponent(
    sceme
  )}`;
}

// 极验业务接口封装
export const geetestHOF = async (api, type, phone = undefined) => {
  Toast.clear(true)
  let loading

  const value = {
    login: '$loginValidate',
    secKill: "$secKillValidate",
    buy: "$buyBenefitValidate",
    active: "$activeBenefitValidate",
    joinActive: '$joinActivityValidate'
  }[type]
  const geetestInstance = vm[value]

  await geetestInstance.startCheck({ phone })

  const loadingMsg = type === 'login' ? "登录中..." : undefined

  try {
    loading = vm.$loading(loadingMsg);
    return await api()
  } finally {
    loading.clear()
  }
}

export const maskName = (name) => {
  let userName = name;
  const isEnglish = /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(name)
  if (isEnglish) {
    const surname = name.split(' ')[0]
    return userName.replace(surname, '*').toUpperCase()
  }


  if (name.length <= 3) {
    userName = "*" + name.substring(1, name.length) //截取name 字符串截取第一个字符，
  } else {
    userName = "**" + name.substring(2, name.length)
  }

  return userName;
}

export const isImageUrl = src => /.(jpeg|jpg|png|gif)$/.test(src)