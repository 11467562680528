var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"benefits"},[_c('van-skeleton',{attrs:{"title":"","row":4,"loading":_vm.loading}},[(_vm.emptyText)?_c('van-empty',{attrs:{"image":require('@/assets/images/promotion_empty.png'),"image-size":118,"description":_vm.emptyText}}):_vm._l((_vm.benefits),function(benefit){return _c('div',{key:benefit.type,staticClass:"benefit"},[_c('div',{class:{
          'flex-left': true,
          'flex-left-h':
            (benefit.amount > 0 && benefit.amount_available_expired_time) ||
            (benefit.amount === 0 &&
              benefit.get_start_time &&
              benefit.get_end_time),
        }},[(benefit.amount + benefit.amount_reserved > 0)?_c('span',{staticClass:"total"},[_vm._v("剩"+_vm._s(benefit.amount + benefit.amount_reserved)+"次")]):_vm._e(),_c('div',{staticClass:"flex-i"},[_c('van-image',{staticClass:"benefit-image",attrs:{"src":benefit.icon_url}}),_c('div',{staticClass:"benefit-content"},[_c('div',{staticClass:"benefit-title"},[_vm._v(_vm._s(benefit.title))]),_c('div',{staticClass:"benefit-subtitle"},[_vm._v(" "+_vm._s(benefit.brief_subtitle)+" ")])])],1),(
            (benefit.amount > 0 && benefit.amount_available_expired_time) ||
            (benefit.amount === 0 &&
              benefit.get_start_time &&
              benefit.get_end_time)
          )?_c('div',{staticClass:"flex-b"},[(benefit.amount > 0 && benefit.amount_available_expired_time)?[_vm._v(" 请在 "+_vm._s(_vm._f("formatDate")(benefit.amount_available_expired_time))+" 前领取，过期作废 ")]:_vm._e(),(
              benefit.amount === 0 &&
              benefit.get_start_time &&
              benefit.get_end_time
            )?[_vm._v(" 下次权益次数将在"+_vm._s(_vm.nextDay(benefit.get_start_time, benefit.get_end_time))+"日发放 ")]:_vm._e()],2):_vm._e()]),_c('div',{staticClass:"flex-right"},[(benefit.amount > 0)?_c('div',{staticClass:"amount"},[_vm._v(" 可领"+_vm._s(benefit.amount)+"次 ")]):_vm._e(),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
            event_id: 'promotion_benefit_click',
            benefit_type: benefit.type,
            card_name: _vm.card.card_type,
          }),expression:"{\n            event_id: 'promotion_benefit_click',\n            benefit_type: benefit.type,\n            card_name: card.card_type,\n          }"}],class:{
            'benefit-btn': true,
            'benefit-btn-disabled': benefit.amount === 0,
          },on:{"click":function($event){return _vm.toBenefitDetail(benefit)}}},[_vm._v(" "+_vm._s(_vm.btnText(benefit))+" ")])])])})],2),_c('van-skeleton',{attrs:{"title":"","row":4,"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }