<template>
  <section class="instruction">
    <div
      v-if="content"
      v-image-preview
      class="instruction-text"
      v-html="content"
      v-imagelazy
    />
    <van-empty v-else />
  </section>
</template>

<script>
import { getItemInfo } from "@/apis/home";
import { useRoute } from "@/components/use/base";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const content = ref("");
    const $route = useRoute();

    onMounted(async () => {
      const res = await getItemInfo({ item_code: $route.query.item_code });
      content.value = res.detail_tutorial;
    });
    return {
      content,
    };
  },
});
</script>

<style lang="less" scoped>
.instruction {
  padding: 10px;
}
.instruction-text {
  min-height: calc(100vh - 20px);
  padding: 10px;
  box-sizing: border-box;

  background: #fff;

  line-height: 1.5;
  /deep/ img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }
}
</style>
