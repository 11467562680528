<template>
  <div>
    <div class="luck-draw-body">
      <van-image
        class="luck-draw-bg"
        :src="activityInfo.special_info.header_img"
      />
      <div class="luck-draw-component qiyuan-wrap">
        <div class="qiyuan-prize">
          <div
            class="prize-list-item"
            v-for="item in prizeList"
            :key="item.item_code"
          >
            <van-image
              @click="drawHandleThrottle"
              class="prize-item-image"
              :src="item.img"
              fit="cover"
            />
            <div class="prize-item-btn" @click="drawHandleThrottle"></div>
          </div>
        </div>
        <div class="qiyuan-text">
          {{ activityInfo.special_info.start_draw_text || "祈愿次数" }}：{{
            remainingTimes
          }}次
        </div>
      </div>
    </div>
    <Dialog
      v-model="showDialog"
      :activityReward="activityReward"
      :type="type"
      :openPrizePopup="openPrizePopup"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "@vue/composition-api";
import throttle from "lodash.throttle";
import Dialog from "./Dialog";
import Vue from "vue";

const vm = Vue.prototype;
export default defineComponent({
  inheritAttrs: false,

  components: {
    Dialog,
  },
  props: {
    prizeConfigList: Array,
    activityInfo: Object,
    activityReward: Object,
    beforeDraw: Function,
    openPrizePopup: Function,
    afterDraw: Function,
    type: String,
    remainingTimes: Number,
  },
  setup(props) {
    const showDialog = ref(false);
    const drawHandle = async () => {
      const res = await props.beforeDraw(true);
      if (!res) {
        return;
      }
      const miss_tips = props.activityInfo.special_info.miss_tips;
      if (res.status === 6 && miss_tips) {
        // 未中奖
        vm.$toast(miss_tips);
      } else {
        showDialog.value = true;
      }
      await props.afterDraw();
    };
    const drawHandleThrottle = throttle(drawHandle, 3000, { trailing: false });
    const prizeList = computed(() => {
      return props.prizeConfigList.filter(
        (item) =>
          item.item_code !== props.activityInfo.special_info.miss_item_code
      );
    });
    return {
      prizeList,
      drawHandleThrottle,
      showDialog,
    };
  },
});
</script>

<style lang="less" scoped>
.qiyuan-prize {
  display: flex;
  flex-wrap: wrap;
}
.luck-draw-component.qiyuan-wrap {
  top: 278px !important;
  width: 300px;
}
.prize-list-item {
  width: 33.33333%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
}
.prize-item-image {
  width: 79px;
  height: 112px;
}

.prize-list-item:nth-child(n + 4) {
  margin-top: 12px;
}

.qiyuan-text {
  text-align: center;
  color: #fff;
  margin-top: 20px;
}

.prize-item-btn {
  margin-top: 10px;
  width: 61px;
  height: 29px;
  background: url(https://common-h5-dev.xiangshuheika.com/static/upload/202402184c31b1472f68f.png)
    no-repeat center center/100%;
}
</style>
