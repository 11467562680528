<template>
  <div :class="`quanmin quanmin_${namespace}`">
    <div class="entry-block" @click="toBuy" />
    <div class="page-benefits">
      <div
        :key="item.display_code"
        v-for="item in catesgoryList"
        :class="`page-benefit page-benefit-${item.display_code}`"
      >
        <div
          v-for="spu in item.list"
          @click="clickHandle(spu)"
          :key="spu.id"
          class="page-benefit-spu"
          v-ans="{
            event_id: 'quanmin_spu_click',
            spu_id: spu.id,
            namespace,
          }"
        >
          <van-image
            lazy-load
            class="qm-image"
            fit="cover"
            :src="spu.display_img_url"
          />
        </div>
      </div>
    </div>
    <div v-if="memberPackage.item_code" class="fixed-height"></div>
    <div v-if="memberPackage.item_code" class="page-footer">
      <div class="agreement">
        开通即同意<span class="agreement-text" @click="jumpAgreement"
          >《{{ aggrement }}服务协议》</span
        >
      </div>
      <div
        class="package"
        v-if="
          namespace === 'ydh1' ||
          namespace === 'ydh2' ||
          namespace === 'qmydh1' ||
          namespace === 'qmwallet1' ||
          namespace === 'qmqb1' ||
          namespace === 'qmwallet2'
        "
      >
        <div
          class="ydh-facevalue"
          v-ans="{ event_id: 'visitor_click_buy', namespace }"
          @click="toBuy"
        ></div>
        <div
          class="ydh-price"
          v-ans="{ event_id: 'visitor_click_buy', namespace }"
          @click="toBuy"
        ></div>
        <div class="ydh-count-dwon">
          秒杀中：
          <van-count-down
            ref="countdown"
            class="count-down"
            :time="countDown"
            @finish="finishCountDown"
            format="HH:mm:ss"
          />
        </div>
      </div>
      <div class="package" v-else>
        <div class="package-discount">
          <div class="package-discount-tag"></div>
          <div class="package-face-value">
            原价¥{{ memberPackage.face_value }}
          </div>
        </div>
        <div class="package-price">
          ¥{{ memberPackage.price }}
          <span class="package-price-unit">/{{ memberPackage.unit }}</span>
        </div>
        <div
          v-ans="{ event_id: 'visitor_click_buy', namespace }"
          @click="toBuy"
          class="package-btn"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import ans from "@/utils/ans";
import { mapActions, mapMutations, mapState } from "vuex";

import {
  getIndexCategory,
  getIndexClassList,
  getPackageList,
  getPaymethod,
} from "@/apis/product";
import benefitMixin from "@/mixins/benefitMixin";
import { toPayV2 } from "@/helper/pay";

export default {
  mixins: [benefitMixin],
  components: {},
  props: ["namespace"],
  data() {
    return {
      catesgoryList: [],
      memberPackage: {},
      payMethod: {},
      current: 0,
      countDown: 60 * 10 * 1000,
    };
  },
  async mounted() {
    ans.pageView("page_visitor", {
      namespace: this.namespace,
      source: this.source,
    });
    const loading = this.$loading();
    try {
      await this.init();
    } finally {
      loading.clear();
    }
  },
  computed: {
    ...mapState(["source", "userInfo", "token"]),
    aggrement() {
      const temp = {
        wg: "金尊提额卡",
        quanmin: "金尊提额卡",
        ydh1: "如易超级会员",
        ydh2: "尊易超级会员",
      };
      return temp[this.namespace] || this.memberPackage.name;
    },
    packageEntrys() {
      const list = [
        {
          name: "升级半年卡",
          namespace: "quanmin06",
        },
        {
          name: "前往季卡",
          namespace: "quanmin03",
        },
      ];
      if (this.namespace !== list[0].namespace) {
        list.reverse();
      }
      return list;
    },
    entry() {
      return this.packageEntrys.find((r) => r.namespace !== this.namespace);
    },
    classNs() {
      const ns_enum = {
        qmfqy06: "quanmin06",
        qmqb1: "qmwallet1",
        qmydh1: "ydh1",
      };

      return ns_enum[this.namespace] || this.namespace;
    },
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    ...mapMutations(["changeState"]),
    async init() {
      const loading = this.$loading();
      try {
        await Promise.all([this.fetchCategory(), this.fetchPackage()]);
      } finally {
        loading.clear();
      }
    },
    clickHandle(item) {
      if (item.display_code !== "z5_privileges") {
        this.toBuy();
      } else {
        this.toDetail(item);
      }
    },
    finishCountDown() {
      this.$refs.countdown.reset();
    },
    toBuy() {
      let url = `${location.origin}/#/quanmin`;
      const return_url = encodeURIComponent(url);

      toPayV2(this.memberPackage, this.payMethod.channel, {
        order_source: encodeURIComponent("分期易"),
        partner_jump_url: return_url,
        partner_url: return_url,
      });
    },
    // 橡树协议
    jumpAgreement() {
      location.href = "https://cdn.xiangshuheika.com/h5/agreement/qm.html";
    },

    async fetchPackage() {
      const [methods, res] = await Promise.all([
        getPaymethod({
          namespace: this.namespace,
          product_type: this.namespace.toUpperCase(),
          source: "default",
        }),
        getPackageList({
          namespace: this.namespace,
          source: this.source,
        }),
      ]);
      this.payMethod = methods.length ? methods[0] : {};
      this.memberPackage = res.data.length ? res.data[0] : {};
    },

    async fetchCategory() {
      const ns_enum = {
        weiguang: "quanmin06",
        quanmin: "wg",
      };
      const catesgory = await getIndexCategory({
        namespace: ns_enum[this.namespace] || this.namespace,
        opened_page: "visitor",
        source: this.source,
      });
      const catesgoryList = catesgory.data;
      const productList = await getIndexClassList({
        title: catesgoryList.map((r) => r.name).join(","),
        opened_page: "visitor",
        namespace: ns_enum[this.namespace] || this.namespace,
      });
      catesgoryList.forEach((item, index) => {
        item.list = productList.data[item.name];
      });
      this.catesgoryList = catesgoryList;
    },
  },
};
</script>