var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"redeem yst"},[_c('div',{staticClass:"top"},[_c('van-image',{attrs:{"src":"https://common-h5-dev.xiangshuheika.com/static/upload/2024082626c064cb203a.jpg"}})],1),_c('div',{staticClass:"container"},[_c('van-image',{staticClass:"redeem-title-img",attrs:{"src":"https://common-h5-dev.xiangshuheika.com/static/upload/20240613239071f3ca91.png"}}),_c('div',{staticClass:"redeem-view"},[_c('van-field',{staticClass:"redeem-input",attrs:{"input-align":"center","placeholder":"请输入正确的兑换码"},model:{value:(_vm.inputVal),callback:function ($$v) {_vm.inputVal=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"inputVal"}}),_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'redeem_list_button',
        }),expression:"{\n          event_id: 'redeem_list_button',\n        }"}],attrs:{"round":"","size":"large","block":"","type":"info"},on:{"click":_vm.redeem}},[_vm._v(" 立即兑换 ")])],1),_c('van-image',{staticClass:"redeem-title-img",attrs:{"src":"https://common-h5-dev.xiangshuheika.com/static/upload/20240613467740e0f913d.png"}}),_c('van-skeleton',{attrs:{"title":"","row":4,"loading":_vm.loading}},[(_vm.empty)?_c('van-empty',{staticClass:"empty",attrs:{"image":"https://common-h5-dev.xiangshuheika.com/static/upload/20240613ca363aae848c2.png"}}):_vm._e(),_c('div',{staticClass:"list"},_vm._l((_vm.cards),function(card){return _c('div',{key:card.user_id,staticClass:"card"},[_c('van-image',{staticClass:"card-image",attrs:{"src":card.logo_img_url}}),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-name"},[_vm._v(_vm._s(card.card_type_cn))]),_c('div',{staticClass:"card-facevalue"},[_vm._v(_vm._s(card.subtitle))]),_c('div',{staticClass:"card-exprie"},[_vm._v(" 有效期："+_vm._s(_vm._f("formatDate")(card.t_created,"YYYY.MM.DD"))+" - "+_vm._s(_vm._f("formatDate")(card.t_expiration,"YYYY.MM.DD"))+" ")])]),_c('div',{staticClass:"card-right"},[_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
                event_id: 'card_list_click',
                card_name: card.card_type,
              }),expression:"{\n                event_id: 'card_list_click',\n                card_name: card.card_type,\n              }"}],staticClass:"to-use",attrs:{"round":"","block":"","disabled":card.card_status !== 'active'},on:{"click":function($event){return _vm.toBenefit(card)}}},[_vm._v("去使用")]),(card.amount !== -1)?_c('div',{staticClass:"time"},[_vm._v(" 剩"+_vm._s(card.amount)+"次 ")]):_vm._e()],1)],1)}),0)],1)],1),_c('van-dialog',{staticClass:"redeem-dialog",attrs:{"title":"恭喜您已获得","show-cancel-button":false,"show-confirm-button":false},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"redeem-msg"},[_vm._v(_vm._s(_vm.redeemCard.card_type_cn))]),_c('van-image',{staticClass:"redeem-icon",attrs:{"src":"https://common-h5-dev.xiangshuheika.com/static/upload/20240613f9782e3fb8377.png"}}),_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'dialog_redeem_use_click',
      }),expression:"{\n        event_id: 'dialog_redeem_use_click',\n      }"}],staticClass:"to-use",attrs:{"block":"","round":""},on:{"click":function($event){return _vm.toBenefit(_vm.redeemCard)}}},[_vm._v("去使用")]),_c('div',{staticClass:"dialog-close-icon",on:{"click":function($event){_vm.show = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }