<template>
  <div class="cards">
    <div class="card-record"></div>
    <van-skeleton title :row="4" :loading="loading">
      <template v-if="!empty">
        <div
          class="card"
          v-for="card in cards"
          :key="card.user_id"
          v-ans="{
            event_id: 'promotion_card_click',
            card_name: card.card_type,
          }"
          @click="toBenefit(card)"
        >
          <div class="card-content">
            <div class="card-name">{{ card.card_type_cn }}</div>
            <van-image class="card-image" :src="card.logo_img_url"></van-image>
          </div>
          <div class="card-bottom">
            <div class="card-exprie">
              会员有效期：{{ card.t_created | formatDate("YYYY.MM.DD") }} -
              {{ card.t_expiration | formatDate("YYYY.MM.DD") }}
            </div>
            <div class="card-btn">查看权益 ></div>
          </div>
        </div>
      </template>
      <van-empty
        v-else
        :image="require('@/assets/images/promotion_empty.png')"
        :image-size="118"
        description="您还没有开通会员"
      />
    </van-skeleton>
  </div>
</template>

<script>
import { getMutilCardByNg } from "@/apis/product";
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      loading: true,
      cards: [],
      empty: false,
    };
  },
  async mounted() {
    try {
      const res = await getMutilCardByNg();
      this.cards = res.data;
      this.empty = res.data.length === 0;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(["initProjectData", "fetchUserInfo"]),
    ...mapMutations(["changeState"]),
    async toBenefit(card) {
      const loading = this.$loading();
      this.changeState({ token: card.token });
      await this.fetchUserInfo();
      await this.initProjectData();
      loading.clear();
      this.$router.push("/promotion/benefit");
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  margin-bottom: 10px;
  background: linear-gradient(99deg, #fef5e2 0%, #fff0d2 100%);
  border-radius: 13px;
  padding: 8px;
  box-sizing: border-box;
}
.card-record {
  width: 134px;
  height: 25px;
  margin: 10px auto;
  background: url("~@/assets/images/promotion_member_record.png") no-repeat
    center/100%;
}
.card-image {
  margin-left: auto;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background: #f4f5f7;
}
.card-content {
  padding: 6px 8px 0 12px;
  margin-bottom: 36px;
  display: flex;
  .card-name {
    margin-top: 3px;
    font-size: 14px;
    color: #333;
    line-height: 20px;
    font-weight: bold;
  }
  .card-exprie {
    font-size: 14px;
    color: #666;
  }
}
.card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  background: rgba(#ead199, 0.25);
  border-radius: 10px;
  padding: 0 13px;
  font-size: 10px;
}
.card-exprie {
  color: #666;
}
.card-btn {
  color: #333;
}
</style>