var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"room-info"},[_c('div',{staticClass:"room-checkdate",on:{"click":_vm.changeDate}},[_c('div',{staticClass:"checkin-date"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.hotel.checkin_date,"MM月DD日"))+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("week")(_vm.hotel.checkin_date)))]),_c('div',{staticClass:"checkin-night"},[_vm._v(_vm._s(_vm.interNight)+"晚")]),_c('div',{staticClass:"checkin-date"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.hotel.checkout_date,"MM月DD日"))+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("week")(_vm.hotel.checkout_date)))]),_c('div',{staticClass:"room-price-tag"},[_c('svg-icon',{staticClass:"hotelpricetop",attrs:{"iconClass":"hotelpricetop"}}),_vm._v("已含税总价 ")],1)]),_c('div',{staticClass:"rooms"},_vm._l((_vm.detail.rooms),function(item){return _c('div',{key:item.id,staticClass:"room-item"},[_c('van-image',{staticClass:"room-image",attrs:{"lazy-load":"","src":item.images[0],"fit":"cover"},on:{"click":function($event){return _vm.previewImage(item.images[0])}}}),_c('div',{staticClass:"room-desc"},[_c('div',{staticClass:"room-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"room-tag"},[_vm._v(_vm._s(item.bed_type))]),_c('div',{staticClass:"room-tag"},[_c('span',{class:item.prices[0].breakfast},[_vm._v(_vm._s(item.prices[0].breakfast))]),_vm._v(" "+_vm._s(_vm.detail.pay_method === 1 ? "不可取消" : "入住前1天16点前可免费取消")+" ")]),_c('div',{staticClass:"room-price"},[_c('div',{staticClass:"room-price-info"},[(item.prices[0].origin_price)?_c('div',{staticClass:"room-facevalue"},[_vm._v(" ¥"+_vm._s(item.prices[0].origin_price)+" ")]):_vm._e(),_c('div',{staticClass:"price"},[(item.prices[0].origin_price)?[_vm._v("会员价")]:_vm._e(),_vm._v(" ¥"),_c('span',[_vm._v(_vm._s(item.prices[0].price))])],2)]),_c('van-image',{directives:[{name:"ans",rawName:"v-ans",value:({
              event_id: 'hotel_detail_click',
              destination_name: _vm.hotel.city,
              checkin_date: _vm.dayjs(_vm.detail.checkin_date).format('YYYY-MM-DD'),
              company_id: _vm.detail.company[0].id,
              company_name: _vm.detail.company[0].name,
              hotel_type: _vm.detail.classification,
              hotel_id: _vm.detail.id,
              hotel_name: _vm.detail.name,
              rooms_id: item.prices[0].id,
              rooms_name: item.prices[0].name,
              rooms_price: item.prices[0].price + '',
              is_direct_sign: _vm.detail.is_direct_sign,
              is_special: _vm.detail.is_special,
              pay_method: _vm.detail.pay_method,
            }),expression:"{\n              event_id: 'hotel_detail_click',\n              destination_name: hotel.city,\n              checkin_date: dayjs(detail.checkin_date).format('YYYY-MM-DD'),\n              company_id: detail.company[0].id,\n              company_name: detail.company[0].name,\n              hotel_type: detail.classification,\n              hotel_id: detail.id,\n              hotel_name: detail.name,\n              rooms_id: item.prices[0].id,\n              rooms_name: item.prices[0].name,\n              rooms_price: item.prices[0].price + '',\n              is_direct_sign: detail.is_direct_sign,\n              is_special: detail.is_special,\n              pay_method: detail.pay_method,\n            }"}],staticClass:"room-btn",attrs:{"fit":"cover","src":require(("@/assets/images/hotel/" + (item.is_date_unavailable
                  ? 'no-book'
                  : ("btn-book" + (_vm.detail.pay_method))) + ".png"))},on:{"click":function($event){return _vm.bookHotel(item)}}})],1)])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }