<template>
  <main class="help-center">
    <van-image
      class="help-image"
      lazy-load
      :src="require('../../assets/images/help.png')"
    />
    <div v-if="showCol && !$route.query.group">
      <van-cell
        v-for="item in list"
        :title="item.group"
        :key="item.id"
        is-link
        :to="`/help?group=${item.group}`"
      />
    </div>
    <van-collapse v-else v-model="activeNames">
      <van-collapse-item
        v-for="item in answers"
        :key="item.title"
        :title="item.title"
        :name="item.title"
      >
        <template #title>
          <div class="help-title">{{ item.title }}</div>
        </template>
        <div class="help-content" v-html="item.content"></div>
      </van-collapse-item>
    </van-collapse>
  </main>
</template>

<script>
import { getHelpListV2, getOrderList } from "@/apis/home";
import { mapGetters, mapState } from "vuex";
import { storage } from "@/utils";
import uniqWith from "lodash.uniqwith";
import { getProjectDivision } from "@/utils";

export default {
  components: {},
  data() {
    return {
      answers: [],
      activeNames: [],
      list: [],
    };
  },
  computed: {
    ...mapState(["namespace"]),
    ...mapGetters(["namespaceGroup"]),
    // 小赢跟省呗显示2层问题
    showCol() {
      return this.namespace === "sy" || this.namespace.startsWith("xy");
    },
  },

  async mounted() {
    this.getHelp();
    let isJump = true;
    if (this.namespace === "xy" && storage.getItem("token")) {
      const res = await getOrderList({
        source: "网络支付,api",
        status: "success",
        category_type: "vip",
      });
      if (
        res.data.res_list[0]?.type &&
        ["XY03", "XY06", "XY12", "XF-XY03", "XF-XY06", "XF-XY12"].includes(
          res.data.res_list[0].type
        )
      ) {
        isJump = false;
      }
    }
    const centerEl = document.querySelector(".help-center");
    centerEl.addEventListener(
      "click",
      (e) => {
        let target = e.target;
        while (target !== centerEl) {
          if (target.nodeName !== "A") {
            target = target.parentNode;
          } else {
            break;
          }
        }
        if (target?.href) {
          // 转换a标签
          e.preventDefault();
          const temp = {
            namespace: this.namespace,
            user_token: storage.getItem("token"),
            return_url: encodeURIComponent(location.origin + location.pathname),
            path: encodeURIComponent(location.hash),
          };
          const url = target.href.replace(/{(\w+)}/g, (str, $) => {
            return temp[$];
          });
          if (isJump) {
            location.href = url;
          } else {
            this.$alert({
              message:
                "小赢尊享卡订单的退款，请返回上一页面咨询在线客服或电话客服400-150-9669",
            }).then(() => {
              this.$router.go(-2);
            });
            return false;
          }
        }
      },
      false
    );
  },
  methods: {
    async getHelp() {
      const loading = this.$loading();
      const { group, category } = this.$route.query;
      const name = (category || "").replace("vip", "");
      const project_name = getProjectDivision(this.namespace || name);
      try {
        const res = await getHelpListV2({
          namespace_name: this.namespace || name,
          project_name,
        });
        if (this.showCol) {
          this.list = res.data;
          this.answers = res.data.find((r) => r.group === group)?.data || [];
        } else {
          this.answers = uniqWith(
            res.data.reduce((a, b) => {
              return a.concat(b.data);
            }, []),
            (a, b) => a.title === b.title && a.content === b.content
          );
        }
      } finally {
        loading.clear();
      }
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.help-center {
  padding-bottom: 20px;
  /deep/ .van-cell {
    align-items: center;
  }
  .help-image {
    height: 70px;
  }
  .help-title {
    line-height: 36px;
  }
  .help-content {
    /deep/ table {
      max-width: 100% !important;
    }
  }
  .auto-renew {
    text-align: center;
    color: @blue-color;
    padding-top: 20px;
  }
}
</style>