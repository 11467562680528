
export default [
  {
    path: '/offlineBenefit/park/list',
    name: 'offline-park-list',
    component: require('@/views/offlineBenefit/park/list').default,
    meta: {
      title: '乐园',
      pageName: '乐园',
      page: 'page_offline-park_list',

    }
  }, {
    path: '/offlineBenefit/park/detail',
    name: 'offline-park-detail',
    component: require('@/views/offlineBenefit/park/detail').default,
    meta: {
      title: '乐园详情',
      pageName: '乐园详情',
      page: 'page_offline-park_detail',
    }
  }, {
    path: '/offlineBenefit/park/book',
    name: 'offline-park-book',
    component: require('@/views/offlineBenefit/park/book').default,
    meta: {
      title: '预定',
      pageName: '预定',
      page: 'page_offline-park_book',
    }
  }]