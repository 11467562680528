<template>
  <main class="service" v-if="!loading">
    <div class="service-member" v-if="token && isMember">
      <p class="text" v-if="orders.length">
        亲爱的会员，为了更好更快帮您解决问题，<br /><span
          >请复制您需要咨询的订单，</span
        >并主动粘贴给到客服
      </p>
      <div v-else>
        <van-image
          style="width: 100px; height: 100px"
          class="service-nomember-img"
          :src="require('../../assets/images/service-nomember-icon.png')"
        />
        <p class="text">亲爱的会员，您可以点击下方按钮咨询客服</p>
      </div>
      <div class="service-order" v-if="orders.length">
        <div
          :class="{
            'service-order-item': true,
            active: active === item.order_number
          }"
          @click="active = item.order_number"
          v-for="item in orders"
          :key="item.id"
        >
          <div class="service-order-left">
            <van-image
              class="order-image"
              lazy-load
              fit="cover"
              :data-src="getImageUrl(item)"
              :src="getImageUrl(item)"
            />
          </div>
          <div class="service-order-right">
            <div class="service-order-name ellipsis">
              {{ item.type === "hotel" ? "5-8折住酒店" : item.name }}
            </div>
            <div class="service-order-date">
              下单时间：{{ item.t_created | formatDate("YYYY.MM.DD HH:mm:ss") }}
            </div>
            <div class="service-order-number">
              <span class="service-order-text ellipsis"
                >订单编号：{{ item.order_number }}</span
              >
              <span class="copy-icon" @click="copy(item.order_number)"
                >复制</span
              >
            </div>
            <!-- <div class="service-radio" /> -->
          </div>
        </div>
      </div>
    </div>

    <div class="service-nomember" v-else>
      <van-image
        class="service-nomember-img"
        :src="require('../../assets/images/service-nomember-icon.png')"
      />
      <div class="service-nomember-text">
        尊敬的用户，您还不是会员<br />立即开通会员，您可享受更多优惠
      </div>
    </div>

    <div class="button-wrap">
      <router-link
        v-if="$route.query.type !== 'service'"
        to="/help"
        class="flex-item button-help"
        v-ans="{ event_id: 'qa_click_faq' }"
        ><span>常见问题</span></router-link
      >
      <span
        class="flex-item button-udesk"
        @click="toServer"
        v-ans="{
          event_id: 'qa_click_consult',
          order_id: order_number
        }"
        ><span>咨询客服</span></span
      >
    </div>
  </main>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { jsonSafeParse, storage } from "@/utils";
import { getMemberInfo, getOrder } from "@/apis";
import orderby from "lodash.orderby";
import ans from "@/utils/ans";

export default {
  data() {
    return {
      loading: true,
      token: storage.getItem("token"),
      orders: [],
      active: "",
      order_number: null
    };
  },
  computed: {
    ...mapGetters(["namespaceGroup"]),
    ...mapState(["userInfo", "isMember", "namespace"])
  },
  async mounted() {
    if (this.token && this.isMember) {
      const { order_number } = this.$route.query;
      const opts = {};
      if (order_number) {
        opts.order_id = order_number;
      }
      ans.pageView(this.$route.meta.page, opts);
      this.fetchList();
      this.order_number = order_number;
    } else {
      this.loading = false;
    }
  },
  methods: {
    async fetchList() {
      const loading = this.$loading();
      let opts = {
        source: "网络支付,api",
        status: "success",
        valid_order: "True"
      };
      try {
        const res = await getOrder(opts);
        const orders = res.data.res_list
          .filter(r => r.category_type === "vip")
          .map(item => ({ ...item, payInfo: JSON.parse(item.pay_info) }));
        this.orders = orderby(orders, ["t_created"], ["desc"]).slice(0, 2);
      } finally {
        this.loading = false;
        loading.clear();
      }
    },
    copy(order_number) {
      this.$copyText(order_number).then(() => {
        this.$toast("订单号已复制");
      });
    },
    async toServer() {
      if (this.token && this.isMember && !this.active && this.orders.length) {
        this.$toast("您还没有选择需要咨询的订单，请重新选择");
        return;
      }
      let url = "https://oakvip.s2.udesk.cn/im_client/?web_plugin_id=28870";
      if (this.isMember) {
        const res = await getMemberInfo();
        url = res.data.service_url;
      }
      const order = this.orders.find(item => item.order_number === this.active);
      if (this.active) {
        location.href = `${url}&hide_product=true&product_image=${encodeURIComponent(
          order.category_type === "benefit"
            ? this.getImageUrl(order)
            : "https://cdn.xiangshuheika.com/static/common/default.png"
        )}&product_订单号=${
          order.order_number
        }&product_send=false&product_title=${encodeURIComponent(order.name)}`;
      } else {
        location.href = url;
      }
    },
    getImageUrl(item) {
      let iconname = item.payInfo.detail_type || item.type;
      if (item.type === "atour") {
        iconname = item.type;
      }
      return `https://cdn.xiangshuheika.com/static/${this.namespaceGroup}/${iconname}.png`;
    }
  }
};
</script>

<style lang="less" scoped>
.service {
  min-height: 100vh;
  background: #f5f5f5;
  padding: 12px;
  box-sizing: border-box;
  .text {
    padding: 14px 13px 0;
    line-height: 22px;
    font-size: 14px;
    margin-bottom: 16px;
    color: 333;
    text-align: center;
    span {
      font-weight: bold;
      color: #ff5001;
    }
  }
}

.service-member {
  background: #fff;
  border-radius: 10px;
  padding: 12px 10px;
  margin-bottom: 12px;
}
.service-order {
  height: 180px;
  overflow: auto;
}
.service-order-item {
  height: 78px;
  display: flex;
  background: #eeeeee;
  border-radius: 8px;
  &:not(:first-child) {
    margin-top: 10px;
  }
  &.active {
    background: linear-gradient(90deg, #ffc360 0%, #ffeba6 100%);
    .service-radio {
      border-color: #df5627;
      &::after {
        content: "";
        position: absolute;
        left: 1px;
        top: 1px;
        right: 1px;
        bottom: 1px;
        background: #df5627;
        border-radius: 50%;
      }
    }
  }
}
.service-order-name {
  line-height: 22px;
  font-weight: bold;
  color: #333;
  font-size: 16px;
  margin-bottom: 1px;
}

.service-order-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-left: 10px;
  position: relative;
  flex: 1;
}

.service-order-date {
  line-height: 17px;
  margin-bottom: 1px;
  color: #333;
}
.service-order-number {
  line-height: 17px;
  color: #999;
}
.service-order-left {
  flex-basis: 70px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/ .van-image {
    width: 36px;
  }
  border-right: 1px dashed #fff;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    right: -5px;
    top: -5px;
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    right: -5px;
    bottom: -5px;
    z-index: 1;
  }
}
.service-radio {
  position: absolute;
  right: 8px;
  top: 33px;
  width: 12px;
  height: 12px;
  border: 1px solid #999;
  background: #fff;
  border-radius: 50%;
}

.button-wrap {
  display: flex;
  height: 54px;
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
}
.flex-item {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #333;
  text-align: center;
  height: 24px;
  &:first-child {
    border-right: 1px solid #f5f5f5;
  }
  span {
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: left center;
    padding-left: 28px;
    line-height: 24px;
    display: inline-block;
  }
}

.button-help {
  span {
    background-image: url("../../assets/images/service-help-icon.png");
  }
}
.button-udesk {
  span {
    background-image: url("../../assets/images/service-udesk-icon.png");
  }
}
.service-nomember {
  background: #fff;
  border-radius: 10px;
  padding: 12px 10px;
  margin-bottom: 12px;
  height: 267px;
  box-sizing: border-box;
}
.service-nomember-img {
  margin: 18px auto 12px;
  width: 138px;
  height: 138px;
}
.service-nomember-text {
  padding: 0 40px;
  color: #333;
  line-height: 26px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
.order-image {
  width: 36px;
  height: 36px;
}

.service-order-number {
  display: flex;
  align-items: center;
}

.copy-icon {
  color: #fff;
  background: #999;
  display: block;
  flex-shrink: 0;
  width: 28px;
  height: 13px;
  line-height: 13px;
  border-radius: 2px;
  text-align: center;
  position: relative;
  font-size: 10px;
  margin-right: 6px;
  &::after {
    content: "";
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px;
  }
}
.service-order-text {
  flex: 1;
}
</style>
