<template>
  <div class="xp-home" v-if="pageshow">
    <div class="card">
      <img
        :src="`https://cdn.xiangshuheika.com/static/${namespace}/card.png`"
        alt=""
      />
      <div class="info">
        <van-image
          class="img"
          :src="`https://cdn.xiangshuheika.com/static/${namespace}/headimg.png`"
        />
        <div class="text">
          <h4>{{ phone }}</h4>
          <p>有效期至{{ userBenefit.card_expiry_date.replace(/-/g, ".") }}</p>
        </div>
      </div>
      <van-image
        class="benefit-logo"
        fit="cover"
        lazy-load
        :src="firstBenefit.display_img_url"
      />
      <div
        class="other"
        @click="toIndex"
        v-ans="{
          event_id: 'member_click_others',
          source,
          namespace,
        }"
      >
        其他联合会员
      </div>
    </div>
    <div class="container" v-if="master_benefit_info.amount > 0">
      <div class="jiao">未激活</div>
      <div
        v-html="
          JSON.parse(this.$getConfigValue('MASTER_CARD_INFO'))[
            master_benefit.product_code
          ]
        "
      ></div>
      <div
        class="btn"
        @click="$router.push('/xiaopai/activation')"
        v-ans="{
          event_id: 'member_click_jihuo',
          source,
          namespace,
        }"
      >
        去激活
      </div>
    </div>
    <div class="container">
      <div class="title">
        生活权益免费领 <span>({{ lifeBenefits.desc }})</span>
      </div>
      <ul>
        <li v-for="item in lifeBenefits.list" :key="item.id">
          <div class="price">
            <sub>¥</sub
            >{{
              item.lowest_face_value *
              getCoundFromSellpoint(item.selling_points)
            }}
          </div>
          <van-image class="img" :src="item.display_img_url" />
          <div class="name">{{ item.desc }}{{ item.lowest_face_value }}元</div>
          <p>{{ item.selling_points.split("x")[0] }}</p>
          <div class="get" @click="toDetail(item)">立即领取</div>
        </li>
      </ul>
    </div>
    <div class="container" v-if="master_benefit_info.amount <= 0">
      <div class="jiao">已激活</div>
      <div
        v-html="
          JSON.parse(this.$getConfigValue('MASTER_CARD_INFO'))[
            master_benefit.product_code
          ]
        "
      ></div>
    </div>
    <div class="footer">
      <div class="item" @click="$router.push('/order')">
        <van-image
          class="img"
          :src="require('../../assets/images/icon-order2.png')"
        />
        我的订单
      </div>
      <div class="item" @click="$router.push('/service')">
        <van-image
          class="img"
          :src="require('../../assets/images/server.png')"
        />
        在线客服
      </div>
    </div>
    <van-action-sheet v-model="show">
      <div class="content">
        <div class="top">
          {{ currentPackage.name }}
          <p><span>¥</span>{{ currentPackage.price }}</p>
        </div>
        <div class="method-tit">支付方式</div>
        <div class="methods">
          <div
            class="item"
            :key="index"
            v-for="(item, index) in payMethods"
            @click="changeMethod(index)"
          >
            <div class="left">
              <van-image class="pay-item-icon" :src="item.icon" />
              {{ item.name }}
            </div>
            <img
              :src="
                require(`../../assets/images/${
                  selected_method.channel === item.channel
                    ? 'selected'
                    : 'select'
                }.png`)
              "
              alt=""
            />
          </div>
        </div>
        <div class="agreement">
          点击支付开通会员即视为同意<span @click="agreement"
            >《会员服务协议》</span
          >
        </div>
        <div class="btn" @click="open">立即开通</div>
        <van-image
          class="close"
          @click="show = false"
          :src="require('../../assets/images/close.png')"
        />
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getIndexCategory,
  getIndexClassList,
  getPackageList,
  getPaymethod,
} from "@/apis/product";
import { getUserBenefit } from "@/apis/home";
import { getMemberInfo } from "@/apis";
import { toPayV2 } from "@/helper/pay";
export default {
  data() {
    return {
      pageshow: false,
      life_benefit: [],
      userBenefit: {},
      benefit_list: [],
      show: false,
      payMethods: [],
      currentPackage: {},
      master_benefit: {},
      selected_method: {},
      master_benefit_info: {},
      catesgoryList: [],
    };
  },
  computed: {
    ...mapState(["userInfo", "source", "namespace", "ptoken"]),
    phone() {
      return this.userInfo.login_phone_number
        .split(".")[0]
        .replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
    },
    lifeBenefits() {
      const arr = JSON.parse(JSON.stringify(this.catesgoryList));
      const item =
        arr.find((item) =>
          item.display_code.includes(this.userInfo.card_type.toLowerCase())
        ) || {};
      if (item?.list?.length) {
        item.list = item.list.slice(1);
      }
      return item;
    },
    firstBenefit() {
      let res = {};
      const item =
        this.catesgoryList.find((item) =>
          item.display_code.includes(this.userInfo.card_type.toLowerCase())
        ) || {};
      if (item?.list?.length) {
        res = item.list[0];
      }
      return res;
    },
  },
  async mounted() {
    const loading = this.$loading();
    const res2 = await getUserBenefit();
    this.userBenefit = res2.data;
    this.benefit_list = res2.data.benefit_list;
    const res = await getIndexCategory({
      namespace: this.namespace,
      opened_page: `xiaopai_member_${this.userInfo.card_type.toLowerCase()}`,
      source: this.source,
    });
    const catesgorys = res.data;
    const list = await getIndexClassList({
      title: catesgorys.map((r) => r.name).join(","),
      opened_page: `xiaopai_member_${this.userInfo.card_type.toLowerCase()}`,
      namespace: this.namespace,
    });
    catesgorys.forEach((item) => {
      item.list = [...list.data[item.name]];
    });
    this.catesgoryList = catesgorys;
    this.master_benefit = list.data["生活特权"].shift();
    this.master_benefit_info = this.benefit_list.filter(
      (r) => r.type === this.master_benefit.product_code
    )[0];
    this.life_benefit = list.data["生活特权"];
    this.pageshow = true;
    loading.clear();

    this.fetchPackage();
  },
  methods: {
    getCoundFromSellpoint(str) {
      const text = str.split("x")[1] || "";
      return Number(text.match(/(\d+)/)?.[0] || 0);
    },
    async fetchPackage() {
      const [methods, res] = await Promise.all([
        getPaymethod({
          namespace: this.namespace,
          product_type: this.namespace.toUpperCase(),
          source: "default",
        }),
        getPackageList({
          namespace: this.namespace,
          source: this.source,
        }),
      ]);
      this.currentPackage = res.data.filter(
        (r) => r.type === this.userInfo.card_type
      )[0];
      this.payMethods = methods.filter((r) => r.channel.includes("xiaopai"));
      this.selected_method = this.payMethods[0];
      this.currentChannel = this.payMethods[0].channel;
    },
    toIndex() {
      this.$router.push("/xiaopai/index?namespace=xiaopai");
    },
    async renewal() {
      const memberInfo = await getMemberInfo();
      if (!memberInfo.data.is_renewable) {
        this.$toast("当前续费次数已达到上限");
        return;
      } else {
        this.show = true;
      }
    },

    toDetail(item) {
      this.$router.push(`/benefitDetail?benefit_type=${item.product_code}`);
    },

    changeMethod(index) {
      this.selected_method = this.payMethods[index];
      this.currentChannel = this.payMethods[index].channel;
    },
    agreement() {
      window.location.href =
        "https://cdn.xiangshuheika.com/h5/agreement/pagj.html";
    },
    async open() {
      const { currentPackage, currentChannel } = this;
      let url =
        (window.location.origin + window.location.pathname).replace(/\/$/, "") +
        `/#/xiaopai/home`;
      const return_url = encodeURIComponent(url);
      toPayV2(currentPackage, currentChannel, {
        partner_jump_url: return_url,
        order_source: encodeURIComponent("联合会员超值购"),
        activity: currentPackage.item_code,
        partner_url: return_url,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.xp-home {
  padding: 12px;

  .card {
    position: relative;

    img {
      width: 100%;
      height: 145px;
    }

    .info {
      position: absolute;
      left: 14px;
      top: 16px;
      display: flex;

      .img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
      }

      .text {
        display: flex;
        flex-flow: column;
        color: #fff;
        justify-content: space-between;
        padding: 4px 0 4px 8px;

        h4 {
          font-weight: normal;
          font-size: 16px;
          margin: 0;
        }

        p {
          font-size: 12px;
        }
      }
    }

    .benefit-logo {
      width: 64px;
      height: 64px;
      position: absolute;
      right: 14px;
      top: 28px;
      border-radius: 50%;
      overflow: hidden;
    }

    .other {
      position: absolute;
      left: 14px;
      bottom: 18px;
      color: #fff;
      font-size: 14px;

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        right: -18px;
        top: 50%;
        transform: translateY(-50%);
        border-left: 6px solid #fff;
        border-top: 4px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 4px solid transparent;
      }
    }

    .renewal {
      position: absolute;
      right: 12px;
      bottom: 14px;
      width: 71px;
      height: 28px;
      border-radius: 28px;
      line-height: 28px;
      text-align: center;
      background: linear-gradient(180deg, #ffea8a 0%, #ffc83d 100%);
      color: #ea5524;
      font-size: 13px;
    }
  }

  .container {
    background-color: #fff;
    margin: 12px 0 0;
    border-radius: 10px;
    padding: 16px 14px 18px;
    position: relative;

    /deep/.title {
      text-align: center;
      font-size: 18px;
      color: #333;
      font-weight: bold;
      line-height: 25px;
      span {
        font-size: 14px;
        color: #999;
      }
    }

    .desc {
      margin-top: 4px;
      color: #fd5e01;
      font-size: 12px;
      text-align: center;
      margin-bottom: 16px;
    }

    .btn {
      height: 49px;
      line-height: 49px;
      text-align: center;
      color: #fff;
      background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
      border-radius: 49px;
      font-size: 18px;
      margin: 0 8px 0;
    }

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 16px;

      li {
        width: 49%;
        margin-bottom: 16px;
        position: relative;
        .img {
          width: 100%;
          height: 96px;
          border-radius: 5px;
          overflow: hidden;
        }

        .name {
          font-weight: bold;
          font-size: 15px;
          color: #333;
          margin-top: 10px;
          line-height: 21px;
        }

        .price {
          position: absolute;
          left: 10px;
          top: 10px;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          z-index: 1;
          sub {
            font-size: 0.5em;
            margin-right: 2px;
          }
        }

        p {
          margin-top: 4px;
          color: #333;
          font-size: 12px;
          line-height: 17px;
        }

        .get {
          margin-top: 10px;
          width: 88px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          border-radius: 28px;
          background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
          color: #fff;
          font-size: 13px;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    .item {
      background-color: #fff;
      height: 55px;
      border-radius: 10px;
      width: 49%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #333;

      .img {
        width: 22px;
        height: 22px;
        margin-right: 4px;
      }
    }
  }
}
.content {
  padding: 24px 12px 15px;
  position: relative;

  .top {
    display: flex;
    justify-content: space-between;
    padding: 14px 0;
    font-size: 16px;
    color: #222;
    border-bottom: 1px solid #f5f5f5;
    font-weight: bold;

    p {
      color: #ff5001;
      font-size: 20px;

      span {
        font-size: 12px;
      }
    }
  }

  .method-tit {
    font-size: 14px;
    color: #222;
    margin-top: 13px;
    font-weight: bold;
  }

  .methods {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      font-size: 12px;
      color: #333;
      font-weight: bold;

      img {
        width: 16px;
        height: 16px;
      }

      .left {
        display: flex;
        align-items: center;
      }

      .pay-item-icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }
  }

  .agreement {
    margin-top: 12px;
    color: #999;
    font-size: 12px;

    span {
      color: #0091ff;
    }
  }

  .btn {
    text-align: center;
    height: 49px;
    line-height: 49px;
    border-radius: 49px;
    color: #fff;
    font-size: 18px;
    background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
    margin: 13px 8px 0;
  }

  .close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 16px;
    height: 16px;
  }
}
/deep/.indent {
  padding-left: 13px;
}
/deep/.instructions {
  margin-top: 12px;
  color: #666;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 12px;
}
.jiao {
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
  border-radius: 8px 0px 8px 0px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  height: 21px;
  line-height: 21px;
  width: 60px;
}
</style>