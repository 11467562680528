import HotelLayout from '@/layout/HotelLayout';

export const newHotel = {
  path: '/hotel',
  name: 'hotel',
  component: HotelLayout,
  children: [
    {
      path: 'list',
      name: 'hotelList2',
      component: require('@/views/hotel/hotelList').default,
      meta: {
        title: '酒店预订',
        pageName: '酒店预订',
        page: 'page_hotel_list',
      }
    }, {
      path: 'detail',
      name: 'hotelDetail',
      component: require('@/views/hotel/hotelDetail').default,
      meta: {
        title: '酒店详情',
        pageName: '酒店详情',
        page: 'page_hotel_detail',

      }
    }, {
      path: 'book',
      name: 'hotelBook',
      component: require('@/views/hotel/hotelBook').default,
      meta: {
        page: 'page_hotel_book',
      }
    }, {
      path: 'imagePreview',
      name: 'imagePreview',
      component: require('@/views/hotel/imagePreview').default,
      meta: {
        page: 'page_imagePreview',
        title: '酒店图片',
        pageName: '酒店图片',
      }
    }
  ]
}