<template>
  <div class="kaishu">
    <van-image
      class="header"
      :src="require('@/assets/images/kaishu_header.jpg')"
    ></van-image>
    <div class="benefit" v-if="sku('kaishuvip-7days')">
      <van-image
        class="benefit-image"
        :src="require('@/assets/images/kaishu_benefit1.jpg')"
      ></van-image>
      <div
        @click="handleClick('kaishuvip-7days')"
        class="benefit-btn resizing run"
      ></div>
    </div>
    <div class="benefit" v-if="sku('kaishu-storys')">
      <van-image
        class="benefit-image"
        :src="require('@/assets/images/kaishu_benefit2.jpg')"
      ></van-image>
      <div
        @click="handleClick('kaishu-storys')"
        class="benefit-btn resizing run"
      ></div>
    </div>
    <div class="benefit" v-if="sku('kaishu-inventions')">
      <van-image
        class="benefit-image"
        :src="require('@/assets/images/kaishu_benefit3.jpg')"
      ></van-image>
      <div
        @click="handleClick('kaishu-inventions')"
        class="benefit-btn resizing run"
      ></div>
    </div>
    <div class="benefit">
      <van-image
        class="benefit-image"
        :src="require('@/assets/images/kaishu_benefit_detail1.jpg')"
      ></van-image>
    </div>
    <div class="benefit benefit-benefit-two">
      <van-image
        class="other-img"
        :src="require('@/assets/images/kaishu_benefit_detail2.jpg')"
      ></van-image>
      <div class="benefit-two-btn">
        <div
          @click="handleClick('kaishu-storys')"
          class="benefit-btn2 resizing run"
        ></div>
        <div
          @click="handleClick('kaishu-inventions')"
          class="benefit-btn2 resizing run"
        ></div>
      </div>
    </div>
    <div class="benefit benefit-benefit-big" v-if="sku('kaishuvip-7days')">
      <van-image
        class="other-img"
        :src="require('@/assets/images/kaishu_benefit_detail3.jpg')"
      ></van-image>
      <div
        @click="handleClick('kaishuvip-7days')"
        class="benefit-btn2 resizing run"
      ></div>
    </div>
  </div>
</template>

<script>
import { getSpuDetail } from "@/apis/home";
import { useRoute } from "@/components/use/base";
import router from "@/router";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import Vue from "vue";

const vm = Vue.prototype;
export default defineComponent({
  setup() {
    const route = useRoute();
    const spu = ref(null);
    const skus = ref([]);
    const sku = (item_code) => {
      return skus.value.find((sku) => sku.item_code === item_code);
    };
    onMounted(async () => {
      const res = await getSpuDetail({
        id: route.query.benefit_type,
        namespace: vm.$getConfigValue("KAISHU_STORY_BENEFIT_NAMESPACE"),
      });
      spu.value = res.data;
      skus.value = res.data.skus;
    });
    const handleClick = (item_code) => {
      router.push({
        path: "/h5Benefit/detail",
        query: {
          ...route.query,
          sku: encodeURIComponent(item_code),
        },
      });
    };
    return {
      handleClick,
      sku,
    };
  },
});
</script>


<style lang="less" scoped>
.kaishu {
  background-color: #93d857;
  min-height: 100vh;
}

.benefit {
  position: relative;
  .benefit-btn {
    width: 126px;
    height: 48px;
    position: absolute;
    bottom: 24px;
    left: 50%;
    margin-left: -63px;
    background: url("~@/assets/images/kaishu_benefit_btn1.png") no-repeat;
    background-size: 102%;
    border-radius: 33%;
    overflow: hidden;
  }
}

.benefit-two-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  display: flex;
  padding: 0 71px;
  justify-content: space-between;
}

.benefit-btn2 {
  width: 66px;
  height: 24px;
  background: url("~@/assets/images/kaishu_benefit_btn2.png") no-repeat;
  background-size: 100%;
}

.benefit-benefit-big {
  .benefit-btn2 {
    position: absolute;
    bottom: 74px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>