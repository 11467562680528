<template>
  <oakvip-overlay v-if="is_dialog" :z-index="100" v-model="showDialog">
    <van-image
      :class="['dialog-img', `dialog-img-${page}`]"
      @click="dialogJump"
      :src="img"
    />
  </oakvip-overlay>
  <div v-else-if="showDialog" class="promotion-content">
    <van-image
      fit="contain"
      :class="['promotion-img', `promotion-img-${page}`]"
      @click="dialogJump"
      :src="img"
    />
  </div>
</template>

  <script>
import { jsonSafeParse } from "@/utils";
import { getXyLifeToken } from "@/apis/home";

export default {
  props: {
    is_dialog: {
      default: () => true,
      type: Boolean,
    },
    page: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      showDialog: false,
      token: "",
      img: "",
    };
  },
  async mounted() {
    this.orderDialogConfig = jsonSafeParse(
      this.$getConfigValue(this.page.toUpperCase() + "_DIALOG")
    );
    if (this.orderDialogConfig.image_url) {
      const res = await getXyLifeToken({ dest_namespace: "xy_life" });
      if (res.data.is_conversion_eligible) {
        this.showDialog = true;
        this.img = this.orderDialogConfig.image_url;
        this.token = res.data.user_token;
      }
    }
  },
  methods: {
    dialogJump() {
      location.href =
        this.orderDialogConfig.url +
        `&user_token=${this.token}&source=${this.$route.query.from || ""}${
          this.page
        }`;
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-img {
  width: 312px;
  height: 336px;
  &.dialog-img-activity {
    width: 331px;
    height: 436px;
  }
}

.promotion-img {
  height: 108px;
}
</style>