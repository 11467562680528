var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPage),expression:"showPage"}],staticClass:"ak-book"},[_c('div',{staticClass:"title"},[_vm._v("所在城市")]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
      event_id: 'appiontment_click_city',
      namespace: _vm.namespace,
    }),expression:"{\n      event_id: 'appiontment_click_city',\n      namespace,\n    }"}],staticClass:"select picker",on:{"click":function($event){_vm.showCityList = true}}},[_vm._v(" "+_vm._s(_vm.current_city_name)+" ")]),_c('div',{staticClass:"shop-list"},_vm._l((_vm.shop_list),function(item,index){return _c('div',{key:item.id,class:{ item: true, active: _vm.shop_current === index },on:{"click":function($event){return _vm.selectShop(item.id, index)}}},[_c('div',[_c('h4',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.address))])])])}),0),_c('div',{staticClass:"footer"},[_c('div',{class:{ 'date-box': true, animate: _vm.next === 1 }},[_c('img',{staticClass:"close",attrs:{"src":require("../../assets/images/close.png"),"alt":""},on:{"click":function($event){_vm.next = 0}}}),_c('div',{staticClass:"title"},[_vm._v("预约体检时间")]),_c('p',{staticClass:"tips"},[_vm._v("*16点之前可预约明天的体检，16:00后仅可预订后天体检。")]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'appiontment_click_date',
          namespace: _vm.namespace,
        }),expression:"{\n          event_id: 'appiontment_click_date',\n          namespace,\n        }"}],staticClass:"select calendar",on:{"click":function($event){_vm.show = true}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDate),expression:"showDate"}]},[_vm._v(" "+_vm._s(_vm.dateFormat)),_c('span',[_vm._v(_vm._s(_vm.day))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showDate),expression:"!showDate"}]})])]),_c('div',{staticClass:"btn-box"},[_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'appiontment_click_next',
          namespace: _vm.namespace,
        }),expression:"{\n          event_id: 'appiontment_click_next',\n          namespace,\n        }"}],staticClass:"btn",on:{"click":_vm.toConfirm}},[_vm._v(" 下一步 ")])])]),_c('van-calendar',{attrs:{"show-confirm":false,"show-mark":false,"color":"#ff5001","formatter":_vm.formatDay,"min-date":_vm.minDate,"max-date":_vm.maxDate,"default-date":_vm.date},on:{"confirm":_vm.onConfirm},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCityList),expression:"showCityList"}],staticClass:"index-city-list"},[_c('van-index-bar',{attrs:{"index-list":_vm.indexList,"sticky":false}},[_vm._l((_vm.cityList),function(item){return [_c('van-index-anchor',{key:item.groupName,attrs:{"index":item.groupName}},[_vm._v(_vm._s(item.groupName))]),_vm._l((item.list),function(city){return _c('van-cell',{directives:[{name:"ans",rawName:"v-ans",value:({
            event_id: 'destination_click_city',
            destination_name: city,
          }),expression:"{\n            event_id: 'destination_click_city',\n            destination_name: city,\n          }"}],key:city,attrs:{"title":city},on:{"click":function($event){return _vm.setCity(city)}}})})]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }