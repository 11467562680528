import { Dialog } from 'vant';

function closeDialog(dialogClass) {
  const dialog = document.querySelector('.' + dialogClass);
  if (!dialog) {
    return;
  }
  if (dialog.querySelector('.dialog-close-icon')) {
    return;
  }
  const div = document.createElement('div');
  div.className = 'dialog-close-icon';
  div.onclick = function () {
    Dialog.close();
  };
  dialog.append(div);
}

export const confirm = (options) => {
  options.message = options.message || ' '
  const dialogClass = `dialog-${(Math.random() + '').slice(2, 10)}`;
  const dialog = Dialog.confirm(Object.assign({ theme: `confirm dialog-message-container common-dialog-confirm ${dialogClass}` }, options));
  setTimeout(() => {
    if (!('hideClose' in options)) {
      closeDialog(dialogClass);
    }
  }, 0);
  return dialog;
}

export const alert = (options) => {
  const dialogClass = `dialog-${(Math.random() + '').slice(2, 10)} ${options.className || ''}`;
  const dialog = Dialog.alert(Object.assign({ theme: `confirm dialog-message-container common-dialog-confirm ${dialogClass}` }, options));
  setTimeout(() => {
    if (!('hideClose' in options)) {
      closeDialog(dialogClass);
    }
  }, 0);
  return dialog;
}