<template>
  <section class="switch-text">
    <section
      class="switch-text-inner"
      ref="dom"
      :style="{
        maxHeight: showFold && fold ? maxHeight + 'px' : 'initial',
        overflow: 'hidden',
      }"
    >
      <slot></slot>
    </section>
    <div
      :class="{ 'switch-btn': true, unfold: !fold }"
      @click="toggle"
      v-if="showFold"
    >
      {{ fold ? "展开更多" : "收起" }}<svg-icon iconClass="arrow2"></svg-icon>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  onBeforeUnmount,
} from "@vue/composition-api";

export default defineComponent({
  props: {
    maxHeight: Number,
    defaultFold: {
      type: Boolean,
      default: true,
    },
  },
  setup({ maxHeight, defaultFold }) {
    const dom = ref(null);
    const showFold = ref(false);
    const fold = ref(defaultFold);
    const toggle = () => {
      fold.value = !fold.value;
    };

    const observer = ref(null);

    const computedDomRect = () => {
      const height = dom.value.getBoundingClientRect().height;
      if (height > maxHeight && !showFold.value) {
        showFold.value = true;
      }
    };

    const listenerImageLoad = () => {
      const images = dom.value.querySelectorAll("img");
      images.forEach((item) => {
        item.addEventListener("load", computedDomRect);
      });
    };

    onMounted(() => {
      observer.value = new MutationObserver(listenerImageLoad);
      observer.value.observe(dom.value, { childList: true, subtree: true });
    });

    onBeforeUnmount(() => {
      observer.value?.disconnect();
    });
    return {
      dom,
      showFold,
      fold,
      toggle,
    };
  },
});
</script>

<style lang="less" scoped>
.switch-btn {
  width: max-content;
  color: #999;

  font-size: 10px;
  line-height: 14px;
  margin: 0 auto;
  padding-top: 10px;
  /deep/ .svg-icon {
    font-size: 14px;
    vertical-align: -3px;
    transform: rotate(90deg);
    padding-left: 2px;
  }
  &.unfold {
    /deep/ .svg-icon {
      transform: rotate(-90deg);
    }
  }
}
</style>
