<template>
  <div class="home" v-if="!fetching">
    <div class="header">
      <section class="header-top">
        <router-link
          v-ans="{
            event_id: 'to_order_click',
          }"
          class="order"
          to="/order"
          >我的订单</router-link
        >
        <router-link
          v-ans="{
            event_id: 'to_service_click',
          }"
          class="service"
          to="/service"
          >在线客服</router-link
        >
      </section>
      <div class="validity">
        会员有效期至{{ card_info.card_expiry_date | formatDate("YYYY.MM.DD") }}
      </div>
    </div>
    <div class="benefit-body">
      <div class="benefit-list" v-if="card_info.card_status === 'active'">
        <div
          v-for="(item, seq) in benefit_list"
          class="benefit-item"
          :key="item.type"
        >
          <van-image
            class="benefit-item-icon"
            mode="aspectFit"
            :src="item.icon_url"
          />
          <div class="benefit-item-text">
            <div class="title">{{ item.title }}</div>
            <div class="subtitle van-multi-ellipsis--l3">
              {{ item.subtitle }}
            </div>
          </div>
          <div
            v-ans="{
              event_id: 'benefit_list_click',
              sequence: seq,
              spu_id: item.type,
              spu_name: item.title,
            }"
            class="benefit-item-btn"
            @click="jump(item)"
          >
            去使用
          </div>
          <div class="times">剩{{ item.amount + item.amount_reserved }}次</div>
        </div>
      </div>
      <div v-if="card_info.card_status === 'expired'" class="card-past">
        您的权益已过期
      </div>
      <div v-if="card_info.card_status === 'refund'" class="card-past">
        您的权益已退款
      </div>
    </div>
  </div>
</template>

<script>
import { getUserBenefit } from "@/apis/home";
import { mapMutations, mapState } from "vuex";

import { jsonSafeParse } from "@/utils";

export default {
  name: "index",
  components: {},
  data() {
    return {
      is_prod: process.env.VUE_APP_RUNTIME_ENV === "production",
      card_info: {
        current_cycle_expired_date: "",
        card_expiry_date: "",
        card_status: "expired",
      },
      benefit_list: [],
      fetching: true,
    };
  },
  computed: {
    ...mapState([
      "namespace",
      "namespaceInfo",
      "userInfo",
      "benefit_source",
      "package_name",
    ]),
  },
  methods: {
    ...mapMutations(["changeState"]),
    async jump(item) {
      this.$router.push(`/benefitDetail?benefit_type=${item.type}`);
    },
    getRichText(title) {
      const RED_TITKL_TEXT_ENUM = jsonSafeParse(
        this.$getConfigValue("RED_TITKL_TEXT_ENUM")
      );
      let result = title;
      RED_TITKL_TEXT_ENUM.forEach((item) => {
        if (title.indexOf(item) > -1) {
          result = title.replace(
            item,
            `<span style="color: #FF5001">${item}</span>`
          );
        }
      });
      return result;
    },
  },
  async mounted() {
    document.title = this.namespaceInfo.name_cn;
    const loading = this.$loading();
    try {
      const res = await getUserBenefit();
      if (res.data.benefit_order) {
        const orders = res.data.benefit_order.split(",");
        res.data.benefit_list.sort((a, b) => {
          return orders.indexOf(a.type) - orders.indexOf(b.type);
        });
      }
      this.benefit_list = (res.data.benefit_list || []).filter((r) => r.title);
      this.card_info = res.data;
    } finally {
      this.fetching = false;
      loading.clear();
    }
  },
};
</script>
<style scoped lang="less">
.home {
  background-color: #fff;
  min-height: 100vh;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.header {
  position: relative;
  height: 116px;
  background: url("https://common-h5-dev.xiangshuheika.com/static/upload/202408267112f4811e3ea.png")
    no-repeat center/contain;
  background-size: cover;
}

.header-top {
  position: absolute;
  right: 0;
  top: 4px;
  height: 21px;
  line-height: 21px;
  background: #fcdcc2;
  border-radius: 11px 0px 0px 11px;
  font-size: 11px;
  a {
    padding: 0 8px;
    color: #6e4b14;
    &:first-child {
      border-right: 1px solid rgba(#333, 0.1);
    }
  }
}
.validity {
  position: absolute;
  left: 40px;
  top: 84px;
  font-size: 12px;
  color: #ffd3bd;
}
.benefit-body {
  position: relative;
  z-index: 1;
  padding: 30px 16px;
}
.benefit-list {
  position: relative;
}
.benefit-item {
  position: relative;
  height: 112px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: url("~@/assets/images/yst_benefit_bg.png") no-repeat;
  background-size: cover;
  padding: 0 12px 0 33px;
  & + & {
    margin-top: 16px;
  }
}
.times {
  position: absolute;
  left: 0;
  top: 8px;
  width: 52px;
  height: 21px;
  line-height: 21px;
  background: url("~@/assets/images/yst_benefit_times_bg.png") no-repeat;
  background-size: 100%;
  font-size: 10px;
  text-align: center;
  color: #fff9e3;
}
.benefit-item-text {
  margin-right: 12px;
  flex: 1;
  .title {
    font-weight: bold;
    line-height: 20px;
    font-size: 14px;
    color: #202437;
    margin-bottom: 2px;
  }
  .subtitle {
    font-size: 12px;
    color: #7a7976;
    line-height: 18px;
  }
}
.benefit-item-icon {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  margin-right: 10px;
}

.benefit-item-btn {
  width: 60px;
  height: 26px;
  background: #fff;
  border-radius: 999px;
  background: linear-gradient(117deg, #f87530 0%, #f73308 100%), #fffefa;
  line-height: 26px;
  color: #fff;
  text-align: center;
  flex-shrink: 0;
}

.card-past {
  text-align: center;
  height: 195px;
  background-color: #fff;
  border-radius: 5px;
  margin: 12px 12px 20px;
  overflow: hidden;
  p {
    text-align: center;
    line-height: 1.5;
    color: #404040;
    margin-top: 17px;
  }
  /deep/ .van-image {
    width: 80px;
    height: 80px;
    margin: 56px auto 14px;
  }
}
</style>