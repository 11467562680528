<template>
  <div>
    <div class="tabbar-container">
      <div class="tabbar-panel">
        <router-view :key="key"></router-view>
      </div>
    </div>
    <div class="tabbar-bottom" v-if="showTabbar">
      <div class="tabbar">
        <router-link
          to="/business/home"
          href="javascript:;"
          active-class="tabbar-item-active"
          class="tabbar-item tabbar-item-index"
        >
          <div class="tabbar-item-icon"></div>
          <p class="tabbar-item-name">会员</p>
        </router-link>
        <router-link
          to="/business/coupon"
          href="javascript:;"
          active-class="tabbar-item-active"
          class="tabbar-item tabbar-item-card"
        >
          <div class="tabbar-item-icon"></div>
          <p class="tabbar-item-name">卡券</p>
        </router-link>
        <router-link
          to="/business/member"
          href="javascript:;"
          active-class="tabbar-item-active"
          class="tabbar-item tabbar-item-member"
        >
          <div class="tabbar-item-icon"></div>
          <p class="tabbar-item-name">我的</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import qs from "qs";

export default {
  computed: {
    ...mapGetters(["showTabbar"]),
    key() {
      return this.$route.path + qs.stringify(this.$route.query);
    },
  },
};
</script>
<style scoped lang='less'>
.tabbar-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: initial;
}

.tabbar-panel {
  flex: 1;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow: initial;
}

.tabbar-bottom {
  position: relative;
  height: 50px;
  width: 100%;
  transition: bottom 0.2s, top 0.2s;
  bottom: 0;
}
.tabbar {
  background: #fff;
  position: fixed;
  display: flex;
  z-index: 500;
  bottom: 0;
  width: 100%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  &:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 1px;
    border-top: 1px solid #c0bfc4;
    color: #c0bfc4;
    transform-origin: 0 0;
    transform: scaleY(0.5);
  }
}
.tabbar-item {
  display: block;
  flex: 1;
  padding: 5px 0 0;
  font-size: 0;
  color: #999;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.tabbar-item-index {
  .tabbar-item-icon {
    background-image: url("../assets/images/icon-main.png");
  }
}
.tabbar-item-index.tabbar-item-active {
  .tabbar-item-icon {
    background-image: url("../assets/images/icon-main-selected.png");
  }
}
.tabbar-item-member {
  .tabbar-item-icon {
    background-image: url("../assets/images/icon-my.png");
  }
}
.tabbar-item-member.tabbar-item-active {
  .tabbar-item-icon {
    background-image: url("../assets/images/icon-my-selected.png");
  }
}
.tabbar-item-card {
  .tabbar-item-icon {
    background-image: url("../assets/images/icon-card.png");
  }
}
.tabbar-item-card.tabbar-item-active {
  .tabbar-item-icon {
    background-image: url("../assets/images/icon-card-selected.png");
  }
}
.tabbar-item-icon {
  width: 27px;
  height: 27px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: 100%;
}
.tabbar-item-name {
  height: 21px;
  line-height: 21px;
  text-align: center;
  color: #202437;
  font-size: 12px;
}
</style>