<template>
  <div>
    <van-row class="images" :gutter="1">
      <van-col
        v-for="(item, index) in images"
        :key="item"
        @click="preview(index)"
        :span="isSingle(index) ? 24 : 12"
        :class="{ single: isSingle(index), 'image-item': true }"
      >
        <van-image class="cell-image" lazy-load fit="cover" :src="item" />
      </van-col>
    </van-row>
    <sceen-swiper :images="images" :index="index" v-model="show" />
  </div>
</template>

<script>
import { getHotelDetail } from "@/apis/hotel";
import { useRoute, useStore } from "@/components/use/base";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import SceenSwiper from "@/components/SceenSwiper";
import Vue from "vue";

const vm = Vue.prototype;
export default defineComponent({
  components: {
    SceenSwiper,
  },
  setup() {
    const $route = useRoute();
    const store = useStore();
    const images = ref([]);
    const index = ref();
    const show = ref(false);
    const isSingle = (index) => {
      if (index < 2 || (index === images.value.length - 1 && index % 2 === 0)) {
        return true;
      }
      return false;
    };
    const preview = (idx) => {
      show.value = true;
      index.value = idx;
    };
    onMounted(async () => {
      const params = {
        id: $route.query.hotelId,
        namespace: store.state.namespace,
        checkin_date: store.state.hotel.checkin_date,
        checkout_date: store.state.hotel.checkout_date,
        is_oak: vm.$getConfigValue("OA_HOTEL_RULE") ? "1" : "0",
      };
      if (store.state.benefitHotelType === "ehotel") {
        params.classification = 3;
        params.namespace = `${params.namespace}-affordable`;
      }
      const res = await getHotelDetail(params);
      const imgs = res.data.images.concat(
        res.data.rooms.map((item) => item.images).flat()
      );
      images.value = imgs;
    });
    return {
      images,
      isSingle,
      show,
      preview,
      index,
    };
  },
});
</script>

<style lang="less" scoped>
.images {
  background: #fff;
}
.image-item {
  height: 167px;
  margin-bottom: 1px;
  .cell-image {
    height: 100%;
  }
  &.single {
    height: 187px;
  }
}
</style>
