import Vue from 'vue';
import store from '../../store';
import router from '../../router';
import DialogComponent from './CentificationDialog';

let instance;

function isInDocument(element) {
  return document.body.contains(element);
}

function initInstance() {
  if (instance) {
    instance.$destroy();
  }

  instance = new (Vue.extend({
    ...DialogComponent,
    store,
    router,
  }))({
    el: document.createElement('div'),
    // avoid missing animation when first rendered
    propsData: {
      lazyRender: false,
    },
  });
  document.body.appendChild(instance.$el);

  instance.$on('input', (value) => {
    instance.value = value;
  });
}


function Dialog(options) {
  return new Promise((resolve, reject) => {
    if (!instance || !isInDocument(instance.$el)) {
      initInstance();
    }
    Object.assign(instance, Dialog.currentOptions, options, {
      resolve,
      reject,
    });
  });
}

Dialog.show = (options) =>
  Dialog({
    value: true,
    ...options,
  });

Dialog.close = () => {
  if (instance) {
    instance.value = false;
  }
};

Dialog.currentOptions = {
  value: false,
  close() {
    if (instance) {
      instance.beforeClose?.();
      instance.value = false;
    }
  }
}

export default Dialog;