<template>
  <main class="order-detail">
    <div class="order-status">
      <div class="order-status-flex">
        <div class="order-status-name">
          <svg-icon
            class="hotel-success-icon"
            v-if="status === 'unCheckin'"
            icon-class="hotel-success"
          />{{ statusText }}
        </div>
        <!-- <router-link :to="`/hotel/detail`" class="book-hotel"
          >再次预订</router-link
        > -->
      </div>
      <div class="order-status-desc" v-if="status === 'confirming'">
        {{ statusDesc }}
      </div>
    </div>
    <div
      class="cancel-container"
      v-if="status === 'checkin' || status === 'canceled'"
    >
      <div class="cancel-title">
        感谢您使用橡树黑卡，欢迎您再次预订
        <van-button
          v-if="!$getConfigValue('HOTEL_SINGLE_BENEFIT')"
          class="cancel-btn"
          round
          block
          type="info"
          :to="`/hotel/detail?id=${hotel.id}&benefitType=${detail.type}`"
          >再次预订</van-button
        >
      </div>
      <div class="desc" v-if="statusSubText">{{ statusSubText }}</div>
    </div>
    <div class="payment-info">
      <div class="title">
        {{ feeText }}
        <div class="residual-price" v-if="payInfo.pay_method === '0'">
          ¥{{ payInfo.total_price }}
        </div>
        <div class="residual-price" v-else>¥{{ payInfo.total_fee }}</div>
      </div>
      <div class="flex-cell flex-cell-price">
        <div class="label">房费</div>
        <div class="value" style="text-decoration: line-through">
          ¥ {{ payInfo.total_origin_price }}
        </div>
      </div>
      <div class="flex-cell flex-cell-preferential">
        <div class="label">优惠</div>
        <div class="value">
          <span>已为您节省 ¥{{ payInfo.total_price_difference }}</span>
        </div>
      </div>
      <div class="flex-cell flex-cell-fax" v-if="payInfo.pay_method === '0'">
        <div class="label">预订担保金</div>
        <div class="value">
          <span>¥{{ payInfo.total_fee }}</span> (入住后，3-5个工作日内将退回)
        </div>
      </div>
    </div>

    <div class="hotel-card">
      <div class="hotel-container">
        <component
          :is="$getConfigValue('HOTEL_SINGLE_BENEFIT') ? 'span' : 'router-link'"
          class="hotel-info"
          :to="`/hotel/detail?id=${hotel.id}&benefitType=${detail.type}`"
        >
          <van-image
            lazy-load
            class="hotel-image"
            v-if="getImageUrl"
            :src="getImageUrl"
          />
          <div class="hotel-content">
            <div class="order-product-desc ellipsis">
              {{ payInfo.hotel_name }} | {{ payInfo.room_name }}
            </div>
            <div class="hotel-address">{{ hotel.address }}</div>
          </div>
          <svg-icon icon-class="arrow2" class="hotel-arrow" />
        </component>
        <div class="hotel-map">
          <div class="hotel-map-item" @click="toMap">
            <svg-icon iconClass="map-route-icon" />路线导航
          </div>
          <div
            v-if="!$getConfigValue('HOTEL_SINGLE_BENEFIT')"
            class="hotel-map-item"
            @click="toService"
          >
            <svg-icon iconClass="map-service" />联系客服
          </div>
        </div>
      </div>
      <div class="hotel-book-info">
        <van-row class="cell">
          <van-col class="cell-label" :span="5">入离日期</van-col>
          <van-col class="cell-value" :span="19">
            {{ payInfo.checkin_date | formatDate("MM月DD日")
            }}{{ payInfo.checkin_date | week }} -
            {{ payInfo.checkout_date | formatDate("MM月DD日")
            }}{{ payInfo.checkout_date | week }} | {{ payInfo.stay_days }}晚
          </van-col>
        </van-row>
        <van-row class="cell">
          <van-col class="cell-label" :span="5">入住房型</van-col>
          <van-col class="cell-value" :span="19">{{
            payInfo.room_name
          }}</van-col>
        </van-row>
        <van-row class="cell">
          <van-col class="cell-label" :span="5">早餐</van-col>
          <van-col class="cell-value" :span="19">{{
            payInfo.breakfast_type
          }}</van-col>
        </van-row>
        <van-row class="cell">
          <van-col class="cell-label" :span="5">入住人</van-col>
          <van-col class="cell-value" :span="19">{{ payInfo.user }}</van-col>
        </van-row>
        <van-row class="cell">
          <van-col class="cell-label" :span="5">联系手机</van-col>
          <van-col class="cell-value" :span="19">{{ payInfo.phone }}</van-col>
        </van-row>
        <van-row v-if="payInfo.pay_method === '0'" class="cell">
          <van-col class="cell-label" :span="5">常旅客号</van-col>
          <van-col
            class="cell-value"
            :span="19"
            v-if="payInfo.passenger_number"
            >{{ payInfo.passenger_number }}</van-col
          >
          <van-col class="cell-value" :span="19" v-else>
            <span>未填写</span>
          </van-col>
        </van-row>
        <van-row v-if="payInfo.pay_method === '0'" class="cell">
          <van-col class="cell-label" :span="5">备注</van-col>
          <van-col class="cell-value" :span="19" v-if="payInfo.remarks">{{
            payInfo.remarks
          }}</van-col>
          <van-col class="cell-value" :span="19" v-else>
            <span>未备注</span>
          </van-col>
        </van-row>
        <div class="line" />
        <van-row class="cell">
          <van-col class="cell-label" :span="5">订单编号</van-col>
          <van-col class="cell-value" :span="19">
            {{ detail.order_number }}
            <span
              round
              size="mini"
              class="copy"
              v-clipboard:copy="detail.order_number"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
              v-ans="{
                event_id: 'order_detail_click_copyorder',
                order_id: detail.order_number,
              }"
              >复制</span
            >
          </van-col>
        </van-row>
        <van-row class="cell">
          <van-col class="cell-label" :span="5">下单时间</van-col>
          <van-col class="cell-value" :span="19">{{ orderTime }}</van-col>
        </van-row>
      </div>
    </div>
    <div class="order-notice" v-if="notice.length">
      <switch-text :max-height="150">
        <div class="notice-item" v-for="item in notice" :key="item.id">
          <div class="notice-title">{{ item.title }}</div>
          <div class="notice-content" v-html="item.content"></div>
        </div>
      </switch-text>
    </div>
    <div class="height60"></div>
    <div class="order-footer">
      <template>
        <van-button
          v-if="
            showCancelButton ||
            (status === 'unpaid' && $getConfigValue('HOTEL_SINGLE_BENEFIT'))
          "
          class="order-footer-button cancel"
          size="small"
          round
          v-ans="{
            event_id: 'order_detail_click_cancel',
            order_id: detail.order_number,
            order_type: 'hotel',
          }"
          :to="`/hotel/cancelOrder?order_number=${detail.order_number}&status=${status}`"
          >取消订单</van-button
        >
        <van-button
          v-if="status === 'unpaid' && $getConfigValue('HOTEL_SINGLE_BENEFIT')"
          class="order-footer-button cancel"
          size="small"
          @click="payAgain"
          round
          v-ans="{
            event_id: 'order_detail_click_cancel',
            order_id: detail.order_number,
            order_type: 'hotel',
          }"
          >继续支付</van-button
        >
        <van-button
          class="order-footer-button"
          round
          v-if="showIndex"
          @click="toIndex"
          v-ans="{
            event_id: 'order_detail_click_back',
            order_id: detail.order_number,
            order_type: detail.type,
            order_time: detail.t_created,
            namespace,
          }"
        >
          返回首页
        </van-button>
        <van-button
          v-if="!isMiniProgram && !$getConfigValue('HOTEL_SINGLE_BENEFIT')"
          class="order-footer-button"
          size="small"
          round
          @click="toService"
          v-ans="{
            event_id: 'hotel_order_service_button',
            order_id: detail.order_number,
            namespace,
          }"
          >联系客服</van-button
        >
      </template>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "dayjs";
import ans from "@/utils/ans";
import dayjs from "dayjs";
import { getHotelDetail } from "@/apis/hotel";
import { qqMapTransBMap } from "@/utils";
import qs from "qs";
import SwitchText from "@/components/SwitchText";

export default {
  props: ["order", "system_date", "notice", "toIndex", "showIndex"],
  components: {
    SwitchText,
  },
  data() {
    return {
      activeNames: [3],
      detail: this.order,

      loading: true,
      count: 0,
      payInfo: JSON.parse(this.order.pay_info),
      itemInfo: {},
      explain: "",
      hotel: {
        images: [],
      },
    };
  },

  async mounted() {
    await this.getHotelDays();
    const { order_number } = this.$route.query;
    const data = this.order;
    ans.pageView(this.$route.meta.page, {
      order_type: "hotel",
      order_id: order_number,
      order_time: data.t_created,
      checkin_date: dayjs(this.payInfo.checkin_date).format("YYYY-MM-DD"),
      hotel_name: this.payInfo.hotel_name,
      rooms_name: this.payInfo.room_name,
      rooms_price: this.payInfo.total_price,
      namespace: this.namespace,
    });
    const params = {
      namespace: this.namespace,
      checkin_date: this.hotel.checkin_date,
      checkout_date: this.hotel.checkout_date,
      id: this.payInfo.id,
      is_oak: this.$getConfigValue("OA_HOTEL_RULE") ? "1" : "0",
    };
    if (this.order.type === "ehotel") {
      params.classification = 3;
      params.namespace = `${params.namespace}-affordable`;
    }
    const res = await getHotelDetail(params);
    this.hotel = res.data;
  },

  methods: {
    ...mapActions(["getHotelDays"]),
    copySuccess() {
      this.$toast("复制成功");
    },
    copyError() {
      this.$toast("复制失败,请手动复制");
    },
    async toService() {
      this.$router.push("/service");
    },

    payAgain() {
      const url = `/pages/pay/pay?${qs.stringify({
        order_number: this.detail.order_number,
        item_name: this.detail.name,
        price: this.detail.amount,
        item_code: this.payInfo.item_code,
        type: this.detail.type,
      })}`;
      this.$router.push(url);
    },

    async toMap() {
      if (this.isMiniProgram || this.isWebchat) {
        wx.ready(() => {
          wx.openLocation({
            name: this.hotel.name,
            latitude: parseFloat(this.hotel.latitude),
            longitude: parseFloat(this.hotel.longitude),
          });
        });
        return;
      }
      const tude = qqMapTransBMap(this.hotel.longitude, this.hotel.latitude);
      window.location.href = `http://api.map.baidu.com/marker?location=${tude.latitude},${tude.longitude}&title=${this.hotel.name}&content=${this.hotel.rating}&output=html`;
    },
  },
  computed: {
    ...mapState([
      "namespace",
      "isMiniProgram",
      "isWebchat",
      "return_index_url",
    ]),
    ...mapGetters(["namespaceGroup"]),
    ...mapGetters(["isHoliday"]),
    getImageUrl() {
      return this.hotel.images?.[0];
    },

    status() {
      if (this.detail.status === "success") {
        if (
          dayjs().isAfter(dayjs(Number(this.payInfo.checkin_date)).endOf("day"))
        ) {
          return "checkin";
        }
        return "unCheckin";
      }
      return this.detail.status;
    },
    feeText() {
      if (this.status === "canceled" || this.status === "checkin") {
        return "订单费用";
      }
      return this.payInfo.pay_method === "0" ? "到店需付房费" : "在线支付";
    },
    statusText() {
      const temp = {
        success: "已成功",
        failed: "已失败",
        confirming: "已支付，确认中",
        unpaid: "待支付",
        canceled: "已取消",
        refund: "已退款",
        unCheckin: "已确认，待入住",
        checkin: "已完成",
      };
      const hotel_order_status = this.order.hotel_order_status || "default";
      const hotelTemp = {
        success: {
          default: "已成功",
          success: "预定成功，待退保证金",
        },
        canceled: {
          default: "已取消",
        },
        refund: {
          default: "已退款",
          success: "预定成功，已退担保金",
          canceled: "取消预订，已退担保金",
        },
        unpaid: {
          default: "待支付",
        },
        confirming: {
          default: "已支付，确认中",
          confirming: "已支付担保金，等待确认中",
        },
        failed: {
          default: "已失败",
        },
      };

      return this.payInfo.pay_method === "0"
        ? hotelTemp[this.order.status][hotel_order_status]
        : temp[this.status];
    },
    statusSubText() {
      if (this.status === "canceled") {
        return this.payInfo.pay_method === "0"
          ? "已取消，担保金将在3-5个工作日退回"
          : "已取消，预计3-5个工作日原路返还";
      }
      if (this.status === "checkin" && this.payInfo.pay_method === "0") {
        return "已完成，担保金将在3-5个工作日退回";
      }
      return "";
    },
    statusDesc() {
      const holiday = this.isHoliday(dayjs(this.order.t_created));
      if (holiday) {
        return "酒店预计24小时内确认订单，请留意短信通知";
      }
      const hour16 = dayjs(this.order.t_created).hour(16).minute(0).second(0);
      // 法定节假日-不含周末：酒店预计24小时内确认订单，请留意短信通知
      return dayjs(this.order.t_created).isBefore(hour16)
        ? "每天16:00前预订酒店，酒店预计当天确认订单"
        : "每天16:00后预订酒店，酒店预计第二天确认订单";
    },
    imageEnum() {
      const imageEnum = {
        success: "success",
        failed: "cancel",
        confirming: "success",
        unpaid: "unpay",
        canceled: "cancel",
        refund: "cancel",
      };
      return imageEnum;
    },
    orderTime() {
      return moment(this.detail.t_created).format("YYYY-MM-DD HH:mm:ss");
    },
    showCancelButton() {
      const hour16 = moment(Number(this.payInfo.checkin_date))
        .subtract(1, "day")
        .set("hour", 16)
        .set("minute", 0)
        .set("second", 0);
      const before16 = moment(this.system_date).isBefore(hour16);
      return (
        before16 &&
        this.payInfo.pay_method === "0" &&
        /^(confirming|success)$/.test(this.detail.status)
      );
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>

<style lang="less" scoped>
.order-detail {
  background: #f4f5f7;
}

.payment-info {
  background: #ffffff;
  padding: 18px 16px;
  border-radius: 12px 12px 0px 0px;
  margin-bottom: 12px;
  & + & {
    margin-top: 12px;
  }
  .title {
    line-height: 24px;
    font-size: 17px;
    font-weight: bold;
    display: flex;
    margin-bottom: 18px;
  }
  .residual-price {
    margin-left: auto;
    display: flex;
    line-height: 24px;
    color: #fc4f10;
    font-size: 17px;
    font-weight: bold;
  }
}

.flex-cell {
  display: flex;
  justify-content: space-between;
  line-height: 18px;
  & + & {
    margin-top: 13px;
  }
  &.flex-cell-fax {
    margin-top: 17px;
    padding-top: 13px;
    border-top: 1px solid #f9f9f9;
    margin-bottom: 0;
  }
  .label {
    color: #888888;
    font-size: 13px;
    flex-basis: 120px;
  }
  .value {
    font-size: 12px;
    color: #999;
    text-align: right;
    span {
      color: #fc4f10;
    }
  }
}

.order-status {
  padding: 19px 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.order-status-name {
  line-height: 30px;
  font-size: 22px;
  color: #333;
  font-weight: bold;
}
.hotel-success-icon {
  margin-right: 4px;
}
.order-status-desc {
  margin-top: 5px;
  font-size: 13px;
  color: #477b6c;
  line-height: 18px;
}
.order-status-flex {
  display: flex;
  align-items: center;
  .book-hotel {
    margin-left: auto;
    width: 56px;
    height: 21px;
    color: #ff5001;
    border: 1px solid currentColor;
    line-height: 21px;
    font-size: 10px;
    border-radius: 20px;
    text-align: center;
    box-sizing: border-box;
  }
}
.cancel-container {
  padding: 20px 16px;
  margin: 0 12px 20px;
  background: #ffffff;
  border-radius: 12px;
  font-size: 13px;

  .cancel-title {
    display: flex;
    align-items: center;
    line-height: 32px;
    color: #222;
    font-weight: bold;
  }
  .cancel-btn {
    height: 32px;
    width: 77px;
    margin-left: auto;
    padding: 0;
    font-size: 12px;
    background: linear-gradient(90deg, #ff650d 0%, #ff5f4a 100%);
    color: #fff;
  }
  .desc {
    line-height: 18px;
    padding-top: 16px;
    color: #477b6c;
    border-top: 1px solid #f9f9f9;
    margin-top: 17px;
  }
}

.hotel-card {
  padding: 20px;
  background: #fff;
}

.hotel-info {
  display: flex;
  margin-bottom: 12px;
  .hotel-image {
    width: 49px;
    height: 41px;
    flex-shrink: 0;
    margin-right: 10px;
    border-radius: 8px;
    overflow: hidden;
  }
  .order-product-desc {
    padding-top: 2px;
    line-height: 18px;
    margin-bottom: 3px;
    font-size: 13px;
    color: #222;
  }
  .hotel-address {
    line-height: 16px;
    color: #888;
    font-size: 11px;
  }
}

.hotel-content {
  flex: 1;
  overflow: hidden;
}

span.hotel-info {
  .hotel-arrow {
    display: none;
  }
}
.hotel-arrow {
  color: #333;
  margin-top: 4px;
}

.hotel-map {
  height: 38px;
  border-radius: 4px;
  background: #fbf9fc;
  display: flex;
  align-items: center;
  color: #333;
  font-size: 12px;

  .hotel-map-item {
    flex: 1;
    text-align: center;
    &:first-child {
      border-right: 1px solid #eaeaea;
    }
  }
  .svg-icon {
    margin-right: 4px;
    font-size: 14px;
    vertical-align: -3px;
  }
}

.line {
  margin-bottom: 19px;
  height: 1px;
  background: #f9f9f9;
}

.hotel-container {
  padding-bottom: 20px;
  border-bottom: 1px solid #f9f9f9;
}

.order-info-item {
  line-height: 22px;
  color: @text-gray2-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-info {
  padding-top: 19px;
  border-top: 1px solid #f9f9f9;
}
.copy {
  display: block;
  width: 38px;
  text-align: center;
  line-height: 19px;
  color: #999 !important;
  border: 1px solid #d2d2d2;
  border-radius: 16px;
  box-sizing: border-box;
  font-size: 10px;
  float: right;
}

.order-card {
  background: #fff;
  padding: 15px;
  margin-bottom: 10px;
}

.hotel-book-info {
  padding-top: 19px;
  .cell {
    line-height: 18px;
    font-size: 13px;
    &:not(:last-child) {
      margin-bottom: 13px;
    }
  }
}
.cell-label {
  color: #999;
}

.cell-value {
  color: #333;
  span {
    color: #aaaaaa;
  }
}

.order-notice {
  margin-top: 12px;
  font-size: 11px;
  padding: 20px;
  background: #fff;
}
.height60 {
  height: 60px;
}
.notice-item + .notice-item {
  margin-top: 20px;
}
.notice-title {
  margin-bottom: 4px;
  font-size: 13px;
  color: #333;
}
.notice-content {
  line-height: 18px;
  color: #999;
  font-size: 11px;
}
.order-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  button {
    &:first-child {
      margin: 10px 0;
    }
  }
}
.order-footer-button {
  margin-left: 15px;
  font-size: 12px;
  height: 25px;
  color: #333;
  border-color: #000;
}
</style>
