var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.fetching)?_c('div',{staticClass:"home"},[_c('div',{staticClass:"header"},[_c('section',{staticClass:"header-top"},[_c('router-link',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'to_order_click',
        }),expression:"{\n          event_id: 'to_order_click',\n        }"}],staticClass:"order",attrs:{"to":"/order"}},[_vm._v("我的订单")]),_c('router-link',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'to_service_click',
        }),expression:"{\n          event_id: 'to_service_click',\n        }"}],staticClass:"service",attrs:{"to":"/service"}},[_vm._v("在线客服")])],1),_c('div',{staticClass:"validity"},[_vm._v(" 会员有效期至"+_vm._s(_vm._f("formatDate")(_vm.card_info.card_expiry_date,"YYYY.MM.DD"))+" ")])]),_c('div',{staticClass:"benefit-body"},[(_vm.card_info.card_status === 'active')?_c('div',{staticClass:"benefit-list"},_vm._l((_vm.benefit_list),function(item,seq){return _c('div',{key:item.type,staticClass:"benefit-item"},[_c('van-image',{staticClass:"benefit-item-icon",attrs:{"mode":"aspectFit","src":item.icon_url}}),_c('div',{staticClass:"benefit-item-text"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"subtitle van-multi-ellipsis--l3"},[_vm._v(" "+_vm._s(item.subtitle)+" ")])]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
            event_id: 'benefit_list_click',
            sequence: seq,
            spu_id: item.type,
            spu_name: item.title,
          }),expression:"{\n            event_id: 'benefit_list_click',\n            sequence: seq,\n            spu_id: item.type,\n            spu_name: item.title,\n          }"}],staticClass:"benefit-item-btn",on:{"click":function($event){return _vm.jump(item)}}},[_vm._v(" 去使用 ")]),_c('div',{staticClass:"times"},[_vm._v("剩"+_vm._s(item.amount + item.amount_reserved)+"次")])],1)}),0):_vm._e(),(_vm.card_info.card_status === 'expired')?_c('div',{staticClass:"card-past"},[_vm._v(" 您的权益已过期 ")]):_vm._e(),(_vm.card_info.card_status === 'refund')?_c('div',{staticClass:"card-past"},[_vm._v(" 您的权益已退款 ")]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }