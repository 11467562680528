<template>
  <div class="ticket-info">
    <select-date :num="30" @changeDate="fetchTickets" />
    <div class="tickets">
      <van-empty
        v-if="empty"
        style="padding-top: 0"
        description="没有可预订的票"
      />
      <div class="ticket-item" v-else v-for="item in tickets" :key="item.id">
        <div class="left">
          <div class="ticket-name">{{ item.name }}</div>
          <div class="ticket-tags">
            <span class="tag" v-for="tag in item.tags" :key="tag.id">{{
              tag.name
            }}</span>
          </div>
          <div class="ticket-tips" @click="showIntroductuon(item)">
            购票须知
            <van-icon style="vertical-align: -2px" name="arrow" />
          </div>
        </div>
        <div class="right">
          <div v-if="coupon" class="ticket-facevalue">¥{{ item.price }}</div>
          <div class="ticket-price">
            <sub>¥</sub>{{ residualPrice(item.price) }}
          </div>
          <div v-if="coupon" class="ticket-save-money">
            已优惠 ¥{{
              item.price | decimal(residualPrice(item.price), "minus")
            }}
          </div>
        </div>
        <div class="ticket-btn" @click="bookHotel(item)">预订</div>
      </div>
    </div>
    <van-popup
      round
      closeable
      position="bottom"
      class="price-popup"
      :z-index="2050"
      v-model="showIntroductionPopup"
    >
      <div class="price-popup-title">购买须知</div>
      <div class="price-popup-content">
        <div style="line-height: 1.5" v-html="detail.purchase_notice"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SelectCalendar from "@/components/SelectCalendar";
import { decimal, storage } from "@/utils";
import dayjs from "dayjs";
import { getBenefitInfo, getUserInfo } from "@/apis";
import SelectDate from "./selectDate";
import { getParkTicketList } from "@/apis/park";

export default {
  components: {
    SelectDate,
  },
  props: {
    coupon: Object,
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dayjs,
      current: 0,
      showIntroductionPopup: false,
      tickets: [],
      empty: false,
    };
  },
  computed: {
    ...mapState(["namespace", "source", "offlineBenefit"]),
    ...mapGetters(["interNight", "namespaceGroup"]),
  },
  async mounted() {
    this.fetchTickets();
  },
  methods: {
    residualPrice(price) {
      if (!this.coupon) {
        return price;
      }
      return decimal.times(this.coupon.discount_rate, price);
    },
    async fetchTickets() {
      const res = await getParkTicketList({
        park_id: this.$route.query.id,
        query_time: dayjs(this.offlineBenefit.parkBookDate).format(
          "YYYY-MM-DD"
        ),
      });

      this.tickets = res.data;
      this.empty = !res.data.length;
    },
    showIntroductuon() {
      this.showIntroductionPopup = true;
    },
    changeDate() {
      SelectCalendar.show({
        tips: "最早预订日期为当前日期的后天",
        type: "park",
        rangeDate: [this.offlineBenefit.parkBookDate].filter(Boolean),
        minDate: dayjs().add(2, "day"),
        maxDate: dayjs().add(31, "day"),
        range: 90,
        single: true,
      }).then(() => {
        this.fetchTickets();
      });
    },
    async bookHotel(ticket) {
      if (ticket.is_date_unavailable) {
        return;
      }

      if (!storage.getItem("token")) {
        this.$toast("你还未登录，请先登录");
        return;
      }
      const loading = this.$loading();
      try {
        // 查看用户是否会员
        const res = await getUserInfo({ mask: "" });
        const { is_member } = res.data;
        if (!is_member) {
          this.$toast("您还不是会员");
          return;
        }

        // 判断用户是否有点数
        const benefit = await getBenefitInfo({
          benefit_type: this.$route.query.benefitType || "paradise",
        });
        const { amount_available, amount_reserved } = benefit.data;
        if (!(amount_available + amount_reserved)) {
          this.$toast("您权益次数为0，不可预订");
          return;
        }
        if (!amount_available) {
          this.$toast("您本期已预定过，请下期再来，可在权益页面查看详细规则");
          return;
        }

        this.$router.push(
          `/offlineBenefit/park/book?parkId=${this.detail.id}&ticketId=${ticket.id}`
        );
      } finally {
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tickets {
  margin-top: 12px;
}
.ticket-info {
  margin-top: 10px;
  padding: 16px 20px;
  background: #fff;
}
.ticket-item {
  display: flex;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0)
    linear-gradient(90deg, #fff5f3 0%, rgba(255, 252, 251, 0) 100%);
  border-radius: 8px;
  padding: 12px 4px 12px 15px;
  & + & {
    margin-top: 12px;
  }
  .ticket-name {
    font-size: 15px;
    color: #111;
    line-height: 19px;
    margin-bottom: 7px;
  }
  .ticket-tags {
    height: 14px;
    margin-bottom: 7px;
    .tag {
      border-radius: 2px;
      border: 1px solid currentColor;
      color: #f21500;
      margin-right: 4px;
      padding: 0 2px;
      font-size: 10px;
    }

    @colors: #f21500, #ff7700, #00b87a;
    each(range(length(@colors)), {
      .tag:nth-child(@{value}) {
        color: extract(@colors, @value);
        border-color: rgba(extract(@colors, @value), 0.9);
      }
    });
  }
  .ticket-tips {
    line-height: 18px;
    font-size: 10px;
    width: max-content;
  }
  .right {
    margin-left: auto;
    margin-right: 4px;
    text-align: right;
    flex-shrink: 0;
  }
  .ticket-facevalue {
    font-size: 13px;
    color: #666;
    text-decoration: line-through;
    line-height: 18px;
  }
  .ticket-price {
    font-weight: bold;
    line-height: 24px;
    color: #f63b2e;
    font-size: 20px;
    sub {
      font-size: 0.5em;
    }
  }
  .ticket-save-money {
    line-height: 14px;
    background: rgba(0, 0, 0, 0)
      linear-gradient(90deg, #ff7f51 0%, #f63b2e 100%);
    border-radius: 8px 1px 8px 8px;
    color: #fff;
    width: max-content;
    font-size: 10px;
    padding: 0 4px;
  }
  .ticket-btn {
    width: 42px;
    height: 42px;
    background: rgba(0, 0, 0, 0)
      linear-gradient(90deg, #fb5b47 0%, #ff3527 100%);
    border-radius: 6px;
    font-size: 15px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.price-popup {
  padding: 15px 18px;
  height: 350px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .price-popup-title {
    flex-shrink: 0;
    color: #222222;
    font-size: 15px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    margin-bottom: 15px;
  }
  .price-popup-content {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
