<template>
  <section class="yhk" v-if="!fetching">
    <div class="yhk-header">
      <van-image
        class="yhk-header-img"
        :src="`https://cdn.xiangshuheika.com/static/yh/header.png`"
      />
      <div class="yhk-header-name">省呗月花卡</div>
      <div class="yhk-header-expiry">
        有效期至{{ (cardInfo.card_expiry_date || "").replace(/-/g, ".") }}
      </div>
    </div>
    <div class="yhk-tab">
      <div class="yhk-item yhk-save">
        <div class="yhk-text">
          <span class="text1">累计已省</span>
          <span class="text2">¥</span> {{ saveMoney }}
        </div>
      </div>
      <div class="yhk-item yhk-benefit" @click="$router.push('/order')">
        我的权益
        <van-image
          class="yhk-go"
          :src="`https://cdn.xiangshuheika.com/static/yh/go.png`"
        />
      </div>
    </div>
    <div v-if="cardInfo.card_status === 'expired'" class="card-past">
      <van-image :src="require('../../assets/images/yh-empty.png')" />
      <p>您的权益已过期</p>
    </div>
    <div v-else-if="cardInfo.card_status === 'refund'" class="card-past">
      <van-image :src="require('../../assets/images/yh-empty.png')" />
      <p>您的权益已退款</p>
    </div>
    <section v-else class="page-benefit">
      <div
        class="page-benefit-item"
        v-for="item in catesgorys"
        :key="item.name"
      >
        <div class="page-benefit-item-inner">
          <div class="benefit-item-title">
            {{ item.name }}
          </div>
          <div
            v-if="item.display_code !== 'benefit_03'"
            class="page-benefit-list-wrap"
          >
            <div class="page-benefit-list">
              <div
                class="page-benefit-spu"
                v-for="(spu, idx) in item.list"
                v-ans="{
                  event_id: 'benefit_list_click',
                  sequence: idx + 1,
                  spu_id: spu.id,
                  spu_name: spu.desc,
                  namespace,
                }"
                :key="spu.product_code"
                v-if="spu.benefit"
              >
                <div
                  class="page-spu-inner"
                  @click="
                    $router.push(
                      `/benefitDetail?benefit_type=${spu.product_code}`
                    )
                  "
                >
                  <div class="image-inner">
                    <van-image
                      v-if="item.display_code !== 'member_travel'"
                      class="page-benefit-image"
                      lazy-load
                      fit="cover"
                      :src="spu.display_img_url"
                    />
                    <div
                      class="amount"
                      v-if="
                        spu.benefit &&
                        spu.benefit.amount_available +
                          spu.benefit.amount_reserved >
                          0
                      "
                    >
                      剩{{
                        spu.benefit.amount_available +
                        spu.benefit.amount_reserved
                      }}次
                    </div>
                    <div class="sell" v-if="spu.selling_points">
                      {{ spu.selling_points }}
                    </div>
                  </div>
                  <div class="page-benefit-name">{{ spu.desc }}</div>
                  <div
                    :class="{
                      'life-use': true,
                      'life-use-disabled':
                        spu.benefit &&
                        (spu.benefit.amount_available == 0 ||
                          spu.benefit.amount_reserved +
                            spu.benefit.amount_available ===
                            0),
                      'life-use-amount':
                        spu.benefit && spu.benefit.amount_available > 0,
                    }"
                  >
                    <div class="use">{{ getBenefitText(spu.benefit) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="page-benefit-list-wrap">
            <div class="page-benefit-list">
              <div
                class="page-benefit-spu2"
                v-for="(spu, idx) in item.list"
                v-ans="{
                  event_id: 'benefit_list_click',
                  sequence: idx + 1,
                  spu_id: spu.id,
                  spu_name: spu.desc,
                  namespace,
                }"
                :key="spu.product_code"
                v-if="spu.benefit"
              >
                <div
                  class="page-spu-inner"
                  @click="
                    $router.push(
                      `/benefitDetail?benefit_type=${spu.product_code}`
                    )
                  "
                >
                  <div class="left">
                    <van-image
                      class="page-benefit-image"
                      lazy-load
                      fit="cover"
                      :src="spu.icon_img_url"
                    />
                    <p>{{ spu.desc }}</p>
                  </div>
                  <div class="right">
                    <van-image
                      class="page-benefit-image"
                      lazy-load
                      fit="cover"
                      :src="spu.display_img_url"
                    />
                    <div
                      :class="{
                        amount: true,
                        'amount-none':
                          spu.benefit.amount_available +
                            spu.benefit.amount_reserved <=
                          0,
                      }"
                    >
                      剩{{
                        spu.benefit.amount_available +
                        spu.benefit.amount_reserved
                      }}次
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div style="height: 20px"></div>
    <img
      class="yhk-server"
      src="https://cdn.xiangshuheika.com/static/yh/yhk-server.png"
      @click="$router.push('/service')"
    />
  </section>
</template>

<script>
import { getIndexCategory, getIndexClassList } from "@/apis/product";
import { mapState } from "vuex";
import { getListBenefit, yhNewBenefit } from "@/apis";
import { getUserBenefit, queryTotalSavMoney } from "@/apis/home";
export default {
  data() {
    return {
      catesgorys: [],
      saveMoney: 0,
      cardInfo: {},
      fetching: true,
    };
  },
  computed: {
    ...mapState(["namespace", "userInfo", "clientConfigs"]),
  },
  async mounted() {
    this.getProductList();
  },
  methods: {
    getDisabled(benefit) {
      return (
        benefit?.amount_available === 0 ||
        benefit?.amount_available + benefit?.amount_reserved === 0
      );
    },
    getBenefitText(benefit) {
      // if (!benefit) {
      //   return "去使用";
      // }
      if (benefit.amount_available > 0) {
        return "去使用";
      } else {
        if (benefit.amount_reserved > 0) {
          return "今日已领";
        }
        return "已领完";
      }
    },
    async getProductList() {
      const loading = this.$loading();
      try {
        const card_info = await getUserBenefit();
        if (card_info.data.card_status === "active") {
          const [catesgory, benefit, save_money] = await Promise.all([
            getIndexCategory({
              namespace: this.namespace,
              opened_page: this.$getConfigValue(
                "OPENED_PAGE_MEMBER",
                this.userInfo.card_type
              ),
              source: this.source,
            }),
            getListBenefit(),
            queryTotalSavMoney(),
          ]);
          //月花卡开通新套餐后，需要隐藏老套餐权益
          const hide_old_benefit = this.$getConfigValue(
            "HIDE_OLD_BENEFIT",
            this.userInfo.card_type
          );
          let new_benefit = benefit.data;
          if (hide_old_benefit) {
            const res = await yhNewBenefit();
            if (res.data.length) {
              new_benefit = benefit.data.filter((r) => {
                if (res.data.includes(r.type)) {
                  return r;
                }
                if (r.amount_available + r.amount_reserved > 0) {
                  return r;
                }
              });
            }
          }
          const catesgorys = catesgory.data.filter((r) => r.status === 1);
          if (!catesgorys.length) {
            return;
          }
          const list = await getIndexClassList({
            title: catesgorys.map((r) => r.name).join(","),
            opened_page: this.$getConfigValue(
              "OPENED_PAGE_MEMBER",
              this.userInfo.card_type
            ),
            namespace: this.namespace,
          });
          catesgorys.forEach((item) => {
            item.list = list.data[item.name]
              .filter((r) => r.status === 1)
              .map((item) => ({
                ...item,
                benefit: new_benefit.find((r) => r.type === item.product_code),
              }));
          });
          this.catesgorys = catesgorys;
          this.saveMoney = save_money.data.total;
        }
        this.cardInfo = card_info.data;
      } finally {
        this.fetching = false;
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.yhk {
  background-color: #002006;
  min-height: 100vh;
  box-sizing: border-box;
}
.yhk-header {
  position: relative;
}
.yhk-header-img {
  width: 100%;
  height: 125px;
}
.yhk-header-name {
  position: absolute;
  color: #ffe9b4;
  font-size: 18px;
  left: 38px;
  bottom: 44px;
  font-weight: bold;
}
.yhk-header-expiry {
  position: absolute;
  color: #ffe9b4;
  font-size: 10px;
  left: 38px;
  bottom: 20px;
}
.yhk-tab {
  margin: 0 12px;
  background: #fff;
  height: 43px;
  border-radius: 5px;
  display: flex;
}
.yhk-item {
  flex: 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.yhk-item:first-child::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 33px;
  background-color: #f5f5f5;
  right: 0;
  top: 6px;
}
.yhk-save {
  color: #0fb600;
  font-size: 20px;
  font-weight: bold;
}
.yhk-text {
  display: flex;
  align-items: baseline;
}
.yhk-save .text1 {
  color: #333;
  font-size: 13px;
  font-weight: normal;
  padding-right: 5px;
}
.yhk-save .text2 {
  font-size: 13px;
}

.yhk-go {
  width: 35px;
  height: 18px;
  margin-left: 10px;
}
.page-benefit-item-inner {
  background-color: #fff;
  margin: 12px 12px 0;
  border-radius: 8px;
}

.benefit-item-title {
  padding: 13px 0;
  text-align: center;
  font-size: 16px;
  box-sizing: border-box;
  line-height: 22px;
  color: #000;
  font-weight: bold;
  width: fit-content;
}
.page-benefit-item {
  .benefit-item-title {
    margin: 0 auto;
    position: relative;
    &::before {
      content: "";
      width: 15px;
      height: 15px;
      display: block;
      position: absolute;
      left: -20px;
      top: 16px;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    &::after {
      content: "";
      width: 15px;
      height: 15px;
      display: block;
      position: absolute;
      right: -20px;
      top: 16px;
      background-repeat: no-repeat;
      background-size: 100%;
      transform: rotateY(180deg);
    }
  }
}
.page-benefit-list {
  padding: 0 8px;
  display: flex;
  flex-wrap: wrap;
}
.page-benefit-spu {
  width: 50%;
  margin-bottom: 8px;
  .page-benefit-image {
    height: 112px;
  }
  .page-spu-inner {
    margin: 0 4px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 8px;
    overflow: hidden;
  }
  .image-inner {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
  }
  .page-benefit-name {
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    padding: 0 5px;
    font-weight: bold;
    margin: 5px 0 8px;
  }

  .life-use {
    font-size: 13px;
    display: flex;
    align-items: center;
    height: 28px;
    width: 108px;
    border-radius: 27px;
    overflow: hidden;
    justify-content: center;
    background: linear-gradient(90deg, #016f3b 0%, #003532 100%);
    margin: 0 auto;
    // &.life-use-amount {
    //   background: linear-gradient(115deg, #dbfced 50%, #1a1a1a 50%);
    // }
    &.life-use-disabled {
      background: #d6d6d6;
      .use {
        color: #003532;
      }
    }
  }
  .amount {
    height: 18px;
    text-align: center;
    line-height: 18px;
    position: absolute;
    z-index: 2000;
    left: 0;
    top: 0;
    background-color: #ff5001;
    color: #fff;
    padding: 0 5px;
    border-radius: 0px 0px 6px 0px;
  }
  .sell {
    position: absolute;
    width: 100%;
    height: 29px;
    line-height: 29px;
    text-align: center;
    color: #ffe9b4;
    font-size: 12px;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12px);
  }
  .use {
    color: #ffe9b4;
    flex: 1;
    text-align: center;
    line-height: 1.5;
  }
}
.page-benefit-spu2 {
  box-sizing: border-box;
  width: 50%;
  margin-bottom: 8px;
  .page-spu-inner {
    margin: 0 4px;
    background: linear-gradient(180deg, #0f482a 0%, #052010 100%);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    padding: 9px 12px;
    border-radius: 8px;
    .left {
      box-sizing: border-box;
      width: 50%;
      padding-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      .page-benefit-image {
        width: 44px;
        height: 44px;
      }
      p {
        font-size: 12px;
        color: #fff;
        margin-top: 9px;
        text-align: center;
      }
    }
    .right {
      position: relative;
      .page-benefit-image {
        width: 68px;
        height: 83px;
      }
      .amount {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background: linear-gradient(90deg, #016f3b 0%, #003532 100%);
        height: 14px;
        line-height: 14px;
        border-radius: 0px 0px 4px 4px;
        color: #ffe9b4;
        font-size: 10px;
        min-width: 40px;
        text-align: center;
      }
      .amount-none {
        background: #d6d6d6;
        color: #003532;
      }
    }
  }
}

.card-past {
  height: 195px;
  background-color: #fff;
  border-radius: 5px;
  margin: 12px 12px 0;
  overflow: hidden;
  p {
    text-align: center;
    line-height: 1.5;
    color: #404040;
    margin-top: 17px;
  }
  /deep/ .van-image {
    width: 48px;
    height: 48px;
    margin: 56px auto 4px;
  }
}
.yhk-server {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 10px;
  bottom: 100px;
}
</style>