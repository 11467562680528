var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"ans",rawName:"v-ans",value:({
    event_id: 'hotel_list_click_hotel',
    destination_name: _vm.data.city,
    checkin_date: _vm.dayjs(_vm.hotel.checkin_date).format('YYYY-MM-DD'),
    hotel_type: _vm.data.classification,
    hotel_id: _vm.data.id,
    hotel_name: _vm.data.name,
    hotel_price: _vm.data.lowest_price,
    is_special: _vm.data.is_special,
    pay_method: _vm.data.pay_method,
    is_direct_sign: _vm.data.is_direct_sign,
  }),expression:"{\n    event_id: 'hotel_list_click_hotel',\n    destination_name: data.city,\n    checkin_date: dayjs(hotel.checkin_date).format('YYYY-MM-DD'),\n    hotel_type: data.classification,\n    hotel_id: data.id,\n    hotel_name: data.name,\n    hotel_price: data.lowest_price,\n    is_special: data.is_special,\n    pay_method: data.pay_method,\n    is_direct_sign: data.is_direct_sign,\n  }"}],staticClass:"hotel-item",on:{"click":_vm.toDetail}},[_c('van-image',{staticClass:"hotel-image",attrs:{"fit":"cover","lazy-load":"","src":_vm.data.images[0]}}),_c('div',{staticClass:"hotel-info"},[_c('div',{staticClass:"hotel-name"},[_vm._v(" "+_vm._s(_vm.data.name)+" "),_c('van-image',{staticClass:"hotel-type-image",attrs:{"src":require(("@/assets/images/hotel2/icon-hotel-type" + (_vm.data.classification) + ".png"))}})],1),_c('div',{staticClass:"hotel-desc"},[_vm._v("\""+_vm._s(_vm.data.rating)+"\"")]),_c('van-row',{staticClass:"advantages"},_vm._l((_vm.advantages),function(item){return _c('van-col',{key:item.name,staticClass:"advantage-item"},[(item.icon)?_c('svg-icon',{attrs:{"iconClass":("tag-" + (item.icon))}}):_vm._e(),_vm._v(_vm._s(item.name)+" ")],1)}),1)],1),_c('div',{staticClass:"hotel-footer"},[_c('div',{staticClass:"hotel-map ellipsis"},[_vm._v(" "+_vm._s(_vm.data.address)+" ")]),(_vm.data.is_all_room_date_unavailable)?_c('div',{staticClass:"room-unavailable"}):_c('div',{staticClass:"hotel-price"},[(_vm.data.lowest_origin_price)?_c('span',{staticClass:"hotel-face_value"},[_vm._v("¥"+_vm._s(_vm.data.lowest_origin_price))]):_vm._e(),_c('span',{staticClass:"hotel_real_price"},[_c('small',[_vm._v("¥")]),_vm._v(_vm._s(_vm.data.lowest_price))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }