<template>
  <main v-if="!loading" class="order-detail">
    <template v-if="!showEmpty">
      <template v-if="isHotel">
        <newHotelOrder
          :system_date="system_date"
          :order="order"
          :notice="notice"
          :toIndex="toIndex"
          :showIndex="showIndex"
        />
      </template>
      <buffetOrder
        v-else-if="order.type === 'buffet'"
        :system_date="system_date"
        :order="order"
        :notice="notice"
      />
      <park-order
        v-else-if="order.type === 'paradise'"
        :system_date="system_date"
        :order="order"
        :notice="notice"
      />
      <new-order
        v-else
        :toIndex="toIndex"
        :showIndex="showIndex"
        :order.sync="order"
        :images="images"
        :detail="order"
        :fetchOrderFn="fetchOrder"
        :oilOrder="oil_order"
        :verifyInfo="verify_info"
      />
      <open-member-dialog v-if="showDialog" page="order_detail" />
    </template>
    <van-empty v-else description="订单不存在" />
  </main>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import newHotelOrder from "./newHotelOrder";
import buffetOrder from "./buffetOrder";
import NewOrder from "./newOrder";
import parkOrder from "./parkOrder";
import { getNamespaceBenefitImage, getOrderDetail } from "@/apis";
import { getOilOrderDetail, getOilVerifyInfo } from "@/apis/oil";
import { getOrderNotice } from "@/apis/hotel";
import OpenMemberDialog from "@/components/OpenMemberDialog";
export default {
  components: {
    NewOrder,
    newHotelOrder,
    buffetOrder,
    parkOrder,
    OpenMemberDialog,
  },
  data() {
    return {
      showEmpty: false,
      loading: true,
      notice: [],
      isHotel: false,
      order: {},
      system_date: new Date().getTime(),
      oil_order: {},
      verify_info: {},
      images: {},
    };
  },
  computed: {
    ...mapState(["namespace", "source", "namespaceInfo", "return_index_url"]),
    ...mapGetters(["namespaceGroup"]),
    showDialog() {
      return (
        this.$route.query.from === "activate" ||
        this.$route.query.from === "activity" ||
        this.$route.query.from === "payResult"
      );
    },
    showIndex() {
      // 返回首页
      if (this.return_index_url) {
        return true;
      }
      if (
        ["shengBei_Android", "shengBei_Ios", "welabApp"].includes(this.runtime)
      ) {
        return true;
      }

      if (this.$getConfigValue("INDEX_URL")) {
        return true;
      }

      return false;
    },
  },

  async mounted() {
    const { order_number } = this.$route.query;
    if (!order_number) {
      this.showEmpty = true;
      this.loading = false;
      return;
    }
    const toast = this.$loading();
    try {
      await this.fetchOrder();
    } catch (e) {
      this.showEmpty = true;
    } finally {
      toast.clear();
      this.loading = false;
    }
  },

  methods: {
    toIndex() {
      if (this.return_index_url) {
        window.location.href = decodeURIComponent(this.return_index_url);
        return;
      }

      if (this.runtime === "shengBei_Android") {
        SMYJSInterface.appGoBack();
        return;
      }
      if (this.runtime === "shengBei_Ios") {
        window.webkit.messageHandlers.SMYJSInterface.postMessage({
          method: "appGoBack",
        });
        return;
      }

      // 我来数科
      if (this.runtime === "welabApp") {
        window.welab.close();
        return;
      }

      const url = this.$getConfigValue("INDEX_URL");
      if (url.startsWith("/")) {
        this.$router.push(url);
      } else {
        this.$jumpUrl(url, "switchTab");
      }
    },

    async fetchOrder() {
      const { order_number, type } = this.$route.query;
      let opts = { order_number };

      if (type === "addoil") {
        this.getOilOrder(opts);
      }
      const res = await getOrderDetail(opts);
      if (!res.ok) {
        this.showEmpty = true;
        return;
      }

      const order = res.data;
      // 没有配置过期时间，默认改为3天

      this.order = order;
      const imageRes = await getNamespaceBenefitImage({
        namespace_en: this.namespace,
        product_code: order.type,
      });
      this.images = imageRes.data;
      this.system_date = new Date().getTime();
      this.isHotel = order.type === "hotel" || order.type === "ehotel";
      if (this.isHotel) {
        const payInfo = JSON.parse(order.pay_info);
        // 兼容处理奈雪卡券返回错误问题
        // 如果奈雪卡券返回了卡密，将卡密改为卡券展示。
        if (
          payInfo.item_code.includes("naixue") &&
          payInfo.secret_code &&
          (payInfo.coupon_format === "single" ||
            payInfo.coupon_format === "kami")
        ) {
          payInfo.coupon_code = payInfo.secret_code;
          payInfo.secret_code = "";
        }
        const notice = await getOrderNotice({
          pay_method: payInfo.pay_method,
          title_set: "取消规则,付款方式,温馨提示",
        });
        this.notice = notice.data;
      }
    },

    async getOilOrder(opts) {
      const load = this.$loading();
      const res = await getOilOrderDetail(opts);
      const res2 = await getOilVerifyInfo(opts);
      const order = res.data;
      this.oil_order = order;
      this.verify_info = res2.data;
      load.clear();
    },
  },
};
</script>


