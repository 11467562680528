<template>
  <div class="outside-wrap">
    <div class="wrap">
      <div
        class="tab-wrap"
        v-for="(item, index) of tabsEnums"
        :key="item.namespace"
        :class="{
          [`tab-wrap-${index}`]: activeCard === item.namespace,
          active: activeCard === item.namespace,
        }"
        @click="changeTab(item.namespace)"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const Tabs_ENUMS = [
  { namespace: "licheng3", name: "尊享卡" },
  { namespace: "licheng5", name: "经典卡" },
  { namespace: "licheng4", name: "省钱卡" },
  { namespace: "licheng6", name: "免费领取" },
];

export default {
  props: ["activeCard"],
  data() {
    return {
      tabsEnums: [...Tabs_ENUMS],
    };
  },

  methods: {
    async changeTab(value) {
      if (this.activeCard !== value) this.$emit("onTabChange", value);
    },
  },
};
</script>

<style scoped lang="less">
.outside-wrap {
  background: rgba(238, 240, 243, 1);
  padding-bottom: 12.5px;
  overflow: hidden;
}

.wrap {
  width: 100%;
  height: 43px;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.tab-wrap {
  box-sizing: border-box;
  position: relative;
  height: 43px;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  font-family: PingFangSC, PingFang SC;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #333333;
  padding: 7px 0 0 14px;
  color: rgba(0, 0, 0, 0.5);

  span {
    position: relative;
    z-index: 1;
  }

  &:nth-child(4) {
    padding-right: 20px;

    &:not(.active) {
      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: -16px;
        right: 0;
        width: 16px;
        height: 16px;
        background: radial-gradient(
          circle at 0% 100%,
          transparent 16px,
          #fff 0
        );
      }
    }
  }

  &:nth-child(1) {
    padding-left: 18px;

    &:not(.active) {
      &::before {
        content: "";
        z-index: 0;
        display: block;
        position: absolute;
        bottom: -16px;
        left: 0;
        width: 16px;
        height: 16px;
        background: radial-gradient(
          circle at 100% 100%,
          transparent 16px,
          #fff 0
        );
      }
    }
  }

  &.active {
    color: #333333;

    > span {
      &::after {
        content: "";
        position: absolute;
        width: 18px;
        height: 4px;
        background: linear-gradient(90deg, #f6d6ba 0%, #e9ac8c 100%);
        left: 50%;
        top: 24px;
        transform: translateX(-50%);
        border-radius: 2px;
      }
    }
  }

  &.tab-wrap-0 {
    z-index: 0;

    &::after {
      content: "";
      position: absolute;
      height: 43px;
      width: 110px;
      top: 0px;
      left: 0;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      background: url("https://common-h5-dev.xiangshuheika.com/static/upload/202437bec016ce50ff469aaff633043174e0ec.png");
    }
  }

  &.tab-wrap-1,
  &.tab-wrap-2 {
    z-index: 0;

    &::after {
      content: "";
      position: absolute;
      height: 43px;
      width: 100px;
      top: 0px;
      left: -5px;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      background: url("https://common-h5-dev.xiangshuheika.com/static/upload/2024377f809189106e4a849cc75ca18965d710.png");
    }
  }

  &.tab-wrap-3 {
    z-index: 0;

    &::after {
      content: "";
      position: absolute;
      height: 43px;
      width: 110px;
      top: 0px;
      right: 0;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      background: url("https://common-h5-dev.xiangshuheika.com/static/upload/202437ee3d072a40b44a1f8a47317cfef3a36e.png");
    }
  }
}
</style>
