<template>
  <div class="footer-wrap">
    <div @click="handleJump('/order')">
      <img
        src="https://common-h5-dev.xiangshuheika.com/static/upload/202437c237b91ac3bb41fb9aecf6d35d62201e.png"
      />
      <p>我的订单</p>
    </div>
    <div @click="handleJump('/help')">
      <img
        src="https://common-h5-dev.xiangshuheika.com/static/upload/2024376ec8ef5f0078454e97576f2f711eda94.png"
      />
      <p>专属客服</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleJump(type) {
      this.$router.push(type);
    },
  },
};
</script>

<style scoped lang="less">
.footer-wrap {
  display: flex;
  position: fixed;
  width: 375px;
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 12px 0 17px 0;
  justify-content: space-evenly;

  > div {
    width: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  img {
    height: 30px;
    width: 30px;
  }
  p {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #333333;
    line-height: 18px;
    margin-top: 9px;
    white-space: nowrap;
  }
}
</style>
