<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  computed: {},

  async mounted() {},
  methods: {},
};
</script>

<style scoped lang='less'>
</style>