<template>
  <div class="btn" @click="handleClick" v-if="isMember || notPay">去使用</div>
</template>

<script>
import { mapState } from "vuex";

import ans from "@/utils/ans";

import { zhonganApply } from "../service";

export default {
  props: ["product_code", "notPay"],
  computed: {
    ...mapState(["isMember", "namespace"]),
  },

  methods: {
    async handleClick() {
      if (!this.isMember) {
        //chenghua_to_use
        ans.track("chenghua_to_use", {
          namespace: this.namespace,
        });
        this.jump();
        return;
      }
      this.$router.push(`benefitDetail?benefit_type=${this.product_code}`);
    },
    async jump() {
      this.$confirm({
        message: `<p>您尚未支付会员费，</p><p>立即支付即可享受会员权益</p>`,
        confirmButtonText: "好的",
        cancelButtonText: "放弃",
      }).then(
        async () => {
          const trans_info = this.$route.query.transInfo;
          await zhonganApply({ namespace_name: this.namespace, trans_info });
          ans.track("chenghua_to_apply", {
            namespace: this.namespace,
          });
        },
        () => {}
      );
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  margin-top: 16px;
  height: 26px;
  width: 100%;
  background: #f6dab0;
  text-align: center;
  border-radius: 6px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 14px;
  color: #7e5c37;
  line-height: 26px;
}
</style>