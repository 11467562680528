import request from '@/utils/request';
import { geetestHOF } from '@/utils'

//抽奖接口
export const handleActivityReward = (data) => {
  const apiFunc = () => request({
    url: '/api/activity/handle_activity_reward',
    method: 'post',
    data,
  })

  return geetestHOF(apiFunc, 'joinActive')
}

//keep 五一活动 抽奖接口
export const handleKeepActivityReward = (data) => {
  return request({
    url: '/api/activity/handle_keep_activity_reward',
    method: 'post',
    data,
  })
}

//查询用户抽中奖品列表
export const getAtivityRecord = (params) => {
  return request({
    url: '/api/activity/activity_record',
    method: 'get',
    params,
  })
}
// 用户抽奖次数
export const getUserDrawAppoint = (params) => {
  return request({
    url: '/api/activity/get_user_draw_appoint',
    method: 'get',
    params,
  })
}
// 提交地址
export const updateActivityReward = (data) => {
  return request({
    url: '/api/activity/update_activity_reward',
    method: 'post',
    data,
  })
}
