<template>
  <div class="main">
    <div class="card-past">
      <van-image :src="require('../../../assets/images/yh-empty.png')" />
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api";

import { useStore } from "@/components/use/base";

export default defineComponent({
  setup() {
    const store = useStore();

    const userInfo = store.state.userInfo;
    const isMember = store.state.isMember;

    const text = computed(() => {
      if (!isMember) return "您目前还不是会员，请开通会员后使用";
      return {
        refund: "您的权益已退款",
        expired: "您的权益已过期",
      }[this.userInfo?.card_status];
    });

    return {
      userInfo,
      text,
    };
  },
});
</script>


<style lang="less" scoped>
.main {
  margin: 0 14px;
  height: 331px;
  background: #fff;
  border-radius: 13px;
  position: relative;
}

.card-past {
  border-radius: 5px;
  overflow: hidden;
  p {
    text-align: center;
    line-height: 1.5;
    margin-top: 17px;
    font-size: 16px;
    margin-top: 17px;
  }
  /deep/ .van-image {
    width: 80px;
    height: 80px;
    margin: 56px auto 14px;
  }
}
</style>