<template>
  <div class="refund-status-bar">
    <div class="refund-status-bar-item refund-status-submit active">
      <van-image
        class="refund-status-icon"
        :src="require('@/assets/images/refund_submit_active_icon.png')"
      />
      发起退款
    </div>
    <div :class="{ 'refund-status-bar-line': true, active: true }"></div>
    <div class="refund-status-bar-item refund-status-examine">
      <div class="refund-status-icon" v-if="waiting">
        <div :class="{ loader: true }">
          <span class="loader-top"></span>
          <span class="loader-bottom"></span>
        </div>
      </div>
      <van-image
        v-else
        class="refund-status-icon"
        :src="require('@/assets/images/refund_examine_active_icon.png')"
      />
      退款审核中
    </div>
    <div :class="{ 'refund-status-bar-line': true, active: complete }"></div>
    <div
      :class="[
        'refund-status-bar-item',
        complete && 'active',
        error ? 'refund-status-error' : 'refund-status-success',
      ]"
    >
      <van-image
        class="refund-status-icon"
        :src="
          require(`@/assets/images/${
            complete
              ? !error
                ? 'refund_success_active_icon'
                : 'refund_failed_active_icon'
              : 'refund_success_icon'
          }.png`)
        "
      />

      {{ error ? "退款失败" : "完成退款" }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    waiting: {
      type: Boolean,
      default: false,
    },
    complete: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
.refund-status-bar {
  display: flex;
  align-items: center;
  padding: 35px 16px 32px;
  justify-content: space-between;
}

.refund-status-bar-line {
  width: 72px;
  height: 2px;
  flex-shrink: 0;
  background: #d8d8d8;
  align-self: flex-start;
  margin-top: 19px;
  &.active {
    background: #ffdaa4;
  }
}

.refund-status-bar-item {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.refund-status-examine {
  .refund-status-icon {
    border-radius: 50%;
    overflow: hidden;
    background: linear-gradient(180deg, #ffe7c8 0%, #efd3ad 100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.refund-status-icon {
  width: 38px;
  height: 38px;
  margin-bottom: 8px;
}

.loader {
  font-size: 3.5px;
  width: 4.3em;
  height: 7.6em;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.loader-top,
.loader-bottom {
  width: 2.5em;
  height: 2.5em;
  border-style: solid;
  background: #fff;
  border-color: #fff;
}

.loader-top,
.loader-bottom {
  border-width: 0.2em 0.2em 0.6em 0.6em;
  border-radius: 50% 100% 50% 30%;
}

.loader-top {
  transform: rotate(-45deg);
}

.loader-bottom {
  transform: rotate(135deg);
}

.loader-top::before,
.loader-bottom::before {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: #f0d4af;
}

.loader-top::before {
  border-radius: 0 100% 0 0;
}

.loader-bottom::before {
  border-radius: 0 0 0 35%;
}

.loader-top::before,
.loader-bottom::before {
  animation: 2s linear infinite;
}

.loader-top::before {
  animation-name: drop-sand;
}

@keyframes drop-sand {
  to {
    transform: translate(-1.5em, 1.5em);
  }
}

.loader-bottom::before {
  transform: translate(1.5em, -1.5em);
  animation-name: fill-sand;
}

@keyframes fill-sand {
  to {
    transform: translate(0, 0);
  }
}

.loader-top,
.loader-bottom {
  overflow: hidden;
}

.loader::after {
  content: "";
  position: absolute;
  width: 0.2em;
  height: 2.5em;
  background-color: #f0d4af;
  top: 1em;
}

.loader::after {
  animation: flow 2s linear infinite;
}

@keyframes flow {
  10%,
  100% {
    transform: translateY(3.2em);
  }
}

.loader {
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  0%,
  90% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0.5turn);
  }
}
</style>
