<template>
  <div class="travel-wrap">
    <div class="title">{{ info.name }}</div>
    <div
      class="content"
      v-for="(item, index) of info.productInfo"
      :key="item.id"
    >
      <template v-if="index === 0">
        <div class="desc">{{ item.desc }}</div>
        <div class="main">
          <div class="coupon">
            <div class="value"><span>5</span>元</div>
            <div class="foot">
              <span>滴滴快车代金券</span>
              <div class="btn" @click="draw(item)">立即领取</div>
              <div class="tip">2选1</div>
            </div>
          </div>
          <div class="coupon">
            <div class="value"><span>5</span>元</div>
            <div class="foot">
              <span>高德打车代金券</span>
              <div class="btn" @click="draw(item)">立即领取</div>
              <div class="tip">2选1</div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="index === 1">
        <div class="desc">{{ item.desc }}</div>
        <div class="main">
          <div class="coupon-1" @click="draw(item)">
            <p>全球机场</p>
            <p>贵宾休息室</p>
            <span class="btn">9.9元</span>
            <div class="tip">3选1</div>
          </div>
          <div class="coupon-1" @click="handleDraw(item)">
            <p>全球高铁</p>
            <p>贵宾休息室</p>
            <span class="btn">9.9元</span>
            <div class="tip">3选1</div>
          </div>
          <div class="coupon-1" @click="draw(item)">
            <p>全球机场</p>
            <p>快速安检</p>
            <span class="btn">9.9元</span>
            <div class="tip">3选1</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["info", "canUseBenefit", "handleDraw"],
  methods: {
    draw(item) {
      if (!this.canUseBenefit) return;
      this.handleDraw(item);
    },
  },
};
</script>

<style scoped lang="less">
.travel-wrap {
  width: auto;
  box-sizing: border-box;
  background: #fff;
  border-radius: 16px;
  margin-top: 12.5px;
  padding: 18px 14px 14px 14px;
  position: relative;

  .title {
    position: absolute;
    top: 9px;
    left: 13px;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #333333;
  }

  .content {
    &:not(:last-child) {
      margin-bottom: 13px;
    }
    .desc {
      font-size: 11px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #afb2b5;
      text-align: right;
    }

    .main {
      display: flex;
      justify-content: space-between;
    }
    .coupon {
      overflow: hidden;
      position: relative;
      border-radius: 8px;
      box-sizing: border-box;
      width: 157.5px;
      height: 60px;
      background: #fffbf8;
      padding-left: 12.5px;
      position: relative;
      margin-top: 12px;

      .value {
        margin-top: 7.5px;
        font-size: 9px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #333333;
        span {
          font-size: 20px;
        }
      }

      .foot {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10.5px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #afb2b5;

        .btn {
          padding: 1px 7px;
          border: 1px solid;
          color: rgba(230, 154, 123, 1);
          border-radius: 10px;
          margin-right: 6px;
        }
      }
    }

    .coupon-1 {
      overflow: hidden;
      box-sizing: border-box;
      margin-top: 12px;
      width: 101.5px;
      height: 80.5px;
      background: #fffbf8;
      border-radius: 8px;
      padding-top: 14px;
      font-family: PingFangSC, PingFang SC;
      position: relative;

      > p {
        font-size: 12px;
        font-weight: 600;
        color: #4c4c4c;
        text-align: center;
        line-height: 16.5px;
      }
      > .btn {
        position: absolute;
        padding: 1px 13px;
        border: 1px solid rgba(230, 154, 123, 1);
        border-radius: 8px;
        font-size: 9px;
        font-weight: 600;
        color: #e69a7b;
        left: 50%;
        transform: translateX(-50%);
        top: 58px;
      }
    }
    .tip {
      position: absolute;
      font-size: 9px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #6e2400;
      padding: 1px 6px;
      background: linear-gradient(90deg, #f6d6ba 0%, #e9ac8c 100%);
      border-radius: 0 0 0 8px;
      top: 0;
      right: 0;
    }
  }
}
</style>
