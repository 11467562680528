<template>
  <van-popup
    :value="show"
    closeable
    position="bottom"
    round
    @click-overlay="close"
    @click-close-icon="close"
    @close="close"
  >
    <div class="title">{{ title }}</div>
    <ul class="select">
      <li
        @click="onSelect(item)"
        :class="['select-item', value === item.value && 'active']"
        v-for="item in transformOptions"
        :key="item.value"
      >
        <svg-icon
          class="icon-checkmark"
          iconClass="checkmark"
          v-if="value === item.value"
        />
        {{ item.label }}
      </li>
    </ul>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String,
    options: {
      type: Array,
      default: () => [],
    },
    selectValue: [Number, String],
    value: Number,
    optionFieldMap: {
      default: () => ({
        label: "label",
        value: "value",
      }),
      type: Object,
    },
  },
  model: {
    prop: "show",
    event: "change",
  },
  computed: {
    transformOptions() {
      return this.options.map((item) => {
        return {
          label: item[this.optionFieldMap.label],
          value: item[this.optionFieldMap.value],
        };
      });
    },
  },
  data() {
    return {};
  },
  methods: {
    onSelect(item) {
      this.$emit("update:value", item.value);
      this.close();
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  padding: 12px 0;
  line-height: 28px;
  font-size: 15px;
  color: #000;
  text-align: center;
  font-weight: bold;
}
.select {
  padding: 0 27px 10px;
}
.select-item {
  padding: 15px 0;
  text-align: center;
  line-height: 20px;
  color: #333;
  font-size: 14px;
  & + & {
    border-top: 0.5px solid #eaeaea;
  }
  &.active {
    color: #ff5001;
  }
  .icon-checkmark {
    margin-left: -14px;
    color: #ff5001;
  }
}
</style>