<template>
  <div class="order-header">
    <div :class="order.status_desc ? 'order-status' : 'order-status2'">
      <div class="order-status-tit">
        <svg-icon
          v-if="order.status !== 'confirming'"
          class="order-status-icon"
          :iconClass="imageEnum[order.status] || 'unpay'"
        />
        <p class="order-status-name">{{ statusEnum(order.status) }}</p>
        <van-loading
          v-if="order.status === 'confirming'"
          style="margin-left: 6px"
          size="20"
          color="#000"
          type="spinner"
        />
      </div>
      <p class="order-status-errmsg" v-if="order.status_desc">
        {{ order.status_desc }}
      </p>
    </div>
    <div class="order-product order-card">
      <div class="subscript"></div>
      <div class="inner-bg1"></div>
      <div class="inner-bg2"></div>
      <van-image
        lazy-load
        class="order-product-image"
        fit="contain"
        :src="getImageUrl"
      />
      <div class="order-product-content">
        <div class="order-product-desc ellipsis">
          {{ detail.desc || detail.name }}
          <div class="desc-info" v-if="isOil">
            {{ oilOrder.oil_name }} | {{ oilOrder.gun_no }}号油枪 |
            {{ oilOrder.units }}L
          </div>
        </div>
        <div class="order-product-facevalue" v-if="!hideFaceValue && !isOil">
          <div class="order-product-price">
            会员优惠价：<span
              class="price"
              v-if="detail.type !== 'ssaw' && detail.type !== 'healthcare'"
            >
              <span class="sub">¥</span>{{ detail.amount }}
            </span>
            <span class="price" v-else>免费领取</span>
            <span class="facevalue">原价 ¥{{ detail.face_value }}</span>
          </div>
        </div>
        <div class="member-price" v-if="isOil">
          会员价
          <p><span>¥</span> {{ oilOrder.real_money }}</p>
        </div>
      </div>
      <div class="preferential-price" v-if="isOil">
        <div class="item">
          订单金额： <span>¥{{ oilOrder.total_money }}</span>
        </div>
        <div class="item">
          优惠金额： <span>-¥{{ oilOrder.savings }}</span>
        </div>
        <div class="item">
          服务费： <span>+¥{{ oilOrder.service_fee }}</span>
        </div>
        <div class="item2">
          合计 <span>¥{{ oilOrder.real_money }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "@vue/composition-api";
import { ORDER_DETAIL_STATUS } from "@/constants";
import { useStore } from "@/components/use/base";
const hide_original_price_list = [
  { namespace: "chongtian", code: "CT" },
  { namespace: "yh", code: "YH" },
  { namespace: "xy", code: "XY" },
];
export default defineComponent({
  props: {
    order: Object,
    detail: Object,
    oilOrder: Object,
    itemInfo: Object,
    payInfo: Object,
    isOil: Boolean,
    namespaceGroup: String,
    images: Object,
  },
  setup({
    order,
    detail,
    oilOrder,
    itemInfo,
    payInfo,
    isOil,
    namespaceGroup,
    images,
  }) {
    const store = useStore();
    const statusEnum = (status) => {
      const state_text = { ...ORDER_DETAIL_STATUS };
      if (!store.state.userInfo.is_member) {
        state_text.confirming = "待领取";
        state_text.canceled = "已失效";
      }
      return state_text[status];
    };
    const hideFaceValue = computed(() => {
      return hide_original_price_list.some((e) => {
        return (
          store.state.namespace.includes(e.namespace) &&
          (itemInfo.item_code || "").includes(e.code)
        );
      });
    });
    const imageEnum = {
      success: "order-success",
      failed: "order-cancel",
      confirming: "order-success",
      unpaid: "order-wait",
      canceled: "order-cancel",
      refund: "order-cancel",
      paid: "order-success",
    };
    const getImageUrl = computed(() => {
      if (images.titleIcon?.[0].image_url) {
        return images.titleIcon?.[0].image_url;
      }
      let iconname = payInfo.detail_type || detail.type;
      if (detail.type === "atour") {
        iconname = detail.type;
      }
      if (isOil) {
        return this.oilOrder.oil_station_info?.logo_small;
      }
      return `https://cdn.xiangshuheika.com/static/${namespaceGroup}/${iconname}.png`;
    });
    return {
      imageEnum,
      statusEnum,
      hideFaceValue,
      getImageUrl,
    };
  },
});
</script>

<style lang="less" scoped>
.order-header {
  background: linear-gradient(140deg, #fff8ee 0%, #f5e4d3 100%);
  box-sizing: border-box;
  padding-top: 31px;
  margin-bottom: 20px;
  .order-status-name {
    margin-left: 4px;
  }
}

.order-status-tit {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 30px;
  color: #000;
  font-size: 22px;
  .order-status-icon {
    font-size: 23px;
  }
}
.order-status-errmsg {
  text-align: center;
  line-height: 23px;
  margin-top: 7px;
  color: #333;
}

.subscript {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 58px;
  height: 48px;
  background: url("../../assets/images/order-sub-icon.png") no-repeat top/100%;
}
.order-product {
  position: relative;
  margin: 16px 48px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #6c3e0c;
  border-radius: 6px 6px 0px 0px;
  border: 0px solid #ffffff;
  padding: 24px 22px;
  z-index: 2;
}

.inner-bg1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  background: url("../../assets/images/order_card_bg.png") no-repeat;
  background-size: 100%;
}
.inner-bg2 {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 23px;
  z-index: 0;
  background: url("../../assets/images/order-card-inner-bg.png") no-repeat
    bottom/100%;
}

.order-product-image {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin-right: 11px;
}

.order-product-desc {
  line-height: 22px;
  margin-bottom: 3px;
  font-size: 16px;
}
.order-product-content {
  flex: 1;
  z-index: 1;
  overflow: hidden;
}
.order-product-facevalue {
  font-size: 11px;
  line-height: 16px;
  .price {
    margin-right: 10px;
  }
  .facevalue {
    opacity: 0.7;
    text-decoration: line-through;
  }
}
</style>
