import { baseApi } from '@/config';
import request from '@/utils/request';

//获取退款列表接口
export const getrefundList = (data) => {
  return request({
    url: '/api/core/refund_application_record',
    method: 'post',
    data,
  })
}

//获取退款明细接口
export const refundDetail = (data) => {
  return request({
    url: '/api/core/cost_detail',
    method: 'post',
    data,
  })
}

//获取退款原因
export const getRefundReason = (data) => {
  return request({
    url: '/api/core/refund_reason',
    method: 'post',
    data,
  })
}

//获取退款原因
export const getRefundOrder = (data) => {
  return request({
    url: '/api/core/query_partner_refund',
    method: 'post',
    data,
  })
}

//发送offer
export const sendOffer = (data) => {
  return request({
    url: '/api/core/send_offer',
    method: 'post',
    data,
  })
}

//查询省钱卡是否可续费
export const queryOakLifeRenewable = (data) => {
  return request({
    url: '/api/core/query_renewable_status_by_unique_id',
    baseURL: baseApi.life,
    method: 'post',
    data,
  })
}