<template>
  <section class="offline-prize">
    <section class="order block">
      <div class="title">订单信息</div>
      <van-field readonly label="订单号" :value="order_number" />
      <van-field readonly type="textarea" label="奖品" :value="order_name" />
    </section>
    <section class="address block">
      <div class="title">填写收货地址</div>
      <div class="subtitle">填写后10个工作日内发货，请留意短信通知</div>
      <van-form @submit="throttleSubmit">
        <van-cell-group inset>
          <van-field
            v-model.trim="form.name"
            name="name"
            label="姓名"
            maxlength="10"
            placeholder="请输入姓名"
          />
          <van-field
            v-model.trim="form.phone"
            name="phone"
            label="手机号"
            maxlength="11"
            placeholder="请输入手机号"
          />
          <van-field
            v-model.trim="form.address"
            name="address"
            label="收货地址"
            maxlength="100"
            placeholder="请输入收货地址"
          />
        </van-cell-group>
        <div style="margin: 40px 16px">
          <van-button class="btn" round block type="info" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </section>
  </section>
</template>

<script>
import { updateActivityReward } from "@/apis/luckdraw";
import { useRoute, useRouter } from "@/components/use/base";
import { defineComponent, reactive } from "@vue/composition-api";
import Vue from "vue";
import throttle from "lodash.throttle";

const vm = Vue.prototype;
export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { order_number, order_name } = route.query;
    const form = reactive({
      name: "",
      phone: "",
      address: "",
    });
    const onSubmit = async () => {
      if (!form.name) {
        vm.$toast("请输入姓名");
        return;
      }
      if (!form.phone) {
        vm.$toast("请输入手机号");
        return;
      }
      if (!/^1[3-9]\d{9}$/.test(form.phone)) {
        vm.$toast("手机号格式输入不正确");
        return;
      }
      if (!form.address) {
        vm.$toast("请输入收货地址");
        return;
      }
      if (form.address.length < 8) {
        vm.$toast("收货地址输入太短");
        return;
      }
      await updateActivityReward({
        activity_name: "keep_blind_box",
        order_number,
        address: form.address,
        user_name: form.name,
        user_phone: form.phone,
      });
      form.phone = "";
      form.name = "";
      form.address = "";
      vm.$alert({
        title: "提交成功",
        message:
          "您已提交收货地址，提交后10个工作日内发货，请留意短信通知。如有疑问，请联系客服400-150-9669",
        confirmButtonText: "好的",
      }).then(() => {
        router.back();
      });
    };
    const throttleSubmit = throttle(onSubmit, 2000, { trailing: false });

    return {
      form,
      order_number,
      order_name,
      throttleSubmit,
    };
  },
});
</script>


<style lang="less" scoped>
.offline-prize {
  padding-top: 10px;
}
.block {
  margin-bottom: 16px;
}

.title {
  color: #333;
  font-weight: bold;
  line-height: 24px;
  padding-left: 16px;
  font-size: 14px;
  margin-bottom: 4px;
}

.subtitle {
  padding-left: 16px;
  font-size: 12px;
  color: #999;
  margin-bottom: 4px;
  line-height: 17px;
}

.btn {
  background: #e5615b;
}
</style>