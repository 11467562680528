<template>
  <div class="buffet-detail" v-if="!loading">
    <div class="buffet-header">
      <van-swipe
        class="buffet-header-swipe"
        :autoplay="3000"
        indicator-color="white"
      >
        <van-swipe-item v-for="(item, idx) in detail.image_list" :key="idx">
          <van-image fit="cover" class="hotel-img" lazy-load :src="item" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="buffet-info">
      <div class="buffet-info-name">
        {{ detail.buffet_merchant_info.name }} · {{ detail.name }}
      </div>
      <div class="buffet-info-location">
        <div class="buffet-info-address">{{ detail.address }}</div>
        <div class="buffet-info-extra">
          <div class="icon-map" @click="toMap">
            <svg-icon class="map-icon" iconClass="address-white" />
            <div class="icon-text">地图</div>
          </div>
          <a class="icon-phone" :href="`tel:${detail.phone}`">
            <svg-icon class="phone-icon" iconClass="phone-white" />
            <div class="icon-text">电话</div>
          </a>
        </div>
      </div>
      <div
        class="buffet-tag"
        @click="visible = true"
        v-if="detail.tag_list_info"
      >
        <div
          :class="`buffet-tag-item buffet-tag-${item.name_en}`"
          v-for="item in detail.tag_list_info.slice(0, 3)"
          :key="item.name"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="buffet-sku">
      <div class="buffet-sku-item" v-for="item in sku" :key="item.id">
        <van-image
          class="sku-img"
          lazy-load
          :src="item.image_list_info[0]"
          fit="cover"
        />
        <div class="buffet-sku-content">
          <div class="sku-name">
            {{ item.name
            }}<span class="sku-name-tag">{{ detail.defaultTag }}</span>
          </div>
          <div class="sku-time">
            时间：{{ item.start_time }}-{{ item.end_time }}
          </div>
          <ul class="sku-week">
            <li
              class="sku-week-tag"
              v-for="w in getWeekText(item.reserve_week_list)"
              :key="w"
            >
              {{ w }}
            </li>
          </ul>
          <div class="sku-price">
            会员价：¥<span class="sku-amount">{{
              item.buffet_price_info.member_price
            }}</span>
            <span class="sku-facevalue"
              >¥{{ item.buffet_price_info.market_price }}</span
            >
            <div 
              class="sku-book" 
              @click="toBook(item)"
              v-ans="{
                event_id: 'buffet_detail_book_button',
                namespace,
                buffet_id: item.id,
              }"
            >
              去预订
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sku-rule">
      <div class="sku-rule-title">使用规则</div>
      <div class="sku-rule-text" v-html="detail.desc" />
    </div>
    <van-popup
      closeable
      position="bottom"
      round
      @click-overlay="visible = false"
      @click-close-icon="visible = false"
      :value="visible"
    >
      <div class="popup-title">会员专属特权</div>
      <div class="popup-content">
        <div
          :class="`advantages-content ${item.name_en}`"
          v-for="(item, idx) in detail.tag_list_info"
          :key="idx"
        >
          <div class="advantages-content-title">{{ item.name }}</div>
          <div class="advantages-content-desc">{{ item.detail }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { qqMapTransBMap, storage } from "@/utils";
import { mapState } from "vuex";
import { getBenefitInfo } from "@/apis";
import { getBuffetList, getBuffetStoreDetail } from "@/apis/buffet";
export default {
  data() {
    return {
      loading: true,
      advantages: [{ title: "title", desc: "desc", name: "name" }],
      visible: false,
      detail: {},
      sku: [],
    };
  },
  computed: {
    ...mapState(["namespace", "isMiniProgram", "isWebchat", "userInfo"]),
  },
  async mounted() {
    const loading = this.$loading();
    try {
      const [sku, res] = await Promise.all([
        getBuffetList({ buffet_store_id: this.$route.query.id }),
        getBuffetStoreDetail(this.$route.query.id),
      ]);
      this.sku = sku.data.data;
      res.data.image_list = (res.data.image_list || "").split(",");
      this.detail = res.data;
    } finally {
      loading.clear();
      this.loading = false;
    }
  },
  methods: {
    getWeekText(weeks) {
      const weekEnum = [
        ["1", "周一"],
        ["2", "周二"],
        ["3", "周三"],
        ["4", "周四"],
        ["5", "周五"],
        ["6", "周六"],
        ["0", "周日"],
      ];
      return weekEnum
        .filter(([k]) => (weeks || "").split(",").includes(k))
        .map(([v, k]) => k);
    },
    async toMap() {
      if (this.isMiniProgram || this.isWebchat) {
        wx.ready(() => {
          wx.openLocation({
            latitude: parseFloat(this.detail.latitude),
            longitude: parseFloat(this.detail.longitude),
          });
        });
        return;
      }
      const tude = qqMapTransBMap(this.detail.longitude, this.detail.latitude);
      window.location.href = `http://api.map.baidu.com/marker?location=${tude.latitude},${tude.longitude}&title=${this.detail.name}&output=html`;
    },
    async toBook(item) {
      if (!storage.getItem("token")) {
        this.$toast("你还未登录，请先登录");
        return;
      }
      if (!this.userInfo.is_member) {
        this.$toast("您还不是会员");
        return;
      }
      // 判断用户是否有点数
      const benefit = await getBenefitInfo({
        benefit_type: "buffet",
      });
      const { amount_available, amount_reserved } = benefit.data;
      if (!(amount_available + amount_reserved)) {
        this.$toast("您权益次数为0，不可预订");
        return;
      }
      if (!amount_available) {
        this.$toast("您本期已预定过，请下期再来");
        return;
      }
      this.$router.push(
        `/buffet/book?store_id=${this.$route.query.id}&sku_id=${item.id}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.buffet-detail {
  background: #f5f5f5;
  padding-bottom: 24px;
}
.buffet-header {
  height: 200px;
}

.buffet-header-swipe {
  height: 200px;
}
.buffet-info {
  margin-top: -10px;
  position: relative;
  z-index: 100;
  height: 127px;
  padding-top: 12px;
  box-sizing: border-box;
  background: linear-gradient(
    360deg,
    rgba(255, 175, 34, 0) 0%,
    rgba(251, 118, 8, 0.9) 60%,
    #fa6400 100%
  );
  border-radius: 10px 10px 0px 0px;
  .buffet-info-name {
    font-size: 20px;
    color: #fff;

    line-height: 28px;
    padding-left: 12px;
  }

  .buffet-info-location {
    color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    padding-left: 12px;
  }
  .buffet-info-address {
    flex: 1;
  }
  .buffet-info-extra {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .buffet-tag {
    padding: 0 10px;
    box-sizing: border-box;
    margin: 0 12px;
    height: 41px;
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    background-image: url("../../assets/images/buffet_tag_arrow.png");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: 330px center;
  }
  .icon-map {
    position: relative;
    margin-right: 10px;
    &::after {
      content: "";
      position: absolute;
      right: -10px;
      top: 5px;
      height: 20px;
      background: rgba(#fff, 0.4);
      width: 1px;
    }
  }
  .icon-map,
  .icon-phone {
    text-align: center;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .icon-phone {
    margin-left: 10px;
    color: #fff;
  }
  .icon-text {
    font-size: 10px;
    opacity: 0.8;
    /deep/ a {
      color: inherit;
    }
  }
  .map-icon {
    font-size: 18px;
    margin-top: -3px;
  }
}
.buffet-sku {
  background: #fff;
  border-radius: 8px;
  margin: 8px 12px;
  padding: 12px 8px;
}
.buffet-sku-item {
  display: flex;
  &:not(:last-child) {
    padding-bottom: 15px;
    border-bottom: 1px solid #f5f5f5;
  }
  & + .buffet-sku-item {
    margin-top: 12px;
  }
}
.sku-img {
  flex-basis: 100px;
  height: 100px;
  flex-shrink: 0;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 8px;
}
.buffet-sku-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sku-name {
  font-size: 16px;
  color: #333;
  line-height: 22px;
  margin-bottom: 4px;
}
.sku-time {
  line-height: 17px;
  color: #333;
  font-size: 12px;
  margin-bottom: 4px;
}
.sku-week {
  display: flex;
  flex-wrap: wrap;
}
.sku-week-tag {
  flex-shrink: 0;
  padding: 0 7px;
  height: 17px;
  line-height: 17px;
  border: 1px solid #ff5001;
  border-radius: 9px;
  background: #ffeee6;
  margin-right: 4px;
  color: #ff5001;
  font-size: 12px;
  margin-bottom: 5px;
}
.sku-price {
  display: flex;
  align-items: flex-end;
  color: #ff5001;
  font-size: 12px;
  .sku-amount {
    font-size: 20px;
    font-weight: bold;
    margin-right: 3px;
    margin-bottom: -2px;
  }
  .sku-facevalue {
    color: #999;
    font-size: 10px;
    text-decoration: line-through;
  }
  .sku-book {
    width: 64px;
    height: 28px;
    background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
    border-radius: 14px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 28px;
    margin-left: auto;
  }
}
.sku-rule {
  background: #fff;
  margin: 0 12px;
  border-radius: 8px;
  padding: 20px 8px;
}
.sku-rule-title {
  color: #333;
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: bold;
}

.sku-rule-text {
  font-size: 12px;
  color: #666;
  line-height: 1.5;
}
.sku-name-tag {
  margin-left: 8px;
  padding: 2px 6px;
  font-size: 11px;
  vertical-align: 2px;
  color: #f1ccb5;
  background: #222222;
  border-radius: 8px 0px 8px 0px;
}

.advantages {
  padding-right: 18px;
  background-size: 10px;
}

.advantages-icon {
  display: inline-block;
  width: 10px;
  margin-right: 2px;
}

.advantages-name {
  color: #ff5001;
}

.popup-title {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 14px 0;
}

.advantages-content {
  margin-bottom: 10px;
  padding: 0 15px;
  &:last-child {
    margin-bottom: 25px;
  }
}
.advantages-content-title {
  line-height: 18px;
  color: #ff5001;
  font-size: 13px;
  margin-bottom: 5px;
}

.buffet-tag-item {
  background-repeat: no-repeat;
  background-image: url("../../assets/images/buffet_tag_member.png");
  background-size: 12px;
  padding-left: 15px;
  flex-shrink: 0;
  &:not(:last-child) {
    margin-right: 8px;
  }

  background-position: left center;
  &.buffet-tag-confirm {
    background-image: url("../../assets/images/buffet_tag_confirm.png");
  }
  &.buffet-tag-cancel {
    background-image: url("../../assets/images/buffet_tag_cancel.png");
  }
}

.advantages-content-desc {
  color: #333;
  line-height: 17px;
}
</style>