<template>
  <div v-if="!logining">
    <keep-alive :include="INCLUDE_COMPONENTS_CONFIG">
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import get from "lodash.get";
import ans from "@/utils/ans";
import { theme_color, qt_key, xsoak_qt_key } from "@/config";
import { getwxconfig } from "@/apis/hotel";
import { getShareInfo } from "@/apis/home";
import GeeTest from "@/utils/geetestVerify";

export default {
  computed: {
    ...mapState([
      "logining",
      "namespace",
      "namespaceInfo",
      "source",
      "isWebchat",
      "userInfo",
    ]),
  },

  data() {
    return {
      INCLUDE_COMPONENTS_CONFIG: ["entry", "benefit", "helpService"],
    };
  },

  async mounted() {
    if (
      ["licheng3", "licheng4", "licheng5", "licheng6"].includes(this.namespace)
    ) {
      this.INCLUDE_COMPONENTS_CONFIG = ["entry", "benefit"];
    }
    if (this.namespace) {
      this.initAns();
      this.setThemeColor();
      this.share();
    }
    if (navigator.userAgent.toLocaleLowerCase().includes("keep")) {
      // keep app增加标识
      sessionStorage.setItem("keepEntry", 1);
    }
    document.body.id = this.namespace;
    this.initGeeTest();
  },
  methods: {
    ...mapMutations(["changeState"]),
    ...mapActions(["fetchUserInfo", "fetchNamespace", "fetchClientConfigList"]),
    async share() {
      if (this.isMiniProgram || this.isWebchat) {
        const res = await getwxconfig({
          url: window.location.href.split("#")[0],
        });
        wx.config({
          debug: false,
          appId: "wxc57020f25695569b",
          timestamp: res.data["timestamp"],
          nonceStr: res.data["nonceStr"],
          signature: res.data["signature"],
          jsApiList: [
            "getLocation",
            "openLocation",
            "updateAppMessageShareData",
            "updateTimelineShareData",
          ],
        });
        wx.error(function (e) {
          console.log(e);
        });
        wx.ready(async () => {
          let shareInfo = {};
          if (this.isWebchat) {
            const res = await getShareInfo({
              namespace: this.namespace,
              env: "wechat",
            });
            shareInfo = res.data;
          }
          if (!Object.keys(shareInfo).length) {
            return;
          }
          wx.updateAppMessageShareData({
            title: shareInfo.title, // 分享标题
            desc: shareInfo.sub_title, // 分享描述
            link:
              window.location.origin +
              `/redirect.html?to_url=${encodeURIComponent(shareInfo.to_url)}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: shareInfo.img_url, // 分享图标
          });
          wx.updateTimelineShareData({
            title: shareInfo.title, // 分享标题
            desc: shareInfo.sub_title, // 分享描述
            link:
              window.location.origin +
              `/redirect.html?to_url=${encodeURIComponent(shareInfo.to_url)}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: shareInfo.img_url, // 分享图标
          });
        });
      }
    },
    initAns() {
      window.aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: [
          "appKey",
          this.namespace.indexOf("life") >= 0 ? xsoak_qt_key : qt_key,
        ],
      });
      window.aplus_queue.push({
        action: "aplus.appendMetaInfo",
        arguments: [
          "globalproperty",
          {
            namespace: this.namespace,
            source: this.source,
            utm_channel: this.source,
          },
        ],
      });
      this.ansView(this.$route);
      window.addEventListener("click", (e) => {
        let node = e.target;
        try {
          while (
            node !== document.body &&
            node?.parentNode &&
            !node?.getAttribute("data-ans")
          ) {
            node = node.parentNode;
          }
          if (node?.getAttribute("data-ans")) {
            const params = JSON.parse(node.getAttribute("data-ans"));
            ans.track(params.event_id, {
              namespace: this.namespace,
              ...params,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
    },
    setThemeColor() {
      const background = get(theme_color, `${this.namespace}.background`);
      const text_color = get(theme_color, `${this.namespace}.text_color`);
      background &&
        document.documentElement.style.setProperty("--background", background);
      text_color &&
        document.documentElement.style.setProperty("--color", text_color);
    },
    ansView(route) {
      const { pageData, page, title } = route.meta;
      if (pageData) {
        let params = {
          source: this.source,
          namespace: this.namespace,
        };
        for (const item of pageData) {
          if (typeof item === "object") {
            const [k, v] = Object.entries(item)[0];
            params[k] = get(this, v) || get(route.query, v);
          } else {
            params[item] = get(this, item) || get(route.query, item);
          }
        }
        ans.pageView(page || title, params);
      }
    },
    initGeeTest() {
      Vue.prototype.$loginValidate = new GeeTest({ type: "member_login" });
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.ansView(route);
      },
    },
    "userInfo.id": {
      handler(val) {
        if (val) {
          Vue.prototype.$secKillValidate?.destroy();
          Vue.prototype.$buyBenefitValidate?.destroy();
          Vue.prototype.$activeBenefitValidate?.destroy();
          Vue.prototype.$joinActivityValidate?.destroy();
          // 秒杀
          Vue.prototype.$secKillValidate = new GeeTest({ type: "sec_kill" });
          // 购买权益
          Vue.prototype.$buyBenefitValidate = new GeeTest({
            type: "buy_benefit",
          });
          // 激活权益
          Vue.prototype.$activeBenefitValidate = new GeeTest({
            type: "activate_benefit",
          });
          // 参加活动
          Vue.prototype.$joinActivityValidate = new GeeTest({
            type: "join_activity",
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang='less'>
</style>