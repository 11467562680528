<template>
  <van-popup
    closeable
    position="bottom"
    round
    @click-overlay="close"
    @click-close-icon="close"
    @close="close"
    :class="[className, `${namespace}-login-dialog`]"
    :value="value"
  >
    <div class="login-header">
      <div :class="{ 'login-title': true }">{{ title || "登录" }}</div>
      <div class="login-desc2" v-if="desc">{{ desc }}</div>
    </div>
    <van-form
      class="login-form"
      @submit="beforeSubmit"
      @failed="onFailed"
      :show-error="false"
      :show-error-message="false"
    >
      <div class="get-code">
        <van-field
          class="field-input"
          v-model.trim="phone"
          placeholder="请输入手机号"
          :rules="[
            {
              required: true,
              message: '请输入手机号',
            },
            {
              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
              message: '请输入正确的11位手机号',
            },
          ]"
        ></van-field>
      </div>
      <div class="get-code">
        <van-field
          class="field-input"
          v-model.trim="code"
          placeholder="请输入验证码"
          :rules="[{ required: true, message: '请输入验证码' }]"
        ></van-field>
        <div
          v-ans="{ event_id: 'validate_code_click' }"
          :class="{ code: true, countDown: countDown > 0 }"
          @click="getVerifyCode"
        >
          {{ countDown > 0 ? `${countDown}s` : "获取验证码" }}
        </div>
      </div>
      <div class="error-text">{{ error }}</div>
      <div class="form-submit">
        <van-button
          round
          size="large"
          block
          type="info"
          native-type="submit"
          v-ans="{ event_id: 'login_btn_click' }"
          >{{ btnText || "立即登录" }}</van-button
        >
      </div>
      <div class="login-agreement" v-if="agreement" v-html="agreement"></div>
    </van-form>
  </van-popup>
</template>

<script>
import { register } from "@/apis/home";
import { mapActions, mapMutations, mapState } from "vuex";
import { loginByExtra } from "@/apis";
import { storage } from "@/utils";

export default {
  props: {
    value: Boolean,
    close: {
      type: Function,
      default() {},
    },
    resolve: Function,
    reject: Function,
    ns: String,
    title: String,
    desc: String,
    className: String,
    btnText: String,
    agreement: String,
    beforeSendCode: Function,
  },
  data() {
    return {
      phone: "",
      code: "",
      countDown: 0,
      error: "",
      session_code: "",
    };
  },

  computed: {
    ...mapState(["namespace", "namespaceInfo", "source"]),
    getTitle() {
      return "请先登录";
    },
  },

  methods: {
    ...mapMutations(["changeState"]),
    ...mapActions(["fetchUserInfo"]),
    async beforeSubmit() {
      if (!this.session_code) {
        this.$toast("请先获取短信验证码");
        return;
      }
      this.onSubmit();
    },
    async onSubmit() {
      this.error = "";
      const loading = this.$loading("登录中...");
      try {
        const res = await loginByExtra({
          code: this.code,
          session_code: this.session_code,
          phone_number: this.phone,
          platform: this.namespace === "keep" ? "route" : "web",
          namespace: this.ns || this.namespace,
          hideMsg: true,
          source: this.source,
        });
        storage.setItem("login_phone_number", this.phone);
        const { user_token } = res.data;
        this.changeState({ token: user_token, ptoken: user_token });
        const userInfo = await this.fetchUserInfo();
        userInfo.login_phone_number = this.phone;
        this.resolve(userInfo);
        this.close();
      } catch (e) {
        console.log(e);
        if (e && e.code === 50201) this.$toast("短信验证码错误");
        if (e && e.code === 508) this.$toast("验证校验未通过");
      } finally {
        loading.clear();
      }
    },
    // 关闭弹窗时 清空form内容
    // 此方法由组件实例调用
    beforeClose() {
      this.phone = "";
      this.code = "";
      this.error = "";
    },

    onFailed({ errors }) {
      if (errors.length) {
        this.error = errors[0].message;
      }
    },
    async getVerifyCode() {
      if (this.countDown > 0) {
        return;
      }
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
        this.$toast("请输入正确的11位手机号");
        return;
      }
      if (this.beforeSendCode) {
        await this.beforeSendCode(this.phone);
      }

      const toast = this.$loading();
      try {
        const res = await register({
          phone_number: this.phone,
          app_name: this.$getConfigValue("APP_NAME"),
        });
        this.$toast("验证码已发送");
        this.countDown = 60;
        this.session_code = res.session_code;
        this.timer = setInterval(() => {
          this.countDown--;
          if (this.countDown == 0) {
            clearInterval(this.timer);
          }
        }, 1000);
      } finally {
        toast.clear();
      }
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.changeState({ showLogin: false });
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.login-header {
  text-align: center;
  padding: 20px 24px 20px;
  position: relative;
}
.login-icon {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 120px;
  background: url("../../assets/images/icon-s.png") no-repeat;
  background-size: 100%;
}
.login-title {
  line-height: 28px;
  font-size: @font-lg;
  font-weight: bold;
  &.free-pay-title {
    margin-top: 20px;
  }
}

.login-form {
  padding: 0 20px;
}
.login-desc {
  line-height: 25px;
  font-size: 16px;
}
.login-desc2 {
  line-height: 25px;
  font-size: 12px;
  color: #ff5001;
  margin-top: 8px;
  letter-spacing: 1px;
}
.get-code {
  position: relative;
  & + .get-code {
    margin-top: 20px;
  }
  /deep/input {
    box-shadow: 0 0 20px 10px #f5f5f5 inset;
  }
}
.field-input {
  padding-top: 13px;
  padding-bottom: 13px;
  background: transparent;
  &::after {
    content: none;
  }
}
.code {
  position: absolute;
  right: 16px;
  top: 12px;
  width: 80px;
  line-height: 26px;
  height: 26px;
  text-align: center;
  font-size: @font-sm;
  color: #0091ff;
  box-sizing: border-box;
  &.countDown {
    color: @text-gray-color;
  }
}
.form-submit {
  margin: 18px 0 14px;
  .van-button {
    background: #ff5001 !important;
    color: #fff !important;
    font-weight: normal;
  }
}
.error-text {
  color: @red-color;
  font-size: @font-sm;
  line-height: 20px;
  padding: 0 16px;
  margin-top: 10px;
  height: 20px;
}

.login-agreement {
  font-size: 12px;
  color: #666;
  padding-bottom: 10px;
  line-height: 1.5;

  /deep/ .login-agreement-text {
    color: #0091ff;
  }
}

.get-code {
  height: 50px;
  background: #f5f5f5;
  border-radius: 45px;
}
.center {
  text-align: center;
}
</style>