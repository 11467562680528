var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('section',{staticClass:"unlogin-home"},[_c('section',{staticClass:"home-header"},[_c('div',{staticClass:"home-header-inner"},[_c('div',{staticClass:"member-text"},[_vm._v("COCO PLUS")]),_c('div',{staticClass:"member-park"},[_vm._v(" "+_vm._s(_vm.namespaceInfo.name_cn)+" ")])])]),_c('div',{staticClass:"benefit"},[_vm._l((_vm.catesgorys),function(item){return _c('div',{key:item.id,class:("benefit-item benefit-item-" + (item.display_code))},[_c('div',{class:("benefit-item-inner benefit-item-inner-" + (item.display_code))},[_c('div',{staticClass:"benefit-item-title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"benefit-spu-list"},_vm._l((item.list),function(spu,idx){return _c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
              event_id: 'benefit_list_click',
              sequence: idx + 1,
              spu_id: spu.id,
              spu_name: spu.desc,
              namespace: _vm.namespace,
            }),expression:"{\n              event_id: 'benefit_list_click',\n              sequence: idx + 1,\n              spu_id: spu.id,\n              spu_name: spu.desc,\n              namespace,\n            }"}],key:spu.product_code,class:{
              'benefit-spu': true,
              'benefit-spu-inline': spu.is_cube,
            }},[(
                (item.display_code === 'travel' ||
                  item.display_code === 'life') &&
                spu.desc
              )?_c('div',{staticClass:"benefit-spu-title"},[_vm._v(" "+_vm._s(spu.desc)+" "),(
                  spu.generate_h5 === 'hotel' &&
                  item.display_code === 'travel'
                )?_c('div',{staticClass:"hotel-sellpoint"},[_vm._v(" "+_vm._s(spu.selling_points)+" ")]):_vm._e()]):_vm._e(),(
                spu.generate_h5 === 'hotel' && item.display_code === 'travel'
              )?_c('hotel',{attrs:{"specialHotel":_vm.specialHotel}}):_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
                event_id: 'busuness_benefit_click',
                namespace: _vm.namespace,
                spu_id: spu.id
              }),expression:"{\n                event_id: 'busuness_benefit_click',\n                namespace,\n                spu_id: spu.id\n              }"}],on:{"click":function($event){return _vm.toDetail(spu)}}},[(
                  item.display_code === 'icon'
                    ? spu.icon_img_url
                    : spu.display_img_url
                )?_c('van-image',{staticClass:"benefit-image",attrs:{"lazy-load":"","src":item.display_code === 'icon'
                    ? spu.icon_img_url
                    : spu.display_img_url}}):_vm._e(),(item.display_code === 'icon')?_c('p',{staticClass:"benefit-desc"},[_vm._v(" "+_vm._s(spu.desc)+" ")]):_vm._e(),(item.display_code === 'icon')?_c('p',{staticClass:"benefit-selling"},[_vm._v(" "+_vm._s(spu.selling_points)+" ")]):_vm._e()],1)],1)}),0)])])}),_c('div',{staticClass:"customer",on:{"click":function($event){return _vm.$router.push('/service')}}},[_c('div',{staticClass:"customer-title"},[_vm._v("如您对COCO PLUS还有疑问？")]),_c('div',{staticClass:"customer-subtitle"},[_vm._v("可以点击咨询在线客服")])])],2),_c('div',{staticClass:"fixed-height"}),_c('div',{staticClass:"fixed-footer"},[_c('van-button',{attrs:{"disabled":"","block":"","round":"","color":"#999"}},[_vm._v(" 活动已结束 ")])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }