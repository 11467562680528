import { getIndexCategory, getIndexClassList, getPackageList, getPaymethod } from "@/apis/product";
import { useStore } from "@/components/use/base";
import { ref } from "@vue/composition-api"

// 查询用户卡信息 用户权益数据 拼接前台分类配置
export default function useVisitorCategory() {
  const catesgorys = ref([]);
  const store = useStore();
  const methods = ref([]);
  const packages = ref([]);
  const currentMethod = ref({});
  const currentPackage = ref({});

  const fetchCategory = async () => {
    const catesgory = await getIndexCategory({
      namespace: store.state.namespace,
      opened_page: "visitor",
      source: store.state.source,
    });
    const res = catesgory.data
    const productList = await getIndexClassList({
      title: res.map((r) => r.name).join(","),
      opened_page: "visitor",
      namespace: store.state.namespace,
    });
    res.forEach((item, index) => {
      item.list = productList.data[item.name]
    });
    catesgorys.value = res;
  };

  const fetchPaymethod = async () => {
    const res = await getPaymethod({
      namespace: store.state.namespace,
      product_type: store.state.namespace.toUpperCase(),
      source: "default",
    });

    methods.value == res;
    currentMethod.value = res.length ? res[0] : {};
  };
  const fetchPackage = async () => {
    const res = await getPackageList({
      namespace: store.state.namespace,
      source: store.state.source,
    });
    packages.value = res.data;
    currentPackage.value = res.data.length ? res.data[0] : {};
  };
  return {
    fetchCategory,
    fetchPackage,
    fetchPaymethod,
    catesgorys,
    packages,
    currentMethod,
    currentPackage
  }
}