<template>
  <div class="ak-book" v-show="showPage">
    <div class="title">*姓名</div>
    <div class="select">
      <input type="text" placeholder="请输入姓名" v-model="name" />
    </div>
    <div class="title">*手机号</div>
    <div class="select">
      <input
        type="text"
        placeholder="请输入正确手机号"
        :disabled="userInfo.user_phone"
        v-model="phone"
      />
    </div>
    <div class="title">*预约时间</div>
    <div
      class="select calendar"
      @click="show = true"
      v-ans="{
        event_id: 'clean_teeth_click_date',
        namespace,
      }"
    >
      <div>
        {{ dateFormat }}<span>{{ day }}</span>
      </div>
    </div>
    <div class="title">*选择预约门店</div>
    <div class="select picker" @click="showCityList = true">
      {{ current_city_name }}
    </div>
    <div class="shop-list">
      <div
        :class="{ item: true, active: shop_current === index }"
        v-for="(item, index) in shop_list"
        :key="item.id"
        @click="selectShop(index)"
      >
        <div>
          <h4>{{ item.name }}</h4>
          <p>{{ item.address }}</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="btn-box">
        <div class="btn" @click="toConfirm">保存并提交</div>
      </div>
    </div>
    <van-calendar
      v-model="show"
      @confirm="onConfirm"
      :show-confirm="false"
      :show-mark="false"
      color="#ff5001"
      :formatter="formatDay"
      :min-date="minDate"
      :max-date="maxDate"
      :default-date="date"
    />
    <div class="index-city-list" v-show="showCityList">
      <van-index-bar :index-list="indexList" :sticky="false">
        <template v-for="item in cityList">
          <van-index-anchor :key="item.groupName" :index="item.groupName">{{
            item.groupName
          }}</van-index-anchor>
          <van-cell
            v-for="city in item.list"
            :key="city"
            :title="city"
            v-ans="{
              event_id: 'clean_teeth_click_city',
              destination_name: city,
            }"
            @click="setCity(city)"
          />
        </template>
      </van-index-bar>
    </div>
    <van-popup v-model="showConfirm" :round="true">
      <div class="confirm-wrap">
        <div class="title">提交后不能修改，是否继续</div>
        <div class="list">
          <div class="item">
            <span>姓名：</span>
            <p>{{ this.name }}</p>
          </div>
          <div class="item">
            <span>联系电话：</span>
            <p>{{ this.phone }}</p>
          </div>
          <div class="item">
            <span>预约时间：</span>{{ dateFormat }} {{ day }}
          </div>
          <div class="item"><span>预约城市：</span>{{ current_city_name }}</div>
          <div class="item">
            <span>预约门店：</span>
            <p>{{ current_shop }}</p>
          </div>
          <div class="item">
            <span>门店地址：</span>
            <p>{{ current_shop_address }}</p>
          </div>
        </div>
        <div class="confirm-btn-wrap">
          <div class="cancel-btn" @click="showConfirm = false">返回修改</div>
          <div
            class="confirm-btn"
            @click="postAppoint"
            v-ans="{
              event_id: 'clean_teeth_click_submit',
              namespace,
              phone_number: phone,
              appiontment_date: dateFormat + day,
            }"
          >
            确认提交
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { cleanTeethShop, submitCleanTeeth } from "@/apis";
import dayjs from "dayjs";
import { Calendar, IndexBar, IndexAnchor } from "vant";
Vue.use(Calendar);
Vue.use(IndexBar);
Vue.use(IndexAnchor);

export default {
  data() {
    return {
      showPage: false,
      name: "",
      phone: "",
      current: -1,
      current_city_name: "",
      day: "09:30-18:00",
      date: new Date(dayjs().add(3, "day")),
      show: false,
      shop_list: [],
      shop_current: 0,
      cleanTeeth: [],
      showCityList: false,
      selectCity: "",
      showConfirm: false,
    };
  },
  computed: {
    ...mapState(["namespace", "userInfo"]),
    minDate() {
      return new Date(dayjs().add(3, "day"));
    },
    maxDate() {
      return new Date(dayjs().add(63, "day"));
    },
    current_shop() {
      return this.shop_list[this.shop_current]?.name;
    },
    current_shop_address() {
      return this.shop_list[this.shop_current]?.address;
    },
    dateFormat() {
      return `${new Date(this.date).getMonth() + 1}月${new Date(
        this.date
      ).getDate()}日`;
    },
    indexList() {
      let indexList = Object.values(this.cleanTeeth).map((r) => r.first_word);
      return [...new Set(indexList)];
    },
    cityList() {
      const res = [];
      this.indexList.forEach((item) => {
        let arr = [];
        this.cleanTeeth
          .filter((r) => r.first_word === item)
          .forEach((i) => {
            !arr.includes(i.city) && arr.push(i.city);
          });
        res.push({
          groupName: item,
          list: arr,
        });
      });
      return res;
    },
  },
  mounted() {
    if (this.userInfo.user_phone) {
      this.phone = this.userInfo.user_phone;
    }
    this.init();
  },
  methods: {
    async init() {
      const toast = this.$loading();
      //获取城市及门店
      const res = await cleanTeethShop();
      //默认门店列表
      let default_city = this.selectCity ? this.selectCity : res.data[0].city;
      const city_shop_list = res.data.filter((item) => {
        if (item.city === default_city) {
          return item;
        }
      });
      this.shop_list = city_shop_list;
      this.cleanTeeth = res.data;
      this.shop_current = 0;
      this.current_city_name = this.selectCity
        ? this.selectCity
        : res.data[0].city;
      this.showPage = true;
      toast.clear();
    },
    onConfirm(value) {
      this.date = value;
      this.show = false;
    },
    formatDay(data) {
      return data;
    },
    //选择门店
    async selectShop(index) {
      this.shop_current = index;
    },
    toConfirm() {
      if (!this.name) {
        this.$toast("请填写姓名");
        return;
      }
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
        this.$toast("请填写正确的手机号");
        return;
      }
      this.showConfirm = true;
    },
    setCity(city) {
      this.selectCity = city;
      this.showCityList = false;
      this.init();
    },
    async postAppoint() {
      const { order_number } = this.$route.query;
      let opts = {
        user_id: this.userInfo.id,
        namespace_name: this.namespace,
        phone_number: this.phone,
        person_name: this.name,
        order_number,
        service_id: this.shop_list[this.shop_current].id,
        appointment_date: dayjs(this.date).format("YYYY-MM-DD"),
      };
      const toast = this.$loading();
      try {
        await submitCleanTeeth(opts);
        this.$toast("预约成功");
        setTimeout(() => {
          this.$router.replace(`/orderDetail?order_number=${order_number}`);
        }, 1000);
      } finally {
        this.showConfirm = false;
        toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ak-book {
  padding-top: 1px;
  min-height: 100vh;
  background-color: #fff;
}
.title {
  font-size: 14px;
  color: #ff5001;
  padding: 0 15px;
  margin-top: 15px;
}
.select {
  margin: 8px 15px 0;
  height: 40px;
  border: 1px solid #999;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  padding-left: 8px;
  font-weight: bold;
  line-height: 40px;
  span {
    padding-left: 10px;
  }
  input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: normal;
  }
}
.picker {
  background: url("../../assets/images/down.png") no-repeat;
  background-position: right 8px center;
}
.calendar {
  background: url("../../assets/images/calendar.png") no-repeat;
  background-position: right 8px center;
  background-size: 16px;
}
.shop-list {
  margin: 15px;
  overflow-y: scroll;
  .item {
    height: 65px;
    background-color: #f5f5f5;
    border-radius: 5px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    flex-wrap: wrap;
    h4 {
      font-size: 14px;
      color: #333;
      margin: 0;
      font-weight: normal;
    }
    p {
      font-size: 12px;
      color: #999;
      margin-top: 4px;
    }
  }
  .active {
    background-color: #fff3da;
  }
}
.btn-box {
  position: relative;
  z-index: 2;
  background: #fff;
  padding: 1px 0 14px 0;
}
.btn {
  background-color: #1a1a1a;
  margin: 15px 25px 0;
  line-height: 50px;
  height: 50px;
  text-align: center;
  border-radius: 25px;
  font-weight: bold;
  font-size: 18px;
  color: #ffefd7;
}
.tips {
  padding: 4px 15px;
  color: #ff5001;
  font-size: 10px;
}
.footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 9;
  .close {
    position: absolute;
    right: 15px;
    top: 10px;
    width: 18px;
    height: 18px;
  }
}
.date-box {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 1px;
  transition: all linear 0.3s;
  background: #fff;
  box-shadow: 0 5px 5px 5px rgba(153, 153, 153, 0.5);
  border-radius: 6px 6px 0 0;
}
.animate {
  transform: translateY(-100%);
}
.index-city-list {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 99;
  overflow-y: scroll;
}
.confirm-wrap {
  width: 295px;
  border-radius: 6px;
  background-color: #fff;
  padding-bottom: 25px;
  .title {
    text-align: center;
    font-size: 14px;
    color: #333;
    padding: 25px 0 0;
    font-weight: bold;
    margin-top: 0;
  }
  .list {
    margin-top: 5px;
    padding-top: 1px;
    .item {
      font-size: 14px;
      margin-top: 12px;
      color: #1a1a1a;
      padding-left: 14px;
      overflow: hidden;
      span {
        width: 78px;
        float: left;
        color: #999;
      }
      p {
        margin-left: 80px;
        padding-right: 10px;
      }
    }
  }
}
.confirm-btn-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 15px;
}
.cancel-btn {
  line-height: 45px;
  height: 45px;
  border: 1px solid #1a1a1a;
  border-radius: 25px;
  font-size: 16px;
  color: #1a1a1a;
  width: 125px;
  text-align: center;
}
.confirm-btn {
  width: 125px;
  background-color: #1a1a1a;
  line-height: 45px;
  height: 45px;
  text-align: center;
  border-radius: 25px;
  font-weight: bold;
  font-size: 16px;
  color: #ffefd7;
}
</style>