<template>
  <section v-show="!loading">
    <display
      page="receive"
      ref="display"
      :cardInfo="cardInfo"
      :memberPackage="memberPackage"
    >
      <template #header>
        <div class="pa-card-receive">
          {{ cardInfo.phone }}送你一张美食卡，超多权益快来领取
        </div>
      </template>
    </display>
    <div class="receive-btn">
      <van-button
        type="info"
        round
        block
        :class="{ 'receive-btn-disabled': !cardInfo.is_available }"
        @click="receive"
        v-ans="{ event_id: 'page_friend_receive' }"
        :disabled="!cardInfo.is_available"
      >
        立即免费领取
        <van-count-down
          class="count-down"
          :time="countup"
          v-if="cardInfo.is_available"
        >
          <template #default="timeData">
            {{ timeData.hours | zero }}:{{ timeData.minutes | zero }}:{{
              timeData.seconds | zero
            }}过期，请及时领取
          </template>
        </van-count-down>
      </van-button>
    </div>
    <div class="pagj-agreement">
      登录领取即视为同意
      <a href="https://cdn.xiangshuheika.com/h5/agreement/pagj.html"
        >《会员服务协议》</a
      >
    </div>
  </section>
</template>


<script>
import loginKeepDialog from "@/components/loginKeepDialog";
import display from "./components/display";
import { getCardStatus, getGiveCard } from "@/apis/home";
import dayjs from "dayjs";
import { getPackageList } from "@/apis/product";
import { mapState } from "vuex";
export default {
  components: {
    display,
  },
  data() {
    return {
      memberPackage: {},
      loading: true,
      cardInfo: {
        last_time: 0,
      },
    };
  },
  computed: {
    ...mapState(["namespace", "token"]),
    countup() {
      return dayjs(this.cardInfo.last_time).diff(dayjs(), "second") * 1000;
    },
  },
  async mounted() {
    const loading = this.$loading();
    try {
      await Promise.all([this.fetchPackage(), this.$refs.display.init()]);
    } finally {
      this.loading = false;
      loading.clear();
    }
  },
  methods: {
    async fetchPackage() {
      const { cardNumber } = this.$route.query;
      try {
        const [res, card] = await Promise.all([
          getPackageList({
            namespace: this.namespace,
            source: this.source,
          }),
          getCardStatus({ card_number: cardNumber, hideMsg: true }),
        ]);
        this.cardInfo = card.data;
        this.memberPackage = res.data.length ? res.data[0] : {};
        if (this.cardInfo.self_card) {
          this.$router.replace("/pagj/card");
        }
      } catch (e) {
        // 查询卡状态错误时，直接跳转到开通页
        this.$router.replace("/pagj/open");
      }
    },
    receive() {
      if (!this.token) {
        this.$toast("您还未登录，请先登录");
        setTimeout(() => {
          loginKeepDialog
            .show({
              agreement: `登录领取即视为同意
        <a
        style="text-align:center"
          class="login-agreement-text"
          href="https://cdn.xiangshuheika.com/h5/agreement/pagj.html"
          >《会员服务协议》</a
        >`,
            })
            .then(async () => {
              const { cardNumber } = this.$route.query;
              // 再次查询卡状态
              const res = await getCardStatus({ card_number: cardNumber });
              this.cardInfo = res.data;
              // 判断是自己的卡，直接跳转到我的卡页面
              if (this.cardInfo.self_card) {
                this.$router.replace("/pagj/card");
                return;
              }
            });
        }, 1000);
        return;
      }
      this.receiveHandle();
    },
    // 领取逻辑
    async receiveHandle() {
      const { cardNumber } = this.$route.query;
      await getGiveCard({ card_number: cardNumber });
      this.$alert({
        message: "领取成功，快去使用吧!",
        confirmButtonText: "去使用",
      }).then(() => {
        this.$router.push("/pagj/card");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.receive-btn {
  position: relative;
  margin: 30px 20px 0;
  height: 49px;
  line-height: 49px;
  border-radius: 49px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  .count-down {
    position: absolute;
    top: -10px;
    right: 0;
    border: 1px solid #ffffff;
    width: max-content;
    color: rgb(#fff, 0.8);
    line-height: 20px;
    height: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fc6f1a;
    padding: 0 5px;
  }
}

.pagj-agreement {
  margin-top: 8px;
  color: #999;
  padding-left: 24px;
  margin-bottom: 25px;
  a {
    color: #4892f0;
  }
}

.pa-card-receive {
  position: absolute;
  left: 0;
  top: 0;
  height: 26px;
  padding: 0 8px;
  line-height: 26px;
  background: rgba(#fff, 0.4);
  border-bottom-right-radius: 10px;
  color: #222;
  padding-left: 31px;
  background-size: 18px;
  background-position: 10px center;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/pa_gjopen_icon_active.png");
}
</style>