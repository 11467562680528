<template>
  <div class="model-wrap">
    <div class="content">
      <div class="rules" v-html="rules"></div>
    </div>
    <div class="icon">
      <svg-icon
        iconClass="cp-draw-modal-cancel"
        @click="$emit('update:visible', false)"
      />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  onBeforeUnmount,
} from "@vue/composition-api";

import { useStore } from "@/components/use/base";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup({ visible }) {
    const store = useStore();
    const rules = ref("");

    onMounted(() => {
      rules.value = store.getters.getConfigValue("UNXP_ACTIVITIES_RULE");
      visible && stop();
    });

    onBeforeUnmount(() => {
      move();
    });

    const stop = () => {
      document.body.style.overflow = "hidden";
    };

    const move = () => {
      document.body.style.overflow = "";
    };

    return {
      rules,
    };
  },
});
</script>

<style scoped lang='less'>
.model-wrap {
  background-color: rgba(0, 0, 0, 0.78);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  justify-content: center;
  flex-wrap: wrap;
}
.content {
  margin: 0 auto;
  margin-top: 50%;
  background: url("https://common-h5-dev.xiangshuheika.com/static/upload/202405105a34be1084faa.png")
    no-repeat top/100%;
  width: 326px;
  height: 356px;
  position: relative;

  .rules {
    padding-right: 5px;
    overflow-y: scroll;
    position: absolute;
    top: 53px;
    left: 25px;
    bottom: 31px;
    right: 20px;
    height: 272px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #f01f2f;
    line-height: 17px;
    text-align: left;
    font-style: normal;
  }
}
.icon {
  width: 100%;
  font-size: 34px;
  margin-top: 44px;
  text-align: center;
}
</style>
