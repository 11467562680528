<template>
  <main class="pagj">
    <section class="pa-header">
      <div class="pa-card-name">{{ memberPackage.card_type_cn }}</div>
      <div class="pa-card-desc">
        <span v-if="page !== 'member'">价值295元</span>
        <template v-if="page === 'member'">
          有效期至 {{ cardInfo.card_expiry_date }} 本月还剩{{
            cardInfo.current_package_remain_days
          }}天
        </template>
        <template v-if="page === 'receive'">
          有效期至 {{ cardInfo.card_last_time }}
        </template>
      </div>
      <div class="rule" @click="showRule">规则</div>
      <slot name="header" />
    </section>
    <section class="pa-body">
      <div
        v-for="(item, index) in catesgorys"
        :key="item.name"
        class="pa-category"
      >
        <div class="pa-category-name">
          <div class="pa-category-text">
            {{ item.name }}<span>{{ item.desc }}</span>
          </div>
        </div>
        <div class="pa-category-list">
          <div
            v-for="listitem in item.list"
            :key="listitem.id"
            :class="index > 0 ? 'pa-category-item' : 'one-pic'"
            @click="jump(listitem)"
          >
            <template v-if="index > 0">
              <div class="pa-category-item-inner">
                <div class="pa-category-desc">{{ listitem.desc }}</div>
                <div class="pa-category-item-flex">
                  <div class="pa-category-left">
                    <div class="pa-category-sell">
                      {{ listitem.selling_points }}
                    </div>
                    <div class="pa-category-btn">免费领取</div>
                  </div>
                  <div class="pa-category-right">
                    <van-image lazy-load :src="listitem.display_img_url" />
                  </div>
                </div>
              </div>
            </template>
            <van-image v-else lazy-load :src="listitem.display_img_url" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { getIndexCategory, getIndexClassList } from "@/apis/product";

import { mapMutations, mapState } from "vuex";
import benefitMixin from "@/mixins/benefitMixin";
import LoginDialog from "@/components/loginDialog";
import ans from "@/utils/ans";
export default {
  mixins: [benefitMixin],
  props: ["page", "cardInfo", "memberPackage"],
  data() {
    return {
      catesgorys: [],
    };
  },
  computed: {
    ...mapState(["namespace", "namespaceInfo", "source", "token", "ptoken"]),
  },
  async mounted() {},
  methods: {
    ...mapMutations(["changeState"]),
    async init() {
      const loading = this.$loading();
      try {
        const catesgory = await getIndexCategory({
          namespace: this.namespace,
          opened_page: "visitor",
          source: "default",
        });

        const catesgorys = catesgory.data.filter((r) => r.status === 1);
        const list = await getIndexClassList({
          title: catesgorys.map((r) => r.name).join(","),
          opened_page: "visitor",
          namespace: this.namespace,
        });
        catesgorys.forEach((item) => {
          const temp = list.data[item.name].filter((r) => r.status === 1);
          item.list = temp;
        });
        this.catesgorys = catesgorys;
      } finally {
        this.loading = false;
        loading.clear();
      }
    },
    jump(item) {
      if (this.page !== "member") {
        return;
      }
      if (!this.ptoken) {
        this.$toast("你还未登录，请先登录");
        setTimeout(() => {
          LoginDialog.show();
        }, 1500);
        return;
      }
      ans.track("page_member_click_benefit", { namespace: this.namespace });
      this.toDetail(item);
    },
    showRule() {
      this.$alert({
        title: "活动规则",
        message: this.namespaceInfo.rule,
        confirmButtonText: "我知道了",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pa-header {
  padding-top: 58px;
  box-sizing: border-box;
  position: relative;
  height: 128px;
  color: #fff;
  background: url("../../../assets/images/pagj_card_bg.png") no-repeat;
  background-size: 100%;
}

.pa-category-list {
  display: flex;
  flex-wrap: wrap;
}
.pa-category-item {
  width: 50%;
  padding: 0 5px;
  margin-bottom: 12px;
  box-sizing: border-box;
}

.pa-category-item-inner {
  height: 91px;
  padding: 10px 8px 8px;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffeee3;
}

.pa-category-item-flex {
  display: flex;
  align-items: flex-end;
}

.pa-category-desc {
  line-height: 18px;
  margin-bottom: 1px;
  font-size: 13px;
  color: #333;
}

.pa-category-sell {
  line-height: 17px;
  color: #666;
}
.pa-category-left {
  flex: 1;
}

.pa-category-btn {
  margin-top: 13px;
  width: max-content;
  padding: 0 7px;
  height: 24px;
  line-height: 24px;
  border-radius: 24px;
  color: #fff;
  background: #fc6f1a;
}

.pa-category-right {
  flex-shrink: 0;
  .van-image {
    width: 40px;
    height: 40px;
  }
}

.pa-category-text {
  font-weight: bold;
  margin: 0 auto;
  width: max-content;
  position: relative;
  span {
    font-weight: normal;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 12px;
    width: 33px;
    height: 2px;
    background-image: url("../../../assets/images/title_arrow.png");
    background-size: 100%;
    background-repeat: no-repeat;
  }
  &::after {
    right: -41px;
    transform: rotateY(-180deg);
  }
  &::before {
    left: -41px;
  }
}
.pa-category-name {
  height: 28px;
  line-height: 28px;
  background: linear-gradient(
    270deg,
    rgba(254, 223, 193, 0) 0%,
    rgba(#fc6f1a, 0.21) 51%,
    rgba(254, 223, 193, 0) 100%
  );
  margin-bottom: 12px;
  color: #222222;
  font-size: 16px;
  text-align: center;
}

.pa-category {
  background: #fff;
  border-radius: 8px;
  padding: 18px 12px 12px;

  & + .pa-category {
    margin-top: 20px;
  }
}

.pa-card-name {
  line-height: 33px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}
.pa-card-desc {
  color: rgba(#fff, 0.8);
  line-height: 17px;
  text-align: center;
}
.rule {
  position: absolute;
  top: 4px;
  right: 0;
  width: 40px;
  height: 21px;
  line-height: 21px;
  border-radius: 21px;
  text-align: center;
  background: #ffe9cd;
  color: #fc6f1a;
}

.one-pic {
  width: 100%;
  height: 210px;
  .van-image {
    height: 100%;
    width: auto;
  }
}
</style>