<template>
  <main class="centification" v-if="!loading">
    <van-tabs
      color="#333"
      @change="change"
      :class="{ 'centification-type': true, disabled: is_verified }"
      type="card"
      v-model="active"
    >
      <van-tab title="中国居民身份证"></van-tab>
      <van-tab v-if="!needFace" title="无中国居民身份证"></van-tab>
    </van-tabs>
    <van-form
      :disabled="is_verified"
      input-align="right"
      class="centification-form"
      @submit="onSubmit"
      @failed="onFailed"
      :show-error="false"
      :show-error-message="false"
    >
      <van-field
        v-if="active === 0"
        class="field-input"
        label="姓"
        maxlength="30"
        :placeholder="active === 0 ? '请输入中文姓' : '请输入英文姓氏'"
        v-model.trim="last_name_cn"
        :rules="[
          {
            required: true,
            message: '请输入姓',
          },
          {
            pattern: /[\u4e00-\u9fa5]{1,10}/,
            message: '姓必须是中文',
          },
        ]"
      />
      <van-field
        v-else
        class="field-input"
        label="姓"
        maxlength="30"
        :placeholder="active === 0 ? '请输入中文姓' : '请输入英文姓氏'"
        v-model.trim="last_name_cn"
        :rules="[
          {
            required: true,
            message: '请输入姓',
          },
        ]"
      />
      <van-field
        v-if="active === 0"
        class="field-input"
        label="名"
        maxlength="30"
        v-model.trim="first_name_cn"
        :placeholder="active === 0 ? '请输入中文名' : '请输入英文名字'"
        :rules="[
          { required: true, message: '请输入名' },
          {
            pattern: /[\u4e00-\u9fa5]{1,10}/,
            message: '名必须是中文',
          },
        ]"
      />
      <van-field
        v-else
        class="field-input"
        label="名"
        maxlength="30"
        v-model.trim="first_name_cn"
        :placeholder="active === 0 ? '请输入中文名' : '请输入英文名字'"
        :rules="[{ required: true, message: '请输入名' }]"
      />
      <van-field
        v-if="active === 1"
        readonly
        clickable
        name="picker"
        :value="id_number_type_text"
        label="证件类型"
        placeholder="请选择"
        @click="showPicker = true"
        :rules="[{ required: true, message: '请选择证件类型' }]"
      />

      <van-field
        v-if="active === 0"
        class="field-input"
        label="身份证"
        maxlength="18"
        v-model.trim="id_number"
        placeholder="请输入身份证号码"
        :rules="[
          { required: true, message: '请输入身份证号码' },
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: '请输入正确的居民身份证号码',
          },
        ]"
      />
      <van-field
        v-if="active === 0"
        class="field-input"
        label="手机号"
        :disabled="!!userInfo.user_phone"
        maxlength="11"
        v-model.trim="phone_number"
        placeholder="请输入手机号"
        :rules="[
          { required: true, message: '请输入手机号' },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: '请输入正确的手机号',
          },
        ]"
      />
      <van-field
        v-if="active === 1"
        class="field-input"
        label="证件"
        maxlength="30"
        v-model.trim="id_number"
        placeholder="请输入证件号码"
        :rules="[{ required: true, message: '请输入证件号码' }]"
      />
      <van-field
        v-if="active === 1"
        :disabled="!!userInfo.user_phone"
        class="field-input"
        label="手机号"
        maxlength="11"
        v-model.trim="phone_number"
        placeholder="请输入手机号"
        :rules="[
          { required: true, message: '请输入手机号' },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: '请输入正确的手机号',
          },
        ]"
      />
      <van-field style="height: 1px" readonly />
      <div v-if="!is_verified" class="centification-button">
        <van-button round block type="info" size="large" native-type="submit"
          >立即认证并保存</van-button
        >
      </div>
    </van-form>
    <div class="centification-safe2">
      点击认证，即视为同意
      <span class="blue" @click="toServer">《用户服务协议》</span>
      <span class="blue" @click="toInfo">《个人信息保护政策》</span>
    </div>
    <div class="centification-safe">
      <div class="centification-safe-text">安全保障,身份认证后不可更改</div>
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </main>
</template>

<script>
import { Tab, Tabs } from "vant";
import { realnameVerify, notIdRealname } from "@/apis/home";
import { mapActions, mapState } from "vuex";
import { activateUserEntityVerify } from "@/apis/identityVerfy";
import { verfyResult } from "@/apis/identityVerfy";
import { storage } from "@/utils";
const ColumnEnum = [
  {
    type: "MTP4TWR",
    text: "台胞证",
  },
  {
    type: "MTP4HKMRR",
    text: "港澳居民来往内地通行证",
  },
  {
    type: "PASSPORT",
    text: "护照",
  },
  {
    type: "OTHER",
    text: "其他",
  },
];
export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      loading: true,
      is_verified: false,
      active: 0,
      showPicker: false,
      id_number_type: "",
      last_name_cn: "",
      first_name_cn: "",
      id_number: "",
      phone_number: "",
      card_number: "",
      needFace: false,
    };
  },
  async mounted() {
    // if (!this.$route.query.isFaceVerfy) {
    //   this.$route.query.isFaceVerfy = false;
    // }
    // console.log(this.$route.query.return_url, this.$route.query.isFaceVerfy);
    const loading = this.$loading();
    try {
      const res = await this.fetchUserInfo();
      this.is_verified = res.is_verified;
      this.card_number = res.card_number;
      this.phone_number = res.user_phone;
      if (this.namespace == "cr") {
        const result = await verfyResult();
        const status = result.data.verify_status;
        if (status === "failed" || status === "no_verify") {
          this.needFace = true;
        }
      }
      if (res.is_verified) {
        this.$toast("您已实名认证");
        this.active = res.id_number_type === "IDCARD" ? 0 : 1;
        this.last_name_cn = res.last_name_cn;
        this.first_name_cn = res.first_name_cn;
        this.id_number = res.id_number;
        this.id_number_type = res.id_number_type;
        this.phone_number = res.phone_number;
        if (this.needFace) {
          this.$confirm({
            title: "温馨提示",
            message: "此操作可能需要人脸检测",
            cancelButtonText: "取消",
            confirmButtonText: "确认",
          })
            .then(async () => {
              const res = await activateUserEntityVerify({
                return_url: `${decodeURIComponent(
                  this.$route.query.return_url
                )}&user_token=${storage.getItem("token")}&face=1`,
              });
              var url = new URL(res.data.certify_url);
              sessionStorage.setItem("face", 1);
              window.location.href = res.data.certify_url;
            })
            .catch(() => {
              this.$router.back();
            });
        }
      }
    } finally {
      loading.clear();
      this.loading = false;
    }
  },
  computed: {
    ...mapState(["namespace", "userInfo"]),
    columns() {
      return ColumnEnum.map((r) => r.text);
    },
    id_number_type_text() {
      return ColumnEnum.find((r) => r.type === this.id_number_type)?.text;
    },
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    change() {
      this.id_number_type = "";
      this.last_name_cn = "";
      this.first_name_cn = "";
      this.id_number = "";
    },
    async onSubmit() {
      const loading = this.$loading();
      try {
        if (this.active === 0) {
          await realnameVerify({
            last_name_cn: this.last_name_cn,
            first_name_cn: this.first_name_cn,
            id_number: this.id_number,
            phone_number: this.phone_number,
          });
          loading.clear();
          if (this.needFace) {
            this.$confirm({
              title: "温馨提示",
              message: "此操作可能需要人脸检测",
              cancelButtonText: "取消",
              confirmButtonText: "确认",
            })
              .then(async () => {
                const res = await activateUserEntityVerify({
                  return_url: `${decodeURIComponent(
                    this.$route.query.return_url
                  )}&user_token=${storage.getItem("token")}&face=1`,
                });
                var url = new URL(res.data.certify_url);
                sessionStorage.setItem("face", 1);
                window.location.href = res.data.certify_url;
              })
              .catch(() => {
                this.$router.back();
              });
          } else {
            this.$toast("实名认证成功");
            await this.fetchUserInfo();
            const { return_to } = this.$route.query;
            if (return_to) {
              this.$router.push(decodeURIComponent(return_to));
            } else {
              setTimeout(() => {
                this.$router.back();
              }, 1500);
            }
          }
        } else {
          const res = await notIdRealname({
            last_name_cn: this.last_name_cn,
            first_name_cn: this.first_name_cn,
            id_number: this.id_number,
            id_number_type: this.id_number_type,
            card_number: this.card_number,
            phone_number: this.phone_number,
          });
          loading.clear();
          this.$alert({
            message: res.msg,
          });
        }
      } catch (e) {
        loading.clear();
      }
    },
    onFailed({ errors }) {
      this.$toast(errors[0].message);
    },
    onConfirm(value, index) {
      this.id_number_type = ColumnEnum[index].type;
      this.showPicker = false;
    },
    toServer() {
      window.location.href =
        "https://cdn.xiangshuheika.com/h5/agreement/kaisa.html";
    },
    toInfo() {
      window.location.href =
        "https://cdn.xiangshuheika.com/h5/agreement/info.html";
    },
  },
};
</script>

<style lang="less" scoped>
.centification {
  padding: 20px 0;
  box-sizing: border-box;
  background: #fff;
  .centification-type {
    margin-bottom: 30px;
    padding: 0 30px;
    &.disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }
  /deep/ .van-tabs__wrap,
  /deep/ .van-tabs__nav--card {
    height: 36px;
  }
  .centification-safe {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 10px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: @text-gray2-color;
    &-text {
      text-indent: 20px;
      background: url("../../assets/images/safe.png") no-repeat;
      background-size: 15px;
      background-position: left center;
    }
  }
  &-button {
    padding: 0 30px;
  }
  .centification-safe2 {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 10px;
    height: 50px;
    line-height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: @text-gray2-color;
  }
  .blue {
    color: #4892f0;
  }
}
</style>