<template>
  <div>
    <component
      :activeCard="activeCard"
      :styleType="styleType"
      v-for="item of pageInfo"
      :key="item.id"
      :is="item.display_code"
      :info="item"
      :pageType="pageType"
      :canUseBenefit="canUseBenefit"
      :cardInfo="cardInfo"
      :handleDraw="handleDraw"
      :packageInfo="packageInfo"
    />
  </div>
</template>

<script>
import TabsHeader from "./components/TabsHeader";
import Footer from "./components/Footer";
import hotel from "./components/hotel";
import info from "./components/info";
import media from "./components/media";
import travel from "./components/travel";
import food from "./components/food";
import memberCard from "./components/memberCard";
import foodFree from "./components/foodFree";
import readFree from "./components/readFree";

export default {
  components: {
    TabsHeader,
    Footer,
    hotel,
    info,
    media,
    travel,
    food,
    memberCard,
    foodFree,
    readFree,
  },

  props: [
    "pageInfo",
    "activeCard",
    "styleType",
    "pageType",
    "canUseBenefit",
    "cardInfo",
    "handleDraw",
    "packageInfo",
  ],
};
</script>

