<template>
  <div class="calendar-container">
    <div class="calendar-tips">{{ tips }}</div>
    <CalendarWeek />
    <CalendarBody v-bind="$attrs" />
  </div>
</template>

<script>
import CalendarWeek from "./CalendarWeek";
import CalendarBody from "./CalendarBody";

export default {
  inheritAttrs: false,
  props: {
    tips: String,
  },
  components: {
    CalendarWeek,
    CalendarBody,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
.calendar-week-inner {
  display: flex;
  align-items: center;
  line-height: 20px;
  font-size: 14px;
  flex-shrink: 0;
  padding-top: 5px;
}
.calendar-week-item {
  flex: 1;
  text-align: center;
  color: #1d1d26;
  &.calendar-week-tag {
    color: #ff5001;
  }
}

.calendar-day-top {
  line-height: 10px;
  height: 10px;
  font-size: 10px;
  margin-bottom: 3px;
}
.calendar-day-bottom {
  line-height: 10px;
  height: 10px;
  font-size: 10px;
  color: #666;
}
.calendar-body-month-list {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
}

.calendar-day-item-date {
  margin: 0 0 4px;
  padding: 6px 0 4px;
}

.calendar-day-date {
  line-height: 20px;
  height: 20px;
}

.calendar-day-item {
  flex: 0 0 calc(100% / 7);
  text-align: center;
  font-size: 14px;
}

.calendar-week {
  flex-basis: 35px;
  flex-shrink: 0;
  padding: 0 5px;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.category-body-inner {
  overflow: auto;
  height: 100%;
}

.category-body {
  flex: 1;
  padding: 0 5px;
  position: relative;
  overflow: hidden;
}

.stricy {
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1111;
}

.calendar-day-today {
  height: 12px;
  font-size: 10px;
}

.calendar-day-selected {
  background-color: rgb(255, 80, 1);
  border-radius: 4px;
  color: #fff;
  .calendar-day-bottom {
    color: #fff;
  }
}

.calendar-day-range {
  position: relative;
  color: #fff;
  &::after {
    content: "";
    position: absolute;
    left: -10px;
    right: -10px;
    top: 0;
    bottom: 0;
    background-color: #ffa780;
    z-index: -1;
  }
}

.calendar-day-disabled {
  pointer-events: none;
  color: #999;
}
.category-body-month {
  margin-bottom: 12px;
}
.category-body-month-title {
  text-align: center;
  font-size: 16px;
  line-height: 36px;
  height: 36px;
  color: #4a4a4a;
}

.calendar-tips {
  line-height: 17px;
  font-size: 12px;
  color: #ff5001;
  text-align: center;
  margin-bottom: 10px;
  padding: 0 15px;
}
.calendar-day-head2 {
  color: #ff5001;
}
.calendar-day-head,
.calendar-day-tail {
  display: flex;
  align-items: center;
  justify-content: center;
}
.day-off {
  background-color: #ffcab2;
  font-size: 10px;
  color: #ff5001;
  border-radius: 2px;
  padding: 2px;
  margin-left: 2px;
}
</style>