<template>
  <floor>
    <template #title>{{ name }}</template>
    <template #tip>{{ desc }}</template>
    <template #main>
      <div class="live-benefit">
        <div class="card-wrap" v-for="item of productInfo" :key="item.id">
          <div class="live-card">
            <div>
              <div class="live-name">{{ item.desc }}</div>
              <div>
                <span class="live-symbol">¥</span
                ><span class="live-price">{{
                  price(item.selling_points)[0]
                }}</span
                ><span class="live-value"
                  >价值{{ price(item.selling_points)[1] }}</span
                >
              </div>
            </div>
            <img :src="item.icon_img_url" />
          </div>
          <div
            class="use"
            @click="handleUse(item.product_code)"
            v-if="isMember || notPay"
          >
            去使用
          </div>
        </div>
      </div>
    </template>
  </floor>
</template>

<script>
import { mapState } from "vuex";

import ans from "@/utils/ans";

import floor from "./floor";

export default {
  props: ["desc", "name", "to_url", "productInfo", "requestZhongAn", "notPay"],
  components: {
    floor,
  },
  computed: {
    ...mapState(["isMember"]),
    price() {
      return function (value) {
        return value.split("｜");
      };
    },
  },
  methods: {
    handleUse(product_code) {
      if (!this.isMember) {
        ans.track("chenghua_to_use", {
          namespace: this.namespace,
        });
        this.requestZhongAn();
        return;
      }
      this.$router.push(`benefitDetail?benefit_type=${product_code}`);
    },
  },
};
</script>

<style scoped lang="less">
.live-benefit {
  display: flex;
  flex-wrap: wrap;
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .card-wrap {
    background: rgba(246, 218, 176, 1);
    padding: 4px;
    border-radius: 6px;
    &:not(:nth-child(2n + 2)) {
      margin-right: 7px;
    }
    &:not(:nth-child(-n + 2)) {
      margin-top: 8px;
    }

    .use {
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 14px;
      color: #7e5c37;
      line-height: 20px;
      margin-top: 6px;
      margin-bottom: 2px;
    }
  }
  .live-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 160px;
    height: 56px;
    font-weight: bold;
    font-family: PingFang-SC, PingFang-SC;
    background-size: 100% !important;
    background-repeat: no-repeat;
    background: url("https://common-h5-dev.xiangshuheika.com/static/upload/2024379fc2f51fc0104fc78286bcba9f62de78.png");
    > img {
      height: 36px;
      width: 36px;
      margin-right: 9px;
    }
    .live-name {
      font-size: 14px;
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      color: #27314a;
      line-height: 20px;
      margin-left: 12px;
      margin-bottom: 4px;
    }
    .live-symbol {
      margin-left: 14px;
      font-size: 10px;
      color: #455368;
      line-height: 14px;
      vertical-align: baseline;
    }
    .live-price {
      font-size: 14px;
      color: rgba(69, 83, 104, 1);
      margin-left: 2px;
      line-height: 14px;
      vertical-align: baseline;
    }
    .live-value {
      font-size: 10px;
      font-weight: 400;
      color: #455368;
      line-height: 14px;
      margin-left: 8px;
      vertical-align: middle;
    }
  }
}
</style>
