<template>
  <section class="page-member" v-if="!loading">
    <section class="page-header">
      <div class="page-header-inner">
        <div class="page-header-flex">
          <div class="page-header-text">
            {{
              userInfo.is_verified
                ? `${userInfo.last_name_cn}${userInfo.first_name_cn}`
                : "COCO PLUS"
            }}
          </div>
          <div class="page-header-exprired">
            <!-- {{ userInfo.card_expiry_date[0] }}到期 -->
          </div>
        </div>
        <div class="page-header-park">
          {{ namespaceInfo.name_cn }}
          <!-- <span class="change-park">切换</span> -->
        </div>
      </div>
    </section>
    <section class="page-benefit">
      <div
        :class="`page-benefit-item page-benefit-item-${item.display_code}`"
        v-for="item in catesgorys"
        :key="item.name"
      >
        <div class="page-benefit-item-inner">
          <div class="benefit-item-title">
            {{ item.name }}
          </div>
          <div class="page-benefit-list-wrap" @scroll="benefitScroll">
            <div class="page-benefit-list">
              <div
                class="page-benefit-spu"
                v-for="(spu, idx) in item.list"
                @click="toDetail(spu)"
                v-ans="{
                  event_id: 'benefit_list_click',
                  sequence: idx + 1,
                  spu_id: spu.id,
                  spu_name: spu.desc,
                  namespace,
                }"
                :key="spu.product_code"
              >
                <div
                  v-if="item.display_code !== 'member_member_gift'"
                  class="page-spu-inner"
                  :style="
                    item.display_code === 'member_travel'
                      ? `background-image:url(${spu.display_img_url})`
                      : ''
                  "
                >
                  <van-image
                    v-if="item.display_code !== 'member_travel'"
                    class="page-benefit-image"
                    lazy-load
                    fit="cover"
                    :src="spu.display_img_url"
                  />
                  <div class="page-benefit-name">{{ spu.desc }}</div>
                  <div
                    v-if="
                      item.display_code === 'member_travel' ||
                      item.display_code === 'member_cocopark'
                    "
                    class="page-benefit-travel-selling"
                  >
                    {{ spu.selling_points }}
                  </div>
                  <div
                    v-if="item.display_code === 'member_travel'"
                    :class="{
                      'page-benefit-use': true,
                      'page-benefit-use-amount':
                        spu.benefit && spu.benefit.amount_available > 0,
                      disabled: getDisabled(spu.benefit),
                    }"
                  >
                    <div class="page-benefit-text">
                      {{ getBenefitText(spu.benefit) }}
                      <span
                        v-if="spu.benefit && spu.benefit.amount_available > 0"
                        class="page-benefit-amount"
                      >
                        X{{
                          spu.benefit.amount_available +
                          spu.benefit.amount_reserved
                        }}
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="item.display_code === 'member_life'"
                    class="page-benefit-selling"
                  >
                    {{ spu.selling_points }}
                  </div>
                </div>
                <div v-else class="benefit-gift">
                  <van-image
                    class="benefit-gift-image"
                    :src="spu.display_img_url"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper" v-if="item.display_code === 'member_cocopark'">
            <div class="swiper-inner" :style="`left:${left}%`" />
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { getIndexCategory, getIndexClassList } from "@/apis/product";
import { mapState } from "vuex";
import { getListBenefit } from "@/apis";
import benefitMixin from "@/mixins/benefitMixin";
import ans from "@/utils/ans";

export default {
  data() {
    return {
      fileList: [],
      catesgorys: [],
      loading: false,
      left: 0,
    };
  },
  mixins: [benefitMixin],

  computed: {
    ...mapState([
      "namespace",
      "source",
      "namespaceInfo",
      "userInfo",
      "isMiniProgram",
    ]),
  },
  created() {
    ans.pageView("page_member", {
      namespace: this.namespace,
      source: this.source,
    });
    this.getProductList();
  },
  methods: {
    benefitScroll(e) {
      const scrollLeft = e.target.scrollLeft;
      const wrapWidth = e.target.clientWidth;
      const innerWidth = e.target.firstChild.clientWidth;
      this.left = ((scrollLeft / (innerWidth - wrapWidth)) * 100) / 2;
    },
    fakeJump(row) {
      if (row.product_code !== "member_gift_xingsheng01") {
        return;
      }
      this.toDetail(row);
    },
    getDisabled(benefit) {
      return (
        benefit?.amount_available === 0 ||
        benefit?.amount_available + benefit?.amount_reserved === 0
      );
    },
    getBenefitText(benefit) {
      if (!benefit) {
        return "去使用";
      }
      if (benefit.amount_available > 0) {
        return "去领取";
      } else {
        if (benefit.amount_reserved > 0) {
          return "本月已领";
        }
        return "已领完";
      }
    },
    async getProductList() {
      this.loading = true;
      const loading = this.$loading();
      try {
        const [catesgory, benefit] = await Promise.all([
          getIndexCategory({
            namespace: this.namespace,
            opened_page: "member",
            source: this.source,
          }),
          getListBenefit(),
        ]);
        const catesgorys = catesgory.data.filter((r) => r.status === 1);
        if (!catesgorys.length) {
          return;
        }
        const list = await getIndexClassList({
          title: catesgorys.map((r) => r.name).join(","),
          opened_page: "member",
          namespace: this.namespace,
        });
        catesgorys.forEach((item) => {
          item.list = list.data[item.name]
            .filter((r) => r.status === 1)
            .map((item) => ({
              ...item,
              benefit: benefit.data.find((r) => r.type === item.product_code),
            }));
        });
        this.catesgorys = catesgorys;
      } finally {
        this.loading = false;
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-benefit {
  padding-bottom: calc(12px + constant(safe-area-inset-bottom));
  padding-bottom: calc(12px + env(safe-area-inset-bottom));
}
.page-header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.page-member {
  background-color: #fff;
}
.page-header {
  height: 118px;
  box-sizing: border-box;
  background: #fef2e6;
  padding: 30px 15px 0;
}
.page-header-inner {
  height: 88px;
  padding: 10px 12px 0;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  background: url("../../../../assets/images/member_bg_card.png") no-repeat;
  background-size: 100%;
}
.page-header-text {
  line-height: 22px;
  color: #f9cdaf;
  font-size: 16px;
}
.page-header-exprired {
  color: #ffefd7;
  font-size: 10px;
}
.page-header-park {
  height: 25px;
  line-height: 25px;
  border: 1px solid #898175;
  box-sizing: border-box;
  border-radius: 25px;
  color: #898175;
  padding: 0 4px;
  display: flex;
  width: max-content;
}
.change-park {
  padding-left: 14px;
  background: url("../../../../assets/images/change_arrow.svg") no-repeat 5px
    center;
  display: block;
  background-size: 8px;
  border-radius: 25px;
  margin-left: 3px;
  padding-right: 6px;
  background-color: rgba(#898175, 0.2);
}

.page-benefit-item {
  padding: 0 15px;
}
.page-benefit-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.page-benefit-spu {
  width: 50%;
  margin-bottom: 20px;
}
.benefit-item-title {
  font-size: 16px;
  color: #222;
  margin-bottom: 10px;
  padding-top: 10px;
  font-weight: bold;
}
.page-spu-inner {
  margin: 0 5px;
}
.page-benefit-image {
  display: block;
  height: 120px;
  margin-bottom: 5px;
}
.page-benefit-name {
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}
.page-benefit-use {
  height: 28px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  text-align: center;
  background-color: #f9cdaf;
  color: #222;
  width: max-content;
  &.disabled {
    background: #dcdcdc;
    .page-benefit-text {
      color: #666;
    }
  }
}

.page-benefit-text {
  font-size: 13px;
}
.page-benefit-item-member_member_gift {
  padding: 0;
  background: linear-gradient(360deg, rgba(254, 223, 193, 0) 0%, #fedfc1 100%);
  margin-bottom: 15px;

  .benefit-item-title {
    padding-top: 13px;
    margin-bottom: 10px;
    line-height: 22px;
  }
  .page-benefit-spu {
    width: 100%;
    margin-bottom: 16px;
  }
  .benefit-gift-image {
    min-height: 50px;
  }
  .page-benefit-item-inner {
    padding: 0 15px;
    border-radius: 9px;
  }
  .page-benefit-list {
    margin: 0;
  }
}
.page-benefit-item-member_cocopark {
  .page-benefit-list-wrap {
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .page-benefit-list {
    width: 150%;
  }
  .page-benefit-spu {
    width: 86px;
  }
  .page-benefit-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
  .page-benefit-name {
    text-align: center;
    padding-top: 5px;
    margin-bottom: 0;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .page-benefit-travel-selling {
    color: #999;
    font-size: 11px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.page-benefit-item-member_travel {
  .page-benefit-spu {
    height: 216px;
    .page-spu-inner {
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 0 8px;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 10px;
      box-sizing: border-box;
      .page-benefit-name {
        color: #fff;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        margin-bottom: 1px;
      }
      .page-benefit-travel-selling {
        color: #fff;
        font-size: 11px;
        opacity: 0.7;
        margin-bottom: 5px;
      }
    }
  }
}
.page-benefit-item-member_life {
  .page-benefit-spu {
    width: 33.33333%;
    .page-spu-inner {
      border-radius: 10px;
      border: 1px solid #f9cdaf;
      overflow: hidden;
      padding-bottom: 7px;
      .page-benefit-name {
        line-height: 17px;
        padding: 5px 8px 3px;
        font-weight: normal;
        font-size: 12px;
        margin-bottom: 0;
      }
      .page-benefit-image {
        height: 82px;
      }
      .page-benefit-selling {
        background: #f9cdaf;
        border-radius: 9px;
        width: max-content;
        padding: 0 10px;
        line-height: 18px;
        margin: 0 8px;
      }
    }
  }
}
.swiper {
  width: 32px;
  height: 3px;
  background: #dcdcdc;
  border-radius: 2px;
  margin: 0 auto;
  position: relative;
  .swiper-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 3px;
    border-radius: 2px;
    background: #f9cdaf;
  }
}
</style>