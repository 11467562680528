<template>
  <div class="refund-result">
    <img class="refund-result-icon" v-if="obj.icon" :src="obj.icon" />
    <refund-status-bar waiting v-else />
    <div class="refund-result-title" v-html="obj.title"></div>
    <div
      class="refund-result-subtitle"
      v-if="obj.subtitle"
      v-html="obj.subtitle"
    ></div>
    <div class="refund-result-desc">{{ obj.desc }}</div>
    <div class="refund-result-btn1" @click="handler1">{{ obj.btn1.text }}</div>
    <div class="refund-result-btn2" v-if="obj.btn2" @click="handler2">
      {{ obj.btn2.text }}
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";
import { getrefundList } from "../../apis/refund";
import refundStatusBar from "@/components/refundStatusBar";
export default {
  name: "refundResult",
  data() {
    return {
      obj: {
        icon: "",
        title: "",
        desc: "",
        btn1: "",
        btn2: null,
      },
    };
  },
  components: {
    refundStatusBar,
  },
  computed: {
    ...mapState(["return_url", "path", "namespace"]),
  },
  async mounted() {
    const { status, msg } = this.$route.query;
    if (status === "benefit_success") {
      this.obj = {
        icon: require("../../assets/images/success.png"),
        title:
          this.namespace === "xy"
            ? "领取成功，请在我的-省钱卡中使用"
            : "权益领取成功，可在会员首页领取权益",
        desc: "",
        btn1: { text: "返回首页", type: "toIndex" },
        btn2: null,
      };
    } else if (status === "benefit_failed") {
      this.obj = {
        icon: require("../../assets/images/failed.png"),
        title: "权益领取失败，请尝试重新领取",
        desc: msg,
        btn1: { text: "重新领取", type: "back" },
        btn2: { text: "返回首页", type: "toIndex" },
      };
    } else if (status === "refund_failed") {
      this.obj = {
        icon: require("../../assets/images/failed.png"),
        title: "您的申请提交失败，请尝试重新提交",
        desc: "",
        btn1: { text: "重新提交", type: "back" },
        btn2: { text: "返回首页", type: "toIndex" },
      };
    } else {
      const { order_number } = this.$route.query;
      const res = await getrefundList({ audit_status: "waiting" });
      let local = [];
      if (res.data.data_list.length) {
        local = res.data.data_list.filter(
          (r) => r.order_number === order_number
        );
      }
      if (local.length) {
        this.obj = {
          title: `您的退款申请已提交`,
          subtitle: `预计${this.$getConfigValue("REFUND_DAY")}天
         ${dayjs(local[0].t_created)
           .add(Number(this.$getConfigValue("REFUND_DAY")), "day")
           .format("YYYY-MM-DD HH:mm:ss")}前完成审核退款到账`,
          desc: "审核通过后会退回至您的付款帐户，具体到账时间以银行的实际到账时间为准，请留意短信通知",
          btn1: { text: "返回首页", type: "toIndex" },
          btn2: null,
        };
      } else {
        this.$router.go(-1);
      }
    }
  },
  methods: {
    handler1() {
      if (this.obj.btn1.type === "toIndex" && this.namespace === "xy") {
        location.href =
          sessionStorage.getItem("return_url") + sessionStorage.getItem("path");
        return;
      }
      if (this.obj.btn1.type === "toIndex" && this.return_url) {
        this.$jumpUrl(this.path?.replace("#", ""));
      }
      if (this.obj.btn1.type === "back") {
        this.$router.go(-1);
      }
    },
    handler2() {
      if (this.obj.btn1.type === "toIndex" && this.namespace === "xy") {
        location.href =
          sessionStorage.getItem("return_url") + sessionStorage.getItem("path");
        return;
      }
      if (this.obj.btn2.type === "toIndex" && this.return_url) {
        this.$jumpUrl(this.path?.replace("#", ""));
      }
    },
  },
};
</script>

<style lang='less' scoped>
.refund-result {
  padding: 0 15px;
  background: #fff;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.refund-result-icon {
  width: 70px;
  height: 70px;
  display: block;
  margin: 60px auto 0;
}
.refund-result-title {
  font-size: 20px;
  line-height: 28px;
  color: #333;
  text-align: center;
  margin-top: 40px;
  color: #333;
}
.refund-result-subtitle {
  font-size: 14px;
  line-height: 20px;
  max-width: 60%;
  margin: 0 auto;
  color: #666;
  text-align: center;
}
.refund-result-desc {
  font-size: 14px;
  color: #999;
  margin-top: 68px;
  line-height: 20px;
  text-align: center;
}
.refund-result-btn1 {
  width: 314px;
  height: 49px;
  background: linear-gradient(90deg, #ffc360 0%, #ffeba6 100%);
  border-radius: 25px;
  line-height: 49px;
  text-align: center;
  font-size: 18px;
  color: #853119;
  font-weight: bold;
  margin: 30px auto 0;
}
.refund-result-btn2 {
  box-sizing: border-box;
  width: 314px;
  height: 49px;
  border-radius: 25px;
  line-height: 49px;
  text-align: center;
  font-size: 18px;
  color: #999;
  margin: 20px auto 0;
  border: 1px solid #999;
}
</style>
