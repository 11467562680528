<template>
  <floor>
    <template #title>{{ title }}</template>
    <template #subTitle>｜{{ subTitle }}</template>
    <template #tip>{{ desc }}</template>
    <template #main>
      <div class="travel-benefit">
        <div
          :class="`travel-card-${index}`"
          v-for="(item, index) of travelArr"
          :key="item.name"
        >
          <img :src="item.img" />
          <p>
            <span class="travel-symbol">¥</span
            ><span class="travel-price">6</span>购
          </p>
          <div class="travel-name">{{ item.name }}</div>
        </div>
      </div>
      <toUseBtn :product_code="productInfo[0].product_code" :notPay="notPay" />
    </template>
  </floor>
</template>

<script>
import floor from "./floor";
import toUseBtn from "./toUseBtn";

export default {
  props: ["desc", "name", "to_url", "productInfo", "notPay"],

  components: {
    floor,
    toUseBtn,
  },

  data() {
    return {
      travelArr: [],
    };
  },
  computed: {
    title() {
      return this.name.split("｜")[0];
    },
    subTitle() {
      return this.name.split("｜")[1];
    },
  },
  mounted() {
    const TRAVEL_ARR =
      JSON.parse(this.$getConfigValue("ZA_CARD_CONGIG")).TRAVEL_ARR || [];
    this.travelArr = [...TRAVEL_ARR];
  },
};
</script>

<style scoped lang="less">
.travel-benefit {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  > div {
    width: 109px;
    height: 139px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    line-height: 17px;

    &:not(:last-child) {
      margin-right: 8px;
    }
    > img {
      width: 100px;
      height: 68px;
      margin: 5px;
    }
    > p {
      color: #455368;
      text-align: center;
      margin-top: 12px;
      .travel-symbol {
        color: rgba(39, 49, 74, 1);
        margin-right: 1px;
      }
      .travel-price {
        font-size: 24px;
        margin-right: 2px;
      }
    }
    .travel-name {
      color: #455368;
      text-align: center;
      margin-top: 2px;
    }
  }
  .travel-card-0 {
    background: url("https://common-h5-dev.xiangshuheika.com/static/upload/202437046e95904df54a4eb1049b129a99e2f4.png");
  }
  .travel-card-1 {
    background: url("https://common-h5-dev.xiangshuheika.com/static/upload/202437ba0a7ddd4f3f408eb245800f8302d5ee.png");
  }
  .travel-card-2 {
    background: url("https://common-h5-dev.xiangshuheika.com/static/upload/202437253b645b9ecd426195670bdd306f11ff.png");
  }
}
</style>
