<template>
  <div class="category-body-month">
    <div class="unstricy">
      <div class="category-body-month-title">{{ month }}</div>
    </div>
    <div class="calendar-body-month-list">
      <div class="calendar-day-item" v-for="item in list" :key="item.value">
        <div
          v-if="item.type === 0"
          @click="selectDate(item)"
          :class="{
            'calendar-day-item-date': true,
            'calendar-day-disabled': !isAvailableDate(item._value),
            'calendar-day-range':
              selectRangeDate.length === 2 &&
              item._value.isBetween(selectRangeDate[0], selectRangeDate[1]),
            'calendar-day-selected':
              item._value.isSame(selectRangeDate[0], 'day') ||
              (selectRangeDate[1] &&
                item._value.isSame(selectRangeDate[1], 'day')),
          }"
        >
          <div class="calendar-day-top">
            {{ item.isToday ? "今天" : "" }}
            <div
              v-if="dayjs(selectRangeDate[0]).isSame(item._value)"
              class="calendar-day-head"
            >
              <span v-if="type === 'hotel'">入住</span>
              <span v-if="item.isHoliday" class="day-off">休</span>
            </div>
            <div
              v-if="dayjs(selectRangeDate[1]).isSame(item._value)"
              class="calendar-day-tail"
            >
              <span v-if="type === 'hotel'">离店</span>
              <span v-if="item.isHoliday" class="day-off">休</span>
            </div>
            <div
              v-if="
                !dayjs(selectRangeDate[0]).isSame(item._value) &&
                !dayjs(selectRangeDate[1]).isSame(item._value)
              "
              :class="`calendar-day-head ${
                item.isHoliday ? 'calendar-day-head2' : ''
              }`"
            >
              {{ item.holiday_name
              }}<span v-if="item.isHoliday" class="day-off">休</span>
            </div>
          </div>
          <div class="calendar-day-date">{{ item.text }}</div>
          <div class="calendar-day-bottom">
            <span v-if="priceInfo && priceInfo[item._value.format('MM-DD')]"
              >¥{{ priceInfo[item._value.format("MM-DD")] }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { Toast } from "vant";

export default {
  props: [
    "month",
    "selectRangeDate",
    "list",
    "getPriceInfo",
    "priceInfo",
    "type",
    "minDate",
    "maxDate",
    "single",
  ],
  data() {
    return {
      dayjs,
    };
  },
  inject: ["resolve"],
  async mounted() {},
  methods: {
    isAvailableDate(date) {
      let min = true;
      let max = true;
      if (this.minDate) {
        min = dayjs(date).isSameOrAfter(dayjs(this.minDate), "day");
      }
      if (this.maxDate) {
        max = dayjs(date).isSameOrBefore(dayjs(this.maxDate), "day");
      }
      return min && max;
    },
    selectDate(item) {
      let res = [...this.selectRangeDate];
      if (this.single) {
        res[0] = item._value;
      } else {
        if (res.length === 0) {
          res.push(item._value);
        } else if (res.length === 1) {
          if (res[0].isSameOrAfter(item._value)) {
            res[0] = item._value;
          } else {
            res.push(item._value);
          }
        } else if (res.length === 2) {
          res = [item._value];
        }
      }
      this.$emit("update:selectRangeDate", res);

      if (this.type === "hotel") {
        if (res.length === 1) {
          this.$toast("请选择离店日期");
          this.getPriceInfo(res[0]);
        }
        if (res.length === 2) {
          if (!res[0].add(1, "day").isSame(res[1])) {
            Toast.clear();
            this.$toast("每次限预定一晚");
            return;
          }
        }
      }

      if (this.single || (!this.single && res.length === 2)) {
        this.resolve(res);
      }
      this.$emit("update:selectRangeDate", res);
    },
  },
};
</script>