<template>
  <section
    :class="[
      'benefit-detail',
      `benefit-${benefitInfo.type}`,
      benefitHeaderImage ? 'new-benefit-style' : '',
    ]"
    v-if="!loading"
  >
    <!-- <van-empty v-if="showEmpty" description="SKU不存在"></van-empty> -->
    <template>
      <benefit-header
        :benefitHeaderImage="benefitHeaderImage"
        :benefit_type="benefit_type"
        :benefitInfo="benefitInfo"
      />
      <benefit-body
        :benefit_type="benefit_type"
        :benefitInfo="benefitInfo"
        :benefitHeaderImage="benefitHeaderImage"
        :skuInfo="skuInfo"
        :selectSku="selectSku"
        :images="images"
        :refresh="refresh"
        :updateSelectSku="(e) => (selectSku = e)"
        :rechargeAccounts="rechargeAccounts"
      />

      <benefit-introduction :benefitInfo="benefitInfo" :selectSku="selectSku" />

      <div
        v-if="!$getConfigValue('HIDE_BENEFIT_ORDER_ENTRY')"
        class="to-order-btn"
        v-ans="{
          event_id: 'benefit_detail_click_button2',
          spu_id: benefitInfo.id,
          spu_name: benefitInfo.name,
          namespace,
          source,
        }"
        @click="$router.push('/order')"
      ></div>
    </template>
  </section>
</template>

<script>
import { getCardInfo } from "@/apis/home";
import { mapActions, mapState } from "vuex";

import {
  getBenefitInfo,
  getSkuByBenefitType,
  getNamespaceBenefitImage,
} from "@/apis";
import ans from "@/utils/ans";
import yioil from "./yioil";
import { queryUserRechargeAccount } from "@/apis/product";
import BenefitBody from "./benefitBody";
import BenefitHeader from "./benefitHeader";
import BenefitIntroduction from "./benefitIntroduction";

export default {
  components: {
    BenefitHeader,
    BenefitBody,
    BenefitIntroduction,
  },
  mixins: [yioil],
  data() {
    return {
      loading: true,
      benefitInfo: {},
      skuInfo: [],
      skus: [],
      selectSku: {
        allowed_recharge_account_types: [],
      },
      images: {},
      rechargeAccounts: [],
      benefit_type: this.$route.query.benefit_type,
    };
  },
  computed: {
    ...mapState([
      "namespace",
      "source",
      "userInfo",
      "isMiniProgram",
      "isWebchat",
      "isAndroid",
      "isiOS",
      "namespaceInfo",
      "benefit_source",
    ]),

    // showEmpty() {
    //   if (/^airporttransfer|atour$/.test(this.benefitInfo.type)) {
    //     return false;
    //   }
    //   return this.skuInfo.length === 0;
    // },

    // 权益头图
    benefitHeaderImage() {
      return this.images.header?.[0].image_url;
    },

    user_phone() {
      if (this.selectSku.recharge_account_number !== 1) {
        return "";
      }
      return (
        this.rechargeAccounts.find(
          (item) =>
            item.account_type_number === this.selectSku.recharge_account_number
        )?.account || ""
      );
    },
  },

  async mounted() {
    const { benefit_type, detail_type } = this.$route.query;
    const temp = {
      starbucks: "sbux",
      mangguo: "mgtv",
      aiqiyi: "iqiyi",
    };
    const skuType = temp[detail_type] || detail_type;
    const toast = this.$loading();
    try {
      await this.fetchUserInfo();
      const [sku, benefit, imageRes] = await Promise.all([
        getSkuByBenefitType({
          benefit_type,
          source: this.source,
          namespace: this.namespace,
        }),
        this.fetchBenefitInfo({ benefit_type }),
        getNamespaceBenefitImage({
          namespace_en: this.namespace,
          product_code: benefit_type,
        }),
        this.fetchRechargeAccount(),
      ]);
      this.images = imageRes.data;
      // 过滤掉话费权益里面包含京东eka的sku
      this.skus = sku.data;
      // todo mock数据
      sku.data.forEach((item, idx) => {
        if (
          item.allowed_recharge_account_types &&
          item.allowed_recharge_account_types.length > 0
        ) {
          item.recharge_account_number =
            item.allowed_recharge_account_types[0].account_type_number;
        }
      });
      if (sku.data.some((item) => item.attributes?.is_phone === "1")) {
        const filterItemCodes =
          this.$getConfigValue("JD_ECARD_ITEMCODE").split("|");
        this.skuInfo = sku.data.filter(
          (item) => !filterItemCodes.includes(item.item_code)
        );
      } else {
        this.skuInfo = sku.data;
        //埋堆堆没有季卡则不显示优酷sku
        if (this.namespace.startsWith("mdd")) {
          const mdd_card_info = await getCardInfo();
          if (!mdd_card_info.data) {
            this.skuInfo = sku.data.filter(
              (r) => r.item_code !== "combo-sy-youku"
            );
          }
        }
      }

      if (this.skuInfo.length) {
        this.selectSku =
          this.skuInfo.find((r) => r.item_code.includes(skuType)) ||
          this.skuInfo[0];
      }
      // 酒店不显示sku
      if (
        benefit_type === "hotel" ||
        benefit_type === "ehotel" ||
        benefit_type === "buffet"
      ) {
        this.skuInfo = [{}];
      }

      this.benefitInfo = benefit.data;
      ans.pageView(this.$route.meta.page, {
        namespace: this.namespace,
        spu_id: this.benefitInfo.id,
        spu_name: this.benefitInfo.name,
        source: this.source,
        benefit_type: this.benefitInfo.type,
      });
    } finally {
      toast.clear();
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    async fetchRechargeAccount() {
      const res = await queryUserRechargeAccount();
      this.rechargeAccounts = res.data;
    },
    async refresh() {
      const { benefit_type } = this.$route.query;
      await Promise.all([
        this.fetchBenefitInfo({ benefit_type }),
        this.fetchRechargeAccount(),
      ]);
    },
    async fetchBenefitInfo(params) {
      try {
        const res = await getBenefitInfo(params);
        this.benefitInfo = res.data;
        return res;
      } catch (e) {
        return { data: {} };
      }
    },
  },
};
</script>

<style lang="less">
.benefit-header {
  height: 223px;
}
.benefit-header-img {
  height: 100%;
}

.benefit-info {
  border-radius: 8px;
  overflow: hidden;
  margin: -73px 12px 0;
  position: relative;
  z-index: 1;
}

.benefit-btn {
  font-weight: bold;
  border: none;
  .btn-primary-style();
}

.benefit-btn-wrap {
  margin-top: 14px;
}
.benefit-desc {
  margin: 15px 12px;
  border-radius: 8px;
  padding: 18px 16px;
  background: #fff;
  position: relative;
}
.benefit-desc-label {
  font-size: 13px;
  color: #333;
  line-height: 24px;
  margin-bottom: 10px;
}
.instruction-text {
  color: #666;
  line-height: 1.5;
}

.benefit-name {
  height: 70px;
  padding: 12px 10px 0 22px;
  box-sizing: border-box;
  background: url("../../assets/images/be-bg.gif") no-repeat center;
  position: relative;
  display: flex;
  margin-bottom: 6px;
}
.benefit-title {
  line-height: 20px;
  color: #6c3e0c;
  font-size: 14px;
  margin-bottom: 2px;
  position: relative;
  z-index: 10;
}
.benefit-amount {
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  height: 18px;
  background: linear-gradient(90deg, #feedd7 0%, #ffffff 100%);
  border-radius: 0px 9px 0px 6px;
  line-height: 18px;
  font-size: 11px;
  padding: 0 9px;
  color: #844f16;
}
.benefit-icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin-right: 11px;
}

.benefit-subtitle {
  line-height: 18px;
  color: #6c3e0c;
  font-size: 12px;
  opacity: 0.8;
  .font-family(PingFangSC-Light);
}

.benefit-sku {
  padding: 0 22px;
  border: 1px solid transparent;
}
.benefit-info-inner {
  background: #fff;
  border-radius: 10px;
  padding-bottom: 20px;
  overflow: hidden;
}
.benefit-info-title {
  font-size: 26px;
  color: #fff;
  line-height: 37px;
  padding-left: 22px;
  margin-bottom: 17px;
  // .font-family(PingFangSC-Ultralight);
}
.benefit-sku-input {
  display: flex;
  align-items: center;
  height: 41px;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 11px 13px;
  box-sizing: border-box;
  margin-top: 12px;
  font-size: 13px;
  position: relative;
  .van-field__control {
    font-size: 13px;
  }
}

.benefit-input-label {
  flex-shrink: 0;
  padding: 0;
  display: flex;
  align-items: center;
  line-height: 20px;
  .benefit-input-label-arrow {
    font-size: 20px;
  }
}

.benefit-input {
  flex: 1;
  padding: 0;
  font-size: 15px;
  background: transparent;
  position: relative;
  overflow: visible;
  &::after {
    content: none !important;
  }
}

.benefit-sku-list {
  margin-top: 12px;
  .van-row {
    margin: -5px;
  }
  .van-col--12 {
    .sku-item-inner-image {
      width: auto;
      height: auto;
    }
  }
}
.sku-item-inner {
  height: 44px;
  box-sizing: border-box;
  border: 0.5px solid #d2d2d2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  &.new-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    &::after {
      content: none !important;
    }
    .sku-item-inner-image {
      width: 26px;
      overflow: hidden;
      border-radius: 50%;
      height: 26px;
      margin: 0 auto 3px;
      flex-shrink: 0;
    }
    .sku-item-name {
      font-size: 10px;
      text-align: center;
      padding: 0 1px;
      line-height: 16px;
      width: 100%;
      box-sizing: border-box;
      font-size: 11px;
      color: #333;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.sku-item-inner-image {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.sku-item {
  padding: 5px;
  &.sku-flex {
    .sku-item-inner {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0 8px;
      .sku-item-inner-image {
        margin: 0 8px 0 0;
        width: 24px;
        height: 24px;
      }
      .sku-item-name {
        width: auto;
        text-align: left;
      }
    }
  }
}
.sku-item.active {
  .sku-item-inner {
    border-color: #f6d9b8;
    box-sizing: border-box;
    background: #fff7ed;
    .out-stock {
      background-color: #f6d9b8;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
      background: url("../../assets/images/atour-selected.png") no-repeat;
      background-size: 100%;
    }
  }
}

.rich-text {
  color: #777;
  line-height: 22px;
  font-size: 11px;
  img {
    max-width: 100%;
  }
  // &.rich-text-more {
  //   max-height: 300px;
  //   overflow: hidden;
  // }
  .instruction-html {
    margin-top: 24px;
  }
}
.more {
  margin: -24px auto 0;
  width: 80px;
  height: 24px;
  line-height: 24px;
  background: #ffc360;
  border-radius: 24px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 100;
}

.to-order-btn {
  position: fixed;
  width: 36px;
  height: 40px;
  right: 0;
  top: 60%;
  z-index: 19;
  background: url("../../assets/images/benefit_order_bg.png") no-repeat
    center/100%;
}

.isp {
  position: absolute;
  right: 10px;
  top: 14px;
  color: #999999;
  font-size: 14px;
}

.to-share {
  position: absolute;
  width: 45px;
  height: 40px;
  right: 0;
  top: 24px;
  background: url("../../assets/images/share.png") no-repeat;
  background-size: 100%;
  z-index: 19;
}

.out-stock {
  font-size: 10px;
  position: absolute;
  right: -3px;
  top: -3px;
  background: #ccc;
  color: #fff;
  line-height: 12px;
  padding: 0 4px;
  border-radius: 5px 0px 5px 0px;
  z-index: 100;
}

.save-money {
  position: absolute;
  left: 10px;
  top: 15px;
  text-indent: 20px;
  background-color: rgba(255, 144, 0, 0.5);
  background-image: url("../../assets/images/logo.png");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 16px;
  height: 26px;
  line-height: 26px;
  border-radius: 13px;
  padding: 0 12px 0 10px;
  font-size: 14px;
  color: #fff;
  z-index: 9;
}

.benefit-kaidivip_quarter {
  .benefit-amount {
    display: none;
  }
}

.new-benefit-style {
  .benefit-info {
    margin-top: -263px;
  }
  .benefit-header {
    height: 370px;
  }
}
.height0-enter-active,
.height0-leave-active {
  transition: all 0.5s;
  overflow: hidden;
}
.height0-enter,
.height0-leave-to {
  height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
}
</style>