<template>
  <van-popup
    closeable
    position="bottom"
    round
    @click-overlay="close"
    @click-close-icon="close"
    :value="visible"
    class="coupon-container"
    style="height: 80%"
  >
    <div class="popup-title">
      优惠券{{ type === "select" ? `（${coupons.length}张）` : "" }}
    </div>
    <div class="popup-content">
      <div class="coupon-contain">
        <template v-if="coupons.length">
          <div
            class="coupon-item"
            v-for="item in coupons"
            @click="selectCoupon(item.coupon_id)"
            :key="item.batch_id || item.coupon_id"
          >
            <div class="coupon-item-type">
              {{ batch_type_enum[item.batch_type] }}
            </div>
            <div class="coupon-value">
              <template v-if="item.batch_type === 'money_off'">
                <small>¥</small>{{ item.face_value }}
              </template>
              <template v-else-if="item.batch_type === 'discount'">
                {{ item.discount_rate | decimal(10, "times") }}<small>折</small>
              </template>
            </div>
            <div class="coupon-info">
              <div class="coupon-limit">
                {{ getLimitText(item) }}
              </div>
              <div class="coupon-end" v-if="namespaceGroup !== 'oa'">
                有效期至{{ item.end_time | formatDate("YYYY.MM.DD HH:mm") }}
              </div>
            </div>
            <div
              v-if="type === 'select'"
              :class="{
                'coupon-action coupon-select': true,
                'coupon-selected': item.coupon_id === coupon_id,
              }"
            ></div>
            <div
              v-else
              class="coupon-action coupon-receive"
              @click="reciveHandle(item)"
            >
              领取
            </div>
          </div>
        </template>
        <van-empty v-else description="暂无可用优惠券" />
      </div>
      <van-button
        v-if="type !== 'get'"
        round
        class="btn"
        block
        @click="submit"
        >{{ type === "get" ? "关闭" : "完成" }}</van-button
      >
    </div>
  </van-popup>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    coupons: Array,
    type: String,
    visible: Boolean,
    recive: Function,
    coupon_id: String,
    canSelectCoupon: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      batch_type_enum: {
        money_off: "满减",
        free: "免单",
        discount: "折扣",
      },
    };
  },
  computed: {
    ...mapGetters(["namespaceGroup"]),
  },
  methods: {
    async reciveHandle(item) {
      this.recive(item);
    },
    close() {
      this.$emit("update:visible", false);
    },
    submit() {
      this.close();
    },
    selectCoupon(coupon_id) {
      if (this.type !== "select") {
        return;
      }
      if (!this.canSelectCoupon) {
        this.$toast("每天使用1张券，您今天已用过请明天再来");
        return;
      }
      this.$emit(
        "update:coupon_id",
        this.coupon_id === coupon_id ? "" : coupon_id
      );
    },
    getLimitText(item) {
      if (item.batch_type === "money_off") {
        return `满${item.min_limit}元可用`;
      } else if (item.batch_type === "free") {
        return `最多可抵扣${item.face_value}元`;
      } else {
        `满${item.min_limit}元可用，最多可抵扣${item.face_value}元`;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.popup-title {
  line-height: 50px;
  margin: 0 15px;
  border-bottom: 1px solid #dcdcdc;
  font-size: 16px;
  color: #222;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
  flex-basis: 50px;
  flex-shrink: 0;
}
.popup-content {
  padding: 0 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 66px);
}

.coupon-contain {
  flex: 1;
  overflow-y: auto;
}

.btn {
  margin: 10px 0;
  background: linear-gradient(90deg, #ff650d 0%, #ff5f4a 100%);
  color: #fff;
  flex-shrink: 0;
}

.coupon-container {
  display: flex;
  flex-direction: column;
  max-height: 70%;
}
.coupon-item {
  height: 87px;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 10px;
  background: #ffede5;
  border-radius: 8px;
  color: #ff5001;
  overflow: hidden;
  margin-bottom: 10px;
}
.coupon-item-type {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px;
  font-size: 10px;
  background: rgba(#ff0026, 0.1);
  border-radius: 0px 0px 8px 0px;
  height: 15px;
  line-height: 15px;
}
.coupon-value {
  font-size: 24px;
  font-weight: bold;
  flex: 1;
  text-align: center;
  small {
    font-size: 14px;
  }
}
.coupon-receive {
  width: 55px;
  text-align: center;
  line-height: 21px;
  border-radius: 21px;
  background: #ff5001;
  color: #fff;
}
.coupon-info {
  flex: 2;
}
.coupon-action {
  margin-left: auto;
}

.coupon-select {
  width: 17px;
  height: 17px;
  border-radius: 17px;
  border: 1px solid #ff5001;
  box-sizing: border-box;
}

.coupon-limit {
  line-height: 22px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
}

.coupon-end {
  line-height: 17px;
}
.coupon-select-icon {
  color: #ff5001;
}

.coupon-selected {
  background: url("../../assets/images/hotel/select_coupon.svg") no-repeat;
  background-size: 17px;
  border: none;
}
</style>