<template>
  <div class="wrap">
    <div
      class="wrap-tips"
      id="cwxp-tips"
      :class="`${animationStyle} ${iosStyle}`"
    >
      <div>向右滑返回</div>
    </div>
    <div class="member-wrap" :class="iosWrapStyle" v-if="!fetching">
      <div class="header">
        <div class="title-wrap">
          <span>联合会员</span>
          <div class="surplus">剩{{ remainCount }}次</div>
        </div>
        <div class="desc">
          <span v-if="userInfo.user_phone"
            >权益领取账号：{{ userInfo.user_phone }}</span
          >
        </div>
        <img
          src="@/assets/images/xpcw-member-header.png"
          class="header-picture"
        />
        <div class="action-wrap">
          <div class="time">有效期至 {{ expiryDate }}</div>
          <div class="action">
            <span @click="handleToCustomer">客服</span>|<span>
              <span @click="handleToOrder"
                >领取记录<svg-icon iconClass="arrow2" class="icon"
              /></span>
            </span>
          </div>
        </div>
      </div>
      <div class="main-wrap">
        <div
          class="main"
          @click="handleDraw"
          v-ans="{
            event_id: 'benefit_list_click',
          }"
        >
          <div class="main-title">VIP尊享权益 每月可领取1次</div>
          <div class="main-content">
            <img src="@/assets/images/xpcw-benefits.png" />
            <div
              class="draw"
              :style="computedPosition"
              :class="!computedPosition && 'invisible'"
            >
              最近领取
            </div>
          </div>
          <div class="sup-amount">
            <div class="amount-desc">合计</div>
            <div class="sup-num">
              总价值<span class="sub">¥</span
              ><span class="num">{{ packagesInfo.face_value }}</span>
            </div>
          </div>
        </div>
        <div
          class="btn"
          :class="benefit.amount_available ? 'resizing' : 'disable'"
          @click="handleDraw"
          v-ans="{
            event_id: 'benefit_list_click',
          }"
        >
          {{ canDraw }}
        </div>
        <div class="draw-time">
          本月可用<span>{{ benefit.amount_available }}</span
          >次，距下月使用还有<span>{{ benefit.next_use_wait_days }}</span
          >天
        </div>
      </div>
      <div class="draw-desc">
        <div class="tip">温馨贴士</div>
        <div v-html="namespaceInfo.rule"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  onUnmounted,
} from "@vue/composition-api";
import Dayjs from "dayjs";
import Vue from "vue";
import pickBy from "lodash.pickby";

import useMemberBenefitCategory from "@/use/useMemberBenefitCategory";
import useVisitorCategory from "@/use/useVisitorCategory";
import { useStore, useRouter } from "@/components/use/base";
import { getBenefitInfo, getOrder } from "@/apis";

const vm = Vue.prototype;

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();

    const benefit = ref({});
    const fetching = ref(true);
    const packagesInfo = ref({});
    const orderList = ref([]);
    const position = ref({});
    const isTipVisible = ref(true);
    const isScroll = ref(false);
    const timer = ref(null);

    const isIos = store.state.isiOS;

    const animationStyle = computed(() => {
      if (!isTipVisible.value && isScroll.value) return "start";
      if (isTipVisible.value && !isScroll.value) return "end";
      return "";
    });

    const { card, catesgorys, getProductList } = useMemberBenefitCategory();
    const { fetchPackage, packages } = useVisitorCategory();

    const userInfo = store.state.userInfo;
    const namespaceInfo = store.state.namespaceInfo;
    console.log(userInfo);

    const expiryDate = computed(() => {
      return Dayjs(benefit.value.activate_expire_time).format("YYYY.MM.DD");
    });

    const canDraw = computed(() => {
      return benefit.value.amount_available ? "立即领取" : "本月已领取";
    });

    const remainCount = computed(() => {
      return (
        benefit.value.amount_reserved + benefit.value.amount_available || 0
      );
    });

    const computedPosition = computed(() => {
      return position.value[orderList.value[0]?.name] || false;
    });

    const getOrderList = async () => {
      try {
        const res = await getOrder();
        orderList.value = res.data.res_list.filter(
          (item) => item.category_type === "benefit"
        );
      } catch (e) {
        console.log(e);
      }
    };

    const handleStart = () => {
      timer.value && clearTimeout(timer.value);
      timer.value = setTimeout(() => {
        handleStop();
      }, 1000);
      isScroll.value = true;
      isTipVisible.value = false;
    };
    const handleStop = () => {
      isTipVisible.value = true;
      isScroll.value = false;
      clearTimeout(timer.value);
    };

    onUnmounted(() => {
      document.removeEventListener("touchmove", handleStart);
    });

    const iosStyle = computed(() => {
      if (isIos) return "ios-style";
      return "";
    });

    onMounted(async () => {
      const loading = vm.$loading();
      document.addEventListener("touchmove", handleStart);
      fetching.value = true;
      try {
        getOrderList();
        await getProductList();
        await fetchPackage();

        const type = catesgorys.value[0].list[0].product_code;
        const card_type = card.value.card_type;

        position.value = JSON.parse(
          store.getters.getConfigValue("CWXP_MEMBER_POSTION")
        );

        packagesInfo.value = packages.value.filter(
          (item) => item.item_code === card_type
        )[0];
        const { data } = await getBenefitInfo({ benefit_type: type });
        benefit.value = data;
      } finally {
        fetching.value = false;
        loading.clear();
      }
    });

    const handleToCustomer = () => {
      router.push("/service");
    };

    const handleToOrder = () => {
      router.push("./order");
    };

    const handleDraw = () => {
      const query = pickBy({
        benefit_type: benefit.value.type,
      });
      router.push({
        path: "/benefitDetail",
        query,
      });
    };

    return {
      card,
      userInfo,
      expiryDate,
      remainCount,
      benefit,
      packagesInfo,
      canDraw,
      fetching,
      computedPosition,
      namespaceInfo,
      animationStyle,
      iosStyle,
      handleToCustomer,
      handleToOrder,
      handleDraw,
    };
  },
});
</script>

<style scoped lang="less">
.member-wrap {
  padding: 25px 12px 24px 12px;
  background: #ffbf7c;
  min-height: 100vh;
  box-sizing: border-box;
  font-family: PingFangSC-Semibold, PingFang SC;
}

.header {
  background: linear-gradient(113deg, #fefdf9 0%, #fff4e2 69%, #fff6e6 100%);
  box-sizing: border-box;
  border-radius: 14px;
  height: 151px;
  padding: 15px 8px 9px 8px;
  position: relative;
  .title-wrap {
    display: flex;
    align-items: center;
    margin-left: 14px;
    font-size: 19px;
    font-weight: 600;
    color: #bb7017;
    line-height: 27px;
    > .surplus {
      margin-left: 5px;
      padding: 1px 8px 1px 8px;
      background: linear-gradient(115deg, #dfbd94 0%, #ffe8c8 100%);
      border-radius: 11px;
      font-size: 11px;
      color: #a96718;
      line-height: 21px;
    }
  }
  .desc {
    min-height: 16px;
    margin: 2px 0 0 14px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a96718;
    line-height: 16px;
  }
  .header-picture {
    width: 105px;
    height: 117px;
    position: absolute;
    right: 9px;
    bottom: 59px;
  }
  .action-wrap {
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 11px;
    font-weight: 400;
    color: #a96718;
    line-height: 16px;
    background: rgba(234, 209, 153, 0.3);
    border-radius: 11px;
    padding: 11px 16px 12px 14px;
    margin-top: 43px;
    .action {
      > span:first-child {
        margin-right: 7px;
      }
      > span:last-child {
        margin-left: 7px;
      }
      .icon {
        margin-left: 3px;
        font-size: 10px;
      }
    }
  }
}
.main-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 12px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 17px;
  border: 1px solid #ffffff;
  padding: 8px 8px 11px 8px;
  .draw-time {
    margin-top: 9px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 17px;
    > span {
      color: #ff5900;
    }
  }
}
.main {
  width: 100%;
  background: #ffffff;
  border-radius: 14px;
  box-sizing: border-box;
  padding: 0 24px 0 24px;
  .main-title {
    margin-top: 13px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
    text-align: center;
  }

  .main-content {
    margin: 0 -24px 0 -24px;
    position: relative;
    > img {
      width: 100%;
    }
    > .draw {
      padding: 2px 8px;
      background: linear-gradient(180deg, #fff35e 0%, #ffe230 100%);
      border-radius: 8px 8px 8px 0px;
      position: absolute;

      font-size: 10px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .sup-amount {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: self-end;
    padding-bottom: 9px;
    .amount-desc {
      font-size: 11px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    .sup-num {
      font-size: 11px;
      font-weight: 400;
      color: #ff5001;
      line-height: 22px;

      .sub {
        margin: 0 2px;
        vertical-align: middle;
      }
      .num {
        font-size: 22px;
        font-weight: 600;
      }
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      margin-bottom: 9px;
      background-image: linear-gradient(
        to right,
        #eaeaea 0,
        #eaeaea 50%,
        transparent 50%
      );
      background-size: 9px 1px;
      background-repeat: y-repeat;
    }
  }
}
.btn {
  margin-top: 11px;
  width: 280px;
  height: 53px;
  background: linear-gradient(360deg, #ff341b 0%, #ff9c5e 100%);
  border-radius: 27px;
  font-size: 27px;
  font-weight: 500;
  color: #ffffff;
  line-height: 53px;
  text-align: center;
  &.disable {
    background: #d9d9d9;
    color: #ffffff;
  }
}

@keyframes btn-resizing {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.95);
  }
}

.invisible {
  display: none;
}
.draw-desc {
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  margin-top: 10px;
  padding: 29px 8px 12px 8px;
  overflow: hidden;
  word-break: break-all;

  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  .tip {
    background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
    border-radius: 0px 0px 8px 0px;
    padding: 2px 7px;
    position: absolute;
    left: 0;
    top: 0;

    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 17px;
  }
}
.wrap {
  position: relative;
  .return-header {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    height: 43px;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #202437;
    line-height: 24px;
    position: fixed;
    width: 100%;
    text-align: center;
    top: 0;
    z-index: 99;

    > img {
      left: 10px;
      position: absolute;
      height: 25px;
      width: 25px;
    }
  }
}

.wrap {
  position: relative;
  .wrap-tips {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 64px;
    height: 21px;
    background: #f96d26;
    border-radius: 4px;
    border: 1px solid #f96d26;
    top: 7px;
    left: 10px;
    z-index: 9;

    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    ::before {
      display: block;
      content: "";
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-right: 8px solid #f96d26;
      position: absolute;
      left: -12px;
    }
    &.start {
      left: -30px;
    }
  }
}
.start {
  animation: start linear 0.5s;
  @keyframes start {
    0% {
      left: 10px;
    }
    100% {
      left: -30px;
    }
  }
}
.end {
  animation: end linear 0.5s;
  @keyframes end {
    0% {
      left: -30px;
    }
    100% {
      left: 10px;
    }
  }
}

.ios-style {
  top: 70px !important;
}
</style>
