<template>
  <section v-show="!loading">
    <display
      ref="display"
      page="member"
      :cardInfo="cardInfo"
      :memberPackage="memberPackage"
    />
    <div class="footer">
      <router-link
        v-ans="{ event_id: 'page_member_click_order' }"
        class="footer-link"
        to="/order"
        >我的订单</router-link
      >
      <router-link
        v-ans="{ event_id: 'page_member_click_service' }"
        class="footer-link"
        to="/service"
        >咨询客服</router-link
      >
    </div>
  </section>
</template>

<script>
import { getUserBenefit } from "@/apis/home";
import display from "./components/display";
import { getPackageList } from "@/apis/product";
import { mapState } from "vuex";
export default {
  components: {
    display,
  },
  data() {
    return {
      cardInfo: {},
      loading: true,
      memberPackage: {},
    };
  },
  computed: {
    ...mapState(["namespace"]),
  },
  async mounted() {
    const loading = this.$loading();
    try {
      const [res] = await Promise.all([
        getUserBenefit(),
        this.fetchPackage(),
        this.$refs.display.init(),
      ]);
      this.cardInfo = res.data;
    } finally {
      this.loading = false;
      loading.clear();
    }
  },
  methods: {
    async fetchPackage() {
      const [res] = await Promise.all([
        getPackageList({
          namespace: this.namespace,
          source: this.source,
        }),
      ]);
      this.memberPackage = res.data.length ? res.data[0] : {};
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  margin: 18px 60px;
}
.footer-link {
  flex: 1;
  display: block;
  font-size: 14px;
  text-align: center;
  color: #4892f0;
}
</style>