<template>
  <div class="food-wrap">
    <div class="head">
      <div class="title-wrap">
        <div class="title">餐饮美食5折购</div>
        <div class="tip">6选1</div>
      </div>
      <div class="desc">{{ info.desc }}</div>
    </div>
    <img :src="info.display_img_url" @click="draw" />
  </div>
</template>

<script>
export default {
  props: ["info", "canUseBenefit", "handleDraw"],
  methods: {
    draw() {
      if (!this.canUseBenefit) return;
      this.handleDraw(this.info.productInfo[0]);
    },
  },
};
</script>

<style scoped lang="less">
.food-wrap {
  padding: 9px 13px 22px 13px;
  width: auto;
  box-sizing: border-box;
  background: #fff;
  border-radius: 16px;
  margin-top: 12.5px;

  img {
    margin-top: 16px;
    width: 100%;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 11px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #afb2b5;
  .title-wrap {
    display: flex;
    align-items: flex-end;
    .title {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
    }
    .tip {
      margin-left: 3px;
    }
  }
}
</style>
