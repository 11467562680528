<template>
  <index-main v-if="isMember" />
  <un-login v-else />
</template>

<script>
import UnLogin from "./unlogin";
import IndexMain from "./main";
import { mapState } from "vuex";

export default {
  name: "entry",
  components: {
    UnLogin,
    IndexMain,
  },
  async created() {},

  computed: {
    ...mapState(["isMember", "isMiniProgram"]),
  },
};
</script>
