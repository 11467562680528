var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category-body-month"},[_c('div',{staticClass:"unstricy"},[_c('div',{staticClass:"category-body-month-title"},[_vm._v(_vm._s(_vm.month))])]),_c('div',{staticClass:"calendar-body-month-list"},_vm._l((_vm.list),function(item){return _c('div',{key:item.value,staticClass:"calendar-day-item"},[(item.type === 0)?_c('div',{class:{
          'calendar-day-item-date': true,
          'calendar-day-disabled': !_vm.isAvailableDate(item._value),
          'calendar-day-range':
            _vm.selectRangeDate.length === 2 &&
            item._value.isBetween(_vm.selectRangeDate[0], _vm.selectRangeDate[1]),
          'calendar-day-selected':
            item._value.isSame(_vm.selectRangeDate[0], 'day') ||
            (_vm.selectRangeDate[1] &&
              item._value.isSame(_vm.selectRangeDate[1], 'day')),
        },on:{"click":function($event){return _vm.selectDate(item)}}},[_c('div',{staticClass:"calendar-day-top"},[_vm._v(" "+_vm._s(item.isToday ? "今天" : "")+" "),(_vm.dayjs(_vm.selectRangeDate[0]).isSame(item._value))?_c('div',{staticClass:"calendar-day-head"},[(_vm.type === 'hotel')?_c('span',[_vm._v("入住")]):_vm._e(),(item.isHoliday)?_c('span',{staticClass:"day-off"},[_vm._v("休")]):_vm._e()]):_vm._e(),(_vm.dayjs(_vm.selectRangeDate[1]).isSame(item._value))?_c('div',{staticClass:"calendar-day-tail"},[(_vm.type === 'hotel')?_c('span',[_vm._v("离店")]):_vm._e(),(item.isHoliday)?_c('span',{staticClass:"day-off"},[_vm._v("休")]):_vm._e()]):_vm._e(),(
              !_vm.dayjs(_vm.selectRangeDate[0]).isSame(item._value) &&
              !_vm.dayjs(_vm.selectRangeDate[1]).isSame(item._value)
            )?_c('div',{class:("calendar-day-head " + (item.isHoliday ? 'calendar-day-head2' : ''))},[_vm._v(" "+_vm._s(item.holiday_name)),(item.isHoliday)?_c('span',{staticClass:"day-off"},[_vm._v("休")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"calendar-day-date"},[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"calendar-day-bottom"},[(_vm.priceInfo && _vm.priceInfo[item._value.format('MM-DD')])?_c('span',[_vm._v("¥"+_vm._s(_vm.priceInfo[item._value.format("MM-DD")]))]):_vm._e()])]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }