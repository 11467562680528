<template>
  <main class="cancel-hotel">
    <div class="reasons">
      <div
        :class="`reason-item${check === item.key ? ' checked' : ''}`"
        v-for="item in reasons"
        :key="item.key"
        @click="check = item.key"
      >
        <div class="item-check"></div>
        <div>{{ item.text }}</div>
      </div>
    </div>
    <div class="bottom">
      <div class="cancel-tips">
        取消成功后，已支付费用将于3-5个工作日原路返回
      </div>
      <div
        class="confirm-button"
        @click="onConfirm"
        v-ans="{
          event_id: 'hotel_order_cancel_button',
          namespace,
          order_number: $route.query.order_number,
        }"
      >
        确认取消
      </div>
    </div>
  </main>
</template>

<script>
import { getCurrentTime } from "@/apis/hotel";
import { getOrder, updateOrder } from "@/apis";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      hotel: {},
      check: "",
      reasons: [
        {
          key: 0,
          text: "订错酒店",
        },
        {
          key: 1,
          text: "订错日期",
        },
        {
          key: 2,
          text: "行程有变",
        },
        {
          key: 3,
          text: "其它APP更便宜",
        },
        {
          key: 4,
          text: "酒店质量差",
        },
        {
          key: 5,
          text: "其它原因",
        },
      ],
    };
  },
  computed: {
    ...mapState(["namespace"]),
  },
  async mounted() {
    const { order_number } = this.$route.query;
    const res = await getOrder({ order_number });
    if (!res.data.res_list.length) {
      return;
    }
    this.hotel = JSON.parse(res.data.res_list[0].pay_info);
  },
  methods: {
    async isBefore16() {
      try {
        const res = await getCurrentTime();
        const { current_time } = res.data;
        const checkinDate = this.hotel.checkin_date;
        const beforeOneDay = new Date(
          Number(checkinDate) - 24 * 60 * 60 * 1000
        );
        const hour16 = beforeOneDay.setHours(16, 0, 0, 0); // 设置时间为16点0时0分0秒
        if (current_time < hour16) {
          return true;
        }
      } catch (e) {
        return false;
      }
      return false;
    },
    async onConfirm() {
      if (this.check === "") {
        this.$toast("请先选择取消原因哦~");
        return;
      }
      const loading = this.$loading();
      try {
        const isBefore16 = await this.isBefore16();
        if (isBefore16) {
          // 调用取消订单接口
          // todo
          const { order_number, status } = this.$route.query;
          await updateOrder({
            order_number,
            status: "canceled",
            extra_params: JSON.stringify({
              canceled_reason: this.reasons[this.check].text,
            }),
          });
          this.$alert({
            message:
              status === 'unpaid' ? '已成功取消本酒店订单' : "已成功取消本酒店订单，请耐心等待退款。退款将于3至5个工作日原路返回",
            hideClose: true,
          }).then(() => {
            this.$router.back();
          });
        } else {
          this.$alert({
            message: "抱歉，此订单已超过取消时间或条件，不可取消",
          });
        }
      } finally {
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cancel-hotel {
  padding: 7px 15px 0;
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;
}

.reason-item {
  display: flex;
  align-items: center;
  line-height: 41px;
  font-size: 15px;
  color: #333;
}

.item-check {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid #999;
  margin-right: 10px;
}

.reason-item.checked .item-check {
  background-image: url("../../assets/images/hotel/checked@2x.png");
  background-size: 100%;
  background-repeat: no-repeat;
  border: none;
}

.bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.cancel-tips {
  color: #999;
  font-size: 12px;
  line-height: 45px;
}

.confirm-button {
  height: 50px;
  font-size: 18px;
  color: #fff;
  line-height: 50px;
  background: linear-gradient(90deg, #ff650d 0%, #ff5f4a 100%);
}

.dialog-content {
  margin: 40px 0;
  padding: 0 20px;
}
</style>