
export default [
  {
    path: '/afterMarket/select',
    name: 'afterMarket',
    component: require('@/views/afterMarket/select').default,
    meta: {
      title: '选择售后类型',
      pageName: '选择售后类型',
      page: 'page_afterMarket_select',
    }
  },
  {
    path: '/afterMarket/form',
    name: 'afterMarketform',
    component: require('@/views/afterMarket/form').default,
    meta: {
      title: '售后信息输入',
      pageName: '售后信息输入',
      page: 'page_afterMarket_form',
    }
  }, {
    path: '/afterMarket/result',
    name: 'afterMarketResult',
    component: require('@/views/afterMarket/result').default,
    meta: {
      title: '售后结果',
      pageName: '售后结果',
      page: 'page_afterMarket_result',
    }
  }, {
    path: '/afterMarket/record',
    name: 'afterMarketRecord',
    component: require('@/views/afterMarket/record').default,
    meta: {
      title: '处理进度查询',
      pageName: '处理进度查询',
      page: 'page_afterMarket_record',
    }
  },
]