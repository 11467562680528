import { Dialog } from "vant";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    resolve: {
      type: Function,
    },
    showConfirmButton: {
      type: Boolean,
      default: true,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    content: {

    },
    title: {

    },
    close: {
      type: Function,
    },
    cancelButtonText: {
      type: String,
      default: "取消"
    },
    confirmButtonText: {
      type: String,
      default: "确认"
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    $route(a, b) {
      if (a.path !== b.path && this.$props.value) {
        this.$props.close();
      }
    }
  },
  mounted() {
  },
  methods: {
    confirm() {
      this.$props.close();
      this.$props.resolve(true);
    },
    cancel() {
      this.$props.close();
      this.$props.resolve(false);
    }
  },
  render() {
    const { title, content, value, showCancelButton, showConfirmButton, cancelButtonText, confirmButtonText } = this.$props;
    return <Dialog.Component
      title={title}
      class="common-jsx-dialog van-dialog--confirm common-dialog-confirm"
      vOn:confirm={this.confirm}
      vOn:cancel={this.cancel}
      value={value}
      cancelButtonText={cancelButtonText}
      confirmButtonText={confirmButtonText}
      showConfirmButton={showConfirmButton}
      showCancelButton={showCancelButton}>
      {content}
    </Dialog.Component>
  }
};
