<template>
  <section class="business-park">
    <div class="park-location" v-if="current.namespace">
      <div class="park-location-title">离我最近商圈</div>
      <div class="park-list">
        <div
          @click="changePark(current)"
          :class="{
            'park-list-item': true,
            'park-list-item-active': current.namespace === namespace,
          }"
          v-ans="{
            event_id: 'business_park_click',
            namespace,
            park_name: current.title,
          }"
        >
          <div class="park-col">
            <div>
              <div class="park-name">{{ current.title }}</div>
              <div class="park-address">
                {{ current.address }}
              </div>
            </div>
            <div class="park-distance">
              {{ current.distance }}{{ current.unit }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="park-location" v-if="list.length">
      <div class="park-location-title">更多商圈</div>
      <div class="park-list">
        <div
          :class="{
            'park-list-item': true,
            'park-list-item-active': item.namespace === namespace,
          }"
          @click="changePark(item)"
          v-for="item in list"
          :key="item.namespace"
          v-ans="{
            event_id: 'business_park_click',
            namespace,
            park_name: item.title,
          }"
        >
          <div class="park-col">
            <div class="park-name">{{ item.title }}</div>
            <div class="park-distance">{{ item.distance }}{{ item.unit }}</div>
          </div>
          <div class="park-address">
            {{ item.address }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getParkList } from "@/apis";
import { mapActions, mapMutations, mapState } from "vuex";
import { storage } from "@/utils";

export default {
  data() {
    return {
      location: {},
      list: [],
      longitude: 0,
      latitude: 0,
      current: {},
      loading: false,
    };
  },
  mounted() {
    this.getLocation();
  },
  computed: {
    ...mapState(["namespace"]),
  },
  methods: {
    ...mapMutations(["changeState"]),
    ...mapActions(["fetchUserInfo"]),
    async changePark(item) {
      if (item.namespace !== this.namespace) {
        this.changeState({ namespace: item.namespace });
        storage.setItem("token", item.namespace);
        await this.fetchUserInfo();
      }
      this.$router.push("/business/home");
    },
    // 获取定位
    async getLocation() {
      this.loading = this.$loading();
      var geolocation = new window.BMap.Geolocation();
      if (geolocation) {
        geolocation.enableSDKLocation();
        geolocation.getCurrentPosition((position) => {
          this.latitude = position.latitude; //纬度
          this.longitude = position.longitude; //经度
          this.fetchParks();
        });
      } else {
        this.fetchParks();
      }
    },
    async fetchParks() {
      try {
        const res = await getParkList({
          location_license: this.longitude ? "1" : "0",
          longitude: this.longitude,
          latitude: this.latitude,
          namespace: this.namespace,
          namespace_group: "xinsheng",
        });
        const { token_list } = res.data;
        this.current = token_list.shift();
        this.list = token_list;
      } finally {
        this.loading && this.loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.business-park {
  background: #fff;
  min-height: 100vh;
  padding: 15px;
  box-sizing: border-box;
}
.park-location {
  & + .park-location {
    margin-top: 15px;
  }
  .park-list-item {
    line-height: 18px;
    color: #333;
    margin-bottom: 5px;
    &.park-list-item-active {
      background: #ffefd7;
    }
  }
}
.park-location-title {
  line-height: 18px;
  margin-bottom: 5px;
}

.park-distance {
  flex-shrink: 0;
  margin-left: 20px;
}

.park-list-item {
  background: #fafafa;
  border-radius: 5px;
  padding: 12px 15px;
  .park-col {
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    margin-bottom: 4px;
  }
  .park-name {
    font-weight: bold;
    font-size: 14px;
  }
  .park-address {
    background: url("../../../assets/images/location.svg") no-repeat left 3px;
    background-size: 12px;
    line-height: 18px;
    padding-left: 14px;
  }
}
</style>