<template>
  <van-popup
    :value="show"
    @close="close"
    @click-overlay="close"
    @click-close-icon="close"
    position="bottom"
    round
    :zIndex="zIndex"
    closeable
    :class="['oak-popup']"
    get-container="body"
  >
    <div v-if="title" class="oak-popup-title">{{ title }}</div>
    <div :class="['oak-popup-content']">
      <slot></slot>
    </div>
  </van-popup>
</template>

<script>
export default {
  model: {
    prop: "show",
    event: "update:show",
  },
  props: {
    title: String,
    zIndex: [Number, String],
    show: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.change(false);
    },
    change(bool) {
      this.$emit("update:show", bool);
    },
  },
};
</script>

<style lang="less" scoped>
.oak-popup {
  display: flex;
  flex-direction: column;
}
.oak-popup-title {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #1e1e1e;
}
.oak-popup-content {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
}
</style>
