<template>
  <div class="yzf" v-if="pageshow">
    <div class='header'>
      <img class='img' src='https://cdn.xiangshuheika.com/static/yzf/bg.png' />
      <div class='title'>
        {{ $getConfigValue("INDEX_TITLE") }}
      </div>
      <div class='desc'>本月剩余{{ user_benefit.current_package_remain_days }}天，有效期至{{ user_benefit.card_expiry_date.replace(
        /-/g,
        "."
      ) }}</div>
    </div>
    <div class='benefit-title'><span class='text'>您的尊享生活权益</span></div>
    <div class='benefit-main'>
      <div v-for="i in components">
          <div class='benefit-container benefit-music' v-if="i.display_code === 'benefit_music'">
            <div class='title'>
              <div class='des'>每月领</div>
              <div>{{ i.name }}</div>
            </div>
            <div class='box' 
              v-for="item in i.list" 
              :style="{ backgroundColor: bgColor[item.product_code] || '#FEECED' }" 
              @click="$router.push(`/benefitDetail?benefit_type=${item.product_code}`)"
            >
              <img class='img' :src='item.icon_img_url' />
              <div class='text'>{{ item.desc }} x{{ item.benefit.amount_available + item.benefit.amount_reserved }}</div>
              <div class='btn'>免费领</div>
            </div>
          </div>
          <div 
            class='benefit-container' 
            v-if="['benefit_movie', 'benefit_bike', 'benefit_viproom'].includes(i.display_code)"
          >
            <div class='title'>
              <div class='des'>{{ i.display_code === 'benefit_viproom' ? '限1次' : '每月领' }}</div>
              <div>{{ i.name }}</div>
            </div>
            <div class='con-box'>
              <div 
                class='item' 
                v-for="item in i.list" 
                :style="{ backgroundColor: bgColor[item.product_code] || '#FEECED' }" 
                @click="$router.push(`/benefitDetail?benefit_type=${item.product_code}`)"
              >
                <img class='img' :src='item.icon_img_url' />
                <div class='text'>{{ item.desc }}</div>
                <div class='btn'>去使用</div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class='za-footer'>
      <div class='item' @click="$router.push('/order')">
        <img class='img' src='https://cdn.xiangshuheika.com/static/yzf/icon-order.png' />
        我的订单
      </div>
      <div class='item' @click="$router.push('/service')">
        <img class='img' src='https://cdn.xiangshuheika.com/static/yzf/icon-serve.png' />
        在线客服
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  onMounted,
  defineComponent,
  ref,
} from "@vue/composition-api";
import {
  getIndexCategory,
  getIndexClassList,
} from "@/apis/product";
import { getUserBenefit } from "@/apis/home";
import { getListBenefit } from "@/apis";
import store from "@/store";
const vm = Vue.prototype;

export default defineComponent({
  setup() {
    const pageshow = ref(false);
    const components = ref([]);
    const user_benefit = ref({});
    const bgColor = ref({});

    onMounted(async () => {
      const load = vm.$loading();
      bgColor.value = JSON.parse(vm.$getConfigValue("ZA_BENEFIT_BG_COLOR"));
      const res = await getUserBenefit();
      user_benefit.value = res.data;

      let opts = {
        namespace: store.state.namespace,
        opened_page: 'member',
        source: 'default'
      }
      const catesgory = await getIndexCategory(opts);
      const catesgorys = catesgory.data.filter((r) => r.status === 1);
      if (!catesgorys.length) {
        return;
      }
      const benefit = await getListBenefit();
      const list = await getIndexClassList({
        title: catesgorys.map((r) => r.name).join(","),
        opened_page: 'member',
        namespace: store.state.namespace,
      });
      catesgorys.forEach((item) => {
        item.list = list.data[item.name]
          .filter((r) => r.status === 1)
          .map((item) => ({
            ...item,
            benefit: benefit.data.find((r) => r.type === item.product_code),
          }));
      });
      components.value = catesgorys;
      load.clear();
      pageshow.value = true;
    })

    return {
      pageshow,
      components,
      user_benefit,
      bgColor,
    }
  }
})

</script>

<style lang='less' scoped>
.yzf {
  background-color: #FFF7E8;
  padding-bottom: 40px;
}
.header {
  position: relative;
  .img {
    width: 100%;
  }
  .title {
    font-size: 26px;
    color: #fff;
    position: absolute;
    left: 17px;
    top: 33px;
  }
  .desc {
    position: absolute;
    left: 17px;
    top: 73px;
    color: #fff;
    font-size: 12px;
  }
}

.benefit-title {
  text-align: center;
  margin-top: 18px;
  .text {
    position: relative;
    font-size: 18px;
    color: #FF4748;
    font-weight: bold;
    &::before {
      position: absolute;
      content: "";
      width: 60px;
      height: 2px;
      background: linear-gradient(90deg, rgba(255, 204, 116, 0) 0%, #FF4748 100%);
      left: -73px;
      top: 50%;
      }
    &::after {
      position: absolute;
      content: "";
      width: 60px;
      height: 2px;
      background: linear-gradient(90deg, rgba(255, 204, 116, 0) 0%, #FF4748 100%);
      left: calc(100% + 8px);
      top: 50%;
      transform: rotate(180deg);
    }
  }
}

.benefit-main {
  margin: 16px 12px 0;
  .benefit-container {
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 0 8px 10px;
    .title {
      padding: 10px 0;
      display: flex;
      font-size: 15px;
      color: #222;
      align-items: center;
      transform: translateX(-12px);
      font-weight: bold;
      .des{
        background: linear-gradient(270deg, #FFC360 0%, #FF4748 100%);
        border-radius: 0px 0px 6px 0px;
        height: 18px;
        line-height: 18px;
        padding: 0 5px;
        font-size: 12px;
        color: #fff;
        margin-right: 5px;
        font-weight: normal;
      }
    }
    .con-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        box-sizing: border-box;
        width: 49%;
        border-radius: 8px;
        padding: 10px 10px 8px;
        text-align: center;
        .img {
          width: 48px;
          height: 48px;
          display: block;
          margin: 0 auto;
        }
        .text {
          font-weight: bold;
          color: #333;
          font-size: 13px;
          margin-top: 5px;
        }
        .btn {
          background-color: #FF4748;
          width: 72px;
          height: 24px;
          border-radius: 24px;
          text-align: center;
          line-height: 24px;
          color: #fff;
          font-size: 12px;
          margin: 8px auto 0;
        }
      }
      .more {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        color: #999;
        height: 100%;
      }
    }
  }
}
.benefit-music {
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
    .img {
      width: 114px;
      height: 48px;
    }
    .text {
      font-weight: bold;
      color: #333;
      font-size: 14px;
    }
    .btn {
      background-color: #FF4748;
      width: 72px;
      height: 24px;
      border-radius: 24px;
      text-align: center;
      line-height: 24px;
      color: #fff;
      font-size: 12px;
    }
  }

}

.za-footer {
  background-color: #fff;
  border-radius: 8px;
  height: 54px;
  display: flex;
  align-items: center;
  margin: 0 12px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 24px;
    background-color: #F5F5F5;
    left: 50%;
    top: 16px;
  }
  .item {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #333;
    .img {
      width: 22px;
      height: 22px;
      margin-right: 6px;
    }
  }
}
</style>
