<template>
  <section class="kd100" v-if="!loading">
    <div style="display: none">
      <section class="header">
        <div class="rule-btn" @click="showRule">规则</div>
      </section>
      <section class="benefit">
        <div class="page-benefits">
          <div
            :key="item.display_code"
            v-for="(item, index) in catesgorys"
            :class="`page-benefit page-benefit-${item.display_code}`"
          >
            <div class="page-benefit-item">
              <div class="page-benefit-tag">权益 {{ index + 1 }}</div>
              <div class="page-benefit-name">{{ item.name }}</div>
              <div class="page-benefit-desc">{{ item.desc }}</div>
            </div>
            <div
              v-for="spu in item.list"
              :key="spu.id"
              class="page-benefit-spu"
            >
              <van-image lazy-load fit="cover" :src="spu.display_img_url" />
            </div>
          </div>
        </div>
      </section>
      <div class="h200" />

      <section class="footer">
        <div class="footer-btn-wrap">
          <div
            class="footer-btn"
            @click="toBuy"
            v-ans="{ event_id: 'visitor_click_buy' }"
          >
            <div class="price">
              <small>¥</small>{{ currentPackage.price
              }}<small>/{{ currentPackage.unit }}</small>
            </div>
            <div class="face-value">
              ¥{{ currentPackage.face_value }}/{{ currentPackage.unit }}
            </div>
            <div class="sellpoint">开通包回本</div>
            <div class="guang1"></div>
            <div class="guang2"></div>
          </div>
          <div class="count-dwon">
            秒杀中：
            <van-count-down :time="countup">
              <template #default="timeData">
                <span class="block">{{ timeData.hours | zero }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes | zero }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds | zero }}</span>
              </template>
            </van-count-down>
          </div>
        </div>
        <div class="agreement">
          购买则视为同意<a class="link" href="../agreement/xs_oak.html"
            >《联合VIP会员权益条款》</a
          >
        </div>
      </section>
    </div>
    <section class="popup-footer">
      <div class="title">开通会员</div>
      <van-image
        class="popup-footer-img"
        :src="require('../../../assets/images/kd100_visitor_desc.png')"
      />
      <div class="agreement">
        <van-checkbox
          an-checkbox
          shape="round"
          checked-color="#00B25A"
          icon-size="16px"
          name="box"
          v-model="checked"
          >购买则视为同意</van-checkbox
        >
        <a class="link" href="../agreement/kd100.html">《VIP会员权益条款》</a
        >和<a href="../agreement/xs_oak.html">《橡树生活权益服务协议》</a>
      </div>
      <div class="footer-btn-wrap">
        <div
          class="footer-btn"
          @click="toBuy"
          v-ans="{ event_id: 'visitor_click_buy' }"
        >
          <div class="price">
            <small>¥</small>{{ currentPackage.price
            }}<small>/{{ currentPackage.unit }}</small>
          </div>
          <div class="face-value">
            ¥{{ currentPackage.face_value }}/{{ currentPackage.unit }}
          </div>
          <div class="sellpoint">开通包回本</div>
          <div class="guang1"></div>
          <div class="guang2"></div>
        </div>
        <div class="count-dwon">
          秒杀中：
          <van-count-down :time="countup">
            <template #default="timeData">
              <span class="block">{{ timeData.hours | zero }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.minutes | zero }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds | zero }}</span>
            </template>
          </van-count-down>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { useStore } from "@/components/use/base";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import dayjs from "dayjs";
import Vue from "vue";
import { toPayV2 } from "@/helper/pay";
import useVisitorCategory from "@/use/useVisitorCategory";

const vm = Vue.prototype;

export default defineComponent({
  setup() {
    const store = useStore();
    const loading = ref(true);
    const checked = ref(true);
    const countup = ref(dayjs().endOf("day").diff(dayjs(), "second") * 1000);
    const showRule = () => {
      vm.$alert({
        title: "活动规则",
        message: store.state.namespaceInfo.rule,
        confirmButtonText: "我知道了",
      });
    };

    const {
      fetchCategory,
      fetchPackage,
      fetchPaymethod,
      catesgorys,
      currentMethod,
      currentPackage,
    } = useVisitorCategory();

    const toBuy = async () => {
      if (!checked.value) {
        vm.$toast("请勾选协议");
        return;
      }
      toPayV2(currentPackage.value, currentMethod.value.channel);
    };

    onMounted(() => {
      document.title = "开通会员";
      try {
        Promise.all([fetchPackage(), fetchCategory(), fetchPaymethod()]);
      } finally {
        loading.value = false;
      }
    });
    return {
      showRule,
      catesgorys,
      currentPackage,
      loading,
      countup,
      currentMethod,
      toBuy,
      checked,
    };
  },
});
</script>


<style lang="less" scoped>
.kd100 {
  background: rgba(0, 0, 0, 0.7);
  min-height: 100vh;
}
.header {
  height: 165px;
  background: url("../../../assets/images/kd100_visitor_header_bg.png")
    no-repeat center/100%;
  position: relative;
}
.rule-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 41px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #26211c;
  color: #fef5ed;
  border-radius: 24px 0 0 24px;
}

.benefit {
  position: relative;
  z-index: 10;
  padding: 8px 12px 20px;
  border-radius: 8px 8px 0 0;
  margin-top: -10px;
  background: linear-gradient(180deg, #fee2bb 0%, #fff 4%, #ffffff 100%);
}

.page-benefit {
  margin-bottom: 16px;
  padding-top: 16px;
}
.page-benefit-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.page-benefit-tag {
  flex-basis: 40px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  color: #fef3e7;
  font-size: 10px;
  margin-right: 8px;
  background: #39322a;
  border-radius: 5px;
}

.page-benefit-name {
  line-height: 22px;
  margin-right: 8px;
  font-size: 16px;
  color: #42352c;
  font-weight: bold;
}

.page-benefit-desc {
  line-height: 20px;
  height: 20px;
  box-sizing: border-box;
  color: #df9440;
  border: 1px solid currentColor;
  border-radius: 20px;
  padding: 0 10px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px 28px 20px;
  background: #fff;
  z-index: 100;
}
.popup-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 12px 20px;
  background: #fff;
  border-radius: 8px 8px 0 0;
  z-index: 100;
  .title {
    text-align: center;
    font-size: 18px;
    color: #42352c;
    font-weight: bold;
    margin-bottom: 12px;
  }
}

.footer-btn {
  position: relative;
  overflow: hidden;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  color: #feeedc;
  background: linear-gradient(to right, #5e544a 0%, #302a24 100%);
  border-radius: 48px;
  z-index: 1;
  .price {
    padding-top: 2px;
    font-size: 36px;
    font-weight: bold;
    margin-right: 5px;
    small {
      padding: 0 4px;
      font-size: 12px;
      font-weight: normal;
      opacity: 0.5;
    }
  }
  .face-value {
    text-decoration: line-through;
    opacity: 0.5;
  }
  .sellpoint {
    font-size: 18px;
    margin-left: 10px;
  }
}

.footer-btn-wrap {
  position: relative;
}
.count-dwon {
  position: absolute;
  top: -23px;
  width: 124px;
  height: 24px;
  line-height: 17px;
  right: 20px;
  display: flex;
  background: url("../../../assets/images/kd100_seckill_bg.png") no-repeat;
  background-size: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 10;
  /deep/ .van-count-down {
    color: #fff;
  }
}

.agreement {
  line-height: 17px;
  margin-top: 10px;
  font-size: 10px;
  color: #666;
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 28px;
  /deep/ a {
    color: #ff5001;
  }
}

.popup-footer-img {
  height: 82px;
}

.h200 {
  height: 100px;
}

.guang1 {
  position: absolute;
  left: -10px;
  top: 0;
  width: 105%;
  height: 100%;
  background: #201b15;
  z-index: -2;
  animation: move 2s infinite;
}
.guang2 {
  position: absolute;
  left: -10px;
  top: 0;
  width: 115%;
  height: 100%;
  z-index: -1;
  background: url("../../../assets/images/guang.png") no-repeat center/100%;
  animation: move1 2s infinite;
}

@keyframes move {
  0% {
    transform: translateX(-100%) skewX(-25deg);
  }
  100% {
    transform: translateX(5%) skewX(-25deg);
  }
}
@keyframes move1 {
  0% {
    transform: translateX(-100%);
  }
  10% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(2%);
  }
}
</style>