<template>
  <div>
    <div class="tabbar-container">
      <div class="tabbar-panel">
        <router-view :key="key"></router-view>
      </div>
    </div>
    <div class="tabbar-bottom" v-if="pagj.isShowTabbar">
      <div class="tabbar">
        <router-link
          to="/pagj/open"
          href="javascript:;"
          active-class="tabbar-item-active"
          class="tabbar-item tabbar-item-index"
        >
          <div class="tabbar-item-icon"></div>
          <p class="tabbar-item-name">见面礼</p>
        </router-link>
        <a
          v-if="!ptoken"
          @click="jump"
          href="javascript:;"
          active-class="tabbar-item-active"
          class="tabbar-item tabbar-item-member"
        >
          <div class="tabbar-item-icon"></div>
          <p class="tabbar-item-name">已购</p>
        </a>
        <router-link
          v-else
          to="/pagj/card"
          href="javascript:;"
          active-class="tabbar-item-active"
          class="tabbar-item tabbar-item-member"
        >
          <div class="tabbar-item-icon"></div>
          <p class="tabbar-item-name">已购</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import qs from "qs";
import loginKeepDialog from "@/components/loginKeepDialog";
import ans from "@/utils/ans";

export default {
  mounted() {},
  computed: {
    ...mapState(["pagj", "ptoken", "namespace"]),
    key() {
      return this.$route.path + qs.stringify(this.$route.query);
    },
  },
  created() {
    // 未登录 重定向到开通页面
    if (
      !this.ptoken &&
      (this.$route.path === "/pagj/card" || this.$route.path === "/pagj/member")
    ) {
      this.$router.replace("/pagj/open");
    }
  },
  methods: {
    jump(e) {
      this.$toast("您还未登录，请先登录");
      ans.track("page_login_clicklogin", { namespace: this.namespace });
      setTimeout(() => {
        loginKeepDialog
          .show({
            agreement: `登录领取即视为同意
        <a
          class="login-agreement-text"
          href="https://cdn.xiangshuheika.com/h5/agreement/pagj.html"
          >《会员服务协议》</a
        >`,
          })
          .then(() => {
            ans.track("page_login_success", { namespace: this.namespace });
            this.$router.push("/pagj/card");
          });
      }, 1000);
      return;
    },
  },
};
</script>
<style scoped lang='less'>
.tabbar-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: initial;
}

.tabbar-panel {
  flex: 1;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow: initial;
}

.tabbar-bottom {
  position: relative;
  height: 50px;
  width: 100%;
  transition: bottom 0.2s, top 0.2s;
  bottom: 0;
}
.tabbar {
  background: #fff;
  position: fixed;
  display: flex;
  z-index: 500;
  bottom: 0;
  width: 100%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: 6px;
  padding-bottom: 6px;
  &:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 1px;
    border-top: 1px solid #c0bfc4;
    color: #c0bfc4;
    transform-origin: 0 0;
    transform: scaleY(0.5);
  }
}
.tabbar-item {
  display: block;
  flex: 1;
  font-size: 0;
  color: #999;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.tabbar-item-index {
  .tabbar-item-icon {
    background-image: url("../assets/images/pagj_open_icon.png");
  }
}
.tabbar-item-index.tabbar-item-active {
  .tabbar-item-icon {
    background-image: url("../assets/images/pa_gjopen_icon_active.png");
  }
}
.tabbar-item-member {
  .tabbar-item-icon {
    background-image: url("../assets/images/pagj_member_icon.png");
  }
}
.tabbar-item-member.tabbar-item-active {
  .tabbar-item-icon {
    background-image: url("../assets/images/pagj_member_icon_active.png");
  }
}
.tabbar-item-icon {
  width: 22px;
  height: 22px;
  margin: 0 auto 2px;
  background-repeat: no-repeat;
  background-size: 100%;
}
.tabbar-item-active {
  .tabbar-item-name {
    color: #fc6f1a;
    font-weight: bold;
  }
}
.tabbar-item-name {
  height: 14px;
  line-height: 14px;
  text-align: center;
  color: #999999;
  font-size: 10px;
}
</style>