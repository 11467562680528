import Vue from "vue";

import { storage } from "@/utils"
import request from "@/utils/request"
import { geetestHOF } from '@/utils'

const vm = Vue.prototype;
// 查询单品使用说明
export const getItemInfo = params => {
  return request({
    url: "/api/mall/get_item_info_by_code",
    method: "get",
    params
  });
};

export const getSpuDetail = params => {
  return request({
    url: "/api/mall/skus",
    method: "get",
    params
  }).then(res => {
    res.data.skus.forEach(item => {
      item.price = item.item_price !== undefined ? item.item_price : item.price;
    });
    return res;
  });
};

// 激活权限
export const activateBenefit = data => {
  const apiFunc = () => request({
    url: "/api/core/activate_benefit",
    method: "post",
    data
  });
  const isBuy = data.pre_activate

  return isBuy ? apiFunc() : geetestHOF(apiFunc, 'active', data)
};

// 查询订单
export const getOrderList = data => {
  return request({
    url: "/api/orders/orders_filter",
    method: "post",
    data
  });
};

// 商品库存查询
export const querySkuStock = params => {
  params.namespace = storage.getItem("namespace");
  return request({
    url: "/api/mall/sku_stocks",
    method: "get",
    params
  });
};
// 查询秒杀库存
export const querySeckillStock = params => {
  params.namespace = storage.getItem("namespace");
  return request({
    url: "/api/activity/seckill_stock",
    method: "get",
    params
  });
};

// 查询活动状态
export const getActivityInfo = params => {
  return request({
    url: `/api/activity/get_activity_info`,
    method: "get",
    params
  });
};

// 查询活动状态
export const getSeckillInfo = params => {
  return request({
    url: `/api/activity/seckill_info`,
    method: "get",
    params
  });
};

// 更新订单状态
export const updateOrderStatus = data => {
  return request({
    url: `/api/orders/create_or_update_user_order`,
    method: "post",
    data
  });
};

//查看用户是否有绑定套餐的资格
export const updateUserPhone = data => {
  return request({
    url: `/api/core/enter_user_phone`,
    method: "post",
    data
  });
};

//查询用户是否是东呈or百丽会员
export const matchMemberUserInfo = data => {
  return request({
    url: `/api/core/match_member_user_info`,
    method: "post",
    data
  });
};

//查询sku信息
export const getSku = data => {
  return request({
    url: `/api/core/sku_info_by_benefit_type`,
    method: "post",
    data
  });
};

export const getHelpList = data => {
  return request({
    url: "/api/core/help_list",
    method: "post",
    data
  });
};
export const getHelpListV2 = data => {
  return request({
    url: "/api/core/help_list_v2",
    method: "post",
    data
  });
};

// 实名认证
export const realnameVerify = data => {
  return request({
    url: `/api/core/realname_verify`,
    method: "post",
    data
  });
};
// 无身份证用户实名认证
export const notIdRealname = data => {
  return request({
    url: `/tools/real_name_api`,
    method: "post",
    data
  });
};
// 兑换
export const goCashing = data => {
  return request({
    url: `/api/core/go_cashing`,
    method: "post",
    data
  });
};

// 兑换
export const goMultiCardCashing = data => {
  return request({
    url: `/api/core/benefit_coupon_mutiple_namespace`,
    method: "post",
    data
  });
};
// 获取验证码
export const register = data => {
  return request({
    url: `/api/phone/register`,
    method: "post",
    data
  });
};

// 权益列表
export const getUserBenefit = params => {
  const card_status =
    vm.$getConfigValue("USER_BENEFIT_STAT_CARD_LIST") || undefined;
  return request({
    url: `/api/core/user_benefit_stat`,
    method: "get",
    params: {
      ...params,
      card_status: card_status
    }
  });
};
// 权益列表
export const getUserBenefityToken = (token) => {
  const card_status =
    vm.$getConfigValue("USER_BENEFIT_STAT_CARD_LIST") || undefined;
  return request({
    url: `/api/core/user_benefit_stat`,
    method: "get",
    token,
    params: {
      card_status: card_status
    }
  });
};
// 权益列表
export const queryPhoneIsp = params => {
  return request({
    url: `/api/core/get_phone_isp`,
    method: "get",
    params
  });
};
// 查询兑换码状态
export const queryRedemptionStatus = params => {
  return request({
    url: `/api/core/redemption_code_status`,
    method: "get",
    params
  });
};
// 创建活动会员
export const createActivityMember = data => {
  return request({
    url: `/api/core/activity_member`,
    method: "post",
    data
  });
};
// 分享权益
export const shareBenefit = data => {
  return request({
    url: `/api/core/share_benefit`,
    method: "post",
    data
  });
};

// 获取分享
export const getShareInfo = params => {
  return request({
    url: `/api/activity/get_share_info`,
    method: "get",
    params
  });
};

// 获取节省金额
export const queryTotalSavMoney = params => {
  return request({
    url: `/api/core/get_total_saving`,
    method: "get",
    params
  });
};

// 获取bs列表
export const queryBsList = params => {
  return request({
    url: `/api/core/get_bs_list`,
    method: "get",
    params
  });
};
// 获取接送机城市列表
export const querySpecialCarCity = params => {
  return request({
    url: `/api/core/special_car_cities`,
    method: "get",
    params
  });
};
// 一键登录
export const onepassLogin = data => {
  return request({
    url: `/api/login/onepass`,
    method: "post",
    data
  });
};
// 获取轮播
export const queryLoopData = params => {
  return request({
    url: `/api/activity/get_loop_data`,
    method: "get",
    params
  });
};

// 查询签约套餐
export const queryPeriodSku = params => {
  return request({
    url: `/api/xpay/period_skus`,
    method: "get",
    params
  });
};

// 查询是否首次签约
export const queryFirstPeriod = params => {
  return request({
    url: `/api/xpay/check_first_pay_period`,
    method: "get",
    params
  });
};

// 查询keep绑定手机号
export const queryLoginBind = params => {
  return request({
    url: `/api/login/get_binding`,
    method: "get",
    params
  });
};
// keep绑定手机号
export const loginBind = data => {
  return request({
    url: `/api/login/phone_binding`,
    method: "post",
    headers: {
      Accept: "*/*"
    },
    data
  });
};
// 创建虚拟用户
export const getLogicUser = params => {
  return request({
    url: `/api/core/logic_user`,
    token: storage.getItem("ptoken"),
    method: "get",
    params
  });
};

// 查询多卡
export const queryMultiCard = params => {
  return request({
    url: `/api/core/multi_card`,
    method: "get",
    token: storage.getItem("ptoken"),
    params
  });
};

// 赠送卡
export const sendGiverCard = params => {
  return request({
    url: `/api/core/give_card`,
    method: "get",
    token: storage.getItem("ptoken"),
    params
  });
};

// 获取卡
export const getGiveCard = params => {
  return request({
    url: `/api/core/get_card`,
    method: "get",
    token: storage.getItem("ptoken"),
    params
  });
};

// 切卡
export const switchGiveCard = params => {
  return request({
    url: `/api/core/get_logic_token`,
    method: "get",
    token: storage.getItem("ptoken"),
    params
  });
};

// 查询卡状态
export const getCardStatus = params => {
  return request({
    url: `/api/core/get_card_status`,
    method: "get",
    token: storage.getItem("ptoken"),
    params
  });
};

// 查询用户今日是否使用权益
export const queryUserBenefitUse = params => {
  return request({
    url: `/api/core/user_today_benefit_use`,
    method: "get",
    params
  });
};

//获取卡信息
export const getCardInfo = params => {
  return request({
    url: `/api/core/mdd_card_info`,
    method: "get",
    params
  });
};

//获取缓存url
export const getCacheUrl = params => {
  return request({
    url: `/api/core/pay/get_cache_pay_url`,
    method: "get",
    params
  });
};

// 获取用户权益使用信息
export const getUserBenefitAmountInfo = params => {
  return request({
    url: `/api/core/get_user_benefit_used_amount_info`,
    method: "get",
    params
  });
};

// 获取用户权益有效期天数
export const getUserBenefitCouponDays = params => {
  return request({
    url: `/api/core/get_package_benefit_rel_coupon_days`,
    method: "get",
    params
  });
};

export const activitySkillOrder = data => {
  const apiFunc = () => request({
    url: `/api/activity/seckill`,
    method: "post",
    data
  });

  return geetestHOF(apiFunc, 'secKill')
};
export const getSeckillResult = params => {
  return request({
    url: `/api/activity/seckill_record_status`,
    method: "get",
    params
  });
};

// 取消秒杀记录
export const cancelSeckillRecord = data => {
  return request({
    url: `/api/activity/cancel_seckill_record`,
    method: "post",
    data
  });
};

export const querySeckillRecords = params => {
  return request({
    url: `/api/activity/seckill_records`,
    method: "get",
    params
  });
};
export const getHelpDetail = params => {
  return request({
    url: `/api/core/help_detail`,
    method: "get",
    params
  });
};
export const getHelpProblemUseFeedback = params => {
  return request({
    url: `/api/core/get_help_problem_user_feedback_record`,
    method: "get",
    params
  });
};
export const createHelpProblemUseFeedback = data => {
  return request({
    url: `/api/core/create_help_problem_user_feedback_record`,
    method: "post",
    data
  });
};

// 单项权益下单
export const singleBenefitPlaceOrder = data => {
  return request({
    url: `/api/mq/single_benefit_place_order`,
    method: "post",
    headers: {
      Accept: "*/*"
    },
    data
  });
};

// 上传验证数据
export const geeTestUpload = data => {
  return request({
    url: "/api/core/cache_geetest_data",
    method: "post",
    data
  });
};

// 查询小赢省钱卡token
export const getXyLifeToken = data => {
  return request({
    url: "/api/core/xy_convert_member_from_vip_to_life_member",
    method: "post",
    data
  });
};
