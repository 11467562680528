<template>
  <div class="luck-draw-body">
    <van-image
      class="luck-draw-bg"
      :src="activityInfo.special_info.header_img"
    />
    <div class="luck-draw-component">
      <SlotMachine
        class="luckygrid"
        :width="width"
        :height="height"
        :defaultConfig="{ gutter: '8px' }"
        ref="luckyWheelRef"
        :prizes="prizesList"
        :default-style="{ fontColor: '#F7923B', fontSize: '13px' }"
        @start="drawHandleThrottle"
        :slots="slots"
        @end="endLucky"
      />
    </div>
    <div class="draw-btn-wrap">
      <van-button
        block
        round
        type="primary"
        class="draw-btn1"
        @click="drawHandleThrottle"
      >
        {{ activityInfo.special_info.start_draw_text || "开始抽奖" }}
        {{ remainingTimes > 0 ? `X${remainingTimes}` : "" }}
      </van-button>
    </div>
    <Dialog
      v-model="showDialog"
      :activityReward="activityReward"
      :type="type"
      :openPrizePopup="openPrizePopup"
    />
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import throttle from "lodash.throttle";

import { SlotMachine } from "@lucky-canvas/vue";
import Dialog from "./Dialog";

export default defineComponent({
  inheritAttrs: false,

  components: {
    SlotMachine,
    Dialog,
  },
  props: {
    activityInfo: Object,
    activityReward: Object,
    beforeDraw: Function,
    openPrizePopup: Function,
    afterDraw: Function,
    type: String,
    remainingTimes: Number,
  },
  setup(props) {
    const luckyWheelRef = ref(null);
    const showDialog = ref(false);
    const run = ref(false);

    const width = computed(() => {
      const w = props.activityInfo.special_info.width || 250;
      return (
        (document.documentElement.getBoundingClientRect().width / 10) *
        (w / 37.5)
      );
    });
    const height = computed(() => {
      const w =
        props.activityInfo.special_info.height ||
        props.activityInfo.special_info.width ||
        90;
      return (
        (document.documentElement.getBoundingClientRect().width / 10) *
        (w / 37.5)
      );
    });

    const prizesList = computed(() => {
      const goods_choice = props.activityInfo.special_info.goods_choice || {};
      return Object.entries(goods_choice).map(([k, v], index) => {
        return {
          item_code: k,
          imgs: [
            {
              activeSrc: v.img1,
              src: v.img,
              width: props.activityInfo.special_info.prizeWidth || "75%",
              top: props.activityInfo.special_info.prizeTop || "25%",
            },
          ],
        };
      });
    });

    const slots = computed(() => {
      const orders = prizesList.value.map((_, index) => index);
      return [
        { order: orders, direction: 1 },
        { order: orders.slice(1, orders.length).concat([0]), direction: -1 },
        { order: orders.slice(2, orders.length).concat([0, 1]), direction: 1 },
      ];
    });

    const startLucky = async () => {
      if (run.value) {
        return;
      }
      const res = await props.beforeDraw();
      if (!res) {
        return;
      }
      luckyWheelRef.value.play();
      run.value = true;
      setTimeout(() => {
        const index = prizesList.value.findIndex(
          (r) => r.item_code === res.item_code
        );
        luckyWheelRef.value.stop(index);
      }, 2000);
    };
    const drawHandleThrottle = throttle(startLucky, 3000, { trailing: false });

    const endLucky = () => {
      showDialog.value = true;
      setTimeout(async () => {
        await props.afterDraw();
        run.value = false;
      }, 300);
    };
    return {
      prizesList,
      width,
      height,
      endLucky,
      drawHandleThrottle,
      luckyWheelRef,
      showDialog,
      slots,
    };
  },
});
</script>

<style lang="less" scoped>
</style>
