<template>
  <div class="card-info" v-if="coupons.length > 0">
    <div v-if="coupons.length > 0" class="coupon" @click="visibleCoupon = true">
      <div class="coupon-tag">券</div>
      <div class="coupon-title">你有可领取优惠券</div>
      <div class="coupon-btn">领取 ></div>
    </div>

    <SelectCoupon
      :visible.sync="visibleCoupon"
      :coupons="coupons"
      type="get"
      :recive="recive"
    />
  </div>
</template>

<script>
import { getDrawCoupon, reciveDrawCoupon } from "@/apis";
import SelectCoupon from "@/components/SelectCoupon";
import { storage } from "@/utils";

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SelectCoupon,
  },
  data() {
    return {
      visible: false,
      visibleCoupon: false,
      coupons: [],
    };
  },
  computed: {},
  mounted() {
    if (storage.getItem("token")) {
      this.getCoupon();
    }
  },
  methods: {
    //获取可领取的优惠券
    async getCoupon() {
      const res = await getDrawCoupon({ scope: "hotel" });
      this.coupons = res.data;
    },
    async recive(item) {
      const loading = this.$loading();
      try {
        await reciveDrawCoupon({ batch_id: item.batch_id });
        await this.getCoupon();
        this.$toast("领取成功");
        loading.clear();
      } catch (e) {
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.coupon {
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 36px;
  line-height: 36px;
  background: url("~@/assets/images/hotel/coupon_bg.png") no-repeat;
  background-size: 100%;
  margin-top: 12px;
}
.coupon-tag {
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  background: #ff5001;
  border-radius: 2px;
  margin-right: 5px;
}
.coupon-title {
  font-weight: bold;
}
.coupon-btn {
  margin-left: auto;
  color: #ff5001;
}
</style>