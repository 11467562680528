<template>
  <div class="redeem">
    <div class="redeem-view">
      <input
        class="redeem-input"
        placeholder="请输入正确的兑换码"
        v-model.trim="inputVal"
      />
    </div>
    <van-button
      round
      size="large"
      block
      type="info"
      @click="redeem"
      v-ans="{
        event_id: 'redeem_button',
        redeem_code: inputVal,
      }"
    >
      立即兑换
    </van-button>
    <div class="redeemed" @click="redeemed">我已兑换，去使用</div>

    <div class="redeem-kefu">
      <div class="login-agreement" v-if="namespace === 'keep'">
        兑换成功视为同意<a
          class="link"
          href="https://mo.gotokeep.com/prime/vip/agreement"
          >《keep付费会员用户服务协议》</a
        >和<a
          href="https://cdn.xiangshuheika.com/h5/agreement/keep.html"
          class="link"
          >《橡树服务协议》</a
        >
      </div>
      <p @click="toService">如遇到问题，请咨询在线客服 ></p>
    </div>
    <van-dialog
      v-model="show"
      title="恭喜您已获得"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="redeem-msg">{{ msg }}</div>
      <van-image
        class="redeem-image"
        width="90px"
        :src="require('../../assets/images/vip.png')"
      />
      <div class="redeem-footer">
        <div class="redeem-cancel" @click="show = false">取消</div>
        <div class="redeem-confirm" @click="$router.push('/index')">好的</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { goCashing } from "@/apis/home";
import { mapActions, mapMutations, mapState } from "vuex";
import { storage } from "@/utils";
import LoginDialog from "@/components/loginDialog";
import CustomDialog from "@/components/dialog";

export default {
  data() {
    return {
      show: false,
      inputVal: "",
      msg: "权益使用权",
    };
  },
  computed: {
    ...mapState(["namespace", "userInfo"]),
  },
  mounted() {},
  methods: {
    ...mapMutations(["changeState"]),
    ...mapActions(["fetchUserInfo", "fetchClientConfigList"]),
    async redeem() {
      const token = storage.getItem("token");
      if (!token && this.namespace) {
        this.login();
        return;
      }
      if (!this.inputVal) {
        this.$toast("请填写兑换码");
        return false;
      }
      const loading = this.$loading();
      try {
        const res = await goCashing({
          cashing_code: this.inputVal,
        });
        await this.fetchUserInfo();
        const { token, namespace } = res.data;
        const clientConfigs = await this.fetchClientConfigList(namespace);
        this.changeState({
          token,
          namespace,
          clientConfigs,
        });
        // 显示成功弹窗
        CustomDialog.show({
          title: "恭喜您已获得",
          showCancelButton: false,
          confirmButtonText: "好的",
          content: (
            <div>
              <div class="redeem-msg">
                {res.msg || this.userInfo.card_type_cn}
              </div>
              <div class="redeem-image"></div>
            </div>
          ),
        }).then((confirm) => {
          if (confirm) {
            this.$router.push("/index");
          }
        });
      } catch (e) {
        console.log(e);
        if (e?.response?.status === 403) {
          this.login();
        }
      } finally {
        loading.clear();
      }
    },
    login() {
      return LoginDialog.show({ title: "若未注册，将自动免费注册" });
    },
    async redeemed() {
      const token = storage.getItem("token");
      //判断是否是广发基金的用户
      if (
        ["gffunds1", "gffunds2", "gffunds3", "gffunds4"].includes(
          this.namespace
        )
      ) {
        this.$router.push("/gfPckageList");
        return;
      }
      if (!token && this.namespace) {
        this.login();
        return;
      }

      if (!this.userInfo.is_member) {
        this.$toast("您还不是会员");
        return;
      } else {
        this.$router.push("/index");
      }
    },

    toService() {
      const token = storage.getItem("token");
      if (!token && this.namespace) {
        this.login();
        return;
      }
      this.$router.push("/service");
    },
  },
  beforeDestroy() {
    CustomDialog.close();
  },
};
</script>

<style lang="less" scoped>
.redeem {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  padding: 0 20px;
  box-sizing: border-box;
}
.redeem-view {
  margin-top: 100px;
  margin-bottom: 40px;
}
.redeem-input {
  line-height: 50px;
  height: 50px;
  text-align: center;
  font-size: 18px;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #e1e2e6;
  outline: 0;
  -webkit-appearance: none;
  background-color: transparent;
}
.redeem-btn {
  margin: 46px 30px 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 25px;
  background: #1a1a1a;
  font-size: 18px;
  color: #ffefd7;
  font-weight: bold;
}
.redeemed {
  text-align: center;
  color: #999;
  height: 49px;
  line-height: 49px;
  margin-top: 24px;
  border-radius: 25px;
  font-size: 18px;
  margin-top: 20px;
  border: 1px solid #999;
  background-color: #fff;
  position: relative;
  z-index: 2;
}
.redeem-kefu {
  position: absolute;
  text-align: center;
  font-size: 12px;
  width: 100%;
  left: 0;
  padding: 0 20px;
  box-sizing: border-box;
  bottom: 23px;
  p {
    padding-top: 12px;
    color: #0091ff;
  }
}
.redeem-text-1 {
  font-size: 13px;
  color: #333;
}
.redeem-vip {
  width: 90px;
  display: block;
  margin: 28px auto;
}
.redeem-desc {
  font-size: 20px;
  color: #333;
  text-align: center;
}

.redeem-image {
  width: 90px;
  height: 90px;
  background: url("../../assets/images/vip.png") no-repeat;
  background-size: 100%;
  margin: 30px auto 10px;
}
.login-agreement {
  font-size: 10px;
}
.redeem-msg {
  text-align: center;
  padding-top: 20px;
}
</style>