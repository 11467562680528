<template>
  <div class="wrap" v-if="!loading">
    <div class="my-order" @click="myOrder">我的订单</div>
    <div class="header">
      <div class="title">{{ cardTypeCn }}</div>
      <div class="time">
        会员有效期 {{ createTime | formatDate("YYYY.MM.DD") }} -
        {{ endTime | formatDate("YYYY.MM.DD") }}
      </div>
    </div>
    <div class="content">
      <div class="tip">剩{{ benefit.amount }}次</div>
      <div class="title">
        <img
          src="https://common-h5-dev.xiangshuheika.com/static/upload/20240726dd24f3ea232a.png"
          alt=""
        />
        以下权益<span>3选1</span
        ><img
          src="https://common-h5-dev.xiangshuheika.com/static/upload/20240726e2485e548c4ca.png"
          alt=""
        />
      </div>
      <div class="card-wrap">
        <div class="card" v-for="item in categories" :key="item.id">
          <div class="card-value">
            <div class="name">{{ item.name }}</div>
            <div class="price">价值¥{{ item.desc }}</div>
          </div>
          <img :src="item.display_img_url" alt="" />
        </div>
      </div>
      <div class="btn" @click="handleDraw">立即领取</div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from "@vue/composition-api";
import Vue from "vue";

import { getIndexCategory } from "@/apis/product";
import { getUserBenefit } from "@/apis/home";
import { useStore, useRouter } from "@/components/use/base";

const vm = Vue.prototype;

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const namespace = store.state.namespace;
    const source = store.state.source;
    const categories = ref([]);
    const benefit = ref({});
    const loading = ref(false);

    const createTime = computed(() => store.state.userInfo.t_vip_created);
    const endTime = computed(() => store.state.userInfo?.card_expiry_date?.[0]);
    const cardTypeCn = computed(() => store.state.userInfo?.card_type_cn);

    const initData = async () => {
      if (!store.state.isMember) {
        router.replace("/index");
        return;
      }
      loading.value = true;
      try {
        const { data } = await getIndexCategory({
          namespace: namespace,
          opened_page: "member",
          source: source,
        });
        const { data: benefitData } = await getUserBenefit();
        categories.value = data;
        benefit.value = benefitData?.benefit_list?.[0];
      } catch (error) {
        vm.$toast("网络错误，请稍后重试");
      } finally {
        loading.value = false;
      }
    };

    const myOrder = () => {
      router.push(`/order`);
    };

    const handleDraw = () => {
      router.push(`/benefitDetail?benefit_type=${benefit.value.type}`);
    };

    onMounted(async () => {
      initData();
    });

    return {
      categories,
      handleDraw,
      myOrder,
      benefit,
      loading,
      createTime,
      endTime,
      cardTypeCn,
    };
  },
});
</script>

<style lang="less" scoped>
.wrap {
  overflow: hidden;
  min-height: 100vh;
  box-sizing: border-box;
  background: linear-gradient(
    #fbc997 0%,
    #fae4c0 170px,
    rgb(255, 253, 251) 254px,
    #fffdfb 100%
  );
  position: relative;
}

.my-order {
  padding: 2px 7px 2px 9px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 11px 0px 0px 11px;
  position: absolute;
  right: 0;
  top: 8px;

  font-weight: 400;
  font-size: 11px;
  color: #6e4b14;
  line-height: 16px;
}

.header {
  margin: 0 22px;
  margin-top: 38px;
  background: url("https://common-h5-dev.xiangshuheika.com/static/upload/202407269c8ef95f7aec7.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 86px;
  padding-left: 17px;
  overflow: hidden;
  box-sizing: border-box;

  .title {
    font-family: DingTalk, DingTalk;
    font-size: 21px;
    color: #ffffff;
    line-height: 25px;
    margin-top: 14px;
  }
  .time {
    font-weight: 400;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.5);
    line-height: 14px;
    margin-top: 2px;
  }
}
.content {
  position: relative;
  margin: 0 14px;
  margin-top: -16px;
  width: auto;
  min-height: 406px;
  background: #fff;
  border-radius: 16px;
  overflow: hidden;

  .tip {
    right: 0;
    top: 0;
    position: absolute;
    background: linear-gradient(90deg, #feedd7 0%, #ffffff 100%);
    border-radius: 0 9px 0 6px;
    padding: 1px 10px;

    font-weight: 400;
    font-size: 11px;
    color: #844f16;
    line-height: 16px;
  }

  .title {
    margin-top: 16px;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    text-align: center;
    span {
      color: rgba(207, 20, 29, 1);
    }
    > img {
      width: 23px;
      height: 17px;
      margin: 0 9px;
    }
  }
  .card-wrap {
    margin: 13px;
  }

  .card {
    display: flex;
    justify-content: space-between;
    height: 87px;
    background: linear-gradient(90deg, #fffdf3 0%, #fff9e3 100%);
    border-radius: 10px;
    box-sizing: border-box;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .card-value {
      margin-left: 22px;
      > .name {
        margin-top: 23px;
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
      }
      > .price {
        font-weight: 400;
        font-size: 11px;
        color: #666666;
        line-height: 16px;
      }
    }
    img {
      height: 87px;
    }
  }

  .btn {
    margin: 0 auto;
    width: 161px;
    height: 42px;
    background: linear-gradient(117deg, #4b5163 0%, #101012 100%);
    border-radius: 22px;
    font-weight: 500;
    font-size: 15px;
    color: #ffe9ce;
    line-height: 42px;
    text-align: center;
  }
}
</style>
