<template>
  <section class="business-member">
    <section class="business-header">
      <div class="business-header-inner">
        <div>
          <div class="business-text">
            {{
              userInfo.is_verified
                ? `${userInfo.last_name_cn}${userInfo.first_name_cn}`
                : "COCO PLUS"
            }}
          </div>
          <div class="business-exprired">
            {{ userInfo.card_expiry_date[0] }}到期
          </div>
        </div>
        <!-- <div class="xf" @click="xf">立即续费</div> -->
      </div>
    </section>
    <div class="business-member-entry">
      <div 
        class="entry-item" 
        :span="12" 
        @click="toOrder"
        v-ans="{
          event_id: 'business_member_button1',
          namespace,
        }"
      >
        <van-image
          class="entry-item-image"
          :src="require('../../../assets/images/order_icon.svg')"
        />
        <p>我的订单</p>
      </div>
      <div 
        class="entry-item" 
        @click="$router.push('/service')"
        v-ans="{
          event_id: 'business_member_button2',
          namespace,
        }"
      >
        <van-image
          class="entry-item-image"
          :src="require('../../../assets/images/customer_icon.svg')"
        />
        <p>专属客服</p>
      </div>
    </div>
    <div class="business-member-cell">
      <!-- <van-cell
        title="当前商圈"
        value-class="cell-value"
        is-link
        :value="namespaceInfo.name_cn"
        to="/business/park"
      >
        <template #icon>
          <svg-icon class="svg-icon" iconClass="park" />
        </template>
      </van-cell> -->
      <van-cell
        title="实名认证"
        is-link
        value="使用权益更方便"
        to="/centification"
      >
        <template #icon>
          <svg-icon class="svg-icon" iconClass="centification" />
        </template>
      </van-cell>
      <van-cell title="常见问题" is-link to="/help">
        <template #icon>
          <svg-icon class="svg-icon" iconClass="answer" />
        </template>
      </van-cell>
      <!-- <van-cell title="服务协议" is-link>
        <template #icon>
          <svg-icon class="svg-icon" iconClass="agreement" />
        </template>
      </van-cell> -->
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { getMemberInfo } from "@/apis";
export default {
  components: {},
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState(["namespace", "namespaceInfo", "userInfo"]),
  },
  methods: {
    async xf() {
      const memberInfo = await getMemberInfo();
      if (!memberInfo.data.is_renewable) {
        this.$toast("当前续费次数已达到上限");
        return;
      }
      this.$router.push("/package?type=xf");
    },
    toOrder() {
      this.$router.push("/order");
    },
  },
};
</script>

<style lang="less" scoped>
.business-member-cell {
  /deep/ .van-cell {
    padding: 18px 15px;
  }
}
.cell-value {
  flex: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.business-text {
  color: #f9cdaf;
  line-height: 22px;
  font-size: 16px;
  margin-bottom: 4px;
}
.business-exprired {
  line-height: 18px;
  opacity: 0.8;
  color: #f9cdaf;
}
.business-header {
  height: 118px;
  background: #fef2e6;
  padding: 30px 15px 0;
  box-sizing: border-box;
}

.business-header-inner {
  height: 88px;
  padding: 0 12px;
  box-sizing: border-box;
  background: url("../../../assets/images/my_member_bg.png") no-repeat;
  background-size: 100%;
  border-radius: 10px 10px 0px 0px;
}
.business-member-entry {
  height: 78px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.entry-item {
  flex: 1;
  p {
    text-align: center;
  }
}
.entry-item-image {
  height: 36px;
  width: 36px;
  display: block;
  margin: 0 auto 4px;
}

.svg-icon {
  font-size: 24px;
  margin-right: 5px;
}

.business-header-inner {
  display: flex;
  align-items: center;
  .xf {
    margin-left: auto;
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
    background: #f9cdaf;
    border-radius: 24px;
    color: #131313;
    font-weight: bold;
  }
}
</style>