<template>
  <div>
    <member v-if="isMember" />
    <visitor v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";

import member from "./member.vue";
import visitor from "./visitor.vue";

export default {
  components: { member, visitor },
  computed: {
    ...mapState(["isMember"]),
  },
};
</script>


<style lang="less" scoped>
</style>