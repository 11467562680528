<template>
  <div class="activation" v-if="pageshow">
    <div class="top">
      <h4>恭喜，开通会员成功</h4>
      <van-image
        class="img"
        lazy-load
        fit="cover"
        :src="firstBenefit.display_img_url"
      />
      <h2>激活{{ benefitInfo.title }}</h2>
    </div>
    <div class="account">
      <div :class="{ item: true, active: active === 1 }" @click="active = 1">
        <b>使用当前账号：</b>
        <van-field v-model="phone" readonly />
      </div>
      <div
        :class="{ item: true, other: true, active: active === 2 }"
        @click="active = 2"
      >
        <b
          >使用其他账号<span v-if="benefitInfo.type === 'tencent'"
            >（2选1）</span
          >：</b
        >
        <van-field
          v-model="phone_number"
          type="tel"
          placeholder="请输入手机号"
        />
        <van-field
          v-if="selectSku.account_top_up_type === 3"
          v-model="account"
          type="number"
          placeholder="请输入QQ号"
        />
      </div>
      <van-button
        :disabled="benefitInfo.amount_available <= 0"
        @click="toBind"
        v-ans="{
          event_id: 'active_click_now',
          source,
          namespace,
        }"
        class="btn"
        block
        type="default"
        round
        >绑定账号激活</van-button
      >
    </div>
    <div class="tips">
      <span>注意：</span
      >本商品为充值商品，激活前请仔细确认该视频账号是否正确，激活成功后立即到账，无法进行退换或更改！
    </div>
    <div class="title">激活须知</div>
    <div
      class="rules"
      v-html="selectSku.instruction || selectSku.spu_instruction"
    ></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { verifyForm } from "@/utils";
import pickBy from "lodash.pickby";
import { activateBenefit } from "@/apis/home";
import { getIndexCategory, getIndexClassList } from "@/apis/product";
import { getBenefitInfo, getSkuByBenefitType } from "@/apis";
export default {
  data() {
    return {
      pageshow: false,
      phone_number: "",
      account: "",
      active: 1,
      benefitInfo: {},
      selectSku: {},
      master_benefit: {},
      catesgoryList: [],
    };
  },
  async mounted() {
    const loading = this.$loading();
    const res2 = await getIndexCategory({
      namespace: this.namespace,
      opened_page: `xiaopai_member_${this.userInfo.card_type.toLowerCase()}`,
      source: this.source,
    });
    const catesgorys = res2.data;
    const list = await getIndexClassList({
      title: catesgorys.map((r) => r.name).join(","),
      opened_page: `xiaopai_member_${this.userInfo.card_type.toLowerCase()}`,
      namespace: this.namespace,
    });
    catesgorys.forEach((item) => {
      item.list = [...list.data[item.name]];
    });
    this.catesgoryList = catesgorys;
    this.master_benefit = list.data["生活特权"].shift();
    const benefit_type = this.master_benefit.product_code;
    const res = await getBenefitInfo({ benefit_type });
    const sku = await getSkuByBenefitType({
      benefit_type,
      namespace: this.namespace,
    });
    this.skuInfo = sku.data;
    this.selectSku = this.skuInfo[0];
    this.benefitInfo = res.data;
    this.pageshow = true;
    loading.clear();
  },
  computed: {
    ...mapState(["userInfo", "source", "namespace", "benefit_source"]),
    phone() {
      return this.userInfo.login_phone_number.split(".")[0];
    },
    firstBenefit() {
      let res = {};
      const item =
        this.catesgoryList.find((item) =>
          item.display_code.includes(this.userInfo.card_type.toLowerCase())
        ) || {};
      if (item?.list?.length) {
        res = item.list[0];
      }
      return res;
    },
  },
  methods: {
    toBind() {
      const { phone_number, account } = this;
      this.$confirm({
        title: "温馨提示",
        hideClose: true,
        message: `确定激活当前账号：<b>${
          phone_number || account || this.phone
        }</b>吗？确认后无法修改`,
        confirmButtonText: "确认激活",
        cancelButtonText: "我再想想",
      }).then(() => {
        this.activation();
      });
    },
    async activation() {
      const { phone_number, account, active } = this;
      if (active === 2) {
        if (!phone_number && !account) {
          this.$toast("请输入账号");
          return;
        }
        const phone = verifyForm.verifyPhone(phone_number);
        if (phone_number && phone) {
          this.$toast(phone);
          return;
        }
        if (account && !/^[1-9][0-9]{4,11}$/.test(account)) {
          this.$toast("请输入正确账号");
          return;
        }
      }
      const loading = this.$loading();
      const params = pickBy(
        {
          benefit_type: this.benefitInfo.type,
          benefit_source: this.benefit_source || "会员权益",
          is_zero_pay: true,
          order_name: this.selectSku.item_name,
          item_code: this.selectSku.item_code,
          top_up_type: this.selectSku.top_up_type,
          detail_type: this.selectSku.item_name,
          activation_platform: this.selectSku.activation_platform,
          phone_number: phone_number || account || this.phone,
          source: this.source,
          to_update_account: "false",
        },
        (v) => v !== undefined
      );
      try {
        await activateBenefit(params);
        this.$toast("激活成功");
        setTimeout(() => {
          this.$router.push(`/xiaopai/home`);
        }, 1000);
      } finally {
        loading.clear();
      }
    },
  },
  watch: {
    phone_number(val) {
      if (val) {
        this.account = "";
      }
      if (this.account) {
        this.$toast("只能激活一个账号，手机号和QQ号权益不互通");
      }
    },
    account(val) {
      if (val) {
        this.phone_number = "";
      }
      if (this.phone_number) {
        this.$toast("只能激活一个账号，手机号和QQ号权益不互通");
      }
    },
    active(val) {
      if (val === 1) {
        this.phone_number = "";
        this.account = "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.activation {
  background-color: #fff;
  min-height: 100vh;

  .top {
    h4 {
      text-align: center;
      font-size: 16px;
      color: #333;
      padding-top: 20px;
      margin-top: 0;
    }

    .img {
      width: 64px;
      height: 64px;
      margin: 18px auto;
    }

    h2 {
      text-align: center;
      font-size: 20px;
      color: #333;
    }
  }

  .account {
    margin: 20px 20px 0;

    .item {
      background: url("../../assets/images/select3.png") no-repeat left 17px;
      background-size: 12px 12px;
      font-size: 14px;
      color: #333;
      line-height: 45px;
      display: flex;

      b {
        padding-left: 16px;
        font-weight: normal;
      }

      /deep/.van-cell {
        background-color: #f5f5f5;
        border-radius: 45px;
        flex: 1;
      }
    }

    .other {
      display: block;

      /deep/.van-cell {
        margin-bottom: 12px;
      }
    }

    .active {
      background: url("../../assets/images/selected3.png") no-repeat left 17px;
      background-size: 12px 12px;
    }

    .btn {
      background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
      height: 45px;
      text-align: center;
      line-height: 45px;
      margin-top: 20px;
      font-size: 18px;
      color: #fff;
      border-radius: 45px;
    }
  }

  .tips {
    margin: 30px 20px;
    background: #fef9f7;
    border: 1px solid #ea5524;
    border-radius: 8px;
    font-size: 12px;
    color: #333;
    line-height: 18px;
    padding: 8px 10px;

    span {
      color: #ea5524;
    }
  }

  .title {
    text-align: center;
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .rules {
    font-size: 12px;
    color: #666;
    line-height: 1.6;
    margin: 12px 20px;
  }
}
</style>