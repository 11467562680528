<template>
  <span>
    <span @click.stop="click">
      <slot></slot>
    </span>
    <oak-popup class="agreement-popup" :title="title" v-model="showPopup">
      <van-loading class="text-center" v-if="loading">加载中</van-loading>
      <iframe
        @load="load"
        v-show="!loading"
        class="agreement-content"
        :src="src"
      ></iframe>
    </oak-popup>
  </span>
</template>


<script>
import ans from "@/utils/ans";
export default {
  props: {
    title: {
      type: String,
    },
    agreementName: {
      type: String,
    },
  },
  data() {
    return {
      showPopup: false,
      loading: false,
    };
  },
  computed: {
    src() {
      if (this.agreementName.startsWith("http")) {
        return this.agreementName;
      }
      return this.$cdn + `/h5/agreement/${this.agreementName}.html`;
    },
  },
  methods: {
    load() {
      this.loading = false;
    },
    click() {
      if (this.$el.getAttribute("data-ans")) {
        const params = JSON.parse(this.$el.getAttribute("data-ans"));
        ans.track(params.event_id, {
          ...params,
        });
      }
      this.showPopup = true;
    },
  },
};
</script>

<style lang="less">
.agreement-popup {
  height: 90%;
  overflow: hidden;
  .oak-popup-content {
    padding: 0;
  }
}
.agreement-content {
  width: 100%;
  height: calc(100% - 4px);
  border: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}
</style>
