var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-swipe',{staticClass:"hotel-banner",attrs:{"autoplay":3000,"indicator-color":"white"},on:{"change":function($event){_vm.current = $event}},scopedSlots:_vm._u([{key:"indicator",fn:function(){return [_c('div',{staticClass:"custom-indicator-box"},[(_vm.images.length > 1)?_c('div',{staticClass:"custom-indicator"},_vm._l((_vm.images),function(item,index){return _c('div',{key:index,class:{
            'custom-indicator-item': true,
            active: index === _vm.current,
            active_1: index === _vm.current - 1,
            active_2: index === _vm.current - 2,
            active1: index === _vm.current + 1,
            active2: index === _vm.current + 2,
          }})}),0):_vm._e()])]},proxy:true}])},_vm._l((_vm.images),function(item,idx){return _c('van-swipe-item',{key:idx},[_c('van-image',{staticClass:"hotel-img",attrs:{"fit":"cover","lazy-load":"","src":item}})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }