<template>
  <van-popup
    :class="`draw-popup draw-popup-${type}`"
    :value="show"
    :duration="0.2"
    get-container="body"
    :close-on-click-overlay="false"
  >
    <div class="draw-popup-main">
      <img
        v-if="type !== 'midAutumn'"
        class="draw-popup-img"
        :src="require('../../assets/images/draw/dialog-img.png')"
        alt=""
      />
      <div v-else class="draw-midautumn-prize">
        <van-image class="draw-midautumn-prize-img" :src="activityReward.img" />
      </div>
      <div class="draw-popup-content">
        <!-- status = 5 中奖,  status = 6 未中奖 -->
        <div
          class="draw-luck-hint draw-luck-title"
          v-if="activityReward.status !== 6"
        >
          恭喜您已抽中
        </div>
        <div
          class="draw-luck-hint draw-pizname"
          v-if="activityReward.status === 6"
          v-html="activityReward.prize_name"
        ></div>
        <div
          class="draw-benefit-name draw-pizname"
          v-if="activityReward.status !== 6"
          v-html="activityReward.prize_name"
        ></div>
        <van-image class="draw-luck-pizimage" :src="activityReward.img" />
        <div class="draw-dialog-btn">
          <button
            v-if="activityReward.status !== 6"
            class="btn1"
            @click="hideDialog"
          >
            继续抽奖
          </button>
          <button
            v-if="activityReward.status !== 6"
            class="btn2"
            @click="showPrize"
          >
            查看我的奖品
          </button>
          <button
            v-if="activityReward.status === 6"
            class="btn2"
            @click="hideDialog"
          >
            继续抽奖
          </button>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  inheritAttrs: false,

  props: ["show", "activityReward", "openPrizePopup", "type"],
  model: {
    prop: "show",
    event: "change",
  },
  data() {
    return {};
  },
  methods: {
    hideDialog() {
      this.$emit("change", false);
    },
    showPrize() {
      this.hideDialog();
      this.openPrizePopup();
    },
  },
};
</script>

<style lang='less' scoped>
.draw-popup {
  background-color: transparent;
  overflow: visible;
}
.draw-popup-main {
  width: 275px;
  transform: translateY(-30px);
}
.draw-popup-img {
  width: 100%;
  position: relative;
  z-index: 4;
}
.draw-popup-content {
  background-color: #5f34fd;
  transform: translateY(-30px);
  padding-bottom: 20px;
  border-radius: 10px;
  padding-top: 44px;
}
.draw-luck-hint {
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
}
.draw-benefit-name {
  color: #fff;
  text-align: center;
  line-height: 32px;
  margin-top: 8px;
  font-weight: bold;
  font-size: 20px;
  padding: 0 20px;
}
.draw-dialog-btn {
  display: flex;
  justify-content: space-between;
  margin: 24px 13px 0;
  button {
    box-sizing: border-box;
    height: 45px;
    border-radius: 28px;
    color: #fff;
    font-size: 16px;
    border: 0;
    background-color: transparent;
    flex: 1;
    margin-right: 11px;
  }
  &:last-child {
    margin-right: 0;
  }
  .btn1 {
    border: 1px solid #fff;
  }
  .btn2 {
    background: #ff1783;
  }
}

.draw-popup-midAutumn .draw-popup-main {
  width: 330px;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
  .draw-luck-hint {
    color: #333;
  }
  .draw-popup-content {
    padding: 0;
    background: transparent;
    transform: translate(0);
  }
}

.draw-midautumn-prize {
  width: 136px;
  height: 160px;
  background: url("../../assets/images/prize-bg.png") no-repeat;
  background-size: contain;
  margin: 0 auto 16px;
}
.draw-midautumn-prize-img {
  display: block;
  height: 100%;
  /deep/ img {
    margin: 0 auto;
    width: auto;
  }
}

.draw-popup-midAutumn {
  .btn1,
  .btn2 {
    height: 50px;
    background: #008c84;
    box-shadow: inset 1px 3px 1px 0px rgba(216, 255, 148, 0.52),
      inset -1px -3px 1px 0px rgba(255, 0, 0, 0.3);
  }
}

.draw-luck-pizimage {
  margin: 10px auto;
  width: 100px;
}
</style>
