import PromotionLayout from '@/layout/PromotionLayout'

export default
  [{
    path: '/promotionLandpage',
    name: 'promotion-landpage',
    component: require('@/views/promotion/landpage').default,
    meta: {
      title: '会员活动',
      pageName: '自营会员推广落地页',
      page: 'promotion-landpage',
    }
  }, {
    path: '/promotion',
    name: 'promotion',
    component: PromotionLayout,
    children: [
      {
        path: '/promotion/card',
        name: 'promotion-card',
        component: require('@/views/promotion/card').default,
        meta: {
          title: '权益领取',
          pageName: '自营会员推广会员记录',
          page: 'promotion-card',
        }
      },
      {
        path: '/promotion/benefit',
        name: 'promotion-benefit',
        component: require('@/views/promotion/benefit').default,
        meta: {
          title: '权益领取',
          pageName: '自营会员推广会员权益列表',
          page: 'promotion-card',
        }
      }
    ]
  }]
