import { getUserBenefit } from "@/apis/home";
import { getIndexCategory, getIndexClassList } from "@/apis/product";
import { useStore } from "@/components/use/base";
import { ref } from "@vue/composition-api"
import Vue from "vue";

const vm = Vue.prototype;


// 查询用户卡信息 用户权益数据 拼接前台分类配置
export default function useMemberBenefitCategory() {
  const catesgorys = ref([]);
  const store = useStore();
  const card = ref({});
  const getProductList = async () => {
    const card_info = await getUserBenefit({
      // 需要筛选的卡历史状态,多个值以逗号分隔,。可选值： 待生效: wait,生效中: active,已退款: refund,已自然到期: expired
      card_status: vm.$getConfigValue(
        "USER_BENEFIT_STAT_CARD_LIST",
      ) || undefined
    });
    card.value = card_info.data;
    if (card_info.data.card_status === "active") {
      const [catesgory] = await Promise.all([
        getIndexCategory({
          namespace: store.state.namespace,
          opened_page: vm.$getConfigValue(
            "OPENED_PAGE_MEMBER",
            store.state.userInfo.card_type
          ),
          source: "default",
        }),
      ]);
      const catesgoryList = catesgory.data;
      if (!catesgoryList.length) {
        return;
      }
      const list = await getIndexClassList({
        title: catesgoryList.map((r) => r.name).join(","),
        opened_page: vm.$getConfigValue(
          "OPENED_PAGE_MEMBER",
          store.state.userInfo.card_type
        ),
        namespace: store.state.namespace,
      });
      catesgoryList.forEach((item) => {
        item.list = list.data[item.name]
          .map((item) => ({
            ...item,
            benefit: card_info.data.benefit_list.find(
              (r) => r.type === item.product_code
            ),
          }))
          .filter((item) => !!item.benefit);
      });
      catesgorys.value = catesgoryList.filter(
        (item) => item.list.length > 0
      );
    }
  };
  return {
    card: card,
    getProductList,
    catesgorys: catesgorys
  }
}