var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"coupon-detail"},[(_vm.showEmpty)?_c('van-empty',{attrs:{"description":"券码不存在，请联系客服"}}):_vm._e(),(!_vm.loading && !_vm.showEmpty)?_c('div',{staticClass:"showcode-main"},[(_vm.status === 'used')?_c('div',{staticClass:"used"}):_vm._e(),(_vm.status === 'expired')?_c('div',{staticClass:"expired"}):_vm._e(),_c('van-image',{staticClass:"showcode-logo",attrs:{"lazy-load":"","fit":"cover","src":_vm.info.logo_url}}),_c('div',{staticClass:"h40"}),_c('div',{class:("coupon-main coupon-main-" + (_vm.payInfo.coupon_format))},[_c('div',{staticClass:"coupon-name"},[_vm._v(_vm._s(_vm.info.item_name))]),(_vm.payInfo.coupon_format === 'kami')?_c('div',{staticClass:"coupon-code coupon-code-kami"},[_c('div',{staticClass:"coupon-code-item"},[_vm._v(" 卡号："+_vm._s(_vm.payInfo.coupon_code)+" "),_c('span',{staticClass:"copy",on:{"click":function($event){{
                _vm.copyCode(_vm.payInfo.coupon_code);
              }}}})]),_c('div',{staticClass:"coupon-code-item"},[_vm._v(" 卡密："+_vm._s(_vm.payInfo.secret_code)+" "),_c('span',{staticClass:"copy",on:{"click":function($event){{
                _vm.copyCode(_vm.payInfo.secret_code);
              }}}})])]):_vm._e(),(_vm.payInfo.coupon_format === 'single')?_c('div',{staticClass:"coupon-code"},[_c('div',{staticClass:"coupon-code-item"},[_vm._v(" "+_vm._s(_vm.payInfo.coupon_code)+" "),_c('span',{staticClass:"copy",on:{"click":function($event){{
                _vm.copyCode(_vm.payInfo.coupon_code);
              }}}})])]):_vm._e(),(
          ['qr_code', 'bar_code', 'qr_bar_code'].includes(
            _vm.payInfo.coupon_format
          )
        )?_c('div',{staticClass:"showcode-img"},[_c('div',{staticClass:"coupon-bar-code"},[_vm._v(_vm._s(_vm.payInfo.coupon_code))]),(
            _vm.payInfo.coupon_format === 'qr_code' ||
            _vm.payInfo.coupon_format === 'qr_bar_code'
          )?_c('qrcode-vue',{attrs:{"value":_vm.coupon_code + '',"size":120,"level":"H"}}):_vm._e(),(
            _vm.payInfo.coupon_format === 'bar_code' ||
            _vm.payInfo.coupon_format === 'qr_bar_code'
          )?_c('barcode',{attrs:{"value":_vm.coupon_code + '',"width":1}},[_vm._v(" 条形码生成失败，请联系客服 ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"coupon-expired"},[_vm._v("有效期至"+_vm._s(_vm.order.coupon_expire_date))])]),_c('div',{staticClass:"showcode-tips",domProps:{"innerHTML":_vm._s(_vm.info.tips)}}),_vm._m(0),_c('div',{staticClass:"showcode-explain"},[_c('div',{staticClass:"showcode-explain-title"},[_vm._v("- 使用条款 -")]),_c('div',{staticClass:"showcode-explain-content",domProps:{"innerHTML":_vm._s(_vm.info.instruction)}})])],1):_vm._e(),(_vm.status === 'unused' || _vm.payInfo.coupon_format === 'link')?[_c('div',{staticClass:"h50"}),_c('div',{staticClass:"code-btn-container"},[(_vm.status === 'unused')?_c('div',{staticClass:"code-btn",on:{"click":_vm.markUsed}},[_vm._v(" 标记为已使用 ")]):_vm._e(),(_vm.payInfo.coupon_format === 'link')?_c('div',{staticClass:"code-btn code-btn-primary",on:{"click":_vm.toUse}},[_vm._v(" 点击使用 ")]):_vm._e()])]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"showcode-line"},[_c('span',{staticClass:"semicircle"}),_c('span',{staticClass:"semicircle2"})])}]

export { render, staticRenderFns }