<template>
  <section class="aftermarket-record" v-if="!loading">
    <van-empty v-if="isEmpty" description="没有查询到售后记录" />
    <template v-else>
      <div class="aftermarket-card aftermarket-record-status">
        <div class="aftermarket-record-status-text">
          {{ flow.flow_status_cn }}
        </div>
        <p
          class="aftermarket-record-status-desc"
          v-html="SUBTITLE_ENUM[flow.flow_status]"
        ></p>
      </div>
      <div class="aftermarket-card aftermarket-record-timeline">
        <van-steps
          direction="vertical"
          :active="active"
          :center="true"
          active-color="#e02525"
        >
          <van-step
            class="timeline-item disabled"
            v-if="
              flow.flow_status === 'in_review' ||
              flow.flow_status === 'submit_application'
            "
          >
            <div class="timeline-step">
              {{ flow.handle_type !== "refund" ? "替换券码" : "同意退款" }}
            </div>
          </van-step>
          <van-step
            class="timeline-item"
            v-for="item in flow.flow_nodes"
            :key="item.id"
          >
            <div class="timeline-step">{{ item.name }}</div>
            <div class="timeline-time">
              {{ item.t_created | formatDate("YYYY.MM.DD HH:mm:ss") }}
            </div>
          </van-step>
          <template v-if="flow.first_commit_nodes.length">
            <template v-if="!expand">
              <van-step
                class="timeline-item"
                v-for="item in flow.first_commit_nodes"
                :key="item.id"
              >
                <div class="timeline-step">{{ item.name }}</div>
                <div class="timeline-time">
                  {{ item.t_created | formatDate("YYYY.MM.DD HH:mm:ss") }}
                </div>
              </van-step>
            </template>
            <template v-if="expand">
              <van-step class="timeline-item">
                <div class="timeline-step">
                  {{ flow.first_commit_nodes[0].name }}
                </div>
                <div class="timeline-time">
                  {{
                    flow.first_commit_nodes[0].t_created
                      | formatDate("YYYY.MM.DD HH:mm:ss")
                  }}
                </div>
              </van-step>
            </template>
          </template>
        </van-steps>
        <div
          v-if="flow.first_commit_nodes.length"
          class="expand"
          @click="expand = !expand"
        >
          <span :class="{ 'expand-icon': true, expanded: expand }"></span>
          {{ expand ? "查看完整进度" : "收起" }}
        </div>
      </div>
      <div class="aftermarket-card aftermarket-record-order">
        <div class="aftermarket-order-title">订单信息</div>
        <div class="aftermarket-order-col">订单名称：{{ order.name }}</div>
        <div class="aftermarket-order-col">
          订单编号：{{ order.order_number }}
        </div>
        <div class="aftermarket-order-col">
          下单时间：{{ order.t_created | formatDate("YYYY.MM.DD HH:mm:ss") }}
        </div>
        <div class="aftermarket-order-col">
          无法使用的原因：{{ flow.problem_type }}
        </div>
      </div>
      <div class="footer">
        <div
          v-ans="{
            event_id: 'page_aftersale_progress_cancel',
            order_number: order.order_number,
          }"
          v-if="
            flow.flow_status === 'in_review' ||
            flow.flow_status === 'submit_application'
          "
          class="footer-btn"
          @click="cancel"
        >
          取消申请
        </div>
        <div class="footer-btn" @click="toOrder">返回订单页</div>
      </div>
    </template>
  </section>
</template>

<script>
import { getOrder } from "@/apis";
import { Step, Steps } from "vant";
import { canWorkFlow, getWorkFlow } from "@/apis/product";
import { getProjectDivision } from "@/utils";
import { mapState } from "vuex";

const SUBTITLE_ENUM = {
  submit_application:
    "工作时间内预计<span>10分钟内</span>审核完成。非常感谢您的耐心等待，我们正在加紧处理",
  in_review:
    "工作时间内预计<span>10分钟内</span>审核完成。非常感谢您的耐心等待，我们正在加紧处理",
  refunded:
    "平台成功发起退款，预计1-3个工作日原路退回至您的支付账户，请您留意后续到账，如有疑问可咨询客服，感谢您的理解",
  updated: "审核通过，您的权益已更新，请返回详情页使用",
  cancel: "您已撤销此次申请，感谢您的理解，祝您生活愉快",
  reject:
    "很抱歉，平台核实到该权益仍属于<span>可使用状态</span>，故无法通过您的申请，感谢您的支持。如有疑问可咨询客服，祝您生活愉快",
};
export default {
  components: {
    [Step.name]: Step,
    [Steps.name]: Steps,
  },
  data() {
    return {
      order: {},
      loading: true,
      isEmpty: false,
      flow: {
        flow_nodes: [],
        first_commit_nodes: [],
      },
      SUBTITLE_ENUM,
      active: 0,
      expand: true,
    };
  },
  computed: {
    ...mapState(["namespace"]),
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      const loading = this.$loading();
      try {
        const { order_number } = this.$route.query;
        const res = await getOrder({ order_number });
        const flow = await getWorkFlow({
          order_number,
          project_division: getProjectDivision(this.namespace),
        });
        if (!flow.data.flow_nodes) {
          this.isEmpty = true;
        } else {
          this.flow = flow.data;
          const order = res.data.res_list[0];
          this.order = order;
          if (
            this.flow.flow_status === "submit_application" ||
            this.flow.flow_status === "in_review"
          ) {
            this.active = 1;
          }
        }
      } finally {
        loading.clear();
        this.loading = false;
      }
    },
    toOrder() {
      const { order_number, fromUrl } = this.$route.query;
      if (fromUrl) {
        location.href = `${decodeURIComponent(
          fromUrl
        )}/orderDetail?order_number=${order_number}`;
      } else {
        this.$router.push(`/orderDetail?order_number=${order_number}`);
      }
    },
    cancel() {
      this.$confirm({
        title: "温馨提示",
        message: "您确定要取消申请吗？",
      }).then(async () => {
        const { order_number, fromUrl } = this.$route.query;
        await canWorkFlow({
          order_number,
          work_order_no: this.flow.work_order_no,
          project_division: getProjectDivision(this.namespace),
        });
        this.$toast("取消申请成功");
        this.init();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.aftermarket-record {
  min-height: 100vh;
  background-color: #f8f8f8;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.aftermarket-card {
  background-color: #fff;
  box-shadow: 0px 0px 3px 0px rgba(153, 153, 153, 0.1);
  border-radius: 8px;
  padding: 12px;
  & + .aftermarket-card {
    margin-top: 12px;
  }
}

.aftermarket-record-status-text {
  padding-left: 28px;
  line-height: 22px;
  font-size: 18px;
  font-weight: bold;
  color: #222;
  margin-bottom: 12px;
  background: url("../../assets/images/aftermarket-wait.png") no-repeat left
    center / 16px;
}

.aftermarket-record-status-desc {
  padding-left: 28px;

  color: #333;
  line-height: 18px;
  /deep/ span {
    color: #e02929;
  }
}

.aftermarket-order-title {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 12px;
}

.aftermarket-order-col {
  line-height: 22px;
  color: #999;
}

.aftermarket-record-timeline {
  position: relative;
  /deep/ .van-step__circle {
    width: 10px;
    height: 10px;
    background-color: #e02525;
  }
  /deep/ .van-step__line {
    background-color: #e02525;
    margin-top: 9px;
  }
  /deep/ .van-icon-checked {
    color: transparent;
    background-color: #e02525;
    border-radius: 50%;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: -2px;
      top: -2px;
      right: -2px;
      bottom: -2px;
      border: 2px solid rgba(#e02525, 0.5);
      border-radius: 50%;
    }
  }
}

.timeline-step {
  font-size: 14px;
  color: #333;
  line-height: 22px;
  margin-bottom: 4px;
}

.timeline-time {
  line-height: 16px;
  color: #999;
}
.timeline-item {
  &.disabled {
    /deep/ .timeline-step {
      color: #999;
    }
    /deep/ .van-step__circle {
      background: #e1e1e1 !important;
    }
    /deep/ .van-step__circle-container {
      border-radius: 50%;
      background: #fff;
      border: 1px solid #e1e1e1;
      width: 10px;
      height: 10px;
      box-sizing: border-box;
    }
    /deep/ .van-step__line {
      background: #e1e1e1 !important;
    }
  }
  & + .timeline-item {
    margin-top: 12px;
  }
}

.footer {
  padding: 12px 24px;
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  margin: auto -12px -12px -12px;
}

.footer-btn {
  margin-left: 8px;
  line-height: 32px;
  height: 32px;
  text-align: center;
  padding: 0 16px;
  box-sizing: border-box;
  border: 1px dashed #999;
  color: #333;
  border-radius: 32px;
}

.expand {
  text-align: center;
  padding: 10px 0;
  color: #999;
}
.expand-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
  background: url("../../assets/images/unfold.png") no-repeat center / 100%;
  vertical-align: middle;
  transform: rotate(180deg);
  &.expanded {
    transform: rotate(0);
  }
}
</style>