<template>
  <section class="unlogin-home" v-if="!loading">
    <section class="home-header">
      <div class="home-header-inner">
        <div class="member-text">COCO PLUS</div>
        <div class="member-park">
          {{ namespaceInfo.name_cn }}
        </div>
      </div>
    </section>
    <div class="benefit">
      <div
        :class="`benefit-item benefit-item-${item.display_code}`"
        v-for="item in catesgorys"
        :key="item.id"
      >
        <div
          :class="`benefit-item-inner benefit-item-inner-${item.display_code}`"
        >
          <div class="benefit-item-title">
            {{ item.name }}
          </div>
          <div class="benefit-spu-list">
            <div
              :class="{
                'benefit-spu': true,
                'benefit-spu-inline': spu.is_cube,
              }"
              v-ans="{
                event_id: 'benefit_list_click',
                sequence: idx + 1,
                spu_id: spu.id,
                spu_name: spu.desc,
                namespace,
              }"
              v-for="(spu, idx) in item.list"
              :key="spu.product_code"
            >
              <div
                class="benefit-spu-title"
                v-if="
                  (item.display_code === 'travel' ||
                    item.display_code === 'life') &&
                  spu.desc
                "
              >
                {{ spu.desc }}
                <div
                  v-if="
                    spu.generate_h5 === 'hotel' &&
                    item.display_code === 'travel'
                  "
                  class="hotel-sellpoint"
                >
                  {{ spu.selling_points }}
                </div>
              </div>

              <hotel
                v-if="
                  spu.generate_h5 === 'hotel' && item.display_code === 'travel'
                "
                :specialHotel="specialHotel"
              />
              <div 
                v-else 
                @click="toDetail(spu)"
                v-ans="{
                  event_id: 'busuness_benefit_click',
                  namespace,
                  spu_id: spu.id
                }"
              >
                <van-image
                  class="benefit-image"
                  lazy-load
                  v-if="
                    item.display_code === 'icon'
                      ? spu.icon_img_url
                      : spu.display_img_url
                  "
                  :src="
                    item.display_code === 'icon'
                      ? spu.icon_img_url
                      : spu.display_img_url
                  "
                />
                <p v-if="item.display_code === 'icon'" class="benefit-desc">
                  {{ spu.desc }}
                </p>
                <p v-if="item.display_code === 'icon'" class="benefit-selling">
                  {{ spu.selling_points }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="customer" @click="$router.push('/service')">
        <div class="customer-title">如您对COCO PLUS还有疑问？</div>
        <div class="customer-subtitle">可以点击咨询在线客服</div>
      </div>
    </div>
    <div class="fixed-height"></div>
    <div class="fixed-footer">
      <van-button disabled block round color="#999"> 活动已结束 </van-button>
    </div>
  </section>
</template>

<script>
import {
  getIndexCategory,
  getIndexClassList,
  getPackageList,
} from "@/apis/product";
import { mapState } from "vuex";
import benefitMixin from "@/mixins/benefitMixin";
import ans from "@/utils/ans";
import { getSpecialHotel } from "@/apis";
import hotel from "./hotel.vue";

export default {
  components: { hotel },
  mixins: [benefitMixin],
  data() {
    return {
      catesgorys: [],
      list: [],
      loading: true,
      packageItem: {},
      specialHotel: {
        fifty_off_hotel_info: [],
        special_hotel_info: [],
      },
    };
  },
  computed: {
    ...mapState(["namespace", "source", "namespaceInfo"]),
  },
  async mounted() {
    ans.pageView("page_visitor", {
      namespace: this.namespace,
      source: this.source,
    });
    await Promise.all([
      this.getProductList(),
      this.fetchPackages(),
      this.fetchSpecialHotel(),
    ]);
  },
  methods: {
    // 获取套餐
    async fetchPackages() {
      const res = await getPackageList({
        namespace: this.namespace,
        source: "default",
      });
      const packages = res.data;
      packages.reverse();
      if (packages.length) {
        this.packageItem = packages[0];
      }
    },
    async fetchSpecialHotel() {
      const res = await getSpecialHotel();
      this.specialHotel = res.data;
    },
    // 请求首页数据
    async getProductList() {
      const loading = this.$loading();
      try {
        const catesgory = await getIndexCategory({
          namespace: this.namespace,
          opened_page: "visitor",
          source: "default",
        });

        const catesgorys = catesgory.data.filter((r) => r.status === 1);
        const list = await getIndexClassList({
          title: catesgorys.map((r) => r.name).join(","),
          opened_page: "visitor",
          namespace: this.namespace,
        });
        catesgorys.forEach((item) => {
          const temp = list.data[item.name].filter((r) => r.status === 1);
          const index = temp.findIndex(
            (k) => k.generate_h5 === "yioil" && item.display_code === "travel"
          );
          if (index > -1) {
            const item = temp[index];
            temp.splice(index, 0, { desc: item.desc });
            item.desc = "";
          }

          item.list = temp;
        });
        this.catesgorys = catesgorys;
      } finally {
        this.loading = false;
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.unlogin-home {
  background-color: #171a24;
}
.benefit {
  padding: 0 5px;
}
.home-header-inner {
  height: 180px;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  background: url("../../../../assets/images/member_bg.png") no-repeat;
  background-size: 100%;
  box-sizing: border-box;
  padding-top: 76px;
}
.member-text {
  font-size: 32px;
  line-height: 45px;
  margin-bottom: 14px;
  color: #f9cdaf;
}
.member-park {
  padding: 0 6px 0 6px;
  line-height: 25px;
  color: #9d8276;
  border: 1px solid currentColor;
  font-size: 12px;
  margin: 0 auto;
  width: max-content;
  border-radius: 25px;
  position: relative;
  box-sizing: border-box;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   right: 6px;
  //   top: 10px;
  //   border-left: 5px solid transparent;
  //   border-right: 5px solid transparent;
  //   border-top: 5px solid #9d8276;
  // }
}

.benefit-item {
  margin-bottom: 10px;
  .benefit-item-title {
    color: #f9cdaf;
    line-height: 22px;
    font-size: 22px;
    margin-bottom: 10px;
    padding-left: 10px;
    margin-top: 25px;
  }
}
.hotel-sellpoint {
  font-size: 14px;
  color: #f9cdaf;
  line-height: 20px;
}
.benefit-spu-list {
  background: #20222f;
  border-radius: 10px;
  padding: 15px 10px;
  margin-right: -5px;
}
.benefit-desc {
  color: #fff;
}
.fixed-footer {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px -1px 0px 0px #ebecf1;
  padding: 8px 20px;
  box-sizing: border-box;
  padding-bottom: calc(8px + constant(safe-area-inset-bottom));
  padding-bottom: calc(8px + env(safe-area-inset-bottom));
}
.to-open {
  background: url("../../../../assets/images/unlogin_btn_bg.png") no-repeat;
  background-size: 100%;
  height: 49px;
  display: flex;
  align-items: center;
  text-align: center;
  .package-info {
    opacity: 0.7;
  }
  .package-facevalue {
    text-decoration: line-through;
    opacity: 0.7;
  }
  .package-pri {
    padding: 0 3px;
    font-size: 24px;
    small {
      font-size: 0.5em;
    }
  }
  .package-price {
    flex: 6;
    color: #f9cdaf;
  }
  .open-text {
    flex: 5;
    color: #1a1a1a;
    font-size: 18px;
    font-weight: bold;
  }
}
.fixed-height {
  height: 85px;
}

.customer {
  height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  background: url("../../../../assets/images/customer_icon.png") no-repeat 10px
    center;
  background-size: 30px;
  position: relative;
  background-color: #20222f;
  border-radius: 10px;
  &::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 25px;
    width: 6px;
    height: 12px;
    background: url("../../../../assets/images/icon-right.png") no-repeat;
    background-size: 100%;
  }
}
.customer-title {
  line-height: 21px;
  font-size: 15px;
  color: #fff;
}
.customer-subtitle {
  line-height: 18px;
  font-size: 12px;
  color: #999;
}

.benefit-image {
  min-height: 50px;
}
.benefit-spu {
  margin-right: 5px;
  &.benefit-spu-inline {
    display: inline-block;
    width: calc(33.33333% - 5px);
  }
}
.benefit-item-inner-coco_benefits01 {
  .benefit-spu-list {
    display: flex;
    margin: 0 -2px;
  }
  .benefit-spu {
    flex: 1;
    margin-top: 0 !important;
    margin-left: 2px;
    margin-right: 2px;
  }
}

.benefit-item-inner-icon {
  .benefit-spu-list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0;
    margin-right: 0;
  }
  .benefit-spu {
    width: 25%;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0 !important;
    margin-right: 0;
  }
  .benefit-image {
    width: 36px;
    height: 36px;
    min-height: 36px;
    display: block;
    margin: 0 auto 4px;
    border-radius: 50%;
    overflow: hidden;
  }
  .benefit-selling {
    margin-top: 2px;
    font-size: 10px;
    color: #999;
  }
}
.benefit-spu + .benefit-spu {
  margin-top: 10px;
}

.benefit-item-member_gift {
  .benefit-spu-list {
    display: flex;
  }
  .benefit-spu {
    flex: 1;
    margin-top: 0;
    &:first-child {
      margin-right: 5px;
    }
  }
}

.benefit-item-inner-travel,
.benefit-item-inner-life {
  .benefit-spu {
    background: #ffefd7;
    border-radius: 5px;
    & + .benefit-spu {
      border-top: 1px solid #292c3d;
      margin-top: 16px;
    }
  }
  .benefit-spu-title {
    line-height: 25px;
    font-size: 16px;
    padding: 15px 0;
  }
  .benefit-spu-inline {
    width: calc(50% - 5px);
    margin-top: 0 !important;
  }
}
.benefit-image {
  img[lazy="loading"] {
    max-height: 100px;
  }
}

.benefit-item-inner-travel,
.benefit-item-inner-life {
  .benefit-spu-list {
    padding-top: 0;
  }
  .benefit-spu-title {
    text-align: center;
    color: #f9cdaf;
  }
  // .benefit-spu-list {
  //   background: #fef2e6;
  //   border-radius: 8px;
  //   border: 1px solid #ffbc7b;
  //   padding: 0 5px 5px;
  // }
  .benefit-spu {
    margin-top: 0;
    background: transparent;
  }
}
</style>