<template>
  <div class="media-wrap" v-if="!loading">
    <div class="title">{{ info.name }}</div>
    <div class="desc">{{ info.desc }}</div>
    <div class="content">
      <img :src="info.display_img_url" />
      <div
        class="btn"
        v-if="pageType !== 'member' || activeCard === 'licheng6'"
      >
        立即领取
      </div>
      <div class="btn-wrap" v-else>
        <div
          class="btn"
          @click="draw(item)"
          v-for="item of productInfo"
          :key="item.id"
        >
          {{ btnText(item) }}
        </div>
      </div>
      <div class="tip">13选1</div>
    </div>
  </div>
</template>

<script>
import { getBenefitInfo } from "@/apis";

export default {
  props: ["info", "canUseBenefit", "handleDraw", "activeCard", "pageType"],
  data() {
    return {
      productInfo: [],
      loading: false,
    };
  },
  computed: {
    btnText() {
      return function (item) {
        return `${item.desc}（剩${item.amount}次）`;
      };
    },
  },
  methods: {
    draw(item) {
      if (!this.canUseBenefit) return;
      this.handleDraw(item);
    },
    async init() {
      this.loading = true;
      const benefitArr = await Promise.all(
        this.info.productInfo.map(async (item) => {
          const { data } = await getBenefitInfo({
            benefit_type: item.product_code,
          });
          return {
            ...item,
            amount: data.amount_available + data.amount_reserved,
          };
        })
      );
      this.productInfo = benefitArr;
      this.loading = false;
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style scoped lang="less">
.media-wrap {
  height: 250px;
  width: auto;
  box-sizing: border-box;
  background: #fff;
  border-radius: 16px;
  margin-top: 12.5px;
  padding: 9px 14px 15px 14px;

  .title {
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #333333;
  }

  .desc {
    margin-top: 3px;
    font-size: 11px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #afb2b5;
  }

  .content {
    overflow: hidden;
    position: relative;
    background: #fffbf8;
    padding: 27px 11px 21px 11px;
    border-radius: 8px;
    margin-top: 8px;
    > img {
      width: 100%;
    }

    .btn-wrap {
      display: flex;
      justify-content: space-around;
      > .btn {
        width: 140px;
      }
    }

    .btn {
      margin-top: 22.5px;
      height: 24px;
      background: #fef3ed;
      border: 1px solid rgba(230, 154, 123, 1);
      text-align: center;
      border-radius: 8px;
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #e69a7b;
      line-height: 24px;
    }

    .tip {
      position: absolute;
      font-size: 9px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #6e2400;
      padding: 1px 6px;
      background: linear-gradient(90deg, #f6d6ba 0%, #e9ac8c 100%);
      border-radius: 0 0 0 8px;
      top: 0;
      right: 0;
    }
  }
}
</style>
