
export default [
  {
    path: '/h5Benefit/kaishu',
    name: 'h5Benefit_kaishu',
    component: require('@/views/h5Benefit/kaishu').default,
    meta: {
      title: '凯叔讲故事',
      pageName: '凯叔讲故事',
      page: 'page_h5Benefit_kaishu',

    }
  }, {
    path: '/h5Benefit/detail',
    name: 'h5Benefit_detail',
    component: require('@/views/h5Benefit/detail').default,
    meta: {
      title: '权益详情',
      pageName: '权益详情',
      page: 'page_h5Benefit_detail',

    }
  }, {
    path: '/h5Benefit/iqy',
    name: 'h5Benefit_iqy',
    component: require('@/views/h5Benefit/iqy').default,
    meta: {
      title: '领取爱奇艺会员',
      pageName: '领取爱奇艺会员',
      page: 'h5Benefit_iqy',

    }
  }]