<template>
  <main class="order-detail">
    <div class="order-status">
      <div class="order-status-flex">
        <div class="order-status-name">
          <svg-icon class="park-success-icon" icon-class="hotel-success" />{{
            statusText
          }}
        </div>
      </div>
    </div>

    <div class="payment-info">
      <div class="title">
        {{ park.name }}1日票
        <div class="residual-price">¥{{ order.amount }}</div>
      </div>
      <div class="flex-cell flex-cell-price">
        <div class="label">{{ payInfo.ticket_name }}</div>
        <div
          v-if="payInfo.coupon_real_price"
          class="value"
          style="text-decoration: line-through"
        >
          ¥ {{ payInfo.raw_total_fee }} x1
        </div>
        <div v-else class="value">¥ {{ order.amount }} x1</div>
      </div>
      <div
        v-if="payInfo.coupon_real_price"
        class="flex-cell flex-cell-preferential"
      >
        <div class="label coupon-label">优惠</div>
        <div class="value">
          <span>会员优惠价 ¥{{ payInfo.total_fee }}</span>
        </div>
      </div>
    </div>
    <div
      class="ticket-container"
      v-if="payInfo.tickets_url && order.status === 'success'"
    >
      <a
        v-ans="{ event_id: 'order_detail_click_viewticket' }"
        class="ticket-url"
        :href="payInfo.tickets_url"
        >查看电子票</a
      >
    </div>
    <div class="park-card">
      <div class="park-container">
        <router-link
          class="park-info"
          :to="`/offlineBenefit/park/detail?id=${park.id}`"
        >
          <van-image
            lazy-load
            class="park-image"
            v-if="getImageUrl"
            :src="getImageUrl"
          />
          <div class="park-content">
            <div class="order-product-desc ellipsis">
              {{ park.name }}
            </div>
            <div class="park-address">{{ park.address }}</div>
          </div>
          <svg-icon icon-class="arrow2" class="park-arrow" />
        </router-link>
        <div class="park-map">
          <div class="park-map-item" @click="toMap">
            <svg-icon iconClass="map-route-icon" />路线导航
          </div>
          <div class="park-map-item" @click="toService">
            <svg-icon iconClass="map-service" />联系客服
          </div>
        </div>
      </div>
      <div class="park-book-info">
        <van-row class="cell">
          <van-col class="cell-label" :span="5">预订日期</van-col>
          <van-col class="cell-value" :span="19">
            {{ payInfo.checkin_date | formatDate("YYYY年MM月DD日") }}
          </van-col>
        </van-row>
        <van-row class="cell">
          <van-col class="cell-label" :span="5">姓名</van-col>
          <van-col class="cell-value" :span="19">
            {{ payInfo.user }}
          </van-col>
        </van-row>
        <van-row class="cell">
          <van-col class="cell-label" :span="5">身份证</van-col>
          <van-col class="cell-value" :span="19">{{
            payInfo.id_card | maskStr
          }}</van-col>
        </van-row>
        <van-row class="cell">
          <van-col class="cell-label" :span="5">联系电话</van-col>
          <van-col class="cell-value" :span="19">{{
            payInfo.ticket_mobile | maskStr
          }}</van-col>
        </van-row>
        <div class="line" />
        <van-row class="cell">
          <van-col class="cell-label" :span="5">订单编号</van-col>
          <van-col class="cell-value" :span="19">
            {{ detail.order_number }}
            <span
              round
              size="mini"
              class="copy"
              v-clipboard:copy="detail.order_number"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
              v-ans="{
                event_id: 'order_detail_click_copyorder',
                order_id: detail.order_number,
              }"
              >复制</span
            >
          </van-col>
        </van-row>
        <van-row class="cell">
          <van-col class="cell-label" :span="5">下单时间</van-col>
          <van-col class="cell-value" :span="19">{{ orderTime }}</van-col>
        </van-row>
      </div>
    </div>
    <div class="order-notice">
      <switch-text :max-height="150">
        <div class="notice-content" v-html="park.rule"></div>
      </switch-text>
    </div>
    <div class="height60"></div>
    <div class="order-footer">
      <template>
        <van-button
          v-if="status === 'unpaid'"
          class="order-footer-button cancel"
          size="small"
          @click="payAgain"
          round
          v-ans="{
            event_id: 'order_detail_click_cancel',
            order_id: detail.order_number,
            order_type: detail.type,
          }"
          >继续支付</van-button
        >
        <van-button
          class="order-footer-button"
          round
          v-if="showIndex"
          @click="toIndex"
          v-ans="{
            event_id: 'order_detail_click_back',
            order_id: detail.order_number,
            order_type: detail.type,
            order_time: detail.t_created,
            namespace,
          }"
        >
          返回首页
        </van-button>
        <van-button
          v-if="!isMiniProgram"
          class="order-footer-button"
          size="small"
          round
          @click="toService"
          v-ans="{
            event_id: 'hotel_order_service_button',
            order_id: detail.order_number,
            namespace,
          }"
          >联系客服</van-button
        >
      </template>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dayjs from "dayjs";
import { qqMapTransBMap } from "@/utils";
import qs from "qs";
import SwitchText from "@/components/SwitchText";
import { getParkDetail } from "@/apis/park";

export default {
  props: ["order", "system_date", "notice", "toIndex", "showIndex"],
  components: {
    SwitchText,
  },
  data() {
    return {
      detail: this.order,
      loading: true,
      count: 0,
      payInfo: JSON.parse(this.order.pay_info),
      itemInfo: {},
      explain: "",
      park: {},
    };
  },

  async mounted() {
    await this.getHotelDays();
    const res = await getParkDetail(this.payInfo.park_id);
    this.park = res.data;
  },

  methods: {
    ...mapActions(["getHotelDays"]),
    copySuccess() {
      this.$toast("复制成功");
    },
    copyError() {
      this.$toast("复制失败,请手动复制");
    },
    async toService() {
      this.$router.push("/service");
    },

    payAgain() {
      const url = `/pages/pay/pay?${qs.stringify({
        order_number: this.detail.order_number,
        item_name: this.detail.name,
        price: this.detail.amount,
        item_code: this.payInfo.item_code,
        type: this.detail.type,
      })}`;
      this.$router.push(url);
    },

    async toMap() {
      if (this.isMiniProgram || this.isWebchat) {
        wx.ready(() => {
          wx.openLocation({
            name: this.park.name,
            latitude: parseFloat(this.park.latitude),
            longitude: parseFloat(this.park.longitude),
          });
        });
        return;
      }
      const tude = qqMapTransBMap(this.park.longitude, this.park.latitude);
      window.location.href = `http://api.map.baidu.com/marker?location=${tude.latitude},${tude.longitude}&title=${this.park.name}&output=html`;
    },
  },
  computed: {
    ...mapState([
      "namespace",
      "isMiniProgram",
      "isWebchat",
      "return_index_url",
    ]),
    ...mapGetters(["namespaceGroup"]),
    ...mapGetters(["isHoliday"]),
    getImageUrl() {
      return this.park.images?.[0].url;
    },

    status() {
      return this.detail.status;
    },

    statusText() {
      const temp = {
        success: "已成功",
        failed: "已失败",
        confirming: "已支付，确认中",
        unpaid: "待支付",
        canceled: "已取消",
        refund: "已退款",
        unCheckin: "已确认，待入住",
        checkin: "已完成",
      };

      return temp[this.status];
    },

    imageEnum() {
      const imageEnum = {
        success: "success",
        failed: "cancel",
        confirming: "success",
        unpaid: "unpay",
        canceled: "cancel",
        refund: "cancel",
      };
      return imageEnum;
    },
    orderTime() {
      return dayjs(this.detail.t_created).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>

<style lang="less" scoped>
.payment-info {
  background: #ffffff;
  padding: 18px 16px;
  border-radius: 12px 12px 0px 0px;
  margin-bottom: 12px;
  & + & {
    margin-top: 12px;
  }
  .title {
    line-height: 24px;
    font-size: 17px;
    font-weight: bold;
    display: flex;
  }
  .residual-price {
    margin-left: auto;
    display: flex;
    line-height: 24px;
    color: #fc4f10;
    font-size: 17px;
    font-weight: bold;
  }
}
.flex-cell-price {
  margin-top: 18px;
}
.flex-cell {
  display: flex;
  justify-content: space-between;
  line-height: 18px;
  & + & {
    margin-top: 13px;
  }
  &.flex-cell-fax {
    margin-top: 17px;
    padding-top: 13px;
    border-top: 1px solid #f9f9f9;
    margin-bottom: 0;
  }
  .label {
    color: #888888;
    font-size: 13px;
    flex-basis: 120px;
  }
  .value {
    font-size: 12px;
    color: #999;
    text-align: right;
    span {
      color: #fc4f10;
    }
  }
}

.coupon-label {
  &::before {
    content: "券";
    background: #fc4f10;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    color: #fff;
    margin-right: 4px;
    text-align: center;
    line-height: 16px;
    font-size: 11px;
  }
}

.order-status {
  padding: 19px 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.order-status-name {
  line-height: 30px;
  font-size: 22px;
  color: #333;
  font-weight: bold;
}
.park-success-icon {
  margin-right: 4px;
}
.order-status-desc {
  margin-top: 5px;
  font-size: 13px;
  color: #477b6c;
  line-height: 18px;
}
.order-status-flex {
  display: flex;
  align-items: center;
  .book-park {
    margin-left: auto;
    width: 56px;
    height: 21px;
    color: #ff5001;
    border: 1px solid currentColor;
    line-height: 21px;
    font-size: 10px;
    border-radius: 20px;
    text-align: center;
    box-sizing: border-box;
  }
}
.cancel-container {
  padding: 20px 16px;
  margin: 0 12px 20px;
  background: #ffffff;
  border-radius: 12px;
  font-size: 13px;

  .cancel-title {
    display: flex;
    align-items: center;
    line-height: 32px;
    color: #222;
    font-weight: bold;
  }
  .cancel-btn {
    height: 32px;
    width: 77px;
    margin-left: auto;
    padding: 0;
    font-size: 12px;
    background: linear-gradient(90deg, #ff650d 0%, #ff5f4a 100%);
    color: #fff;
  }
  .desc {
    line-height: 18px;
    padding-top: 16px;
    color: #477b6c;
    border-top: 1px solid #f9f9f9;
    margin-top: 17px;
  }
}

.park-card {
  padding: 20px;
  background: #fff;
}

.park-info {
  display: flex;
  margin-bottom: 12px;
  .park-image {
    width: 49px;
    height: 41px;
    flex-shrink: 0;
    margin-right: 10px;
    border-radius: 8px;
    overflow: hidden;
  }
  .order-product-desc {
    padding-top: 2px;
    line-height: 18px;
    margin-bottom: 3px;
    font-size: 13px;
    color: #222;
  }
  .park-address {
    line-height: 16px;
    color: #888;
    font-size: 11px;
  }
}

.park-content {
  flex: 1;
  overflow: hidden;
}

span.park-info {
  .park-arrow {
    display: none;
  }
}
.park-arrow {
  color: #333;
  margin-top: 4px;
}

.park-map {
  height: 38px;
  border-radius: 4px;
  background: #fbf9fc;
  display: flex;
  align-items: center;
  color: #333;
  font-size: 12px;

  .park-map-item {
    flex: 1;
    text-align: center;
    &:first-child {
      border-right: 1px solid #eaeaea;
    }
  }
  .svg-icon {
    margin-right: 4px;
    font-size: 14px;
    vertical-align: -3px;
  }
}

.line {
  margin-bottom: 19px;
  height: 1px;
  background: #f9f9f9;
}

.park-container {
  padding-bottom: 20px;
  border-bottom: 1px solid #f9f9f9;
}

.order-info-item {
  line-height: 22px;
  color: @text-gray2-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-info {
  padding-top: 19px;
  border-top: 1px solid #f9f9f9;
}
.copy {
  display: block;
  width: 38px;
  text-align: center;
  line-height: 19px;
  color: #999 !important;
  border: 1px solid #d2d2d2;
  border-radius: 16px;
  box-sizing: border-box;
  font-size: 10px;
  float: right;
}

.order-card {
  background: #fff;
  padding: 15px;
  margin-bottom: 10px;
}

.park-book-info {
  padding-top: 19px;
  .cell {
    line-height: 18px;
    font-size: 13px;
    &:not(:last-child) {
      margin-bottom: 13px;
    }
  }
}
.cell-label {
  color: #999;
}

.cell-value {
  color: #333;
  span {
    color: #aaaaaa;
  }
}

.order-notice {
  margin-top: 12px;
  font-size: 11px;
  padding: 20px;
  background: #fff;
}
.height60 {
  height: 60px;
}
.notice-item + .notice-item {
  margin-top: 20px;
}
.notice-title {
  margin-bottom: 4px;
  font-size: 13px;
  color: #333;
}
.notice-content {
  line-height: 18px;
  color: #999;
  font-size: 11px;
}
.order-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  button {
    &:first-child {
      margin: 10px 0;
    }
  }
}
.order-footer-button {
  margin-left: 15px;
  font-size: 12px;
  height: 25px;
  color: #333;
  border-color: #000;
}

.ticket-url {
  color: #ff5001;
  font-size: 12px;
}

.ticket-container {
  margin-bottom: 7px;
  margin-top: -6px;
  height: 54px;
  background: url("~@/assets/images/park_ticket_bg.png") no-repeat center/cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  a {
    line-height: 17px;
    padding: 4px 11px;
    color: #fff;
    font-size: 12px;
    background: linear-gradient(90deg, #fa8679 0%, #f54e62 100%);
    border-radius: 13px;
  }
}
</style>
