var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("quanmin quanmin_" + _vm.namespace)},[_c('div',{staticClass:"entry-block",on:{"click":_vm.toBuy}}),_c('div',{staticClass:"page-benefits"},_vm._l((_vm.catesgoryList),function(item){return _c('div',{key:item.display_code,class:("page-benefit page-benefit-" + (item.display_code))},_vm._l((item.list),function(spu){return _c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'quanmin_spu_click',
          spu_id: spu.id,
          namespace: _vm.namespace,
        }),expression:"{\n          event_id: 'quanmin_spu_click',\n          spu_id: spu.id,\n          namespace,\n        }"}],key:spu.id,staticClass:"page-benefit-spu",on:{"click":function($event){return _vm.clickHandle(spu)}}},[_c('van-image',{staticClass:"qm-image",attrs:{"lazy-load":"","fit":"cover","src":spu.display_img_url}})],1)}),0)}),0),(_vm.memberPackage.item_code)?_c('div',{staticClass:"fixed-height"}):_vm._e(),(_vm.memberPackage.item_code)?_c('div',{staticClass:"page-footer"},[_c('div',{staticClass:"agreement"},[_vm._v(" 开通即同意"),_c('span',{staticClass:"agreement-text",on:{"click":_vm.jumpAgreement}},[_vm._v("《"+_vm._s(_vm.aggrement)+"服务协议》")])]),(
        _vm.namespace === 'ydh1' ||
        _vm.namespace === 'ydh2' ||
        _vm.namespace === 'qmydh1' ||
        _vm.namespace === 'qmwallet1' ||
        _vm.namespace === 'qmqb1' ||
        _vm.namespace === 'qmwallet2'
      )?_c('div',{staticClass:"package"},[_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'visitor_click_buy', namespace: _vm.namespace }),expression:"{ event_id: 'visitor_click_buy', namespace }"}],staticClass:"ydh-facevalue",on:{"click":_vm.toBuy}}),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'visitor_click_buy', namespace: _vm.namespace }),expression:"{ event_id: 'visitor_click_buy', namespace }"}],staticClass:"ydh-price",on:{"click":_vm.toBuy}}),_c('div',{staticClass:"ydh-count-dwon"},[_vm._v(" 秒杀中： "),_c('van-count-down',{ref:"countdown",staticClass:"count-down",attrs:{"time":_vm.countDown,"format":"HH:mm:ss"},on:{"finish":_vm.finishCountDown}})],1)]):_c('div',{staticClass:"package"},[_c('div',{staticClass:"package-discount"},[_c('div',{staticClass:"package-discount-tag"}),_c('div',{staticClass:"package-face-value"},[_vm._v(" 原价¥"+_vm._s(_vm.memberPackage.face_value)+" ")])]),_c('div',{staticClass:"package-price"},[_vm._v(" ¥"+_vm._s(_vm.memberPackage.price)+" "),_c('span',{staticClass:"package-price-unit"},[_vm._v("/"+_vm._s(_vm.memberPackage.unit))])]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'visitor_click_buy', namespace: _vm.namespace }),expression:"{ event_id: 'visitor_click_buy', namespace }"}],staticClass:"package-btn",on:{"click":_vm.toBuy}})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }