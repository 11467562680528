<template>
  <section class="hotel-book" v-if="!loading">
    <div class="hotel-detail card-info">
      <div v-if="showHotelBack" class="hotel-back" @click="$router.back()" />
      <div class="hotel-room">
        <div class="hotel-name ellipsis">{{ hotelBook.hotel_name }}</div>
        <van-image
          class="hotel-image"
          fit="cover"
          lazy-load
          :src="hotelBook.hotel_image_url"
        />
      </div>
      <div class="hotel-checkin">
        <div class="hotel-checkin-text">
          {{ hotel.checkin_date | formatDate("MM月DD日") }}
          {{ hotel.checkin_date | week }} -
          {{ hotel.checkout_date | formatDate("MM月DD日") }}
          {{ hotel.checkout_date | week }}
          <span class="split"></span>
          {{ interNight }}晚
        </div>
      </div>
      <div class="hotel-info">
        {{ hotelBook.room_name }}·{{ hotelBook.breakfast }}
        <span v-if="hotelBook.pay_method === 1">不可取消</span>
      </div>
      <div v-if="hotelBook.pay_method === 0" class="cancel-tag">
        {{ cancelTime }}16:00前可免费取消
      </div>
    </div>
    <div class="hotel-book-info card-info">
      <div class="title">入住信息</div>
      <div class="hotel-night">
        <van-row class="hotel-night-cell">
          <van-col class="cell-label" :span="5">入住房数</van-col>
          <van-col class="cell-text" :span="19"
            >1间{{ hotelBook.pay_method == 0 ? "（需担保）" : "" }}</van-col
          >
        </van-row>
        <div
          :class="{
            'night-tips': true,
            [`night-tips-${hotelBook.pay_method}`]: true,
          }"
        >
          <svg-icon class="info-icon" iconClass="info" />
          {{
            hotelBook.pay_method == 0
              ? "因房量紧张，平台需您支付预订担保金，入住后将返还"
              : "如需预订多个房间，请再下一笔订单，仅限本人入住"
          }}
        </div>
      </div>
      <van-row class="cell">
        <van-col class="cell-label" :span="5">住客姓名</van-col>
        <van-col class="cell-value" :span="19">
          <van-field
            rows="1"
            autosize
            className="hotel-order-input"
            placeholder="请填写本人姓名，后续入住将默认为本人"
            v-model="cn_name"
            :disabled="inputDisabled"
          />
        </van-col>
      </van-row>
      <van-row class="cell">
        <van-col class="cell-label" :span="5">联系手机</van-col>
        <van-col class="cell-value" :span="19">
          <van-field
            rows="1"
            autosize
            className="hotel-order-input"
            placeholder="请填写本人手机号"
            maxlength="11"
            v-model="phone_number"
            :disabled="inputDisabled"
          />
        </van-col>
      </van-row>
      <van-row v-if="hotelBook.pay_method == 0" class="cell">
        <van-col class="cell-label" :span="5">常旅客号</van-col>
        <van-col class="cell-value" :span="19">
          <van-field
            rows="1"
            autosize
            className="hotel-order-input"
            placeholder="请在入住时与前台确认，确保获取积分"
            maxlength="15"
            v-model="passenger_number"
            type="textarea"
          />
        </van-col>
      </van-row>
      <van-row v-if="hotelBook.pay_method == 0" class="cell">
        <van-col class="cell-label" :span="5">备注</van-col>
        <van-col class="cell-value" :span="19">
          <van-field
            rows="1"
            className="hotel-order-input"
            v-model="remarks"
            autosize
            placeholder="需求转达至酒店，尽量安排但无法保证"
            type="textarea"
          />
        </van-col>
      </van-row>
    </div>
    <div class="card-info residual">
      <div class="title">
        {{ hotelBook.pay_method == 0 ? "到店需付房费" : "账单明细" }}
        <div class="residual-price">
          <van-image
            v-if="!$getConfigValue('HOTEL_SINGLE_BENEFIT')"
            class="tag"
            :src="require('@/assets/images/hotel2/hotel-book-price-tag.png')"
          />
          ¥{{ residualPrice }}
        </div>
      </div>
      <div class="flex-cell flex-cell-price">
        <div class="label">房费</div>
        <div class="value">¥{{ hotelBook.total_origin_price }}</div>
      </div>
      <div class="flex-cell flex-cell-preferential">
        <div class="label">
          {{ !$getConfigValue("HOTEL_SINGLE_BENEFIT") ? "优惠" : "优惠券" }}
        </div>
        <div class="value">
          -¥{{ hotelBook.total_origin_price - residualPrice }}
        </div>
      </div>
      <div class="flex-cell flex-cell-fax">
        <div class="label">发票</div>
        <div class="value">
          {{
            hotelBook.pay_method == 0
              ? "离店时，请与酒店前台咨询开发票"
              : "只提供电子增值税普通发票，离店后一个月内可联系本会员的人工客服开具发票"
          }}
        </div>
      </div>
    </div>

    <div
      v-if="couponList.length"
      class="card-info"
      @click="visibleCoupon = true"
    >
      <van-row type="flex" align="center">
        <van-col :span="7">优惠券</van-col>
        <van-col class="coupon-price">
          <span v-if="couponId" class="coupon-amount">
            -¥{{ couponAmount }}
          </span>
          <div v-else class="coupon-numbers">
            {{ couponList.length }}张优惠券
          </div>
        </van-col>
        <van-col>
          <svg-icon class="coupon-arrow" iconClass="arrow" />
        </van-col>
      </van-row>
    </div>
    <div class="card-info">
      <div class="title">预订须知</div>
      <div class="book-tips">
        <div class="h4">确认时长</div>
        <div class="tips" v-if="holiday">
          <svg-icon
            class="info-icon"
            icon-class="info"
          />法定节假日预订：订单确认时间延长至24小时内
        </div>
        <div class="desc" v-if="holiday">
          法定节假日，当地法定节假日及会展期间等，酒店营业高峰期，订单确认时间延长，请耐心等待
        </div>
        <div class="tips">
          <svg-icon class="info-icon" icon-class="info" />{{ booktips }}
        </div>
      </div>
      <div class="book-tips" v-html="hotelBook.hotel_order_confirm_tips"></div>
      <template v-if="hotelBook.order_notice && hotelBook.order_notice.length">
        <div
          class="book-tips"
          v-for="(item, idx) in hotelBook.order_notice"
          :key="idx"
        >
          <div>
            <div class="h4">{{ item.title }}</div>
            <div class="content" v-html="item.content"></div>
          </div>
        </div>
      </template>
    </div>
    <div class="fixed-height"></div>
    <div class="book-footer">
      <div class="book-deposit" v-if="hotelBook.pay_method == 0">
        <div class="book-amount">
          <span class="text">预订担保金：</span
          ><span class="book-deposit-amount"
            ><small>¥</small>{{ hotelBook.deposit }}</span
          >
        </div>
        <div class="book-amount-tips">入住后，担保金将于5个工作日内返还</div>
      </div>
      <div v-else class="book-amount">
        <div class="book-deposit-amount">
          <small>¥</small>{{ residualPrice }}
          <span class="book-facevalue"
            >¥{{ hotelBook.total_origin_price }}</span
          >
        </div>
        <div class="book-amount-tips">
          节省{{ hotelBook.total_origin_price - residualPrice }}元房费
        </div>
      </div>
      <van-button
        class="book-btn"
        @click="submit"
        v-ans="{
          event_id: 'booking_detail_click_buy',
          destination_name: hotelDetail.city,
          checkin_date: dayjs(hotelDetail.checkin_date).format('YYYY-MM-DD'),
          company_id: hotelDetail.company && hotelDetail.company[0].id,
          company_name: hotelDetail.company && hotelDetail.company[0].name,
          hotel_type: hotelDetail.classification,
          hotel_id: hotelDetail.id,
          hotel_name: hotelDetail.name,
          is_direct_sign: hotelDetail.is_direct_sign,
          is_special: hotelDetail.is_special,
          pay_method: hotelDetail.pay_method,
          rooms_id: hotelBook.room_id,
          rooms_price: hotelBook.total_price,
          rooms_name: decodeURIComponent(hotelBook.room_name),
        }"
        >提交订单</van-button
      >
    </div>
    <SelectCoupon
      :canSelectCoupon="canSelectCoupon"
      :coupon_id.sync="couponId"
      :visible.sync="visibleCoupon"
      :coupons="couponList"
      type="select"
    />
    <van-popup
      round
      closeable
      position="bottom"
      class="book-popup"
      v-model="showConfirmModal"
    >
      <div class="book-popup-title">预订须知</div>
      <div class="book-confirm-content">
        <div class="step">
          <div class="title">1.预付担保金</div>
          <div class="desc">预付担保金将在您入住后5个工作日自动退回</div>
        </div>
        <div class="step">
          <div class="title">2.房费到店付<span class="tag">预定须知</span></div>
          <div class="desc">您预订的是到店付的酒店,到店需现场付房费</div>
        </div>
        <div class="step">
          <div class="title">
            3.入住成功，5天内退还担保金<span class="tag">预定须知</span>
          </div>
          <div class="desc">
            如未如约入住，请在入住前一天16:00前取消订单，3-5天内退还担保金。否则扣担保金，冻结所有会员权益。详情见权益说明
          </div>
        </div>
      </div>
      <van-button
        type="info"
        round
        class="book-confirm-btn"
        block
        @click="toPay"
        >确认并支付</van-button
      >
    </van-popup>
  </section>
</template>

<script>
import { getHotelBook, getHotelDetail } from "@/apis/hotel";
import { mapGetters, mapState } from "vuex";
import { getActivityCoupon } from "@/apis";
import { getCurrentTime } from "@/apis/hotel";
import dayjs from "dayjs";
import SelectCoupon from "@/components/SelectCoupon";
import qs from "qs";
import pickBy from "lodash.pickby";
import ans from "@/utils/ans";

export default {
  name: "hotelBook",
  components: {
    SelectCoupon,
  },
  data() {
    return {
      showConfirmModal: false,
      loading: false,
      remarks: undefined,
      passenger_number: undefined,
      hotelBook: {},
      hotelDetail: {},
      roomInfo: {},
      dayjs,
      couponId: undefined,
      couponList: [],
      visibleCoupon: false,
      phone_number: "",
      canSelectCoupon: true,
      cn_name: "",
      captchaInstance: null,
    };
  },

  computed: {
    ...mapState([
      "hotel",
      "namespace",
      "benefitHotelType",
      "userInfo",
      "return_url",
      "source",
      "namespaceInfo",
    ]),
    ...mapGetters(["interNight", "showHotelBack", "isHoliday"]),
    inputDisabled() {
      if (!this.namespaceInfo.enable_recharge_account_limit) {
        return false;
      }
      return !!this.userInfo.cn_name && !!this.userInfo.phone_number;
    },
    couponAmount() {
      const coupon =
        this.couponList.find((item) => item.coupon_id === this.couponId) || {};
      return coupon.face_value > this.hotelBook.total_price
        ? this.hotelBook.total_price -
            Number(this.$getConfigValue("HOTEL_MIN_PRICE"))
        : coupon.face_value;
    },

    cancelTime() {
      return dayjs(Number(this.hotelBook.checkin_date))
        .subtract(1, "day")
        .format("MM月DD日");
    },
    booktips() {
      const hour16 = dayjs().hour(16).minute(0).second(0);
      if (dayjs().isBefore(hour16)) {
        return "每天16:00前预订酒店，酒店将于当天确认订单";
      }
      return "每天16:00后预订酒店，酒店将于次日确认订单";
    },
    holiday() {
      return this.isHoliday(dayjs().startOf("day"));
    },
    residualPrice() {
      const coupon =
        this.couponList.find((item) => item.coupon_id === this.couponId) || {};
      const facevalue = Number(coupon.face_value) || 0;
      return this.hotelBook.total_price > facevalue
        ? this.hotelBook.total_price - facevalue
        : this.$getConfigValue("HOTEL_MIN_PRICE");
    },
  },
  async mounted() {
    this.cn_name = this.userInfo.cn_name;
    this.phone_number = this.userInfo.user_phone;
    this.getHotelDetail();
  },
  methods: {
    // 16点以前
    async isBefore16() {
      try {
        const res = await getCurrentTime();
        const { current_time } = res.data;
        let { checkin_date } = this.hotelBook;
        const beforeOneDay = new Date(
          Number(checkin_date) - 24 * 60 * 60 * 1000
        );
        const hour16 = beforeOneDay.setHours(16, 0, 0, 0); // 设置时间为16时0分0秒
        if (current_time < hour16) {
          return true;
        }
      } catch (e) {
        return false;
      }
      return false;
    },
    async getHotelDetail() {
      const { hotelId, roomId } = this.$route.query;
      const loading = this.$loading();
      this.loading = true;
      try {
        const params = {
          namespace: this.namespace,
          checkin_date: this.hotel.checkin_date,
          checkout_date: this.hotel.checkout_date,
          id: hotelId,
          is_oak: this.$getConfigValue("OA_HOTEL_RULE") ? "1" : "0",
        };
        if (this.benefitHotelType === "ehotel") {
          params.classification = 3;
          params.namespace = `${params.namespace}-affordable`;
        }
        const res = await getHotelDetail(params);
        this.hotelDetail = res.data;
        const roomInfo = res.data.rooms.find((item) => item.id == roomId);
        const price = roomInfo.prices[0].price;
        const book = await getHotelBook({
          checkin_date: this.hotel.checkin_date,
          checkout_date: this.hotel.checkout_date,
          room_cnt: 1,
          hotel_id: hotelId,
          room_id: roomId,
          price,
          origin_price: roomInfo.prices[0].origin_price || price,
          breakfast: roomInfo.prices[0].breakfast,
        });
        document.title = res.data.name;
        ans.pageView(this.$route.meta.page, {
          namespace: this.namespace,
          company_id: res.data.company[0].id,
          company_name: res.data.company[0].name,
          destination_name: this.hotel.city,
          checkin_date: dayjs(this.hotel.checkin_date).format("YYYY-MM-DD"),
          hotel_type: res.data.classification,
          hotel_id: res.data.id,
          hotel_name: res.data.name,
          is_direct_sign: res.data.is_direct_sign,
          is_special: res.data.is_special,
          pay_method: res.data.pay_method,
          rooms_id: roomId,
          rooms_price: price,
          rooms_name: decodeURIComponent(book.data.room_name),
        });
        const coupons = await getActivityCoupon({ scope: "hotel" });
        const couponList = coupons.data;
        const availableCoupon = couponList.filter((item) => {
          const { attrs, start_time, end_time, min_limit } = item;
          const { room_ids, id_type } = attrs;
          if (
            price >= min_limit &&
            dayjs().isBetween(dayjs(start_time), dayjs(end_time))
          ) {
            if (
              (id_type === "disabled" && !room_ids.includes(Number(roomId))) ||
              (id_type === "enabled" && room_ids.includes(Number(roomId)))
            ) {
              return true;
            }
          }
          return false;
        });
        this.canSelectCoupon = availableCoupon.every((item) => !item.use_limit);

        let temp = availableCoupon[0]?.face_value;
        let couponId = "";
        // 默认选中最大优惠券
        availableCoupon.forEach((item) => {
          if (item.face_value >= temp) {
            temp = item.face_value;
            couponId = item.coupon_id;
          }
        });
        // todo 查询桔多多当天是否有使用过优惠券，如果使用过，则默认不勾选
        this.couponList = availableCoupon;
        if (this.canSelectCoupon) {
          this.couponId = couponId;
        }
        this.hotelBook = book.data;
        this.roomInfo = roomInfo;
      } finally {
        this.loading = false;
        loading.clear();
      }
    },
    async submit() {
      // 验证输入框
      if (
        this.passenger_number &&
        !/^[a-z0-9A-Z]+$/.test(this.passenger_number)
      ) {
        this.$alert({
          message: "旅客号必须是数字或英文，15字以内，请正确填写",
          confirmButtonText: "好的",
        });
        return;
      }
      if (this.$getConfigValue("OA_HOTEL_RULE")) {
        if (!this.cn_name) {
          this.$toast("请填写本人姓名");
          return;
        }
        if (!this.phone_number) {
          this.$toast("请填写本人手机号");
          return;
        }

        if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone_number)) {
          this.$toast("请填写正确的手机号");
          return;
        }
      }
      if (this.hotelBook.pay_method == 0) {
        this.showConfirmModal = true;
        return;
      }
      this.toPay();
    },
    async toPay() {
      this.showConfirmModal = false;
      const isBefore16 = await this.isBefore16();
      // 16点之后不能预订  判断房型是否关闭
      if (!isBefore16 || this.roomInfo.is_date_unavailable) {
        this.$toast("请重新选择入住日期");
        setTimeout(() => {
          this.$router.back();
        }, 1500);
        return;
      }
      const { hotelId, roomId } = this.$route.query;
      const url = `/pages/pay/pay?${qs.stringify(
        pickBy(
          {
            sku_name: encodeURIComponent(this.hotelBook.hotel_name),
            item_code: encodeURIComponent(
              encodeURI(
                `HT${this.roomInfo.prices[0].id}#${this.hotel.checkin_date}`
              )
            ),
            item_name: encodeURIComponent("预订酒店"),
            type: this.benefitHotelType,
            source: this.source,
            hoter_order_params: JSON.stringify({
              checkin_date: this.hotel.checkin_date,
              checkout_date: this.hotel.checkout_date,
              room_cnt: 1,
              hotel_id: hotelId,
              room_id: roomId,
              price: this.residualPrice,
              origin_price: this.hotelBook.total_origin_price,
              breakfast: decodeURIComponent(this.hotelBook.breakfast),
            }),
            passenger_number: this.passenger_number,
            remarks: this.remarks,
            coupon_id: this.couponId,
            price:
              this.hotelBook.pay_method == 0
                ? this.hotelBook.deposit
                : this.residualPrice,
            cn_name: this.cn_name || this.userInfo.cn_name,
            phone_number: this.phone_number || this.userInfo.phone_number,
          },
          (v) => v !== undefined
        ),
        { encode: false }
      )}`;
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.card-info {
  background: #ffffff;
  border-radius: 12px;
  padding: 18px 16px;
  & + & {
    margin-top: 12px;
  }
  .title {
    line-height: 24px;
    font-size: 17px;
    font-weight: bold;
  }
}
.hotel-book {
  padding: 12px 12px 0;
}
.hotel-image {
  position: absolute;
  top: 6px;
  right: 0;
  width: 49px;
  height: 41px;
}
.hotel-back {
  width: 24px;
  height: 24px;
  background: url("~@/assets/images/hotel_back.png") no-repeat;
  background-size: 100%;
  margin-right: 10px;
}

.book-tips {
  margin-top: 18px;
  line-height: 18px;
  font-size: 11px;
  color: #999;
  &:last-child {
    .content {
      margin-bottom: 0;
    }
  }
  /deep/ .h4 {
    font-size: 13px;
    color: #333;
    margin-bottom: 4px;
  }
  /deep/ .content {
    margin-bottom: 22px;
  }
  .tips {
    color: #2c912e;
  }
  .desc {
    margin: 5px 0 18px;
  }
  .info-icon {
    margin-right: 2px;
  }
}
.hotel-detail {
  background: #fff;
}

.hotel-room {
  display: flex;
  position: relative;
  .hotel-name {
    line-height: 22px;
    height: 22px;
    font-weight: bold;
    font-size: 16px;
    color: #222;
    margin-right: 30px;
    padding-bottom: 9px;
    border-bottom: 1px solid #f9f9f9;
    width: calc(100% - 70px);
    height: 32px;
    margin-bottom: 9px;
    box-sizing: border-box;
  }
  .hotel-image {
    width: 49px;
    height: 41px;
    margin-left: auto;
    flex-shrink: 0;
    border-radius: 8px;
    overflow: hidden;
  }
}

.hotel-checkin-text {
  line-height: 18px;
  font-size: 13px;
  color: #222;
  font-weight: bold;
  margin-bottom: 4px;
  .split {
    width: 1px;
    display: inline-block;
    background: rgba(#d2d2d2, 0.6);
    margin: 0 7px;
    vertical-align: top;
    height: 14px;
  }
}
.hotel-info {
  line-height: 16px;
  color: #999;
  font-size: 11px;
}
.hotel-night {
  padding: 18px 0 10px;
}

.cancel-tag {
  padding: 0 5px;
  line-height: 19px;
  background: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #d2d2d2;
  font-size: 10px;
  color: #333;
  width: max-content;
  margin-top: 10px;
}

.hotel-book-info {
  .cell {
    line-height: 16px;
    padding-top: 16px;
    font-size: 13px;
    /deep/ .van-field {
      padding: 0;
      line-height: 18px;
      font-size: 13px;
      .van-field__control {
        &::placeholder {
          color: #bbb;
        }
      }
    }
    &:not(:last-child) {
      padding-bottom: 16px;
    }
    & + .cell {
      border-top: 1px solid #f9f9f9;
    }
  }
}
.cell-label {
  color: #888;
  padding: 1px 0;
  line-height: 16px;
  span {
    color: red;
    font-size: 12px;
  }
}
.cell-value {
  color: #999;
  /deep/ textarea {
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }
  }
}
.cell-text {
  line-height: 18px;
  color: #000;
}

.hotel-night-cell {
  margin-bottom: 10px;
}
.night-tips {
  line-height: 18px;
  padding: 9px 12px;
  border-radius: 8px;
  color: #999;
  background: #f9f9f9;
  font-size: 11px;

  &.night-tips-0 {
    color: #fc4f10;
    background: rgba(#ff5001, 0.04);
  }
}

.residual {
  align-self: flex-end;
  .title {
    display: flex;
    margin-bottom: 18px;
  }
  .tag {
    width: 76px;
    height: 20px;
    margin-right: 3px;
  }
  .residual-price {
    margin-top: 3px;
    margin-left: auto;
    display: flex;
    height: 24px;
    line-height: 24px;
    color: #fc4f10;
    font-size: 17px;
    font-weight: bold;
  }
}

.flex-cell {
  display: flex;
  justify-content: space-between;
  line-height: 18px;
  margin-bottom: 13px;
  &:last-child {
    margin-top: 17px;
    padding-top: 13px;
    border-top: 1px solid #f9f9f9;
    margin-bottom: 0;
  }
  .label {
    color: #888888;
    font-size: 13px;
    flex-basis: 120px;
  }
  .value {
    font-size: 12px;
    color: #999;
    text-align: right;
  }
}

.flex-cell-preferential {
  .value {
    color: #fc4f10;
  }
}
.flex-cell-price {
  .value {
    color: #333;
    text-decoration: line-through;
  }
}

.coupon {
  padding: 0 15px;
  line-height: 40px;
  background: #fff;
  margin: 10px;
  border-radius: 8px;
}
.coupon-price {
  text-align: right;
  flex: 1;
}

.coupon-numbers {
  width: max-content;
  padding: 0 4px;
  height: 18px;
  line-height: 18px;
  color: #fff;
  font-size: 13px;
  background: linear-gradient(#ff650d, #ff5f4a);
  position: relative;
  border-radius: 2px;
  &::before {
    content: "";
    position: absolute;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #fff;
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #fff;
    z-index: 1;
  }
}
.coupon-amount {
  color: #ff5001;
  font-size: 14px;
}
.coupon-arrow {
  transform: rotate(-90deg);
  font-size: 20px;
  vertical-align: -6px !important;
}

.book-footer {
  position: fixed;
  height: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  box-sizing: border-box;
  padding: 0 14px 0 18px;
  display: flex;
  align-items: center;
}
.book-btn {
  width: 120px;
  height: 50px;
  background: linear-gradient(90deg, #ff650d 0%, #ff5f4a 100%);
  border-radius: 27px;
  font-size: 15px;
  color: #fff;
  margin-left: auto;
}

.book-amount {
  flex: 1;
}

.fixed-height {
  height: 88px;
}
.book-amount {
  color: #000;
  font-size: 26px;
  small {
    font-size: 0.5em;
  }
  .text {
    font-size: 10px;
    color: #333;
  }
  .book-facevalue {
    font-weight: normal;
    text-decoration: line-through;
    font-size: 12px;
    color: #666;
    margin-left: 3px;
  }
}
.book-deposit-amount {
  font-weight: bold;
}
.book-amount-tips {
  line-height: 19px;
  color: #ff5001;
  font-size: 10px;
  font-weight: normal;
  background: #fef7f9;
  border-radius: 10px;
  border: 1px solid #f6f0ee;
  width: max-content;
  padding: 0 6px;
  margin-top: 6px;
}

.book-popup {
  padding: 15px 34px 25px;
  border-radius: 8px;
  box-sizing: border-box;
  /deep/ .van-popup__close-icon {
    left: 18px;
    top: 24px;
    color: #666;
    font-size: 0;
    width: 13px;
    height: 13px;
    background: url("~@/assets/images/close.svg") no-repeat center/100%;
  }
  .book-popup-title {
    line-height: 28px;
    font-size: 19px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 25px;
  }
  .book-confirm-content {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: -24px;
      top: 0;
      width: 10px;
      height: 175px;
      background: url("~@/assets/images/hotel2/book-tips-arrow.png") no-repeat
        left top / 100%;
    }
  }
  .step {
    .title {
      color: #000000;
      line-height: 21px;
      font-size: 15px;
      margin-bottom: 4px;
    }
    &:not(:last-child) {
      margin-bottom: 18px;
    }
    .desc {
      font-size: 11px;
      line-height: 18px;
      color: #999;
    }
    .tag {
      padding: 0 6px;
      line-height: 19px;
      background: #fef7f9;
      border-radius: 10px;
      border: 1px solid #f6f0ee;
      text-align: center;
      margin-left: 6px;
      font-size: 10px;
      color: #ff5001;
    }
  }
}

.book-confirm-btn {
  margin-top: 34px;
  background: linear-gradient(to right, #ff650d 0%, #ff5f4a 100%);
  height: 41px;
  color: #fff;
  font-size: 17px;
}
</style>
