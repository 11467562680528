<template>
  <section class="park-book" v-if="!loading">
    <div class="park-detail card-info">
      <div class="park-name">{{ parkDetail.name }}1日票</div>
      <div class="park-select-date">选择日期</div>
      <select-date show-price :num="30" :priceDict="priceDict" />
      <div class="park-book-tips">温馨提醒：五折优惠仅适用1张乐园门票！</div>
      <div class="park-ticket-info">
        <div class="left">
          <div class="park-ticket-name">{{ ticketInfo.name }}</div>
          <div class="park-ticket-tags">
            <span class="tag" v-for="tag in ticketInfo.tags" :key="tag.id">{{
              tag.name
            }}</span>
            <span @click="showIntroductionPopup = true" class="introduction"
              >购买须知 <van-icon style="vertical-align: -2px" name="arrow"
            /></span>
          </div>
        </div>
        <div class="park-ticket-price">¥{{ price }}</div>
      </div>
    </div>
    <div class="park-book-info card-info">
      <div class="card-info-title">优惠</div>
      <van-row class="cell" @click="visibleCoupon = true">
        <van-col class="cell-label" :span="5">优惠券</van-col>
        <van-col class="cell-value coupon-price" :span="19">
          <span v-if="couponId" class="coupon-amount">
            -¥{{ price | decimal(residualPrice, "minus") }}
          </span>
          <div v-else class="coupon-numbers">
            {{ couponList.length }}张优惠券
          </div>
          <van-icon name="arrow" />
        </van-col>
      </van-row>
    </div>
    <div class="park-book-info card-info">
      <div class="card-info-title">出行信息</div>
      <van-row class="cell">
        <van-col class="cell-label" :span="5">姓名</van-col>
        <van-col class="cell-value" :span="19">
          <van-field
            rows="1"
            autosize
            className="park-order-input"
            maxlength="20"
            placeholder="请与证件姓名保持一致"
            v-model.trim="form.cn_name"
          />
        </van-col>
      </van-row>
      <van-row class="cell">
        <van-col class="cell-label" :span="5">证件类型</van-col>
        <van-col style="color: #333" class="cell-value" :span="19"
          >身份证</van-col
        >
      </van-row>
      <van-row class="cell">
        <van-col class="cell-label" :span="5">证件号码</van-col>
        <van-col class="cell-value" :span="19">
          <van-field
            rows="1"
            autosize
            className="park-order-input"
            placeholder="请填写身份证"
            maxlength="18"
            v-model.trim="form.idcard"
          />
        </van-col>
      </van-row>
    </div>
    <div class="park-book-info card-info">
      <div class="card-info-title">联系方式</div>
      <van-row class="cell">
        <van-col class="cell-label" :span="5">联系手机</van-col>
        <van-col class="cell-value" :span="19">
          <van-field
            rows="1"
            autosize
            className="park-order-input"
            placeholder="请输入手机号，用于接收信息"
            maxlength="11"
            v-model.trim="form.phone_number"
          />
        </van-col>
      </van-row>
    </div>

    <div class="fixed-height"></div>
    <div class="book-footer">
      <div class="book-amount">
        <span class="book-amount-price">
          <small>¥</small>{{ residualPrice }}
        </span>
        <span v-if="couponId" class="book-amount-savemoney"
          >已优惠¥{{ price | decimal(residualPrice, "minus") }}</span
        >
        <span
          v-if="couponId"
          @click="pricePopup = !pricePopup"
          class="book-amount-popup"
          >明细 <van-icon :name="!pricePopup ? 'arrow-up' : 'arrow-down'"
        /></span>
      </div>
      <van-button class="book-btn" @click="submit">去支付</van-button>
    </div>
    <SelectCoupon
      canSelectCoupon
      :coupon_id.sync="couponId"
      :visible.sync="visibleCoupon"
      :coupons="couponList"
      type="select"
    />
    <van-popup
      round
      closeable
      position="bottom"
      class="price-popup"
      :z-index="1999"
      v-model="pricePopup"
    >
      <div class="price-popup-title">金额明细</div>
      <div class="price-popup-content">
        <div class="cell-title">{{ parkDetail.name }}</div>
        <div class="flex">
          <div>{{ ticketInfo.name }}</div>
          <div>¥{{ price }}</div>
        </div>
        <div class="cell-title">优惠</div>
        <div class="flex">
          <div>五折优惠</div>
          <div class="price">
            - ¥{{ price | decimal(residualPrice, "minus") }}
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      round
      closeable
      position="bottom"
      class="price-popup"
      :z-index="2050"
      v-model="showIntroductionPopup"
    >
      <div class="price-popup-title">购买须知</div>
      <div class="price-popup-content">
        <div style="line-height: 1.5" v-html="parkDetail.purchase_notice"></div>
      </div>
    </van-popup>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { getActivityCoupon } from "@/apis";
import dayjs from "dayjs";
import SelectCoupon from "@/components/SelectCoupon";
import qs from "qs";
import pickBy from "lodash.pickby";
import SelectDate from "./selectDate";
import { isValidIdCard, isValidPhone } from "@/helper/validate";
import {
  getParkDetail,
  getParkTickekDetail,
  realnameVerifyPure,
} from "@/apis/park";
import { decimal } from "@/utils";

export default {
  name: "parkBook",
  components: {
    SelectDate,
    SelectCoupon,
  },
  data() {
    return {
      loading: false,
      couponId: "",
      parkDetail: {},
      couponList: [],
      ticketInfo: {
        price_dict: {},
      },
      coupon: null,
      visibleCoupon: false,
      pricePopup: false,
      showIntroductionPopup: false,
      form: {
        phone_number: "",
        cn_name: "",
        idcard: "",
      },
    };
  },

  computed: {
    ...mapState([
      "namespace",
      "benefitHotelType",
      "userInfo",
      "return_url",
      "source",
      "offlineBenefit",
    ]),
    ...mapGetters(["interNight", "showHotelBack", "isHoliday"]),

    residualPrice() {
      if (!this.couponId) {
        return this.price;
      }
      const coupon =
        this.couponList.find((item) => item.coupon_id === this.couponId) || {};
      return decimal.times(coupon.discount_rate, this.price);
    },
    price() {
      return this.ticketInfo.price_dict[
        this.offlineBenefit.parkBookDate.format("YYYY-MM-DD")
      ];
    },
    priceDict() {
      if (this.couponId) {
        const res = {};
        const coupon = this.couponList.find(
          (item) => item.coupon_id === this.couponId
        );
        for (const key in this.ticketInfo.price_dict) {
          res[key] = decimal.times(
            this.ticketInfo.price_dict[key],
            coupon.discount_rate
          );
        }
        return res;
      }
      return this.ticketInfo.price_dict;
    },
  },
  async mounted() {
    this.getDetail();
  },
  methods: {
    /** 获取详情
     * @returns {Promise<number>} 详情
     */
    async getDetail() {
      const { parkId, ticketId } = this.$route.query;
      const loading = this.$loading();
      this.loading = true;
      try {
        const ticketRes = await getParkTickekDetail(ticketId);
        const res = await getParkDetail(parkId);
        const ticketInfo = ticketRes.data;
        document.title = res.data.name;

        const coupons = await getActivityCoupon({ scope: "park" });
        const couponList = coupons.data;
        const availableCoupon = couponList.filter((item) =>
          item.attrs.ticket_ids.includes(Number(parkId))
        );
        availableCoupon.sort((a, b) => a.discount_rate - b.discount_rate);

        if (availableCoupon.length) {
          this.couponId = availableCoupon[0].coupon_id;
        }
        this.parkDetail = res.data;
        this.couponList = availableCoupon;
        this.ticketInfo = ticketInfo;
      } finally {
        this.loading = false;
        loading.clear();
      }
    },
    submit() {
      this.toPay();
    },
    async toPay() {
      if (!this.offlineBenefit.parkBookDate) {
        this.$toast("请选择预订日期");
        return;
      }
      if (!this.form.cn_name) {
        this.$toast("请输入姓名");
        return;
      }
      if (!this.form.idcard) {
        this.$toast("请输入身份证号码");
        return;
      }
      if (!isValidIdCard(this.form.idcard)) {
        this.$toast("身份证号码格式不正确");
        return;
      }
      if (!this.form.phone_number) {
        this.$toast("请输入手机号码");
        return;
      }
      if (!isValidPhone(this.form.phone_number)) {
        this.$toast("手机号格式不正确");
        return;
      }

      // 根据身份证号获取出生日期
      const { from_age, to_age } = this.ticketInfo;
      const birthday = dayjs(this.form.idcard.slice(6, 14));
      if (
        !dayjs().isBetween(
          dayjs(birthday).add(from_age, "year"),
          dayjs(birthday).add(to_age, "year"),
          "day"
        )
      ) {
        this.$alert({
          title: "温馨提示",
          message: `预订${this.ticketInfo.name}年龄需在${from_age}~${to_age}周岁之间`,
        });
        return;
      }

      await this.$confirm({
        title: "购票须知",
        message: `门票购买后<span style="color:#FF5001">不可改期、不可退票、不可转让</span>。请确认好您的出行日期和出行信息`,
        confirmButtonText: "继续支付",
        cancelButtonText: "重新选择",
      });
      const loading = this.$loading();

      try {
        await realnameVerifyPure({
          cn_name: this.form.cn_name,
          id_number: this.form.idcard,
        });
      } finally {
        loading.clear();
      }
      const { parkId, ticketId } = this.$route.query;
      const url = `/pages/pay/pay?${qs.stringify(
        pickBy(
          {
            park_id: this.parkDetail.id,
            park_name: encodeURIComponent(this.parkDetail.name),
            ticket_cnt: 1,
            ticket_name: this.ticketInfo.name,
            user: this.form.cn_name,
            id_card: this.form.idcard,
            ticket_mobile: this.form.phone_number,
            sku_name: encodeURIComponent(
              this.parkDetail.name + this.ticketInfo.name
            ),
            price: this.residualPrice,
            checkin_date: this.offlineBenefit.parkBookDate.valueOf(),
            item_code: encodeURIComponent(
              `PT#${ticketId}#${this.offlineBenefit.parkBookDate.valueOf()}`
            ),
            item_name: encodeURIComponent(
              this.parkDetail.name + this.ticketInfo.name
            ),
            type: this.$route.query.benefit_type || "paradise",
            source: this.source,
            coupon_id: this.couponId,
          },
          (v) => v !== undefined
        ),
        { encode: false }
      )}`;
      this.$jumpUrl(url, "navigateTo");
    },
  },
};
</script>

<style lang="less" scoped>
.card-info {
  background: #ffffff;
  border-radius: 12px;
  padding: 18px 16px;
  & + & {
    margin-top: 12px;
  }
  .title {
    line-height: 24px;
    font-size: 17px;
    font-weight: bold;
  }
}
.card-info-title {
  font-size: 18px;
  color: #111;
}
.park-book {
  padding: 12px 12px 0;
}

.park-book-info {
  .cell {
    line-height: 16px;
    padding-top: 16px;
    font-size: 13px;
    /deep/ .van-field {
      padding: 0;
      line-height: 18px;
      font-size: 13px;
      .van-field__control {
        &::placeholder {
          color: #bbb;
        }
      }
    }
    &:not(:last-child) {
      padding-bottom: 16px;
    }
    & + .cell {
      border-top: 1px solid #f9f9f9;
    }
  }
}
.park-name {
  color: #202437;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  padding-bottom: 9px;
  border-bottom: 1px solid #f9f9f9;
  margin-bottom: 16px;
}
.park-select-date {
  line-height: 22px;
  font-size: 16px;
  color: #202437;
  font-weight: bold;
  margin-bottom: 9px;
}
.park-book-tips {
  margin-top: 13px;
  color: #fc4f10;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 17px;
}
.park-ticket-info {
  padding-top: 18px;
  display: flex;
  border-top: 1px solid #f9f9f9;
  .park-ticket-name {
    color: #111;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 7px;
  }
  .park-ticket-tags {
    line-height: 14px;
    font-size: 10px;
    .tag {
      color: #ff5001;
      margin-right: 9px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 1px;
        height: 100%;
        background: #f1f1f1;
        right: -4px;
      }
    }
    .introduction {
      color: #1572fe;
    }
  }
}
.park-ticket-price {
  margin-left: auto;
  color: #ff6f00;
  font-size: 20px;
  font-weight: bold;
}
.cell-label {
  color: #888;
  padding: 1px 0;
  line-height: 16px;
  span {
    color: red;
    font-size: 12px;
  }
}
.cell-value {
  color: #999;
  /deep/ textarea {
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }
  }
}
.cell-text {
  line-height: 18px;
  color: #000;
}

.coupon {
  padding: 0 15px;
  line-height: 40px;
  background: #fff;
  margin: 10px;
  border-radius: 8px;
}
.coupon-price {
  text-align: right;
  flex: 1;
}

.coupon-numbers {
  padding: 0 4px;
  height: 18px;
  line-height: 18px;
  color: #fff;
  font-size: 13px;
  background: linear-gradient(#ff650d, #ff5f4a);
  position: relative;
  border-radius: 2px;
  display: inline-block;
  &::before {
    content: "";
    position: absolute;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #fff;
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #fff;
    z-index: 1;
  }
}
.coupon-amount {
  color: #ff5001;
  font-size: 14px;
}
.coupon-arrow {
  transform: rotate(-90deg);
  font-size: 20px;
  vertical-align: -6px !important;
}

.book-footer {
  position: fixed;
  height: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  box-sizing: border-box;
  padding: 0 14px 0 18px;
  display: flex;
  align-items: center;
  z-index: 2002;
}
.book-btn {
  width: 120px;
  height: 50px;
  background: linear-gradient(90deg, #ff650d 0%, #ff5f4a 100%);
  border-radius: 27px;
  font-size: 15px;
  color: #fff;
  margin-left: auto;
}

.book-amount {
  flex: 1;
  display: flex;
  align-items: center;
}

.fixed-height {
  height: 88px;
}

.book-confirm-btn {
  margin-top: 34px;
  background: linear-gradient(to right, #ff650d 0%, #ff5f4a 100%);
  height: 41px;
  color: #fff;
  font-size: 17px;
}

.book-amount-price {
  font-size: 24px;
  color: #ff5500;
  line-height: 30px;
  margin-right: 8px;
}
.book-amount-savemoney {
  height: 20px;
  line-height: 20px;
  background: #fff4eb;
  padding: 3px 4px;
  color: #ff5500;

  font-size: 14px;
  margin-right: 10px;
}
.book-amount-popup {
  height: 20px;
  line-height: 20px;
  color: #111;
}

.price-popup {
  padding: 15px 18px;
  height: 350px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .price-popup-title {
    flex-shrink: 0;
    color: #222222;
    font-size: 15px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    margin-bottom: 15px;
  }
  .price-popup-content {
    flex: 1;
    overflow-y: auto;
  }
  .cell-title {
    margin: 10px 0;
    font-size: 16px;
    color: #111;
    font-weight: bold;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 20px;
    color: #888;
    font-size: 14px;
  }
  .price {
    color: #ff5500;
  }
}
</style>
