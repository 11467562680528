<template>
  <div class="xiaopai" v-if="pageshow">
    <van-image
      class="header-img"
      :src="`https://cdn.xiangshuheika.com/static/${namespace}/header_new.png`"
    />
    <div class="xp-package">
      <div class="tab">
        <ul>
          <li
            v-for="item in memberPackage"
            @click="changePackageGroup(item)"
            :key="item.item_code"
          >
            <van-image
              :src="`https://cdn.xiangshuheika.com/static/${namespace}/${
                groupActive === item.item_code
                  ? `tab-${item.master_card_tag}-selected`
                  : `tab-${item.master_card_tag}`
              }.png`"
            />
          </li>
        </ul>
      </div>
      <div class="small-tab">
        <ul class="small-tab-ul">
          <li
            @click="changePackage(memberPackageItem)"
            :class="{
              'small-tab-item': true,
              active: active === memberPackageItem.item_code,
            }"
            v-for="memberPackageItem in childs"
            :key="memberPackageItem.item_code"
          >
            {{ memberPackageItem.price }}元{{ memberPackageItem.unit }}卡
          </li>
          <li
            class="active-line"
            :style="{
              left: childs.findIndex((r) => r.item_code === active) * 50 + '%',
            }"
          ></li>
        </ul>
      </div>
      <div class="package-benefit">
        <div class="package-image">
          <van-image
            lazy-load
            v-if="packageDesc.display_img_url"
            class="package-image"
            :src="packageDesc.display_img_url"
          />
        </div>
        <div class="value">
          {{ packageDesc.desc }}
        </div>
        <van-sticky :offset-top="0">
          <van-button round block type="info" v-if="!token" @click="tobuy">
            未登录，请先登录
          </van-button>
          <van-button
            round
            block
            type="info"
            v-else-if="!currentPackage.is_active"
            :disabled="!!token"
          >
            未购买，活动已结束
          </van-button>
          <div class="open-btn" @click="tobuy" v-else>
            <div class="open-p" v-if="!needActivation">去使用</div>
            <div class="open-p" v-else>去激活</div>
          </div>
        </van-sticky>
        <div class="rules">
          购买前请阅读<span @click="popup_show = true">《充值规则》</span>
        </div>
        <van-popup v-model="popup_show" closeable class="xp-popup">
          <div>
            <div class="tit">充值规则</div>
            <div class="rules-wrap" v-html="activity.rules"></div>
          </div>
        </van-popup>
      </div>
    </div>
    <div class="container">
      <div class="jiao">购买须知</div>
      <div v-html="instructions"></div>
    </div>
    <div class="container">
      <div class="title">
        生活权益免费领<span>(价值{{ lifeBenefits.desc }}元)</span>
      </div>
      <ul>
        <li
          v-for="item in lifeBenefits.list"
          :key="item.id"
          @click="benefitHandle"
        >
          <van-image class="img" :src="item.display_img_url" />
          <div class="price">
            <sub>¥</sub
            >{{
              item.lowest_face_value *
              getCoundFromSellpoint(item.selling_points)
            }}
          </div>
          <div class="name">{{ item.desc }}{{ item.lowest_face_value }}元</div>
          <p>{{ item.selling_points }}</p>
          <div
            class="get"
            v-ans="{
              event_id: 'vistor_click_receive_free',
              namespace,
              source,
            }"
          >
            免费领取
          </div>
        </li>
      </ul>
    </div>
    <div class="container">
      <div class="title">更多权益领取入口</div>
      <div class="location">{{ $getConfigValue("ENTRY") }}</div>
      <van-image
        class="process-img"
        :src="`https://cdn.xiangshuheika.com/static/${namespace}/entry.png`"
      />
    </div>
    <div style="height: 80px"></div>

    <van-action-sheet v-model="show">
      <div class="content">
        <div class="top">
          <!-- {{ currentPackage.name }} -->
          VIP会员支付明细
          <!-- <p><span>¥</span>{{ currentPackage.price }}</p> -->
        </div>
        <div class="method-tit">视频会员</div>
        <div class="benefit-item">
          {{ firstBenefit.desc }}
          <div>¥{{ currentPackage.price }}</div>
        </div>
        <div class="method-tit">生活特权</div>
        <div
          class="benefit-item"
          v-for="item in lifeBenefits.list"
          :key="item.desc"
        >
          {{ item.desc }} ¥{{ item.lowest_face_value }} x
          {{ getCoundFromSellpoint(item.selling_points) }}次
          <div>
            <span
              >¥
              {{
                item.lowest_face_value *
                getCoundFromSellpoint(item.selling_points)
              }}</span
            >
            ¥0
          </div>
        </div>
        <div class="method-tit">支付方式</div>
        <div class="methods">
          <div
            class="item"
            v-for="(item, index) in payMethods"
            :key="item.channel"
            @click="changeMethod(index)"
          >
            <div class="left">
              <van-image class="pay-item-icon" :src="item.icon" />
              {{ item.name }}
            </div>
            <img
              :src="
                require(`../../assets/images/${
                  selected_method.channel === item.channel
                    ? 'selected'
                    : 'select'
                }.png`)
              "
              alt=""
            />
          </div>
        </div>
        <div class="agreement">
          点击支付开通会员即视为同意<span @click="agreement"
            >《会员服务协议》</span
          >
        </div>
        <div
          class="btn"
          @click="open"
          v-ans="{
            event_id: 'vistor_click_pay_now',
            namespace,
            source,
          }"
        >
          <div class="open-p open-p2">
            <div class="left">官方授权</div>
            <div class="right">
              <span>¥</span><b>{{ currentPackage.price }}</b
              ><span>/{{ currentPackage.unit }}</span
              ><span>立即开通</span>
            </div>
          </div>
        </div>
        <van-image
          class="close"
          @click="show = false"
          :src="require('../../assets/images/close.png')"
        />
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from "vue";
import ans from "@/utils/ans";
import { mapState, mapMutations, mapActions } from "vuex";
import { Sticky } from "vant";
import { storage, creatSign } from "@/utils";
import {
  getIndexCategory,
  getIndexClassList,
  getPackageList,
  getPaymethod,
} from "@/apis/product";
import {
  cardList,
  logicUserToken,
  getActivityInfo,
  getBenefitInfo,
} from "@/apis";
import loginKeepDialog from "@/components/loginKeepDialog";
import { toPayV2 } from "@/helper/pay";
Vue.use(Sticky);
export default {
  data() {
    return {
      pageshow: false,
      show: false,
      active: "",
      payMethods: [],
      selected_method: {},
      memberPackage: [],
      currentPackage: {},
      currentChannel: "",
      life_benefit: [],
      childs: [],
      popup_show: false,
      activity: {},
      needActivation: false,
      instructions: "",
      discount: "",
      catesgoryList: [],
      groupActive: "",
    };
  },
  async mounted() {
    const loading = this.$loading();
    try {
      await Promise.all([this.fetchPackage()]);
      const res = await getActivityInfo({
        activity_name: `xiaopai`,
      });
      this.activity = res.data;
    } finally {
      this.loading = false;
      this.pageshow = true;
      loading.clear();
    }
  },
  computed: {
    ...mapState(["userInfo", "source", "namespace", "ptoken", "token"]),
    packageDesc() {
      const item =
        this.catesgoryList.find((item) => item.display_code === "package") ||
        {};

      return item;
    },
    firstBenefit() {
      let res = {};
      const item =
        this.catesgoryList.find((item) => item.display_code === "life") || {};
      if (item?.list?.length) {
        res = item.list[0];
      }
      return res;
    },
    lifeBenefits() {
      const arr = JSON.parse(JSON.stringify(this.catesgoryList));
      const item = arr.find((item) => item.display_code === "life") || {};
      if (item?.list?.length) {
        item.list = item.list.slice(1);
      }
      return item;
    },
  },
  methods: {
    ...mapMutations(["changeState"]),
    ...mapActions(["fetchUserInfo"]),
    getCoundFromSellpoint(str) {
      const text = str.split("x")[1] || "";
      return Number(text.match(/(\d+)/)?.[0] || 0);
    },
    getGroupMember(list) {
      const res = {};
      list.forEach((item) => {
        const sub = item.item_code.slice(0, 3);
        if (!res[sub]) {
          res[sub] = {
            child: [],
          };
        }
        res[sub].child.push(item);
      });
      const arr = Object.values(res).map((item) => {
        const child = item.child.sort((a, b) => a.order - b.order);
        return {
          includeName: child.map((item) => item.item_code),
          child: child,
          ...child[0],
        };
      });
      arr.sort((a, b) => a.order - b.order);
      return arr;
    },
    async fetchPackage() {
      const [methods, res, user_card] = await Promise.all([
        getPaymethod({
          namespace: this.namespace,
          product_type: this.namespace.toUpperCase(),
          source: "default",
        }),
        getPackageList({
          namespace: this.namespace,
          source: this.source,
        }),
        cardList({
          namespace_name: this.namespace,
        }),
      ]);

      res.data.forEach((item) => {
        Object.assign(
          item,
          user_card.data.find((r) => r.card_type === item.type)
        );
      });

      this.payMethods = methods.filter((r) => r.channel.includes("xiaopai"));
      this.selected_method = this.payMethods[0];
      this.currentChannel = this.payMethods[0].channel;

      const sortData = res.data.sort((a, b) => a.order - b.order);
      this.memberPackage = this.getGroupMember(sortData);
      //获取已开通的卡，定位在第一张已开通的卡上

      let activeList = sortData.filter((r) => r.is_active === true);
      const type = this.$route.query.type;
      if (type) {
        const list = sortData.filter((item) => item.master_card_tag === type);
        if (list.length) {
          activeList = list;
        }
      }
      this.currentPackage = activeList[0] || sortData[0];
      this.active = this.currentPackage.item_code;
      this.groupActive = this.memberPackage.find((item) =>
        item.includeName.includes(this.currentPackage.item_code)
      ).item_code;

      this.getCategory();
    },
    async getCategory() {
      const res = await getIndexCategory({
        namespace: this.namespace,
        opened_page: `xiaopai_visitor_${this.currentPackage.card_type.toLowerCase()}`,
        source: this.source,
      });
      const catesgorys = res.data;
      const list = await getIndexClassList({
        title: catesgorys.map((r) => r.name).join(","),
        opened_page: `xiaopai_visitor_${this.currentPackage.card_type.toLowerCase()}`,
        namespace: this.namespace,
      });

      catesgorys.forEach((item, index) => {
        item.list = [...list.data[item.name]];
      });
      this.catesgoryList = catesgorys;
      const first = list.data["生活特权"].shift();
      this.life_benefit = list.data["生活特权"];
      //如果购买了当前卡，则获取用户主卡权益是否激活
      if (this.currentPackage.is_active) {
        const logic = await logicUserToken({
          card_type: this.currentPackage.card_type,
        });
        const benefit_info = await getBenefitInfo(
          {
            benefit_type: first.product_code,
          },
          logic.data.token
        );
        this.needActivation = benefit_info.data.amount_available > 0;
      }
    },
    async tobuy() {
      if (!storage.getItem("token")) {
        ans.track("vistor_awake_login", {
          namespace: this.namespace,
          source: this.source,
        });
        loginKeepDialog
          .show({
            title: "手机号验证",
            desc: "为保证您的会员权益及时到账，请先关联手机号",
            btnText: "完成",
            agreement: `<div style="text-align:center">购买则视为同意
        <a
          class="login-agreement-text"
          href="https://cdn.xiangshuheika.com/h5/agreement/pagj.html"
          >《服务协议》</a
        > </div>`,
          })
          .then(async (res) => {
            ans.track("vistor_click_login_now", {
              namespace: this.namespace,
            });
            await Promise.all([this.fetchPackage()]);
          });
        return;
      }
      if (!this.currentPackage.is_active) {
        // this.$toast("活动已结束");
      } else {
        //点击去使用跳转已开通页时，获取逻辑用户的token，替换缓存中的token
        const res = await logicUserToken({
          card_type: this.currentPackage.card_type,
        });
        storage.setItem("token", res.data.token);
        await this.fetchUserInfo();
        if (this.needActivation) {
          this.$router.push(`/xiaopai/activation`);
          return;
        }
        this.$router.push(`/xiaopai/home`);
      }
    },
    async open() {
      const res = await logicUserToken({
        card_type: this.currentPackage.card_type,
      });
      let url =
        (window.location.origin + window.location.pathname).replace(/\/$/, "") +
        `/#/xiaopai/index?namespace=xiaopai&type=${this.currentPackage.master_card_tag}`;
      const return_url = encodeURIComponent(url);

      toPayV2(this.currentPackage, this.currentChannel, {
        partner_jump_url: return_url,
        order_source: encodeURIComponent("联合会员超值购"),
        activity: this.currentPackage.item_code,
        partner_url: return_url,
        user_token: res.data.token,
      });
    },
    benefitHandle() {
      if (!storage.getItem("token")) {
        loginKeepDialog
          .show({
            title: "手机号验证",
            desc: "为保证您的会员权益及时到账，请先关联手机号",
            btnText: "完成",
            agreement: ` 购买则视为同意
        <a
          class="login-agreement-text"
          href="https://cdn.xiangshuheika.com/h5/agreement/pagj.html"
          >《服务协议》</a
        >`,
          })
          .then(async (res) => {
            await Promise.all([this.fetchPackage()]);
            this.benefitToast();
          });
        return;
      } else {
        this.benefitToast();
      }
    },
    async benefitToast() {
      if (!this.currentPackage.is_active) {
        this.$confirm({
          message: `
                <div>您还不是会员，请购买VIP权益</div>
                <p>1.电视、手机、电脑、IPAD四端视频版权内容</p>
                <p>2.买1得5，生活权益免费领，立省${this.lifeBenefits.desc}元</p>
              `,
          className: "xiaopai-dialog",
          confirmButtonText: "立即购买",
        }).then(() => {
          this.tobuy();
        });
      } else {
        //点击去使用跳转已开通页时，获取逻辑用户的token，替换缓存中的token
        const res = await logicUserToken({
          card_type: this.currentPackage.card_type,
        });
        storage.setItem("token", res.data.token);
        await this.fetchUserInfo();
        this.$router.push("/xiaopai/home");
      }
    },
    changePackageGroup(item) {
      this.groupActive = item.item_code;
      this.changePackage(item);
    },
    changePackage(item) {
      this.active = item.item_code;
      this.currentPackage = item;
      this.getCategory();
    },
    changeMethod(index) {
      this.selected_method = this.payMethods[index];
      this.currentChannel = this.payMethods[index].channel;
    },
    agreement() {
      window.location.href =
        "https://cdn.xiangshuheika.com/h5/agreement/pagj.html";
    },
  },
  watch: {
    groupActive(val) {
      if (this.memberPackage.length) {
        this.childs = this.memberPackage.find(
          (r) => r.item_code === val
        )?.child;
      }
    },
    currentPackage(val) {
      this.instructions = JSON.parse(this.$getConfigValue("MASTER_CARD_INFO"))[
        val.master_card_tag
      ];
      this.discount = (
        Math.floor((val.price / val.face_value) * 100 + 1) / 10
      ).toFixed(1);
    },
  },
};
</script>

<style lang="less" scoped>
.xiaopai {
  background: #ea5524;
}

.header-img {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.xp-package {
  padding: 0 12px;
  margin-top: 150px;
  position: relative;
  z-index: 2;
}

.tab ul {
  display: flex;
  align-items: flex-end;
  height: 44px;

  li {
    padding: 0 1px;
    flex: 1;
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
  /deep/ .van-image {
    margin-bottom: -1px;
  }
}

.package-benefit {
  background-color: #fff;
  padding: 10px;
  margin-top: -2px;
  border-radius: 0px 0px 10px 10px;
  position: relative;
  z-index: 2;

  .value {
    font-size: 12px;
    color: #333;
    line-height: 20px;
    padding: 10px 0;
    font-weight: bold;
    text-align: center;

    span {
      font-size: 10px;
    }
  }

  /deep/.van-sticky--fixed {
    top: auto;
    bottom: 0;
    background-color: #fff;
    padding: 8px 22px;
  }

  .rules {
    text-align: center;
    font-size: 12px;
    color: #999;
    margin-top: 10px;
    padding-bottom: 5px;

    span {
      color: #0091ff;
    }
  }
}

.open-btn {
  background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
  height: 49px;
  border-radius: 49px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  position: relative;

  .open-p {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 0 18px 0 21px;

    b {
      font-size: 24px;
      line-height: 1;
      font-size: 36px;
    }
  }

  .open-p2 {
    position: relative;
    height: 100%;
    border-radius: 49px;
    overflow: hidden;
    justify-content: space-between;
    font-size: 16px;
    &::after {
      content: "";
      position: absolute;
      width: 116px;
      height: 100px;
      left: -10px;
      top: -30px;
      background-color: #212121;
      transform: rotateZ(10deg);
    }
    .left {
      position: relative;
      z-index: 2;
    }
    .right {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: flex-end;
      div {
        padding: 0 10px;
        min-width: 62px;
        text-align: center;
      }
      p {
        font-size: 12px;
        color: #fff;
        &:first-child {
          background-color: #212121;
          border-radius: 10px 12px 12px 0px;
          text-align: center;
          line-height: 19px;
        }
        &:last-child {
          text-decoration: line-through;
          line-height: 17px;
          margin-top: 3px;
        }
      }
    }
    span.qiang {
      font-size: 33px;
      font-weight: bold;
      padding-right: 0;
    }
  }

  span {
    font-size: 22px;

    &:last-child {
      padding-right: 10px;
    }
  }

  .save {
    position: absolute;
    border-radius: 10px 12px 12px 0px;
    background: #ffbc10;
    height: 22px;
    line-height: 22px;
    padding: 0 6px;
    font-size: 12px;
    color: #fff;
    right: 0;
    top: -11px;
  }
}

.privilege {
  margin: 12px 12px 0;
}

.container {
  background: #fff;
  border-radius: 10px;
  margin: 12px 12px 0;
  padding: 18px 10px 1px;
  position: relative;

  /deep/.title {
    text-align: center;
    font-size: 18px;
    color: #333;
    font-weight: bold;
    span {
      font-size: 14px;
      color: #999;
      font-weight: normal;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 16px;

    li {
      width: 49%;
      margin-bottom: 16px;
      position: relative;
      .img {
        width: 100%;
        height: 96px;
        border-radius: 5px;
        overflow: hidden;
      }

      .name {
        font-weight: bold;
        font-size: 15px;
        color: #333;
        margin-top: 10px;
        line-height: 21px;
      }
      .price {
        position: absolute;
        left: 10px;
        top: 10px;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        sub {
          font-size: 0.5em;
          margin-right: 2px;
        }
      }

      p {
        margin-top: 4px;
        color: #333;
        font-size: 12px;
        line-height: 17px;
      }

      .get {
        margin-top: 10px;
        width: 88px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        border-radius: 28px;
        background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
        color: #fff;
        font-size: 13px;
      }
    }
  }
}

.process-img {
  width: 100%;
  min-height: 100px;
  margin-bottom: 25px;
}

.location {
  text-align: center;
  font-size: 12px;
  color: #fd5e01;
  padding: 12px 0;
}

.content {
  padding: 24px 12px 15px;
  position: relative;

  .top {
    display: flex;
    justify-content: space-between;
    padding: 9px 0;
    font-size: 16px;
    color: #222;
    border-bottom: 1px solid #f5f5f5;
    font-weight: bold;

    p {
      color: #ff5001;
      font-size: 20px;

      span {
        font-size: 12px;
      }
    }
  }

  .method-tit {
    font-size: 15px;
    color: #222;
    margin-top: 13px;
    font-weight: bold;
  }

  .methods {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      font-size: 12px;
      color: #333;
      font-weight: bold;

      img {
        width: 16px;
        height: 16px;
      }

      .left {
        display: flex;
        align-items: center;
      }

      .pay-item-icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }
  }

  .agreement {
    margin-top: 12px;
    color: #999;
    font-size: 12px;

    span {
      color: #0091ff;
    }
  }

  .btn {
    background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
    height: 49px;
    border-radius: 49px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    position: relative;
    margin: 15px 15px 0;
    .open-p {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 0 18px 0 21px;

      b {
        font-size: 24px;
      }
    }

    .open-p2 {
      position: relative;
      height: 100%;
      border-radius: 49px;
      overflow: hidden;
      justify-content: space-between;
      font-size: 16px;

      &::after {
        content: "";
        position: absolute;
        width: 116px;
        height: 100px;
        left: -10px;
        top: -30px;
        background-color: #212121;
        transform: rotateZ(10deg);
      }

      .left {
        position: relative;
        z-index: 2;
      }

      .right {
        position: relative;
        z-index: 2;
        transform: translateY(-2px);
        span {
          font-size: 12px;
          &:last-child {
            padding: 0 12px 0 25px;
            font-size: 18px;
          }
        }
      }
    }
  }

  .close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 16px;
    height: 16px;
  }
}
.rules-wrap {
  height: 330px;
  overflow-y: scroll;
}
.jiao {
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
  border-radius: 8px 0px 8px 0px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  height: 21px;
  line-height: 21px;
  width: 60px;
}
/deep/.instructions {
  margin-top: 12px;
  color: #666;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 12px;
}
.benefit-item {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #222;
  margin-top: 10px;
  line-height: 1;
  span {
    color: #999;
    text-decoration: line-through;
  }
}
/deep/.indent {
  padding-left: 13px;
}
.small-tab {
  background: #fff;
  padding: 13px 0 14px;
}
.small-tab-ul {
  display: flex;
  padding-bottom: 8px;
  position: relative;
  width: 220px;
  margin: 0 auto;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: #ebebeb;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 2px;
    z-index: 1;
  }
}

.package-image {
  max-width: 100%;
  height: 154px;
}
.active-line {
  display: block;
  width: 50%;
  height: 3px;
  background: #fd5e01;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 2px;
  z-index: 2;
  transition: all 0.3s;
}
.small-tab-item {
  flex: 1;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  color: #666;
  &.active {
    color: #fd5e01;
  }
}
</style>