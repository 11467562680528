<template>
  <van-dialog
    theme="confirm common-dialog-confirm"
    show-cancel-button
    @cancel="close"
    @confirm="submit"
    :value="value"
    title="请选择您需要领取的权益"
  >
    <div class="railSourceList">
      <div
        v-for="(item, index) in bsList"
        :class="railSource_selected === index ? 'bs-item active' : 'bs-item'"
        :key="item.bs_id"
        @click="railSource_selected = index"
      >
        <van-image
          v-if="railSource_selected === index"
          class="atour-active"
          :src="require('../../assets/images/atour-selected2.png')"
        />
        <div class="railText">
          <van-image
            v-if="item.source === '金卡体验卡'"
            class="atour-bgd"
            :src="require('../../assets/images/atour-gold.png')"
          />
          <van-image
            v-if="item.source === '会员权益'"
            class="atour-bgd"
            :src="require('../../assets/images/atour-silver.png')"
          />
          <van-image
            class="rail-logo"
            :src="require('../../assets/images/atour-logo.png')"
          />
          <div class="rail-title">{{ item.title }}</div>
          <div class="rail-amount">x{{ item.amount }}</div>
        </div>
      </div>
    </div>
  </van-dialog>
</template>

<script>
import { activateBenefit, queryBsList } from "@/apis/home";
import { mapActions } from "vuex";
export default {
  props: ["showAtour", "refresh"],
  data() {
    return {
      railSource_selected: 0,
      value: false,
      bsList: [],
    };
  },
  async mounted() {
    const loading = this.$loading();
    try {
      const res = await queryBsList({
        benefit_type: this.$route.query.benefit_type,
      });
      this.bsList = res.data
        .filter(
          (r) =>
            (r.source === "金卡体验卡" || r.source === "会员权益") &&
            r.amount > 0
        )
        .reverse();
      if (this.bsList.length === 0) {
        this.$confirm({
          title: "温馨提示",
          hideClose: true,
          message: "您的酒店会员均已领取过，请在我的订单中查看并激活使用",
          confirmButtonText: "去查看",
        })
          .then(() => {
            this.$router.push("/order?status=success");
          })
          .catch((r) => {
            this.close();
          });
      } else {
        this.value = true;
      }
    } finally {
      loading.clear();
    }
  },
  methods: {
    close() {
      this.$emit("update:showAtour", false);
    },
    async submit() {
      const loading = this.$loading();
      let opts = {
        benefit_type: this.$route.query.benefit_type,
        benefit_source: this.bsList[this.railSource_selected].source,
        is_zero_pay: true,
        order_name: "亚朵酒店会员",
      };
      try {
        const res = await activateBenefit(opts);
        this.refresh();
        this.value = false;
        this.$confirm({
          title: "温馨提示",
          hideClose: true,
          message: "您已成功领取会员兑换码，请在我的订单中查看并激活使用",
          confirmButtonText: "去查看",
        })
          .then(() => {
            this.$router.push(
              `/orderDetail?order_number=${res.data.order_number}`
            );
          })
          .catch((r) => {
            this.close();
          });
      } finally {
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.railSourceList .bs-item {
  overflow: hidden;
  border: 1px solid #fff;
  padding: 8px;
  border-radius: 11px;
  position: relative;
  overflow: hidden;
}
.railSourceList .bs-item.active {
  border: 1px solid rgb(255, 95, 75);
}
.rail-logo {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 8px;
  top: 10px;
}
.atour-active {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 0;
  bottom: 0;
  line-height: 1;
}

.atour-bgd {
  width: 100%;
  overflow: hidden;
  float: left;
}
.atour-bgd img {
  float: left;
}
.railText {
  position: relative;
  overflow: hidden;
}
.rail-title {
  font-size: 14px;
  color: #333;
  position: absolute;
  left: 29px;
  top: 8px;
  font-weight: bold;
  line-height: 1.4;
}
.rail-amount {
  font-size: 14px;
  color: #333;
  position: absolute;
  right: 8px;
  top: 8px;
  line-height: 1.4;
}
.railSourceList-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1001;
  background-color: rgba(51, 51, 51, 0.7);
}
.railSourceList-container {
  position: absolute;
  width: 312px;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 20px;
}
</style>