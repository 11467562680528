<template>
  <div class="luck-draw-body">
    <van-image
      class="luck-draw-bg"
      :src="activityInfo.special_info.header_img"
    />
    <div class="scratch-card luck-draw-component">
      <div
        id="scratchContainer"
        ref="scratchRef"
        :style="`background-image:url(${
          showMask ? require('../../assets/images/scratch-mask.png') : ''
        })`"
        class="scratch-inner"
      ></div>
      <div class="scratch-mask" v-if="showMask">
        <div class="scratch-btn" @click="drawHandleThrottle">点我刮奖</div>
        <p class="scratch-times">
          剩余 {{ remainingTimes > 0 ? `${remainingTimes}` : 0 }} 次
        </p>
      </div>
    </div>
    <van-popup
      :class="`draw-scratch-popup`"
      v-model="showDialog"
      :duration="0.2"
      get-container="body"
      :close-on-click-overlay="false"
    >
      <div class="dialog-prize">
        <div class="dialog-prize-title">
          {{ activityReward.status !== 6 ? "恭喜您" : "很遗憾" }}
        </div>
        <div class="dialog-prize-subtitle">
          {{
            activityReward.status !== 6
              ? "本次刮到的奖品是"
              : "您与奖品擦肩而过"
          }}
        </div>
        <van-image
          :src="activityReward.img"
          class="dialog-prize-img"
          fit="cover"
          lazy-load
        />
        <div class="dialog-prize-btn">
          <div
            class="dialog-prize-btn0 dialog-prize-btn1"
            @click="showPrizeHandle"
          >
            查看我的奖品
          </div>
          <div class="dialog-prize-btn0 dialog-prize-btn2" @click="closeDialog">
            再刮一次
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ScratchCard, SCRATCH_TYPE } from "scratchcard-js";
import { defineComponent, ref } from "@vue/composition-api";
import throttle from "lodash.throttle";

export default defineComponent({
  inheritAttrs: false,

  props: {
    beforeDraw: Function,
    afterDraw: Function,
    openPrizePopup: Function,
    remainingTimes: Number,
    activityReward: Object,
    activityInfo: Object,
  },
  setup(props) {
    const showMask = ref(true);
    const scratchRef = ref(null);
    const showDialog = ref(false);
    // 展示刮奖区域
    const drawScratch = (prizeImg) => {
      const sc = new ScratchCard("#scratchContainer", {
        scratchType: SCRATCH_TYPE.SPRAY,
        containerWidth: scratchRef.value.offsetWidth,
        containerHeight: scratchRef.value.offsetHeight,
        imageForwardSrc: require("../../assets/images/scratch-mask.png"),
        htmlBackground: `<img src=${prizeImg}>`,
        clearZoneRadius: 30,
        nPoints: 30,
        pointSize: 5,
        callback: () => {
          // 刮奖结束，显示弹窗
          showDialog.value = true;
        },
      });
      // Init
      sc.init().then(() => {
        showMask.value = false;
      });
    };

    const closeDialog = () => {
      showDialog.value = false;
      scratchRef.value.innerHTML = "";
      showMask.value = true;
    };

    const showPrizeHandle = () => {
      props.openPrizePopup();
      closeDialog();
    };

    const drawHandle = async () => {
      const res = await props.beforeDraw();
      if (!res) {
        return;
      }
      drawScratch(res.img);
      await props.afterDraw();
    };
    const drawHandleThrottle = throttle(drawHandle, 3000, { trailing: false });
    return {
      showMask,
      drawHandleThrottle,
      scratchRef,
      showDialog,
      closeDialog,
      showPrizeHandle,
    };
  },
});
</script>

<style lang="less" scoped>
.scratch-card {
  width: 270px;
  height: 114px;
  margin: 0 auto;
  position: relative;
}
.scratch-container {
  width: 100%;
  height: 100%;
}

.scratch-inner {
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  /deep/ .sc__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    img {
      max-width: 160;
      max-height: 80px;
    }
  }
  /deep/ .sc__canvas {
    position: relative;
    z-index: 2;
  }
}

.scratch-mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scratch-btn {
  width: 145px;
  height: 40px;
  margin-bottom: 6px;
  background: linear-gradient(90deg, #fec87d 0%, #ff6051 53%, #f6627a 100%);
  box-shadow: inset 0px 3px 3px 0px rgba(255, 255, 255, 0.5);
  text-align: center;
  border-radius: 40px;
  color: #fff;
  line-height: 40px;
  font-size: 16px;
}

.draw-scratch-popup {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/scratch_dialog_bg.png") no-repeat center /
    100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .dialog-prize-img {
    max-width: 110px;
    margin: 0 auto;
  }
  .dialog-prize {
    width: 300px;
    height: 320px;
  }
  .dialog-prize-title {
    text-align: center;
    color: #cf4b2a;
    font-size: 24px;
    margin-top: 18px;
  }
  .dialog-prize-subtitle {
    text-align: center;
    color: #cf4b2a;
    font-size: 18px;
    margin-top: 38px;
    margin-bottom: 20px;
  }

  .dialog-prize-btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    padding: 0 20px;
  }
  .dialog-prize-img {
    height: 110px;
  }
  .dialog-prize-btn1 {
    color: #ff1300;
    border: 1px solid currentColor;
    margin-right: 10px;
  }
  .dialog-prize-btn2 {
    background: linear-gradient(90deg, #fec87d 0%, #ff6051 53%, #f6627a 100%);
    box-shadow: inset 0px 3px 3px 0px rgba(255, 255, 255, 0.5);
    color: #fff;
  }
  .dialog-prize-btn0 {
    border-radius: 34px;
    font-size: 16px;
    text-align: center;
    height: 34px;
    line-height: 34px;
    flex: 1;
    box-sizing: border-box;
  }
}
</style>