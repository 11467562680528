<template>
  <section
    :class="{ 'luck-draw': true, [`luck-draw-${type}`]: true }"
    :style="{
      backgroundColor: activityInfo.special_info.theme_color,
    }"
  >
    <div id="custom-style" ref="styleRef"></div>
    <div class="show-prize-btn" @click="scrollToPrize">奖品列表</div>
    <component
      :is="luckDrawComponent"
      :type="type"
      :beforeDraw="beforeDraw"
      :afterDraw="afterDraw"
      :remainingTimes="remainingTimes"
      :activityReward="activityReward"
      :openPrizePopup="openPrizePopup"
      :activityInfo="activityInfo"
      :prizeRecord="prizeRecord"
      :toUse="toUse"
      :toGet="toGet"
      :prizeConfigList="prizeConfigList"
    />

    <!-- 奖品图片 -->
    <PrizeList
      :prizeRecord="prizeRecord"
      :type="type"
      :activityInfo="activityInfo"
      ref="prizeListRef"
      :activityName="activityName"
    />
    <div class="prize-result" v-if="activityInfo.special_info.prize_list_img">
      <van-image
        lazy-load
        class="prize-result-img"
        :src="activityInfo.special_info.prize_list_img"
        alt=""
      />
    </div>
    <div class="luck-draw-rule">
      <div class="luck-rule-inner">
        <div class="draw-rules-title">活动规则：</div>
        <div class="draw-rules-text" v-html="activityInfo.rules"></div>
      </div>
    </div>
    <open-member-dialog page="activity" />
  </section>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import PrizeList from "./PrizeList";
import Blindbox from "./Blindbox";
import LuckyTurn from "./LuckyTurn";
import LuckyGrid from "./LuckyGrid";
import MidAutumn from "./MidAutumn";
import Qiyuan from "./Qiyuan";
import OpenMemberDialog from '@/components/OpenMemberDialog'
import SlotMachine from "./SlotMachine";
import Drawlot from "./Drawlot";
import Egg from "./Egg";
import Scratch from "./Scratchcard";
import {  storage } from "@/utils";
import { handleActivityReward } from "@/apis/luckdraw";
import Vue from "vue";
import store from "@/store";
import ans from "@/utils/ans";
import animateScrollTo from "animated-scroll-to";
const vm = Vue.prototype;

export default defineComponent({
  inheritAttrs: false,
  props: {
    activityInfo: Object,
    fetchActivityInfo: Function,
    prizeRecord: Array,
    activityName: String,
  },
  components: {
    PrizeList,
    Blindbox,
    LuckyTurn,
    SlotMachine,
    LuckyGrid,
    MidAutumn,
    Drawlot,
    Scratch,
    Egg,
    Qiyuan,
    OpenMemberDialog
  },
  setup({ activityInfo, fetchActivityInfo, activityName }) {
    const type = computed(() => activityInfo.special_info.type);
    const styleRef = ref(null);
    const prizeListRef = ref(null);
    const activityReward = reactive({});
    const userInfo = store.state.userInfo;

    const luckDrawComponent = computed(() => {
      const Comp = {
        blindbox: "Blindbox",
        lucky: "LuckyTurn",
        luckyGrid: "LuckyGrid",
        midAutumn: "MidAutumn",
        drawlot: "Drawlot",
        scratch: "Scratch",
        slotMachine: "SlotMachine",
        egg: "Egg",
        qiyuan: "Qiyuan",
      };
      return Comp[type.value];
    });

    // 剩余次数
    const remainingTimes = computed(() => {
      if (activityInfo.special_info.member_open_activity) {
        return activityInfo.keepAcitvityTimes;
      }
      return (
        activityInfo.special_info.times_per_day - activityInfo.amt_of_today
      );
    });

    const prizeConfigList = computed(() => {
      const goods_choice = activityInfo.special_info.goods_choice || {};
      return Object.entries(goods_choice).map(([k, v], index) => {
        return {
          ...v,
          item_code: k,
        };
      });
    });

    const beforeDraw = async (showLoading) => {
      let token = storage.getItem("token");
      if (!token) {
        vm.$toast("您还未登录，请先登录~");
        return;
      }
      // 针对会员权益活动 结束活动后可以继续参与
      if (
        !activityInfo.is_active &&
        !activityInfo.special_info.member_open_activity
      ) {
        vm.$toast("活动已结束");
        return;
      }
      //如果不是会员 并且 非会员不能参与
      if (!userInfo.is_member) {
        const un_index = vm.$getConfigValue("UN_INDEX");
        if (un_index) {
          vm.$confirm({
            message: "您还不是会员，开通会员即可享受特惠权益",
            cancelButtonText: "我再想想",
            confirmButtonText: "去开通",
          }).then(() => {
            location.href = un_index;
          });
        } else {
          vm.$toast("本活动仅限VIP会员参加，请先开通会员~");
        }
        return;
      }
      if (!remainingTimes.value) {
        vm.$toast(activityInfo.special_info.no_times_tips || "没有次数了哦~");
        return;
      }
      let loading;
      if (showLoading) {
        loading = vm.$loading();
      }
      try {
        const res = await handleActivityReward({
          activity_name: activityName,
          source: "会员权益",
          reward_platform: activityInfo.reward_platform,
          hideMsg: true,
        });
        Object.assign(activityReward, res.data);
        ans.track("blindbox_benefit_click", {
          item_code: res.data.item_code,
        });
        return res.data;
      } catch (e) {
        vm.$toast(e?.msg || "活动太火爆了，请稍后再试");
        Promise.reject();
      } finally {
        loading && loading.clear();
      }
    };
    const afterDraw = async () => {
      await Promise.all([fetchActivityInfo()]);
    };

    const openPrizePopup = () => {
      prizeListRef.value.open();
    };

    const toUse = (item) => {
      prizeListRef.value.toUse(item);
    };

    const toGet = (item) => {
      prizeListRef.value.toGet(item);
    };

    const scrollToPrize = () => {
      if (type.value === "egg") {
        prizeListRef.value.open();
      } else {
        const el = document.querySelector(".prize-result");
        el.style.display = "block";
        animateScrollTo(el);
      }
    };

    watch(activityInfo, () => {
      if (activityInfo.special_info?.style) {
        document.getElementById(
          "custom-style"
        ).innerHTML = `<style>${activityInfo.special_info.style}</style>`;
      }
    });

    return {
      luckDrawComponent,
      type,
      remainingTimes,
      beforeDraw,
      afterDraw,
      styleRef,
      openPrizePopup,
      prizeListRef,
      toUse,
      toGet,
      activityReward,
      scrollToPrize,
      prizeConfigList,
      // behavioralVerify,
    };
  },
});
</script>


<style lang="less" scoped>
.luck-draw {
  /deep/ .luck-draw-body {
    position: relative;
  }
  /deep/ .luck-draw-bg {
    min-height: 50vh;
    img {
      width: 100%;
      height: auto;
    }
  }
  /deep/ .luck-draw-component {
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translateX(-50%);
  }
  /deep/ .draw-btn-wrap {
    margin: 0 20px;
  }
}

.luck-draw-rule {
  padding: 10px 12px;
}
.luck-rule-inner {
  border-radius: 10px;
  padding: 15px 10px;
  font-size: 12px;
  color: #fff;
  background-repeat: no-repeat;
  background-size: 100%;
}
.draw-rules-title {
  font-size: 14px;
  font-weight: bold;
}
.draw-rules-text {
  line-height: 1.5;
  margin-top: 10px;
  white-space: pre-wrap;
}

.luck-draw-midAutumn,
.luck-draw-drawlot {
  /deep/ .prize-list-btn {
    display: none;
  }
}

.prize-result {
  display: none;
  margin: 20px 12px 0;
  img {
    width: 100%;
  }
}

.show-prize-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 76px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  border-radius: 28px 0 0 28px;
  background: rgba(#01acea, 0.7);
  color: #fff;
  text-align: center;
  z-index: 10;
  padding-left: 10px;
  box-sizing: border-box;
}
</style>a