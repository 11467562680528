<template>
  <van-popup
    closeable
    position="bottom"
    round
    @click-overlay="closePopup"
    @click-close-icon="closePopup"
    @close="closePopup"
    :style="{ height: '80%' }"
    :value="value"
  >
    <div class="popup-container">
      <div class="popup-title">{{ title || "选择日期" }}</div>
      <div class="popup-body">
        <Calendar v-bind="$props" />
      </div>
    </div>
  </van-popup>
</template>

<script>
import { sleep } from "@/utils";
import { mapMutations } from "vuex";
import Calendar from "./Calendar";

export default {
  props: [
    "value",
    "close",
    "resolve",
    "title",
    "getPriceList",
    "type",
    "tips",
    "minDate",
    "maxDate",
    "range",
    "rangeDate",
    "single",
  ],
  components: {
    Calendar,
  },
  provide() {
    return {
      resolve: this.onChange,
    };
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapMutations(["changeHotel"]),
    async onChange(res) {
      if (!this.single) {
        await sleep(800);
      }
      this.resolve(res);
      this.close();
    },
    closePopup() {
      this.close();
    },
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        // 切换页面时关闭窗口
        this.closePopup();
      }
    },
  },
};
</script>

<style scoped>
.popup-title {
  padding: 15px 15px 10px;
  line-height: 22px;
  justify-content: space-between;
  text-align: center;
  font-size: 16px;
  flex-shrink: 0;
  position: relative;
  font-weight: bold;
}
.popup-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.popup-body {
  display: flex;
  flex-direction: column;
  height: calc(100% - 47px);
}
</style>