import { storage } from '@/utils';
import dayjs from 'dayjs';
const firstDay = dayjs().startOf("day").add(2, 'day')
const parkBookDate = dayjs(storage.getItem('parkBookDate') || firstDay)
export default {
  state: {
    parkBookDate: parkBookDate.isAfter(firstDay) ? parkBookDate : firstDay,
  },
  getters: {

  },
  mutations: {
    changeBookDate(state, payload) {
      state.parkBookDate = payload;
      storage.setItem('parkBookDate', payload);
    }
  },
  actions: {


  }
}
