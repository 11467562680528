<template>
  <div
    :class="{ 'qm-member': true, [`qm-member-${namespace}`]: true }"
    v-if="!fetching"
  >
    <div :class="`qm-card ${firstCard}-card-wrap`">
      <van-swipe
        class="swiper-card"
        :loop="false"
        :show-indicators="false"
        @change="changeCard"
      >
        <van-swipe-item
          v-for="(item, idx) in cardList"
          :key="item.namespace"
          :class="{
            'slide-prev': idx + 1 === active,
            'slide-active': idx === active,
            'slide-next': active + 1 === idx,
          }"
        >
          <div :class="`qm-card-item ${item.card_type.toLowerCase()}-card`">
            <div class="card-exprie">到期时间：{{ item.t_expiration }}</div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div :class="`member-benefits member-benefits-${namespace}`">
      <div
        v-for="item in catesgoryList"
        :key="item.display_code"
        :class="`member-benefit member-benefit-${item.display_code}`"
      >
        <div :class="`benefit-title ${item.display_code}-img`"></div>
        <div class="benefit-list">
          <template v-if="item.display_code === 'partner_privileges'">
            <div class="parnter-list">
              <div
                class="parnter-list-item"
                v-for="(row, index) in item.list"
                :key="row.desc"
              >
                <van-image
                  class="parnter-list-image"
                  lazy-load
                  :src="row.display_img_url"
                />
                <div class="parnter-list-info ellipsis">
                  <div class="parnter-title">{{ row.desc }}</div>
                  <div class="parnter-subtitle ellipsis">
                    {{ row.selling_points }}
                  </div>
                </div>
                <div
                  v-ans="{
                    event_id: 'benefit_list_click',
                    sequence: index,
                    namespace,
                    spu_id: row.id,
                    spu_name: row.desc,
                  }"
                  @click="toDetail(row)"
                  :style="`background-image:url(${row.icon_img_url})`"
                  :class="{
                    'parnter-btn': true,
                    'parnter-btn-disabled': row.is_cube,
                  }"
                ></div>
              </div>
            </div>
          </template>
          <div class="life-list" v-if="item.display_code === 'life_privileges'">
            <template>
              <div
                v-for="(row, index) in item.list"
                v-ans="{
                  event_id: 'benefit_list_click',
                  sequence: index,
                  namespace,
                  spu_id: row.id,
                  spu_name: row.desc,
                }"
                class="list-inner"
                :key="row.name"
                @click="toDetail(row)"
              >
                <div class="life-list-item">
                  <van-image
                    class="life-list-image"
                    :src="row.display_img_url"
                    fit="contain"
                  />
                  <div class="life-title">
                    <span
                      v-if="
                        namespace === 'qmwallet1' ||
                        namespace === 'qmwallet2' ||
                        namespace === 'qmqb1'
                      "
                      class="life-title-number"
                      >权益{{ index + 1 }}</span
                    >
                    {{ row.desc }}
                    <span
                      v-if="
                        namespace === 'qmwallet1' ||
                        namespace === 'qmwallet2' ||
                        namespace === 'qmqb1'
                      "
                      class="life-title-sellpoint"
                      >{{ item.selling_points }}</span
                    >
                  </div>
                  <div
                    :class="{
                      'life-use': true,
                      'life-use-disabled':
                        row.benefit &&
                        (row.benefit.amount_available == 0 ||
                          row.benefit.amount_reserved +
                            row.benefit.amount_available ===
                            0),
                      'life-use-amount':
                        row.benefit && row.benefit.amount_available > 0,
                    }"
                  >
                    <div
                      class="amount"
                      v-if="row.benefit && row.benefit.amount_available > 0"
                    >
                      剩{{
                        row.benefit.amount_available +
                        row.benefit.amount_reserved
                      }}次
                    </div>
                    <div class="use">{{ getBenefitText(row.benefit) }}</div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="member-footer">
      <div @click="$router.push('/service')" class="footer-item footer-service">
        专属客服
      </div>
      <div class="footer-item footer-help" @click="$router.push('/help')">
        常见问题
      </div>
    </div>
    <div
      class="to-order-btn"
      v-ans="{ event_id: 'benefit_detail_click_button2' }"
      @click="$router.push('/order')"
    >
      我的订单
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { getListBenefit } from "@/apis";

import {
  getIndexCategory,
  getIndexClassList,
  getMutilCard,
} from "@/apis/product";
import benefitMixin from "@/mixins/benefitMixin";
export default {
  mixins: [benefitMixin],
  props: ["namespace"],
  data() {
    return {
      active: 0,
      fetching: true,
      cardList: [],
      catesgoryList: [],
    };
  },
  computed: {
    ...mapState(["source"]),
    firstCard() {
      return this.cardList.length
        ? this.cardList[0].card_type.toLowerCase()
        : "";
    },
  },
  async mounted() {
    const loading = this.$loading();
    try {
      await Promise.all([this.fetchCategory(), this.fetchMultCard()]);
    } finally {
      this.fetching = false;
      loading.clear();
    }
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    ...mapMutations(["changeState"]),
    async changeCard(index) {
      this.active = index;
      const card = this.cardList[index];
      const loading = this.$loading();
      this.changeState({ token: card.token });
      try {
        await this.fetchUserInfo();
        await this.fetchCategory();
      } finally {
        loading.clear();
      }
    },
    getBenefitText(benefit) {
      if (!benefit) {
        return "去使用";
      }
      if (benefit.amount_available > 0) {
        return "去领取";
      } else {
        if (benefit.amount_reserved > 0) {
          return "本月已领";
        }
        return "已领完";
      }
    },

    async fetchCategory() {
      const ns_enum = {
        weiguang: "quanmin06",
        quanmin: "wg",
      };
      const [benefit, catesgory] = await Promise.all([
        getListBenefit(),
        getIndexCategory({
          namespace: ns_enum[this.namespace] || this.namespace,
          opened_page: "member",
          source: this.source,
        }),
      ]);
      const catesgoryList = catesgory.data.filter((r) => r.status === 1);
      if (catesgoryList.length) {
        const productList = await getIndexClassList({
          title: catesgoryList.map((r) => r.name).join(","),
          opened_page: "member",
          namespace: ns_enum[this.namespace] || this.namespace,
        });
        catesgoryList.forEach((item, index) => {
          item.list = productList.data[item.name]
            .filter((r) => r.status === 1)
            .map((item) => ({
              ...item,
              benefit: benefit.data.find((r) => r.type === item.product_code),
            }));
        });
        this.catesgoryList = catesgoryList;
      } else {
        this.catesgoryList = [];
      }
    },
    async fetchMultCard() {
      const res = await getMutilCard({
        namespace: this.namespace,
        source: this.source,
      });
      const cardList = res.data;
      const index = cardList.findIndex((r) => r.namespace === this.namespace);
      if (index > 0) {
        cardList.reverse();
      }
      this.cardList = cardList;
    },
  },
};
</script>

<style lang="less" scoped>
.member-benefits {
  padding: 0 16px;
  .member-benefit {
    border-radius: 5px;
  }
}

@yhdlist: ydh1, ydh2;
@ydhbgcolor: #18191e, #101116;
@ydhcolor: #532b95, #6f5353;
@ydhfootcolor: #7a61e4, #835959;

each(@yhdlist, {
  .ydh(@v, @i){
    background-color: extract(@ydhbgcolor, @i);
    .ry06-card-wrap {
      height: 226px;
    }
    .qmydh06-card-wrap {
      height: 226px;
    }
    .card-exprie {
      color: extract(@ydhcolor, @i);
    }
    .member-footer {
      height: 68px;
      background: url("../../assets/images/ydh1_footer_bg.png") no-repeat;
      background-size: 100%;
      .footer-item {
        text-align: center;
        color: extract(@ydhfootcolor, @i);
        font-size: 18px;
        background-position: 30px center;
      }
      .footer-help {
        background-image: url("../../assets/images/footer-help-@{v}.png");
      }
      .footer-service {
        background-image: url("../../assets/images/footer-service-@{v}.png");
      }
    }
  }
  .qm-member.qm-member-qmydh1 when (@index=1) {
    color:red;
    .ydh(@value, @index);
  }
  .qm-member.qm-member-@{value} {
    .ydh(@value, @index);
  }
})
  .member-benefits-ydh1,
.member-benefits-qmydh1,
.member-benefits-ydh2 {
  .parnter-list-image {
    flex-basis: 44px !important;
    height: 44px !important;
  }
  .member-benefit-partner_privileges {
    height: 445px;
    background-repeat: no-repeat;
    background-size: 100%;
    box-sizing: border-box;
    padding-top: 50px;
    background-color: transparent;
  }
  .member-benefit-life_privileges {
    height: 1120px;
    background-size: 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    margin-top: 20px;
    background-color: transparent;
  }
  .benefit-title {
    display: none;
  }

  .life-list {
    display: block;
    padding-top: 5px;
    .list-inner {
      width: 100%;
      position: relative;
      height: 133px;
      padding: 17px 0 !important;
      box-sizing: border-box;
    }
  }
  .life-list-item {
    display: flex;
    flex-direction: column;
  }

  .life-title {
    order: 1;
    height: 32px;
    line-height: 32px !important;
    width: 177px;
    box-sizing: border-box;
    color: #fff !important;
    background-size: 100%;
    text-indent: 45px;
    margin-top: 0 !important;
    font-weight: normal !important;
    margin-bottom: 20px !important;
  }
  .life-use-disabled {
    text-align: center !important;
    line-height: 28px;
  }
  .life-use-amount {
    background: linear-gradient(90deg, #fffff4, #dbcf92) !important;
    text-align: center;
    padding: 0 10px;
    .amount,
    .use {
      color: #68448c;
      flex-basis: auto;
      width: max-content;
      text-align: left;
      display: inline-block;
      line-height: 28px;
    }
    .amount {
      &::after {
        content: "/";
      }
    }
    .use {
      &::after {
        content: " >";
      }
    }
  }
  .life-list-image {
    order: 3;
    height: 47px !important;
    width: 180px;
    max-width: 60%;
    /deep/ img {
      width: auto;
      height: 47px;
    }
  }

  .life-use {
    display: block !important;
    position: absolute;
    bottom: 16px;
    right: 10px;
  }
}

.member-benefits-ydh1,
.member-benefits-qmydh1 {
  .member-benefit-partner_privileges {
    background-image: url("../../assets/images/ydh1-benefit-bg.png");
  }
  .member-benefit-life_privileges {
    background-image: url("../../assets/images/ydh1-benefit-bg2.png");
  }
  .parnter-title {
    color: #fcfcfc !important;
  }
  .parnter-subtitle {
    color: #665a85 !important;
  }
  .life-title {
    background: url("../../assets/images/ydh1_title_bg.png") no-repeat left/
      100%;
  }
}

.member-benefits-qmydh1 {
  .member-benefit-life_privileges {
    background-image: url("../../assets/images/qmydh1-benefit-bg2.png");
    height: 838px;
  }
}

.member-benefits-ydh2 {
  .member-benefit-partner_privileges {
    background-image: url("../../assets/images/ydh2-benefit-bg.png");
    margin-top: 20px;
  }
  .member-benefit-life_privileges {
    background-image: url("../../assets/images/ydh2-benefit-bg2.png");
  }
  .parnter-title {
    color: #fcfcfc !important;
  }
  .parnter-subtitle {
    color: #706b67 !important;
  }
  .life-title {
    width: 182px;
    background: url("../../assets/images/ydh2_title_bg.png") no-repeat left/
      100%;
  }
  .life-use-amount {
    background: #f5d8a3 !important;
    .use {
      color: #333;
    }
  }
  .member-benefit-life_privileges {
    height: 1210px;
  }
}
</style>