<template>
  <main class="oil-detail" v-if="pageshow">
    <div class="oil-header">
      <van-image class="logo" :src="oil_info.logo_small" />
      <div class="info">
        <div class="top">
          <div>{{ oil_info.station_name }}</div>
          <div class="navigation" @click="toMap(oil_info.station_name)">
            <img
              src="https://cdn.xiangshuheika.com/static/oil/address2.png"
              alt=""
            />
            {{ oil_info.distance_km }}km
          </div>
        </div>
        <div class="address">{{ oil_info.address }}</div>
        <div class="price">
          <div class="item1">
            <p>优惠价</p>
            <div>
              <span>¥</span>{{ current_oil_info.oil_price_yfq }}<span>/L</span>
            </div>
          </div>
          <div class="item2">
            <p>国标价</p>
            <div>
              ¥<span>{{ current_oil_info.oil_price_official }}</span
              >/L
            </div>
          </div>
          <div class="item2">
            <p>油站价</p>
            <div>
              ¥<span>{{ current_oil_info.oil_price_gun }}</span
              >/L
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="oil-gun">
      <div class="gun-tab">
        <div
          :class="{
            item: true,
            active: current_oil_name === item,
          }"
          v-for="(item, index) in gun_tab"
          :key="item"
          @click="changeOilName(item)"
        >
          {{ item }}
        </div>
      </div>
      <div class="main">
        <p>选择油枪号（请在向油站工作人员确认后再进行选择）：</p>
        <div class="gun-list">
          <div
            :class="{
              item: true,
              active: current_gun_index === index,
            }"
            @click="selectGun(index)"
            v-for="(item, index) in current_gun_nos"
          >
            {{ item }}
          </div>
        </div>
        <div class="top-up-price">
          <van-field
            v-model="price"
            type="number"
            placeholder="请输入加油金额"
          />
          <p>
            <b>元</b> <span>/约{{ litre }}L</span>
          </p>
        </div>
        <div class="preferential">
          <div
            :class="{ item: true, active: current_price === index }"
            v-for="(item, index) in preferential_price_info"
            @click="change(index, item.totalPrice)"
          >
            ¥{{ item.totalPrice }}
            <p>优惠 ¥{{ item.promotionAmount }}</p>
          </div>
        </div>
      </div>
      <div class="price-info" v-if="Number(price) >= 10 && !loading">
        <div class="item">
          订单金额： <span>¥{{ price }}</span>
        </div>
        <div class="item">
          优惠金额： <span>- ¥{{ deductionAmount }}</span>
        </div>
        <div class="item">
          服务费： <span>+ ¥{{ serviceFee }}</span>
        </div>
        <div class="combined">
          <b></b>
          <p>
            合计<span>¥{{ pay_price }}</span>
          </p>
        </div>
      </div>
      <div class="prompt">
        <div class="title">温馨提示</div>
        <div class="content">
          <p>
            1.
            由于油站限制，同一油站不能同时存在两笔未核销订单，请您加油核销后再继续下单！
          </p>
          <p>
            2.
            为保证您正常使用权益，请您在到达油站并确认好油号、油枪号、加油金额后再进行支付！
          </p>
          <p>3. 如需开具发票请您在完成加油后向油站索要发票</p>
          <p>
            4. 本服务由省心科技提供，在使用中遇到问题可拨打{{
              $getConfigValue("SHENGXIN_OIL_PHONE")
            }}咨询
          </p>
          <p>5. 请注意，输入的加油金额不小于10元。</p>
        </div>
      </div>
      <div style="height: 50px" v-if="Number(price) >= 10"></div>
    </div>
    <div class="footer" v-if="Number(price) >= 10">
      <div class="all-price">
        实付
        <div v-if="loading">
          <van-loading type="circular" size="18px" />
        </div>
        <p v-else>
          ¥<span>{{ pay_price }}</span>
        </p>
      </div>
      <div
        class="pay"
        @click="toPay"
        v-ans="{
          event_id: 'oil_detail_pay_button',
        }"
      >
        去支付
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import {
  computed,
  onMounted,
  defineComponent,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { getOilDetail, getOilPreferentialPrice } from "@/apis/oil";
import { placeOrder } from "@/apis";
import store from "@/store";
import qs from "qs";
import throttle from "lodash.throttle";
import { ENV_FLAG, getBaseUrl, IS_PROD } from "@/config";
import { storage } from "@/utils";
import pickBy from "lodash.pickby";
const vm = Vue.prototype;

export default defineComponent({
  setup() {
    const pageshow = ref(false);
    const gun_list = ref([]); //油号列表
    const oil_info = ref({}); //油站信息
    const gun_tab = ref([]); //油号标签列表
    const current_oil_name = ref(""); //当前选中油号
    const current_gun_nos = ref([]); //当前选中油号对应油枪号
    const current_gun_index = ref(-1);
    const price = ref(null);
    const preferential_price_info = ref({});
    const current_oil_info = ref({});
    const pay_price = ref(null);
    const deductionAmount = ref(null);
    const serviceFee = ref(null);
    const loading = ref(false);
    const current_price = ref(-1);

    //计算加油升数   用户输入金额 / 油枪价（油站挂牌价）
    const litre = computed(() => {
      return (price.value / current_oil_info.value.oil_price_gun).toFixed(1);
    });

    watch(current_oil_name, (newVal, oldVal) => {
      const current = gun_list.value.filter((r) => r.oil_name === newVal)[0];
      current_gun_nos.value = current.gun_nos;
      preferential_price_info.value = current.preferential_price_info;
      current_oil_info.value = current;
      if (price.value) {
        getPrice(price.value);
      }
    });

    //输入价格变化时，实时获取优惠信息
    watch(
      price,
      throttle(
        async (newVal, oldVal) => {
          getPrice(newVal);
        },
        1000,
        { leading: false }
      )
    );

    onMounted(async () => {
      const params = qs.parse(location.href.split("?")[1]);
      const { id, oil_name, longitude, latitude, phone } = params;
      const load = vm.$loading();
      const res = await getOilDetail({
        station_id: id,
        longitude,
        latitude,
        price: "100,200,300",
        phone_number: phone,
      });
      oil_info.value = res.data[0];

      gun_list.value = res.data;
      gun_tab.value = res.data.map((r) => r.oil_name);
      pageshow.value = true;
      current_oil_name.value = decodeURIComponent(oil_name);
      load.clear();
    });

    const getPrice = async (newVal) => {
      const new_price = Number(newVal);
      if (new_price && new_price >= 10) {
        loading.value = true;
        const params = qs.parse(location.href.split("?")[1]);
        const { id, phone } = params;
        const res = await getOilPreferentialPrice({
          price: new_price,
          oil_name: current_oil_name.value,
          station_id: id,
          phone_number: phone,
        });
        if (!Object.keys(res.data[new_price]).length) {
          vm.$toast("输入金额过小，请重新输入");
          return;
        }
        pay_price.value = res.data[new_price].realPrice;
        deductionAmount.value = res.data[new_price].deductionAmount;
        serviceFee.value = res.data[new_price].serviceFee;
        loading.value = false;
      } else if (new_price !== 0) {
        vm.$toast("请输入10元及以上金额");
      }
    };

    const changeOilName = (value) => {
      current_oil_name.value = value;
    };
    const selectGun = (index) => {
      current_gun_index.value = index;
    };

    const toPay = () => {
      if (current_gun_index.value < 0) {
        vm.$toast("请选择油枪号");
        return;
      }
      if (!price.value) {
        vm.$toast("请输入加油金额");
        return;
      }
      if (price.value <= 0) {
        vm.$toast("请输入正确的金额");
        return;
      }
      if (oil_info.value.distance_km > 1) {
        vm.$confirm({
          message: "您可能不在油站附近，建议您到达油站后再进行支付！",
          cancelButtonText: "取消支付",
          confirmButtonText: "继续支付",
          className: "oil-dialog",
        }).then(() => {
          pay();
        });
        return;
      }
      pay();
    };

    const pay = async () => {
      const params = qs.parse(location.href.split("?")[1]);
      const { phone, id, item_code = "ADDOIL", wxOpenId } = params;
      const { userInfo, namespace } = store.state;
      const loading = vm.$loading();
      let opts = {
        platform: "extra",
        benefit_source: "会员权益",
        item_code,
        channel: "sxtechpay_miniapp",
        order_desc: decodeURIComponent(oil_info.value.station_name),
        order_source: userInfo.card_type_cn,
        //order_name: decodeURIComponent('加油'),
        partner_jump_url: encodeURIComponent(
          (window.location.origin + window.location.pathname).replace(
            /\/$/,
            ""
          ) + `/pages/pay_result/pay_result?category_type=benefit`
        ),
        out_order_id: `OAK${navigator.productSub}${new Date().getTime()}`,
        envflag: ENV_FLAG(namespace),
        is_prod: IS_PROD,
        namespace: namespace,
        timestamp: new Date().getTime(),
        project_url: getBaseUrl(namespace),
        user_token: storage.getItem("token"),
        return_url: "/pages/pay_result/pay_result",
        openid: wxOpenId,
      };
      opts.biz_params_info = JSON.stringify({
        station_id: id,
        oil_name: current_oil_name.value,
        gun_no: current_gun_nos.value[current_gun_index.value],
        oil_amount: price.value,
        supplier_type: "shengxin",
        phone_number: phone || userInfo.user_phone,
      });
      opts = pickBy(opts, (v) => !!v || v === 0);
      const res = await placeOrder(opts);
      loading.clear();
      location.href = res.data.mweb_url;
      //const paramsData = qs.stringify(opts, { encode: false });
      //location.href = `${pay_api}?${paramsData}`;
    };

    const toMap = async (name) => {
      if (store.state.isMiniProgram || store.state.isWebchat) {
        wx.ready(() => {
          wx.openLocation({
            latitude: parseFloat(current_oil_info.value.latitude),
            longitude: parseFloat(current_oil_info.value.longitude),
          });
        });
        return;
      }
      window.location.href = `http://api.map.baidu.com/marker?location=${current_oil_info.value.latitude},${current_oil_info.value.longitude}&title=${name}&output=html`;
    };

    const change = (index, totalPrice) => {
      current_price.value = index;
      price.value = totalPrice;
    };

    return {
      pageshow,
      gun_list,
      oil_info,
      gun_tab,
      current_oil_name,
      current_gun_nos,
      current_gun_index,
      price,
      preferential_price_info,
      current_oil_info,
      litre,
      pay_price,
      deductionAmount,
      serviceFee,
      loading,
      current_price,
      changeOilName,
      selectGun,
      toMap,
      toPay,
      change,
    };
  },
});
</script>

<style lang="less" scoped>
.oil-detail {
  background-color: #f5f5f5;
  padding: 16px 12px;
}
.oil-header {
  background-color: #fff;
  display: flex;
  border-radius: 8px;
  padding: 12px;
  .logo {
    width: 70px;
    height: 70px;
    margin-right: 12px;
  }
  .info {
    flex: 1;
    .top {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #333;
      font-weight: bold;
      .navigation {
        box-sizing: border-box;
        width: 76px;
        height: 18px;
        line-height: 18px;
        background: linear-gradient(136deg, #ff6a00 0%, #f71b00 100%);
        border-radius: 9px;
        padding: 0 9px;
        display: flex;
        font-weight: normal;
        color: #fff;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }
    .address {
      min-height: 36px;
      margin-top: 10px;
      font-size: 12px;
      color: #999;
      line-height: 18px;
    }
    .price {
      margin-top: 12px;
      display: flex;
      .item1 {
        font-weight: bold;
        color: #ff5001;
        font-size: 24px;
        margin-right: 28px;
        p {
          font-size: 12px;
          font-weight: normal;
        }
        span {
          font-size: 12px;
          font-weight: normal;
          padding: 0 2px;
        }
      }
      .item2 {
        color: #999;
        font-size: 12px;
        margin-right: 20px;
        p {
          margin-bottom: 12px;
        }
        span {
          text-decoration: line-through;
        }
      }
    }
  }
}
.oil-gun {
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
  .gun-tab {
    display: flex;
    justify-content: space-between;
    .item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #222;
      background-color: #f5f5f5;
      height: 40px;
    }
    .active {
      background-color: #fff;
      color: #ff5001;
      font-weight: bold;
    }
  }
  .main {
    padding: 12px;
    background-color: #fff;
    p {
      font-size: 12px;
      color: #666;
    }
  }
}
.gun-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 12px;
  .item {
    box-sizing: border-box;
    width: 23%;
    border: 1px solid #999;
    margin-bottom: 8px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 16px;
    color: #999;
    margin-right: 2%;
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  .active {
    background-color: #ff7334;
    border: 1px solid #ff7334;
    color: #fff;
  }
}
.top-up-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /deep/ .van-field {
    background-color: #f5f5f5;
    font-size: 16px;
    color: #1a1a1a;
    border-radius: 8px;
  }
  /deep/ .van-cell::after {
    display: none;
  }
  p {
    width: 100px;
    color: #999;
    margin-left: 15px;
    b {
      font-size: 16px;
      font-weight: normal;
    }
    span {
      font-size: 12px;
    }
  }
}
.preferential {
  display: flex;
  margin-top: 12px;
  .item {
    box-sizing: border-box;
    padding-top: 5px;
    margin-right: 8px;
    border: 1px solid #999;
    width: 76px;
    height: 44px;
    font-size: 16px;
    color: #999;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: bold;
    p {
      background-color: #999;
      color: #fff;
      height: 16px;
      line-height: 16px;
      font-size: 12px;
    }
  }
  .active {
    border: 1px solid #ff7334;
    color: #ff7334;
    p {
      background-color: #ff7334;
    }
  }
}
.prompt {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 16px;
  padding: 12px;
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  .content {
    margin-top: 12px;
    font-size: 12px;
    color: #666;
    line-height: 18px;
  }
}

.footer {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  .all-price {
    font-size: 12px;
    color: #1a1a1a;
    display: flex;
    align-items: flex-end;
    font-weight: bold;
    p {
      color: #ff5001;
      line-height: 1;
      padding: 0 5px;
      span {
        font-size: 24px;
        padding-left: 3px;
      }
    }
  }
  .pay {
    width: 96px;
    height: 32px;
    background-color: #1a1a1a;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    font-size: 12px;
  }
}
.price-info {
  background-color: #fff;
  margin-top: 16px;
  padding: 12px;
  border-radius: 8px;
  .item {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #666;
    margin-bottom: 10px;
    span {
      color: #333;
    }
  }
  .combined {
    font-size: 12px;
    color: #1a1a1a;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    span {
      color: #ff5001;
      margin-left: 3px;
    }
  }
}
</style>