<template>
  <div class="hotel">
    <div class="hotel-row">
      <div
        :class="{ 'hotel-col': true, active: active === 0 }"
        @click="active = 0"
      >
        ¥299起住五星
      </div>
      <div
        :class="{ 'hotel-col': true, active: active === 1 }"
        @click="active = 1"
      >
        5折起住酒店
      </div>
    </div>
    <div class="hotel-list">
      <div class="hotel-item" v-for="item in hotelList" :key="item.hotel_id">
        <div
          class="hotel-item-inner"
          @click="$router.push(`/hotel/detail?id=${item.hotel_id}`)"
          v-ans="{
            event_id: 'business_hotel_click',
            namespace,
            hotel_id,
          }"
        >
          <van-image
            class="hotel-img"
            fit="cover"
            lazy-load
            :src="item.hotel_img"
          />
          <div class="hotel-price">
            会员价：<span class="hotel-realprice"
              ><small>¥</small>{{ item.lowest_price }}</span
            >
            <span class="hotel-facevalue">¥{{ item.lowest_origin_price }}</span>
          </div>
        </div>
      </div>
    </div>
    <div @click="$router.push('/hotel/list')" class="hotel-more">
      更多酒店 >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["specialHotel"],
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    ...mapState(["namespace"]),
    hotelList() {
      const list =
        this.active === 0
          ? this.specialHotel.special_hotel_info
          : this.specialHotel.fifty_off_hotel_info;
      return list.slice(0, 4);
    },
  },
};
</script>

<style lang="less" scoped>
.hotel-row {
  display: flex;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #333;
  font-size: 16px;
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 14px;
  background: #fff;
}
.hotel-col {
  flex: 1;
  &.active {
    background: #f9cdaf;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
}

.hotel-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -3px;
}
.hotel-item {
  width: 50%;
  margin-bottom: 7px;
  border-radius: 8px;
}
.hotel-item-inner {
  overflow: hidden;
  margin: 0 3px;
  background: #fff;
  border-radius: 10px;
}
.hotel-img {
  height: 158px;
  display: block;
}
.hotel-price {
  padding: 10px 8px;
}
.hotel-realprice {
  color: #f5af7f;
  font-size: 20px;
  margin-right: 10px;
  small {
    font-size: 12px;
  }
}
.hotel-more {
  color: #999;
  line-height: 18px;
  margin-top: 16px;
  text-align: center;
  font-size: 13px;
}
.hotel-facevalue {
  text-decoration: line-through;
  color: #999;
}
</style>