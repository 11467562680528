var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"oil"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"data"},[_c('div',{staticClass:"item"},[_c('p',[_vm._v(_vm._s(_vm.consumptionInfo.total_consumption))]),_c('span',[_vm._v("累计消费(元)")])]),_c('div',{staticClass:"item"},[_c('p',[_vm._v(_vm._s(_vm.consumptionInfo.total_savings))]),_c('span',[_vm._v("累计节省(元)")])]),_c('div',{staticClass:"item"},[_c('p',[_vm._v(_vm._s(_vm.consumptionInfo.total_units))]),_c('span',[_vm._v("累计加油(升)")])])]),_c('van-dropdown-menu',{attrs:{"active-color":"#FF5001"}},[_c('van-dropdown-item',{attrs:{"options":_vm.station_type_list},model:{value:(_vm.station_type),callback:function ($$v) {_vm.station_type=$$v},expression:"station_type"}}),_c('van-dropdown-item',{attrs:{"options":_vm.oil_name_list},model:{value:(_vm.oil_name),callback:function ($$v) {_vm.oil_name=$$v},expression:"oil_name"}}),_c('van-dropdown-item',{attrs:{"options":_vm.sort_way_list},model:{value:(_vm.sort_way),callback:function ($$v) {_vm.sort_way=$$v},expression:"sort_way"}})],1)],1),_c('div',{staticClass:"main"},[_vm._m(0),(_vm.oil_list.length)?_c('div',{staticClass:"list"},[_vm._l((_vm.oil_list),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('router-link',{attrs:{"to":{
            path: '/oil/detail',
            query: {
              id: item.station_id,
              oil_name: encodeURIComponent(item.oil_name),
              longitude: _vm.longitude,
              latitude: _vm.latitude,
              phone: _vm.phone_number,
              item_code: _vm.itemCode,
              wxOpenId: _vm.openId,
            },
          }}},[_c('div',{staticClass:"top"},[_c('span',[_vm._v(_vm._s(item.oil_name))]),_c('h4',[_vm._v(_vm._s(item.station_name))])]),_c('div',{staticClass:"address"},[_c('div',[_vm._v(_vm._s(item.address))]),_c('div',{staticClass:"navigation",on:{"click":function($event){$event.preventDefault();return _vm.toMap(item.station_name, item.latitude, item.longitude)}}},[_c('p',[_vm._v(_vm._s(item.distance_km)+"km")]),_c('span',[_vm._v("导航")])])]),_c('div',{staticClass:"price"},[_c('div',{staticClass:"pre-price"},[_vm._v(" 优惠价 "),_c('span',[_vm._v("¥")]),_c('b',[_vm._v(_vm._s(item.oil_price_yfq))]),_c('span',[_vm._v("/L")])]),_c('div',{staticClass:"gb-price"},[_c('i',[_vm._v("国标价")]),_vm._v(" ¥ "),_c('span',[_vm._v(_vm._s(item.oil_price_official))]),_vm._v("/L ")])]),_c('div',{staticClass:"label"},_vm._l((item.preferential_price_info),function(i){return _c('span',[_vm._v("加"+_vm._s(i.totalPrice)+"省"+_vm._s(i.promotionAmount))])}),0),_c('div',{staticClass:"btn-wrap"},[_c('div',{staticClass:"btn"},[_c('div',{staticClass:"navigate",on:{"click":function($event){$event.preventDefault();return _vm.toMap(item.station_name, item.latitude, item.longitude)}}},[_vm._v(" 先导航 ")]),_c('div',{staticClass:"pay"},[_vm._v("再下单")])])])])],1)}),(_vm.loading && !_vm.finished)?_c('div',{staticClass:"loading"},[_c('van-loading',{attrs:{"size":"24px"}},[_vm._v("加载更多...")])],1):_vm._e()],2):_c('div',[_c('van-empty',{attrs:{"image":"search","description":"附近没有加油站"}})],1)]),_c('div',{staticClass:"to-order-btn",on:{"click":function($event){return _vm.$router.push('/order')}}},[_vm._v("我的订单")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"process"},[_c('img',{attrs:{"src":"https://cdn.xiangshuheika.com/static/oil/process.png","alt":""}})])}]

export { render, staticRenderFns }