<template>
  <div style="text-align: center; line-height: 30px; padding-top: 50px">
    加载中...
  </div>
</template>

<script>
import { getCacheUrl } from "@/apis/home";
export default {
  async mounted() {
    const res = await getCacheUrl({ key: this.$route.query.key });
    location.replace(res.data.url);
  },
};
</script>