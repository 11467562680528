var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.detail && _vm.sku)?_c('div',{staticClass:"buffet-book"},[_c('div',{staticClass:"buffet-header"},[_c('van-image',{attrs:{"fit":"cover","lazy-load":"","src":_vm.sku.image_list_info[0] || ''}}),_c('div',{staticClass:"buffet-info"},[_c('div',{staticClass:"buffet-hotel-name"},[_vm._v(" "+_vm._s(_vm.detail.buffet_merchant_info.name)+" ")]),_c('div',{staticClass:"buffet-room-name"},[_vm._v(" "+_vm._s(_vm.detail.name)),_c('span',{staticClass:"buffet-room-tag"},[_vm._v(_vm._s(_vm.detail.defaultTag)+_vm._s(_vm.sku.name))])]),_c('div',{staticClass:"buffet-room-tips"},[_vm._v(" 注：提前"+_vm._s(_vm.sku.buffet_price_info.reserve_day)+"天"+_vm._s(_vm.sku.buffet_price_info.reserve_time)+"点之前预定，提前1天电话取消 ")])])],1),_c('div',{staticClass:"buffet-book-form"},[_c('div',{staticClass:"buffet-form-title"},[_vm._v("预定信息")]),_c('van-form',{ref:"form",attrs:{"show-error":false,"show-error-message":false},on:{"submit":_vm.beforeSubmit,"failed":_vm.failed}},[_c('van-field',{attrs:{"name":"user_name","maxlength":10,"label":"*预定姓名","placeholder":"请输入预定姓名","rules":[{ required: true, message: '请输入预定姓名' }]},model:{value:(_vm.user_name),callback:function ($$v) {_vm.user_name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"user_name"}}),_c('van-field',{attrs:{"name":"phone","maxlength":11,"label":"*预定手机号","disabled":_vm.disabledPhone,"placeholder":"请输入预定手机号","rules":[
          {
            required: true,
            message: '请输入预定手机号',
          },
          { pattern: /1[3-9]\d{9}/, message: '请输入正确的手机号' } ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('van-field',{attrs:{"readonly":"","clickable":"","name":"date","value":_vm.date,"rules":[{ required: true, message: '请选择预定日期' }],"label":"*预定日期","placeholder":"请选择预定日期"},on:{"click":function($event){_vm.showCalendar = true}}}),_c('van-field',{attrs:{"readonly":"","clickable":"","name":"time","value":_vm.time,"label":"*预定时间","rules":[{ required: true, message: '请选择预定时间' }],"placeholder":"请选择预定时间"},on:{"click":function($event){_vm.showTimePicker = true}}}),_c('van-cell',{staticClass:"attendance",attrs:{"title-class":"van-field__label","title":"出席人数"}},[[_c('div',{staticClass:"custom-title",staticStyle:{"text-align":"left","font-weight":"bold","color":"#333"}},[_vm._v(" 2人 ")])]],2),_c('div',{staticClass:"form-tips"},[_vm._v(" 默认出席人数为2人，如需预订多人用餐，请再下一笔订单 ")]),_c('van-field',{attrs:{"name":"comments","label":"备注","placeholder":"请输入备注信息"},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1)],1),_c('div',{staticClass:"tips"},[_c('div',{staticClass:"tips-title"},[_vm._v("温馨提示")]),_c('div',{staticClass:"tips-content",domProps:{"innerHTML":_vm._s(_vm.detail.content)}})]),(_vm.sku.buffet_price_info)?_c('div',{staticClass:"footer"},[_c('div',{staticClass:"left-info"},[_c('div',[_vm._v(" ¥"),_c('span',{staticClass:"amount"},[_vm._v(_vm._s(_vm.sku.buffet_price_info.member_price))]),_c('span',{staticClass:"facevalue"},[_vm._v("¥"+_vm._s(_vm.sku.buffet_price_info.market_price))])]),_c('div',{staticClass:"extra"},[_vm._v(" 节省"+_vm._s(_vm._f("decimal")(_vm.sku.buffet_price_info.market_price,_vm.sku.buffet_price_info.member_price, "minus"))+"元 ")])]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'buffet_book_submit_click',
        namespace: _vm.namespace,
        buffet_id: _vm.$route.query.sku_id,
      }),expression:"{\n        event_id: 'buffet_book_submit_click',\n        namespace,\n        buffet_id: $route.query.sku_id,\n      }"}],staticClass:"right-info",on:{"click":_vm.submitEvent}},[_vm._v(" 提交订单 ")])]):_vm._e(),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showTimePicker),callback:function ($$v) {_vm.showTimePicker=$$v},expression:"showTimePicker"}},[_c('van-datetime-picker',{attrs:{"type":"time","min-hour":_vm.sku.start_hour,"max-hour":_vm.sku.end_hour},on:{"confirm":_vm.onConfirm,"cancel":function($event){_vm.showTimePicker = false}}})],1),_c('van-calendar',{attrs:{"formatter":_vm.formatter,"min-date":_vm.minDate,"max-date":_vm.maxDate},on:{"confirm":_vm.onCalendarConfirm},model:{value:(_vm.showCalendar),callback:function ($$v) {_vm.showCalendar=$$v},expression:"showCalendar"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }