<template>
  <main class="custom-lucky">
    <div class="luck-draw-body">
      <van-image
        class="luck-draw-bg"
        :src="activityInfo.special_info.header_img"
      />
      <div class="custom-lucky-container">
        <div class="custom-lucky-title">
          中秋快乐，恭喜您获得许愿签
          {{ "X" + activityInfo.special_info.times_per_day || 0 }}
        </div>
        <LuckyGrid
          class="luckygrid"
          :width="350"
          :height="300"
          :rows="2"
          :blocks="[{ padding: '10px' }]"
          :cols="4"
          :defaultConfig="{ gutter: '8px' }"
          ref="luckyWheelRef"
          :prizes="prizesList"
          @start="drawHandleThrottle"
          @end="endLucky"
        />
        <div class="custom-lucky-footer">
          <van-button
            class="custom-lucky-start-btn"
            round
            block
            @click="drawHandleThrottle"
            :disabled="disabled"
            >{{ disabled ? "许愿签0" : "许愿1次" }}</van-button
          >
          <div class="custom-lucky-times">
            点击使用许愿签许愿
            {{
              `${activityInfo.amt_of_today}/${activityInfo.special_info.times_per_day}`
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="custom-user-prizes" v-if="prizeRecord.length">
      <div class="draw-list">
        <div class="draw-list-inner">
          <div
            v-for="item in prizeRecord"
            :key="item.t_created"
            class="draw-item"
          >
            <div class="draw-name">{{ item.extra_info.prize_name }}</div>
            <div
              class="draw-use-btn"
              v-if="item.status === 5"
              @click="toUse(item)"
            >
              去使用
            </div>
            <div
              class="draw-use-btn"
              v-if="item.status === 7"
              @click="toGet(item)"
            >
              领取
            </div>
            <div class="draw-use-btn expired" v-if="item.status === 8">
              已失效
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      v-model="showDialog"
      :activityReward="activityReward"
      :type="type"
      :openPrizePopup="openPrizePopup"
    />
  </main>
</template>

<script>
import { computed, defineComponent, ref } from "@vue/composition-api";
import throttle from "lodash.throttle";
import { LuckyGrid } from "@lucky-canvas/vue";
import Dialog from "./Dialog";

export default defineComponent({
  inheritAttrs: false,

  components: {
    LuckyGrid,
    Dialog,
  },
  props: {
    beforeDraw: Function,
    type: String,
    afterDraw: Function,
    toGet: Function,
    toUse: Function,
    openPrizePopup: Function,
    remainingTimes: Number,
    activityReward: Object,
    activityInfo: Object,
    prizeRecord: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const luckyWheelRef = ref(null);
    const showDialog = ref(false);
    const run = ref(false);
    const disabled = computed(() => {
      return (
        props.activityInfo.amt_of_today ===
          props.activityInfo.special_info.times_per_day ||
        !props.activityInfo.special_info.times_per_day
      );
    });
    const prizesList = computed(() => {
      const point = [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
        { x: 3, y: 1 },
        { x: 2, y: 1 },
        { x: 1, y: 1 },
        { x: 0, y: 1 },
      ];
      const goods_choice = props.activityInfo.special_info.goods_choice || {};
      return Object.entries(goods_choice).map(([k, v], index) => ({
        ...point[index],
        item_code: k,
        imgs: [
          {
            src: v.img,
            activeSrc: v.img1,
            width: "100%",
            top: "0",
          },
        ],
      }));
    });

    const startLucky = async () => {
      if (run.value) {
        return;
      }
      const res = await props.beforeDraw();
      if (!res) {
        return;
      }
      luckyWheelRef.value.play();
      run.value = true;
      setTimeout(() => {
        const index = prizesList.value.findIndex(
          (r) => r.item_code === res.item_code
        );
        luckyWheelRef.value.stop(index);
      }, 2000);
    };
    const drawHandleThrottle = throttle(startLucky, 3000, { trailing: false });

    const endLucky = () => {
      showDialog.value = true;
      setTimeout(async () => {
        await props.afterDraw();
        run.value = false;
      }, 300);
    };

    return {
      disabled,
      prizesList,
      luckyWheelRef,
      drawHandleThrottle,
      endLucky,
      showDialog,
    };
  },
});
</script>

<style lang="less" scoped>
.custom-lucky-container {
  position: relative;
  margin: 5px auto 20px;
  height: 453px;
  width: 357px;
  background: url("../../assets/images/midautumn_lucky_bg.png") no-repeat;
  background-size: 100%;
  padding-top: 50px;
  box-sizing: border-box;
}
.custom-lucky-title {
  height: 22px;
  line-height: 22px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 12px;
  width: 100%;
  color: #fff;
  text-shadow: 0px 0px 14px #114950;
  color: #fff5e6;
  font-size: 16px;
}
.luckygrid {
  margin: 0 auto 0;
}

.custom-lucky-footer {
  margin-top: 10px;
  padding: 0 30px;
}
.custom-lucky-start-btn {
  height: 50px;
  color: #fff;
  font-size: 18px;
  background: #008c84;
  box-shadow: inset 1px 3px 1px 0px rgba(216, 255, 148, 0.52),
    inset -1px -3px 1px 0px rgba(255, 0, 0, 0.3);
  &.van-button--disabled {
    background: #999;

    box-shadow: inset 1px 3px 1px 0px rgba(255, 255, 255, 0.52),
      inset -1px -3px 1px 0px rgba(105, 105, 105, 0.3);
  }
}

.custom-lucky-times {
  text-align: center;
  color: #ff9b00;
  margin-top: 10px;
}

.custom-user-prizes {
  margin: 0 10px;
  height: 254px;
  padding: 50px 16px 0;
  box-sizing: border-box;
  background: url("../../assets/images/picze_bg.png") no-repeat;
  background-size: 100%;
}

.draw-item {
  background: url("../../assets/images/picze_item_bg.png") no-repeat;
  height: 50px;
  display: flex;
  background-size: 100%;
  align-items: center;
  padding: 0 12px;
  justify-content: space-between;
  & + .draw-item {
    margin-top: 10px;
  }
}
.draw-name {
  color: #000;
}
.draw-list-inner {
  max-height: 180px;
  overflow-y: auto;
}
.draw-use-btn {
  width: 64px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
  color: #fff;
  background: #008c84;
}
</style>