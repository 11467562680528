/*
 * @description: 
 * @author: Zyg 
 * @date: 2024-07-19 10:04:35
 * remark: 机场快速通道及高铁贵宾厅页面
 */
<template>
  <div class="wrap">
    <div class="title">{{ title }}</div>
    <div class="desc">1人次</div>
    <div class="name">
      姓名: {{ userName }}
      <van-icon :name="showIcon" class="icon" @click="handleMosaic" />
    </div>
    <QrcodeVue class="qrcode" :value="couponValue.qr_code" size="162" />
    <div class="code">{{ couponValue.qr_code }}</div>
    <img
      src="https://common-h5-dev.xiangshuheika.com/static/upload/2024071872f08ad5f6c39.png"
      class="logo"
    />
    <div class="time">
      二维码将在<span>{{ time }}秒</span>后刷新
    </div>
    <div class="business" v-if="couponValue.sign === 'check'">
      该服务由【在机场】的设备完成核销
    </div>
    <div class="business">
      此服务由<span>北京悦途出行网络科技有限公司</span>提供
    </div>
    <div class="footer" @click="showStore">查看可用网点</div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "@vue/composition-api";
import QrcodeVue from "qrcode.vue";
import Vue from "vue";

import { maskName } from "@/utils/index";

const vm = Vue.prototype;

export default defineComponent({
  props: ["couponValue", "beginTime", "expiredTime", "time"],
  components: {
    QrcodeVue,
  },

  setup({ couponValue }) {
    const mosaic = ref(true);

    const userName = computed(() => {
      return mosaic.value
        ? maskName(couponValue.user_name)
        : couponValue.user_name;
    });

    const showIcon = computed(() => {
      return !mosaic.value ? "eye-o" : "closed-eye";
    });

    const title = computed(() => {
      return {
        HSR: "高铁贵宾厅权益",
        check: "机场快速通道",
      }[couponValue.sign];
    });

    const showStore = () => {
      const url = JSON.parse(vm.$getConfigValue("TRAVEL_YUETU_BENEFIT"))[
        couponValue.sign
      ];
      window.open(url);
    };

    const handleMosaic = () => {
      mosaic.value = !mosaic.value;
    };

    return {
      showIcon,
      userName,
      title,
      handleMosaic,
      showStore,
    };
  },
});
</script>

<style lang="less" scoped>
.wrap {
  padding-top: 35px;
  font-weight: 400;
  text-align: center;
  position: relative;
  min-height: calc(100vh - 79px);
}
.title {
  font-size: 26px;
  color: #333333;
  line-height: 26px;
  text-align: center;
}
.desc {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  text-align: center;
  margin-top: 12px;
}
.name {
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  .icon {
    margin-left: 10px;
  }
}
.qrcode {
  margin: 0 auto;
  margin-top: 4px;
  height: 163px;
  width: 163px;
}
.code {
  margin-top: 2px;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
}
.logo {
  margin-top: 30px;
  height: 25px;
  width: 107px;
}
.time {
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 24px;
  > span {
    color: #827127;
  }
}
.business {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 24px;
  > span {
    color: #827127;
  }
}
.footer {
  width: 335px;
  height: 50px;
  background: #dcc8a3;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);

  font-weight: 500;
  font-size: 16px;
  color: #272727;
  line-height: 50px;
}
</style>
