<template>
  <div class="ak-confirm">
    <div class="tips">
      温馨提示：为了准确、及时的为您提供贴心的体检、评估等健康服务，请您完成以下真实个人信息的填写（带*号为必填信息）
    </div>
    <div class="form">
      <div class="item">
        <span>*姓名</span>
        <van-field
          v-model="name"
          input-align="right"
          placeholder="请输入正确姓名"
        />
      </div>
      <div class="item">
        <span>*性别</span>
        <div class="radio">
          <span
            :class="{ active: sex_index === index }"
            v-for="(item, index) in sex"
            :key="item.type"
            @click="sex_index = index"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <div class="item">
        <span>*婚姻状况</span>
        <div class="radio">
          <span
            :class="{ active: marriage_index === index }"
            v-for="(item, index) in marriage"
            :key="item"
            @click="marriage_index = index"
            >{{ item }}</span
          >
        </div>
      </div>
      <div class="item">
        <span>*证件类型</span>
        <div class="id-number" @click="show = true">
          {{ id_number_name }}
        </div>
      </div>
      <div class="item">
        <span>*身份证号</span>
        <van-field
          v-model="id_number"
          maxlength="18"
          input-align="right"
          placeholder="请输入正确证件号"
        />
      </div>
      <div class="item">
        <span>*手机号</span>
        <van-field
          v-model="phone"
          :disabled="!!userInfo.user_phone"
          maxlength="11"
          input-align="right"
          placeholder="请输入本人手机号"
        />
      </div>
    </div>
    <div class="btn" @click="confirm">保存并提交</div>
    <van-popup v-model="show" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="show = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <van-popup v-model="showConfirm" :round="true">
      <div class="confirm-wrap">
        <div class="title">提交后不能修改，是否继续</div>
        <div class="list">
          <div class="item">
            <span>姓名：</span>
            <p>{{ this.name }}</p>
          </div>
          <div class="item">
            <span>联系电话：</span>
            <p>{{ this.phone }}</p>
          </div>
          <div class="item">
            <span>身份证号：</span>
            <p>{{ this.id_number }}</p>
          </div>
          <div class="item">
            <span>体检分院：</span>
            <p>{{ this.shop }}</p>
          </div>
          <div class="item">
            <span>体检时间：</span>{{ dateFormat }} {{ day }}
          </div>
        </div>
        <div class="confirm-btn-wrap">
          <div class="cancel-btn" @click="showConfirm = false">返回修改</div>
          <div
            class="confirm-btn"
            @click="postAppoint"
            v-ans="{
              event_id: 'information_click_submit',
              namespace,
              phone_number: this.phone,
              appiontment_city: this.shop,
              appiontment_date: this.dateFormat + this.day,
              user_id: userInfo.id,
            }"
          >
            确认提交
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { appoint } from "@/apis";
import ans from "@/utils/ans";
export default {
  data() {
    return {
      name: "",
      columns: ["中国居民身份证", "港澳通行证"],
      id_number_index: 0,
      id_number: "",
      id_number_name: "中国居民身份证",
      phone: "",
      sex: [
        { name: "男", type: "male" },
        { name: "女", type: "female" },
      ],
      sex_index: -1,
      marriage: ["已婚", "未婚"],
      marriage_index: -1,
      show: false,
      showConfirm: false,
      date: "",
      shop: "",
      day: "07:30-10:30",
    };
  },
  computed: {
    ...mapState(["userInfo", "namespace"]),
    dateFormat() {
      return `${new Date(this.date).getMonth() + 1}月${new Date(
        this.date
      ).getDate()}日`;
    },
  },
  mounted() {
    if (this.userInfo.user_phone) {
      this.phone = this.userInfo.user_phone;
    }
    const { date, shop, shopId } = this.$route.query;
    this.date = decodeURIComponent(date);
    this.shop = decodeURIComponent(shop);
    this.shopId = shopId;
    ans.pageView(this.$route.meta.page, {
      user_id: this.userInfo.id,
      phone_number: this.phone,
      appiontment_city: this.shop,
      appiontment_date: this.dateFormat + this.day,
      namespace: this.namespace,
    });
  },
  methods: {
    confirm() {
      if (!this.name) {
        this.$toast("请填写姓名");
        return;
      }
      if (this.sex_index < 0) {
        this.$toast("请选择性别");
        return;
      }
      if (this.marriage_index < 0) {
        this.$toast("请选择婚姻状况");
        return;
      }
      if (!this.id_number) {
        this.$toast("请填写证件号码");
        return;
      }
      if (!/^[0-9A-Za-z]+$/.test(this.id_number)) {
        this.$toast("请填写正确的证件号码");
        return;
      }
      if (!this.phone) {
        this.$toast("请填写手机号");
        return;
      }
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
        this.$toast("请填写正确的手机号");
        return;
      }
      this.showConfirm = true;
    },
    onConfirm(index) {
      this.id_number_name = index;
      this.show = false;
    },
    async postAppoint() {
      const start_time = new Date(this.date);
      start_time.setHours(7);
      start_time.setMinutes(30);
      const end_time = new Date(this.date);
      end_time.setHours(10);
      end_time.setMinutes(30);
      const toast = this.$loading();
      try {
        const res = await appoint({
          name: this.name,
          gender: this.sex[this.sex_index].type,
          marital_status: this.marriage[this.marriage_index],
          id_type: this.id_number_name,
          id_no: this.id_number,
          hospital_id: this.shopId,
          start_time: parseInt(start_time.getTime() / 1000),
          end_time: parseInt(end_time.getTime() / 1000),
          hospital_name: this.shop,
        });
        toast.clear();
        const order_number = res.data;
        this.$router.push(`/orderDetail?order_number=${order_number}`);
      } catch (error) {
        toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ak-confirm {
  min-height: 100vh;
  background-color: #fff;
}
.tips {
  padding: 12px 15px;
  line-height: 1.6;
  font-size: 12px;
  color: #ff5001;
}
.form {
  padding-left: 15px;
  .item {
    display: flex;
    height: 55px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e1e2e6;
    span {
      width: 80px;
      font-size: 14px;
      color: #333;
    }
  }
}
.btn {
  background-color: #1a1a1a;
  margin: 30px 25px 0;
  line-height: 50px;
  height: 50px;
  text-align: center;
  border-radius: 25px;
  font-weight: bold;
  font-size: 18px;
  color: #ffefd7;
}
.radio {
  padding-right: 15px;
  font-size: 14px;
  color: #333;
  span {
    margin-left: 16px;
    padding-left: 16px;
    background: url("../../assets/images/select.png") no-repeat;
    background-position: left center;
    background-size: 12px;
  }
  .active {
    background: url("../../assets/images/selected2.png") no-repeat;
    background-position: left center;
    background-size: 12px;
  }
}
.id-number {
  padding-right: 32px;
  background: url("../../assets/images/down.png") no-repeat;
  background-position: right 15px center;
  background-size: 12px;
  font-size: 14px;
}
.confirm-wrap {
  width: 295px;
  height: 298px;
  border-radius: 6px;
  background-color: #fff;
  .title {
    text-align: center;
    font-size: 14px;
    color: #333;
    padding: 25px 0 0;
    font-weight: bold;
  }
  .list {
    margin-top: 5px;
    padding-top: 1px;
    .item {
      font-size: 14px;
      margin-top: 12px;
      color: #1a1a1a;
      padding-left: 14px;
      overflow: hidden;
      span {
        width: 78px;
        float: left;
        color: #999;
      }
      p {
        margin-left: 80px;
        padding-right: 10px;
      }
    }
  }
}
.confirm-btn-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 15px;
}
.cancel-btn {
  line-height: 45px;
  height: 45px;
  border: 1px solid #1a1a1a;
  border-radius: 25px;
  font-size: 16px;
  color: #1a1a1a;
  width: 125px;
  text-align: center;
}
.confirm-btn {
  width: 125px;
  background-color: #1a1a1a;
  line-height: 45px;
  height: 45px;
  text-align: center;
  border-radius: 25px;
  font-weight: bold;
  font-size: 16px;
  color: #ffefd7;
}
</style>