<template>
  <div class="wrap">
    <van-swipe :loop="false" :show-indicators="false" @change="changePackage">
      <van-swipe-item
        v-for="(item, index) in cardArr"
        :key="index"
        :class="{
          'slide-prev': index + 1 === active,
          'slide-next': active + 1 === index,
          [`card-item-${item.namespace}`]: true,
        }"
      >
        <div class="swipe-item"></div>
      </van-swipe-item>
    </van-swipe>
    <div class="tip-wrap" v-if="!isMember">
      优先推荐 提额机会 开通立享价值<span><span>¥</span>{{ tipPrice }}</span
      >/月
    </div>
    <div v-else class="expiry-date">
      有效期至 {{ userInfo.card_expiry_date[0] }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ChHeader",
  props: {
    active: Number,
    cardArr: Array,
  },

  computed: {
    ...mapState(["isMember", "namespace", "userInfo"]),
    tipPrice() {
      if (this.isMember) {
        return this.cardArr[0].price;
      }
      return this.cardArr[this.active]?.price;
    },
  },
  methods: {
    changePackage(index) {
      this.$emit("changePackage", index);
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  box-sizing: border-box;
  padding-top: 32px;
  background: #131520;
  height: 252px;
  position: relative;
  /deep/ .van-swipe-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.swipe-item {
  height: 131px;
  width: 208px;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
}

.slide-next {
  transform: scale(0.9);
  .swipe-item {
    margin-left: -260px;
  }
}

.slide-prev {
  transform: scale(0.9);
  .swipe-item {
    margin-right: -260px;
  }
}
.card-item-za3 {
  .swipe-item {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/2024371a53788444a0441ba5040ee70fcbf2ff.png);
  }
}
.card-item-za4 {
  .swipe-item {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/2024371955bba1f8b049b59302d05aec50da83.png);
  }
}
.card-item-za5 {
  .swipe-item {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/2024376474454b47e643f5a239fe5d06d1b200.png);
  }
}
.tip-wrap {
  position: absolute;
  left: 50%;
  top: 195px;
  transform: translateX(-50%);
  height: 24px;
  background: #000000;
  border-radius: 12px;
  color: rgba(197, 199, 206, 1);
  width: auto;
  word-wrap: none;
  white-space: nowrap;
  padding: 1px 24px;
  box-sizing: border-box;
  font-size: 10px;
  span {
    color: rgba(245, 212, 182, 1);
    font-size: 16px;
    > span {
      font-size: 10px;
    }
  }
}

.expiry-date {
  position: absolute;
  left: 50%;
  top: 195px;
  transform: translateX(-50%);
  width: 244px;
  height: 24px;
  background: #000000;
  border-radius: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 11px;
  color: #ddcca3;
  line-height: 24px;
  text-align: center;
}
</style>
