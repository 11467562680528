<template>
  <div ref="contentRef">
    <section class="help-detail" v-if="!loading">
      <div class="help card">
        <div class="help-title">{{ help.help_title }}</div>
        <div
          class="help-content"
          v-image-preview
          v-html="help.help_content"
        ></div>
      </div>
      <div class="feedback card" v-if="token">
        <div class="feedback-title">以上方案对您有帮助吗？</div>
        <div class="feedbacks">
          <div
            class="feedback-item no-help"
            v-if="!result || result === 'nohelp'"
            v-ans="{ event_id: 'help_service_nohelp', help_id: help.id }"
            :style="`transform:translateX(${transform.nohelp}px)`"
            @click="select('nohelp', 1)"
          />
          <div
            v-if="!result || result === 'helpful'"
            class="feedback-item helpful"
            v-ans="{ event_id: 'help_service_helpful', help_id: help.id }"
            :style="`transform:translateX(${transform.helpful}px)`"
            @click="select('helpful', -1)"
          />
        </div>
      </div>
      <van-popup
        closeable
        position="bottom"
        round
        @click-overlay="close"
        @click-close-icon="close"
        :value="visible"
        class="feedback-popup-container"
      >
        <div class="title">未帮助到您的原因是</div>
        <div class="content">
          <ul class="reasons">
            <li v-for="item in reasons" :key="item">
              <div
                @click="selectReason(item)"
                v-ans="{ event_id: 'help_service_reason', resson: item }"
                :class="{ inner: true, active: selectReasons.includes(item) }"
              >
                {{ item }}
              </div>
            </li>
          </ul>
          <van-field
            v-model="message"
            maxlength="200"
            class="textarea"
            type="textarea"
            show-word-limit
            placeholder="还有其他问题要吐槽"
          />
          <van-button @click="submit" block type="info" class="btn"
            >提交</van-button
          >
        </div>
      </van-popup>
    </section>
  </div>
</template>

<script>
import {
  createHelpProblemUseFeedback,
  getHelpDetail,
  getHelpProblemUseFeedback,
  getOrderList,
} from "@/apis/home";
import { useRoute } from "@/components/use/base";
import store from "@/store";
import { getProjectDivision } from "@/utils";
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "@vue/composition-api";
import Vue from "vue";
const vm = Vue.prototype;
export default defineComponent({
  setup() {
    const result = ref("");
    const visible = ref(false);
    const message = ref("");
    const selectReasons = ref([]);
    const loading = ref(true);
    const route = useRoute();
    const token = store.state.token;
    const reasons = ref([
      "标题看不懂",
      "内容不理解",
      "文字太多懒得看",
      "引导操作太复杂",
      "答案不认可",
      "人工入口太难找",
    ]);
    const transform = reactive({
      nohelp: 1,
      helpful: -1,
    });
    const contentRef = ref(null);
    const help = ref({
      title: "标题",
      content: "内容",
    });
    const select = (type, direction) => {
      if (result.value) {
        return;
      }
      if (type === "nohelp") {
        visible.value = true;
      } else {
        transform[type] = direction * 75;
        result.value = type;
        createFeedback("有帮助");
        vm.$toast("感谢您的反馈");
      }
    };
    const selectReason = (item) => {
      if (selectReasons.value.includes(item)) {
        selectReasons.value = selectReasons.value.filter((i) => i !== item);
      } else {
        selectReasons.value.push(item);
      }
    };
    const submit = async () => {
      if (!selectReasons.value.length && !message.value)
        return vm.$toast("请选择原因或者填写其他问题");
      vm.$toast("感谢您的反馈，我们会继续努力");
      await createFeedback(
        ["没帮助"]
          .concat([selectReasons.value])
          .concat(message.value)
          .filter(Boolean)
          .join(",")
      );
      transform.nohelp = 75;
      result.value = "nohelp";
      close();
    };

    const createFeedback = async (content) => {
      await createHelpProblemUseFeedback({
        help_group_id: route.query.group_id,
        help_id: route.query.id,
        order_number: route.query.order_number,
        project_name: getProjectDivision(store.state.namespace),
        content,
      });
    };

    const jump = async (url) => {
      const XY_HELP_ENTRY_REFUND = vm.$getConfigValue("XY_HELP_ENTRY_REFUND");
      if (XY_HELP_ENTRY_REFUND && token) {
        const res = await getOrderList({
          source: "网络支付,api",
          status: "success",
          category_type: "vip",
        });
        if (
          res.data.res_list[0]?.type &&
          XY_HELP_ENTRY_REFUND.split(",").includes(res.data.res_list[0].type)
        )
          vm.$alert({
            message:
              "小赢尊享卡订单的退款，请返回上一页面咨询在线客服或电话客服400-150-9669",
          });
        return;
      }
      location.href = url;
    };

    const close = () => {
      message.value = "";
      selectReasons.value = [];
      visible.value = false;
    };

    const clickEl = (e) => {
      const centerEl = contentRef.value;
      let target = e.target;
      while (target !== centerEl) {
        if (target.nodeName !== "A") {
          target = target.parentNode;
        } else {
          break;
        }
      }
      if (target?.href) {
        // 转换a标签
        e.preventDefault();
        const temp = {
          namespace: store.state.namespace,
          user_token: token,
          return_url: encodeURIComponent(location.origin + location.pathname),
          path: encodeURIComponent(location.hash),
        };
        const url = target.href.replace(/{(\w+)}/g, (str, $) => {
          return temp[$];
        });
        jump(url);
      }
    };
    onMounted(async () => {
      const fecting = vm.$loading();
      try {
        const res = await getHelpDetail({
          help_group_id: route.query.group_id,
          help_id: route.query.id,
        });
        help.value = res.data;

        if (token) {
          const feedbackRef = await getHelpProblemUseFeedback({
            help_group_id: route.query.group_id,
            help_id: route.query.id,
            project_name: getProjectDivision(store.state.namespace),
          });
          if (feedbackRef.data[0]) {
            result.value =
              feedbackRef.data[0].content === "有帮助" ? "helpful" : "nohelp";
            transform[result.value] = transform[result.value] * 75;
          }
        }
      } finally {
        loading.value = false;
        fecting.clear();
      }
      nextTick(() => {
        const centerEl = contentRef.value;
        centerEl.addEventListener("click", clickEl, false);
      });
    });
    onUnmounted(() => {
      const centerEl = contentRef.value;
      centerEl.removeEventListener("click", clickEl, false);
    });
    return {
      help,
      transform,
      select,
      visible,
      result,
      message,
      selectReasons,
      selectReason,
      reasons,
      submit,
      contentRef,
      loading,
      token,
      close,
    };
  },
});
</script>

<style lang="less" scoped>
.help-detail {
  padding: 20px 12px 10px;
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #f4f5f7;
}
.card {
  background-color: #fff;
  border-radius: 12px;
  & + & {
    margin-bottom: 12px;
  }
}
.help {
  padding: 16px 16px;
  margin-bottom: 12px;
}
.help-title {
  line-height: 21px;
  font-size: 15px;
  color: #333;
  font-weight: bold;
  margin-bottom: 5px;
}
.help-content {
  line-height: 30px !important;
  font-size: 14px !important;
  color: #444;
  /deep/ img {
    max-width: 100%;
    height: auto;
  }
  /deep/ table {
    max-width: 100% !important;
  }
}

.feedback {
  padding: 14px 0 19px;
}
.feedback-title {
  text-align: center;
  color: #999;
  line-height: 17px;
  margin-bottom: 10px;
}

.feedbacks {
  width: 250px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.feedback-item {
  width: 99px;
  height: 41px;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: all 0.3s;
}

.no-help {
  background-image: url("~@/assets/images/no-help.png");
  &.active {
    background-image: url("~@/assets/images/no-help-select.png");
  }
}
.helpful {
  margin-left: auto;
  background-image: url("~@/assets/images/helpful.png");
  &.active {
    background-image: url("~@/assets/images/helpful-select.png");
  }
}

.feedback-popup-container {
  background: #fff;
  .title {
    height: 52px;
    line-height: 52px;
    text-align: center;
    color: #000;
    font-size: 15px;
  }
  .content {
    padding: 12px;
  }

  .reasons {
    margin: 0 -4px;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50%;
      padding: 0 4px;
      box-sizing: border-box;
    }
    .inner {
      height: 38px;
      background: #f6f7f9;
      border-radius: 8px;
      border: 1px solid #f6f7f9;
      font-size: 14px;
      color: #444;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        position: relative;
        border-color: #ff5001;
        &::after {
          content: "";
          position: absolute;
          right: -1px;
          top: -1px;
          width: 23px;
          height: 18px;
          background: url("~@/assets/images/select-reson-bg.svg") no-repeat;
        }
      }
    }
  }
  .textarea {
    height: 80px;
    background: #f6f7f9;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    padding: 14px 10px 7px;
    /deep/ .van-field__body {
      height: 35px;
    }
    /deep/ textarea {
      min-height: auto;
    }
  }
  .btn {
    height: 50px;
    background: linear-gradient(270deg, #363636 0%, #000000 100%);
    border-radius: 8px;
    color: #fff;
    margin-top: 14px;
    font-size: 15px;
    border-radius: 8px;
  }
}
</style>
