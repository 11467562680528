<template>
  <div class="read-wrap">
    <div class="title">阅读生活权益免费领</div>
    <div class="main">
      <div
        class="card"
        v-for="item of info.productInfo"
        :key="item.id"
        @click="handleDraw(item)"
      >
        <img :src="item.display_img_url" />
        <div class="btn">立即领取</div>
      </div>
    </div>
  </div>
</template>

<script>
import copy from "copy-to-clipboard";

import ans from "@/utils/ans";

export default {
  props: ["info"],
  methods: {
    handleDraw(item) {
      ans.track("licheng_free_benefit_copy", {
        namespace: this.namespace,
        source: this.source,
        benefit_type: item.product_code,
      });
      copy(item.partner_jump_url);
      this.$toast("使用链接已复制，请打开外部浏览器使用");
    },
  },
};
</script>
<style lang='less' scoped>
.read-wrap {
  width: auto;
  background: #fff;
  border-radius: 16px;
  margin-top: 12.5px;
  padding: 11px 7px 12px 7px;
  font-family: PingFangSC, PingFang SC;
  .title {
    margin-left: 5.5px;
    font-size: 14px;
    font-weight: 600;
    color: #0f2540;
  }
  .main {
    margin-top: 14.5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .card {
      width: 159.5px;
      height: 146px;
      background: #fffbf8;
      border-radius: 8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 8px;

      > img {
        margin-top: 9px;
        margin: 4px 2px 0 2px;
        width: 100%;
        height: 100px;
      }

      .btn {
        padding-top: 1px;
        width: 48.5px;
        height: 13px;
        border: 1px solid #e69a7b;
        font-size: 9px;
        font-weight: 600;
        color: #e69a7b;
        border-radius: 8px;
        line-height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>