<template>
  <div class="modal-wrap">
    <div class="wrap">
      <div class="content">
        <img src="@/assets/images/xp-draw-modal.png" />
        <van-count-down :time="countDown" class="count-down">
          <template #default="timeData">
            <span class="block">{{ timeData.hours }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.minutes | zero }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.seconds | zero }}</span>
          </template>
        </van-count-down>
      </div>
    </div>
    <div class="btn resizing" @click="handleOpen">{{ count }}元 立即开通</div>
    <div class="icon">
      <svg-icon
        iconClass="cp-draw-modal-cancel"
        @click="$emit('update:visible', false)"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: ["visible", "submit", "count"],
  data() {
    return {
      countDown: 0,
    };
  },

  mounted() {
    this.visible && this.stop();
    this.getCountDown();
  },

  beforeDestroy() {
    this.move();
  },

  methods: {
    stop() {
      document.body.style.overflow = "hidden";
    },
    move() {
      document.body.style.overflow = "";
    },
    getCountDown() {
      const current = dayjs().valueOf();
      const tenHour = 36000000;
      const time = localStorage.getItem("unxp_modal_time");
      if (!time) {
        localStorage.setItem("unxp_modal_time", current);
        this.countDown = tenHour;
        return;
      }
      const timeOut = current - parseInt(time);
      if (timeOut > tenHour) {
        this.countDown = tenHour;
        localStorage.setItem("unxp_modal_time", current);
      } else {
        this.countDown = tenHour - timeOut;
      }
    },
    handleOpen() {
      this.submit();
    },
  },
};
</script>

<style lang="less" scoped>
.modal-wrap {
  background-color: rgba(0, 0, 0, 0.78);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  .wrap {
    .content {
      position: relative;
      > img {
        width: 309px;
        height: 331px;
      }
      .count-down {
        position: absolute;
        left: 97px;
        top: 73px;
        display: flex;
        align-items: center;
        .block {
          display: block;
          width: 16px;
          height: 16px;
          background: #ffffff;
          border-radius: 4px;
          font-size: 11px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #f98047;
          line-height: 16px;
          text-align: center;
        }
        .colon {
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 16px;
          margin: 0 3px;
          height: 16px;
          margin-top: -2px;
        }
      }
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
    border-radius: 27px;
    width: 266px;
    height: 54px;
    background: linear-gradient(162deg, #ffb348 0%, #ff6326 100%);
    border-radius: 27px;
    font-size: 20px;
    font-weight: normal;
    color: #ffffff;
    line-height: 24px;
  }
  .icon {
    width: 100%;
    font-size: 34px;
    margin-top: 44px;
    text-align: center;
  }
}
</style>
