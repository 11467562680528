<template>
  <div
    :class="[
      'select-date',
      showPrice ? 'select-date-big' : 'select-date-small',
    ]"
  >
    <div class="book-item-scroll">
      <div
        :class="[
          'book-item',
          item.date.isSame(offlineBenefit.parkBookDate, 'day') && 'active',
        ]"
        v-for="(item, index) in dates"
        @click="dateClick(item.date, index)"
        :key="index"
      >
        <div class="book-price-date" v-if="showPrice">
          <div class="book-week">{{ item.date | week }}</div>
          <div class="book-date">{{ item.date | formatDate("MM-DD") }}</div>
          <div v-if="item.price !== undefined" class="book-price">
            ¥{{ item.price }}
          </div>
        </div>
        <template v-else> 订{{ item.date | formatDate("MM月DD日") }} </template>
      </div>
    </div>
    <div
      v-if="dates.length > 4"
      :class="!showPrice ? 'book-item' : 'more-date'"
      @click="moreClick"
    >
      更多<br v-if="showPrice" />日期<van-icon name="arrow" />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapMutations, mapState } from "vuex";
import SelectCalendar from "@/components/SelectCalendar";
import animateScrollTo from "animated-scroll-to";

export default {
  props: {
    showPrice: Boolean,
    priceDict: {
      type: Object,
      default: () => ({}),
    },
    num: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      dates: [],
    };
  },

  computed: {
    ...mapState(["offlineBenefit"]),
  },

  methods: {
    ...mapMutations(["changeBookDate"]),
    generateDate() {
      this.dates = Array.from({ length: this.num }).map((item, index) => {
        const date = dayjs()
          .startOf("day")
          .add(index + 2, "day");
        return {
          date,
          price: this.priceDict[date.format("YYYY-MM-DD")],
        };
      });
      if (this.showPrice) {
        this.dates = this.dates.filter((item) => item.price !== undefined);
      }
    },
    dateClick(date, index) {
      if (
        this.showPrice &&
        this.priceDict[date.format("YYYY-MM-DD")] === undefined
      ) {
        this.$toast("该日期暂没有票了");
        return;
      }
      this.changeBookDate(date);
      this.$emit("changeDate", date);
      this.scroll(index);
    },
    getPriceList() {
      return Object.entries(this.priceDict).reduce((a, b) => {
        a[b[0].slice(5)] = b[1];
        return a;
      }, {});
    },
    moreClick() {
      SelectCalendar.show({
        getPriceList: this.getPriceList,
        tips: "最早预订日期为当前日期的后天",
        type: "park",
        rangeDate: [this.offlineBenefit.parkBookDate].filter(Boolean),
        minDate: dayjs().add(2, "day"),
        maxDate: dayjs().add(31, "day"),
        range: 90,
        single: true,
      }).then((rangeDate) => {
        const idx = this.dates.findIndex((item) =>
          item.date.isSame(rangeDate[0], "day")
        );
        this.dateClick(rangeDate[0], idx);
      });
    },
    scroll(idx) {
      if (idx === -1) {
        return;
      }
      const scrollEl = document.querySelector(".book-item-scroll");
      const leftEl = scrollEl?.querySelectorAll(".book-item")[idx];
      if (leftEl) {
        animateScrollTo([leftEl?.offsetLeft - 100, 0], {
          elementToScroll: scrollEl,
        });
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.offlineBenefit.parkBookDate) {
        const idx = this.dates.findIndex((item) =>
          item.date.isSame(this.offlineBenefit.parkBookDate, "day")
        );
        this.scroll(idx);
      }
    });
  },
  watch: {
    priceDict: {
      handler() {
        this.generateDate();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.select-date {
  display: flex;
  flex-shrink: 0;
  .book-item {
    &:not(:last-child) {
      margin-right: 6px;
    }
    &:last-child {
      margin-left: 6px;
    }
    background: #f5f7fa;
    border-radius: 4px;
    border: 1px solid #ebeff5;
    text-align: center;
    font-size: 14px;
    box-sizing: border-box;
    flex-shrink: 0;
    &.active {
      background: #ebf3ff;
      border-color: #0066f6;
    }
  }
  &.select-date-big {
    .book-item {
      width: 60px;
    }
  }
  &.select-date-small {
    .book-item {
      padding: 0 8px;
      line-height: 24px;
      font-size: 12px;
    }
  }
  .book-item-scroll {
    display: flex;
    flex: 1;
    overflow-x: auto;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .book-price-date {
    height: 74px;
    padding: 10px 0;
    box-sizing: border-box;
  }
  .book-week {
    line-height: 19px;
  }
  .book-date,
  .book-price {
    line-height: 17px;
  }
  .book-price {
    color: #ff5500;
  }
  .more-date {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    color: #006ff6;
    margin-left: 10px;
    font-size: 13px;
    line-height: 20px;
    /deep/ .van-icon {
      margin-left: 2px;
    }
  }
}
</style>
