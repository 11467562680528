<template>
  <section class="header">
    <van-image :src="require('@/assets/images/smile.png')" class="smile" />
    <div>
      <div class="header-name">
        {{ userPhone | maskStr }}
      </div>
      <div class="header-subtext">
        {{
          $getConfigValue("SHOW_REMAIN_DAYS")
            | formatText({
              remain_days: card.current_package_remain_days,
              expiry_date: card.card_expiry_date,
            })
        }}
      </div>
    </div>
    <div class="desc">每天可使用一项权益</div>
    <div class="renewal">
      <div @click="toPay" v-if="canRenewal">立即续费</div>
    </div>
  </section>
</template>

<script>
import { defineComponent, computed, onMounted } from "@vue/composition-api";

import { useStore } from "@/components/use/base";
import { toPayV2 } from "@/helper/pay";
import useVisitorCategory from "@/use/useVisitorCategory";

export default defineComponent({
  props: ["card"],
  setup() {
    const store = useStore();

    const { fetchPackage, fetchPaymethod, currentMethod, currentPackage } =
      useVisitorCategory();

    onMounted(async () => {
      await Promise.all([fetchPackage(), fetchPaymethod()]);
    });

    const canRenewal = computed(() => {
      const renewalCount = store.state.userInfo.valid_ch_count;
      return renewalCount < 4;
    });

    const toPay = () => {
      let url =
        (window.location.origin + window.location.pathname).replace(/\/$/, "") +
        `/#/index`;
      const return_url = encodeURIComponent(url);
      toPayV2(currentPackage.value, currentMethod.value.channel, {
        partner_jump_url: return_url,
      });
    };

    return {
      userPhone: store.state.userInfo.login_phone_number,
      canRenewal,
      toPay,
    };
  },
});
</script>

<style lang="less" scoped>
.header {
  height: 133px;
  border-radius: 10px;
  background: url("~@/assets/images/unxp_member_header_bg.png") no-repeat left
    center/cover;
  margin-bottom: 12px;
  display: flex;
  padding: 20px 12px;
  box-sizing: border-box;
  position: relative;
  color: #fff;
  .desc {
    position: absolute;
    bottom: 26px;
    left: 17px;
    font-size: 12px;
  }
  .renewal {
    position: absolute;
    right: 13px;
    bottom: 18px;
    height: 28px;
    background: white;
    box-shadow: 0px 0px 9px 4px rgba(255, 255, 255, 0.3);
    border-radius: 14px;
    display: flex;
    justify-content: space-between;
    > img {
      height: 38px;
      width: 38px;
      margin-top: -10px;
      margin-left: -2px;
    }
    > p {
      margin-left: -5px;
      margin-right: 5px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 11px;
      color: #ff6600;
      line-height: 28px;
      text-align: center;
      white-space: nowrap;
    }
    > div {
      width: 71px;
      height: 28px;
      background: linear-gradient(180deg, #ffea8a 0%, #ffc83d 100%);
      border-radius: 14px;
      font-weight: 600;
      font-size: 13px;
      color: #ea5524;
      line-height: 28px;
      text-align: center;
    }
  }

  .smile {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .header-name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .header-subtext {
    line-height: 22px;
    color: rgba(#fff, 0.7);
  }
}
</style>
