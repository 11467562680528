<template>
  <div class="online-ordering" v-if="catesgory.name">
    <div
      class="online-ordering-image"
      :style="{ background: `url(${catesgory.display_img_url})` }"
    ></div>
    <div class="online-ordering-list">
      <Card
        v-for="(item, index) in catesgoryList"
        :key="index"
        :cardValue="item"
        :index="index"
        :jump="jump"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  getIndexCategory,
  getIndexClassList,
  queryH5JumpUrl,
} from "@/apis/product";

import Card from "./Card.vue";
import copy from "copy-to-clipboard";

export default {
  components: {
    Card,
  },
  data() {
    return {
      catesgoryList: [],
      catesgory: {},
    };
  },
  computed: {
    ...mapState(["namespace", "userInfo", "isMiniProgram"]),
  },
  async mounted() {
    const loading = this.$loading();
    try {
      const catesgory = await getIndexCategory({
        namespace: this.namespace,
        opened_page: "member",
      });
      const s = catesgory.data.filter(
        (r) => r.status === 1 && r.name === "在线点餐"
      );
      const productList = await getIndexClassList({
        title: "在线点餐",
        opened_page: "member",
        namespace: this.namespace,
      });
      const catesgoryList = productList.data["在线点餐"];
      this.catesgoryList = catesgoryList;
      this.catesgory = s[0] || {};
    } finally {
      loading.clear();
    }
  },
  methods: {
    async jump(item) {
      let src = item.partner_jump_url;
      if (!src) {
        const url = await queryH5JumpUrl({
          user_id: this.userInfo.id,
          generate_h5_code: item.generate_h5,
          spu_id: item.id,
          phone_number: this.userInfo.user_phone,
        });
        src = url.data;
      }
      if (this.isMiniProgram) {
        this.$alert({
          title: "提示",
          message: "请使用系统浏览器打开页面",
          confirmButtonText: "复制链接",
        }).then(() => {
          copy(src);
          this.$toast("使用链接已复制，请打开外部浏览器使用");
        });
      } else {
        location.href = src;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.online-ordering {
  background-color: #f5f5f5;
  min-height: 100vh;
}

.online-ordering-image {
  height: 148px;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
}

.online-ordering-list {
  padding: 25px 16px 16px;
  border-radius: 16px 16px 0 0;
  z-index: 100;
  margin-top: -16px;
  background: #f7f7f7;
}
</style>