<template>
  <floor>
    <template #title>{{ title }}</template>
    <template #subTitle>｜{{ subTitle }}</template>
    <template #tip>{{ desc }}</template>
    <template #main>
      <div class="media-benefit">
        <div>
          <div class="card-1" v-for="item of mediaArr1" :key="item.name">
            <img :src="item.img" />
            <div class="media-name">{{ item.name }}</div>
            <div class="media-price">原价：{{ item.price }}元</div>
          </div>
        </div>
        <div>
          <div class="card-2" v-for="item of mediaArr2" :key="item.name">
            <img :src="item.img" />
            <div class="media-name">{{ item.name }}</div>
            <div class="media-price">原价：{{ item.price }}元</div>
          </div>
        </div>
      </div>
      <toUseBtn :product_code="productInfo[0].product_code" :notPay="notPay" />
    </template>
  </floor>
</template>

<script>
import toUseBtn from "./toUseBtn.vue";
import floor from "./floor.vue";

export default {
  props: ["desc", "name", "to_url", "productInfo", "notPay"],
  components: {
    toUseBtn,
    floor,
  },
  computed: {
    title() {
      return this.name.split("｜")[0];
    },
    subTitle() {
      return this.name.split("｜")[1];
    },
  },
  data() {
    return {
      mediaArr1: [],
      mediaArr2: [],
    };
  },
  mounted() {
    const ZA_CARD_CONGIG =
      JSON.parse(this.$getConfigValue("ZA_CARD_CONGIG")) || {};
    this.mediaArr1 = ZA_CARD_CONGIG["MEDIA_ARR1"];
    this.mediaArr2 = ZA_CARD_CONGIG["MEDIA_ARR2"];
  },
};
</script>


<style scoped lang="less">
.media-benefit {
  margin-top: 19px;
  overflow-y: scroll;
  margin-right: -16px;
  > div {
    display: flex;
    white-space: nowrap;
    &:not(:first-child) {
      margin-top: 5px;
    }
    > div {
      flex-shrink: 0;
      width: 101px;
      height: 112px;
      border-radius: 8px;
      margin-right: 8px;
      font-family: PingFang-SC, PingFang-SC;

      display: flex;
      justify-content: center;
      align-content: flex-start;
      flex-wrap: wrap;

      > img {
        height: 40px;
        width: 40px;
        margin-top: 12px;
      }

      .media-name {
        font-size: 14px;
        font-weight: bold;
        color: #27314a;
        line-height: 20px;
        margin-top: 8px;
      }
      .media-price {
        font-size: 12px;
        font-weight: 400;
        color: #455368;
        line-height: 17px;
        margin-top: 4px;
      }
    }
  }
  .card-1 {
    background: linear-gradient(180deg, #dfe8fa 0%, #e6f4fa 100%);
  }
  .card-2 {
    background: #f9dfd8 linear-gradient(180deg, #f9dfd8 0%, #faf1ef 100%);
  }
}
</style>