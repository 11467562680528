<template>
   <div class='cash-out_record' v-if="show">
        <div class='cash-out_record-status'>
        <div v-for="item in status" :key="item" class='cash-out_record-status-item'>{{item}}</div>
        </div>
        <div class='record-list'>
            <div v-for="(item, index) in record_list" :key="item.status+index" class='record-list-item'>
                <div class='record-list-item-l'>
                    <div>{{item.account.slice(0,3)+`****`+item.account.slice(-4)}}</div>
                    <div class='record-list-item-date'>{{(item.t_withdrawal || '').replace(/-/g, '.')}}</div>
                </div>
                <div class='record-list-item-c'>+¥{{item.withdrawal_amt}}</div>
                <div class='record-list-item-r'>{{order_status[item.status]}}</div>
            </div>
            <div v-if="!record_list.length" class="record-no">暂无提现记录</div>
        </div>
    </div>
</template>

<script>
import { getWithdrawalsRecord } from '../../apis/cashback'
export default {
   name: 'cashOutRecord',
   data() {
       return {
           record_list: [],
            status: ['支付宝账号', '提现金额', '状态'],
            order_status: {
                "success": "已成功",
                "failed": "已失败",
                "confirming": "提现中"
            },
            show: false
       }
   },
  components: {},

  async mounted () {
    const toast = this.$loading();
    try {
        const res = await getWithdrawalsRecord();
        this.record_list = res.data;
    } finally {
        toast.clear();
    }
    this.show = true;
   }
}
</script>

<style lang='less' scoped>
    .cash-out_record{
        background-color: #fff;
        border-radius: 8px;
        margin: 15px;
        padding: 0 15px;
    }
    .cash-out_record-status{
        border-bottom: 1PX solid #F5F5F5;
        font-size: 14px;
        color: #333;
        height: 50px;
        line-height: 50px;
    }
    .cash-out_record-status-item{
        text-align: center;
        min-width: 70px;
        float: left;
    }
    .cash-out_record-status-item:first-child{
        box-sizing: border-box;
        padding-left: 5px;
        width: 45%;
        text-align: left;
    }
    .cash-out_record-status-item:last-child{
        float: right;
    }
    .record-list{
        padding-bottom: 20px;
    }
    .record-list-item{
        margin-top: 20px;
        font-size: 13px;
        color: #333;
        text-align: center;
        overflow: hidden;
    }
    .record-list-item-l{
        width: 45%;
        text-align: left;
        float: left;

    }
    .record-list-item-date{
        color: #999;
        font-size: 12px;
    }
    .record-list-item-c{
        float: left;
        min-width: 70px;
        margin-top: 5px;
    }
    .record-list-item-r{
        float: right;
        min-width: 70px;
        margin-top: 5px;
    }
    .record-no{
        text-align: center;
        font-size: 13px;
        color: #999;
        margin-top: 20px;
    }
</style>
