<template>
  <section class="park-detail" v-if="Object.keys(detail).length">
    <banner :images="detail.images" :hotelId="detail.id" />
    <div class="park-content">
      <div class="park-info card-info">
        <div class="park-name">
          {{ detail.name }}
        </div>
        <div class="park-sub-text">
          <span class="green">开放时间</span>
          {{ detail.opening_times }}
          <span class="gray">（仅供参考，以园区当日为准）</span>
        </div>
        <div
          :class="{
            'park-location': true,
            small: !detail.landmark || detail.landmark.length === 0,
          }"
        >
          <div class="park-address">
            <div :class="{ 'park-address-desc': true, ellipsis: true }">
              {{ detail.address }}
            </div>
            <div
              class="park-address-bus"
              v-if="detail.landmark && detail.landmark.length"
            >
              <div class="ellipsis">{{ detail.landmark[0] }}</div>
              <div class="ellipsis">{{ detail.landmark[1] }}</div>
            </div>
          </div>
          <div class="park-map" @click="toMap">
            <van-image
              class="park-map-icon"
              :src="require('@/assets/images/hotel/map_point.png')"
            />
            <div class="park-map-text">地图/导航</div>
          </div>
        </div>
        <div class="save-money">
          <div class="left">特权提示：五折优惠仅适用1张乐园门票</div>
        </div>
      </div>
      <ticket-detail :detail="detail" :coupon="coupon" />
    </div>
  </section>
</template>

<script>
import banner from "./banner";
import ticketDetail from "./ticketDetail";
import { mapGetters, mapState } from "vuex";
import dayjs from "dayjs";
import { qqMapTransBMap } from "@/utils";
import { getActivityCoupon } from "@/apis";
import { getParkDetail } from "@/apis/park";

export default {
  name: "hotelDetail",
  components: {
    banner,
    ticketDetail,
  },
  data() {
    return {
      detail: {},
      priceInfo: {},
      visible: false,
      showMore: false,
      images: [],
      show: false,
      couponList: [],
      coupon: null,
    };
  },
  computed: {
    ...mapState([
      "park",
      "namespace",
      "benefitHotelType",
      "isMiniProgram",
      "isWebchat",
      "isMember",
    ]),
    ...mapGetters(["maxCheckinDate", "namespaceGroup", "showHotelBack"]),
  },
  async mounted() {
    this.getDetail();
  },
  methods: {
    toOpen() {
      this.$jumpUrl("/pages/redeem/redeem");
    },
    async getDetail() {
      const { id } = this.$route.query;
      const loading = this.$loading();
      try {
        const res = await getParkDetail(id);
        this.getCoupons();
        res.data.images = res.data.images.map((item) => item.url);
        this.detail = res.data;
      } finally {
        loading.clear();
      }
    },
    async getCoupons() {
      const { id } = this.$route.query;
      const coupons = await getActivityCoupon({ scope: "park" });
      const couponList = coupons.data;
      const availableCoupon = couponList.filter((item) =>
        item.attrs.ticket_ids.includes(Number(id))
      );
      availableCoupon.sort((a, b) => a.discount_rate - b.discount_rate);
      this.coupon = availableCoupon[0];
    },
    async toMap() {
      if (this.isMiniProgram || this.isWebchat) {
        wx.ready(() => {
          wx.openLocation({
            name: this.detail.name,
            latitude: parseFloat(this.detail.latitude),
            longitude: parseFloat(this.detail.longitude),
          });
        });
        return;
      }
      const tude = qqMapTransBMap(this.detail.longitude, this.detail.latitude);
      window.location.href = `http://api.map.baidu.com/marker?location=${tude.latitude},${tude.longitude}&title=${this.detail.name}&output=html`;
    },
  },
};
</script>

<style lang="less" scoped>
.park-detail {
  position: relative;
  background: #f9f7fa;
}

.card-info {
  padding: 12px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
}

.park-content {
  .park-info {
    margin-top: -7px;
    position: relative;
    z-index: 100;
    padding: 20px;
  }
  .park-desc {
    color: #874c21;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .park-name {
    font-size: 18px;
    line-height: 25px;
    color: #222;
    margin-right: 5px;
    margin-bottom: 8px;
  }
  .park-sub-text {
    margin-bottom: 12px;
    .green {
      color: #00b87a;
    }
    .gray {
      color: #999;
    }
  }
}

.park-location {
  display: flex;
  height: 80px;
  border-radius: 8px;
  background: linear-gradient(to right, #ffffff 0%, #ffffff 75%, #edf2f8 100%);
  padding-top: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  &.small {
    padding-top: 0;
    height: 43px;
    align-items: center;
    background: linear-gradient(270deg, #edf2f8 0%, #ffffff 100%);
    .park-address-desc {
      margin-bottom: 0;
      line-height: 16px;
      font-size: 11px;
      font-weight: normal;
    }
    .park-map {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }
    .park-map-text {
      margin-right: 6px;
    }
    .park-map-icon {
      width: 23px;
      height: 23px;
    }
  }
}
.park-map-text {
  line-height: 16px;
  font-size: 11px;
  font-weight: bold;
}
.park-map-icon {
  width: 27px;
  height: 27px;
  margin: 3px auto 4px;
}

.park-address {
  margin-right: 9px;
  flex: 1;
  overflow: hidden;
}

.park-address-desc {
  line-height: 18px;
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: bold;
}

.park-address-bus {
  line-height: 16px;
  color: #666;
  font-size: 11px;
}

.park-map {
  flex-shrink: 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  color: #333;
  line-height: 17px;
}

.save-money {
  margin-top: 12px;
  height: 39px;
  background: linear-gradient(270deg, #565656 0%, #010101 100%);
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  border-radius: 8px;
  border: 0px solid #ffffff;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  color: #fbebd3;
  font-size: 11px;
  span {
    font-size: 16px;
  }
  .left {
    padding-left: 13px;
    background: url("~@/assets/images/savemoney-icon.png") no-repeat left
      center/10px;
  }
}
</style>
