<template>
  <section class="hotel-detail" v-if="Object.keys(detail).length">
    <div v-if="showHotelBack" class="hotel-back" @click="$router.back()" />
    <banner :images="detail.images" :hotelId="detail.id" />
    <div class="hotel-content">
      <div class="hotel-info card-info">
        <div class="hotel-desc">"{{ detail.rating }}"</div>

        <div class="flex">
          <div class="hotel-name">
            {{ detail.name }}
            <van-image
              class="hotel-type-image"
              :src="
                require(`@/assets/images/hotel2/icon-hotel-type${detail.classification}.png`)
              "
            />
          </div>
        </div>
        <van-row class="hotel-tags" :gutter="3">
          <van-col v-for="item in detail.tags" :key="item">
            <div class="hotel-tag">{{ item }}</div>
          </van-col>
        </van-row>
        <div
          :class="{
            'hotel-location': true,
            small: !detail.landmark || detail.landmark.length === 0,
          }"
        >
          <div class="hotel-address">
            <div :class="{ 'hotel-address-desc': true, ellipsis: true }">
              {{ detail.address }}
            </div>
            <div
              class="hotel-address-bus"
              v-if="detail.landmark && detail.landmark.length"
            >
              <div class="ellipsis">{{ detail.landmark[0] }}</div>
              <div class="ellipsis">{{ detail.landmark[1] }}</div>
            </div>
          </div>
          <div class="hotel-map" @click="toMap">
            <van-image
              class="hotel-map-icon"
              :src="require('@/assets/images/hotel/map_point.png')"
            />
            <div class="hotel-map-text">地图/导航</div>
          </div>
        </div>
        <div class="save-money" v-if="!$getConfigValue('HOTEL_SINGLE_BENEFIT')">
          <div class="left" v-if="isMember">
            本单{{ namespace === "oak" ? "黑卡" : "会员" }}已节省 ¥<span>{{
              saveMoney
            }}</span>
          </div>
          <div class="left" v-else>
            现在开通会员可节省 ¥<span>{{ saveMoney }}</span>
          </div>
          <div class="right" v-if="isMember" @click="visible = true">
            查看详情
          </div>
          <div class="right" v-else @click="toOpen">立即开通</div>
        </div>
      </div>
      <coupon
        :detail="detail"
        v-if="!$getConfigValue('HOTEL_SINGLE_BENEFIT')"
      />
      <room-detail
        :previewImage="previewImage"
        :detail="detail"
        :fetchDetail="getHotelDetail"
        :getPriceList="getPriceList"
      />
      <div class="hotel-facilities-container">
        <div class="hotel-facilities-title">设施/服务</div>
        <div class="hotel-facilities">
          <div
            class="hotel-facilitie"
            v-for="(item, idx) in detail.facilities"
            :key="idx"
          >
            <div class="hotel-facilitie-title">
              <div class="hotel-facilitie-icon">
                <svg-icon
                  :iconClass="`facilitie-${facilitiesIcons(item.name)}`"
                />
              </div>
              <div class="hotel-facilitie-name">
                {{ item.name }}
              </div>
            </div>
            <svg-icon
              class="hotel-facilitie-select"
              iconClass="hotelcheckmark"
            />
            <div class="hotel-facilitie-content">
              <span
                class="hotel-facilitie-content-item"
                v-for="(description, i) in item.descriptions"
                :key="i"
              >
                {{ description }}
              </span>
            </div>
          </div>
          <div
            v-if="faclen > 36"
            @click="showMore = true"
            class="show-more-facilitie"
          >
            查看全部设施·{{ faclen }}项
          </div>
        </div>
      </div>
      <div class="hotel-facilities-container">
        <div class="hotel-facilities-title">预订须知</div>
        <div class="hotel-policie-item" v-if="checkinText">
          <div class="hotel-policies-title">入离</div>
          <div class="hotel-policies-content">{{ checkinText }}</div>
        </div>
        <div class="hotel-policie-item">
          <div class="hotel-policies-title">确认</div>
          <div class="hotel-policies-content">
            下单后房间需确认，请耐心等待
            <div class="desc">
              如您等待订单确认已超过6小时，将预计24小时内完成确认，我们将为您加急处理，请耐心等待短信通知
            </div>
          </div>
        </div>
        <div class="hotel-policie-item">
          <div class="hotel-policies-title">退订</div>
          <div class="hotel-policies-content">
            <template v-if="detail.pay_method === 0">
              <div class="unsubscribe">入住前一天免费取消<span></span></div>
              <div class="desc">
                到店付，会员尊享入住前1天16:00前免费取消订单，成功取消后,担保金将于3~5个工作日原路返还
              </div>
            </template>
            <template v-else>
              <div>不可取消</div>
              <div class="desc">
                若预订酒店后，酒店已确认您的订单，则不可退改
              </div>
            </template>
          </div>
        </div>
        <div class="line"></div>
        <div class="hotel-policie-item">
          <div class="hotel-policies-title">发票</div>
          <div class="hotel-policies-content">
            <div class="fax">
              <svg-icon
                class="hotelpricetop"
                iconClass="hotelpricetop"
              />很棒！已含税总价
            </div>
            <div class="desc">
              {{
                detail.pay_method === 0
                  ? "离店时，请与酒店前台咨询开发票"
                  : "只提供电子增值税普通发票，离店后一个月内可联系本会员的人工客服开具发票，逾期视为放弃开票"
              }}
            </div>
          </div>
        </div>
        <div class="line line2"></div>

        <ul class="other-policy">
          <li class="other-policy-item" v-if="detail.pay_method === 0">
            <div class="other-policy-title">担保金</div>
            <div class="other-policy-content">
              <span class="other-policy-text">
                预订酒店需支付担保金200元，到店后支付房费并办理相关手续入住，<span
                  style="color: #2c912e"
                  >成功入住后担保金3~5个工作日返还</span
                >；未如约入住，担保金将扣除并冻结所有会员权益
              </span>
            </div>
          </li>
          <li
            class="other-policy-item"
            v-for="(item, idx) in otherPolicies"
            :key="idx"
          >
            <div class="other-policy-title">
              {{ item.name }}
            </div>
            <div class="other-policy-content">
              <span
                class="other-policy-text"
                v-for="(description, i) in item.descriptions"
                :key="i"
              >
                {{ description }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <van-popup
      closeable
      position="bottom"
      round
      class="advantages-popup"
      @click-overlay="visible = false"
      @click-close-icon="visible = false"
      :value="visible"
    >
      <div class="popup-title">会员专属特权</div>
      <div class="popup-content">
        <div
          class="advantages-item"
          v-for="(item, idx) in advantages"
          :key="idx"
        >
          <div class="advantages-flex">
            <svg-icon :icon-class="`tag2-${item.tag}`" />
            <div class="advantages-content">
              <div class="advantages-content-title">
                {{ item.title }}
                <div class="advantages-tag">{{ item.text }}</div>
              </div>
              <div class="advantages-content-desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      closeable
      class="facilities-popup"
      position="bottom"
      round
      @click-overlay="showMore = false"
      @click-close-icon="showMore = false"
      :value="showMore"
    >
      <div class="popup-title">设施/服务</div>
      <div class="popup-content">
        <ul class="hotel-facilities">
          <li
            class="hotel-facilitie"
            v-for="(item, idx) in detail.facilities"
            :key="idx"
          >
            <div class="hotel-facilitie-title">
              <div class="hotel-facilitie-name">
                {{ item.name }}
              </div>
            </div>
            <div class="hotel-facilitie-content">
              <span
                class="hotel-facilitie-content-item"
                v-for="(description, i) in item.descriptions"
                :key="i"
              >
                {{ description }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </van-popup>
    <sceen-swiper :images="images" v-model="show" />
  </section>
</template>

<script>
import banner from "./banner";
import coupon from "./coupon";
import roomDetail from "./roomDetail";
import { getHotelDetail, getHotelPriceInfo } from "@/apis/hotel";
import { mapGetters, mapState } from "vuex";
import dayjs from "dayjs";
import ans from "@/utils/ans";
import { qqMapTransBMap } from "@/utils";
import SceenSwiper from "@/components/SceenSwiper";

const tagicons = require.context(
  "@/icons",
  false,
  /tag2-[\u4e00-\u9fa5]+\.svg$/
);
const iconNames = tagicons.keys().map((item) => {
  const m = item.match(/tag2-([\u4e00-\u9fa5]+)\.svg$/);
  if (m) {
    return m[1];
  }
  return "";
});

const facilities = require.context(
  "@/icons",
  false,
  /facilitie-[\u4e00-\u9fa5]+\.svg$/
);
const facilitiesIcons = facilities.keys().map((item) => {
  const m = item.match(/facilitie-([\u4e00-\u9fa5]+)\.svg$/);
  if (m) {
    return m[1];
  }
  return "";
});

export default {
  name: "hotelDetail",
  components: {
    banner,
    coupon,
    roomDetail,
    SceenSwiper,
  },
  data() {
    return {
      detail: {},
      priceInfo: {},
      visible: false,
      showMore: false,
      images: [],
      show: false,
    };
  },
  computed: {
    ...mapState([
      "hotel",
      "namespace",
      "benefitHotelType",
      "isMiniProgram",
      "isWebchat",
      "isMember",
    ]),
    ...mapGetters(["maxCheckinDate", "namespaceGroup", "showHotelBack"]),

    saveMoney() {
      if (!this.detail?.rooms.length) return 0;
      return (
        this.detail.rooms[0].prices[0].origin_price -
        this.detail.rooms[0].prices[0].price
      );
    },
    otherPolicies() {
      return this.detail.policies?.filter((item) => item.name !== "入离时间");
    },
    checkinText() {
      const item = this.detail.policies?.find(
        (item) => item.name === "入离时间"
      );
      if (!item) {
        return "";
      }
      return item.descriptions.join(" ");
    },
    faclen() {
      return (this.detail.facilities || [])
        .map((item) => item.descriptions)
        .flat().length;
    },

    advantages() {
      return this.detail.advantages.map((item) => ({
        tag: iconNames.includes(item[0]) ? item[0] : "会员专属价",
        desc: item[2],
        title: item[1],
        text: item[0],
      }));
    },
  },
  async mounted() {
    this.getHotelDetail();
    const { id } = this.$route.query;
    const res = await getHotelPriceInfo({
      hotel_id: id,
      is_oak: this.$getConfigValue("OA_HOTEL_RULE") ? "1" : "0",
    });
    this.priceInfo = res.data;
  },
  methods: {
    facilitiesIcons(name) {
      return facilitiesIcons.includes(name) ? name : "通用服务";
    },
    previewImage(image) {
      this.images = [image];
      this.show = true;
    },
    toOpen() {
      this.$jumpUrl("/pages/redeem/redeem");
    },
    getPriceList(date) {
      if (this.$getConfigValue("HOTEL_SINGLE_BENEFIT")) {
        return;
      }
      const { priceInfo } = this;
      let startDate = date.subtract(2, "day");
      let range = 10;
      const today = dayjs().startOf("day");
      if (startDate.isBefore(today, "day")) {
        startDate = today;
        let diff = date.diff(startDate, "day");
        range = diff + 8;
      }
      const price = {};
      for (let i = 0; i < range; i++) {
        if (startDate.add(i, "day").isAfter(dayjs(this.maxCheckinDate))) {
          break;
        }
        const month = startDate.add(i, "day").format("MM-DD");
        price[month] = priceInfo.special_price[month] || priceInfo.price;
      }
      return price;
    },
    async getHotelDetail() {
      const { id } = this.$route.query;
      const loading = this.$loading();
      try {
        const params = {
          namespace: this.namespace,
          checkin_date: this.hotel.checkin_date,
          checkout_date: this.hotel.checkout_date,
          id,
          is_oak: this.$getConfigValue("OA_HOTEL_RULE") ? "1" : "0",
        };
        if (this.benefitHotelType === "ehotel") {
          params.classification = 3;
          params.namespace = `${params.namespace}-affordable`;
        }
        const res = await getHotelDetail(params);
        this.detail = res.data;
        ans.pageView(this.$route.meta.page, {
          namespace: this.namespace,
          company_id: res.data.company[0].id,
          company_name: res.data.company[0].name,
          destination_name: this.hotel.city,
          checkin_date: dayjs(this.hotel.checkin_date).format("YYYY-MM-DD"),
          hotel_type: res.data.classification,
          hotel_id: res.data.id,
          hotel_name: res.data.name,
          is_direct_sign: res.data.is_direct_sign,
          is_special: res.data.is_special,
          pay_method: res.data.pay_method,
          hotel_price: res.data.rooms[0].prices[0].price,
        });
      } finally {
        loading.clear();
      }
    },
    async toMap() {
      if (this.isMiniProgram || this.isWebchat) {
        wx.ready(() => {
          wx.openLocation({
            name: this.detail.name,
            latitude: parseFloat(this.detail.latitude),
            longitude: parseFloat(this.detail.longitude),
          });
        });
        return;
      }
      const tude = qqMapTransBMap(this.detail.longitude, this.detail.latitude);
      window.location.href = `http://api.map.baidu.com/marker?location=${tude.latitude},${tude.longitude}&title=${this.detail.name}&content=${this.detail.rating}&output=html`;
    },
  },
};
</script>

<style lang="less" scoped>
.hotel-detail {
  position: relative;
  background: #f9f7fa;
}
.hotel-back {
  position: absolute;
  left: 15px;
  width: 24px;
  height: 24px;
  top: 10px;
  background: url("~@/assets/images/hotel_back.png") no-repeat;
  background-size: 100%;
  z-index: 1000;
}
.hotel-type-image {
  width: 54px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.card-info {
  padding: 12px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
}

.hotel-content {
  .hotel-info {
    margin-top: -7px;
    position: relative;
    z-index: 100;
    padding: 13px 20px 20px;
  }
  .hotel-desc {
    color: #874c21;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .hotel-name {
    font-size: 18px;
    line-height: 25px;
    color: #222;
    margin-right: 5px;
    margin-bottom: 6px;
  }
}
.hotel-tags {
  margin-bottom: 15px;
}
.hotel-tag {
  height: 27px;
  line-height: 27px;
  background: #f8eeeb;
  padding: 0 7px;
  border-radius: 4px;
  color: #a3541e;
  font-size: 11px;
  .font-family(PingFangSC-Semibold);
}
.hotel-location {
  display: flex;
  height: 80px;
  border-radius: 8px;
  background: linear-gradient(to right, #ffffff 0%, #ffffff 75%, #edf2f8 100%);
  padding-top: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  &.small {
    padding-top: 0;
    height: 43px;
    align-items: center;
    background: linear-gradient(270deg, #edf2f8 0%, #ffffff 100%);
    .hotel-address-desc {
      margin-bottom: 0;
      line-height: 16px;
      font-size: 11px;
      font-weight: normal;
    }
    .hotel-map {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }
    .hotel-map-text {
      margin-right: 6px;
    }
    .hotel-map-icon {
      width: 23px;
      height: 23px;
    }
  }
}
.hotel-map-text {
  line-height: 16px;
  font-size: 11px;
  font-weight: bold;
}
.hotel-map-icon {
  width: 27px;
  height: 27px;
  margin: 3px auto 4px;
}

.hotel-address {
  margin-right: 9px;
  flex: 1;
  overflow: hidden;
}

.hotel-address-desc {
  line-height: 18px;
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: bold;
}

.hotel-address-bus {
  line-height: 16px;
  color: #666;
  font-size: 11px;
}

.hotel-map {
  flex-shrink: 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  color: #333;
  line-height: 17px;
}

.save-money {
  margin-top: 12px;
  height: 39px;
  background: linear-gradient(270deg, #565656 0%, #010101 100%);
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  border-radius: 8px;
  border: 0px solid #ffffff;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  color: #fbebd3;
  font-size: 11px;
  span {
    font-size: 16px;
  }
  .left {
    padding-left: 13px;
    background: url("~@/assets/images/savemoney-icon.png") no-repeat left 6px/10px;
  }
  .right {
    margin-left: auto;
    height: 23px;
    background: linear-gradient(222deg, #fff9f1 0%, #ffe0b9 100%);
    box-shadow: inset 0px 1px 0px 0px #ffffff;
    border-radius: 13px;
    border: 0px solid #ffffff;
    line-height: 24px;
    text-align: center;
    width: 64px;
    color: #2c2c2c;
  }
}

.hotel-facilities-title {
  line-height: 20px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: bold;
}
.hotel-facilitie {
  display: flex;
  text-align: center;
  margin-bottom: 20px;
}
.hotel-facilitie-icon {
  font-size: 22px;
  margin-bottom: 2px;
}
.hotel-facilitie-title {
  flex-basis: 45px;
  margin-right: 15px;
  font-size: 10px;
  flex-shrink: 0;
  .font-family(PingFangSC-Semibold);
}
.hotel-facilitie-select {
  font-size: 16px;
  margin-right: 6px;
  flex-shrink: 0;
}
.hotel-facilitie-content {
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-height: 23px;
  color: #777;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.hotel-facilitie-content-item {
  margin-right: 10px;
}

.show-more-facilitie {
  height: 28px;
  line-height: 28px;
  box-sizing: border-box;
  padding: 0 10px;
  box-sizing: border-box;
  margin: 20px auto 0;
  border-radius: 14px;
  border: 1px solid #999999;
  font-size: 11px;
  color: #000;
  max-width: max-content;
}

.advantages {
  padding-right: 18px;
  background: url("~@/assets/images/hotel/advantages_arrow.png") no-repeat right
    center;
  background-size: 10px;
}

.advantages-icon {
  display: inline-block;
  width: 10px;
  margin-right: 2px;
}

.advantages-name {
  color: #ff5001;
}
.advantages-popup {
  border-radius: 16px 16px 0 0 !important;
  /deep/ .van-popup__close-icon {
    left: 18px;
    top: 24px;
    color: #666;
    font-size: 0;
    width: 13px;
    height: 13px;
    background: url("~@/assets/images/close.svg") no-repeat center/100%;
  }
  .popup-content {
    position: relative;
    margin-top: -17px;
    border-radius: 10px 10px 0px 0px;
    z-index: 10;
    padding: 25px 36px;
    background: #fff;
  }
  .popup-title {
    height: 80px;
    background: linear-gradient(to right, #ffe0b9, #fff9f1);
    line-height: 22px;
    padding-top: 20px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    box-sizing: border-box;
  }
}

.advantages-item {
  margin-bottom: 16px;
}

.advantages-flex {
  display: flex;
  .advantages-icon {
    flex-basis: 31px;
    height: 31px;
    flex-shrink: 0;
    margin-right: 6px;
  }
  .advantages-content {
    flex: 1;
  }
  .svg-icon {
    font-size: 31px;
    margin-right: 6px;
  }
}

.advantages-content-title {
  line-height: 21px;
  font-size: 15px;
  color: #222;
  margin-bottom: 4px;
  font-weight: bold;
}
.advantages-tag {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  border-radius: 19px;
  background: #fef7f9;
  color: #ff5001;
  font-size: 10px;
  font-weight: normal;
  padding: 0 6px;
}

.advantages-content-desc {
  line-height: 18px;
  color: #999;
  font-size: 10px;
}

.hotel-facilities-container {
  padding: 18px 24px;
  background: #fff;
  & + .hotel-facilities-container {
    margin-top: 8px;
  }
}

.hotel-facilities-title {
  line-height: 25px;
  font-size: 18px;
  color: #333;
  margin-bottom: 18px;
}

.hotel-policie-item {
  display: flex;
  font-size: 13px;
  color: #000;
  margin-bottom: 16px;
  line-height: 1.5;
}
.hotel-policies-title {
  flex-basis: 43px;
  flex-shrink: 0;
  font-size: 13px;
  line-height: 21px;

  color: #000000;
  .font-family(PingFangSC-Medium);
}

.unsubscribe {
  color: #2c912e;
  span {
    display: inline-block;
    width: 52px;
    height: 16px;
    background: url("~@/assets/images/hotel-unsubscribe-tag.png") no-repeat
      center/100%;
    vertical-align: middle;
    margin-left: 4px;
  }
}

.hotel-policies-content {
  line-height: 21px;
  .desc {
    margin-top: 3px;
    color: #777777;
    line-height: 21px;
    font-size: 11px;
    line-height: 19px;
  }
  .hotelpricetop {
    margin-right: 2px;
  }
  .fax {
    font-size: 11px;
    color: #ff5001;
  }
}
.hotel-policies-content-item {
  margin-right: 5px;
  flex-shrink: 0;
}

.line {
  height: 1px;
  background: #d2d2d2;
  opacity: 0.13;
  margin: 4px 0 20px;
}

.facilities-popup {
  .popup-title {
    text-align: center;
    padding: 14px 0 20px;
    line-height: 22px;
    font-size: 16px;
    color: #222;
    font-weight: bold;
  }
  .hotel-facilities {
    padding: 0 20px;
  }
  .hotel-facilitie {
    display: block;
  }
  .hotel-facilitie-name {
    text-align: left;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .hotel-facilitie-content {
    display: block;
    text-align: left;
    color: #979797;
  }
}

.other-policy-item {
  padding-top: 19px;
  &:not(:last-child) {
    padding-bottom: 17px;
    border-bottom: 1px solid rgba(210, 210, 210, 0.13);
  }
}

.other-policy-title {
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 8px;
}
.other-policy-content {
  font-size: 11px;
  color: #777;
  line-height: 21px;
}

.line2 {
  margin-bottom: 0;
}
</style>
