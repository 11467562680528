<template>
  <div class="dossen" v-if="pageShow">
    <img
      class="header-img"
      :src="belle_type === 'tt' ? `${CDN}tt.png` : `${CDN}tmy.png`"
      alt=""
    />
    <div class="header-main">
      <div class="dossen-title">
        <img class="dossen-title-bg" :src="`${CDN}title-bg.png`" alt="" />
        <div class="dossen-title-content">
          {{ benefitInfo.title }}
          <div class="dossen-desc">{{ benefitInfo.subtitle }}</div>
        </div>
      </div>
      <div class="belle-btn">
        <div
          class="dossen-btn"
          @click="show = true"
          v-ans="{
            event_id: '$user_click',
            behavior: 'button1',
            namespace: namespace,
            spu_name: benefitInfo.name,
            spu_id: benefitInfo.id,
          }"
        >
          注册会员
        </div>
        <div
          class="dossen-btn get-coupon"
          @click="showGetCouponHandle"
          v-ans="{
            event_id: '$user_click',
            behavior: 'button2',
            namespace: namespace,
            spu_name: benefitInfo.name,
            spu_id: benefitInfo.id,
          }"
        >
          {{
            benefitInfo.amount_reserved + benefitInfo.amount_available === 0
              ? "已领取"
              : "领取优惠券"
          }}
        </div>
      </div>
    </div>
    <div class="dossen-bennefit">
      <div class="dossen-bennefit-title">权益介绍</div>
      <van-image
        v-if="!benefitInfo.benefit_instruction"
        lazy-load
        :src="benefitInfo.desc_img_url"
        alt=""
      />
      <div
        v-else
        v-html="benefitInfo.benefit_instruction"
        class="rich-text"
      ></div>
      <div class="link" @click="toExtraLink2">
        {{ benefitInfo.desc_extra_link_name }}
      </div>
      <div class="dossen-bennefit-title">使用指南</div>
      <van-image
        v-if="benefitInfo.manual_img_url"
        lazy-load
        :src="benefitInfo.manual_img_url"
        alt=""
      />
      <div
        v-if="!benefitInfo.manual_img_url && skuInfo.instruction"
        v-html="skuInfo.instruction"
        class="rich-text"
      ></div>
      <div
        v-if="!benefitInfo.manual_img_url && !skuInfo.instruction"
        v-html="skuInfo.spu_instruction"
        class="rich-text"
      ></div>
      <div class="link" @click="toExtraLink">
        {{ benefitInfo.manual_extra_link_name }}
      </div>
    </div>
    <div class="dossen-height"></div>
    <van-dialog
      v-model="show"
      theme="round-button"
      class="belle-dialog"
      confirm-button-text="立即注册"
      confirm-button-color="linear-gradient(90deg, #FFC360 0%, #FFEBA6 100%)"
      cancel-button-text="稍后注册"
      cancel-button-color="#fff"
      :before-close="registerBelle"
      show-cancel-button
    >
      <div class="belle-doalog-mian">
        <div>请补充注册信息，以便我们为您更好的服务</div>
        <div class="belle-form">
          <div class="belle-form-item">
            <span>*手机号</span>
            <van-field
              v-model="phone_value"
              type="tel"
              class="belle-input"
              placeholder="请输入本人手机号"
              :disabled="user_phone ? true : false"
            />
          </div>
          <div class="belle-form-item">
            <span>*性别</span>
            <div class="belle-form-gender" @click="showPicker = true">
              {{ gender }}
              <img
                class="belle-form-unfold"
                :src="require('../../assets/images/unfold.png')"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
    <van-dialog
      v-model="showGetCoupon"
      theme="round-button"
      class="belle-dialog"
      confirm-button-text="立即领取"
      confirm-button-color="linear-gradient(90deg, #FFC360 0%, #FFEBA6 100%)"
      cancel-button-text="稍后领取"
      cancel-button-color="#fff"
      :before-close="getCoupon"
      show-cancel-button
    >
      <div class="belle-doalog-mian">
        <div>即将领取权益，请确认个人信息</div>
        <div class="belle-form">
          <div class="belle-form-item">
            <span>*手机号</span>
            <van-field
              v-model="phone_value"
              type="tel"
              class="belle-input"
              placeholder="请输入本人手机号"
              :disabled="user_phone ? true : false"
            />
          </div>
        </div>
      </div>
    </van-dialog>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { CDN } from "../../config";
import {
  activateBenefit,
  getSku,
  matchMemberUserInfo,
  updateUserPhone,
} from "@/apis/home";
import { getBenefitInfo, getUserInfo } from "@/apis";
import { verifyForm } from "../../utils";
import { mapMutations, mapState } from "vuex";
import ans from "@/utils/ans";
export default {
  name: "belle",
  data() {
    return {
      CDN,
      pageShow: false,
      belle_type: "",
      user_phone: "",
      phone_value: null,
      show: false,
      showPicker: false,
      columns: ["男", "女"],
      gender: "请选择",
      showGetCoupon: false,
      benefitInfo: {},
      skuInfo: {},
    };
  },
  components: {},
  computed: {
    ...mapState(["userInfo", "namespace"]),
  },
  async mounted() {
    const { belle_type } = this.$route.query;
    this.belle_type = belle_type;
    const toast = this.$loading();
    try {
      let benefit_type = "";
      belle_type === "tt" && (benefit_type = "belle_tt");
      belle_type === "tmy" && (benefit_type = "belle_tm");
      const res = await getBenefitInfo({ benefit_type });
      const res2 = await getSku({ benefit_type, namespace: this.namespace });
      this.skuInfo = res2.data[0];
      this.benefitInfo = res.data;
      this.user_phone = this.userInfo.user_phone;
      if (this.userInfo.user_phone) {
        this.phone_value = this.userInfo.user_phone;
      }
      this.pageShow = true;
    } finally {
      toast.clear();
    }
    ans.pageView(this.$route.meta.page, {
      namespace: this.namespace,
      spu_name: this.benefitInfo.name,
      spu_id: this.benefitInfo.id,
    });
  },
  methods: {
    ...mapMutations(["changeState"]),
    showGetCouponHandle() {
      if (
        this.benefitInfo.amount_reserved + this.benefitInfo.amount_available ===
        0
      ) {
        this.$toast("您已领取过优惠券");
        return;
      }
      this.showGetCoupon = true;
    },
    async getCoupon(e, done) {
      const { belle_type } = this.$route.query;
      let type = belle_type === "tt" ? "belle_tt" : "belle_tm";
      if (e === "confirm") {
        const verifyPhone = verifyForm.verifyPhone(this.phone_value);
        if (verifyPhone) {
          this.$toast(verifyPhone);
          done(false);
          return;
        }
        const toast = this.$loading();
        try {
          if (!this.user_phone && this.phone_value) {
            await updateUserPhone({
              user_phone: this.phone_value,
              is_save: "F",
            });
          }
          //查询用户是否注册过百丽会员
          const belle_info = await matchMemberUserInfo({
            benefit_source: "会员权益",
            benefit_type: type,
            phone_number: this.phone_value,
            namespace: this.namespace,
          });
          //如果不是百丽会员, 弹窗先注册百丽会员
          if (belle_info.data.is_member === "0") {
            this.showGetCoupon = false;
            this.show = true;
            return;
          }
          //领取百丽优惠券
          const res = await activateBenefit({
            behavior: "get_coupon_code",
            benefit_source: "会员权益",
            benefit_type: type,
            namespace: this.namespace,
            phone_number: this.phone_value,
            activation_platform: this.skuInfo.activation_platform,
            item_code: this.skuInfo.item_code,
            is_zero_pay: true,
          });
          // if(res.data.is_member === '0'){
          //    this.show = true;
          //    return;
          // }
          this.$confirm({
            message:
              this.skuInfo.activation_tips ||
              "您已领取专属优惠券，可前往品牌微信公众号会员中心查看优惠券，并前往线下门店使用。",
            className: "belle-dialog-color",
            showCancelButton: false,
            confirmButtonText: "好的",
          });
          //更新权益信息
          const res2 = await getBenefitInfo({ benefit_type: type });
          this.benefitInfo = res2.data;
        } finally {
          done();
          toast.clear();
        }
      } else {
        done();
      }
    },
    onConfirm(value) {
      this.gender = value;
      this.showPicker = false;
    },
    async registerBelle(e, done) {
      const { belle_type } = this.$route.query;
      let type = belle_type === "tt" ? "belle_tt" : "belle_tm";
      if (e === "confirm") {
        try {
          const verifyPhone = verifyForm.verifyPhone(this.phone_value);
          if (verifyPhone) {
            this.$toast(verifyPhone);
            done(false);
            return;
          }
          if (!this.user_phone && this.phone_value) {
            await updateUserPhone({
              user_phone: this.phone_value,
              is_save: "F",
            });
          }
          if (this.gender === "请选择") {
            this.$toast("请选择性别");
            done(false);
            return;
          }
          const toast = this.$loading();
          try {
            //注册百丽会员
            const res = await matchMemberUserInfo({
              behavior: "register_user",
              benefit_source: "会员权益",
              benefit_type: type,
              sex: this.gender === "男" ? 1 : 2,
              namespace: this.namespace,
              phone_number: this.phone_value,
              activation_platform: type,
            });
            this.$confirm({
              message: `您已升级至${this.skuInfo.item_name}，尊享会员权益，可前往领取专属优惠券。`,
              className: "belle-dialog-color",
              cancelButtonText: "去领券",
              confirmButtonText: "稍后领取",
            }).catch(() => {
              if (
                this.benefitInfo.amount_reserved +
                  this.benefitInfo.amount_available ===
                0
              ) {
                this.$toast("您已领取过优惠券");
                return;
              }
              this.showGetCoupon = true;
            });
            this.user_phone = this.phone_value;
          } finally {
            done();
            toast.clear();
          }
        } finally {
          done();
        }
      } else {
        done();
      }
    },
    toExtraLink() {
      window.location.href = this.benefitInfo.manual_extra_url;
    },
    toExtraLink2() {
      window.location.href = this.benefitInfo.desc_extra_url;
    },
  },
};
</script>

<style lang='less' scoped>
.dossen {
  background-color: #f8f8f8;
}
.header-img {
  width: 100%;
}
.header-main {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(153, 153, 153, 0.1);
  border-radius: 8px;
  margin: 0 10px;
  position: relative;
  z-index: 9;
  margin-top: -89px;
  padding-bottom: 15px;
  overflow: hidden;
}
.dossen-title {
  box-sizing: border-box;
  padding-top: 15px;
  height: 85px;
  padding-left: 10px;
  color: #202437;
  font-size: 20px;
  font-weight: bold;
}
.dossen-desc {
  font-size: 13px;
  color: #666;
  font-weight: normal;
  margin-top: 7px;
  line-height: 18px;
}
.dossen-phone {
  box-sizing: border-box;
  font-size: 15px;
  color: #333;
  margin: 15px 10px 0;
  height: 47px;
  line-height: 47px;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding-left: 10px;
  display: flex;
}
.belle-input {
  height: 47px;
  border: none;
  background-color: transparent;
  width: 200px;
  padding: 0 0 0 20px;
  height: 32px;
  line-height: 32px;
}
.belle-input input {
  text-align: end;
}
::-webkit-input-placeholder {
  color: #c6c8d0;
}
:-moz-placeholder {
  color: #c6c8d0;
}
::-moz-placeholder {
  color: #c6c8d0;
}
:-ms-input-placeholder {
  color: #c6c8d0;
}
.dossen-footer {
  box-sizing: border-box;
  background-color: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 65px;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  z-index: 11;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.dossen-height {
  height: 30px;
}
.dossen-bennefit {
  background-color: #fff;
  border-radius: 8px;
  margin: 10px 10px 0;
  padding-top: 1px;
  padding-bottom: 16px;
}
.dossen-bennefit-title {
  font-size: 13px;
  color: #202437;
  font-weight: bold;
  margin: 20px 0 12px 10px;
}
.dossen-btn {
  border-radius: 25px;
  background: linear-gradient(90deg, #ffc360 0%, #ffeba6 100%);
  height: 50px;
  line-height: 50px;
  margin: 20px 25px 0;
  color: #853119;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.dossen-title-bg {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.dossen-title-content {
  position: relative;
  z-index: 9;
  line-height: 30px;
}
.dossen-icon {
  display: block;
  width: 60px;
  margin: 0 auto 26px;
}
.belle-btn {
  display: flex;
  justify-content: space-between;
  margin: 20px 15px 0;
}
.belle-btn .dossen-btn {
  width: 156px;
  margin: 0 auto;
}
.get-coupon {
  box-sizing: border-box;
  background: none;
  border: 1px solid #999;
  color: #999;
  font-weight: normal;
}
.belle-doalog-mian {
  padding: 27px 15px 0;
  font-size: 14px;
  color: #333;
  text-align: center;
}
.belle-form {
  padding: 20px 0;
}
.belle-form-item {
  display: flex;
  justify-content: space-between;
  padding: 0 36px;
  line-height: 32px;
  margin-bottom: 10px;
}
.belle-form-item span {
  width: 70px;
}
.belle-form-gender {
  box-sizing: border-box;
  width: 97px;
  text-align: left;
  border: 1px solid #999;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  color: #999;
  display: flex;
  justify-content: space-between;
}
.belle-form-unfold {
  width: 14px;
  height: 14px;
  margin-top: 8px;
  margin-right: 4px;
}
.belle-dialog.van-dialog--round-button .van-dialog__cancel {
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 40px;
  border: 1px solid #999 !important;
  color: #999 !important;
  font-weight: normal;
}
.belle-dialog.van-dialog--round-button .van-dialog__cancel .van-button__text,
.belle-dialog.van-dialog--round-button .van-dialog__confirm .van-button__text {
  line-height: 38px !important;
}
.belle-dialog.van-dialog--round-button .van-dialog__confirm {
  margin-left: 10px;
  border-radius: 40px;
  color: #853119 !important;
}
.belle-dialog-color.van-dialog--confirm .van-dialog__confirm {
  background: linear-gradient(90deg, #ffc360 0%, #ffeba6 100%);
  color: #853119 !important;
}
.link {
  font-size: 13px;
  color: #4892f0;
  padding: 0 24px;
}
.rich-text {
  font-size: 28px;
  color: #333;
  line-height: 1.6;
  padding: 0 20px;
}
</style>
