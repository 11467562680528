<template>
  <div class="extra-wrap">
    <img
      src="https://common-h5-dev.xiangshuheika.com/static/upload/20240603fd0a59c2db38a.png"
    />
    <div class="extra-card canDraw">
      <div class="card-main">
        <img :src="extraBenefit.icon_img_url" alt="" />
        <div>
          <div class="content">
            <p>{{ extraBenefit.name }}</p>
            <div>活动加赠</div>
          </div>
          <div class="card-time">活动时间:{{ extraBenefit.desc }}</div>
        </div>
      </div>
      <div class="draw">
        <p v-if="drawTime">{{ drawTime }}</p>
        <div class="btn" @click="handleDraw">领取</div>
      </div>
      <div class="card-footer">先领京东plus年卡，领取成功后获得此权益</div>
    </div>
  </div>
</template>


<script>
import {
  defineComponent,
  computed,
  onMounted,
  ref,
} from "@vue/composition-api";
import Vue from "vue";

import { useRouter, useStore } from "@/components/use/base";
import { getIndexCategory, fetchJdPlusOrder } from "@/apis/product";
import { getOrder, memberOrderJoinActivity } from "@/apis";

const vm = Vue.prototype;

export default defineComponent({
  props: {
    benefitList: {
      default: [],
      type: Array,
    },
  },

  setup({ benefitList }) {
    const extraBenefit = ref({});
    const jdBenefit = ref({});

    const router = useRouter();
    const store = useStore();

    const namespace = store.state.namespace;

    onMounted(async () => {
      await getCategory();
      jdBenefit.value = benefitList[0];
    });

    const getCategory = async () => {
      const { data: categoriesData } = await getIndexCategory({
        namespace: namespace,
        opened_page: "member",
        source: "default",
      });
      extraBenefit.value = categoriesData.map((value) => {
        const benefitObj =
          benefitList.filter((item) => item.type === value.display_code) || [];
        return {
          ...value,
          benefitObj,
        };
      })[0];
    };

    const drawTime = computed(() => {
      return extraBenefit.value.benefitObj?.[0]?.amount
        ? `剩${extraBenefit.value.benefitObj?.[0]?.amount}次`
        : " ";
    });

    const handleDraw = async () => {
      const loading = vm.$loading();
      try {
        const { data } = await fetchJdPlusOrder();
        if (!data) {
          vm.$toast("请先领取京东PLUS年卡");
          return;
        }
        const { data: orderData } = await getOrder({ category_type: "vip" });
        const orderNumber = orderData.res_list[0].order_number;
        await memberOrderJoinActivity({
          activity_name: "kkplus_add_point_for_gift",
          order_number: orderNumber,
          reward_platform: "add_benefit_point_for_gift",
        });

        router.push(
          `/benefitDetail?benefit_type=${extraBenefit.value.display_code}`
        );
      } catch (e) {
        console.log(e);
      } finally {
        loading.clear();
      }
    };

    return {
      extraBenefit,
      jdBenefit,
      handleDraw,
      drawTime,
    };
  },
});
</script>

<style lang="less" scoped>
.extra-wrap {
  width: 346px;
  height: 195px;
  background: #ffffff;
  border-radius: 13px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 25px;
  position: relative;
  padding-top: 36px;

  > img {
    height: 30px;
    width: 156px;
    position: absolute;
    top: -9px;
    left: 50%;
    transform: translateX(-50%);
  }

  .notDraw {
    background-image: url("https://common-h5-dev.xiangshuheika.com/static/upload/20240603a5a609065e6fc.png");
  }
  .canDraw {
    background-image: url("https://common-h5-dev.xiangshuheika.com/static/upload/20240603346021ecc40ba.png");
  }

  .extra-card {
    background-size: cover;
    background-repeat: no-repeat;
    height: 136px;
    width: 313px;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 25px;
    padding-left: 18px;
    box-sizing: border-box;
    position: relative;
  }
  .card-main {
    display: flex;
    > img {
      width: 43px;
      height: 43px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .content {
      display: flex;
      align-items: center;

      > p {
        font-weight: 600;
        font-size: 14px;
        color: #333333;
      }
      > div {
        padding: 2px 5px;
        background: linear-gradient(297deg, #ff341b 0%, #ff9c5e 100%);
        border-radius: 10px;
        margin-left: 2px;

        line-height: 15px;
        font-weight: 600;
        font-size: 11px;
        color: #ffffff;
        text-align: center;
      }
    }
    .card-time {
      margin-top: 3px;
      font-weight: 400;
      font-size: 11px;
      color: #666666;
      line-height: 16px;
    }
  }
  .card-footer {
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(
        to right,
        #f4d494 0%,
        #f4d494 60%,
        transparent 50%
      );
      background-size: 9px;
      background-repeat: y-repeat;
      margin-bottom: 9px;
    }

    width: fit-content;
    margin-top: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 11px;
    color: #666666;
    line-height: 16px;
    text-align: left;
    font-style: normal;
  }
  .draw {
    position: absolute;
    top: 35px;
    right: 8px;

    p {
      font-weight: 400;
      font-size: 11px;
      color: #372938;
      line-height: 16px;
      text-align: center;
      height: 16px;
    }
    .btn {
      margin-top: 5px;
      background: #ffffff;
      font-weight: 400;
      font-size: 11px;
      color: #372938;
      line-height: 26px;
      text-align: center;
      border-radius: 13px;
      width: 60px;
      height: 26px;
      box-sizing: border-box;

      &.disable {
        background: #d2d2d2;
        color: #ffffff;
      }
    }
  }
}
</style>
