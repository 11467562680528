<template>
  <div class="header">
    <svg-icon class="icon" iconClass="arrow-left" @click="handleBack" />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { useRouter } from "@/components/use/base";

export default defineComponent({
  setup() {
    const router = useRouter();

    const handleBack = () => {
      router.go(-1);
    };

    return {
      handleBack,
    };
  },
});
</script>

<style lang="less" scoped>
.header {
  height: 44px;
  display: flex;
  align-items: center;
  font-size: 20px;
  padding-left: 16px;
}
</style>
