<template>
  <div class="luck-draw-body">
    <van-image
      class="luck-draw-bg"
      :src="activityInfo.special_info.header_img"
    />
    <div class="luck-draw-component">
      <LuckyWheel
        class="luckyturn"
        :width="width"
        :height="height"
        :defaultConfig="{
          gutter: '1px',
          offsetDegree,
        }"
        ref="luckyWheelRef"
        :prizes="prizesList"
        @start="drawHandleThrottle"
        :default-style="{
          fontColor: '#F7923B',
          fontSize: '13px',
        }"
        :blocks="[]"
        :buttons="buttons"
        @end="endLucky"
      />
    </div>
    <div class="draw-btn-wrap">
      <van-button
        block
        round
        type="primary"
        class="draw-btn1"
        @click="drawHandleThrottle"
      >
        {{ activityInfo.special_info.start_draw_text || "开始抽奖" }}
        {{ remainingTimes > 0 ? `X${remainingTimes}` : "" }}
      </van-button>
    </div>
    <Dialog
      v-model="showDialog"
      :activityReward="activityReward"
      :type="type"
      :openPrizePopup="openPrizePopup"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "@vue/composition-api";
import throttle from "lodash.throttle";

import { LuckyWheel } from "@lucky-canvas/vue";
import Dialog from "./Dialog";

export default defineComponent({
  inheritAttrs: false,

  components: {
    LuckyWheel,
    Dialog,
  },
  props: {
    activityInfo: Object,
    activityReward: Object,
    beforeDraw: Function,
    openPrizePopup: Function,
    afterDraw: Function,
    type: String,
    remainingTimes: Number,
  },
  setup(props) {
    const luckyWheelRef = ref(null);
    const showDialog = ref(false);
    const run = ref(false);
    const buttons = ref([
      {
        radius: "30%",
        imgs: [
          {
            src:
              props.activityInfo.special_info.arrowImg ||
              `https://common-h5-dev.xiangshuheika.com/static/upload/20221118f457cd7b50d2469f8cfad6dc25041d02.png`,
            width: "100px",
            top: "-65px",
          },
        ],
      },
    ]);

    const width = computed(() => {
      const w = props.activityInfo.special_info.width || 280;
      return (
        (document.documentElement.getBoundingClientRect().width / 10) *
        (w / 37.5)
      );
    });
    const height = computed(() => {
      const w =
        props.activityInfo.special_info.height ||
        props.activityInfo.special_info.width ||
        280;
      return (
        (document.documentElement.getBoundingClientRect().width / 10) *
        (w / 37.5)
      );
    });

    const prizesList = computed(() => {
      // 转盘背景色
      const bgColor = (
        props.activityInfo.special_info.prizeBackground || "#fff3e5,#ffe478"
      )
        .split(",")
        .filter(Boolean);
      if (bgColor.length === 1) {
        bgColor[1] = bgColor[0];
      }
      const goods_choice = props.activityInfo.special_info.goods_choice || {};
      const goods = Object.entries(goods_choice).map(([k, v], index) => ({
        title: v.prize_title || v.prize_name,
        item_code: k,
        background: index % 2 == 0 ? bgColor[0] : bgColor[1],
        max_num: v.max_num,
        min_num: v.min_num,
        imgs: [
          {
            src: v.img,
            width: props.activityInfo.special_info.prizeWidth || "70%",
            top: props.activityInfo.special_info.prizeTop || "12%",
          },
        ],
      }));
      goods.sort((a, b) => a.min_num - b.min_num);
      return goods;
    });

    const offsetDegree = computed(() => {
      return -360 / prizesList.value.length / 2;
    });

    const startLucky = async () => {
      if (run.value) {
        return;
      }
      const res = await props.beforeDraw();
      if (!res) {
        return;
      }
      luckyWheelRef.value.play();
      run.value = true;
      setTimeout(() => {
        const index = prizesList.value.findIndex(
          (r) => r.item_code === res.item_code
        );
        luckyWheelRef.value.stop(index);
      }, 2000);
    };
    const drawHandleThrottle = throttle(startLucky, 3000, { trailing: false });

    const endLucky = () => {
      showDialog.value = true;
      setTimeout(async () => {
        await props.afterDraw();
        run.value = false;
      }, 300);
    };
    return {
      buttons,
      prizesList,
      width,
      height,
      endLucky,
      drawHandleThrottle,
      luckyWheelRef,
      showDialog,
      offsetDegree,
    };
  },
});
</script>
