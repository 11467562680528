import Vue from 'vue';
import dayjs from 'dayjs';
import { decimal, maskStr } from '@/utils';

Vue.filter('formatDate', function (value, format = 'YYYY-MM-DD') {
  if (!value) return '';
  if (/^\d+$/.test(value)) {
    value = Number(value);
  }
  return dayjs(value).format(format);
});

Vue.filter('zero', function (value) {
  if (!/^\d+$/.test(value)) {
    return value;
  }

  return value < 10 ? `0${value}` : value;
});


Vue.filter('decimal', decimal);

// 转换模板中的变量
Vue.filter('formatText', function (replaceText, obj) {
  if (!replaceText) {
    return replaceText;
  }
  return replaceText.replace(/{([^{}]+)}/g, (str, $) => {
    return obj[$];
  });

});

Vue.filter('week', function (str) {
  if (typeof str === 'string' && /^\d+$/.test(str)) {
    str = Number(str);
  }
  const day = dayjs(str);

  const weekEnum = [
    ["0", "周日"],
    ["1", "周一"],
    ["2", "周二"],
    ["3", "周三"],
    ["4", "周四"],
    ["5", "周五"],
    ["6", "周六"],
  ];
  const now = dayjs().startOf('day');
  const diff = day.diff(now, 'day') + '';
  const diffEnum = {
    '0': '今天',
    '-1': '昨天',
    '1': '明天',
    '2': '后天',
  }
  try {
    return diffEnum[diff] || weekEnum[day.day()][1];
  } catch (e) {
    return ''
  }
})

Vue.filter('maskStr', maskStr)

Vue.filter('getCoundFromSellpoint', str => {
  const text = str.split("x")[1] || "";
  return Number(text.match(/(\d+)/)?.[0] || 0);
})