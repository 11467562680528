<template>
  <section
    class="hotel-item"
    @click="toDetail"
    v-ans="{
      event_id: 'hotel_list_click_hotel',
      destination_name: data.city,
      checkin_date: dayjs(hotel.checkin_date).format('YYYY-MM-DD'),
      hotel_type: data.classification,
      hotel_id: data.id,
      hotel_name: data.name,
      hotel_price: data.lowest_price,
      is_special: data.is_special,
      pay_method: data.pay_method,
      is_direct_sign: data.is_direct_sign,
    }"
  >
    <van-image
      class="hotel-image"
      fit="cover"
      lazy-load
      :src="data.images[0]"
    />
    <div class="hotel-info">
      <div class="hotel-name">
        {{ data.name }}
        <van-image
          class="hotel-type-image"
          :src="
            require(`@/assets/images/hotel2/icon-hotel-type${data.classification}.png`)
          "
        />
      </div>
      <div class="hotel-desc">"{{ data.rating }}"</div>
      <van-row class="advantages">
        <van-col
          v-for="item in advantages"
          class="advantage-item"
          :key="item.name"
        >
          <svg-icon v-if="item.icon" :iconClass="`tag-${item.icon}`" />{{
            item.name
          }}
        </van-col>
      </van-row>
    </div>
    <div class="hotel-footer">
      <div class="hotel-map ellipsis">
        {{ data.address }}
      </div>
      <div
        v-if="data.is_all_room_date_unavailable"
        class="room-unavailable"
      ></div>
      <div class="hotel-price" v-else>
        <span v-if="data.lowest_origin_price" class="hotel-face_value"
          >¥{{ data.lowest_origin_price }}</span
        >
        <span class="hotel_real_price"
          ><small>¥</small>{{ data.lowest_price }}</span
        >
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    iconNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dayjs,
    };
  },
  computed: {
    ...mapState(["hotel"]),
    advantages() {
      return this.data.advantages.map((item) => ({
        icon: this.iconNames.includes(item[0]) ? item[0] : "",
        name: item[0],
      }));
    },
  },
  methods: {
    toDetail() {
      this.$router.push(`/hotel/detail?id=${this.data.id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.hotel-item {
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  position: relative;
  & + & {
    margin-top: 20px;
  }
}
.hotel-type-image {
  width: 54px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}
.hotel-image {
  height: 178px;
}
.hotel-name {
  font-size: 17px;
  line-height: 24px;
  color: #000;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(#f5f5f5, 0.6);
  margin-bottom: 13px;
}
.hotel-info {
  padding: 12px 14px 20px 12px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.hotel-desc {
  color: #a3541e;
  font-size: 12px;
  line-height: 17px;
}

.advantage-item {
  height: 20px;
  line-height: 20px;
  border: 1px solid rgba(#874c21, 0.3);
  border-radius: 4px;
  color: #a3541e;
  margin-right: 4px;
  font-size: 10px;
  padding: 0 5px;
  .svg-icon {
    vertical-align: -0.5px;
    margin-right: 1px;
  }
}

.advantages {
  margin-top: 8px;
}
.hotel-footer {
  height: 42px;
  background: url("~@/assets/images/hotel/hotel_map.png") no-repeat;
  background-size: contain;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
}
.hotel-map {
  text-indent: 15px;
  background: url("~@/assets/images/map-icon2.png") no-repeat left center;
  background-size: contain;
  color: #333;
  line-height: 13px;
  flex: 1;
}
.hotel-price {
  height: 33px;
  line-height: 33px;
  margin-bottom: 9px;
  flex: 1;
  text-align: right;
}
.hotel_real_price {
  font-size: 24px;
  font-weight: bold;
  color: #ff5001;
  small {
    font-size: 0.5em;
  }
}
.hotel-face_value {
  line-height: 17px;
  color: #999;
  text-decoration: line-through;
  margin-right: 4px;
  font-size: 11px;
}
.room-unavailable {
  position: absolute;
  right: 14px;
  bottom: 12px;
  width: 72px;
  height: 72px;
  background: url("~@/assets/images/hotel/room_unavailable.png") no-repeat;
  background-size: 100%;
}
</style>
