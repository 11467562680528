<template>
  <div>
    <div id="custom-style" ref="styleRef"></div>
    <van-dialog
      v-model="showOpenvip"
      :beforeClose="beforeClose"
      confirm-button-text="放弃奖励特权"
      cancel-button-text="去开通领奖"
      theme="round-button"
      class="openvip"
      show-cancel-button
    >
      <div class="open-dialog">
        <div v-if="activityReward.prize_name">
          恭喜您已抽中{{ activityReward.prize_name }}，
        </div>
        <div>
          奖品将在
          <van-count-down class="count-down" :time="countDown" />内失效。
        </div>
      </div>
    </van-dialog>
    <div
      class="lucky-main"
      :style="{
        backgroundImage: `url(https://cdn.xiangshuheika.com/static/activity/${activityInfo.name}-lucky_pagebg.png)`,
      }"
    >
      <div class="rules" @click="showRules = true">活动规则</div>
      <img
        class="header-text"
        :src="`https://cdn.xiangshuheika.com/static/activity/${activityInfo.name}-header_text.png`"
        alt=""
      />
      <div class="lucky-wrap">
        <div class="prize-list">
          <div class="prize-list-top">
            <div>我的奖品</div>
            <div class="count-down" v-if="showCountDown">
              倒计时：
              <van-count-down
                class="count-down2"
                :time="first_prize_countDown"
                format="HH时mm分ss秒"
              />
            </div>
          </div>
          <div class="first-prize">
            <div class="first-prize-wrap" v-if="prizeRecord.length">
              <div class="first-prize-l">
                <img
                  class="first-prize-img"
                  :src="CDN + `${prizeRecord[0].extra_info.item_code}.png`"
                  alt=""
                />
                <div>{{ prizeRecord[0].extra_info.prize_name }}</div>
              </div>
              <div
                class="prize-get"
                v-if="prizeRecord[0].status === 5"
                @click="toUse(prizeRecord[0])"
              >
                去使用
              </div>
              <div
                class="prize-get"
                v-if="prizeRecord[0].status === 7"
                @click="toGet(prizeRecord[0])"
              >
                立即领取
              </div>
              <div class="prize-get expired" v-if="prizeRecord[0].status === 8">
                已失效
              </div>
            </div>
            <div v-else class="prize-list-empty">暂无奖品，快去抽奖吧~</div>
          </div>
          <div
            v-if="prizeRecord.length > 1"
            class="prize-more"
            @click="show = true"
          >
            查看更多>
          </div>
        </div>
        <div
          class="lucky-box"
          :style="{
            backgroundImage: `url(https://cdn.xiangshuheika.com/static/activity/${activityInfo.name}-lucky_bg2.png)`,
          }"
        >
          <LuckyWheel
            class="lucky"
            :width="width"
            :height="width"
            :defaultConfig="defaultConfig"
            ref="luckyWheel"
            :prizes="prizes"
            @start="drawHandle"
            :default-style="defaultStyle"
            :blocks="[]"
            :buttons="lucky_button"
            @end="endLucky"
          />
        </div>
        <img
          class="pedestal"
          src="https://cdn.xiangshuheika.com/static/activity/pedestal.png"
          alt=""
        />
      </div>
      <van-action-sheet v-model="show" duration="0.2" title="我的奖品">
        <div class="draw-list">
          <div
            v-for="item in prizeRecord"
            :key="item.t_created"
            class="draw-item"
          >
            <div class="draw-item-l">
              <van-image
                lazy-load
                :src="CDN + `${item.extra_info.item_code}.png`"
                alt=""
              />
            </div>
            <div class="draw-name">{{ item.extra_info.prize_name }}</div>
            <div
              class="draw-use-btn"
              v-if="item.status === 5"
              @click="toUse(item)"
            >
              去使用
            </div>
            <div
              class="draw-use-btn"
              v-if="item.status === 7"
              @click="toGet(item)"
            >
              {{ !userInfo.is_member ? "待领奖" : "领取" }}
            </div>
            <div class="draw-use-btn expired" v-if="item.status === 8">
              已失效
            </div>
          </div>
          <div v-if="!prizeRecord.length" class="draw-no-prize">
            还没有抽中奖品哦~
          </div>
        </div>
      </van-action-sheet>
      <div class="lucky-dialog" v-if="luckyDialog">
        <div
          class="lucky-dialog-main"
          :style="{
            backgroundImage: `url(https://cdn.xiangshuheika.com/static/activity/dialog-bg.png)`,
          }"
        >
          <div class="lucky-dialog-title">
            <img
              class="decorate"
              src="../../assets/images/decorate.png"
              alt=""
            />
            恭喜您
            <img
              class="decorate"
              src="../../assets/images/decorate.png"
              alt=""
            />
          </div>
          <div class="lucky-dialog-text">
            为数不多的“{{ activityReward.prize_name }}”被你抽中了！
            <van-count-down
              class="count-down"
              :time="countDown"
            />内开通会员即可使用奖励，数量有限，先到先得。
          </div>
          <div class="lucky-dialog-btn">
            <div class="lu-btn confirm-btn" @click="toOpen">去开通领奖</div>
            <div class="lu-btn cancel-btn" @click="hideLuckyDialog">
              放弃奖励特权
            </div>
          </div>
        </div>
      </div>
      <div v-if="showRules" class="rules-wrap">
        <div class="rules-main">
          <div class="rules-main-within">
            <img
              class="rules-title-img"
              src="../../assets/images/rules.png"
              alt=""
            />
            <div class="rules-content" v-html="activityInfo.rules"></div>
            <img
              class="rules-close"
              src="../../assets/images/close.png"
              alt=""
              @click="showRules = false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { handleActivityReward, getAtivityRecord } from "../../apis/luckdraw";
import { getStrFromJsonStr, storage } from "../../utils";
import { mapState } from "vuex";
import { LuckyWheel } from "@lucky-canvas/vue";
import throttle from "lodash.throttle";
import dayjs from "dayjs";
import ans from "@/utils/ans";
import { CDN } from "@/config/index";
Vue.component("LuckyWheel", LuckyWheel);

export default {
  props: {
    activityInfo: Object,
    prizeRecord: Array,
    fetchActivityInfo: Function,
  },
  name: "luckDraw",
  inheritAttrs: false,
  data() {
    return {
      show: false,
      activity_name: `${storage.getItem("namespace")}_blind_box`,
      defaultStyle: {
        fontColor: "#F7923B",
        fontSize: "13px",
      },
      defaultConfig: {
        gutter: "1px",
      },
      showOpenvip: false,
      countDown: 0,
      luckyDialog: false,
      showRules: false,
      activityReward: {},
      lucking: false,
      CDN,
    };
  },
  computed: {
    ...mapState(["userInfo", "namespace"]),
    width() {
      const w = this.activityInfo.special_info.width || 280;
      return (
        (document.documentElement.getBoundingClientRect().width / 10) *
        (w / 37.5)
      );
    },
    amount() {
      return (
        this.activityInfo.special_info.times_per_day -
        this.activityInfo.amt_of_today
      );
    },
    type() {
      return this.activityInfo.special_info.type;
    },
    prizes() {
      const goods_choice = this.activityInfo.special_info.goods_choice || [];
      const goods = Object.entries(goods_choice).map(([k, v], index) => ({
        title: v.prize_title || v.prize_name,
        item_code: k,
        background: index % 2 == 0 ? "#fff3e5" : "#ffe478",
        imgs: [{ src: v.img, width: "20%", top: "13%" }],
      }));
      return goods;
    },
    lucky_button() {
      return [
        {
          radius: "50px",
          imgs: [
            {
              src:
                this.amount > 0
                  ? "https://cdn.xiangshuheika.com/static/activity/lucky_arrow2.png"
                  : "https://cdn.xiangshuheika.com/static/activity/lucky_arrow3.png",
              width: "126%",
              top: "-75px",
            },
          ],
        },
      ];
    },
    first_prize_countDown() {
      if (this.prizeRecord.length) {
        //创建时间
        const s_time = dayjs(this.prizeRecord[0].t_created).valueOf();
        const s_time2 = dayjs(this.userInfo.t_vip_created).valueOf();
        //有效时间，单位：分钟
        const { period_of_validity = 0, card_member_period_of_validity = 0 } =
          this.activityInfo.special_info;
        //计算剩余有效时间，单位：毫秒
        if (this.userInfo.is_member) {
          return (
            Number(card_member_period_of_validity) * 60 * 1000 -
            (dayjs().valueOf() - s_time2)
          );
        } else {
          return (
            Number(period_of_validity) * 60 * 1000 -
            (dayjs().valueOf() - s_time)
          );
        }
      } else {
        return 0;
      }
    },
    showCountDown() {
      let show = false;
      if (
        this.prizeRecord.length &&
        this.prizeRecord[0]?.status === 7 &&
        this.first_prize_countDown > 0
      ) {
        show = true;
      }
      return show;
    },
  },

  methods: {
    endLucky() {
      //非会员抽中奖品后提示开通会员
      if (this.activityReward?.status === 6) {
        this.$confirm({
          message: this.activityReward.prize_name,
          showCancelButton: false,
          confirmButtonText: "好的",
        });
        return;
      }
      this.afterDraw();
      this.countTime(this.activityReward.current_time);
      this.luckyDialog = true;
      this.lucking = false;
    },

    //获取活动信息

    beforeDraw() {
      let token = storage.getItem("token");
      if (!token) {
        this.$toast("您还未登录，请先登录~");
        return;
      }
      if (!this.activityInfo.is_active) {
        this.$toast("活动已结束");
        return;
      }
      //this.activityInfo.special_info.is_member === false  会员不可参与
      if (
        !this.activityInfo.special_info.is_member &&
        this.userInfo.is_member
      ) {
        this.$toast("您已是会员，无法参与抽奖活动，快去领取奖品吧");
        return;
      }
      //如果不是会员 并且 非会员不能参与
      if (
        !this.userInfo.is_member &&
        !this.activityInfo.special_info.is_visitor
      ) {
        const un_index = this.$getConfigValue("UN_INDEX");
        if (un_index) {
          this.$confirm({
            message: "您还不是会员，开通会员即可享受特惠权益",
            cancelButtonText: "我再想想",
            confirmButtonText: "去开通",
          }).then(() => {
            location.href = un_index;
          });
        } else {
          this.$toast("本活动仅限VIP会员参加，请先开通会员~");
        }
        return;
      }
      if (!this.amount) {
        this.$toast("没有次数了哦~");
        return;
      }
      return true;
    },
    //开始抽奖按钮
    drawHandle: throttle(
      function () {
        if (this.lucking || !this.beforeDraw()) {
          return;
        }
        this.lucking = true;
        handleActivityReward({
          activity_name: this.activity_name,
          source: "会员权益",
          reward_platform: this.activityInfo.reward_platform,
          hideMsg: true,
        })
          .then((res) => {
            this.toast && this.toast.clear();
            this.activityReward = res.data;
            ans.track("blindbox_benefit_click", {
              item_code: res.data.item_code,
            });
            // 开始转动
            this.$refs.luckyWheel.play();
            // 抽奖2s
            setTimeout(() => {
              const index = this.prizes.findIndex(
                (r) => r.item_code === res.data.item_code
              );
              // 结束抽奖
              this.$refs.luckyWheel.stop(index);
            }, 2000);
          })
          .catch((e) => {
            this.lucking = false;
            this.$toast(e?.msg || "活动太火爆了，请稍后再试");
          });
      },
      3000,
      { trailing: false }
    ),

    //去领奖 or 去使用
    toUse(item) {
      let token = storage.getItem("token");
      if (
        item.operation === "use" ||
        (item.extra_info && item.extra_info.operation === "use")
      ) {
        this.$router.push(
          `/orderDetail?order_number=${
            item.order_number || item.extra_info.order_number
          }`
        );
      }
      if (
        item.operation === "buy" ||
        (item.extra_info && item.extra_info.operation === "buy")
      ) {
        if (this.namespace.includes("life")) {
          this.$jumpUrl(
            `/benefit_detail?id=${
              item.benefit_type || item.extra_info.benefit_type
            }&user_token=${token}`
          );
        } else {
          this.$jumpUrl(
            `/pages/benefit_detail/benefit_detail?benefit_type=${
              item.benefit_type || item.extra_info.benefit_type
            }&user_token=${token}`
          );
        }
      }
    },
    toGet(item) {
      if (this.userInfo.is_member) {
        this.toast = this.$loading();
        handleActivityReward({
          activity_name: this.activity_name,
          source: "会员权益",
          reward_platform: this.activityInfo.reward_platform,
          order_number: item.extra_info.order_number,
        })
          .then(() => {
            this.toast.clear();
            this.toUse(item);
          })
          .catch(() => {
            this.toast.clear();
          });
      } else {
        this.activity_reward = {};
        this.countTime(dayjs(item.t_created).valueOf());
        if (this.countDown > 0) {
          this.showOpenvip = true;
        } else {
          this.$toast("奖品已失效，请刷新页面重试");
        }
      }
    },
    async afterDraw() {
      await Promise.all([this.fetchActivityInfo()]);
    },
    hideLuckyDialog() {
      this.luckyDialog = false;
    },
    toOpen() {
      ans.track("visitor_activity_buy", {
        namespace: storage.getItem("namespace"),
        userid: this.userInfo.id,
      });
      this.hideLuckyDialog();
      const un_index = this.$getConfigValue("UN_INDEX");
      if (un_index) {
        location.href = un_index;
      } else {
        this.$toast("请开通会员后领奖哦~");
      }
    },

    countTime(create_time) {
      //创建时间
      const s_time = dayjs(create_time).valueOf();
      //有效时间，单位：分钟
      const { period_of_validity = 0 } = this.activityInfo.special_info;
      //计算剩余有效时间，单位：毫秒
      this.countDown =
        Number(period_of_validity) * 60 * 1000 - (dayjs().valueOf() - s_time);
    },
    beforeClose(action, done) {
      done();
      if (action === "cancel") {
        ans.track("visitor_activity_buy", {
          namespace: storage.getItem("namespace"),
          userid: this.userInfo.id,
        });
        const un_index = this.$getConfigValue("UN_INDEX");
        if (un_index) {
          location.href = un_index;
        } else {
          this.$toast("请开通会员后领奖哦~");
        }
      }
    },
  },
};
</script>


<style lang='less' scoped>
.lucky-main {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 1px;
}
.header-text {
  display: block;
  width: 295px;
  margin: 32px auto 0;
}
.lucky-page-bg {
  position: relative;
  z-index: 1;
  width: 100%;
}
.lucky-wrap {
  width: 100%;
  z-index: 2;
  margin-top: 19px;
}
.lucky-box {
  width: 345px;
  height: 345px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: url("https://cdn.xiangshuheika.com/static/activity/lucky_bg.png")
    no-repeat;
  background-size: 100%;
  margin-top: 20px;
  position: relative;
  z-index: 2;
}
.lucky {
  margin: 0 auto;
  background-size: 100%;
}
.prize-list {
  background: linear-gradient(0deg, #ffaf5d 0%, #ffe3ac 100%);
  margin: 0 17px;
  border-radius: 5px;
  padding: 13px 8px 10px;
}
.prize-list-top {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #832f14;
  font-weight: bold;
  line-height: 17px;
}
.prize-list-top .count-down {
  font-weight: normal;
  font-size: 11px;
  color: #333;
}
.prize-list-top .count-down2 {
  display: inline-block;
  font-size: 11px;
}
.first-prize {
  background-color: #fff;
  border-radius: 4px;
  height: 74px;
  margin-top: 6px;
  padding: 0 14px 0 0;
}
.pedestal {
  display: block;
  width: 300px;
  margin: 0 auto;
  transform: translateY(-52px);
  position: relative;
  z-index: 1;
}
.first-prize-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 74px;
}
.first-prize-img {
  width: 72px;
}
.prize-get {
  width: 75px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 15px;
  background: linear-gradient(90deg, #ff7d51 0%, #ff5122 100%);
  font-size: 13px;
  color: #fff;
}
.first-prize-l {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #333;
  font-weight: bold;
}
.prize-list-empty {
  text-align: center;
  line-height: 80px;
  color: #8b8b8b;
  font-size: 16px;
}
.prize-more {
  text-align: center;
  color: #832f14;
  font-size: 12px;
  margin-top: 10px;
}
.lucky-dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
}
.lucky-dialog-main {
  background-color: #fff;
  position: absolute;
  width: 84%;
  height: 290px;
  left: 8%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  overflow: hidden;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.lucky-dialog-title {
  font-size: 23px;
  color: #333;
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lucky-dialog-text {
  margin: 16px 30px 0;
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}
.lucky-dialog-btn {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 4px;
  width: 100%;
  .lu-btn {
    text-align: center;
    height: 39px;
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: center;
    border-radius: 20px;
    margin: 0 27px;
  }
  .cancel-btn {
    color: #ff7a86;
    margin-top: 4px;
  }
  .confirm-btn {
    background: linear-gradient(90deg, #ffe599 0%, #fee058 100%);
    color: #ad491f;
  }
}
.count-down {
  color: #ff4242;
}
.lucky-dialog-text .van-count-down {
  display: inline-block;
  font-size: 16px;
}

.draw-list {
  margin: 0 15px;
  border-top: 1px solid #dcdcdc;
  padding-bottom: 30px;
  height: 400px;
  padding-top: 5px;
  overflow-y: scroll;
}
.draw-no-prize {
  text-align: center;
  font-size: 13px;
  color: #999;
  margin-top: 20px;
}
.draw-item {
  height: 87px;
  background: url("../../assets/images/draw/prize-bg2.png") no-repeat 0 0;
  background-size: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.draw-item-l {
  width: 87px;
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.draw-name {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  margin-left: 16px;
}
.draw-item-l img {
  width: 51px;
  height: 51px;
}
.draw-use-btn {
  display: flex;
  width: 56px;
  height: 21px;
  background: #ff5001;
  border-radius: 11px;
  line-height: 21px;
  color: #fff;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  margin-left: auto;
}
.rules {
  position: absolute;
  right: 0;
  top: 23px;
  width: 60px;
  height: 22px;
  border-radius: 11px 0 0 11px;
  background: linear-gradient(0deg, #fc3749 0%, #fc4249 100%);
  border: 1px solid #fdebc5;
  font-size: 12px;
  color: #ffeed0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rules-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
}
.rules-main {
  box-sizing: border-box;
  width: 84%;
  position: absolute;
  left: 8%;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(37deg, #fe7544 0%, #fc3749 100%);
  border-radius: 5px;
  padding: 5px;
}
.rules-main-within {
  background: #fffdfb;
  border-radius: 5px;
  padding: 0 16px 26px;
}
.rules-title-img {
  width: 184px;
  height: 35px;
  display: block;
  margin: 0 auto;
  transform: translateY(-1px);
}
.rules-content {
  margin-top: 30px;
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  height: 300px;
  overflow-y: scroll;
  white-space: pre-wrap;
}
.rules-close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 18px;
}
.decorate {
  margin: 0 10px;
  width: 60px;
}
.decorate:first-child {
  transform: rotate(180deg);
}
.expired {
  background: #999;
}
.open-dialog {
  text-align: center;
  padding: 36px 24px 26px;
  font-size: 14px;
  line-height: 1.6;
}
.open-dialog .van-count-down {
  display: inline-block;
}
.count-down {
  color: #ff4242;
}
</style>