import BuffetLayout from '@/layout/BuffetLayout';

export default {
  path: '/buffet',
  name: 'buffet',
  component: BuffetLayout,
  children: [{
    path: '/buffet/list',
    name: 'buffetlist',
    component: require('@/views/buffet/list').default,
    meta: {
      title: '门店列表',
      pageName: '门店列表',
      page: 'page_buffet_list',
    }
  }, {
    path: '/buffet/city',
    name: 'buffetcity',
    component: require('@/views/buffet/city').default,
    meta: {
      title: '选择城市',
      pageName: '选择城市',
      page: 'page_buffet_city',
    }
  }, {
    path: '/buffet/detail',
    name: 'buffetdetail',
    component: require('@/views/buffet/detail').default,
    meta: {
      title: '自助餐',
      pageName: '自助餐',
      page: 'page_buffet_detail',

    }
  }, {
    path: '/buffet/book',
    name: 'buffetbook',
    component: require('@/views/buffet/book').default,
    meta: {
      title: '订单填写',
      pageName: '订单填写',
      page: 'page_buffet_book',
    }
  },
  ]
}