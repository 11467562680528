import request from "@/utils/request"

export const getOfflineParkList = (params) => {
  return request({
    url: '/api/park/get_list',
    method: 'get',
    params,
  })
}
export const getParkDetail = (id) => {
  return request({
    url: `/api/park/${id}`,
    method: 'get',
  })
}

export const getParkTicketList = (params) => {
  return request({
    url: '/api/park/ticket/get_list',
    method: 'get',
    params,
  })
}
export const getParkTickekDetail = (id, params) => {
  return request({
    url: `/api/park/ticket/${id}`,
    method: 'get',
    params,
  })
}

export const realnameVerifyPure = (data) => {
  return request({
    url: `/api/core/realname_verify_pure`,
    method: 'post',
    data,
  })
}
