
import BusinessLayout from '@/layout/BusinessLayout';

export default {
  path: '/business',
  redirect: '/business/home',
  component: BusinessLayout,
  children: [{
    path: '/business/home',
    name: 'businessHome',
    component: require('@/views/business/home').default,
    meta: {
      title: 'COCO PLUS',
      pageName: '星河会员首页',
      showTabbar: true,
      page: 'page_xinsheng',
    }
  }, {
    path: '/business/coupon',
    name: 'businessCard',
    component: require('@/views/business/coupon').default,
    meta: {
      title: '卡券',
      showTabbar: true,
      pageName: '卡券页',
      pageData: ['namespace'],
      page: 'page_coupon',


    }
  }, {
    path: '/business/member',
    name: 'businessMember',
    component: require('@/views/business/member').default,
    meta: {
      title: '我的',
      showTabbar: true,
      pageName: '星河会员我的页',
      pageData: ['namespace'],
      page: 'page_xinshengmy',

    }
  }, {
    path: '/business/park',
    component: require('@/views/business/park').default,
    name: 'benefitPark',
    meta: {
      title: '选择商圈',
      pageName: '选择商圈',
      pageData: ['namespace'],
      page: 'page_xinshengpark',
    }
  }]
}