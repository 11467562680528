var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('main',{staticClass:"centification"},[_c('van-tabs',{class:{ 'centification-type': true, disabled: _vm.is_verified },attrs:{"color":"#333","type":"card"},on:{"change":_vm.change},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"中国居民身份证"}}),(!_vm.needFace)?_c('van-tab',{attrs:{"title":"无中国居民身份证"}}):_vm._e()],1),_c('van-form',{staticClass:"centification-form",attrs:{"disabled":_vm.is_verified,"input-align":"right","show-error":false,"show-error-message":false},on:{"submit":_vm.onSubmit,"failed":_vm.onFailed}},[(_vm.active === 0)?_c('van-field',{staticClass:"field-input",attrs:{"label":"姓","maxlength":"30","placeholder":_vm.active === 0 ? '请输入中文姓' : '请输入英文姓氏',"rules":[
        {
          required: true,
          message: '请输入姓',
        },
        {
          pattern: /[\u4e00-\u9fa5]{1,10}/,
          message: '姓必须是中文',
        } ]},model:{value:(_vm.last_name_cn),callback:function ($$v) {_vm.last_name_cn=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"last_name_cn"}}):_c('van-field',{staticClass:"field-input",attrs:{"label":"姓","maxlength":"30","placeholder":_vm.active === 0 ? '请输入中文姓' : '请输入英文姓氏',"rules":[
        {
          required: true,
          message: '请输入姓',
        } ]},model:{value:(_vm.last_name_cn),callback:function ($$v) {_vm.last_name_cn=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"last_name_cn"}}),(_vm.active === 0)?_c('van-field',{staticClass:"field-input",attrs:{"label":"名","maxlength":"30","placeholder":_vm.active === 0 ? '请输入中文名' : '请输入英文名字',"rules":[
        { required: true, message: '请输入名' },
        {
          pattern: /[\u4e00-\u9fa5]{1,10}/,
          message: '名必须是中文',
        } ]},model:{value:(_vm.first_name_cn),callback:function ($$v) {_vm.first_name_cn=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"first_name_cn"}}):_c('van-field',{staticClass:"field-input",attrs:{"label":"名","maxlength":"30","placeholder":_vm.active === 0 ? '请输入中文名' : '请输入英文名字',"rules":[{ required: true, message: '请输入名' }]},model:{value:(_vm.first_name_cn),callback:function ($$v) {_vm.first_name_cn=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"first_name_cn"}}),(_vm.active === 1)?_c('van-field',{attrs:{"readonly":"","clickable":"","name":"picker","value":_vm.id_number_type_text,"label":"证件类型","placeholder":"请选择","rules":[{ required: true, message: '请选择证件类型' }]},on:{"click":function($event){_vm.showPicker = true}}}):_vm._e(),(_vm.active === 0)?_c('van-field',{staticClass:"field-input",attrs:{"label":"身份证","maxlength":"18","placeholder":"请输入身份证号码","rules":[
        { required: true, message: '请输入身份证号码' },
        {
          pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
          message: '请输入正确的居民身份证号码',
        } ]},model:{value:(_vm.id_number),callback:function ($$v) {_vm.id_number=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"id_number"}}):_vm._e(),(_vm.active === 0)?_c('van-field',{staticClass:"field-input",attrs:{"label":"手机号","disabled":!!_vm.userInfo.user_phone,"maxlength":"11","placeholder":"请输入手机号","rules":[
        { required: true, message: '请输入手机号' },
        {
          pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
          message: '请输入正确的手机号',
        } ]},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phone_number"}}):_vm._e(),(_vm.active === 1)?_c('van-field',{staticClass:"field-input",attrs:{"label":"证件","maxlength":"30","placeholder":"请输入证件号码","rules":[{ required: true, message: '请输入证件号码' }]},model:{value:(_vm.id_number),callback:function ($$v) {_vm.id_number=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"id_number"}}):_vm._e(),(_vm.active === 1)?_c('van-field',{staticClass:"field-input",attrs:{"disabled":!!_vm.userInfo.user_phone,"label":"手机号","maxlength":"11","placeholder":"请输入手机号","rules":[
        { required: true, message: '请输入手机号' },
        {
          pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
          message: '请输入正确的手机号',
        } ]},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phone_number"}}):_vm._e(),_c('van-field',{staticStyle:{"height":"1px"},attrs:{"readonly":""}}),(!_vm.is_verified)?_c('div',{staticClass:"centification-button"},[_c('van-button',{attrs:{"round":"","block":"","type":"info","size":"large","native-type":"submit"}},[_vm._v("立即认证并保存")])],1):_vm._e()],1),_c('div',{staticClass:"centification-safe2"},[_vm._v(" 点击认证，即视为同意 "),_c('span',{staticClass:"blue",on:{"click":_vm.toServer}},[_vm._v("《用户服务协议》")]),_c('span',{staticClass:"blue",on:{"click":_vm.toInfo}},[_vm._v("《个人信息保护政策》")])]),_vm._m(0),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.columns},on:{"confirm":_vm.onConfirm,"cancel":function($event){_vm.showPicker = false}}})],1)],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"centification-safe"},[_c('div',{staticClass:"centification-safe-text"},[_vm._v("安全保障,身份认证后不可更改")])])}]

export { render, staticRenderFns }