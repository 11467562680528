<template>
  <floor>
    <template #title>{{ title }}</template>
    <template #subTitle>｜{{ subTitle }}</template>
    <template #tip>{{ desc }}</template>
    <template #main>
      <div class="food-benefit">
        <div class="food-card" v-for="item of foodArr" :key="item.name">
          <div class="food-title-wrap">
            <div>
              ¥<span class="food-price">{{ item.price }}</span
              ><span class="food-buy">购</span>
            </div>
            <div class="food-text">{{ item.text }}</div>
          </div>
          <div class="logo-wrap">
            <img :src="item.img" />
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
      <toUseBtn :product_code="productInfo[0].product_code" :notPay="notPay" />
    </template>
  </floor>
</template>

<script>
import toUseBtn from "./toUseBtn.vue";
import floor from "./floor.vue";

export default {
  props: ["desc", "name", "to_url", "productInfo", "notPay"],
  components: {
    toUseBtn,
    floor,
  },
  data() {
    return {
      foodArr: [],
    };
  },
  computed: {
    title() {
      return this.name.split("｜")[0];
    },
    subTitle() {
      return this.name.split("｜")[1];
    },
  },
  mounted() {
    const FOOD_ARR =
      JSON.parse(this.$getConfigValue("ZA_CARD_CONGIG")).FOOD_ARR || [];
    this.foodArr = [...FOOD_ARR];
  },
};
</script>

<style scoped lang="less">
.floor {
  margin-top: 24px;
  padding: 0 16px;
  .title-wrap {
    display: flex;
    justify-content: space-between;
    font-family: PingFang-SC, PingFang-SC;
    .title {
      height: 22px;
      font-size: 16px;
      font-weight: bold;
      color: #27314a;
      line-height: 22px;
      display: flex;
      align-items: center;
      > span {
        margin-left: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #455368;
        line-height: 20px;
      }
    }
    .tip {
      background: #fff5e8;
      border-radius: 10px;
      line-height: 20px;
      color: rgba(69, 83, 104, 1);
      padding: 1px 12px 0px 12px;
    }
  }
}

.food-benefit {
  display: flex;
  flex-wrap: wrap;
  margin-top: 13px;

  > .food-card {
    width: 168px;
    height: 64px;
    background: url("https://common-h5-dev.xiangshuheika.com/static/upload/202437a70d38d1d2e34a40a80b573f27268f57.png");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 8px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    &:nth-child(2n + 1) {
      margin-right: 7px;
    }
    .food-title-wrap {
      width: 101px;
      color: rgba(39, 49, 74, 1);
      font-size: 14px;
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      padding-left: 10px;

      .food-price {
        font-size: 24px;
        margin-right: 2px;
      }
      .food-text {
        font-size: 12px;
        font-weight: 400;
        color: #455368;
        line-height: 17px;
      }
    }
    .logo-wrap {
      margin-top: 5px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 54px;
      > img {
        width: 36px;
        height: 36px;
        margin-bottom: 1px;
      }
    }
  }
}
</style>
