<template>
  <section class="select-order">
    <section class="order-panel">
      <div class="order-panel-top">
        <div class="flex-l">最近订单</div>
        <div
          class="flex-r"
          v-ans="{ event_id: 'help_service_change_order_click1' }"
          @click="visible = true"
        >
          切换订单 <svg-icon class="icon-right" icon-class="arrow2" />
        </div>
      </div>
      <div
        class="order-item"
        v-ans="{
          event_id: 'help_service_order_click',
          order_number: order.order_number,
          category_type: order.category_type,
        }"
        @click="toService(order)"
      >
        <div class="order-header">
          <div>{{ order.t_created | formatDate("YYYY-MM-DD HH:mm:ss") }}</div>
          <div class="order-status">已成功</div>
        </div>
        <div class="order-content">
          <div class="flex-left ellipsis">
            <div class="order-name ellipsis">{{ order.name }}</div>
            <div class="order-number">订单编码：{{ order.order_number }}</div>
          </div>
        </div>
      </div>
    </section>
    <van-popup
      closeable
      position="bottom"
      round
      close-on-popstate
      @click-overlay="close"
      @close="close"
      @click-close-icon="close"
      :value="visible"
      class="order-popup-container"
      style="max-height: 90%"
      safe-area-inset-bottom
    >
      <div class="title">请选择订单</div>
      <ul class="order-list">
        <li
          :class="{
            'order-item': true,
            active: order.order_number === item.order_number,
          }"
          v-for="item in orders"
          @click="selectOrder(item)"
          :key="item.order_number"
        >
          <div class="order-header">
            <div>{{ item.t_created | formatDate("MM月DD日 HH:mm") }}</div>
            <div class="order-status">已成功</div>
          </div>
          <div class="order-content">
            <div class="flex-left ellipsis">
              <div class="order-name ellipsis">{{ item.name }}</div>
              <div class="order-number">订单编码：{{ item.order_number }}</div>
            </div>
            <div
              class="flex-right"
              v-ans="{
                event_id: 'help_service_select_order',
                order_number: item.order_number,
                category_type: item.category_type,
              }"
            >
              选择
            </div>
          </div>
        </li>
      </ul>
    </van-popup>
    <van-popup
      closeable
      :close-on-popstate="true"
      position="bottom"
      round
      @click-overlay="showServicePopup = false"
      @click-close-icon="showServicePopup = false"
      :value="showServicePopup"
      class="order-popup-container"
      safe-area-inset-bottom
    >
      <div class="title">当前订单如下</div>
      <div class="service-order">
        <div class="service-panel-top">
          <div class="flex-l">您当前咨询的是如下订单：</div>
          <div
            class="flex-r"
            @click="visible = true"
            v-ans="{ event_id: 'help_service_change_order_click2' }"
          >
            切换订单 <svg-icon class="icon-right" icon-class="arrow2" />
          </div>
        </div>
        <div class="order-item active" @click="toService(order)">
          <div class="order-header">
            <div>{{ order.t_created | formatDate("MM月DD日 HH:mm") }}</div>
            <div class="order-status">已成功</div>
          </div>
          <div class="order-content">
            <div class="flex-left ellipsis">
              <div class="order-name ellipsis">{{ order.name }}</div>
              <div class="order-number">订单编码：{{ order.order_number }}</div>
            </div>
            <div
              class="flex-right"
              v-ans="{ event_id: 'help_service_to_udesk' }"
            >
              选择
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </section>
</template>

<script>
import { defineComponent, onDeactivated, ref } from "@vue/composition-api";

export default defineComponent({
  model: {
    prop: "order",
    event: "change",
  },
  props: {
    orders: Array,
    order: Object,
    toService: Function,
  },
  setup({ order, toService }, { emit }) {
    const visible = ref(false);
    const showServicePopup = ref(false);
    const close = () => {
      visible.value = false;
      showServicePopup.value = false;
    };
    const selectOrder = (item) => {
      emit("change", item);
      close();
      sessionStorage.setItem("helpServiceOrder", item.order_number);
      // 跳转udesk
      setTimeout(() => {
        toService(item);
      }, 300);
    };

    onDeactivated(() => {
      close();
    });

    const showServiceVisible = () => {
      showServicePopup.value = true;
    };
    return {
      close,
      visible,
      selectOrder,
      showServiceVisible,
      showServicePopup,
    };
  },
});
</script>

<style lang="less" scoped>
.order-popup-container {
  background: #f4f5f7;
}
.order-panel {
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 12px;
  .order-item {
    .order-header {
      font-size: 14px;
    }
  }
}
.order-panel-top {
  display: flex;
  height: 51px;
  background: linear-gradient(90deg, #ff650d 0%, #ff5f4a 100%);
  padding: 0 16px;
  align-items: center;
  color: #fff;
  .flex-l {
    font-weight: bold;
    font-size: 18px;
  }
  .flex-r {
    margin-left: auto;
    font-size: 14px;
  }
}
.order-item {
  padding: 0 12px;
  box-sizing: border-box;
  border: 1px solid transparent;
  & + & {
    margin-top: 10px;
  }
  background: #fff;
  .order-header {
    display: flex;
    font-size: 12px;
    color: #999;
    line-height: 17px;
    height: 42px;
    align-items: center;
    border-bottom: 1px solid rgba(#f1f1f1, 0.7);
    .order-status {
      margin-left: auto;
    }
  }
  .order-content {
    margin-right: 8px;
    padding: 11px 0 17px;
    display: flex;
    .order-name {
      font-size: 16px;
      color: #333;
      line-height: 22px;
      font-weight: bold;
      margin-bottom: 4px;
      line-height: 22px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #ff622b;
        transform: translateY(-50%);
      }
    }
    .order-number {
      color: #888;
      font-size: 11px;
      line-height: 16px;
    }
  }
  .flex-left {
    text-indent: 15px;
  }

  .flex-right {
    margin-top: 11px;
    margin-left: auto;
    height: 23px;
    line-height: 23px;
    flex-basis: 64px;
    flex-shrink: 0;
    border-radius: 13px;
    text-align: center;
    color: #333;
    font-size: 11px;
    border: 1px solid #d2d2d2;
  }
  &.active {
    border-color: #ff5001;
    .flex-right {
      background: linear-gradient(90deg, #ff650d 0%, #ff5f4a 100%);
      color: #fff;
      border-color: transparent;
    }
  }
}

.order-popup-container {
  background: #f4f5f7;
  display: flex;
  flex-direction: column;

  .title {
    height: 52px;
    text-align: center;
    font-size: 15px;
    color: #000;
    font-weight: bold;
    line-height: 52px;
    background: #fff;
    flex-basis: 52px;
  }
  .order-list {
    padding: 10px 16px;
    flex: 1;
    overflow-y: auto;
  }
  .order-item {
    border-radius: 10px;
  }
}

.service-order {
  padding: 20px 16px;
  background: #fff;
  .service-panel-top {
    display: flex;
    line-height: 17px;
    justify-content: space-between;
    margin-bottom: 13px;

    color: #777777;
    .flex-r {
      color: #333;
    }
  }
}
</style>
