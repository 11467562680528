var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageshow)?_c('div',[_c('img',{staticClass:"apply-img",attrs:{"src":_vm.applyPic}}),_c('div',{class:_vm.partner_data.data && !_vm.partner_data.is_offer
        ? 'apply-height'
        : 'apply-height2'}),(_vm.partner_data.data && !_vm.partner_data.is_offer)?_c('div',{staticClass:"refund-apply-main"},[_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'refund_apply_button1',
        namespace: _vm.namespace,
        order_number: _vm.$route.query.order_number
      }),expression:"{\n        event_id: 'refund_apply_button1',\n        namespace,\n        order_number: $route.query.order_number\n      }"}],staticClass:"refund-apply-btn refund-apply-btn2",on:{"click":_vm.toForm}},[_vm._v(" 继续申请，永久注销VIP身份 > ")]),(['sy', 'xy'].includes(_vm.namespace))?_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'refund_apply_button2',
        namespace: _vm.namespace,
        order_number: _vm.$route.query.order_number
      }),expression:"{\n        event_id: 'refund_apply_button2',\n        namespace,\n        order_number: $route.query.order_number\n      }"}],staticClass:"get-benefit-btn",on:{"click":_vm.getBenefit}},[_vm._v(" 领取权益，并取消退款申请 ")]):_vm._e()]):_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
      event_id: 'refund_apply_button1',
      namespace: _vm.namespace,
      order_number: _vm.$route.query.order_number
    }),expression:"{\n      event_id: 'refund_apply_button1',\n      namespace,\n      order_number: $route.query.order_number\n    }"}],staticClass:"refund-apply-btn",on:{"click":_vm.toForm}},[_vm._v(" 继续申请，永久注销VIP身份 > ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }