<template>
  <div
    class="land-page"
    v-if="!loading"
    :style="`background: ${config.headerBgColor}`"
  >
    <div class="main">
      <van-image class="main-image" :src="config.headerImage"></van-image>
      <div class="main-form">
        <van-field
          maxlength="11"
          type="number"
          class="form-input"
          placeholder="请输入手机号码"
          v-model="phone_number"
        ></van-field>
        <div
          :style="{ backgroundImage: `url(${config.buttonBgImage})` }"
          @click="beforeSubmit"
          v-ans="{ event_id: 'promotion_landpage_submit1' }"
          class="main-btn resizing"
        >
          {{ config.buttonText }}
        </div>
        <div
          class="agreement"
          v-ans="{ event_id: 'promotion_landpage_checked' }"
        >
          <van-checkbox
            v-model="checked"
            icon-size="16px"
            class="agreement-checked"
          >
            我已阅读并同意
            <agreement-popup
              title="用户协议"
              agreementName="toc-service"
              v-ans="{ event_id: 'promotion_landpage_agreement_service1' }"
            >
              《用户协议》
            </agreement-popup>
            <agreement-popup
              title="会员服务协议"
              agreementName="toc-member"
              v-ans="{ event_id: 'promotion_landpage_agreement_member1' }"
            >
              《会员服务协议》
            </agreement-popup>
          </van-checkbox>
        </div>
      </div>
    </div>
    <div class="rule">
      <div
        class="rule-title"
        :style="{ backgroundImage: `url(${config.ruleBgImage})` }"
      ></div>
      <div class="rule-content" v-html="namespaceInfo.rule"></div>
    </div>
    <van-popup
      class="checked-confirm-popup"
      :value="showCheckedConfirm"
      @close="closeCheckedConfirm"
      @click-overlay="closeCheckedConfirm"
      @click-close-icon="closeCheckedConfirm"
      round
      get-container="body"
    >
      <div class="agreement-content">
        我同意协议
        <agreement-popup
          title="用户协议"
          agreementName="toc-service"
          v-ans="{ event_id: 'promotion_landpage_agreement_service2' }"
        >
        </agreement-popup>
        <agreement-popup
          title="会员服务协议"
          agreementName="toc-member"
          v-ans="{ event_id: 'promotion_landpage_agreement_member2' }"
        >
          《会员服务协议》 </agreement-popup
        >并开通会员
      </div>
      <div class="agreement-btn">
        <van-button
          v-ans="{ event_id: 'promotion_landpage_submit2' }"
          @click="submit"
          round
          block
          >同意协议</van-button
        >
      </div>
      <div class="dialog-close-icon" @click="closeCheckedConfirm"></div>
    </van-popup>
  </div>
</template>

<script>
import { getIndexCategory, signByPhone } from "@/apis/product";
import { mapState } from "vuex";
import AgreementPopup from "@/components/AgreementPopup.vue";
import { isValidPhone } from "@/helper/validate";
import { getInAlipayUrl } from "@/utils";
export default {
  components: { AgreementPopup },
  data() {
    return {
      loading: true,
      phone_number: "",
      config: {},
      checked: false,
      showCheckedConfirm: false,
    };
  },
  computed: {
    ...mapState(["namespaceInfo", "isWebchat", "isiOS"]),
  },
  async mounted() {
    const { namespace, source } = this.$route.query;

    if (!namespace || !source) {
      this.$toast("渠道信息不存在");
      return;
    }
    const res = await getIndexCategory({
      namespace,
      opened_page: "landpage",
      source,
    });
    const buttonCfg =
      res.data.find((item) => item.display_code === "button") || {};
    const headerCfg =
      res.data.find((item) => item.display_code === "header") || {};
    const ruleCfg = res.data.find((item) => item.display_code === "rule") || {};
    document.title = headerCfg.name;
    this.config = {
      headerBgColor: headerCfg.desc,
      headerImage: headerCfg.display_img_url,
      item_code: buttonCfg.desc,
      buttonBgImage: buttonCfg.display_img_url,
      ruleBgImage: ruleCfg.display_img_url,
      buttonText: buttonCfg.name,
    };
    this.loading = false;
  },
  methods: {
    closeCheckedConfirm() {
      this.showCheckedConfirm = false;
    },
    async beforeSubmit() {
      if (!this.phone_number) {
        this.$toast("请输入手机号码");
        return;
      }
      if (!isValidPhone(this.phone_number)) {
        this.$toast("请输入正确的手机号码");
        return;
      }
      if (!this.checked) {
        this.showCheckedConfirm = true;
        return;
      }
      this.submit();
    },
    async submit() {
      const { namespace, source } = this.$route.query;
      this.checked = true;
      const loading = this.$loading();
      try {
        const res = await signByPhone({
          phone_number: this.phone_number,
          namespace: namespace,
          item_code: this.config.item_code,
          source,
        });
        let url = res.data.sign_url;
        if (this.isWebchat) {
          // ios 内使用ulink打开支付宝app
          if (this.isiOS) {
            url = getInAlipayUrl(url);
          } else {
            // 微信内跳转到自动跳转页面，显示遮罩层
            url = `/redirect.html?to_url=${encodeURIComponent(
              url
            )}&title=权益领取&showWechatMask=true&namespace=${namespace}&source=${source}`;
          }
        }
        location.href = url;
      } finally {
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.land-page {
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 35px;
}
.main-image {
  min-height: 350px;
}
.main-btn {
  height: 48px;
  width: 250px;
  margin: 16px auto 10px;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  color: #ff0016;
  font-size: 20px;
  line-height: 48px;
  letter-spacing: 1px;
  font-weight: bold;
}

.form-input {
  border-radius: 9999px;
  padding: 12px 27px;
  font-size: 18px;
  /deep/ input {
    letter-spacing: 1px;
  }
}

.main-form {
  padding: 0 30px;
}
.agreement {
  text-align: center;
  display: flex;
  justify-content: center;
}
.agreement-checked {
  /deep/ .van-icon-success {
    border-color: #fff;
  }
  /deep/ .van-checkbox__icon--checked {
    .van-icon-success {
      border: none;
      background: url("~@/assets/images/keep_checked-white.svg") no-repeat
        center/100%;
      &::before {
        content: none;
      }
    }
  }
  /deep/ .van-checkbox__label {
    font-size: 10px;
    color: #fff;
  }
}

.rule {
  padding: 0 12px;
  margin-top: 46px;
}
.rule-title {
  height: 65px;
  background-repeat: no-repeat;
  background-size: 100%;
}
.rule-content {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  margin-top: -22px;
  z-index: 1;
  color: #666;
  line-height: 22px;
}

.checked-confirm-popup {
  box-sizing: border-box;
  padding: 34px 27px;
  width: 295px;
  text-align: center;
  line-height: 22px;
  color: #333;
  font-size: 16px;
  overflow: initial;
  top: 42%;
  .agreement-content {
    margin-bottom: 24px;

    span {
      color: #ff5e00;
    }
  }
  /deep/ .van-button {
    background: linear-gradient(to top, #ff341b 0%, #ff9c5e 100%);
    height: 49px;
    width: 145px;
    margin: 0 auto;
    color: #fff;
    font-size: 16px;
  }
}
</style>