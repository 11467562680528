<template>
  <div class="footer-wrap" :class="{ member: pageType === 'member' }">
    <template v-if="pageType !== 'member' && activeCard !== 'licheng6'">
      <div class="btn-wrap" @click="handleBuy">
        <div class="value">
          <div class="price">{{ count }}</div>
          <div class="unit">元/年</div>
        </div>
        <div class="text">立即开通</div>
      </div>
    </template>
    <template v-else>
      <div class="member-wrap">
        <div @click="jumpOrder">
          <img
            src="https://common-h5-dev.xiangshuheika.com/static/upload/2024375e76b32243b941f89e7017b3c991fbc0.png"
          />
          我的订单
        </div>
        <div @click="jumpCustomer">
          <img
            src="https://common-h5-dev.xiangshuheika.com/static/upload/202437772ff9449b8a4104982ff8e83a2a2e20.png"
          />
          专属客服
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ["pageType", "styleType", "activeCard", "packageInfo"],
  data() {
    return {
      radio: false,
      BENEFIT_HAS_PACKAGE: [],
      BENEFIT_NOT_HAS_PACKAGE: [],
    };
  },

  mounted() {
    this.initPackageValue();
  },

  computed: {
    count() {
      return this.styleType
        ? this.packageInfo.item_price
        : this.packageInfo.renew_price;
    },
  },

  methods: {
    handleBuy() {
      const productId = this.styleType
        ? this.BENEFIT_HAS_PACKAGE[this.activeCard]
        : this.BENEFIT_NOT_HAS_PACKAGE[this.activeCard];
      const url = `pages/mall/page/mallDetail/mallDetail?pageCode=20021&positionCode=1001&topCategoryName=package&productId=${productId}`;
      this.$jumpUrl(url);
    },
    jumpOrder() {
      this.$router.push("/order");
    },
    jumpCustomer() {
      this.$router.push("/service");
    },
    initPackageValue() {
      const packageValue = JSON.parse(
        this.$getConfigValue("LICHENG_PACKAGE_CONFIG")
      );
      this.BENEFIT_HAS_PACKAGE = packageValue["BENEFIT_HAS_PACKAGE"];
      this.BENEFIT_NOT_HAS_PACKAGE = packageValue["BENEFIT_NOT_HAS_PACKAGE"];
    },
  },
};
</script>

<style scoped lang="less">
.footer-wrap {
  background: #ffffff;
  padding: 20px 17.5px 14px 17.5px;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  bottom: 0px;
  z-index: 0;

  &.member {
    padding: 12px 0 16px 0;
  }

  .btn-wrap {
    background: url("https://common-h5-dev.xiangshuheika.com/static/upload/20243766e38b45f567424c95903648a0e70d51.png");
    background-size: 100%;
    background-repeat: no-repeat;
    width: auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 66px;
    padding-right: 43px;
    .value {
      font-family: PingFangSC, PingFang SC;
      display: flex;
      align-items: flex-end;
      .price {
        font-size: 24px;
        font-weight: 600;
        color: #f05525;
      }
      .unit {
        font-size: 10px;
        font-weight: 600;
        color: #ffffff;
        line-height: 20px;
        margin-left: 4px;
      }
    }
    .text {
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 20px;
    }
  }

  .radio {
    margin-top: 6px;
    /deep/ .van-radio__label {
      font-size: 10px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 11px;
      margin-left: 4px;
    }
    /deep/ .van-radio__icon {
      margin-bottom: 1px;
    }
  }

  .member-wrap {
    display: flex;
    justify-content: space-around;
    img {
      display: block;
      height: 30px;
      width: 30px;
      margin-left: 11px;
      margin-bottom: 8px;
    }
    > div {
      font-size: 13px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>
