<template>
  <div class="buffet-hotel">
    <div class="buffet-city" @click="$router.push('/buffet/city')">
      <van-search :value="buffet.city" shape="round" readonly right-icon="a" />
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="buffet-list-item" v-for="item in list" :key="item.name">
        <van-image
          class="buffet-list-img"
          fit="cover"
          @click="$router.push(`/buffet/detail?id=${item.id}`)"
          :src="item.image_list_info[0]"
          v-ans="{
            event_id: 'buffet_list_click',
            namespace,
            buffet_id: item.id,
          }"
        />
        <div class="buffet-item-name">{{ item.buffet_merchant_info.name }}</div>
        <div class="buffet-item-address">{{ item.address }}</div>
        <div class="buffet-item-footer">
          <div class="buffet-item-tag">{{ item.defaultTag }}</div>
          <div class="buffet-item-price">
            <small>¥</small>{{ item.lowest_price_info.member_price
            }}<span class="buffet-item-qi">起</span>
            <span class="buffet-item-facevalue"
              >¥{{ item.lowest_price_info.market_price }}</span
            >
          </div>

          <div
            class="buffet-item-btn"
            @click="$router.push(`/buffet/detail?id=${item.id}`)"
            v-ans="{
              event_id: 'buffet_list_click',
              namespace,
              buffet_id: item.id,
            }"
          >
            立即预订
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import Vue from "vue";

import { Search } from "vant";
import { mapState } from "vuex";
import { getBuffetStore } from "@/apis/buffet";
import { List } from "vant";

Vue.use(Search);
Vue.use(List);

export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
    };
  },
  computed: {
    ...mapState(["buffet", "namespace"]),
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData(page) {
      this.loading = true;
      try {
        this.finished = false;
        const res = await getBuffetStore({
          buffet_city_name: this.buffet.city,
          page: page || this.page,
        });
        this.list =
          this.page > 1 ? this.list.concat(res.data.data) : res.data.data;
        if (this.page >= res.data.total_pages) {
          this.finished = true;
        }
      } finally {
        this.loading = false;
      }
    },
    onLoad() {
      this.page++;
      this.fetchData(this.page);
    },
  },
};
</script>

<style lang="less" scoped>
.buffet-hotel {
  min-height: 100vh;
  background: #1d1616;
  box-sizing: border-box;
  padding: 12px;
}

.buffet-list-item {
  margin-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  font-size: 12px;
  .buffet-list-img {
    height: 170px;
    width: 100%;
    margin-bottom: 10px;
  }
  .buffet-item-name {
    color: #222;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px;
    padding: 0 10px;
  }
  .buffet-item-address {
    line-height: 17px;
    color: #333;
    text-indent: 12px;
    padding: 0 10px;
    margin-bottom: 17px;
    background: url("../../assets/images/address.svg") no-repeat;
    background-position: 6px -2px;
    background-size: 18px;
  }
  .buffet-item-footer {
    padding: 0 10px;
    display: flex;
    align-items: center;
    .buffet-item-tag {
      padding: 0 6px;
      margin-right: 13px;
      line-height: 22px;
      color: #f1ccb5;
      background: #222222;
      border-radius: 10px 0px 10px 0px;
    }
    .buffet-item-price {
      color: #ff5001;
      font-size: 24px;
      font-weight: 600;
      small {
        font-size: 0.5em;
      }
    }
    .buffet-item-qi {
      color: #999;
      margin-right: 8px;
      align-self: baseline;
      font-size: 12px;
      font-weight: normal;
    }
    .buffet-item-facevalue {
      color: #999;
      text-decoration: line-through;
      font-size: 12px;
      font-weight: normal;
    }
    .buffet-item-btn {
      background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
      border-radius: 17px;
      height: 34px;
      width: 92px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 34px;
      margin-left: auto;
    }
  }
}

.buffet-city {
  margin: -12px -12px 0;
  /deep/ .van-search {
    &::after {
      content: "";
      width: 10px;
      height: 6px;
      position: absolute;
      right: 24px;
      top: 18px;
      background: url("../../assets/images/down.png") no-repeat;
      background-size: 100%;
    }
    padding: 7px 12px;
    .van-search__content {
      background: transparent;
      border: 1px solid #999;
      .van-cell--borderless {
        padding: 2px 0;
      }
    }
  }
}
</style>