<template>
  <section class="select-city">
    <main class="search-fixed">
      <div class="search-city">
        <van-field
          class="input"
          v-model.trim="search"
          @input="searchCityHandle"
          left-icon="search"
          placeholder="搜索城市"
        />
      </div>
    </main>
    <!-- <div class="height50"></div> -->
    <div class="search-result" v-if="search">
      <div class="search-result-city">
        <template v-if="searchCity.length">
          <div class="search-result-title">搜索结果</div>
          <van-cell
            @click="setCity(item.name)"
            v-for="item in searchCity"
            :key="item.name"
            v-ans="{
              event_id: 'buffet_city_select_click',
              namespace,
              city_name: item.name,
            }"
            >{{ item.name }}</van-cell
          >
        </template>
        <div v-else class="search-result-empty">
          <van-image
            description=""
            :src="require('../../assets/images/hotel/no-city.png')"
          />
          <p>暂无相关城市</p>
          <p>可尝试其他关键词进行搜索</p>
        </div>
      </div>
    </div>
    <van-index-bar v-else :index-list="['热门', ...indexList]">
      <van-index-anchor index="热门">热门城市</van-index-anchor>
      <van-row class="hot-citys" :gutter="10">
        <van-col :span="8" v-for="city in hot_city_of_buffets" :key="city">
          <div 
            @click="setCity(city)" 
            class="hot-city-item"
            v-ans="{
              event_id: 'buffet_city_select_click',
              namespace,
              city_name: city,
            }"
          >
            {{ city }}
          </div>
        </van-col>
      </van-row>
      <template v-for="item in cityList">
        <van-index-anchor :key="item.groupName" :index="item.groupName">{{
          item.groupName
        }}</van-index-anchor>
        <van-cell
          v-for="city in item.list"
          :key="city.en_name"
          :title="city.name"
          v-ans="{
            event_id: 'buffet_city_select_click',
            namespace,
            city_name: city,
          }"
          @click="setCity(city.name)"
        />
      </template>
    </van-index-bar>
  </section>
</template>

<script>
import Vue from "vue";
import { IndexBar, IndexAnchor } from "vant";
import { mapMutations, mapState } from "vuex";
import { getBuffetCity } from "@/apis/buffet";
Vue.use(IndexBar);
Vue.use(IndexAnchor);

export default {
  data() {
    return {
      search: "",
      searchCity: [],
      hot_city_of_buffets: [],
      city_of_buffets_info: {},
    };
  },
  computed: {
    ...mapState(["hotel", "namespace"]),
    indexList() {
      let indexList = Object.values(this.city_of_buffets_info).map((r) => r[2]);
      return [...new Set(indexList)]; // 去重
    },
    citys() {
      return Object.entries(this.city_of_buffets_info).map(([k, v]) => ({
        name: k,
        index: v[2],
        en_name: v[0],
        code: v[1],
      }));
    },
    cityList() {
      const res = [];
      this.indexList.forEach((item) => {
        res.push({
          groupName: item,
          list: this.citys.filter((r) => r.index === item),
        });
      });
      return res;
    },
  },
  async mounted() {
    const res = await getBuffetCity();
    this.city_of_buffets_info = res.data.city_of_buffets_info;
    this.hot_city_of_buffets = res.data.hot_city_of_buffets;
  },
  methods: {
    ...mapMutations(["changeCity"]),
    searchCityHandle(val) {
      this.searchCity = this.citys.filter((item) => {
        return (
          item.name.includes(val) ||
          item.en_name.includes(val) ||
          item.code.includes(val)
        );
      });
    },
    setCity(city) {
      this.changeCity(city);
      this.$router.push("/buffet/list");
    },
  },
};
</script>

<style lang="less" scoped>
.hot-citys {
  padding: 16px;
  .hot-city-item {
    text-align: center;
    background: #f7f7f7;
    height: 34px;
    line-height: 34px;
    border-radius: 34px;
    margin-bottom: 10px;
  }
}
.van-index-bar__sidebar {
  .van-index-bar__index {
    line-height: 24px;
  }
}
.height50 {
  height: 50px;
}
.search-city {
  padding: 8px 15px;
  .input {
    background: #f5f5f5;
    line-height: 34px;
    padding: 0 10px;
    border-radius: 34px;
  }
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 10%);
}
.search-fixed {
  // position: fixed;
  // left: 0;
  // right: 0;
  // top: 0;
  background: #fff;
  z-index: 1000;
}
.select-city {
  background: #fff;
}

.search-result-title {
  font-size: 14px;
  padding: 15px;
  font-weight: bold;
}

.search-result-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
  .van-image {
    margin-top: -100px;
    width: 100px;
  }
  p {
    line-height: 20px;
    color: #666;
  }
}
.van-index-bar {
  padding-top: 10px;
}
</style>