<template>
  <div class="outside-wrap">
    <div class="head">
      <div class="title">{{ info.name }}</div>
      <div class="desc">{{ info.desc }}</div>
    </div>
    <div class="content">
      <div
        class="coupon"
        @click="draw(item)"
        v-for="item of info.productInfo"
        :key="item.id"
      >
        <div
          class="value"
          v-html="item.desc"
          :class="{ value1: item.desc === '天天领' }"
        ></div>
        <div class="foot">
          <span>{{ item.selling_points }}</span>
          <div class="btn">立即领取</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["info", "canUseBenefit", "handleDraw"],
  data() {
    return {
      productInfo: [],
    };
  },
  methods: {
    draw(item) {
      if (!this.canUseBenefit) return;
      this.handleDraw(item);
    },
  },
};
</script>

<style scoped lang="less">
.outside-wrap {
  height: 187.5px;
  background: #fef3ed;
  border-radius: 16px;
  margin-top: 12.5px;
  padding: 9px 12.5px 11px 12.5px;
  box-sizing: border-box;

  .head {
    display: flex;
    justify-content: space-between;
    font-family: PingFangSC, PingFang SC;

    .title {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
    }

    .desc {
      margin-top: 5px;
      font-size: 11px;
      font-weight: 400;
      color: #afb2b5;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    flex-wrap: wrap;
  }

  .coupon {
    border-radius: 8px;
    box-sizing: border-box;
    width: 157.5px;
    height: 60px;
    background: #ffffff;
    padding-left: 12.5px;
    position: relative;
    margin-bottom: 6px;

    .value {
      margin-top: 7.5px;
      font-size: 9px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #333333;
      /deep/ span {
        font-size: 20px;
      }
    }
    .value1 {
      font-size: 9px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-top: 17px;
    }

    .foot {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 10px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #afb2b5;

      .btn {
        padding: 1px 7px;
        border: 1px solid;
        color: rgba(230, 154, 123, 1);
        border-radius: 10px;
        margin-right: 6px;
      }
    }
  }
}
</style>
