<template>
  <component :is="showComponent" />
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api";

import { useRoute } from "@/components/use/base";

import ln from "./ln.vue";
import commonPage from "../commonPage";

export default defineComponent({
  components: {
    ln,
    commonPage,
  },
  setup() {
    const route = useRoute();

    const showComponent = computed(() => {
      const isLnplus = route?.query?.islnplus === "true";
      return isLnplus ? "commonPage" : "ln";
    });

    return {
      showComponent,
    };
  },
});
</script>

<style lang="less" scoped>
</style>