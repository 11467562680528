<template>
  <main>
    <div class="package-title">选择套餐</div>
    <div class="voucher-packages">
      <div
        @click="changeSelected(item)"
        v-for="item in packages"
        :key="item.type"
        class="voucher-packages-col"
      >
        <div
          :class="{
            'voucher-packages-item': true,
            'voucher-packages-item-active': item.type === selectedPackage.type,
          }"
        >
          <div class="packages-item-name ellipsis">{{ item.name }}</div>
          <div class="packages-item-price">
            <span class="packages-item-sub">¥</span
            >{{
              !item.isPaymentSigning
                ? type === "xf"
                  ? item.renew_price
                  : item.price
                : item.first_price
            }}
          </div>
          <div class="packages-item-cost">¥{{ item.face_value }}</div>
          <div v-if="getDiscount(item)" class="packages-item-discount">
            {{ getDiscount(item) }}折
          </div>
        </div>
      </div>
    </div>
    <div class="agreement-tips">
      <div class="period-tips">
        <div v-if="selectedPackage.isPaymentSigning">
          次月起按{{ selectedPackage.normal_price }}/月自动续费，可随时取消
        </div>
      </div>
      <div class="agreement">
        点击支付开通会员即视为同意<a
          class="agreement-text"
          href="../agreement/service_agreement.html"
          >《服务协议》</a
        >
      </div>
    </div>
  </main>
</template>

<script>
import { Decimal } from "decimal.js";
import { mapState } from "vuex";
import omit from "lodash.omit";
import { baseApi } from "@/config";

const zero = (num) => {
  return num < 10 ? "0" + num : num;
};
const same = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  if (arr1.sort().join() === arr2.sort().join()) {
    return true;
  }
  return false;
};

export default {
  inheritAttrs: false,
  props: ["packages", "selectedPackage", "type"],
  data() {
    return {
      VUE_APP_API_URL: baseApi.common,
      discountConfig: {},
      showCardType: "",
      selected: [],
    };
  },
  computed: {
    ...mapState(["namespace"]),
    coupons() {
      return new Decimal(this.selectedPackage.face_value || 0)
        .minus(this.selectedPackage.price || 0)
        .toNumber();
    },
  },
  async mounted() {},
  methods: {
    getDiscount(item) {
      const price = item.first_price || item.price;
      if (price === item.face_value) {
        return "";
      }
      const str = ((price / item.face_value) * 10).toFixed(2);
      return Number(str.slice(0, str.length - 1));
    },
    getCountdown() {
      const now = new Date().getTime();
      const last = new Date().setHours(23, 59, 59);
      const bt = Math.floor((last - now) / 1000);
      const hour = Math.floor(bt / 3600);
      const minute = Math.floor((bt - hour * 3600) / 60);
      const seconds = Math.floor(bt - hour * 3600 - minute * 60);
      return `${zero(hour)}:${zero(minute)}:${zero(seconds)}`;
    },

    changeSelected(item) {
      if (item.type === this.selectedPackage.type) {
        return;
      }
      this.selected = [];
      this.$emit(
        "update:selectedPackage",
        omit(item, ["bd_packages", "bd_price", "bd_face_price"])
      );
    },
    changeBdPackage(item) {
      let selected = this.selected;
      if (!selected.some((r) => r.item_code === item.item_code)) {
        selected = selected.concat(item);
      } else {
        selected = selected.filter((r) => r.item_code !== item.item_code);
      }
      this.selected = selected;
      const selectedPackage = this.selectedPackage;
      // 获取折扣信息
      let discount = 1;
      for (const k of this.discountConfig) {
        if (
          same(k.item_codes, [
            selectedPackage.item_code,
            ...selected.map((r) => r.item_code),
          ])
        ) {
          discount = k.discount;
          break;
        }
      }
      selectedPackage.bd_packages = [
        selectedPackage.item_code,
        ...selected.map((r) => r.item_code),
      ];
      selectedPackage.bd_price = (
        [selectedPackage.price, ...selected.map((r) => r.price)].reduce(
          (a, b) => (a += b),
          0
        ) * discount
      ).toFixed(2);
      selectedPackage.bd_face_price = [
        selectedPackage.face_value,
        ...selected.map((r) => r.face_value),
      ].reduce((a, b) => (a += b), 0);
      this.$emit("update:selectedPackage", selectedPackage);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.popup-container {
  margin-bottom: 20px;
  .popup-container-title {
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 18px;
    font-size: @font-md;
  }
}
.voucher {
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 32px;
  background: linear-gradient(360deg, #ffe1b6 0%, #ffe9cd 100%);
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: -4px;
    top: 12px;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 10;
    display: block;
  }
  &::after {
    content: "";
    position: absolute;
    right: -4px;
    top: 12px;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 10;
  }
  &-coupon {
    color: @red-color;
    font-weight: bold;
  }
  .voucher-card {
    background: url("../../assets/images/laba.png") no-repeat 5px center;
    background-size: 17px;
    padding-left: 24px;
    border-radius: 4px;
    padding-right: 4px;
  }
  .voucher-time {
    color: @text-gray2-color;
    margin-left: auto;
    margin-right: 5px;
  }
  .voucher-countdown {
    border-radius: 15px;
    border: 1px solid currentColor;
    color: @red-color;
    padding: 0 8px;
    height: 20px;
    line-height: 20px;
    margin-left: auto;
    margin-right: 10px;
  }
}
.voucher-packages {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.voucher-packages-item {
  padding: 12px 5px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #c6c8d0;
  position: relative;
  &.voucher-packages-item-active {
    background: #fdf4ee;
    border-color: #f9cdaf;
  }
  height: 98px;
  text-align: center;
}
.packages-item-name {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 1px;
  font-weight: bold;
}
.packages-item-price {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 2px;
  font-weight: bold;
  color: #ff5001;
}
.packages-item-sub {
  font-size: 16px;
}
.packages-item-cost {
  text-decoration: line-through;
  line-height: 18px;
  color: @text-gray2-color;
}
.packages-item-autofee {
  line-height: 17px;
  color: @text-gray2-color;
}
.voucher-packages-row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.voucher-packages-col {
  flex: 1;
  max-width: 50%;
  min-width: 30%;
  flex-shrink: 0;
  &:not(:last-child) {
    margin-right: 10px;
  }
}
.packages-item-discount {
  position: absolute;
  left: -1px;
  top: -1px;
  padding: 0 3px;
  color: #fff;
  background: @red-color;
  border-radius: 4px 0px 4px 0px;
  line-height: 14px;
  height: 14px;
  font-size: @font-xs;
  z-index: 1;
}
.bd-package {
  margin-bottom: 24px;
  &-title {
    line-height: 18px;
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 10px;
  }
  &-item + &-item {
    margin-top: 10px;
  }
  &-item {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 8px 12px;
    border: 1px solid #a1a3af;
    &.active {
      border-color: #ff7c27;
      .bd-package-checkbox {
        background: url("../../assets/images/package_checked.png") no-repeat;
        background-size: 100%;
        border: none;
      }
    }
  }
  &-name {
    line-height: 18px;
  }
  &-desc {
    line-height: 18px;
    font-weight: bold;
    font-size: 13px;
  }

  &-checkbox {
    margin-left: auto;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid #a1a3af;
    box-sizing: border-box;
  }
}
.agreement {
  margin-bottom: 15px;
  color: #999;
}
.agreement-tips {
  margin-top: 10px;
  color: #797e8c;
  line-height: 18px;
}

.agreement-text {
  color: #0091ff;
}
</style>