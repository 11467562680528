<template>
  <section class="card" v-if="list">
    <section class="tips">
      Tips：如您使用了会员卡中的任一权益，会员卡将不可再赠送好友
    </section>
    <van-empty
      v-if="!list.length"
      description="空空如也，快去购买会员卡吧～"
      style="margin-top: 90px"
      :image="require('../../assets/images/empty2.png')"
    />
    <section class="card-list" v-if="list.length">
      <section
        :class="{ 'card-item': true, disabled: !item.is_available }"
        v-for="item in list"
        :key="item.card_number"
      >
        <div class="card-name">{{ item.card_type_cn }}</div>
        <div class="card-expire">有效期至{{ item.expiry_time }}</div>
        <div class="card-footer" v-if="item.is_available">
          <div
            @click="toUsed(item)"
            v-if="item.canUsed"
            v-ans="{ event_id: 'page_my_usebenefit' }"
            :class="{ 'card-used-btn': true }"
          >
            使用权益
          </div>
          <div
            v-else
            @click="showUsedError"
            :class="{ 'card-used-btn': true, disabled: true }"
          >
            使用权益
          </div>
          <div
            v-if="item.give_status"
            @click="share(item)"
            v-ans="{ event_id: 'page_my_send' }"
            class="card-used-btn gift"
          >
            赠送好友
          </div>
          <div v-else @click="showError(item)" class="card-used-btn disabled">
            不可赠送
          </div>
        </div>
        <div class="card-status-text" v-else-if="item.unavailable_reason === 1">
          赠送中，请提醒好友在{{ getGiveTime(item) }}前领取
        </div>
        <div class="card-status-text" v-else-if="item.unavailable_reason === 2">
          好友{{ item.phone }}已于{{
            item.get_time | formatDate("YYYY-MM-DD HH:mm:ss")
          }}领取
        </div>
        <div class="card-status-text" v-else-if="item.unavailable_reason === 3">
          会员已过期
        </div>
      </section>
    </section>
    <van-overlay :show="show" @click="show = false">
      <div class="share-overlay">
        <van-image
          class="share-point"
          :src="require('../../assets/images/share_point.png')"
        />
        <div class="share-text">
          点击右上角转发给好友 请提醒好友在24小时内领取，过期自动退回
        </div>
      </div>
    </van-overlay>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import copy from "copy-to-clipboard";
import {
  getShareInfo,
  queryMultiCard,
  sendGiverCard,
  switchGiveCard,
} from "@/apis/home";
import * as dayjs from "dayjs";

export default {
  data() {
    return {
      show: false,
      list: null,
    };
  },
  async mounted() {
    // 查询多卡
    this.init();
  },
  computed: {
    ...mapState(["isWebchat", "namespace", "source"]),
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    ...mapMutations(["changeState"]),
    async init() {
      const loading = this.$loading();
      try {
        const res = await queryMultiCard();
        const list = res.data.map((item) => {
          // 可以赠送 并且没有被领取过
          // 不能继续使用
          item.canUsed = true;
          if (
            !item.is_available &&
            item.give_status === 1 &&
            item.unavailable_reason === 0
          ) {
            item.is_available = 1;
            item.canUsed = false;
          }
          return item;
        });
        this.list = list;
      } finally {
        loading.clear();
      }
    },
    getGiveTime(item) {
      return dayjs(item.give_time).add(1, "day").format("YYYY-MM-DD HH:mm:ss");
    },
    async toUsed(item) {
      // 使用权益 切换token后跳转到权益使用界面
      // 1切换token
      // 2查询用户信息
      // 3跳转
      if (!item.canUsed) {
        return;
      }
      const loading = this.$loading();
      try {
        const res = await switchGiveCard({ card_number: item.card_number });
        this.changeState({ token: res.data });
        await this.fetchUserInfo();
        this.$router.push("/pagj/member");
      } finally {
        loading.clear();
      }
    },
    async share(item) {
      this.$confirm({
        title: "温馨提示",
        message: "赠送后，请提醒好友在24小时内领取，过期自动退回",
        cancelButtonText: "取消",
        confirmButtonText: "赠送好友",
      }).then(async () => {
        await sendGiverCard({ card_number: item.card_number });
        const shareInfo = {
          to_url: `${location.origin}${location.pathname}#/pagj/receive?namespace=${this.namespace}&source=default&cardNumber=${item.card_number}`,
        };
        if (this.isWebchat) {
          const res = await getShareInfo({
            namespace: this.namespace,
            env: "wechat",
          });

          shareInfo.title = res.data.title;
          shareInfo.sub_title = res.data.sub_title;
          shareInfo.img_url = res.data.img_url;

          wx.updateAppMessageShareData({
            title: shareInfo.title, // 分享标题
            desc: shareInfo.sub_title, // 分享描述
            trigger: () => {
              this.show = false;
            },
            link:
              window.location.origin +
              `/redirect.html?to_url=${encodeURIComponent(shareInfo.to_url)}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: shareInfo.img_url, // 分享图标
          });
          this.show = true;
        } else {
          copy(shareInfo.to_url);
          this.$toast("分享地址已复制");
        }
        this.init();
      });
    },
    showError(item) {
      this.$alert({
        title: "温馨提示",
        message:
          item.card_type === 2
            ? "此美食卡是好友赠送的，不可再转赠哦"
            : "您已使用过此美食卡权益，不可赠送",
        confirmButtonText: "好的",
      });
    },
    showUsedError() {
      this.$alert({
        title: "温馨提示",
        message:
          "您的会员卡正在赠送中，暂不能使用权益，请提醒好友点击后24小时内领取，如过期未领取，会员卡将自动退回您的账户",
        confirmButtonText: "好的",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #fc6f1a;
  line-height: 24px;
  padding-left: 12px;
  background: #ffe9cd;
}

.card {
  min-height: calc(100vh - 50px);
  background: #f5f5f5;
}
.card-list {
  padding: 18px 15px 30px;
}

.card-item {
  & + .card-item {
    margin-top: 15px;
  }
  height: 144px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 12px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("../../assets/images/pagj_card_item_bg.png");
  &.disabled {
    background-image: url("../../assets/images/pagj_card_item_disabled_bg.png");
    .card-expire {
      color: #fff;
    }
  }
}

.card-name {
  line-height: 33px;
  color: #fff;
  font-size: 24px;
  margin-bottom: 5px;
}

.card-expire {
  line-height: 17px;
  color: #fdb78c;
}

.card-footer {
  margin-top: 37px;
  display: flex;
  height: 28px;
  justify-content: flex-end;
}

.card-used-btn {
  height: 28px;
  line-height: 28px;
  padding: 0 8px;
  border-radius: 28px;
  margin-left: 8px;
  color: #fc6f1a;
  background: #fff;
  font-size: 14px;
  &.gift,
  &.disabled {
    text-indent: 20px;
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 10px center;
  }
  &.gift {
    background-image: url("../../assets/images/pagj_gift.png");
  }
  &.disabled {
    color: #999;

    background-image: url("../../assets/images/question.png");
  }
}

.share-overlay {
  float: right;
}
.share-point {
  width: 56px;
  height: 62px;
  margin-left: auto;
}
.share-text {
  width: 202px;
  text-align: center;
  line-height: 25px;
  color: #fff;
  font-size: 18px;
  padding-right: 12px;
  opacity: 0.9;
}

.card-status-text {
  margin-top: 48px;
  line-height: 17px;
  color: rgba(#fff, 0.7);
}
</style>