/**
 * 校验身份证号码
 * @param {string} id 身份证号
 * @returns boolean
 */
export function isValidIdCard(id) {
  let regex = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  return regex.test(id);
}

/**
 * 校验手机号码
 * @param {string} phone 手机号
 * @returns boolean
 */
export function isValidPhone(phone) {
  let regex = /^1[3456789]\d{9}$/
  return regex.test(phone);
}