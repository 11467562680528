<template>
  <div class="ak-book" v-show="showPage">
    <div class="title">所在城市</div>
    <div
      class="select picker"
      v-ans="{
        event_id: 'appiontment_click_city',
        namespace,
      }"
      @click="showCityList = true"
    >
      {{ current_city_name }}
    </div>
    <div class="shop-list">
      <div
        :class="{ item: true, active: shop_current === index }"
        v-for="(item, index) in shop_list"
        :key="item.id"
        @click="selectShop(item.id, index)"
      >
        <div>
          <h4>{{ item.name }}</h4>
          <p>{{ item.address }}</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <div :class="{ 'date-box': true, animate: next === 1 }">
        <img
          class="close"
          @click="next = 0"
          src="../../assets/images/close.png"
          alt=""
        />
        <div class="title">预约体检时间</div>
        <p class="tips">*16点之前可预约明天的体检，16:00后仅可预订后天体检。</p>
        <div
          class="select calendar"
          @click="show = true"
          v-ans="{
            event_id: 'appiontment_click_date',
            namespace,
          }"
        >
          <div v-show="showDate">
            {{ dateFormat }}<span>{{ day }}</span>
          </div>
          <div v-show="!showDate"></div>
        </div>
      </div>

      <div class="btn-box">
        <div
          class="btn"
          @click="toConfirm"
          v-ans="{
            event_id: 'appiontment_click_next',
            namespace,
          }"
        >
          下一步
        </div>
      </div>
    </div>
    <van-calendar
      v-model="show"
      @confirm="onConfirm"
      :show-confirm="false"
      :show-mark="false"
      color="#ff5001"
      :formatter="formatDay"
      :min-date="minDate"
      :max-date="maxDate"
      :default-date="date"
    />
    <div class="index-city-list" v-show="showCityList">
      <van-index-bar :index-list="indexList" :sticky="false">
        <template v-for="item in cityList">
          <van-index-anchor :key="item.groupName" :index="item.groupName">{{
            item.groupName
          }}</van-index-anchor>
          <van-cell
            v-for="city in item.list"
            :key="city"
            :title="city"
            v-ans="{
              event_id: 'destination_click_city',
              destination_name: city,
            }"
            @click="setCity(city)"
          />
        </template>
      </van-index-bar>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import { akDate, akShop } from "@/apis";
import dayjs from "dayjs";
import { Calendar, IndexBar, IndexAnchor } from "vant";
Vue.use(Calendar);
Vue.use(IndexBar);
Vue.use(IndexAnchor);

export default {
  data() {
    return {
      showPage: false,
      current: -1,
      current_city_name: "",
      day: "07:30-10:30",
      date: new Date(),
      show: false,
      shop_list: [],
      shop_current: 0,
      showSelectCity: false,
      akgb: [],
      date_list: [],
      showDate: true,
      next: 0,
      showCityList: false,
      selectCity: "",
    };
  },
  computed: {
    ...mapState(["namespace"]),
    minDate() {
      const date = new Date();
      const hours = date.getHours();
      if (hours >= 16) {
        return new Date(dayjs().add(2, "day"));
      } else {
        return new Date(dayjs().add(1, "day"));
      }
    },
    maxDate() {
      if (this.date_list.length) {
        return new Date(
          this.date_list[this.date_list.length - 1].regdate.replace(/-/g, "/")
        );
      }
    },
    dateFormat() {
      return `${new Date(this.date).getMonth() + 1}月${new Date(
        this.date
      ).getDate()}日`;
    },
    indexList() {
      let indexList = Object.values(this.akgb).map((r) => r.first_word);
      return [...new Set(indexList)];
    },
    cityList() {
      const res = [];
      this.indexList.forEach((item) => {
        let arr = [];
        this.akgb
          .filter((r) => r.first_word === item)
          .forEach((i) => {
            !arr.includes(i.city) && arr.push(i.city);
          });
        res.push({
          groupName: item,
          list: arr,
        });
      });
      return res;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations(["changeState"]),
    async init() {
      const toast = this.$loading();
      //获取爱康国宾城市及门店
      const res = await akShop();
      let opts = {
        hospid: res.data[0].id,
      };
      //默认门店列表
      let default_city = this.selectCity ? this.selectCity : res.data[0].city;
      const city_shop_list = res.data.filter((item) => {
        if (item.city === default_city) {
          return item;
        }
      });
      this.shop_list = city_shop_list;
      opts.hospid = city_shop_list[0].id;
      //获取爱康国宾可预约日期
      const res2 = await akDate(opts);
      this.akgb = res.data;
      this.date_list = res2.data;
      this.current_city_name = this.selectCity
        ? this.selectCity
        : res.data[0].city;
      //获取第一天可预约时间
      res2.data.some((item) => {
        let date = new Date(item.regdate.replace(/-/g, "/"));
        if (date.getTime() - new Date().getTime() > 8 * 60 * 60 * 1000) {
          if (item.usedNum < item.maxNum) {
            this.date = date;
            this.showDate = true;
            return item;
          } else {
            this.showDate = false;
          }
        }
      });
      this.showPage = true;
      toast.clear();
    },
    onConfirm(value) {
      this.date = value;
      this.show = false;
    },
    formatDay(date) {
      let timestamp = new Date(date.date).getTime();
      let time_arr = [];
      this.date_list.forEach((item) => {
        const dt = new Date(item.regdate.replace(/-/g, "/")).getTime();
        time_arr.push(dt);
        if (item.usedNum >= item.maxNum && timestamp === dt) {
          date.type = "disabled";
          date.bottomInfo = "约满";
        }
      });
      if (!time_arr.includes(timestamp)) {
        date.type = "disabled";
        date.bottomInfo = "约满";
      }
      return date;
    },
    //切换门店
    async selectShop(id, index) {
      const toast = this.$loading();
      const res2 = await akDate({
        hospid: id,
      });
      this.shop_current = index;
      this.date_list = res2.data;
      res2.data.some((item) => {
        let date = new Date(item.regdate.replace(/-/g, "/"));
        if (date.getTime() - new Date().getTime() > 8 * 60 * 60 * 1000) {
          if (item.usedNum < item.maxNum) {
            this.date = date;
            this.showDate = true;
            return item;
          } else {
            this.showDate = false;
          }
        }
      });
      toast.clear();
    },
    toConfirm() {
      if (!this.showDate) {
        this.$toast("没有可预约日期");
        return;
      }
      if (this.next === 0) {
        this.next = 1;
        return;
      }
      this.$router.push(
        `/akConfirm?date=${encodeURIComponent(
          this.date
        )}&shop=${encodeURIComponent(
          this.shop_list[this.shop_current].name
        )}&shopId=${this.shop_list[this.shop_current].id}`
      );
    },
    toSelect() {
      this.$router.push(`/akSelectCity`);
    },
    setCity(city) {
      this.init();
      this.selectCity = city;
      this.showCityList = false;
    },
  },
};
</script>

<style lang="less" scoped>
.ak-book {
  padding-top: 1px;
  min-height: 100vh;
  background-color: #fff;
}
.title {
  font-size: 14px;
  color: #333;
  padding: 0 15px;
  margin-top: 15px;
}
.select {
  margin: 8px 15px 0;
  height: 50px;
  border: 1px solid #999;
  border-radius: 4px;
  font-size: 18px;
  color: #333;
  padding-left: 8px;
  font-weight: bold;
  line-height: 50px;
  span {
    padding-left: 10px;
  }
}
.picker {
  background: url("../../assets/images/down.png") no-repeat;
  background-position: right 8px center;
}
.calendar {
  background: url("../../assets/images/calendar.png") no-repeat;
  background-position: right 8px center;
  background-size: 16px;
}
.shop-list {
  margin: 15px;
  overflow-y: scroll;
  .item {
    height: 65px;
    background-color: #f5f5f5;
    border-radius: 5px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    flex-wrap: wrap;
    h4 {
      font-size: 14px;
      color: #333;
      margin: 0;
      font-weight: normal;
    }
    p {
      font-size: 12px;
      color: #999;
      margin-top: 4px;
    }
  }
  .active {
    background-color: #fff3da;
  }
}
.btn-box {
  position: relative;
  z-index: 2;
  background: #fff;
  padding: 1px 0 14px 0;
}
.btn {
  background-color: #1a1a1a;
  margin: 15px 25px 0;
  line-height: 50px;
  height: 50px;
  text-align: center;
  border-radius: 25px;
  font-weight: bold;
  font-size: 18px;
  color: #ffefd7;
}
.tips {
  padding: 4px 15px;
  color: #ff5001;
  font-size: 10px;
}
.footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 9;
  .close {
    position: absolute;
    right: 15px;
    top: 10px;
    width: 18px;
    height: 18px;
  }
}
.date-box {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 1px;
  transition: all linear 0.3s;
  background: #fff;
  box-shadow: 0 5px 5px 5px rgba(153, 153, 153, 0.5);
  border-radius: 6px 6px 0 0;
}
.animate {
  transform: translateY(-100%);
}
.index-city-list {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 99;
  overflow-y: scroll;
}
</style>