import request from "@/utils/hotelRequest"
import Vue from 'vue'
// 查询酒店项目基础信息
export const getHotelBaseInfo = (params) => {
  return request({
    url: '/api/hotel/get_index_info',
    method: 'get',
    params,
  })
}
// 查询酒店列表
export const getHotelList = (params) => {
  return request({
    url: '/api/hotels/hotels_filter',
    method: 'get',
    params,
  }).then(res => {
    if(Vue.prototype.$getConfigValue('HOTEL_SINGLE_BENEFIT')){
    res.data.hotel_list.forEach(item => {
        item.lowest_price = item.lowest_origin_price;
        delete item.lowest_origin_price;
      })
    }
    return res
  })
}
export const getHotelDetail = ({ id, ...params }) => {
  return request({
    url: `/api/hotels/${id}/details`,
    method: 'get',
    params,
  }).then(res => {
    if(Vue.prototype.$getConfigValue('HOTEL_SINGLE_BENEFIT')){
      res.data.rooms.forEach(room => {
        room.prices.forEach(price => {
          price.price = price.origin_price
          delete price.origin_price
        })
      })
    }
    return res
  })
}
export const getHotelBook = (params) => {
  return request({
    url: `/api/hotel/get_hotel_order_confirm`,
    method: 'get',
    params,
  })
}
export const getOrderNotice = (params) => {
  return request({
    url: `/api/hotel/get_order_notice`,
    method: 'get',
    params,
  })
}

export const getCurrentTime = (params) => {
  return request({
    url: `/current_sys_time`,
    method: 'get',
    params,
  })
}
export const getHotelPriceInfo = (params) => {
  return request({
    url: `/api/hotel/hotel_price_info`,
    method: 'get',
    params,
  })
}

export const getwxconfig = (data) => {
  return request({
    url: `/api/login/get_js_sdk_config`,
    method: 'post',
    data,
  })
}

export const getHotelPriceLevels = (params) => {
  return request({
    url: `/api/hotel/price_levels`,
    method: 'get',
    params,
  })
}

// 查询酒店提示
export const getHotelTips = (params) => {
  return request({
    url: `/api/hotel/get_hotel_tip`,
    method: 'get',
    params,
  })
}
