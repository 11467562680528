<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  created(){
    
  },
  methods: {
    
  }
}
</script>

<style>
  body,html{
    min-height: 100vh;
    background: #f8f8f8;
  }
  #app{
    height: 100%;
  }
</style>
