<template>
  <div class="yzf" v-if="pageshow">
    <div class="header">
      <img src="https://cdn.xiangshuheika.com/static/yzf/header.png" alt="">
      <div class="notice" @click="notice">
        <div class="left">
          <img class="icon" src="https://cdn.xiangshuheika.com/static/yzf/sound.png" alt="">
          限时活动，有机会享受更高额度
        </div>
        <img class="right" src="https://cdn.xiangshuheika.com/static/yzf/right.png" alt="">
      </div>
      <div class="rules" @click="showRule">规则</div>
    </div>
    <div class="main">
      <div class="box">
        <div class="title">热门视听会员月月免费领 <span>(多选1)</span></div>
        <div class="benefit-box1">
          <div class="amount">6次</div>
          <img src="https://cdn.xiangshuheika.com/static/yzf/icon1.png" alt="">
          <img src="https://cdn.xiangshuheika.com/static/yzf/icon2.png" alt="">
          <img src="https://cdn.xiangshuheika.com/static/yzf/icon3.png" alt="">
          <img src="https://cdn.xiangshuheika.com/static/yzf/icon4.png" alt="">
          <img src="https://cdn.xiangshuheika.com/static/yzf/icon5.png" alt="">
          <img src="https://cdn.xiangshuheika.com/static/yzf/icon6.png" alt="">
        </div>
      </div>
      <div class="box">
        <div class="title">每月吃喝玩乐优惠购 </div>
        <div class="benefit-box2">
          <div class="item">
            <div class="amount">6次</div>
            <img src="https://cdn.xiangshuheika.com/static/yzf/icon7.png" alt="">
          </div>
          <div class="item">
            <div class="amount">6次</div>
            <img src="https://cdn.xiangshuheika.com/static/yzf/icon8.png" alt="">
          </div>
          <div class="item">
            <div class="amount">3次</div>
            <img src="https://cdn.xiangshuheika.com/static/yzf/icon9.png" alt="">
          </div>
        </div>
      </div>
      <div class="box">
        <div class="title">每月10元享骑行会员 <span>(2选1)</span></div>
        <div class="benefit-box2 benefit-box3">
          <div class="item">
            <img src="https://cdn.xiangshuheika.com/static/yzf/icon10.png" alt="">
          </div>
          <div class="item">
            <div class="amount">6次</div>
            <img src="https://cdn.xiangshuheika.com/static/yzf/icon11.png" alt="">
          </div>
        </div>
      </div>
      <div class="box">
        <div class="title">79元起住全国经济型酒店  </div>
        <div class="ehotel">
          <div class="city" @click="$router.push('/hotel/list?benefitType=ehotel')"></div>
          <img src="https://cdn.xiangshuheika.com/static/yzf/card1.png" alt="">
        </div>
      </div>
      <div class="box">
        <div class="title">29.9元享全国高铁贵宾厅  </div>
        <div class="ehotel">
          <div class="city" @click="toViproom"></div>
          <img src="https://cdn.xiangshuheika.com/static/yzf/card2.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  onMounted,
  defineComponent,
  ref,
} from "@vue/composition-api";
import { getActivityInfo } from "@/apis";
import copy from "copy-to-clipboard";
import store from "@/store";
const vm = Vue.prototype;

export default defineComponent({
  setup() { 
    const pageshow = ref(false);
    const rules = ref('');

    onMounted(async () => { 
      if (store.state.userInfo.is_member) { 
        vm.$alert({
          message: '您的会员生效中，请点击复制链接并在手机浏览器中打开使用',
          confirmButtonText: "复制使用链接",
          hideClose: "true",
          beforeClose: (action, done) => { 
            const link = location.href.replace('/yzf', '/yzf/home');
            copy(link);
            vm.$toast('复制成功，请在手机浏览器中粘贴并访问即可进入链接使用权益')
            done(false);
          }
        })
        pageshow.value = true;
        return;
      }
      const res = await getActivityInfo({
        activity_name: `yzf`,
        hideMsg: true,
      });
      rules.value = res.data.rules;
      pageshow.value = true;
    })

    const notice = () => {
      vm.$alert({
        message: '同意购买会员后，更新额度以借款页展示结果为准',
        confirmButtonText: "好的",
        className: "notice-modal",
      });
    }
    const showRule = () => {
      vm.$alert({
        title: "活动规则",
        message: rules.value,
        confirmButtonText: "我知道了",
      });
    }
    const toViproom = () => { 
      location.href = vm.$getConfigValue("VIPROOM_LINK")
    }

    return {
      pageshow,
      rules,
      notice,
      showRule,
      toViproom,
    }
  }
})

</script>

<style lang='less' scoped>
.yzf {
  background-color: #FFEBD1;
}
.header {
  position: relative;
  img {
    width: 100%;
  }
  .notice {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(240, 102, 0, .2);
    height: 26px;
    display: flex;
    color: #AE4716;
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    .left {
      display: flex;
      align-items: center;
    }
    .icon {
      width: 16px;
      margin-right: 6px;
    }
    .right {
      width: 9px;
    }
  }
  .rules {
    position: absolute;
    width: 34px;
    text-align: center;
    background-color: rgba(255, 255, 255, .55);
    color: #333;
    right: 0;
    top: 34px;
    height: 20px;
    line-height: 20px;
    border-radius: 22px 0 0 22px;
    font-size: 12px;
  }
}
.box {
  background-color: #fff;
  border-radius: 10px;
  margin: 12px 12px 0;
  padding: 17px 10px 10px;
}
.main {
  position: relative;
  z-index: 2;
  background-color: #FFEBD1;
  border-radius: 10px 10px 0px 0px;
  margin-top: -18px;
  padding: 8px 0 20px;
}
.title {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #333;
  span {
    font-size: 12px;
  }
}
.benefit-box1 {
  position: relative;
  background-color: #FEF3E1;
  border-radius: 8px;
  margin-top: 18px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px;
  img {
    width: 36px;
  }
}
.amount {
  position: absolute;
  background-color: #FF5001;
  border-radius: 8px 8px 8px 0px;
  height: 15px;
  line-height: 15px;
  font-size: 10px;
  right: 0;
  top: -8px;
  padding: 0 4px;
  color: #fff;
}
.benefit-box2 {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  .item {
    width: 32%;
    position: relative;
    img {
      width: 100%;
    }
  }
}
.benefit-box3 {
  .item {
    width: 49%;
  }
}
.ehotel {
  margin-top: 17px;
  position: relative;
  img {
    width: 100%;
  }
  .city {
    width: 70px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 6px;
  }
}
</style>

<style lang="less">
.notice-modal {
  .van-dialog__message {
    padding-top: 50px;
    font-size: 14px;
  }
}

</style>
