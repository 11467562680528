
export default {
  state: {
    isShowTabbar: false
  },
  getters: {
    showTabbar(state, getters, rootState) {
      return state.isShowTabbar && rootState.isMember
    }
  },
  mutations: {
    changeBusinessState(state, payload) {
      for (let k in payload) {
        state[k] = payload[k];
      }
    }
  }
}