var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('section',{staticClass:"page-member"},[_c('section',{staticClass:"page-header"},[_c('div',{staticClass:"page-header-inner"},[_c('div',{staticClass:"page-header-flex"},[_c('div',{staticClass:"page-header-text"},[_vm._v(" "+_vm._s(_vm.userInfo.is_verified ? ("" + (_vm.userInfo.last_name_cn) + (_vm.userInfo.first_name_cn)) : "COCO PLUS")+" ")]),_c('div',{staticClass:"page-header-exprired"})]),_c('div',{staticClass:"page-header-park"},[_vm._v(" "+_vm._s(_vm.namespaceInfo.name_cn)+" ")])])]),_c('section',{staticClass:"page-benefit"},_vm._l((_vm.catesgorys),function(item){return _c('div',{key:item.name,class:("page-benefit-item page-benefit-item-" + (item.display_code))},[_c('div',{staticClass:"page-benefit-item-inner"},[_c('div',{staticClass:"benefit-item-title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"page-benefit-list-wrap",on:{"scroll":_vm.benefitScroll}},[_c('div',{staticClass:"page-benefit-list"},_vm._l((item.list),function(spu,idx){return _c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
                event_id: 'benefit_list_click',
                sequence: idx + 1,
                spu_id: spu.id,
                spu_name: spu.desc,
                namespace: _vm.namespace,
              }),expression:"{\n                event_id: 'benefit_list_click',\n                sequence: idx + 1,\n                spu_id: spu.id,\n                spu_name: spu.desc,\n                namespace,\n              }"}],key:spu.product_code,staticClass:"page-benefit-spu",on:{"click":function($event){return _vm.toDetail(spu)}}},[(item.display_code !== 'member_member_gift')?_c('div',{staticClass:"page-spu-inner",style:(item.display_code === 'member_travel'
                    ? ("background-image:url(" + (spu.display_img_url) + ")")
                    : '')},[(item.display_code !== 'member_travel')?_c('van-image',{staticClass:"page-benefit-image",attrs:{"lazy-load":"","fit":"cover","src":spu.display_img_url}}):_vm._e(),_c('div',{staticClass:"page-benefit-name"},[_vm._v(_vm._s(spu.desc))]),(
                    item.display_code === 'member_travel' ||
                    item.display_code === 'member_cocopark'
                  )?_c('div',{staticClass:"page-benefit-travel-selling"},[_vm._v(" "+_vm._s(spu.selling_points)+" ")]):_vm._e(),(item.display_code === 'member_travel')?_c('div',{class:{
                    'page-benefit-use': true,
                    'page-benefit-use-amount':
                      spu.benefit && spu.benefit.amount_available > 0,
                    disabled: _vm.getDisabled(spu.benefit),
                  }},[_c('div',{staticClass:"page-benefit-text"},[_vm._v(" "+_vm._s(_vm.getBenefitText(spu.benefit))+" "),(spu.benefit && spu.benefit.amount_available > 0)?_c('span',{staticClass:"page-benefit-amount"},[_vm._v(" X"+_vm._s(spu.benefit.amount_available + spu.benefit.amount_reserved)+" ")]):_vm._e()])]):_vm._e(),(item.display_code === 'member_life')?_c('div',{staticClass:"page-benefit-selling"},[_vm._v(" "+_vm._s(spu.selling_points)+" ")]):_vm._e()],1):_c('div',{staticClass:"benefit-gift"},[_c('van-image',{staticClass:"benefit-gift-image",attrs:{"src":spu.display_img_url}})],1)])}),0)]),(item.display_code === 'member_cocopark')?_c('div',{staticClass:"swiper"},[_c('div',{staticClass:"swiper-inner",style:(("left:" + _vm.left + "%"))})]):_vm._e()])])}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }