<template>
  <div class="floor">
    <div class="title-wrap">
      <div class="title">
        <slot name="title" />
        <span><slot name="subTitle" /></span>
      </div>
      <div class="tip"><slot name="tip" /></div>
    </div>
    <slot name="main" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.floor {
  margin-top: 24px;
  padding: 0 16px;
  .title-wrap {
    display: flex;
    justify-content: space-between;
    font-family: PingFang-SC, PingFang-SC;
    .title {
      height: 22px;
      font-size: 16px;
      font-weight: bold;
      color: #27314a;
      line-height: 22px;
      display: flex;
      align-items: center;
      > span {
        margin-left: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #455368;
        line-height: 20px;
      }
    }
    .tip {
      background: #fff5e8;
      border-radius: 10px;
      line-height: 20px;
      color: rgba(69, 83, 104, 1);
      padding: 1px 12px 0px 12px;
    }
  }
}
</style>
