<template>
  <FloorWrap :title="benefit.name" :desc="benefit.desc">
    <template slot="content">
      <img
        :src="benefit.display_img_url"
        class="img"
        @click="handleDraw"
        v-ans="{ event_id: 'unxp_video_click' }"
      />
    </template>
  </FloorWrap>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import Vue from "vue";

import { useRouter } from "@/components/use/base";
import { queryUserBenefitUse } from "@/apis/home";

import FloorWrap from "./FloorWrap.vue";

const vm = Vue.prototype;

export default defineComponent({
  props: ["benefit"],

  components: {
    FloorWrap,
  },
  setup({ benefit }) {
    const router = useRouter();

    const handleDraw = async () => {
      const res = await queryUserBenefitUse();
      if (res.data === "used") {
        vm.$alert({
          message: "每天仅可使用1项权益，你今天已用过，请明天再来",
          confirmButtonText: "好的",
        });
        return;
      }
      router.push(
        `/benefitDetail?benefit_type=${benefit.list[0].product_code}`
      );
    };

    return {
      handleDraw,
    };
  },
});
</script>


<style lang="less" scoped>
.img {
  margin-top: 13px;
  width: 100%;
  height: 160px;
  border: none;
}
</style>