import { render, staticRenderFns } from "./memberCenter.vue?vue&type=template&id=051f0490&scoped=true&"
import script from "./memberCenter.vue?vue&type=script&lang=js&"
export * from "./memberCenter.vue?vue&type=script&lang=js&"
import style0 from "./memberCenter.vue?vue&type=style&index=0&id=051f0490&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "051f0490",
  null
  
)

export default component.exports