import Vue from 'vue';

//易观数据appkey
export const qt_key = process.env.VUE_APP_RUNTIME_ENV === 'production' ? '5sqe7x1ekhnzx0oko7puertt' : 'qbx7sqcd7a7pncj5kn2oxkew';

//省钱卡易观数据appkey
export const xsoak_qt_key = process.env.VUE_APP_RUNTIME_ENV === 'production' ? 'y7jmgkovkzjs0ak41jgwcjmn' : 'yyzq7ehaibv5baciq0axkge4';

// commonH5
export const commonH5 = process.env.VUE_APP_RUNTIME_ENV === 'production' ? 'https://common-h5-prod.xiangshuheika.com/common/#' : 'https://common-h5-dev.xiangshuheika.com/common/#';

export const yioil_plat = 'c42F1';

export const yioil_app_key = 'rNjrQSDW3X2MZZ3D';

export const yioil_app_secret = 'xREshiiKHbyEFCo3';

export const yioil_ejiayou_link = 'https://api.ejiayou.com/pages/platform/soulList/index.html';

export const CDN = 'https://cdn.xiangshuheika.com/static/common/';

// 极验 appid
export const geetest_appid = '673f902910958944aa3926ef44c3fbb1';

//接口域名地址
export const baseApi = {
  xy: process.env.VUE_APP_RUNTIME_ENV === 'production' ? 'https://xy-api.xiangshuheika.com' : 'https://api-stag.xiangshuheika.com',
  common: process.env.VUE_APP_RUNTIME_ENV === 'production' ? "https://api.xiangshuheika.com" : "https://api-stag.xiangshuheika.com",
  life: process.env.VUE_APP_RUNTIME_ENV === 'production' ? "https://life-api.xiangshuheika.com" : "https://api-stag-xs.xiangshuheika.com",
  lt: process.env.VUE_APP_RUNTIME_ENV === 'production' ? "https://dpplus-api.xiangshuheika.com" : "https://api-stag-dpplus.xiangshuheika.com",
}
export const dockApi = {
  xy: process.env.VUE_APP_RUNTIME_ENV === 'production' ? 'http://oakvip-common-svc.xiaoying' : 'http://oakvip-common-svc.default',
  common: 'http://oakvip-common-svc.default',
  life: process.env.VUE_APP_RUNTIME_ENV === 'production' ? 'http://oakvip-core-svc' : 'http://oakvip-activity-svc',
}
export const pay_api = `${baseApi.common}/api/core/payv2.html`;


//阿里云api网关使用的key，secret
export const key = process.env.VUE_APP_RUNTIME_ENV === 'production' ? "4c6d435d576434993f35771344eeb7ed" : '0632a25280c2b714de6482b8bc33114e';

export const adminUrl = process.env.VUE_APP_RUNTIME_ENV === 'production' ? "https://admin.xiangshuheika.com" : 'https://admin-dev.xiangshuheika.com';

export const secret = process.env.VUE_APP_RUNTIME_ENV === 'production' ? "123745d4a1b1252fb1686e8c2abcc466a4c9f156c31c2bf9135b684df93d8b90b62680b9cbf9f4bbe8e5b3fa9b7beb04" : '48daeaf3482bbee5581794e31532adee664d44e0c7a779aa136322b4568d96421fef738158a150cce69df71db61dcdfd';

export const getDockUrl = (namespace) => {
  if (namespace.includes('_life')) {
    return dockApi.life;
  }
  if (namespace !== 'lt' && namespace !== 'xy') {
    return dockApi.common;
  }
  return dockApi[namespace]
}
export const getBaseUrl = (namespace) => {
  // 小赢域名
  if (location.origin.includes('xy-h5.xiangshuheika.com')) {
    return baseApi.xy;
  }
  if (namespace.includes('_life')) {
    return baseApi.life;
  }
  if (namespace !== 'lt' && namespace !== 'xy') {
    return baseApi.common;
  }
  return baseApi[namespace] || baseApi.common
}

export const envflag = process.env.VUE_APP_RUNTIME_ENV === 'production' ? 'oakprod' : 'oaktest'
export const xy_envflag = process.env.VUE_APP_RUNTIME_ENV === 'production' ? 'xyprod' : 'xytest'

export const xsoak_domain = process.env.VUE_APP_RUNTIME_ENV === 'production' ? 'https://xsoak-h5.xiangshuheika.com/xsoak/#' : 'https://xsoak-h5-dev.xiangshuheika.com/xsoak/#'

export const ENV_FLAG = namespace => {
  return namespace.startsWith('xy') ? xy_envflag : envflag
}


const agentIdList = () => ({
  common: Vue.prototype.$getConfigValue('COMMON_AGENT_ID') + "",
  fs: "20019",
})

//主题色
export const theme_color = {
  'cr': { background: 'linear-gradient(270deg, #EDCBA9 0%, #FBEAD6 100%)', text_color: '#1D1A1B' },
  'wl': { background: 'linear-gradient(90deg,#FFC984 0%,#FEEC9D 100%)', text_color: '#802727' },
  'xg': { background: 'linear-gradient(270deg,rgba(255,218,164,1) 0%,rgba(252,193,113,1) 100%)', text_color: '#925915' },
  'za': { background: 'linear-gradient(90deg,rgba(255,218,164,1) 0%,rgba(252,193,113,1) 100%)', text_color: '#7E5C37' },
  'ln': { background: 'linear-gradient(90deg, #FFC360 0%, #FFEBA6 100%)', text_color: '#853119' },
  'sy': { background: 'linear-gradient(90deg, #FFC360 0%, #FFEBA6 100%)', text_color: '#853119' },
  'yh1': { background: 'linear-gradient(90deg, #FFE0C1 0%, #FABA7A 100%)', text_color: '#853119' },
  'yh2': { background: 'linear-gradient(90deg, #FFE0C1 0%, #FABA7A 100%)', text_color: '#853119' },
  'yh3': { background: 'linear-gradient(90deg, #FFE0C1 0%, #FABA7A 100%)', text_color: '#853119' },
  'yh4': { background: 'linear-gradient(90deg, #FFE0C1 0%, #FABA7A 100%)', text_color: '#853119' },
  'yh5': { background: 'linear-gradient(90deg, #FFE0C1 0%, #FABA7A 100%)', text_color: '#853119' },
  'yh6': { background: 'linear-gradient(90deg, #FFE0C1 0%, #FABA7A 100%)', text_color: '#853119' },
  'yh7': { background: 'linear-gradient(90deg, #FFE0C1 0%, #FABA7A 100%)', text_color: '#853119' },
  'yh8': { background: 'linear-gradient(90deg, #FFE0C1 0%, #FABA7A 100%)', text_color: '#853119' },
  'gffunds1': { background: 'linear-gradient(90deg, #FFE0C1 0%, #FABA7A 100%)', text_color: '#853119' },
  'gffunds2': { background: 'linear-gradient(90deg, #FFE0C1 0%, #FABA7A 100%)', text_color: '#853119' },
  'gffunds3': { background: 'linear-gradient(90deg, #FFE0C1 0%, #FABA7A 100%)', text_color: '#853119' },
  'gffunds4': { background: 'linear-gradient(90deg, #FFE0C1 0%, #FABA7A 100%)', text_color: '#853119' },
  'chongtian': { background: 'linear-gradient(90deg, #E3B378 0%, #D79663 100%)', text_color: '#FFF3DA' },
  'pingan_gj': { background: '#FC6F1A', text_color: '#fff' },
  'keep': { background: '#ff5001', text_color: '#fff' },
  'xiaopai': { background: 'linear-gradient(360deg, #FF3922 0%, #FA6400 100%)', text_color: '#fff' },
  'unxp': { background: 'linear-gradient(360deg, #FF3922 0%, #FA6400 100%)', text_color: '#fff' },
  'leiniao': { background: 'linear-gradient(360deg, #FF3922 0%, #FA6400 100%)', text_color: '#fff' },
}

export const agentConfig = (key) => {
  const agentIdListObj = agentIdList();
  for (let k in agentIdListObj) {
    if (key.includes(k)) {
      return agentIdListObj[k];
    }
  }
  return agentIdListObj.common;
}

export const IS_PROD = process.env.VUE_APP_RUNTIME_ENV === "production" ? "T" : "F"

export const viproom_list = ['viproom', 'railviproom', 'viproom_combo', 'BDVIP-9', 'airportviproom', 'BDviproom', 'bdvip-9', 'bdviproom']

// 默认客服地址
export const defaultUdesk = 'https://oakvip.s2.udesk.cn/im_client/?web_plugin_id=28870';
