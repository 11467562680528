import request from '@/utils/request';

//获取赚钱返现外卖订单
export const getCashbackOrders = (params) => {
  return request({
    url: '/api/cashback/order_list',
    method: 'get',
    params,
  })
}

//查询提现记录
export const getWithdrawalsRecord = (params) => {
  return request({
    url: '/api/cashback/withdrawals_record',
    method: 'get',
    params,
  })
}

//获取总返现，待返现，待提现金额
export const getWithdrawalInfo = (params) => {
  return request({
    url: '/api/cashback/withdrawal_info',
    method: 'get',
    params,
  })
}

//提现接口
export const cashWithdrawal = (data) => {
  return request({
    url: '/api/cashback/cash_withdrawal',
    method: 'post',
    data,
  })
}
