<template>
  <section class="kd100" v-if="!fetching">
    <section class="header">
      <div class="header-name">
        {{ $getConfigValue("INDEX_TITLE") }}
      </div>
      <div class="header-subtext">
        {{
          $getConfigValue("SHOW_REMAIN_DAYS")
            | formatText({
              remain_days: card.current_package_remain_days,
              expiry_date: card.card_expiry_date,
            })
        }}
      </div>
      <van-button
        v-ans="{
          event_id: 'click_xufee',
        }"
        class="renew"
        type="info"
        size="small"
        round
        @click="toRenew"
        >立即续费</van-button
      >
    </section>
    <section class="benefit" v-if="card.card_status === 'active'">
      <div class="page-benefits">
        <div
          :key="item.display_code"
          v-for="item in catesgorysList"
          :class="`page-benefit page-benefit-${item.display_code}`"
        >
          <div class="page-benefit-item">
            <div class="page-benefit-name">
              {{ item.name }}
            </div>
            <div class="page-benefit-desc">
              剩{{
                item.list[0].benefit.amount +
                item.list[0].benefit.amount_reserved
              }}次
            </div>
          </div>
          <div class="page-benefit-list">
            <div
              v-for="spu in item.list"
              :key="spu.id"
              class="page-benefit-spu"
              :style="`background-image:url(${spu.display_img_url})`"
            >
              <div
                class="page-benefit-spu-inner"
                v-if="item.display_code !== 'benefit03'"
              >
                <div class="page-benefit-spu-info">
                  <div class="page-benefit-spu-desc">
                    {{ spu.desc }}
                  </div>
                  <div class="page-benefit-spu-sell">
                    {{ spu.selling_points }}
                  </div>
                </div>
                <van-button
                  class="page-benefit-spu-btn"
                  type="info"
                  size="small"
                  v-ans="{
                    event_id: 'benefit_list_click',
                    spu_id: spu.id,
                    spu_name: spu.desc,
                  }"
                  @click="jump(spu)"
                  round
                  >{{ getBenefitText(spu.benefit) }}</van-button
                >
              </div>

              <div
                class="fill"
                :style="`background-image:url(${spu.display_img_url})`"
                v-else
                v-ans="{
                  event_id: 'benefit_list_click',
                  spu_id: spu.id,
                  spu_name: spu.desc,
                }"
                @click="jump(spu)"
              />
              <div
                class="next-use"
                v-if="
                  item.display_code === 'benefit02' &&
                  spu.benefit &&
                  spu.benefit.get_start_time &&
                  spu.benefit.get_end_time &&
                  spu.benefit.amount <= 0 &&
                  spu.benefit.amount_reserved > 0
                "
              >
                {{
                  nextDay(spu.benefit.get_start_time, spu.benefit.get_end_time)
                }}
                可用下一次
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-else-if="card.card_status === 'expired'" class="card-past">
      <van-image :src="require('../../../assets/images/yh-empty.png')" />
      <p>您的权益已过期</p>
    </div>
    <div v-else-if="card.card_status === 'refund'" class="card-past">
      <van-image :src="require('../../../assets/images/yh-empty.png')" />
      <p>您的权益已退款</p>
    </div>
    <section class="page-footer">
      <router-link class="order" to="/order">
        <van-image
          :src="require('../../../assets/images/kd100_member_order_icon.png')"
        />
        我的订单
      </router-link>
      <router-link class="service" to="/service">
        <van-image
          :src="require('../../../assets/images/kd100_member_service_icon.png')"
        />
        在线客服</router-link
      >
    </section>
  </section>
</template>

<script>
import { getPackageList, getPaymethod, queryH5JumpUrl } from "@/apis/product";
import { useRouter, useStore } from "@/components/use/base";
import { defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import Vue from "vue";
import dayjs from "dayjs";
import { toPayV2 } from "@/helper/pay";
import useMemberBenefitCategory from "@/use/useMemberBenefitCategory";
import copy from "copy-to-clipboard";

const vm = Vue.prototype;

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const fetching = ref(true);
    const catesgorysList = ref([]);
    const { card, catesgorys, getProductList } = useMemberBenefitCategory();

    const jump = async (item) => {
      if (item.jump_method !== "h5" && !item.jump_url) {
        router.push(`/benefitDetail?benefit_type=${item.benefit.type}`);
        return;
      }
      copy(item.jump_url);
      vm.$toast("使用链接已复制，请打开外部浏览器使用");
    };

    const nextDay = (start, end) => {
      if (dayjs().isBefore(dayjs(start).startOf("day"))) {
        return dayjs(start).format("YYYY.MM.DD");
      }
      return dayjs(end).add(1, "day").format("YYYY.MM.DD");
    };

    const toRenew = async () => {
      const days = dayjs(card.value.card_expiry_date.replace(/\./g, "-")).diff(
        dayjs(),
        "day"
      );
      if (days > 90) {
        vm.$toast("您的会员有效期大于90天，暂不可续费");
        return;
      }
      const loading = vm.$loading();
      try {
        await toBuy();
      } finally {
        loading.clear();
      }
    };

    const toBuy = async () => {
      const [methods, res] = await Promise.all([
        getPaymethod({
          namespace: store.state.namespace,
          product_type: store.state.namespace.toUpperCase(),
          source: "default",
        }),
        getPackageList({
          namespace: store.state.namespace,
          source: store.state.source,
        }),
      ]);
      const payMethod = methods.length ? methods[0] : {};
      const memberPackage = res.data.length ? res.data[0] : {};
      toPayV2(memberPackage, payMethod.channel);
    };

    const getBenefitText = (benefit) => {
      if (benefit.type.startsWith("kaidivip")) {
        return "查看";
      }
      if (benefit.amount > 0) {
        return "去使用";
      } else {
        if (benefit.amount_reserved > 0) {
          return "已使用";
        }
        return "已领完";
      }
    };

    // 为绕过ios小程序安全策略,点击按钮请求的数据无法复制
    const handleSpuList = (arr = []) => {
      return arr.map(async (spu) => {
        let jump_url;
        if (spu.jump_method === "h5") {
          const { data } = await queryH5JumpUrl({
            phone_number: store.state.userInfo.user_phone,
            namespace: store.state.userInfo.namespace,
            spu_id: spu.id,
            user_id: store.state.userInfo.id,
            generate_h5_code: spu.generate_h5,
          });
          jump_url = data;
        }
        return { ...spu, jump_url };
      });
    };

    watch(
      catesgorys,
      async () => {
        catesgorysList.value = await Promise.all(
          catesgorys.value.map(async (item) => {
            const list = await Promise.all(handleSpuList(item.list));
            return { ...item, list };
          })
        );
      },
      { deep: true }
    );

    onMounted(async () => {
      const loading = vm.$loading();
      try {
        await getProductList();
      } finally {
        fetching.value = false;
        loading.clear();
      }
    });
    return {
      catesgorysList,
      catesgorys,
      fetching,
      toBuy,
      jump,
      card,
      toRenew,
      nextDay,
      getBenefitText,
    };
  },
});
</script>


<style lang="less" scoped>
.kd100 {
  background: #f5f6f8;
  padding-bottom: 24px;
}
.header {
  height: 150px;
  background: url("../../../assets/images/kd100_member_header_bg.png") no-repeat
    center/100%;
  position: relative;
  box-sizing: border-box;
  padding: 36px 24px 0;
  display: flex;
  flex-direction: column;
}

.header-name {
  line-height: 25px;
  color: #39322a;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
}
.header-subtext {
  line-height: 17px;
  color: #999;
}

.renew {
  margin-top: 16px;
  width: 84px;
  align-self: flex-end;
  color: #ffe5c6;
  background: linear-gradient(109deg, #5e544a 0%, #302a24 100%);
  line-height: 24px;
  height: 24px;
  font-size: 12px;
}
.rule-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 41px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #26211c;
  color: #fef5ed;
  border-radius: 24px 0 0 24px;
}

.benefit {
  position: relative;
  z-index: 10;
}

.page-benefits {
  padding: 0 12px;
}

.page-benefit {
  margin-bottom: 16px;
  padding: 12px;
}
.page-benefit-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
}

.page-benefit-benefit03 {
  .page-benefit-list {
    display: flex;
    flex-wrap: wrap;
  }
  .page-benefit-desc {
    display: none;
  }
}

.page-benefit-benefit01,
.page-benefit-benefit02 {
  background: #fff;
  border-radius: 8px;
  .page-benefit-spu-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
    height: 80px;
  }
  .page-benefit-spu-btn {
    width: 60px;
    height: 24px;
    line-height: 24px;
    background: linear-gradient(109deg, #5e544a 0%, #302a24 100%);
    color: #ffe5c6;
    font-size: 12px;
  }
  .page-benefit-spu-desc {
    line-height: 17px;
    color: #42352c;
    font-weight: bold;
  }
  .page-benefit-spu-sell {
    line-height: 17px;
    color: #999;
  }
}

.page-benefit-benefit01 {
  .page-benefit-desc {
    display: none;
  }
  .page-benefit-spu {
    height: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 70px;
  }
}
.page-benefit-benefit02 {
  .page-benefit-spu {
    height: 133px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .page-benefit-spu-inner {
    margin-left: 36px;
    height: 60px;
  }
}

.page-benefit-benefit03 {
  .page-benefit-list {
    margin: 0 -4px;
  }
  .page-benefit-spu {
    flex-basis: 33.333333%;
    height: 90px;
    margin-top: 8px;

    background: none !important;
    padding: 0 4px;
    box-sizing: border-box;
  }
  .page-benefit-list {
    margin-top: -8px;
  }
  .fill {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    border-radius: 8px;
  }
}

.page-benefit-name {
  line-height: 22px;
  margin-right: 8px;
  font-size: 16px;
  color: #42352c;
  font-weight: bold;
}

.page-benefit-desc {
  line-height: 20px;
  height: 20px;
  box-sizing: border-box;
  color: #df9440;
  border: 1px solid currentColor;
  border-radius: 20px;
  padding: 0 10px;
}

.next-use {
  text-align: center;
  color: #999;
  margin-top: 53px;
}

.page-footer {
  display: flex;
  height: 56px;
  background: #fff;
  align-items: center;
  border-radius: 8px;
  margin: 0 12px;
  /deep/ img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
  .order,
  .service {
    background-repeat: no-repeat;
    background-size: 30px;
    flex: 1;
    color: #42352c;
    font-size: 13px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .order {
    border-right: 1px solid #d8d8d8;
  }
}
</style>