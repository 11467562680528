<template>
  <van-popup
    closeable
    round
    @click-overlay="close"
    @click-close-icon="close"
    class="centification-dialog"
    :value="value"
  >
    <main class="centification">
      <div class="name">
        <div class="title">完成实名认证</div>
        <div class="subtitle">{{ subtitle }}</div>
      </div>
      <div class="phone">
        手机号：<span>{{ phone_number | maskStr }}</span>
      </div>
      <van-form
        class="centification-form"
        @submit="onSubmit"
        @failed="onFailed"
        ref="form"
        :show-error="false"
        :show-error-message="false"
      >
        <van-field
          class="field-input"
          label="姓"
          :border="false"
          maxlength="30"
          placeholder="姓"
          v-model.trim="last_name_cn"
          :rules="[
            {
              required: true,
              message: '请输入姓',
            },
          ]"
        />
        <van-field
          class="field-input"
          label="名"
          :border="false"
          maxlength="30"
          v-model.trim="first_name_cn"
          placeholder="名"
          :rules="[
            { required: true, message: '请输入名' },
            {
              pattern: /[\u4e00-\u9fa5]{1,10}/,
              message: '名必须是中文',
            },
          ]"
        />

        <van-field
          class="field-input"
          label="身份证"
          maxlength="18"
          :border="false"
          v-model.trim="id_number"
          placeholder="身份证"
          :rules="[
            { required: true, message: '请输入身份证号码' },
            {
              pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
              message: '请输入正确的居民身份证号码',
            },
          ]"
        />

        <div class="centification-button">
          <van-button
            v-ans="{ event_id: 'centification_dialog_submit' }"
            round
            block
            type="info"
            size="large"
            native-type="submit"
            >确认</van-button
          >
        </div>
      </van-form>
    </main>
  </van-popup>
</template>

<script>
import { realnameVerify } from "@/apis/home";
import { mapActions, mapState } from "vuex";
import ans from "@/utils/ans";

export default {
  props: {
    value: Boolean,
    close: {
      type: Function,
      default() {},
    },
    resolve: Function,
    reject: Function,
    title: String,
    className: String,
    subtitle: String,
    phone_number: String,
  },
  data() {
    return {
      last_name_cn: "",
      first_name_cn: "",
      id_number: "",
    };
  },
  async mounted() {
    ans.track("centification_dialog_show");
  },
  computed: {
    ...mapState(["namespace"]),
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    beforeClose() {
      this.last_name_cn = "";
      this.first_name_cn = "";
      this.id_number = "";
    },

    async onSubmit() {
      const loading = this.$loading();
      try {
        await realnameVerify({
          last_name_cn: this.last_name_cn,
          first_name_cn: this.first_name_cn,
          id_number: this.id_number,
          phone_number: this.phone_number,
        });
        loading.clear();
        this.$toast("实名认证成功");
        await this.fetchUserInfo();
        this.resolve();
        this.close();
      } catch (e) {
        loading.clear();
        this.reject();
      }
    },
    onFailed({ errors }) {
      this.$toast(errors[0].message);
    },
  },
};
</script>

<style lang="less" scoped>
.centification-dialog {
  width: 80%;
  padding: 26px 16px 36px;
}

.name {
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  color: #ff5001;
  .subtitle {
    color: #333;
  }
  margin-bottom: 22px;
}

.centification-form {
  /deep/ .van-field__label {
    display: none;
  }
}
.field-input {
  background: #f5f5f5;
  border-radius: 25px;
  & + & {
    margin-top: 13px;
  }
}

.centification-button {
  margin-top: 24px;
  /deep/ .van-button {
    color: #fff;
    font-size: 16px;
    height: 49px;
    background: linear-gradient(to top, #ff341b 0%, #ff9c5e 100%);
  }
}

.phone {
  padding-left: 20px;
  margin: 22px 0 15px 0;
  font-size: 14px;
  color: #999;
  span {
    color: #333;
  }
}
</style>