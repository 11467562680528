<template>
  <div class="main">
    <div class="card" v-for="(item, index) in benefitList" :key="item.type">
      <div class="tag">可领取 {{ item.amount + item.amount_reserved }} 次</div>
      <img :src="item.icon_url" alt="" />
      <div>
        <p class="name">{{ getRichText(item.title) }}</p>
        <p class="desc">
          {{ item.subtitle }}
        </p>
      </div>
      <div
        class="btn"
        @click="jump(item)"
        v-ans="{
          event_id: 'benefit_list_click',
          sequence: index,
          spu_id: item.type,
          spu_name: item.title,
        }"
      >
        去使用
      </div>
    </div>

    <section class="page-footer">
      <router-link class="order" to="/order"
        ><svg-icon icon-class="leiniao-order" />我的订单</router-link
      >
      <router-link class="service" to="/service"
        ><svg-icon icon-class="leiniao-service" />在线客服</router-link
      >
    </section>
  </div>
</template>

<script>
export default {
  props: ["benefitList", "getRichText"],

  methods: {
    jump(item) {
      this.$router.push(`/benefitDetail?benefit_type=${item.type}`);
    },
  },
};
</script>

<style scoped lang='less'>
.main {
  position: relative;
  width: auto;
  height: 303px;
  background: #ffffff;
  border-radius: 13px;
  margin: 0 14px;
  padding: 10px 12px 0 18px;
  box-sizing: border-box;

  .card {
    font-family: PingFangSC, PingFang SC;
    position: relative;
    padding: 0 7px 0 16px;
    height: 106px;
    background: url("https://common-h5-dev.xiangshuheika.com/static/upload/20240320b89cad08d7d0e.png")
        no-repeat top left 100%/90px,
      linear-gradient(270deg, #ffdfe1 0%, #fff3ef 100%);
    border-radius: 8px;
    margin-bottom: 11px;
    display: flex;
    align-items: center;
    > img {
      width: 45px;
      height: 45px;
      flex-shrink: 0;
    }
    .name {
      margin-left: 10px;
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      margin-right: 10px;
    }
    .desc {
      margin-left: 10px;
      font-weight: 400;
      font-size: 9px;
      color: #333333;
      line-height: 13px;
      word-break: break-all;
      margin-right: 10px;
    }
    .btn {
      flex-shrink: 0;
      width: 60px;
      height: 23px;
      background: #fffefa;
      border-radius: 12px;
      margin: 0 0 0 auto;
      font-weight: 500;
      font-size: 11px;
      color: #ec4d25;
      line-height: 23px;
      text-align: center;
    }
  }
}

.tag {
  position: absolute;
  left: -8px;
  top: 3px;
  width: 70px;
  height: 30px;
  line-height: 18px;
  color: #fff;
  background: url("~@/assets/images/kukai_benefit_tag.png") no-repeat;
  background-size: 100%;
  font-size: 10px;
  text-align: center;
}

.page-footer {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 15px 0;
  margin-top: -10px;
  height: 92px;
  opacity: 0.82;
  box-sizing: border-box;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: url("~@/assets/images/kukai_footer_bg.png") no-repeat;
  background-size: 100%;

  .svg-icon {
    font-size: 20px;
    margin-right: 6px;
    color: #fd4c14;
    vertical-align: -4px;
  }
  .order,
  .service {
    font-size: 15px;
    border-radius: 42px;
    height: 42px;
    line-height: 42px;
    width: 50%;
    border: 1px solid #ebebeb;
    color: #202437;
    text-align: center;
    line-height: 40px;
    vertical-align: -4px;
    font-weight: bold;
  }
  .order {
    margin-right: 10px;
  }
}
</style>
