<template>
  <section class="yhk" v-if="pageshow">
    <van-image
      class="header-img"
      :src="`https://cdn.xiangshuheika.com/static/mdd/index-header.png`"
    />
    <div class="tit">
      会员有效期至：{{ useBenefit.card_expiry_date.replace(/-/g, ".") }}
    </div>
    <div class="main">
      <div class="list">
        <div
          class="item"
          v-for="item in benefit_list"
          :key="item.id"
          v-ans="{
            event_id: 'benefit_list_click',
            spu_id: item.id,
            spu_name: item.desc,
            namespace,
          }"
          @click="$router.push(`/benefitDetail?benefit_type=${item.type}`)"
        >
          <div class="top">
            <div>
              <div class="name">{{ item.title }}</div>
              <p>
                {{ item.subtitle }}，剩{{
                  item.amount + item.amount_reserved
                }}次
              </p>
              <p v-if="item.get_start_time && item.get_end_time">
                可领时间：{{ item.get_start_time }}-{{ item.get_end_time }}
              </p>
            </div>
            <div class="use">立即使用</div>
          </div>
          <van-image
            v-if="item.type === 'combo' && mdd_card_info"
            class="img"
            :src="`https://cdn.xiangshuheika.com/static/mdd/${item.type}-youku.png`"
          />
          <van-image
            v-else
            class="img"
            :src="`https://cdn.xiangshuheika.com/static/mdd/${item.type}.png`"
          />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-item" @click="$router.push('/order')">
        <div class="footer-icon footer-order"></div>
        我的订单
      </div>
      <div class="footer-item" @click="$router.push('/service')">
        <div class="footer-icon footer-server"></div>
        在线客服
      </div>
    </div>
    <div class="height60"></div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { getUserBenefit, getCardInfo } from "@/apis/home";
import dayjs from "dayjs";
export default {
  data() {
    return {
      useBenefit: {
        card_expiry_date: "",
      },
      pageshow: false,
      benefit_list: [],
      mdd_card_info: false,
    };
  },
  computed: {
    ...mapState(["namespace", "userInfo"]),
  },
  async mounted() {
    const res = await getUserBenefit();
    if (this.namespace.startsWith("mdd")) {
      const mdd_card_info = await getCardInfo();
      this.mdd_card_info = mdd_card_info.data;
    }
    this.useBenefit = res.data;
    res.data.benefit_list.map((r) => {
      r.get_start_time &&
        (r.get_start_time = dayjs(r.get_start_time).format("YYYY.MM.DD"));
      r.get_end_time &&
        (r.get_end_time = dayjs(r.get_end_time).format("YYYY.MM.DD"));
    });
    this.benefit_list = res.data.benefit_list;
    this.pageshow = true;
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.header-img {
  height: 200px;
}
.tit {
  box-sizing: border-box;
  background-color: #ff8aab;
  font-size: 12px;
  color: #fff;
  padding: 0 12px;
  height: 23px;
  line-height: 23px;
}
.main {
  margin: 13px 12px 0;

  .list {
    margin-top: 11px;

    .item {
      background: #ffedea;
      border: 1px solid #ffcbc4;
      padding: 10px;
      border-radius: 8px;
      margin-bottom: 12px;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        .name {
          font-size: 18px;
          color: #333;
          font-weight: bold;
          line-height: 25px;
        }

        p {
          font-size: 12px;
          color: #666;
          line-height: 17px;
        }

        .use {
          width: 100px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          border-radius: 32px;
          font-size: 14px;
          color: #fff;
          background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
        }
      }
    }
  }
}

.footer {
  background-color: #fff;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #333;
  height: 55px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer-item {
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.footer-item::after {
  content: "";
  width: 1px;
  height: 19px;
  background-color: #f5f5f5;
  position: absolute;
  right: 0;
  top: 2px;
}

.footer-item:last-child:after {
  display: none;
}

.footer-icon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.footer-order {
  background-image: url("../../assets/images/icon-order1.png");
}

.footer-server {
  background-image: url("../../assets/images/icon-server1.png");
}

.height60 {
  height: 60px;
}
</style>