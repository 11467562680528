<template>
  <div>
    <van-dialog
      theme="confirm common-dialog-confirm"
      show-cancel-button
      @cancel="close"
      @confirm="submit"
      confirm-button-text="立即领取"
      :value="value"
      title="请选择您需要领取的权益"
      :before-close="beforeClose"
    >
      <div class="railSourceList">
        <van-form ref="form">
          <van-field
            readonly
            clickable
            name="city"
            :value="city"
            label="使用城市"
            :rules="[{ required: true, message: '请选择城市' }]"
            placeholder="点击选择城市"
            @click="showPicker = true"
          />
          <van-field
            name="phone"
            :disabled="!!userInfo.user_phone"
            v-model="phone"
            :value="phone"
            :rules="[
              {
                required: true,
                message: '请输入手机号',
              },
              { validator, message: '手机号格式不正确' },
            ]"
            label="领取手机号"
            placeholder="领取手机号"
          />
        </van-form>
      </div>
    </van-dialog>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { activateBenefit, querySpecialCarCity } from "@/apis/home";
import { mapActions, mapState } from "vuex";
export default {
  props: ["showAtour", "refresh", "sku"],
  data() {
    return {
      railSource_selected: 0,
      value: false,
      showPicker: false,
      cityIndex: "",
      phone: "",
      citys: [],
      columns: [],
    };
  },
  created() {
    this.phone = this.userInfo.user_phone || "";
  },
  computed: {
    ...mapState(["userInfo", "benefit_source"]),
    city() {
      if (this.cityIndex === "") {
        return "";
      }
      return this.citys[this.cityIndex].city;
    },
  },
  async mounted() {
    const loading = this.$loading();
    try {
      const res = await querySpecialCarCity();
      this.citys = res.data;
      this.columns = res.data.map((r) => r.city);
      this.value = true;
    } finally {
      loading.clear();
    }
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    async beforeClose(action, done) {
      if (action === "confirm") {
        done(false);
      } else {
        done();
      }
    },
    validator(val) {
      return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(val);
    },
    onConfirm(e, index) {
      this.cityIndex = index;
      this.showPicker = false;
    },
    close() {
      this.$emit("update:showAirport", false);
    },
    async submit() {
      const valdate = await this.$refs.form.validate();
      if (valdate) {
        return;
      }
      const loading = this.$loading();
      let opts = {
        benefit_type: this.$route.query.benefit_type,
        detail_type: "Shenma",
        activity_id: this.citys[this.cityIndex].activity_id,
        is_zero_pay: true,
        phone_number: this.phone,
        item_code: this.sku.item_code,
      };
      if (this.benefit_source) {
        opts.benefit_source = this.benefit_source;
      }
      try {
        await activateBenefit(opts);
        await this.fetchUserInfo();
        this.refresh();
        this.value = false;
        this.$alert({
          title: "温馨提示",
          hideClose: true,
          message:
            "5折接送机券已下发至您的账户，<br/>请在【神马专车】APP中使用",
          confirmButtonText: "好的",
        }).then(() => {
          this.close();
        });
      } finally {
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>