var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-dialog',{staticClass:"login-dialog",attrs:{"value":_vm.value,"show-confirm-button":false}},[_c('div',{staticClass:"login-header"},[_c('div',{class:{ 'login-title': true },domProps:{"innerHTML":_vm._s(_vm.getTitle)}})]),_c('van-form',{staticClass:"login-form",attrs:{"show-error":false,"show-error-message":false},on:{"submit":_vm.onSubmit,"failed":_vm.onFailed}},[_c('div',{staticClass:"get-code"},[_c('van-field',{staticClass:"field-input",attrs:{"placeholder":"请输入手机号","rules":[
          {
            required: true,
            message: '请输入手机号',
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: '请输入正确的11位手机号',
          } ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phone"}}),_c('div',{class:{ code: true, countDown: _vm.countDown > 0 },on:{"click":_vm.getVerifyCode}},[_vm._v(" "+_vm._s(_vm.countDown > 0 ? (_vm.countDown + "s") : "获取验证码")+" ")])],1),_c('van-field',{staticClass:"field-input",attrs:{"placeholder":"请输入验证码","rules":[{ required: true, message: '请输入验证码' }]},model:{value:(_vm.code),callback:function ($$v) {_vm.code=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"code"}}),_c('div',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.error))]),_c('div',{staticClass:"form-submit"},[_c('van-button',{attrs:{"round":"","size":"large","block":"","type":"info","native-type":"submit"}},[_vm._v("立即绑定")])],1),_c('div',{staticClass:"tips"},[_vm._v("不影响使用，后续可用此手机号登录使用GoGo大会员权益")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }