<template>
  <section class="business-card">
    <van-tabs v-model="active" animated class="tabs-card">
      <van-tab
        v-for="tab in tabs"
        :title="`${tab.title}${
          list[tab.type].length ? `(${list[tab.type].length})` : ''
        }`"
        :key="tab.type"
      >
        <div :class="`card-list card-list-${tab.type}`">
          <div
            class="card-item"
            v-for="item in list[tab.type]"
            :key="item.id"
            @click="toDetail(item, tab.status)"
          >
            <van-image
              lazy-load
              :src="getImage(item.name)"
              class="card-image"
            />
            <div class="card-content">
              <div class="card-name">{{ item.name }}</div>
              <div class="card-amount">{{ item.amount }}</div>
              <div class="card-expired">有效期至{{ item.t_expiration }}</div>
            </div>
            <div class="card-btn">{{ tab.btnText }}</div>
          </div>
          <van-empty v-if="!list[tab.type].length" description="暂无卡券" />
        </div>
      </van-tab>
    </van-tabs>
  </section>
</template>

<script>
import { getUserCoupon } from "@/apis";
import qs from "qs";
import { mapState } from "vuex";

export default {
  data() {
    return {
      active: 0,
      list: {
        unused_list: [],
        used_list: [],
        expired_list: [],
      },
      tabs: [
        {
          type: "unused_list",
          status: "unused",
          title: "未使用",
          btnText: "未使用",
        },
        {
          type: "used_list",
          title: "已使用",
          status: "used",
          btnText: "已使用",
        },
        {
          type: "expired_list",
          title: "已过期",
          status: "expired",
          btnText: "已过期",
        },
      ],
    };
  },
  computed: {
    ...mapState(["namespace"]),
  },
  mounted() {
    this.fetchCoupon();
  },
  methods: {
    getImage(name) {
      return `https://cdn.xiangshuheika.com/static/${this.namespace}/order/${name}.png`;
    },
    toDetail(row, status) {
      this.$router.push(`/couponDetail?${qs.stringify({ ...row, status })}`);
    },
    async fetchCoupon() {
      const loading = this.$loading();
      const res = await getUserCoupon();
      loading.clear();
      this.list = res.data;
    },
  },
};
</script>


<style lang="less" scoped>
.card-list {
  margin-top: 10px;
  min-height: 80vh;
}
.card-item {
  height: 95px;
  margin-bottom: 10px;
  padding: 12px 15px 12px 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #fff;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 90px;
    top: -5px;
    width: 10px;
    height: 10px;
    background-color: #f5f5f5;
    display: block;
    border-radius: 10px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 90px;
    bottom: -5px;
    width: 10px;
    height: 10px;
    background-color: #f5f5f5;
    display: block;
    border-radius: 10px;
  }
  .card-image {
    height: 71px;
    flex-basis: 71px;
    display: block;
    margin-right: 17px;
    flex-shrink: 0;
  }
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card-name {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    color: #333;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
  }
  .card-amount {
    line-height: 18px;
    color: #333;
  }
  .card-expired {
    color: #999;
    margin-top: 5px;
  }
  .card-btn {
    margin-left: auto;
    flex-shrink: 0;
    width: 56px;
    text-align: center;
    height: 25px;
    line-height: 25px;
    border-radius: 25px;
    background-color: #d9ba77;
    color: #fff;
  }
}
.card-list-used_list,
.card-list-expired_list {
  .card-btn {
    background: #999;
  }
}
.business-card {
  padding: 10px 15px;
}
.tabs-card {
  /deep/ .van-tabs__nav {
    padding-left: 8px;
    align-items: center;
    padding-bottom: 0;
  }
  /deep/ .van-tabs__line {
    display: none;
  }
  /deep/ .van-tabs__wrap {
    height: 45px;
    border-radius: 10px;
  }
  /deep/ .van-tab {
    line-height: 25px;
    box-sizing: border-box;
    color: #333;
    padding: 0 8px;
    margin-right: 10px;
    height: 25px;
    border: 1px solid currentColor;
    border-radius: 25px;
    flex: inherit;
    &.van-tab--active {
      color: #ff5001;
      background: #ffede5;
    }
  }
}
</style>
