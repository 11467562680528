<template>
  <section :class="`page-fqy page-${namespace}`" v-if="!fetching">
    <div
      class="floor-item"
      v-for="item in catesgorys"
      :key="item.id"
      v-ans="{
        event_id: 'benefit_list_click',
        spu_id: item.id,
        spu_name: item.desc,
        namespace,
      }"
    >
      <div class="top">
        <div class="left">
          <div v-text-highlight="TEXT_HIGHLIGHT_CONFIG" class="name">
            {{ item.name }}
          </div>
          <div class="desc">{{ item.desc }}</div>
        </div>
        <div
          class="use"
          @click="
            $router.push(
              `/benefitDetail?benefit_type=${item.list[0].product_code}`
            )
          "
        >
          立即使用
        </div>
      </div>
      <div class="page-benefit-list">
        <div class="page-benefit-spu" v-for="sku in item.list" :key="sku.id">
          <van-image :src="sku.display_img_url" lazy-load />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import useMemberBenefitCategory from "@/use/useMemberBenefitCategory";
import store from "@/store";
import { jsonSafeParse } from "@/utils";

const vm = Vue.prototype;

export default defineComponent({
  setup() {
    const { catesgorys, getProductList } = useMemberBenefitCategory();
    const fetching = ref(true);
    onMounted(async () => {
      const loading = vm.$loading();
      try {
        await getProductList();
      } finally {
        fetching.value = false;
        loading.clear();
      }
    });
    return {
      catesgorys,
      fetching,
      namespace: store.state.namespace,
      TEXT_HIGHLIGHT_CONFIG: jsonSafeParse(
        vm.$getConfigValue("TEXT_HIGHLIGHT_CONFIG")
      ),
    };
  },
});
</script>

<style lang="less" scoped>
.page-fqy {
  padding: 18px 12px;
}
.floor-item {
  border-radius: 9px;
  & + & {
    margin-top: 18px;
  }
  .top {
    height: 100px;
    display: flex;
    padding-left: 15px;
    padding-right: 12px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .name {
    font-size: 17px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 1px;
  }
  .desc {
    font-size: 15px;
    line-height: 21px;
  }
  .left {
    padding-top: 15px;
  }
  .use {
    margin-top: 25px;
    margin-left: auto;
    width: 78px;
    height: 30px;
    text-align: center;
    border-radius: 99999px;
    font-size: 15px;
    line-height: 30px;
  }
}
.page-benefit-list {
  margin-top: -26px;
  position: relative;
  z-index: 1;
}

.page-benefit-spu {
  min-height: 100px;
}

@namespaces: fqycc12, fqybc12;

.fqycc12() {
  --bgcolor: #7e290d;
  --nameColor: #4c2d22;
  --descColor: #7d3b20;
  --useBg: linear-gradient(179deg, #ff662d 0%, #ff776d 60%, #ff3737 100%);
  --useShadow: 0px 7px 20px 0px #ffc294, 0px 2px 0px 0px #fef4ec,
    inset 0px 1px 0px 0px #ffffff;
  --useColor: #fff;
  --bgImage: url("~@/assets/images/fqycc12-benefit-top-bg.png");
}

.fqybc12() {
  --bgcolor: #0f1343;
  --nameColor: #d9dbf4;
  --descColor: #9399e5;
  --useBg: linear-gradient(
    180deg,
    #ffcfa0 0%,
    #fff2e7 16%,
    #f4c095 60%,
    #e6944d 100%
  );
  --useShadow: 0px 7px 20px 0px #3d25a9, 0px 2px 0px 0px #faead6;
  --useColor: #63301c;
  --bgImage: url("~@/assets/images/fqybc12-benefit-top-bg.png");
}

each(@namespaces, {
  .page-@{value} when (@value = fqycc12) {
   .fqycc12();
  }
  .page-@{value} when (@value = fqybc12) {
   .fqybc12();
  }
});

.page-fqy {
  background-color: var(--bgcolor);
  .top {
    background-image: var(--bgImage);
  }
  .name {
    color: var(--nameColor);
  }
  .desc {
    color: var(--descColor);
  }
  .use {
    background: var(--useBg);
    color: var(--useColor);
    box-shadow: var(--useShadow);
  }
}
</style>

