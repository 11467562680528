var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"select-order"},[_c('section',{staticClass:"order-panel"},[_c('div',{staticClass:"order-panel-top"},[_c('div',{staticClass:"flex-l"},[_vm._v("最近订单")]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'help_service_change_order_click1' }),expression:"{ event_id: 'help_service_change_order_click1' }"}],staticClass:"flex-r",on:{"click":function($event){_vm.visible = true}}},[_vm._v(" 切换订单 "),_c('svg-icon',{staticClass:"icon-right",attrs:{"icon-class":"arrow2"}})],1)]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'help_service_order_click',
        order_number: _vm.order.order_number,
        category_type: _vm.order.category_type,
      }),expression:"{\n        event_id: 'help_service_order_click',\n        order_number: order.order_number,\n        category_type: order.category_type,\n      }"}],staticClass:"order-item",on:{"click":function($event){return _vm.toService(_vm.order)}}},[_c('div',{staticClass:"order-header"},[_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.order.t_created,"YYYY-MM-DD HH:mm:ss")))]),_c('div',{staticClass:"order-status"},[_vm._v("已成功")])]),_c('div',{staticClass:"order-content"},[_c('div',{staticClass:"flex-left ellipsis"},[_c('div',{staticClass:"order-name ellipsis"},[_vm._v(_vm._s(_vm.order.name))]),_c('div',{staticClass:"order-number"},[_vm._v("订单编码："+_vm._s(_vm.order.order_number))])])])])]),_c('van-popup',{staticClass:"order-popup-container",staticStyle:{"max-height":"90%"},attrs:{"closeable":"","position":"bottom","round":"","close-on-popstate":"","value":_vm.visible,"safe-area-inset-bottom":""},on:{"click-overlay":_vm.close,"close":_vm.close,"click-close-icon":_vm.close}},[_c('div',{staticClass:"title"},[_vm._v("请选择订单")]),_c('ul',{staticClass:"order-list"},_vm._l((_vm.orders),function(item){return _c('li',{key:item.order_number,class:{
          'order-item': true,
          active: _vm.order.order_number === item.order_number,
        },on:{"click":function($event){return _vm.selectOrder(item)}}},[_c('div',{staticClass:"order-header"},[_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(item.t_created,"MM月DD日 HH:mm")))]),_c('div',{staticClass:"order-status"},[_vm._v("已成功")])]),_c('div',{staticClass:"order-content"},[_c('div',{staticClass:"flex-left ellipsis"},[_c('div',{staticClass:"order-name ellipsis"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"order-number"},[_vm._v("订单编码："+_vm._s(item.order_number))])]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
              event_id: 'help_service_select_order',
              order_number: item.order_number,
              category_type: item.category_type,
            }),expression:"{\n              event_id: 'help_service_select_order',\n              order_number: item.order_number,\n              category_type: item.category_type,\n            }"}],staticClass:"flex-right"},[_vm._v(" 选择 ")])])])}),0)]),_c('van-popup',{staticClass:"order-popup-container",attrs:{"closeable":"","close-on-popstate":true,"position":"bottom","round":"","value":_vm.showServicePopup,"safe-area-inset-bottom":""},on:{"click-overlay":function($event){_vm.showServicePopup = false},"click-close-icon":function($event){_vm.showServicePopup = false}}},[_c('div',{staticClass:"title"},[_vm._v("当前订单如下")]),_c('div',{staticClass:"service-order"},[_c('div',{staticClass:"service-panel-top"},[_c('div',{staticClass:"flex-l"},[_vm._v("您当前咨询的是如下订单：")]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'help_service_change_order_click2' }),expression:"{ event_id: 'help_service_change_order_click2' }"}],staticClass:"flex-r",on:{"click":function($event){_vm.visible = true}}},[_vm._v(" 切换订单 "),_c('svg-icon',{staticClass:"icon-right",attrs:{"icon-class":"arrow2"}})],1)]),_c('div',{staticClass:"order-item active",on:{"click":function($event){return _vm.toService(_vm.order)}}},[_c('div',{staticClass:"order-header"},[_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.order.t_created,"MM月DD日 HH:mm")))]),_c('div',{staticClass:"order-status"},[_vm._v("已成功")])]),_c('div',{staticClass:"order-content"},[_c('div',{staticClass:"flex-left ellipsis"},[_c('div',{staticClass:"order-name ellipsis"},[_vm._v(_vm._s(_vm.order.name))]),_c('div',{staticClass:"order-number"},[_vm._v("订单编码："+_vm._s(_vm.order.order_number))])]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'help_service_to_udesk' }),expression:"{ event_id: 'help_service_to_udesk' }"}],staticClass:"flex-right"},[_vm._v(" 选择 ")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }