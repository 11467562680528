<template>
  <div class="category-body">
    <div class="category-body-inner">
      <CalendarMonth
        :priceInfo="priceInfo"
        :getPriceInfo="getPriceInfo"
        v-for="(item, index) in listGroup"
        :key="index"
        :list="item.list"
        :month="item.month"
        :class="`month-${item.monthNumber}`"
        :selectRangeDate.sync="selectRangeDate"
        :type="type"
        :minDate="minDate"
        :maxDate="maxDate"
        :single="single"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import CalendarMonth from "./CalendarMonth";
import animateScrollTo from "animated-scroll-to";
import { isHoliday, jsonSafeParse } from "@/utils";

const generateDay = (day, holidays) => {
  const date = dayjs(day);
  // 获取生成日期的第一天 和 最后一天
  const firstDate = date.startOf("month");
  const lastDate = date.endOf("month");
  const preMonthDate = date.subtract(1, "month");
  let list = [];
  const nowMonthDays = date.daysInMonth(); // 获取这个月有多少天
  const preMonthLastDay = preMonthDate.endOf("month").day(); // 获取上个月最后一天是周几
  // 生成上个月的日期
  for (let i = 1; i <= preMonthLastDay + 1; i++) {
    const thisDate = firstDate.subtract(i, "day").startOf("day");
    let item = {
      marks: [],
      text: thisDate.date(),
      type: -1,
      isHead: false,
      isTail: false,
      isToday: thisDate.isSame(new Date(), "day"),
      value: thisDate.format("YYYY-MM-DD"),
      isHoliday: isHoliday(thisDate.format("YYYY-MM-DD")),
      holiday_name: holidays[thisDate.format("YYYY-MM-DD")],
    };
    list.push(item);
  }
  list.reverse();
  // 生成这个月的日期
  for (let i = 0; i < nowMonthDays; i++) {
    const thisDate = firstDate.add(i, "day").startOf("day");
    let item = {
      marks: [],
      _value: thisDate,
      text: thisDate.date(),
      type: 0,
      isHead: false,
      isTail: false,
      isToday: thisDate.isSame(new Date(), "day"),
      value: thisDate.format("YYYY-MM-DD"),
      isHoliday: isHoliday(thisDate.format("YYYY-MM-DD")),
      holiday_name: holidays[thisDate.format("YYYY-MM-DD")],
    };
    list.push(item);
  }
  // 生成下个月的日期
  let i = 1;
  while (list.length < 42) {
    const thisDate = lastDate.add(i++, "day").startOf("day");
    let item = {
      marks: [],
      _value: thisDate,
      text: thisDate.date(),
      type: 1,
      isHead: false,
      isTail: false,
      isToday: thisDate.isSame(new Date(), "day"),
      value: thisDate.format("YYYY-MM-DD"),
      isHoliday: isHoliday(thisDate.format("YYYY-MM-DD")),
      holiday_name: holidays[thisDate.format("YYYY-MM-DD")],
    };
    list.push(item);
  }
  if (list[35] && list[35].type === 1) {
    list = list.slice(0, 35);
  }
  return list;
};

export default {
  props: [
    "getPriceList",
    "type",
    "range",
    "minDate",
    "maxDate",
    "rangeDate",
    "single",
  ],
  components: {
    CalendarMonth,
  },
  data() {
    return {
      selectRangeDate: this.$props.rangeDate,
      priceInfo: null,
    };
  },
  mounted() {
    this.getPriceInfo(this.selectRangeDate[0]);
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.selectRangeDate[0]) {
          const monthEl = document.querySelector(
            `.month-${dayjs(this.selectRangeDate[0]).format("YYYYMM")}`
          );
          const scrollEl = monthEl.parentNode;
          const t = monthEl.getBoundingClientRect().top;
          animateScrollTo([null, t - scrollEl.getBoundingClientRect().top], {
            elementToScroll: scrollEl,
            speed: 500,
          });
        }
      }, 100);
    });
  },
  computed: {
    listGroup() {
      const listGroup = [];
      for (let i = 0; i < Math.ceil(this.range / 30) + 1; i++) {
        const startMonth = dayjs().add(i, "month").startOf("month");
        const holidays = jsonSafeParse(this.$getConfigValue("HOLIDAYS"));
        const list = generateDay(startMonth, holidays);
        listGroup.push({
          list,
          month: startMonth.format("YYYY年MM月"),
          monthNumber: startMonth.format("YYYYMM"),
        });
      }
      return listGroup;
    },
  },
  methods: {
    getPriceInfo(date) {
      if (this.getPriceList) {
        const priceInfo = this.getPriceList(dayjs(date));
        this.priceInfo = priceInfo;
      }
    },
  },
};
</script>