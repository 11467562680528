<template>
  <div
    :class="{
      'benefit-info': true,
      'benefit-info-margin': true,
    }"
  >
    <div class="benefit-info-title" v-if="benefitHeaderImage">
      {{ benefitInfo.title || benefitInfo.name }}
    </div>
    <div class="benefit-info-inner">
      <div class="benefit-name">
        <div class="benefit-icon" v-if="benefitIcon">
          <van-image lazy-load :src="benefitIcon" />
        </div>
        <div class="benefit-header-info">
          <div class="benefit-title">
            <span class="benefit-title-span">{{ getSecondTitle }}</span>
          </div>
          <div class="benefit-subtitle">
            {{ benefitInfo.subtitle }}
          </div>
        </div>
        <span v-if="amount > 0" class="benefit-amount">剩{{ amount }}次</span>
      </div>
      <div class="benefit-sku">
        <benefit-sku
          :skuInfo="skuInfo"
          :selectSku="selectSku"
          :showAccount="showAccount"
          :ispType.sync="isp"
          :updateSelectSku="updateSelectSku"
          :faceValueStatus.sync="faceValueStatus"
          :disabled="disabled"
          :account="account"
          :phone_account.sync="phone_account"
          :rechargeAccounts="rechargeAccounts"
          :isAbnormalAccount.sync="abnormal_account"
        />
        <benefit-btn-wrap
          :benefitInfo="benefitInfo"
          :selectSku="selectSku"
          :showAccount="showAccount"
          :account="account"
          :phone_account="phone_account"
          :benefit_type="benefit_type"
          :faceValueStatus="faceValueStatus"
          :rechargeAccounts="rechargeAccounts"
          :refresh="refresh"
          :disabled="disabled"
          :abnormal_account.sync="abnormal_account"
          :isp="isp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BenefitBtnWrap from "./benefitBtnWrap";
import BenefitSku from "./benefitSku";
export default {
  components: { BenefitSku, BenefitBtnWrap },
  props: {
    benefit_type: String,
    benefitHeaderImage: String,
    benefitInfo: Object,
    skuInfo: Array,
    images: Object,
    selectSku: Object,
    updateSelectSku: Function,
    refresh: Function,
    rechargeAccounts: Array,
  },
  data() {
    return {
      phone_account: "",
      isp: null,
      faceValueStatus: {},
      abnormal_account: false,
    };
  },
  computed: {
    ...mapState(["userInfo", "namespace", "namespaceInfo"]),
    account: {
      get() {
        return this.phone_account.replace(/ /g, "");
      },
      set() {},
    },
    showAccount() {
      return this.selectSku.allowed_recharge_account_types?.length > 0;
    },
    disabled() {
      // 未启用充值账号绑定功能，不禁用
      if (!this.namespaceInfo.enable_recharge_account_limit) {
        return false;
      }
      return !!this.rechargeAccounts.find(
        (item) =>
          item.account_type_number === this.selectSku.recharge_account_number
      );
    },
    amount() {
      // 亚朵不显示剩余次数
      if (this.benefit_type === "atour") {
        return 0;
      }
      // 免费权益不显示点数
      if (this.selectSku.top_up_type === 5) {
        return 0;
      }
      return (
        this.benefitInfo.amount_available + this.benefitInfo.amount_reserved
      );
    },
    // 权益icon
    benefitIcon() {
      return this.images.titleIcon?.[0].image_url;
    },

    getSecondTitle() {
      if (!this.userInfo.is_member) {
        return "您还不是会员";
      }
      if (!this.benefitIcon) {
        return this.benefitInfo.title || this.benefitInfo.name;
      }
      if (this.benefitInfo.brief_subtitle) {
        return this.benefitInfo.brief_subtitle;
      }
      if (
        this.benefitInfo.amount_available <= 0 &&
        this.benefitInfo.amount_reserved <= 0
      ) {
        return "本权益已用完";
      }
      const unit = this.benefitInfo.use_period_frequency
        ? `本${this.benefitInfo.use_period_frequency}`
        : "当前";
      return [
        `${unit}可用${this.benefitInfo.amount_available}次`,
        this.benefitInfo.next_use_wait_days !== null &&
          `距下${this.benefitInfo.use_period_frequency || "次"}使用还有${
            this.benefitInfo.next_use_wait_days
          }天`,
      ]
        .filter(Boolean)
        .join("，");
    },
  },
  methods: {},
  watch: {
    "selectSku.recharge_account_number": {
      handler: function (val) {
        this.phone_account =
          this.rechargeAccounts.find((item) => item.account_type_number === val)
            ?.account || "";
      },
      immediate: true,
    },
  },
};
</script>