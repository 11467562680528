<template>
  <div class="luck-draw-body">
    <van-image
      class="luck-draw-bg"
      :src="activityInfo.special_info.header_img"
    />
    <div class="luck-draw-component">
      <LuckyGrid
        class="luckygrid"
        :width="width"
        :buttons="buttons"
        :height="height"
        :defaultConfig="{ gutter: '8px' }"
        ref="luckyWheelRef"
        :prizes="prizesList"
        :default-style="{ fontColor: '#F7923B', fontSize: '13px' }"
        @start="drawHandleThrottle"
        @end="endLucky"
      />
    </div>
    <div class="draw-btn-wrap">
      <van-button
        block
        round
        type="primary"
        class="draw-btn1"
        @click="drawHandleThrottle"
      >
        {{ activityInfo.special_info.start_draw_text || "开始抽奖" }}
        {{ remainingTimes > 0 ? `X${remainingTimes}` : "" }}
      </van-button>
    </div>
    <Dialog
      v-model="showDialog"
      :activityReward="activityReward"
      :type="type"
      :openPrizePopup="openPrizePopup"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "@vue/composition-api";
import throttle from "lodash.throttle";

import { LuckyGrid } from "@lucky-canvas/vue";
import Dialog from "./Dialog";

export default defineComponent({
  inheritAttrs: false,

  components: {
    LuckyGrid,
    Dialog,
  },
  props: {
    activityInfo: Object,
    activityReward: Object,
    beforeDraw: Function,
    openPrizePopup: Function,
    afterDraw: Function,
    type: String,
    remainingTimes: Number,
  },
  setup(props) {
    const luckyWheelRef = ref(null);
    const showDialog = ref(false);
    const run = ref(false);

    const buttons = computed(() => {
      if (!props.activityInfo.special_info.arrowImg) {
        return [];
      }
      return [
        {
          x: 1,
          y: 1,
          imgs: [
            {
              width: "90%",
              top: "5%",
              src: props.activityInfo.special_info.arrowImg,
            },
          ],
        },
      ];
    });

    const width = computed(() => {
      const w = props.activityInfo.special_info.width || 280;
      return (
        (document.documentElement.getBoundingClientRect().width / 10) *
        (w / 37.5)
      );
    });
    const height = computed(() => {
      const w =
        props.activityInfo.special_info.height ||
        props.activityInfo.special_info.width ||
        280;
      return (
        (document.documentElement.getBoundingClientRect().width / 10) *
        (w / 37.5)
      );
    });

    const prizesList = computed(() => {
      const point = [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 2, y: 1 },
        { x: 2, y: 2 },
        { x: 1, y: 2 },
        { x: 0, y: 2 },
        { x: 0, y: 1 },
      ];
      // 如果不配置转盘指针图片，则指针位置展示奖品
      if (!props.activityInfo.special_info.arrowImg) {
        point.unshift({ x: 1, y: 1 });
      }
      const goods_choice = props.activityInfo.special_info.goods_choice || {};
      const goods = Object.entries(goods_choice).map(([k, v], index) => {
        return {
          ...point[index],
          borderRadius: "10px",
          item_code: k,
          max_num: v.max_num,
          min_num: v.min_num,
          imgs: [
            {
              activeSrc: v.img1,
              src: v.img,
              width: props.activityInfo.special_info.prizeWidth || "90%",
              top: props.activityInfo.special_info.prizeTop || "0",
            },
          ],
        };
      });
      goods.sort((a, b) => a.min_num - b.min_num);
      return goods;
    });

    const startLucky = async () => {
      if (run.value) {
        return;
      }
      const res = await props.beforeDraw();
      if (!res) {
        return;
      }
      luckyWheelRef.value.play();
      run.value = true;
      setTimeout(() => {
        const index = prizesList.value.findIndex(
          (r) => r.item_code === res.item_code
        );
        luckyWheelRef.value.stop(index);
      }, 2000);
    };
    const drawHandleThrottle = throttle(startLucky, 3000, { trailing: false });

    const endLucky = () => {
      showDialog.value = true;
      setTimeout(async () => {
        await props.afterDraw();
        run.value = false;
      }, 300);
    };
    return {
      buttons,
      prizesList,
      width,
      height,
      endLucky,
      drawHandleThrottle,
      luckyWheelRef,
      showDialog,
    };
  },
});
</script>

<style lang="less" scoped>
</style>
