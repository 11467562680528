var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.fetching)?_c('section',{class:("page-fqy page-" + _vm.namespace)},_vm._l((_vm.catesgorys),function(item){return _c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
      event_id: 'benefit_list_click',
      spu_id: item.id,
      spu_name: item.desc,
      namespace: _vm.namespace,
    }),expression:"{\n      event_id: 'benefit_list_click',\n      spu_id: item.id,\n      spu_name: item.desc,\n      namespace,\n    }"}],key:item.id,staticClass:"floor-item"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[_c('div',{directives:[{name:"text-highlight",rawName:"v-text-highlight",value:(_vm.TEXT_HIGHLIGHT_CONFIG),expression:"TEXT_HIGHLIGHT_CONFIG"}],staticClass:"name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))])]),_c('div',{staticClass:"use",on:{"click":function($event){return _vm.$router.push(
            ("/benefitDetail?benefit_type=" + (item.list[0].product_code))
          )}}},[_vm._v(" 立即使用 ")])]),_c('div',{staticClass:"page-benefit-list"},_vm._l((item.list),function(sku){return _c('div',{key:sku.id,staticClass:"page-benefit-spu"},[_c('van-image',{attrs:{"src":sku.display_img_url,"lazy-load":""}})],1)}),0)])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }