import { render, staticRenderFns } from "./HotelLayout.vue?vue&type=template&id=28a00420&scoped=true&"
import script from "./HotelLayout.vue?vue&type=script&lang=js&"
export * from "./HotelLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a00420",
  null
  
)

export default component.exports