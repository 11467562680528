<template>
  <section class="keep" v-if="!fetching">
    <van-notice-bar
      v-if="$getConfigValue('INDEX_NOTICE')"
      left-icon="volume-o"
      mode="closeable"
      class="notice-bar"
      :text="$getConfigValue('INDEX_NOTICE')"
    />
    <section class="page-header">
      <div
        :class="`page-header-inner ${is_labeled && 'page-header-inner-label'}`"
      >
        <div class="logout-account" @click="showLogout">切换账号</div>
        <div v-if="!is_labeled" class="page-header-desc">
          每天可解锁使用1项权益
        </div>
        <div class="page-header-desc-isLabeled" v-else>
          <p>每天可解锁使用1项权益</p>
          <p>视听特权、商超购物、咖啡特权需7天内领取</p>
        </div>
        <div class="page-header-expired">
          {{ card.card_expiry_date | formatDate("YYYY-MM-DD") }} 到期
        </div>
        <van-button @click="renew" round class="renew">立即续费</van-button>
      </div>
    </section>
    <div class="keep-buySend" v-if="showBuySend" @click="handleBuySend"></div>
    <van-sticky :offset-bottom="-50" class="tab-sticky">
      <ul class="keep-tab">
        <li
          @click="scrollToFloor(index)"
          v-for="(item, index) in catesgorys"
          :class="{ 'keep-tab-item': true, active: tabIndex === index }"
          :key="item.desc"
        >
          {{ item.desc }}
        </li>
      </ul>
    </van-sticky>
    <section class="page-benefit" v-if="card.card_status === 'active'">
      <router-link
        v-ans="{
          event_id: 'luckdraw_index_entry',
        }"
        to="/luckdraw"
        :class="['activity-entry', activityTimes === 0 ? 'no-times' : '']"
        :style="`background-image:url(${keepActivity.prizeEntryImage})`"
        v-if="keepActivity.prizeEntryImage && showActivityEntry"
      >
        <div class="activity-times">剩{{ activityTimes }}次</div>
      </router-link>
      <div
        :class="`page-benefit-item page-benefit-${item.display_code}`"
        v-for="item in catesgorys"
        :key="item.name"
      >
        <div class="page-benefit-item-inner">
          <div class="benefit-item-title">
            {{ item.name }}
          </div>
          <div
            :class="`page-benefit-list-wrap page-benefit-${item.display_code}`"
          >
            <div class="page-benefit-list">
              <div
                class="page-benefit-spu"
                v-for="(spu, idx) in item.list"
                v-ans="{
                  event_id: 'benefit_list_click',
                  sequence: idx + 1,
                  spu_id: spu.id,
                  spu_name: spu.desc,
                  namespace,
                }"
                :key="spu.product_code"
              >
                <div class="page-spu-inner">
                  <van-image
                    class="page-benefit-image"
                    lazy-load
                    fit="cover"
                    :src="spu.display_img_url"
                  />

                  <div
                    class="page-benefit-wrap"
                    v-if="item.display_code !== 'keepvip'"
                  >
                    <div class="page-benefit-desc">
                      剩{{ spu.benefit.amount + spu.benefit.amount_reserved }}次
                    </div>
                    <van-button
                      round
                      block
                      :class="[
                        'page-benefit-btn',
                        isDisabled(spu.benefit) ? 'disabled' : '',
                      ]"
                      @click="jump(spu.benefit)"
                    >
                      {{ getBenefitText(spu.benefit) }}
                    </van-button>
                    <div
                      class="benefit-text"
                      v-if="
                        spu.benefit.get_start_time &&
                        spu.benefit.get_end_time &&
                        spu.benefit.amount <= 0 &&
                        spu.benefit.amount_reserved > 0
                      "
                    >
                      {{
                        nextDay(
                          spu.benefit.get_start_time,
                          spu.benefit.get_end_time
                        )
                      }}可用
                    </div>
                    <div class="benefit-text" v-else v-html="spu.desc"></div>
                    <div class="benefit-selling-point" v-if="is_labeled">
                      {{ spu.selling_points }}
                    </div>
                  </div>
                  <div
                    class="keep-click-el"
                    v-else
                    @click="jump(spu.benefit)"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-else-if="card.card_status === 'expired'" class="card-past">
      <van-image :src="require('../../assets/images/yh-empty.png')" />
      <p>您的权益已过期</p>
    </div>
    <div v-else-if="card.card_status === 'refund'" class="card-past">
      <van-image :src="require('../../assets/images/yh-empty.png')" />
      <p>您的权益已退款</p>
    </div>
    <section class="page-footer">
      <router-link class="order" to="/order"
        ><svg-icon icon-class="keep-order" />我的订单</router-link
      >
      <router-link class="service" to="/service"
        ><svg-icon icon-class="keep-service" />在线客服</router-link
      >
    </section>
    <CommonPopup :show.sync="showBuySendPopup">
      <div class="buySend-popup">
        <div class="buySend-popup-img"></div>
        <div class="buy-send-btn" @click="handleBuySend">去使用</div>
      </div>
    </CommonPopup>
  </section>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "@vue/composition-api";
import Vue from "vue";
import { NoticeBar } from "vant";
import dayjs from "dayjs";
import animateScrollTo from "animated-scroll-to";

import { storage } from "@/utils";
import { useRoute, useRouter, useStore } from "@/components/use/base";
import PhoneBindDialog from "@/components/phoneBindDialog";
import { getUserDrawAppoint } from "@/apis/luckdraw";
import useMemberBenefitCategory from "@/use/useMemberBenefitCategory";
import CommonPopup from "@/components/CommonPopup";
import ans from "@/utils/ans";
import { queryLoginBind, queryUserBenefitUse } from "@/apis/home";

const vm = Vue.prototype;

const getExtraPhone = () => {
  const phone_number = storage.getItem("login_phone_number") || "";
  return phone_number.slice(0, 3) + "****" + phone_number.slice(-4);
};
export default defineComponent({
  components: {
    [NoticeBar.name]: NoticeBar,
    CommonPopup,
  },
  setup() {
    const { namespace, userInfo } = useStore().state;
    const store = useStore();
    const is_labeled = store.state.is_labeled;
    const $route = useRoute();
    const $router = useRouter();
    const activityTimes = ref(0);
    const showActivityEntry = ref(false);
    const fetching = ref(true);
    const tabIndex = ref(0);
    const keepEntry = ref(
      !!$route.query.once || !!sessionStorage.getItem("keepEntry")
    );
    const showBuySendPopup = ref(false);

    const { card, catesgorys, getProductList } = useMemberBenefitCategory();
    const getBenefitText = (benefit) => {
      // if (!benefit) {
      //   return "去使用";
      // }
      if (benefit.type.startsWith("keepvip")) {
        return "查看";
      }
      if (benefit.amount > 0) {
        return "去使用";
      } else {
        if (benefit.amount_reserved > 0) {
          return "已使用";
        }
        return "已领完";
      }
    };

    const showBuySend = computed(() => {
      const isActivity = vm.$getConfigValue("KEEP_BUY_SEND");
      const hasBenefit = card.value.benefit_list?.some(
        (item) => item.type === "keep_addbenefit"
      );
      return isActivity && hasBenefit ? true : false;
    });

    const initBuySendPopup = () => {
      // 已经跳出过弹窗则不跳
      const hadPopUp = localStorage.getItem("hadPopup");
      const activityBenefit = card.value.benefit_list?.filter(
        (item) => item.type === "keep_addbenefit"
      )[0]?.amount;
      if (showBuySend.value && !hadPopUp && !!activityBenefit) {
        localStorage.setItem("hadPopup", true);
        showBuySendPopup.value = true;
      }
    };

    const handleBuySend = () => {
      $router.push(`/benefitDetail?benefit_type=keep_addbenefit`);
    };

    const nextDay = (start, end) => {
      if (dayjs().isBefore(dayjs(start).startOf("day"))) {
        return dayjs(start).format("YYYY.MM.DD");
      }
      return dayjs(end).add(1, "day").format("YYYY.MM.DD");
    };

    const isDisabled = (benefit) => {
      return (
        benefit?.amount === 0 ||
        benefit?.amount + benefit?.amount_reserved === 0
      );
    };
    // 退出登录
    const showLogout = () => {
      if (keepEntry.value) {
        vm.$alert({
          title: "温馨提示",
          confirmButtonText: "好的",
          message: "请在app-头像-设置-账号与安全-退出登录然后切换账号",
        });
      } else {
        vm.$confirm({
          message: `<div class="logout-confirm">
            当前已登录账号
          </div>
          <div class="logout-confirm-phone">
            ${getExtraPhone()}
          </div>`,
          className: "logout-confirm",
          confirmButtonText: "退出并重新登录",
          cancelButtonText: "取消",
        }).then(() => {
          storage.removeItem("token");
          storage.removeItem("login_phone_number");
          store.commit("changeState", {
            token: "",
            ptoken: "",
            showKeepLogin: true,
            isMember: false,
            memberInfo: {},
            userInfo: {},
          });
          $router.push("/keep");
        });
      }
    };
    const jump = async (item) => {
      if (vm.$getConfigValue("LIMIT_USER_BENEFIT")) {
        const res = await queryUserBenefitUse();
        if (res.data === "used") {
          vm.$alert({
            message: "每天仅可使用1项权益，你今天已用过，请明天再来",
            confirmButtonText: "好的",
          });
          return;
        }
      }
      $router.push(`/benefitDetail?benefit_type=${item.type}`);
    };

    const fetchActivityInfo = async () => {
      const res = await getUserDrawAppoint({
        activity_name: "keep_blind_box",
        hideMsg: true,
      });
      activityTimes.value = res.data.number;
      if (res.data.used || res.data.number > 0) {
        showActivityEntry.value = true;
      }
    };

    const keepActivity = computed(() => {
      const activity = vm.$getConfigValue("KEEP_MEMBER_OPEN_ACTIVITY");
      if (!activity) {
        return {};
      }
      try {
        return JSON.parse(activity);
      } catch (e) {
        console.log(e);
        return {};
      }
    });

    const scrollToFloor = (idx) => {
      tabIndex.value = idx;
      const el = document.querySelector(
        `.page-benefit-${catesgorys.value[idx].display_code}`
      );

      animateScrollTo(el, {
        verticalOffset: -50,
      });
    };

    const renew = () => {
      $router.push("/keep?renew=1");
    };

    onMounted(async () => {
      if (!store.state.isMember) {
        $router.replace("/keep");
        return;
      }
      is_labeled &&
        ans.pageView("keep_grayscale_member", {
          namespace: namespace,
        });
      const loading = vm.$loading();
      try {
        await getProductList();
      } finally {
        fetching.value = false;
        loading.clear();
      }
      if (userInfo.is_member && keepEntry.value) {
        // keep用户登录 已开通会员未绑定手机号的需要需弹窗强制绑定
        const queryBind = await queryLoginBind({
          namespace,
          unique_id: userInfo.unique_id,
        });
        if (!queryBind.data) {
          PhoneBindDialog.show();
        }
      }
      // 配置了活动入口，才查询剩余活动次数
      if (keepActivity.value.prizeEntryImage) {
        await fetchActivityInfo();
      }
      initBuySendPopup();
    });

    onBeforeUnmount(() => {
      PhoneBindDialog.close();
    });

    return {
      catesgorys,
      fetching,
      getBenefitText,
      isDisabled,
      showLogout,
      namespace,
      card,
      jump,
      nextDay,
      keepActivity,
      activityTimes,
      showActivityEntry,
      scrollToFloor,
      tabIndex,
      renew,
      showBuySend,
      handleBuySend,
      is_labeled,
      showBuySendPopup,
    };
  },
});
</script>

<style lang="less" scoped>
.keep-buySend {
  background: url("https://common-h5-dev.xiangshuheika.com/static/upload/20241127f11ce21cacfe7.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 106px;
  margin: 0px 16px 5px 16px;
}

.buySend-popup {
  .buySend-popup-img {
    background: url("https://common-h5-dev.xiangshuheika.com/static/upload/2024112706c2a4b38e74a.png");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 276px;
    height: 335px;
  }
  .buy-send-btn {
    margin: 0 auto;
    margin-top: 12px;
    width: 120px;
    height: 40px;
    background: linear-gradient(136deg, #ff6a00 0%, #f71b00 100%), #666666;
    border-radius: 20px;

    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
  }
}
.keep {
  background: #fa5923;
  padding-bottom: 24px;
}
.notice-bar {
  height: 24px;
  background: #ff4f00;
  font-size: 12px;
  color: #fff;
}
.page-header {
  height: 165px;
  color: #ff5001;
  padding: 26px 16px 0;
  box-sizing: border-box;
  background: linear-gradient(360deg, #fa5923 0%, #ff934b 100%);
}

.page-header-inner {
  position: relative;
  background: url("../../assets/images/keep_member_bg.png") no-repeat
    center/100%;
  height: 125px;
  padding: 62px 16px 16px;
  box-sizing: border-box;
}
.page-header-inner-label {
  padding: 50px 16px 16px !important;
  background: url("../../assets/images/keep_member_bg_label.png") no-repeat
    center/100% !important;
}

.logout-account {
  width: 95px;
  height: 26px;
  position: absolute;
  color: #fff;
  left: -4px;
  top: -13px;
  font-size: 14px;
  line-height: 26px;
  padding-left: 5px;
  box-sizing: border-box;
  background: url("../../assets/images/keep_member_switch_account.png")
    left/100% no-repeat;
  &::before {
    content: "";
    display: inline-block;
    background: url("../../assets/images/logout.png") no-repeat left center/100%
      12px;
    width: 10px;
    height: 10px;
    margin-right: 6px;
  }
}

.renew {
  width: 78px;
  height: 28px;
  line-height: 28px;
  background: linear-gradient(136deg, #ff6a00 0%, #f71b00 100%);
  border-radius: 14px;
  color: #fff;
  border: none;
  position: absolute;
  right: 16px;
  bottom: 14px;
  padding: 0;
}

.page-header-desc {
  line-height: 22px;
  letter-spacing: 0.5px;
  font-size: 14px;
}

.page-header-desc-isLabeled {
  font-weight: 400;
  font-size: 12px;
  color: #ff5001;
  line-height: 12px;
  text-align: left;
  p {
    margin-top: 5px;
  }
}

.page-header-expired {
  margin-top: 8px;
  line-height: 17px;
}

.page-benefit-image {
  max-height: 125px;
}

.tab-sticky {
  /deep/ .van-sticky--fixed {
    .keep-tab {
      box-shadow: 0 0 5px rgb(254, 59, 51);
    }
  }
}

.keep-tab {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background: #fa5923;
  padding: 10px 0;
}

.keep-tab-item {
  padding: 0 4px;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  &.active {
    font-weight: bold;
    font-size: 16px;
    border-bottom: 2px solid #fff;
  }
  & + .keep-tab-item {
    margin-left: 15px;
  }
}

.page-benefit {
  padding: 10px 15px 0;
}

.benefit-item-title {
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  margin-bottom: 8px;
}

.page-benefit-item {
  & + .page-benefit-item {
    margin-top: 16px;
  }
}

.page-benefit-btn {
  &.disabled {
    opacity: 0.5;
  }
}

.page-benefit-spu {
  & + .page-benefit-spu {
    margin-top: 12px;
  }
}

.page-spu-inner {
  position: relative;
}

.keep-click-el {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: transparent;
}

.page-benefit-wrap {
  position: absolute;
  right: 0;
  top: 0;
  width: 88px;
  text-align: center;
  height: 100%;
  box-sizing: border-box;
  padding: 29px 6px 0;
  color: #fff;
}
.benefit-text {
  line-height: 16px;
  margin-top: 4px;
  font-size: 10px;
}
.benefit-selling-point {
  line-height: 12px;
  font-size: 10px;
}
.page-benefit-desc {
  margin-bottom: 4px;
  line-height: 16px;
}

.page-benefit-btn {
  height: 26px;
  line-height: 26px;
  color: #f82800;
  background: linear-gradient(180deg, #ffead5 0%, #fed1aa 100%);
}

.page-footer {
  display: flex;
  margin: 24px 14px 0;
  border-radius: 8px;
  padding: 5px 0;
  font-size: 15px;
  background: linear-gradient(94deg, #ffe8d4 0%, #ffffff 100%);
  .svg-icon {
    font-size: 18px;
    margin-right: 6px;
    color: #ff9667;
    vertical-align: -4px;
  }
  .order,
  .service {
    width: 50%;
    color: #333;
    line-height: 34px;
    text-align: center;
  }
  .order {
    margin-right: 10px;
  }
}
.times {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 4px;
  line-height: 18px;
  color: #fff;
  background: #ff9667;
  border-radius: 0 8px 0 8px;
  font-size: 10px;
}

.card-past {
  height: 195px;
  background-color: #fff;
  border-radius: 5px;
  margin: 12px 12px 20px;
  overflow: hidden;
  p {
    text-align: center;
    line-height: 1.5;
    color: #404040;
    margin-top: 17px;
  }
  /deep/ .van-image {
    width: 80px;
    height: 80px;
    margin: 56px auto 14px;
  }
}

.activity-entry {
  position: fixed;
  top: 50%;
  right: 0;
  width: 60px;
  height: 60px;
  display: block;
  z-index: 100;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  &.no-times {
    .activity-times {
      display: none;
    }
  }
  .activity-times {
    margin-top: auto;
    height: 17px;
    border-radius: 17px;
    background: linear-gradient(to bottom, #ff7371, rgb(254, 59, 51));
    line-height: 17px;
    text-align: center;
    width: 100%;
    color: #fff;
  }
}
</style>