<template>
  <div class="content-wrap" :class="{ isMember }">
    <component
      v-for="item of pageInfo"
      :key="item.id"
      :is="item.display_code"
      v-bind="item"
      :active="active"
      :requestZhongAn="requestZhongAn"
      :notPay="notPay"
    />
    <div class="footer">
      <p>以上权益内容由深圳市橡树黑卡网络科技有限公司提供</p>
      <p>如有问题请电话联系客服咨询：<span>400-150-9669</span></p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { getIndexCategory, getIndexClassList } from "@/apis/product";
import ans from "@/utils/ans";

import media from "./components/media";
import food from "./components/food";
import travel from "./components/travel";
import live from "./components/live";
import hotel from "./components/hotel";
import { zhonganApply } from "./service";

export default {
  name: "ChContent",
  props: {
    active: Number,
    cardArr: Array,
  },
  components: {
    media,
    food,
    travel,
    live,
    hotel,
  },
  data() {
    return {
      pageInfo: [],
      notPay: false,
    };
  },
  computed: {
    ...mapState(["namespace", "isMember"]),
  },
  watch: {
    active: {
      handler(value) {
        const card = this.cardArr[value]?.namespace;
        this.init(card);
      },
      immediate: true,
    },
  },
  methods: {
    async init(card) {
      try {
        const { data: categoriesData } = await getIndexCategory({
          namespace: card || this.namespace,
          opened_page: "member",
          source: "default",
        });
        const { data: productData } = await getIndexClassList({
          title: categoriesData.map((v) => v.name).join(","),
          opened_page: "member",
          namespace: card || this.namespace,
        });
        const pageInfo = categoriesData.map((value) => ({
          ...value,
          productInfo: productData[value.name] || [],
        }));
        this.pageInfo = pageInfo;
      } catch (error) {
        console.log(error);
      }
    },

    async requestZhongAn() {
      this.$confirm({
        message: `<p>您尚未支付会员费，</p><p>立即支付即可享受会员权益</p>`,
        confirmButtonText: "好的",
        cancelButtonText: "放弃",
      }).then(
        async () => {
          const trans_info = this.$route.query.transInfo;
          await zhonganApply({ namespace_name: this.namespace, trans_info });
          ans.track("chenghua_to_apply", {
            namespace: this.namespace,
          });
        },
        () => {}
      );
    },
  },
  async mounted() {
    this.notPay = !!this.$route.query.transInfo;
    this.init();
  },
};
</script>

<style lang="less" scoped>
.content-wrap {
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
}

.footer {
  margin: 24px 0;
  text-align: center;
  font-size: 10px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #c5c7ce;
  line-height: 14px;
  span {
    color: rgba(93, 174, 255, 1);
  }
}
.isMember {
  padding-bottom: 86px;
}
</style>