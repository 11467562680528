<template>
  <div>
    <member v-if="isMember" />
    <visitor v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";
import member from "./member";
import visitor from "./visitor";

export default {
  components: {
    member,
    visitor,
  },
  computed: {
    ...mapState(["isMember", "namespace"]),
  },
};
</script>