<template>
  <component :is="component" />
</template>

<script>
import yh from "./yh";
import mdd from "./mdd";
import keep from "./keep";
import page from "./main";
import kd100 from "./kd100";
import huaruntong from "./huaruntong";
import leiniao from "./leiniao";
import kukai from "./kukai";
import unxp from "./unxp";
import xpcw from "./xpcw";
import chenghua from "./chenghua";
import licheng from "./licheng";
import lniao from "./lniao";
import fqy from "./fqy";
import ln from "./ln";
import yst from "./yst";
import commonPage from "./commonPage";
import commonRedeem from "./commonRedeem";

import { mapState } from "vuex";
import ans from "@/utils/ans";

export default {
  components: {
    yh,
    mdd,
    page,
    keep,
    kd100,
    huaruntong,
    leiniao,
    unxp,
    xpcw,
    chenghua,
    kukai,
    licheng,
    lniao,
    fqy,
    ln,
    yst,
    commonPage,
    commonRedeem,
  },
  mounted() {
    document.title = this.$getConfigValue("INDEX_TITLE");
    if (this.$getConfigValue("IS_QUANMIN_NAMESPACE") && this.namespace) {
      this.$router.replace("/quanmin");
    }
    if (this.isMember) {
      ans.pageView("page_member");
    } else {
      ans.pageView("page_visitor");
    }
  },
  computed: {
    ...mapState(["namespace", "isMember", "namespaceInfo"]),
    component() {
      if (this.namespace.startsWith("yh")) {
        return "yh";
      }

      // 租户组共用页
      const namespaceGroupEnum = {
        yst: "yst",
      };
      const namespace_group = this.namespaceInfo.namespace_group_en || "";
      if (namespaceGroupEnum[namespace_group]) {
        return namespaceGroupEnum[namespace_group];
      }

      // 租户跟页面组建映射
      const temp = {
        kd100: "kd100",
        mdd: "mdd",
        cr: "huaruntong",
        keep: "keep",
        leiniao: "leiniao",
        unxp: "unxp",
        cwxp: "xpcw",
        kukai: "kukai",
        lniao: "lniao",
        za3: "chenghua",
        za4: "chenghua",
        za5: "chenghua",
        fqybc12: "fqy",
        fqycc12: "fqy",
        licheng3: "licheng",
        licheng4: "licheng",
        licheng5: "licheng",
        licheng6: "licheng",
        ysttc: "yst",
      };

      if (this.$getConfigValue("LN_PAGE_CONFIG")) return "ln";
      if (this.$getConfigValue("KUKAI_PAGE_CONFIG")) return "commonPage";
      if (this.$getConfigValue("COMMON_REDEEM_PAGE")) return "commonRedeem";

      return temp[this.namespace] || "page";
    },
  },
};
</script>

<style></style>