<template>
  <div class="benefit-header">
    <div class="save-money" v-if="saveText">
      {{ saveText }}
    </div>
    <van-image
      lazy-load
      class="benefit-header-img"
      fit="cover"
      :src="benefitImage"
    />
    <div v-if="shareInfo" class="to-share" @click="openShareModal"></div>
  </div>
</template>

<script>
import { jsonSafeParse } from "@/utils";
import { mapState } from "vuex";
import { queryTotalSavMoney, shareBenefit } from "@/apis/home";
import copy from "copy-to-clipboard";
import * as dayjs from "dayjs";
import qs from "qs";

export default {
  props: {
    benefit_type: String,
    benefitHeaderImage: String,
    benefitInfo: Object,
  },

  data() {
    return {
      saveText: "",
      shareInfo: null,
    };
  },
  computed: {
    ...mapState(["namespace", "isShengbeiApp"]),
    //获取顶部头图链接
    benefitImage() {
      // 先取套餐权益配比地址，没有的话就取spu 地址 再没有的话就从oss取
      return (
        this.benefitHeaderImage ||
        // this.skus[0]?.product_img ||
        `${this.$cdn}/static/${this.namespace}/${this.benefit_type}-bg2.png`
      );
    },
  },
  mounted() {
    this.getShareInfo();
    this.getTotalMoney();
  },
  methods: {
    async getShareInfo() {
      //获取活动信息
      const SY_BENEFIT_SHARE_INFO = this.$getConfigValue(
        "SY_BENEFIT_SHARE_INFO"
      );
      if (SY_BENEFIT_SHARE_INFO) {
        const info = jsonSafeParse(SY_BENEFIT_SHARE_INFO);
        if (info[this.benefit_type]) {
          this.shareInfo = info[this.benefit_type];
        }
      }
    },
    async getTotalMoney() {
      if (!this.$getConfigValue("SHOW_SAVING_MONEY")) {
        return;
      }
      try {
        const res = await queryTotalSavMoney();
        const { total, unit, expected_saving } = res.data;
        this.saveText = total
          ? `生活特权已为您累计节省${total}${unit}`
          : `生活特权预计可为您节省${expected_saving}${unit}`;
      } catch (e) {
        this.saveText = "";
      }
    },
    openShareModal() {
      if (this.benefitInfo.amount_available <= 0) {
        this.$toast("当前可用次数不足");
        return;
      }
      this.$alert({
        title: "分享前确认",
        confirmButtonText: "同意并分享",
        message:
          "您的本月可用次数将分享赠送给好友，您本月将不可使用该权益。是否继续分享",
      }).then(async (ok) => {
        const loading = this.$loading();
        try {
          const res = await shareBenefit({
            namespace: "fs",
            benefit_type: this.benefit_type,
            benefit_source: "会员权益",
          });
          await this.refresh();
          // 生成兑换链接
          const link = `${location.origin}${
            location.pathname
          }#/shareBenefit?${qs.stringify(
            {
              source: "fs",
              code: res.data.cashing_code,
              benefit_type: this.benefit_type,
              title: encodeURIComponent(this.benefitInfo.title),
              subtitle: encodeURIComponent(this.benefitInfo.subtitle),
              expired: dayjs(res.data.t_expiration).valueOf(),
              from: this.namespace,
            },
            { encode: false }
          )}`;
          // 获取分享链接
          if (this.isShengbeiApp) {
            const shareInfo = JSON.stringify({
              scene: "",
              title: this.shareInfo.title,
              subtitle: this.shareInfo.subtitle,
              html5: link,
              iconURL: "https://cdn.xiangshuheika.com/static/sy/share.png",
              imageData: "",
              isInvite: false,
              shareto: "wxfriend",
            });
            if (this.isAndroid) {
              SMYJSInterface.appShare(shareInfo);
            } else if (this.isiOS) {
              window.webkit.messageHandlers.SMYJSInterface.postMessage({
                method: "appShare",
                param: shareInfo,
              });
            }
          } else {
            copy(link);
          }
        } finally {
          loading.clear();
        }
      });
    },
  },
};
</script>