import PhoneModal from "@/components/phoneModal";
import { creatRefuelLink } from "@/utils";
import copy from "copy-to-clipboard";

export default {
  methods: {
    getYioilLink(userInfo) {
      // 判断用户是否在小程序内
      if (this.isMiniProgram) {
        // 弹窗提示用户是否复制手机号
        this.$confirm({
          message:
            "无法在小程序内直接使用加油优惠，可复制链接至手机自带浏览器访问并使用。",
          cancelButtonText: "稍后使用",
          confirmButtonText: "复制去使用",
        }).then(() => {
          // 使用微信定位api获取位置
          wx.ready(() => {
            wx.getLocation({
              success: ({ latitude, longitude }) => {
                const link = creatRefuelLink(latitude, longitude, userInfo);
                copy(link);
                this.$toast("已复制链接，请使用自带浏览器访问并使用");
              },
            });
          });
        });
      } else {
        this.getLocation(userInfo);
      }
    },

    getLocation(res) {
      // 公众号内
      if (this.isWebchat) {
        wx.ready(() => {
          wx.getLocation({
            success: ({ latitude, longitude }) => {
              const link = creatRefuelLink(latitude, longitude, res);
              window.location.href = link;
            },
            error: () => {
              this.$toast("获取定位失败");
            },
          });
        });
      } else {
        var geolocation = new window.BMap.Geolocation();
        if (geolocation) {
          geolocation.enableSDKLocation();
          return geolocation.getCurrentPosition(
            (position) => {
              var lat = position.latitude; //纬度
              var lag = position.longitude; //经度
              let link = creatRefuelLink(lat, lag, res);
              window.location.href = link;
            },
            () => {
              this.$toast("获取定位失败");
            }
          );
        } else {
          this.$toast("获取定位失败");
        }
      }
    },
    async checkYioil() {
      // 判断是否登录
      // 判断是否会员
      if (!this.userInfo.user_phone) {
        const toast = this.$loading();
        try {
          const info = await this.fetchUserInfo();
          if (!info.user_phone) {
            PhoneModal.show().then(async (e) => {
              this.getYioilLink(e);
            });
          } else {
            this.getYioilLink(info);
          }
        } finally {
          toast.clear();
        }
      } else {
        this.getYioilLink(this.userInfo);
      }
    }
  }
}