<template>
  <div>
    <member :swipeClick="swipeClick" :toBuy="toBuy" v-if="isMember" />
    <visitor :swipeClick="swipeClick" :toBuy="toBuy" v-else />
  </div>
</template>

<script>
import { storage } from "@/utils";
import { mapState } from "vuex";
import member from "./member";
import visitor from "./visitor";

export default {
  components: {
    member,
    visitor,
  },
  mounted() {
    const { token, device } = this.$route.query;
    // 华润通权益激活参数
    if (token && device) {
      storage.setItem("CR_PARAMS", JSON.stringify({ token, device }));
    }
  },
  computed: {
    ...mapState(["isMember", "namespace"]),
  },
  methods: {
    toBuy() {
      this.$alert({
        title: "温馨提示",
        message:
          "感谢您一路以来的支持与厚爱！因华润通经营策略调整，通享卡现已暂停发售。",
        showConfirmButton: false,
        hideClose: true,
      });
      // const CR_PARAMS = storage.getItem("CR_PARAMS");
      // if (CR_PARAMS) {
      //   try {
      //     const obj = JSON.parse(CR_PARAMS);
      //     location.href = `${this.$getConfigValue("CR_PAY_URL")}?token=${
      //       obj.token
      //     }`;
      //   } catch (e) {}
      // } else {
      //   this.$toast("请在华润通内购买");
      // }
    },
    swipeClick(item) {
      if (!item.tag) {
        return;
      }
      if (item.tag.startsWith("/")) {
        this.router.push(item.tag);
      } else if (item.tag.startsWith("http")) {
        location.href = item.tag;
      }
    },
  },
};
</script>