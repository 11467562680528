<template>
  <div>
    <transition name="height0">
      <div class="benefit-sku-input" v-if="showAccount">
        <div
          class="benefit-input-label"
          :style="`pointer-events: ${
            selectSku.allowed_recharge_account_types.length > 1
              ? 'initial'
              : 'none'
          }`"
          @click="showSelectRechargePopup = true"
        >
          充值{{ topUpText
          }}<svg-icon
            v-if="selectSku.allowed_recharge_account_types.length > 1"
            class="benefit-input-label-arrow"
            iconClass="arrow"
          />
          <span v-else>：</span>
        </div>
        <van-field
          :disabled="disabled"
          :placeholder="placeholder"
          class="benefit-input"
          :value="phone_account"
          @input="changeAccount"
          :error-message="errorMessage"
          :type="input_type"
        />
        <div v-if="isp && isp.phone_number === account" class="isp">
          {{ isp.isp }}
        </div>
      </div>
    </transition>
    <div class="benefit-sku-list" v-if="skuInfo.length > 1">
      <van-row>
        <van-col
          @click="skuChange(item, idx)"
          :class="{
            'sku-item': true,
            'sku-flex': !!item.image,
            active: item.id === selectSku.id,
          }"
          :span="12"
          v-for="(item, idx) in skuInfo"
          :key="item.id"
          v-ans="{
            event_id: 'benefit_detail_click_spu',
            spu_id: benefitInfo.type,
            spu_name: benefitInfo.name,
            sku_id: selectSku.id,
            sku_name: selectSku.item_name,
            namespace,
          }"
        >
          <div
            :class="{
              'sku-item-inner': true,
              'new-image': !!item.image,
            }"
          >
            <van-image
              lazy-load
              class="sku-item-inner-image"
              :src="item.image || getImage(item)"
              fit="contain"
            />
            <div
              v-if="item.image"
              :class="{
                'sku-item-name': true,
              }"
            >
              {{ item.platform_product_name }}
            </div>
            <div v-if="item.status === 0" class="out-stock">无货</div>
          </div>
        </van-col>
      </van-row>
    </div>
    <select-popup
      title="选择账号充值类型"
      :value="selectSku.recharge_account_number"
      @update:value="changeRechargeAccountNumber"
      :options="selectSku.allowed_recharge_account_types"
      :optionFieldMap="{
        label: 'account_type_name',
        value: 'account_type_number',
      }"
      v-model="showSelectRechargePopup"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/selectPopup";

import { mapState } from "vuex";
import { queryPhoneIsp } from "@/apis/home";
import { isValidPhone } from "@/helper/validate";
import { checkPhone } from "@/apis";
import { queryMobileOperator } from "@/apis/product";
export default {
  props: {
    skuInfo: Array,
    rechargeAccounts: Array,
    selectSku: Object,
    benefitInfo: {
      type: Object,
      default: () => ({}),
    },
    showAccount: Boolean,
    updateSelectSku: Function,
    ispType: Object,
    faceValueStatus: {
      type: Object,
      default: () => ({}),
    },
    disabled: Boolean,
    isAbnormalAccount: Boolean,
    account: String,
    phone_account: String,
  },
  components: {
    SelectPopup,
  },
  data() {
    return {
      showSelectRechargePopup: false,
      errorMessage: "",
      abnormal_account: false,
      isp: null,
    };
  },
  computed: {
    ...mapState(["namespace"]),
    input_type() {
      return this.selectSku.recharge_account_number === 1 ? "tel" : "text";
    },

    placeholder() {
      return "请输入" + this.topUpText;
    },
    topUpText() {
      return (
        this.selectSku.allowed_recharge_account_types?.find(
          (item) =>
            item.account_type_number === this.selectSku.recharge_account_number
        )?.account_type_name || "充值账号"
      );
    },
  },
  methods: {
    async queryPhoneFaceStatus(isp) {
      const face_values = this.skuInfo
        .filter((r) => r.attributes?.is_phone === "1")
        .map((item) => item.face_value);
      if (!face_values.length) {
        return;
      }
      const str = [...new Set(face_values)].join("|");
      const ispEnum = {
        移动: "CM",
        中国移动: "CM",
        联通: "CU",
        中国联通: "CU",
        电信: "CT",
        中国电信: "CT",
      };
      const res = await queryMobileOperator({
        isp: ispEnum[isp.isp] || isp.isp,
        face_values: str,
        namespace_name: this.namespace,
      });
      this.$emit("update:faceValueStatus", res.data);
    },
    skuChange(item, idx) {
      if (item.item_code === this.selectSku.item_code) return;
      this.updateSelectSku(item);
      this.errorMessage = "";
    },
    changeRechargeAccountNumber(type) {
      this.updateSelectSku({
        ...this.selectSku,
        recharge_account_number: type,
      });
    },

    changeAccount(e) {
      if (this.selectSku.recharge_account_number === 1) {
        let s = e.replace(/(^\d{3}|\d{4}\B)/g, "$1 ").replace("  ", " ");
        this.$emit("update:phone_account", s);
        const currentAccount = e.replace(/ /g, "");
        if (currentAccount.length && currentAccount.length !== 11) {
          this.errorMessage = "请输入正确的手机号";
        } else {
          this.errorMessage = "";
        }
        return;
      }
      this.$emit("update:phone_account", e);
    },
    // sku 图片
    getImage(item) {
      // 先取权益规则配比里面的图片  没有的话就取oss图片
      return (
        item.product_img_url ||
        `https://cdn.xiangshuheika.com/static/${this.namespace}/benefit/${item.item_code}.png`
      );
    },
    async getPhoneIsp() {
      // 缓存下查询结果
      if (this.isp?.phone_number === this.account) {
        return;
      }
      if (this.selectSku.attributes?.is_phone === "1") {
        // 查询手机归属地
        const isp = await queryPhoneIsp({ phone_number: this.account });
        // 如果是电信手机，并且充话费，显示所有配置的权益
        if (
          isp.data?.isp === "电信" &&
          this.skuInfo.some((item) => item.attributes?.is_phone === "1") &&
          this.skuInfo.length !== this.skus.length
        ) {
          this.skuInfo = this.skus;
          if (this.skuInfo.length) {
            this.updateSelectSku(this.skuInfo[0]);
          }
        }
        this.isp = isp.data;
        this.$emit("update:ispType", isp.data);
      }
    },
    async checkPhone() {
      //检测手机号是否为空号
      const res = await checkPhone({
        phone: this.account,
      });
      const { checked, status } = res.data;
      this.abnormal_account = checked && ["空号", "沉默号"].includes(status);
      this.$emit("update:isAbnormalAccount", this.abnormal_account);
    },
  },
  watch: {
    account: {
      // 判断手机充值
      handler: function (val) {
        if (this.selectSku.recharge_account_number === 1 && isValidPhone(val)) {
          this.getPhoneIsp();
          this.checkPhone();
        }
      },
      immediate: true,
    },
    isp(val) {
      if (val) {
        this.queryPhoneFaceStatus(val);
      } else {
        this.faceValueStatus = {};
      }
    },
  },
};
</script>