<template>
  <div class="wrap">
    <div class="header">
      <div class="title">{{ title }}</div>
      <div class="desc">{{ desc }}</div>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: ["title", "desc"],

  setup() {},
});
</script>

<style lang="less" scoped>
.wrap {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px 15px 19px 15px;
  margin-bottom: 21px;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .title {
    font-weight: 500;
    font-size: 15px;
    color: #333333;
    line-height: 21px;
    text-align: left;
  }
  .desc {
    font-weight: 400;
    font-size: 11px;
    color: #666666;
    line-height: 16px;
  }
}
</style>
