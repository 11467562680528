<template>
  <div>
    <router-view :key="key"></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import qs from "qs";
import { storage, creatSign } from "@/utils";

export default {
  mounted() { },
  computed: {
    ...mapState(["namespace"]),
    key() {
      return this.$route.path + qs.stringify(this.$route.query);
    },
  },
  created() {
    if (
      !storage.getItem("token") &&
      (this.$route.path === "/xiaopai/activation" || this.$route.path === "/xiaopai/home")
    ) {
      this.$router.replace("/xiaopai/index");
    }
  },
  methods: {

  },
};
</script>
<style scoped lang='less'>

</style>