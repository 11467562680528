<template>
  <new />
</template>

<script>
import { useRoute, useRouter, useStore } from "@/components/use/base";
import { defineComponent, onMounted } from "@vue/composition-api";
import New from "./new";
export default defineComponent({
  components: {
    New,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    onMounted(() => {
      // 会员自动跳转到首页
      if (store.state.isMember && !route.query.renew) {
        router.replace("/index");
      }
    });
    return {};
  },
});
</script>
