import request from "@/utils/hotelRequest"

//认证结果
export const verfyResult = (params) => {
  return request({
    url: '/api/core/user_entity_verify_result',
    method: 'get',
    params,
  })
}
// 激活认证
export const activateUserEntityVerify = (data) => {
  return request({
    url: '/api/core/user_entity_verify',
    method: 'post',
    data,
  })
}
