<template>
  <div class="food-wrap">
    <div class="head">
      <div class="title">{{ info.name }}</div>
      <div class="desc">{{ info.desc }}</div>
    </div>
    <div class="main">
      <div
        class="coupon"
        v-for="item of couponList1"
        :key="item.id"
        @click="draw(item)"
      >
        <div class="value-1" v-if="item.selling_points === '天天领'">
          {{ item.selling_points }}
        </div>
        <div class="value" v-else>
          最高<span>{{ item.selling_points }}</span
          >元
        </div>
        <div class="foot">
          <span>{{ item.desc }}</span>
          <div class="btn">立即领取</div>
        </div>
      </div>
      <div
        class="min-coupon"
        v-for="(item, index) of couponList2"
        :key="item.id"
        @click="draw(item)"
      >
        <div class="title" v-if="index <= 4"><span>5</span>折起</div>
        <div class="title2" v-else>{{ item.selling_points }}</div>
        <div class="name">{{ item.desc }}</div>
        <div class="btn">去使用</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["info", "handleDraw"],
  data() {
    return {
      couponList1: [],
      couponList2: [],
    };
  },

  computed: {
    ...mapState(["source", "namespace", "userInfo"]),
  },

  mounted() {
    this.couponList1 = this.info.productInfo.slice(0, 2);
    this.couponList2 = this.info.productInfo.slice(2);
  },

  methods: {
    async draw(item) {
      this.handleDraw(item);
    },
  },
};
</script>

<style scoped lang="less">
.food-wrap {
  width: auto;
  background: #fff;
  border-radius: 16px;
  margin-top: 12.5px;
  padding: 9px 16px 20px 16px;
  .head {
    display: flex;
    justify-content: space-between;
    font-family: PingFangSC, PingFang SC;
    .title {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
    }
    .desc {
      margin-top: 5px;
      font-size: 11px;
      font-weight: 400;
      color: #afb2b5;
    }
  }

  .main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .coupon {
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    width: 157.5px;
    height: 60px;
    background: #fffbf8;
    padding-left: 12.5px;
    position: relative;
    margin-top: 12px;

    .value {
      margin-top: 7.5px;
      font-size: 9px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #333333;
      span {
        margin: 0 1px;
        font-size: 20px;
      }
    }

    .value-1 {
      font-size: 9px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-top: 17px;
      line-height: 13px;
    }

    .foot {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 10.5px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #afb2b5;

      .btn {
        padding: 1px 7px;
        border: 1px solid;
        color: rgba(230, 154, 123, 1);
        border-radius: 10px;
        margin-right: 6px;
        font-size: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .min-coupon {
    width: 76px;
    height: 80px;
    box-sizing: border-box;
    background: #fffbf8;
    padding: 7.5px 0 9px 0;
    font-family: PingFangSC, PingFang SC;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 6px;
    .title {
      width: 100%;
      font-size: 9px;
      font-weight: 600;
      color: #333333;
      line-height: 12.5px;
      span {
        font-size: 20px;
        margin-right: 3px;
      }
    }
    .title2 {
      width: 100%;
      font-size: 11px;
      font-weight: 600;
      color: #333333;
      margin-top: 4px;
    }
    .name {
      width: 100%;
      font-size: 10.5px;
      font-weight: 400;
      color: #afb2b5;
      line-height: 14.5px;
      margin-top: 5px;
    }
    .btn {
      box-sizing: border-box;
      white-space: nowrap;
      padding: 1px 11px;
      border: 1px solid rgba(230, 154, 123, 1);
      color: rgba(230, 154, 123, 1);
      border-radius: 10px;
      font-size: 9px;
      line-height: 9px;
      margin-top: 8px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
