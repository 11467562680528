<template>
  <div class="benefits">
    <van-skeleton title :row="4" :loading="loading">
      <van-empty
        v-if="emptyText"
        :image="require('@/assets/images/promotion_empty.png')"
        :image-size="118"
        :description="emptyText"
      />
      <div
        v-else
        class="benefit"
        v-for="benefit in benefits"
        :key="benefit.type"
      >
        <div
          :class="{
            'flex-left': true,
            'flex-left-h':
              (benefit.amount > 0 && benefit.amount_available_expired_time) ||
              (benefit.amount === 0 &&
                benefit.get_start_time &&
                benefit.get_end_time),
          }"
        >
          <span
            v-if="benefit.amount + benefit.amount_reserved > 0"
            class="total"
            >剩{{ benefit.amount + benefit.amount_reserved }}次</span
          >
          <div class="flex-i">
            <van-image
              :src="benefit.icon_url"
              class="benefit-image"
            ></van-image>
            <div class="benefit-content">
              <div class="benefit-title">{{ benefit.title }}</div>
              <div class="benefit-subtitle">
                {{ benefit.brief_subtitle }}
              </div>
            </div>
          </div>
          <div
            class="flex-b"
            v-if="
              (benefit.amount > 0 && benefit.amount_available_expired_time) ||
              (benefit.amount === 0 &&
                benefit.get_start_time &&
                benefit.get_end_time)
            "
          >
            <template
              v-if="benefit.amount > 0 && benefit.amount_available_expired_time"
            >
              请在
              {{ benefit.amount_available_expired_time | formatDate }}
              前领取，过期作废
            </template>
            <template
              v-if="
                benefit.amount === 0 &&
                benefit.get_start_time &&
                benefit.get_end_time
              "
            >
              下次权益次数将在{{
                nextDay(benefit.get_start_time, benefit.get_end_time)
              }}日发放
            </template>
          </div>
        </div>
        <div class="flex-right">
          <div class="amount" v-if="benefit.amount > 0">
            可领{{ benefit.amount }}次
          </div>
          <div
            @click="toBenefitDetail(benefit)"
            v-ans="{
              event_id: 'promotion_benefit_click',
              benefit_type: benefit.type,
              card_name: card.card_type,
            }"
            :class="{
              'benefit-btn': true,
              'benefit-btn-disabled': benefit.amount === 0,
            }"
          >
            {{ btnText(benefit) }}
          </div>
        </div>
      </div>
    </van-skeleton>
    <van-skeleton title :row="4" :loading="loading"> </van-skeleton>
  </div>
</template>

<script>
import { getUserBenefit } from "@/apis/home";
import * as dayjs from "dayjs";
import { mapActions, mapState } from "vuex";
import CentificationDialog from "@/components/CentificationDialog";
export default {
  data() {
    return {
      benefits: [],
      card: {},
      showVerifyDialog: false,
      loading: true,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    emptyText() {
      const temp = {
        refund: "您的会员已退款",
        expired: "您的会员已过期",
      };
      return temp[this.card.card_status] || "";
    },
  },
  async mounted() {
    try {
      const res = await getUserBenefit();
      if (res.data.benefit_order) {
        const orders = res.data.benefit_order.split(",");
        res.data.benefit_list.sort((a, b) => {
          return orders.indexOf(a.type) - orders.indexOf(b.type);
        });
      }
      this.card = res.data;
      this.benefits = res.data.benefit_list;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    nextDay(start, end) {
      if (dayjs().isBefore(dayjs(start).startOf("day"))) {
        return dayjs(start).format("YYYY.MM.DD");
      }
      return dayjs(end).add(1, "day").format("YYYY.MM.DD");
    },
    btnText(benefit) {
      if (benefit.type === "keepvip_month") {
        return "查看";
      }
      if (benefit.amount_reserved > 0 && benefit.amount === 0) {
        return "下次再来";
      } else if (benefit.amount_reserved === 0 && benefit.amount === 0) {
        return "已领完";
      } else {
        return "领取";
      }
    },
    async toBenefitDetail(benefit) {
      const userInfo = await this.fetchUserInfo();
      // 只有可以使用的权益再校验实名
      if (!userInfo.is_verified && benefit.amount > 0) {
        await CentificationDialog.show({
          subtitle: benefit.title,
          phone_number: this.userInfo.login_phone_number,
        });
      }
      this.$router.push(`/benefitDetail?benefit_type=${benefit.type}`);
    },
  },
};
</script>

<style lang="less" scoped>
.benefit {
  & + & {
    margin-top: 16px;
  }
  position: relative;
  padding: 18px 8px 18px 18px;
  display: flex;
  height: 136px;
  align-items: center;
  box-sizing: border-box;
  background: url("~@/assets/images/promotion_benefit_bg.png") no-repeat
    center/100%;
}

.flex-i {
  display: flex;
  margin-right: 12px;
}

.total {
  position: absolute;
  right: 0;
  top: 0;
  background: url("~@/assets/images/amount_tag_bg.png") no-repeat left/100%;
  line-height: 14px;
  width: 35px;
  height: 16px;
  text-align: center;
  color: #fff;
  font-size: 10px;
}

.benefit-image {
  width: 43px;
  height: 43px;
  margin-right: 10px;
  border-radius: 6px;
  overflow: hidden;
}

.benefit-title {
  margin-top: 2px;
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  font-weight: bold;
}

.benefit-subtitle {
  font-size: 11px;
  color: #666;
  line-height: 16px;
}

.flex-left {
  display: flex;
  flex-direction: column;
  &.flex-left-h {
    height: 100%;
  }
}
.flex-right {
  margin-left: auto;
  width: 60px;
  text-align: center;
}

.amount {
  margin-bottom: 5px;
  font-size: 11px;
  color: #372938;
  line-height: 12px;
}
.benefit-btn {
  width: 60px;
  height: 26px;
  border-radius: 9999px;
  background: #fff;
  line-height: 26px;
  font-size: 11px;
  color: #372938;
  &.benefit-btn-disabled {
    background: #d2d2d2;
    color: #fff;
  }
}
.flex-b {
  margin-top: auto;
  position: relative;
  line-height: 16px;
  padding: 8px 0;
  font-size: 11px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 167px;
    border-top: 1px dashed #f4d494;
  }
}
</style>