import axios from 'axios';
import { Toast } from 'vant'
import qs from 'qs';
import pickBy from 'lodash.pickby';
import { decrypt, storage } from '.';
import { CryptoJS } from 'jsrsasign';
import { key, secret, getBaseUrl } from '@/config';
import store from '@/store';
import router from '@/router';

const contentType = 'application/x-www-form-urlencoded';

function encrypt(option, method, accept, contentType) {
  var _Headers =
    'X-Ca-Key' + ":" + decrypt(key) + "\n" +
    "X-Ca-Stage" + ":" + "RELEASE" + "\n";
  var paramsData = '';
  var arr = Object.keys(option.data).sort();
  for (var k in arr) {
    if (option.data[arr[k]] || option.data[arr[k]] === 0) {
      paramsData += arr[k] + "=" + option.data[arr[k]] + "&"
    } else {
      paramsData += arr[k] + "&"
    }
  }
  var _Url = '';
  if (paramsData.length) {
    _Url = option.url + "?" + paramsData.substring(0, paramsData.length - 1)
  } else {
    _Url = option.url
  }
  var stringToSign = method.toUpperCase() + "\n" + accept + "\n" + "\n" + (contentType || '') + "\n" + "\n" + _Headers + _Url;
  // console.log(stringToSign.replace(/\n/g, '#'));

  var hmacSha256 = CryptoJS.HmacSHA256(stringToSign, decrypt(secret));
  var hashInBase64 = CryptoJS.enc.Base64.stringify(hmacSha256);
  return { hashInBase64 };
}
const instance = axios.create({
  timeout: 30000,
  headers: {
    'Accept': contentType,
    "X-Ca-Stage": "RELEASE",
    'X-Ca-Key': decrypt(key),
    'X-Ca-Signature-Headers': 'X-Ca-Key,X-Ca-Stage',
  }
});

instance.interceptors.request.use(config => {
  const namespace = sessionStorage.getItem('tenant') || storage.getItem('namespace') || store.state.namespace || '';
  const baseURL = getBaseUrl(namespace);
  config.baseURL = config.baseURL || baseURL;
  const tempData = config.data || {};
  if (config.method === 'post' || config.method === 'put') {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    config.data = qs.stringify(config.data || {});
  }
  const { hashInBase64 } = encrypt({
    url: config.url,
    data: pickBy(Object.assign(tempData, config.params || {}), v => v !== null && v !== undefined),
  }, config.method.toUpperCase(), config.headers.Accept, config.headers['Content-Type']);
  config.headers['X-Ca-Signature'] = hashInBase64;
  let token = storage.getItem('token');
  // token可以通过方法传进来
  token = config.token || token;
  if (token) {
    if (!token.startsWith('JWT')) {
      token = `JWT ${token}`
    }
    config.headers['Authorization'] = token;
  } else {
    config.headers['Authorization'] = '';
  }
  return config;
});


export default (params) => {
  const option = params.data || params.params || {};
  const hideMsg = 'hideMsg' in option;
  if (hideMsg) {
    delete option.hideMsg;
  }
  return instance(params).then(response => {
    if (response.data?.ok === false) {
      return Promise.reject(response.data)
    }
    return response.data;
  }).catch(error => {
    let status = '';
    if (error?.response?.status) {
      status = error.response.status;
    }
    if (status === 403) {
      // 网关签名错误也是返回的403，这里临时用文案来判断
      if (error.response.data?.detail === '身份认证信息未提供。') {
        Toast('登录已过期');
        store.commit('changeState', {
          isMember: false,
          memberInfo: {},
          userInfo: {},
          token: '',
          ptoken: '',
        });
        storage.removeItem('token');
        storage.removeItem('ptoken'); // 主账号token
        const namespace = storage.getItem('namespace');
        if (namespace?.startsWith('toc')) {
          router.replace('/promotion/card')
        }
      } else {
        // 将非登录过期产生的403错误码改成400，避免因一个接口403错误导致清除token
        if (error?.response?.status) {
          error.response.status = 400;
        }
        if (!hideMsg) {
          if (error?.msg) {
            Toast(error.msg || '网络繁忙');
          } else {
            Toast(`网络繁忙${status ? `(${status})` : ''}`)
          }
        }
      }
    } else {
      if (!hideMsg) {
        if (error?.msg) {
          Toast(error.msg || '网络繁忙');
        } else {
          Toast(`网络繁忙${status ? `(${status})` : ''}`)
        }
      }
      try {
        if (typeof error === 'object') {
          console.log(error);
          window.DATAFLUX_RUM && window.DATAFLUX_RUM.addError(JSON.stringify(error));
        }
      } catch (e) {
      }
    }
    try {
      if (typeof error === 'object') {
        window.DATAFLUX_RUM && window.DATAFLUX_RUM.addError(JSON.stringify(error));
      }
    } catch (e) {
    }
    if (status !== 200) {
      try {
        let e = error;
        if (e.response) {
          let { data, config } = e.response;
          const req = {
            url: config.baseURL + config.url,
            headers: config.headers,
          }
          if (config.data) {
            req.data = config.data;
          }
          if (data) {
            config.res = data;
          }
          let s = `REQUEST ${e}
          ${JSON.stringify(req)}`
          window.DATAFLUX_RUM && window.DATAFLUX_RUM.addError(s);
        }
      } catch (e) { }
    }
    return Promise.reject(error);
  });
};


