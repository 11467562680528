
import PagjLayout from '@/layout/PagjLayout';

export default {
  path: '/pagj',
  redirect: '/pagj/home',
  component: PagjLayout,
  children: [{
    path: '/pagj/open',
    name: 'pagjopen',
    component: require('@/views/pagj/open').default,
    meta: {
      title: '见面礼',
      pageName: 'page_visitor',
      pageData: ['namespace'],
      showTabbar: true,
      page: 'page_visitor',
    }
  }, {
    path: '/pagj/card',
    name: 'pagjcard',
    component: require('@/views/pagj/card').default,
    meta: {
      title: '已购会员卡',
      pageName: 'page_my',
      pageData: ['namespace'],
      showTabbar: true,
      page: 'page_my',
    }
  }, {
    path: '/pagj/receive',
    component: require('@/views/pagj/receive').default,
    name: 'pagjreceive',
    meta: {
      title: '见面礼',
      pageName: 'page_friend',
      pageData: ['namespace'],
      page: 'page_friend',

    }
  }, {
    path: '/pagj/member',
    component: require('@/views/pagj/member').default,
    name: 'pagjmember',
    meta: {
      title: '使用权益',
      pageName: 'page_member',
      pageData: ['namespace'],
      page: 'page_member',

    }
  }, {
    path: '/pagj/redirect',
    component: require('@/views/pagj/redirect').default,
    name: 'pagjmemberredirect',
    meta: {
      title: '温馨提示',
      pageName: 'page_redirect',
      pageData: ['namespace'],
      page: 'page_redirect',
    }
  }]
}