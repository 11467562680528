<template>
  <div class="buffet-book" v-if="detail && sku">
    <div class="buffet-header">
      <van-image fit="cover" lazy-load :src="sku.image_list_info[0] || ''" />
      <div class="buffet-info">
        <div class="buffet-hotel-name">
          {{ detail.buffet_merchant_info.name }}
        </div>
        <div class="buffet-room-name">
          {{ detail.name
          }}<span class="buffet-room-tag"
            >{{ detail.defaultTag }}{{ sku.name }}</span
          >
        </div>
        <div class="buffet-room-tips">
          注：提前{{ sku.buffet_price_info.reserve_day }}天{{
            sku.buffet_price_info.reserve_time
          }}点之前预定，提前1天电话取消
        </div>
      </div>
    </div>
    <div class="buffet-book-form">
      <div class="buffet-form-title">预定信息</div>
      <van-form
        ref="form"
        @submit="beforeSubmit"
        @failed="failed"
        :show-error="false"
        :show-error-message="false"
      >
        <van-field
          v-model.trim="user_name"
          name="user_name"
          :maxlength="10"
          label="*预定姓名"
          placeholder="请输入预定姓名"
          :rules="[{ required: true, message: '请输入预定姓名' }]"
        />
        <van-field
          v-model="phone"
          name="phone"
          :maxlength="11"
          label="*预定手机号"
          :disabled="disabledPhone"
          placeholder="请输入预定手机号"
          :rules="[
            {
              required: true,
              message: '请输入预定手机号',
            },
            { pattern: /1[3-9]\d{9}/, message: '请输入正确的手机号' },
          ]"
        />
        <van-field
          readonly
          clickable
          name="date"
          :value="date"
          :rules="[{ required: true, message: '请选择预定日期' }]"
          label="*预定日期"
          placeholder="请选择预定日期"
          @click="showCalendar = true"
        />
        <van-field
          readonly
          clickable
          name="time"
          :value="time"
          label="*预定时间"
          :rules="[{ required: true, message: '请选择预定时间' }]"
          placeholder="请选择预定时间"
          @click="showTimePicker = true"
        />
        <van-cell
          class="attendance"
          title-class="van-field__label"
          title="出席人数"
        >
          <template>
            <div
              style="text-align: left; font-weight: bold; color: #333"
              class="custom-title"
            >
              2人
            </div>
          </template>
        </van-cell>
        <div class="form-tips">
          默认出席人数为2人，如需预订多人用餐，请再下一笔订单
        </div>
        <van-field
          name="comments"
          v-model="comments"
          label="备注"
          placeholder="请输入备注信息"
        />
      </van-form>
    </div>
    <div class="tips">
      <div class="tips-title">温馨提示</div>
      <div class="tips-content" v-html="detail.content" />
    </div>
    <div class="footer" v-if="sku.buffet_price_info">
      <div class="left-info">
        <div>
          ¥<span class="amount">{{ sku.buffet_price_info.member_price }}</span
          ><span class="facevalue"
            >¥{{ sku.buffet_price_info.market_price }}</span
          >
        </div>
        <div class="extra">
          节省{{
            sku.buffet_price_info.market_price
              | decimal(sku.buffet_price_info.member_price, "minus")
          }}元
        </div>
      </div>
      <div
        @click="submitEvent"
        class="right-info"
        v-ans="{
          event_id: 'buffet_book_submit_click',
          namespace,
          buffet_id: $route.query.sku_id,
        }"
      >
        提交订单
      </div>
    </div>
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        type="time"
        :min-hour="sku.start_hour"
        :max-hour="sku.end_hour"
        @confirm="onConfirm"
        @cancel="showTimePicker = false"
      />
    </van-popup>
    <van-calendar
      :formatter="formatter"
      :min-date="minDate"
      :max-date="maxDate"
      v-model="showCalendar"
      @confirm="onCalendarConfirm"
    />
  </div>
</template>

<script>
import { DatetimePicker } from "vant";
import Vue from "vue";
import qs from "qs";
import { mapActions, mapState } from "vuex";
import { getBuffetDetail, getBuffetStoreDetail } from "@/apis/buffet";
import pickBy from "lodash.pickby";

import dayjs from "dayjs";
import { updateUserPhone } from "@/apis/home";

Vue.use(DatetimePicker);

export default {
  data() {
    return {
      pattern: /1[3-9]]d{9}/,
      date: "",
      time: "",
      comments: "",
      showTimePicker: false,
      showCalendar: false,
      user_name: "",
      phone: "",
      detail: null,
      sku: null,
      maxDate: new Date(),
      minDate: new Date(),
      disabledPhone: false,
    };
  },
  computed: {
    ...mapState(["userInfo", "namespace"]),
  },
  async mounted() {
    const [res, store, userInfo] = await Promise.all([
      getBuffetDetail(this.$route.query.sku_id),
      getBuffetStoreDetail(this.$route.query.store_id),
      this.fetchUserInfo(),
    ]);
    res.data.image_list = res.data.image_list_info;
    if (userInfo.user_phone) {
      this.phone = userInfo.user_phone;
      this.disabledPhone = true;
    }
    this.sku = res.data;
    this.sku.start_hour = this.sku.start_time.split(":")[0];
    this.sku.end_hour = this.sku.end_time.split(":")[0];
    const [hour, minute] = this.sku.buffet_price_info.reserve_time.split(":");
    const end = dayjs().hour(hour).minute(minute);
    const dayNum = dayjs().isAfter(end)
      ? this.sku.buffet_price_info.reserve_day + 1
      : this.sku.buffet_price_info.reserve_day;
    this.minDate = new Date(dayjs().add(dayNum, "day"));
    this.maxDate = new Date(dayjs().add(14, "day"));
    this.detail = store.data;
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    formatter(day) {
      const week = day.date.getDay();
      if (day.type !== "disabled") {
        if (this.sku.reserve_week_list.includes(week)) {
          day.disabled = false;
        } else {
          day.type = "disabled";
          day.bottomInfo = "关闭";
        }
      }
      return day;
    },
    onConfirm(value) {
      this.time = value;
      this.showTimePicker = false;
    },
    onCalendarConfirm(date) {
      this.date = dayjs(date).format("YYYY-MM-DD");
      this.showCalendar = false;
    },
    async beforeSubmit({ date, time, ...values }) {
      const d = dayjs(date + " " + time);
      const [hour, minute] = this.sku.buffet_price_info.reserve_time.split(":");
      const lastDateTime = d
        .subtract(this.sku.buffet_price_info.reserve_day, "day")
        .hour(Number(hour))
        .minute(minute)
        .second(0);

      if (dayjs().isAfter(lastDateTime)) {
        this.$toast(
          `需提前${this.sku.buffet_price_info.reserve_day}天${this.sku.buffet_price_info.reserve_time}点之前预定`
        );
        return;
      }

      // 校验预订时间 10:00-12:00
      const startTime = dayjs()
        .hour(this.sku.start_time.split(":")[0])
        .minute(this.sku.start_time.split(":")[1]);
      const endTime = dayjs()
        .hour(this.sku.end_time.split(":")[0])
        .minute(this.sku.end_time.split(":")[1]);
      const currentTime = dayjs()
        .hour(time.split(":")[0])
        .minute(time.split(":")[1]);
      if (currentTime.isBefore(startTime) || currentTime.isAfter(endTime)) {
        this.$toast(
          `预定时间需在${this.sku.start_time}至${this.sku.end_time}之间`
        );
        return;
      }
      if (!this.userInfo.user_phone) {
        this.$confirm({
          title: "温馨提示",
          confirmButtonText: "确认号码",
          message: `<div>确定预订到手机号：<b>${this.phone}</b>吗？<br />此手机号将会用于所有手机号充值的权益，填写后不可修改，请确认是否继续使用？</div>`,
        }).then(() => {
          this.bindPhone({ date, time, ...values });
        });
      } else {
        this.onSubmit({ date, time, ...values });
      }
    },
    async bindPhone(values) {
      const loading = this.$loading();
      try {
        try {
          await updateUserPhone({
            user_phone: this.phone,
            hideMsg: true,
            is_save: "F",
          });
          this.onSubmit(values);
        } catch (e) {
          this.$toast("手机号已被其它账号绑定，不可使用，如有疑问请联系客服");
        }
      } finally {
        loading.clear();
      }
    },
    async onSubmit({ date, time, ...values }) {
      const url = `/pages/pay/pay?${qs.stringify(
        pickBy(
          {
            detail_type: "buffet",
            sku_name: encodeURIComponent(
              this.detail.buffet_merchant_info.name +
                this.detail.name +
                this.sku.name
            ),
            item_code: encodeURIComponent(
              encodeURI(
                `BUFFET${this.sku.buffet_price_info.price_plan_id}#${dayjs(
                  date + " " + time
                ).valueOf()}`
              )
            ),
            item_name: encodeURIComponent("预定自助餐"),
            top_up_account: this.phone,
            account_top_up_type: 1,
            type: "buffet",
            price: this.sku.buffet_price_info.member_price,
            biz_params_info: JSON.stringify({
              ...values,
              sku_id: this.$route.query.sku_id,
              market_price: this.sku.buffet_price_info.market_price,
              merchant_name: this.detail.buffet_merchant_info.name,
              meal_time: dayjs(date + " " + time).valueOf(),
              store_name: this.detail.name,
              buffet_name: this.sku.name,
              attendance: 2,
              city: this.detail.buffet_city_info.name,
            }),
          },
          (v) => v !== undefined
        ),
        { encode: false }
      )}`;
      this.$router.push(url);
    },
    failed({ errors }) {
      this.$toast(errors[0].message);
    },
    submitEvent() {
      this.$refs.form.submit();
    },
  },
};
</script>

<style lang="less" scoped>
.buffet-book {
  padding-bottom: 78px;
}
.buffet-header {
  display: flex;
  padding: 12px;
  background: #fff;
  margin-bottom: 12px;
  .buffet-info {
    display: flex;
    flex-direction: column;
  }
  .van-image {
    flex-basis: 88px;
    height: 88px;
    flex-shrink: 0;
    margin-right: 8px;
    border-radius: 8px;
    overflow: hidden;
  }
  .buffet-hotel-name {
    font-size: 18px;
    color: #333;
    font-weight: bold;
    margin-bottom: 7px;
    line-height: 25px;
  }
  .buffet-room-name {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    line-height: 20px;
  }
  .buffet-room-tips {
    margin-top: auto;
    color: #ff5001;
    line-height: 17px;
    font-size: 12px;
  }
}
.buffet-book-form {
  background: #fff;
  margin-bottom: 12px;
  padding-top: 12px;
  /deep/ .van-field--disabled .van-field__label {
    color: #666;
  }
  /deep/ .van-cell__title {
    font-size: 13px;
  }
  .buffet-form-title {
    font-weight: bold;
    color: #333333;
    line-height: 22px;

    font-size: 16px;
    padding-left: 12px;
  }
  .form-tips {
    padding-top: 8px;
    color: #ff5001;
    padding-left: 19px;
  }
  .peoples {
    padding-bottom: 3px;
    &::after {
      content: none;
    }
  }
}
.tips {
  background: #ffffff;
  padding: 12px;
}
.tips-title {
  color: #666666;
  font-size: 13px;
  margin-bottom: 8px;
  font-weight: bold;
}
.tips-content {
  color: #999999;
  line-height: 1.5;
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 68px;
  background: #ffffff;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
}
.left-info {
  color: #333;
  .amount {
    font-size: 24px;
    font-weight: bold;
    margin-right: 5px;
  }
  .facevalue {
    color: #999;
    text-decoration: line-through;
  }
  .extra {
    color: #ff5001;
    line-height: 17px;
  }
}

.right-info {
  width: 120px;
  height: 49px;
  background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
  border-radius: 25px;
  color: #ffffff;
  line-height: 49px;
  text-align: center;
  font-size: 18px;
}

.buffet-room-tag {
  margin-left: 15px;
  padding: 2px 6px;
  font-size: 11px;
  vertical-align: 2px;
  color: #f1ccb5;
  font-weight: normal;
  background: #222222;
  border-radius: 8px 0px 8px 0px;
}
</style>