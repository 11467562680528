<template>
   <div class='cash-out'>
    <div class='cash-out-main'>
        <div class='cash-out-title'>待提现现金</div>
        <div class='cash-out-price'><span class='cash-out-price-symbol'>¥</span>{{cash_back_success}}</div>
        <div 
            class='cash-out-record' 
            @click="toCashOutRecord"
            v-ans="{
                event_id: 'cash_out_record_button',
                namespace,
            }"
        >
            提现记录
        </div>
    </div>
    <div class='cash-out-form'>
        <div class='cash-out-form-item'>
            <div class="cash-out-form-item-label">真实姓名</div>
            <van-field v-model="name_value" class='cash-out-form-item-input' placeholder='请输入简体汉字' />
        </div>
        <div class='cash-out-form-item'>
            <div class="cash-out-form-item-label">支付宝号</div>
            <van-field v-model="alipay_value" class='cash-out-form-item-input' placeholder='请输入邮箱或手机号格式账号' />
        </div>
    </div>
    <div class='cash-out-price-input'>
        <div class='cash-out-price-input-title'>提现金额</div>
        <div class='cash-out-price-input-wrap'>
            <div>¥</div>
            <van-field v-model="price_value" class='cash-out-price-item-input' placeholder='请输入大于1元的整数' />
        </div>
        <div class='cash-out-price-btn' @click="cashOutHandle">支付宝提现</div>
    </div>
    <div class='cash-out-hint' v-html="activityInfo.special_info.explain"></div>
   </div>
</template>

<script>
import { storage, verifyForm } from '../../utils'
import { getWithdrawalInfo, cashWithdrawal } from '../../apis/cashback'
import { getActivityInfo } from '../../apis'
import { mapState } from "vuex";
export default {
   name: 'cashOut',
   data() {
       return {
           cash_back_success: null,
            name_value: '',
            alipay_value: '',
            price_value: '',
            activityInfo: {special_info: {explain: ''}}
       }
    },
   
    computed: {
        ...mapState(["namespace"]),
    },
  components: {},

  async mounted () {
    const toast = this.$loading();
    try {
        const withdrawa_info = await getWithdrawalInfo();
        this.cash_back_success = withdrawa_info.data.amt_to_be_withdrawn;
        this.getActivity();
    } finally {
        toast.clear();
    }
    
  },

  methods: {
      toCashOutRecord(){
          this.$router.push('/cashOutRecord')
      },
      cashOutHandle (){
        //判断输入的信息是否满足要求
        const { name_value, alipay_value, price_value, cash_back_success } = this;
        const verifyName = verifyForm.verifyName(name_value);
        const verifyAlipay = verifyForm.verifyAlipay(alipay_value);
        const verifyInteger = verifyForm.verifyInteger(price_value);
        if(verifyName){
            this.$toast(verifyName);
            return;
        }
        if(verifyAlipay){
            this.$toast(verifyAlipay);
            return;
        }
        if(verifyInteger){
            this.$toast(verifyInteger);
            return;
        }
        //判断输入的提现金额是否小于可提现金额
        if(Number(cash_back_success) < Number(price_value)){
            this.$toast('提现金额不足');
            return;
        }
        const toast = this.$loading();
        //调用提现接口
        let opts = {
            account: alipay_value,
            name: name_value,
            withdrawal_amt: price_value,
            order_number: `WD${(Math.random()*1000).toFixed(0)}${new Date().getTime()}`
        };
        cashWithdrawal(opts).then(() => {
            getWithdrawalInfo().then(res => {
                this.cash_back_success = res.data.amt_to_be_withdrawn
            });
            toast.clear();
            this.$confirm({
                title: '温馨提示',
                confirmButtonText: '好的',
                message: `你已成功发起提现，请留意支付宝消息，预计24小时内到账`,
                showCancelButton: false,
            })
        }).catch(() => {
            toast.clear();
        })
    },
    getActivity(){
        getActivityInfo({activity_name: `${storage.getItem('namespace')}_meituancashback`}).then(res => {
            this.activityInfo = res.data
        })
    }
  }
}
</script>

<style lang='less' scoped>
    .cash-out{
        padding: 15px;
        background-color: #F5F5F5
    }
    .cash-out-main{
        height: 110px;
        background: #13121B;
        border-radius: 8px;
        position: relative;
        text-align: center;
        color: #FAD0B7;
    }
    .cash-out-title{
        width: 100%;
        height: 18px;
        line-height: 18px;
        font-size: 13px;
        padding-top: 22px;
    }
    .cash-out-price{
        width: 100%;
        line-height: 40px;
        margin-top: 8px;
        font-size: 28px;
        font-weight: bold;
    }
    .cash-out-price-symbol{
        font-size: 12px;
        font-weight: normal;
        padding-right: 4px;
    }
    .cash-out-record{
        position: absolute;
        font-size: 12px;
        right: 10px;
        top: 10px;
    }
    .cash-out-form{
        background-color: #fff;
        border-radius: 8px;
        margin-top: 10px;
        padding: 0 15px;
    }
    .cash-out-form-item{
        height: 54px;
        display: flex;
        line-height: 54px;
        font-size: 14px;
        color: #222;
        border-bottom: 1PX solid #F5F5F5;
        align-items: center;
        .cash-out-form-item-label{
            width: 80px;
        }
    }
    .cash-out-form-item-input{
        font-size: 14px;
    }
    .cash-out-form-item:last-child{
        border-bottom: none;
    }
    .cash-out-form-item-place{
        color: #DCDCDC;
    }
    ::-webkit-input-placeholder{
        color: #DCDCDC;
    }
    :-moz-placeholder{
        color: #DCDCDC;
    }
    ::-moz-placeholder{
        color: #DCDCDC;
    }
    :-ms-input-placeholder{
        color: #DCDCDC;
    }
    .cash-out-price-input{
        background-color: #fff;
        border-radius: 8px;
        margin-top: 10px;
        padding: 15px 10px 20px;
    }
    .cash-out-price-input-title{
        font-size: 12px;
        color: #222;
        padding-left: 5px;
    }
    .cash-out-price-input-wrap{
        display: flex;
        margin: 13px 5px 0;
        font-size: 18px;
        color: #222;
        font-weight: bold;
        align-items: center;
        border-bottom: 1PX solid #F5F5F5;
    }
    .cash-out-price-item-input{
        font-size: 24px;
        font-weight: bold;
    }
    .cash-out-price-btn{
        margin-top: 20px;
        height: 50px;
        font-size: 18px;
        color: #fff;
        border-radius: 50px;
        background-color: #1677FF;
        text-align: center;
        line-height: 50px;
    }
    .cash-out-hint{
        font-size: 12px;
        color: #666;
        line-height: 23px;
        margin-top: 12px;
    }
</style>
