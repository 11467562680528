<template>
  <main class="takeaway">
    <div class="withdrawal card-style">
      <div class="withdrawal-text">待领取现金：</div>
      <div class="withdrawal-amount">
        <small>¥</small>{{ withdrawalInfo.amt_to_be_withdrawn || 0 }}
      </div>
      <div 
        @click="toWithdrawl" 
        class="withdrawal-btn"
        v-ans="{
          event_id: 'takeaway_withdrawal_btn_click',
          namespace,
        }"
      >
        去提现
        <img
          class="arrow-right"
          :src="require('../../assets/images/arrow-right.png')"
        />
      </div>
    </div>
    <div class="takeaway-entry card-style">
      <div class="takeaway-img"></div>
      <div class="xy-takeaway" v-if="namespace === 'xy'">
        <van-image
          @click="meituan"
          class="xy-takeaway-link-img"
          lazy-load
          :src="`https://cdn.xiangshuheika.com/static/activity/xy-takeaway1.png`"
        />
        <van-image
          class="xy-takeaway-link-img"
          lazy-load
          :src="`https://cdn.xiangshuheika.com/static/activity/xy-takeaway2.png`"
        />
      </div>
      <div @click="meituan" class="takeaway-link" v-else>
        <van-image
          class="takeaway-link-img"
          lazy-load
          :src="`https://cdn.xiangshuheika.com/static/activity/meituantakeaway.png`"
        />
      </div>
    </div>
    <div class="card-style">
      <div class="title">怎么拿返现</div>
      <van-image
        class="cashback-answer-img"
        lazy-load
        src="https://cdn.xiangshuheika.com/static/activity/takeawaydesc.png"
      />
    </div>
    <div class="card-style">
      <div class="title">活动规则</div>
      <div class="rule">{{ activityInfo.rules }}</div>
    </div>
  </main>
</template>

<script>
import { getActivityInfo } from "@/apis";
import { mapState } from "vuex";
import { getItemInfo } from "@/apis/home";
import { getWithdrawalInfo } from "@/apis/cashback";
import { getStrFromJsonStr, storage } from "@/utils";
export default {
  data() {
    return {
      activityInfo: {},
      link: "",
      withdrawalInfo: {},
    };
  },
  computed: {
    ...mapState(["namespace", "userInfo"]),
  },
  async mounted() {
    const activity_name = this.$route.query.activityName || this.namespace;
    const res = await getActivityInfo({
      activity_name: `${activity_name}_takeaway`,
    });
    this.activityInfo = res.data;
    const item_code = getStrFromJsonStr(
      this.activityInfo.special_info,
      "item_code"
    );
    if (storage.getItem("token")) {
      const sku = await getItemInfo({
        user_id: this.userInfo.id,
        item_code:
          item_code ||
          (this.namespace.includes("life")
            ? "meituan_delivery_bonus-member_privileges"
            : "meituancashback-red_envelope"),
      });
      this.link = sku.partner_jump_url;
      const withdrawalInfo = await getWithdrawalInfo();
      this.withdrawalInfo = withdrawalInfo.data;
    } else {
      this.$toast("你未登录，请先登录");
    }
  },
  methods: {
    meituan() {
      if (!storage.getItem("token")) {
        this.$toast("你未登录，请先登录");
      } else {
        location.href = this.link;
      }
    },
    toWithdrawl() {
      if (!storage.getItem("token")) {
        this.$toast("你未登录，请先登录");
      } else {
        this.$router.push("/cashBack");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.takeaway {
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #fee1ac;
  padding: 15px 15px 40px 15px;
}
.takeaway-entry {
  margin-top: 20px !important;
}
.takeaway-img {
  height: 37px;
  margin-top: -20px;
  margin-bottom: 15px;
  background: url(../../assets/images/takeaway.png) no-repeat;
  background-position: center;
  background-size: auto 37px;
}
.card-style {
  border-radius: 8px;
  background: #fff;
  padding: 10px;
  & + & {
    margin-top: 10px;
  }
  .title {
    font-size: 14px;
    color: #222;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 10px;
  }
}
.takeaway-link-img {
  height: 172px;
}
.cashback-answer-img {
  height: 68px;
}
.withdrawal {
  padding: 22px 10px;
  display: flex;
  box-sizing: border-box;
  align-items: baseline;
}
.withdrawal-btn {
  width: 82px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  color: #fff;
  background: linear-gradient(180deg, #ff8743 0%, #ff1d43 100%);
  border-radius: 16px;
  margin-left: auto;
}
.withdrawal-text {
  color: #222;
  font-weight: bold;
  font-size: 14px;
}
.withdrawal-amount {
  color: #f63838;
  font-weight: bold;
  font-size: 24px;
  small {
    font-size: 0.5em;
    padding-right: 2px;
  }
}
.rule {
  color: #999;
  line-height: 17px;
  padding-bottom: 10px;
  white-space: pre-wrap;
}
.arrow-right {
  width: 10px;
  height: 10px;
}
.xy-takeaway {
  background-color: #ffedf0;
  border-radius: 8px;
}
</style>