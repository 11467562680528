import Vue from 'vue';
import store from '../../store';
import router from '../../router';
import Login from './LoginDialog';

let instance;

function isInDocument(element) {
  return document.body.contains(element);
}

function initInstance() {
  if (instance) {
    instance.$destroy();
  }

  instance = new (Vue.extend({
    ...Login,
    store,
    router,
  }))({
    el: document.createElement('div'),
    // avoid missing animation when first rendered
    propsData: {
      lazyRender: false,
    },
  });
  document.body.appendChild(instance.$el);

  instance.$on('input', (value) => {
    instance.value = value;
  });
}


function LoginDialog(options) {
  return new Promise((resolve, reject) => {
    if (!instance || !isInDocument(instance.$el)) {
      initInstance();
    }
    Object.assign(instance, LoginDialog.currentOptions, options, {
      resolve,
      reject,
    });
  });
}

LoginDialog.show = (options) =>
  LoginDialog({
    value: true,
    ...options,
  });

LoginDialog.close = () => {
  if (instance) {
    instance.value = false;
  }
};

LoginDialog.currentOptions = {
  value: false,
  close() {
    if (instance) {
      instance.beforeClose?.();
      instance.value = false;
    }
  }
}

export default LoginDialog;