import * as dayjs from 'dayjs';

import store from '@/store';
import request from '@/utils/request';
import { storage } from "@/utils"
import { geetestHOF } from '@/utils'

//获取活动信息
export const getActivityInfo = (params) => {
  return request({
    url: '/api/activity/get_activity_info',
    method: 'get',
    params,
  })
}

//获取用户信息
export const getUserInfo = (params) => {
  return request({
    url: '/api/users/get_single_user_info',
    method: 'get',
    params,
  })
}

//获取租户信息
export const getNamespaceInfo = (params) => {
  return request({
    url: '/api/core/namespace_info',
    method: 'get',
    params,
  })
}

//获取权益信息
export const getBenefitInfo = ({ benefit_type, ...rest }, token) => {
  const { benefit_source, package_name } = store.state;
  if (benefit_source && benefit_source !== 'auto') {
    rest.benefit_source = benefit_source
  }
  if (package_name) {
    rest.package_name = package_name
  }
  return request({
    url: `/api/benefits/${benefit_type}/card_benefit_info`,
    params: rest,
    method: 'get',
    token
  }).then(res => {
    res.data.coupon_days = res.data.coupon_days || 3;
    return res
  })
}

//获取会员信息
export const getMemberInfo = (data) => {
  return request({
    url: `/api/core/memberships`,
    method: 'post',
    data,
  })
}
export const getParkList = (data) => {
  return request({
    url: `/api/core/commercial_area_distance`,
    method: 'post',
    data,
  })
}

export const getUserCoupon = (data) => {
  return request({
    url: `/api/core/user_coupon`,
    method: 'post',
    data,
  })
}
export const getActivityCoupon = (params) => {
  return request({
    url: `/api/activity/user_coupons`,
    method: 'get',
    params,
  })
}
export const getListBenefit = (params) => {
  return request({
    url: `/api/benefits/list_benefit`,
    params,
  })
}
export const getSkuByBenefitType = (data) => {
  return request({
    method: 'post',
    url: `/api/core/sku_info_by_benefit_type`,
    data,
  })
}

// 获取权益Spu图片
export const getNamespaceBenefitImage = (params) => {
  return request({
    method: 'get',
    url: `/api/core/get_namespace_benefit_spu_images`,
    params,
  })
}

// 查询支付订单
export const queryOrder = (params) => {
  return request({
    url: '/api/xpay/query_order',
    method: 'get',
    params,
  })
}
// 查询支付订单
export const getOnceToken = (params) => {
  return request({
    url: '/api/login/consume_once',
    method: 'get',
    params,
  })
}

// 第三方登录
export const loginByExtra = (data) => {
  const apiFunc = () => request({
    url: '/api/login/auth',
    method: 'post',
    data,
  })
  const phone = data.phone_number
  return geetestHOF(apiFunc, 'login', phone)
}


export const getDrawCoupon = () => {
  return request({
    url: `/api/activity/draw_coupon`,
  })
}
export const reciveDrawCoupon = (data) => {
  return request({
    url: `/api/activity/draw_coupon`,
    method: 'post',
    data,
  })
}
// 查询活动酒店
export const getSpecialHotel = (params) => {
  return request({
    url: '/api/hotel/get_special_hotel_info',
    method: 'get',
    params,
  })
}
export const getPlanData = (data) => {
  return request({
    url: `/api/core/plan_data`,
    method: 'post',
    data,
  })
}

// 查询星盛积分
export const getXinshenPoint = (params) => {
  return request({
    url: '/api/core/xingsheng_user_point',
    method: 'get',
    params,
  })
}
export const getOrder = (data) => {
  return request({
    url: `/api/orders/orders_filter`,
    method: 'post',
    data,
  })
}
export const getActivityOrder = (params) => {
  return request({
    url: `/api/core/activity_orders`,
    method: 'get',
    params,
  })
}

export const getOrderDetail = (params) => {
  return request({
    url: `/api/orders/order_detail`,
    method: 'get',
    params
  }).then(res => {
    res.data.coupon_expire_date = dayjs(res.data.coupon_expire_info?.expired_datetime || dayjs(res.data.t_created)
      .add(3, "day")).format("YYYY-MM-DD HH:mm:ss");
    res.data.coupon_enable = res.data.coupon_expire_info?.enable;
    res.data.coupon_days = res.data.coupon_expire_info?.coupon_days || 3;
    return res
  })
}
export const hasMoreOrder = (data) => {
  return request({
    url: `/api/orders/orders_exists`,
    method: 'post',
    data,
  })
}
export const updateOrder = (data) => {
  return request({
    url: `/api/orders/create_or_update_user_order`,
    method: 'post',
    data,
  })
}
// 查询星盛积分
export const airlines = (params) => {
  return request({
    url: '/api/core/airlines',
    method: 'get',
    params,
  })
}

// 下单
export const createPay = (data) => {
  return request({
    url: '/api/core/place_order',
    method: 'post',
    data,
  })
}

// 空号检测
export const checkPhone = (params) => {
  return request({
    url: '/api/login/check_phone',
    method: 'get',
    params,
  })
}


// 查询是否可以预约
export const canAppoint = (params) => {
  return request({
    url: '/api/core/appoint',
    method: 'get',
    params,
  })
}

// 预约体检
export const appoint = (data) => {
  return request({
    url: '/api/core/appoint',
    method: 'post',
    data,
  })
}

// 爱康国宾日历
export const akDate = (params) => {
  return request({
    url: '/api/appointment/date',
    method: 'get',
    params,
  })
}

// 爱康国宾城市及门店
export const akShop = (params) => {
  return request({
    url: '/api/appointment/hospitals',
    method: 'get',
    params,
  })
}

export const getClientConfigList = (params) => {
  return request({
    url: '/api/config_center/client_cfg',
    method: 'get',
    params,
  })
}

// 洁牙查询是否可以预约
export const cleanTeethCanAppoint = (params) => {
  return request({
    url: '/api/appointment/teeth_cleaning_appoint',
    method: 'get',
    params,
  })
}

// 洁牙城市及门店
export const cleanTeethShop = (params) => {
  return request({
    url: '/api/appointment/teeth_cleaning_appoint/service_list',
    method: 'get',
    params,
  })
}

// 洁牙预约
export const submitCleanTeeth = (data) => {
  return request({
    url: '/api/appointment/teeth_cleaning_appoint/user_info',
    method: 'post',
    data,
  })
}


// 洁牙取消订单
export const cleanTeethChangeOrderStatus = (data) => {
  return request({
    url: '/api/appointment/teeth_cleaning_appoint/appointment_change_order_status',
    method: 'post',
    data,
  })
}

// 查询月花卡新套餐权益
export const yhNewBenefit = (params) => {
  return request({
    url: '/api/core/xyh_benefits',
    method: 'get',
    params,
  })
}

//小湃获取卡列表
export const cardList = (params) => {
  return request({
    url: '/api/core/card_list_for_xiao_pai',
    method: 'get',
    token: storage.getItem('ptoken'),
    params,
  })
}

//获取逻辑用户的token
export const logicUserToken = (params) => {
  return request({
    url: '/api/core/logic_user_token',
    method: 'get',
    token: storage.getItem('ptoken'),
    params,
  })
}

// 提交投诉
export const complaint = (data) => {
  return request({
    url: '/api/core/complaint',
    method: 'post',
    data,
  })
}

// 上传图片
export const uploadFlowPicture = (data) => {
  return request({
    url: '/api/core/upload_picture/user_complaint',
    method: 'post',
    data,
  })
}

// 支付
export const placeOrder = (data) => {
  return request({
    url: '/api/core/place_order',
    method: 'post',
    data,
  })
}

// 获取主用户token
export const getMasterToken = () => {
  return request({
    url: '/api/core/get_master_token',
    method: 'get',
  })
}

export const memberOrderJoinActivity = data => {
  return request({
    url: "/api/core/member_order_join_activity",
    method: "post",
    data,
    hideMsg: true
  });
};

// 悦途权益二维码信息
export const yueTuTravelCoupon = params => {
  return request({
    url: "/api/mq/get_lounge_benefit_infos",
    method: "get",
    params
  })
}

// 判断用户是否为灰度用户
export const getGrayScaleLabel = () => request({
  url: "/api/core/get_grayscale_label",
  method: "get"
})