
export default [
  {
    path: '/pages/index/index',
    redirect: '/index'
  },
  {
    path: '/pages/activity/activity',
    redirect: '/activity'
  },
  {
    path: '/pages/redeem/redeem',
    redirect: '/redeem'
  },
  {
    path: '/pages/package_list/package_list',
    redirect: '/gfPckageList'
  },
  {
    path: '/pages/quanmin/index',
    redirect: '/quanmin'
  },
  {
    path: '/pages/keep/index',
    redirect: '/keep'
  },
  {
    path: '/pages/package/package',
    redirect: '/package'
  },
  {
    path: '/pages/customer_service/customer_service',
    redirect: '/service'
  },
  {
    path: '/pages/help/help',
    redirect: '/help',
  },
  {
    path: '/pages/help_list/help_list',
    redirect: '/help',
  }, {
    path: '/pages/centification/centification',
    redirect: '/centification'
  },
  {
    path: '/pages/pay/pay',
    redirect: '/pay',
  }, {
    path: '/pages/benefit_detail/benefit_detail',
    redirect: '/benefitDetail',
  }, {
    path: '/pages/hotelList/hotelList',
    redirect: '/hotel/list',
  },
  {
    path: '/pages/hotel_detail/hotel_detail',
    redirect: '/hotel/detail',
  },
  {
    path: '/pages/order/order',
    redirect: '/order',
  },
  {
    path: '/pages/pay_result/pay_result',
    redirect: '/payResult',
  },
  {
    path: '/pages/onlineOrdering/onlineOrdering',
    redirect: '/onlineOrdering',
  },
  {
    path: '/pages/coupon/coupon',
    redirect: '/coupon',
  },
  {
    path: '/pages/hotel_list/hotel_list',
    redirect: '/hotel/list',
  },
]