<template>
  <div class="room-info">
    <div class="room-checkdate" @click="changeDate">
      <div class="checkin-date">
        {{ hotel.checkin_date | formatDate("MM月DD日") }}
      </div>
      <span>{{ hotel.checkin_date | week }}</span>
      <div class="checkin-night">{{ interNight }}晚</div>
      <div class="checkin-date">
        {{ hotel.checkout_date | formatDate("MM月DD日") }}
      </div>
      <span>{{ hotel.checkout_date | week }}</span>

      <div class="room-price-tag">
        <svg-icon class="hotelpricetop" iconClass="hotelpricetop" />已含税总价
      </div>
    </div>
    <div class="rooms">
      <div class="room-item" v-for="item in detail.rooms" :key="item.id">
        <van-image
          lazy-load
          @click="previewImage(item.images[0])"
          class="room-image"
          :src="item.images[0]"
          fit="cover"
        />
        <div class="room-desc">
          <div class="room-name">{{ item.name }}</div>
          <div class="room-tag">{{ item.bed_type }}</div>
          <div class="room-tag">
            <span :class="item.prices[0].breakfast">{{
              item.prices[0].breakfast
            }}</span>
            {{
              detail.pay_method === 1 ? "不可取消" : "入住前1天16点前可免费取消"
            }}
          </div>
          <div class="room-price">
            <div class="room-price-info">
              <div class="room-facevalue" v-if="item.prices[0].origin_price">
                ¥{{ item.prices[0].origin_price }}
              </div>
              <div class="price">
                <template v-if="item.prices[0].origin_price">会员价</template>
                ¥<span>{{ item.prices[0].price }}</span>
              </div>
            </div>

            <van-image
              class="room-btn"
              fit="cover"
              v-ans="{
                event_id: 'hotel_detail_click',
                destination_name: hotel.city,
                checkin_date: dayjs(detail.checkin_date).format('YYYY-MM-DD'),
                company_id: detail.company[0].id,
                company_name: detail.company[0].name,
                hotel_type: detail.classification,
                hotel_id: detail.id,
                hotel_name: detail.name,
                rooms_id: item.prices[0].id,
                rooms_name: item.prices[0].name,
                rooms_price: item.prices[0].price + '',
                is_direct_sign: detail.is_direct_sign,
                is_special: detail.is_special,
                pay_method: detail.pay_method,
              }"
              @click="bookHotel(item)"
              :src="
                require(`@/assets/images/hotel/${
                  item.is_date_unavailable
                    ? 'no-book'
                    : `btn-book${detail.pay_method}`
                }.png`)
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import SelectCalendar from "@/components/SelectCalendar";
import { creatSign, storage } from "@/utils";
import dayjs from "dayjs";
import { getBenefitInfo, getPlanData, getUserInfo } from "@/apis";

export default {
  props: {
    previewImage: Function,
    fetchDetail: {
      type: Function,
      default: () => {},
    },
    getPriceList: {
      type: Function,
      default: () => {},
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dayjs,
      current: 0,
    };
  },
  computed: {
    ...mapState([
      "hotel",
      "return_url",
      "benefitHotelType",
      "namespace",
      "source",
    ]),
    ...mapGetters(["interNight", "namespaceGroup", "maxCheckinDate"]),
  },
  methods: {
    ...mapMutations(["changeHotel"]),
    changeDate() {
      SelectCalendar.show({
        rangeDate: [
          dayjs(this.hotel.checkin_date).startOf("day"),
          dayjs(this.hotel.checkout_date).startOf("day"),
        ],
        type: "hotel",
        minDate: this.hotel.minDate,
        maxDate: this.maxCheckinDate,
        range: this.hotel.hotel_advance_range,
        tips: this.hotel.calendar_choice_tips,
        getPriceList: this.getPriceList,
        single: false,
      }).then((rangeDate) => {
        this.changeHotel({
          checkin_date: rangeDate[0].valueOf(),
          checkout_date: rangeDate[1].valueOf(),
        });
        this.fetchDetail();
      });
    },
    async bookHotel(room) {
      if (room.is_date_unavailable) {
        return;
      }
      // 龙腾未登录，跳转到登录页面
      if (!storage.getItem("token")) {
        this.$toast("你还未登录，请先登录");
        if (this.namespaceGroup === "lt") {
          setTimeout(() => {
            this.$jumpUrl("/pages/index/index");
          }, 1500);
        }
        return;
      }
      // // 经济型酒店增加限制
      // if (this.benefitHotelType === "ehotel") {
      //   const agentId = agentConfig(this.namespace);
      //   if (!agentId) {
      //     this.$toast("agent不存在");
      //     return;
      //   }

      //   const params = {
      //     agent_id: agentId,
      //     plan_name: "ehotel_order_number",
      //     t_start: dayjs().startOf("month").format("YYYY-MM-DD"),
      //     t_end: dayjs().endOf("month").format("YYYY-MM-DD"),
      //     namespace: this.namespace,
      //   };
      //   // 这里的签名参数只需要包装一层字符串
      //   params.sign = creatSign(this.namespace, `${JSON.stringify(params)}`);
      //   const planData = await getPlanData(params);
      //   if (planData.data[0].result === 0) {
      //     this.$toast("来晚了~本月优惠名额已用完，请下月再来");
      //     return;
      //   }
      // }
      const loading = this.$loading();
      try {
        // 查看用户是否会员
        const res = await getUserInfo({ mask: "" });
        const { is_verified, is_member } = res.data;
        if (!this.$getConfigValue("HOTEL_SINGLE_BENEFIT")) {
          if (!is_member) {
            if (this.namespace === "oak") {
              this.$alert({
                title: "您还不是会员",
                message: "开通会员，尊享酒店特权",
                confirmButtonText: "去开通",
              }).then(() => {
                this.$jumpUrl("/pages/index/index", "switchTab");
              });
            } else {
              this.$toast("您还不是会员");
            }
            // setTimeout(() => {
            //   this.$jumpUrl("/pages/package/package");
            // }, 1500);
            return;
          }
          // 是否通过实名认证
          if (!is_verified) {
            this.$router.push("/centification");
            return;
          }
          // 判断用户是否有点数
          const benefit = await getBenefitInfo({
            benefit_type: this.benefitHotelType,
          });
          const { amount_available, amount_reserved } = benefit.data;
          if (!(amount_available + amount_reserved)) {
            this.$toast("您酒店权益次数为0，不可预订");
            return;
          }
          if (!amount_available) {
            this.$toast(
              "您本期已预定过酒店，请下期再来，可在权益页面查看详细规则"
            );
            return;
          }
        } else {
          // 判断是否从桔多多那边跳转进来的
          if (!this.source || this.source === "default") {
            this.$toast("渠道不存在，请退出重新进入");
            return;
          }
          // 是否通过实名认证
          if (!is_verified) {
            this.$router.push("/centification");
            return;
          }
        }
        this.$router.push(
          `/hotel/book?hotelId=${this.detail.id}&roomId=${room.id}`
        );
      } finally {
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.card-info.room-info {
  padding: 0;
}
.room-checkdate {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 20px 0 24px;
  position: relative;

  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 85%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 8px 8px 0 0;
  & + span {
    font-size: 12px;
  }
}
.checkin-night {
  flex-shrink: 0;
  margin: 0 4px;
  line-height: 20px;
  border-radius: 10px;
  padding: 0 7px;
  background: #edf2f8;
}
.room-price-tag {
  line-height: 16px;
  color: #ff5001;
  margin-left: auto;
  font-size: 11px;
}
.hotelpricetop {
  font-size: 11px;
  margin-right: 2px;
}
.checkin-date {
  flex-shrink: 0;
  font-size: 17px;
  position: relative;
  .font-family(PingFangSC-Medium);
}

.rooms {
  padding: 3px 12px 7px;
}
.room-item {
  display: flex;
  background: #fff;
  padding: 12px;
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  border-radius: 6px;
  & + & {
    margin-top: 7px;
  }
}
.room-desc {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.room-name {
  line-height: 22px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 6px;
}

.room-tag {
  line-height: 16px;
  color: #333;
  font-size: 11px;
  & + & {
    margin-top: 5px;
  }
  span {
    border-radius: 4px 0px 4px 0px;
    border: 1px solid currentColor;
    font-size: 10px;
    color: #ff5001;
    margin-right: 2px;
    padding: 0 2px;
  }
}
.room-facevalue {
  line-height: 18px;
  color: #666;
  text-decoration: line-through;
  margin-bottom: 1px;
}
.room-price {
  display: flex;
  margin-top: 8px;
}

.room-price-info {
  margin-left: auto;
  margin-right: 10px;
  text-align: right;
  align-self: flex-end;
  .price {
    color: #ff5001;
    span {
      font-size: 24px;
      .font-family(PingFangSC-Semibold);
    }
  }
}
.room-image {
  flex-basis: 80px;
  height: 110px;
  margin-right: 8px;
  border-radius: 2px 2px 10px 2px;
  overflow: hidden;
}
.room-btn {
  width: 44px;
  height: auto;
  align-self: flex-end;
  /deep/ img {
    height: auto;
  }
}
</style>
