<template>
  <section class="hr-conpon" v-if="!fecting">
    <div class="tab-header">
      <div
        :class="{ 'tab-item': true, active: index === active }"
        v-for="(item, index) in products"
        :key="item.id"
        @click="active = index"
      >
        <div class="tab-item-name">{{ item.desc }}</div>
        <div class="tab-item-desc">
          {{ item.amountInfo.total_amount }}/月<span
            >剩余{{
              item.amountInfo.total_amount - item.amountInfo.used_amount
            }}元</span
          >
        </div>
      </div>
      <div :class="{ k1: true, active: active === 0 }"></div>
      <div :class="{ k2: true, active: active === 1 }"></div>
    </div>
    <ul class="tab-body">
      <li
        v-for="sku in spu.skus"
        :key="sku.id"
        :class="{ disabled: sku.stock.daily_stocks === 0 }"
      >
        <div class="facevalue">
          ¥<span>{{ sku.face_value }}</span>
        </div>
        <div class="desc">{{ getDescFromItemCode(sku.item_code) }}</div>
        <div class="subdesc ellipsis">全品类/除特殊商品</div>
        <div class="progress">
          <div class="progress-inner" :style="`width:${sku.width}%`">
            {{ sku.width }}%
          </div>
        </div>
        <div
          :class="{ receive: true }"
          v-ans="{
            event_id: 'benefit_list_click',
            spu_id: sku.id,
            spu_name: sku.item_name,
          }"
          @click="receive(sku, spu)"
        >
          领取
        </div>
      </li>
    </ul>
    <div class="parking" v-if="park" @click="jump(park.benefit)">
      <van-image
        class="parking-image"
        v-ans="{
          event_id: 'benefit_list_click',
          spu_id: park.id,
          spu_name: park.name,
        }"
        :src="park.display_img_url"
      />
      <div
        :class="['benefit-spu-btn', isDisabled(park.benefit) ? 'disabled' : '']"
      >
        {{ getBenefitText(park.benefit) }}
      </div>
    </div>
  </section>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import {
  activateBenefit,
  getUserBenefitAmountInfo,
  querySkuStock,
} from "@/apis/home";
import { getSkuByBenefitType } from "@/apis";
import { useRouter, useStore } from "@/components/use/base";
import { getDockUrl, viproom_list } from "@/config";
import pickBy from "lodash.pickby";
import { storage } from "@/utils";
import Vue from "vue";
const vm = Vue.prototype;
const getWidth = (stock) => {
  if (!stock || !Object.keys(stock).length || !stock.daily_fixed_stocks) {
    return 0;
  }
  const w = Number(
    (
      ((stock.daily_fixed_stocks - stock.daily_stocks) /
        stock.daily_fixed_stocks) *
      100
    ).toFixed(0)
  );
  const w1 = 100 - w;
  return w1 < 20 ? 20 : w1;
};

const getDescFromItemCode = (item_code) => {
  const match = item_code.match(/(\d+),(\d+)/);
  if (!match) {
    return;
  }
  return `满${match[1]}减${match[2]}`;
};

export default defineComponent({
  inheritAttrs: false,
  props: {
    jump: Function,
    getBenefitText: Function,
    isDisabled: Function,
    list: Array,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const products = ref(props.list.slice(0, 2));
    const park = ref(props.list[2]);
    const active = ref(0);
    const fecting = ref(true);
    const spu = computed(() => products.value[active.value]);
    const querySkus = async () => {
      const promises = products.value.map((item) =>
        getSkuByBenefitType({
          benefit_type: item.product_code,
          source: store.state.source,
          namespace: store.state.namespace,
        })
      );
      const promises1 = products.value.map((item) =>
        getUserBenefitAmountInfo({ benefit_type: item.product_code })
      );
      const amountInfo = await Promise.all(promises1);
      const res = await Promise.all(promises);
      const item_codes = res
        .reduce((a, b) => a.concat(...b.data.map((r) => r.item_code)), [])
        .join("~");
      const stock = await querySkuStock({
        item_code: item_codes,
        user_id: store.state.userInfo.id,
        project_url: encodeURIComponent(getDockUrl(store.state.namespace)),
      });
      res.forEach((item, index) => {
        item.data.forEach((s) => {
          s.stock =
            stock.data.find((r) => r.item_code === s.item_code)?.data || {};
          s.width = getWidth(s.stock);
        });
        products.value[index].amountInfo = amountInfo[index].data;
        products.value[index].skus = item.data;
      });
      products.value = [...products.value];
      fecting.value = false;
    };
    const receive = async (sku, spu) => {
      if (
        sku.stock.daily_stocks <= 0 ||
        sku.stock.partner_stocks <= 0 ||
        sku.stock.saleable_stocks <= 0 ||
        sku.stock.user_daily_stocks <= 0
      ) {
        vm.$toast("库存不足，请稍后再试");
        return;
      }
      if (
        spu.amountInfo.total_amount - spu.amountInfo.used_amount <
        sku.face_value
      ) {
        vm.$toast("当前额度不足");
        return;
      }
      const params = pickBy(
        {
          benefit_type: sku.product_code,
          benefit_source: "会员权益",
          is_zero_pay: true,
          order_name: sku.item_name,
          item_code: sku.item_code,
          top_up_type: sku.top_up_type,
          detail_type: viproom_list.includes(sku.product_code)
            ? undefined
            : sku.item_name,
          activation_platform: sku.activation_platform,
          source: store.state.source,
        },
        (v) => v !== undefined
      );
      // 华润通停车场激活参数
      if (params.benefit_type === "freeparking") {
        const CR_PARAMS = storage.getItem("CR_PARAMS");
        if (CR_PARAMS) {
          try {
            const obj = JSON.parse(CR_PARAMS);
            params.third_party_params = JSON.stringify({
              token: obj.token,
              device_param: obj.device,
            });
          } catch (e) {}
        }
      }
      const toast = vm.$loading();
      try {
        const res = await activateBenefit(params);
        // 更新库存
        await querySkus();
        vm.$alert({
          message: `您已成功领取${sku.item_name}，可在我的订单中查看使用`,
          confirmButtonText: "去查看",
        }).then(() => {
          router.push(`/orderDetail?order_number=${res.data.order_number}`);
        });
      } finally {
        toast.clear();
      }
    };

    onMounted(async () => {
      if (props.list.length >= 3) {
        querySkus();
      }
    });
    return {
      active,
      products,
      spu,
      receive,
      fecting,
      getDescFromItemCode,
      park,
    };
  },
});
</script>

<style lang="less" scoped>
.tab-header {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.tab-item {
  flex: 1;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 3;
  color: #8c4d28;
}
.tab-item-name {
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 14px;
}

.tab-item-name,
.tab-item-desc {
  line-height: 16px;
  span {
    margin-left: 12px;
  }
}

.k1,
.k2 {
  position: absolute;
  top: 0;
  width: 178px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
}
.k1 {
  left: 0;
  background-image: url("../../../assets/images/hr_coupon_tab_default2.png");
  &.active {
    background-image: url("../../../assets/images/hr_coupon_tab_active2.png");
    z-index: 2;
  }
}
.k2 {
  right: 0;
  background-image: url("../../../assets/images/hr_coupon_tab_default1.png");
  &.active {
    background-image: url("../../../assets/images/hr_coupon_tab_active1.png");
    z-index: 2;
  }
}
.tab-body {
  background: #fcdba7;
  padding: 8px 0 8px 8px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  li {
    width: 92px;
    height: 123px;
    flex-shrink: 0;
    margin-right: 8px;
    background-image: url("../../../assets/images/hr_coupon_bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    padding-top: 16px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    &:nth-child(1),
    &:nth-child(2) {
      &::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 33px;
        height: 17px;
        line-height: 17px;
        text-align: center;
        content: "HOT";
        border-radius: 8px 0px 8px 0px;
        color: #fff9f1;
        font-weight: bold;
        background: #ff5001;
      }
    }
    &.disabled {
      .receive {
        pointer-events: none;
        font-weight: normal;
        color: #999;
      }
      background-image: url("../../../assets/images/hr_coupon_bg_disabled.png");
    }
  }
  .facevalue {
    line-height: 28px;
    font-weight: bold;
    span {
      font-size: 20px;
      margin-left: 5px;
    }
  }
  .desc {
    line-height: 16px;
    color: #000000;
  }
  .subdesc {
    line-height: 14px;
    color: #666;
    margin-bottom: 5px;
    font-size: 10px;
  }
  .progress {
    width: 80px;
    margin: 0 auto 10px;
    height: 12px;
    border-radius: 6px;
    background: rgba(#ff5001, 0.33);
    position: relative;
    overflow: hidden;
  }
  .progress-inner {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    text-align: center;
    color: #fff;
    background: linear-gradient(270deg, #ff8802 0%, #ff5001 100%);
    transition: all 0.3s;
  }
  .receive {
    line-height: 17px;
    color: #fff;
    font-weight: bold;
  }
}

.parking {
  height: 80px;
  margin-top: 18px;
  border-radius: 8px;
  position: relative;
  .parking-image {
    height: 100%;
    width: 100%;
  }
}
</style>