<template>
  <div class="receive-vip">
    <div class="main-title">查询到您有以下可领取的爱奇艺会员</div>
    <div class="card-list">
      <div class="card-item gold">
        <span class="vip-name"><i class="icon"></i>{{ cardType }}VIP会员</span>
        <span class="vip-days">可领取：{{ cardDays }}天</span>
      </div>
    </div>
    <div class="sms-panel">
      <div class="input-wrap phone">
        <span class="info-title">中国大陆 (+86)</span>
        <input
          maxlength="11"
          autocomplete="off"
          type="tel"
          disabled
          :value="phone"
          placeholder="请输入手机号"
          class="input"
        />
        <!---->
      </div>
      <div class="input-wrap sms-code">
        <span class="info-title">验证码</span>
        <input
          type="tel"
          maxlength="6"
          autocomplete="off"
          placeholder="请输入验证码"
          class="input"
          v-ans="{ event_id: 'receive_iqy_sms_code_click' }"
          v-model="code"
        />
        <!---->
        <span
          @click="sendSmsCode"
          :class="['sms-code-btn', count > 0 && 'disable']"
          >{{ count > 0 ? `${count}秒` : "获取验证码" }}</span
        >
        <!---->
      </div>
    </div>
    <van-button
      round
      block
      @click="submit"
      v-ans="{ event_id: 'receive_iqy_activation_btn_click' }"
      :disabled="disabled"
      :class="['receive-btn', disabled && 'disable']"
      >立即领取</van-button
    >
    <div class="rules-panel">
      <p><van-icon name="info" />会员领取规则</p>
      <p>
        1、如您的手机号未注册或未绑定爱奇艺账号，领取会员后将自动为您注册爱奇艺账号，您可直接登录爱奇艺相关平台，享受会员权益。
      </p>
      <p>2.充值手机号为下单时填写的手机号，不可更改。</p>
    </div>
  </div>
</template>

<script>
import jsonp from "jsonp";
import qs from "qs";
import { benefitIQYBenefit } from "@/apis/product";
export default {
  data() {
    return {
      hasSendSms: false,
      count: 0,
      code: "",
      cardType: "",
      cardDays: 0,
      phone: this.$route.query.mobile || "",
      coupon_valid_time:
        Number(this.$route.query.coupon_valid_time || "") * 1000,
      isActivate: false,
    };
  },
  computed: {
    disabled() {
      return this.code.length !== 6 || !this.hasSendSms;
    },
  },
  mounted() {
    const IQI_RECEIVE_VIP_URL = this.$getConfigValue("IQI_RECEIVE_VIP_URL");
    if (IQI_RECEIVE_VIP_URL) {
      const params = {
        gold: this.$route.query.gold,
        diamond: this.$route.query.diamond,
        token: this.$route.query.token,
        pagetype: this.$route.query.pagetype,
      };
      location.replace(
        IQI_RECEIVE_VIP_URL + qs.stringify(params, { addQueryPrefix: true })
      );
      return;
    }
    const gold = this.$route.query.gold;
    const diamond = this.$route.query.diamond;
    this.cardDays = gold || diamond;
    this.cardType = gold ? "黄金" : "星钻";
    if (this.coupon_valid_time < Date.now()) {
      this.$alert({
        title: "温馨提示",
        message: "当前链接已过期，请重新访问",
      }).then(() => {
        history.back();
      });
    }
  },
  methods: {
    async submit() {
      if (this.isActivate) {
        this.$toast("您已成功激活会员，无需重复激活");
        return;
      }
      if (this.coupon_valid_time < Date.now()) {
        this.$alert({
          title: "温馨提示",
          message: "当前链接已过期，请退出订单页面重新进入",
        }).then(() => {
          history.back();
        });
        return;
      }
      const loading = this.$loading();
      try {
        await benefitIQYBenefit({
          token: this.$route.query.token,
          mobile: this.phone,
          order_number: this.$route.query.order_number,
          sms_code: this.code,
        });
        this.$alert({
          title: "充值成功",
          message: `会员权益已充至账户${this.phone}中`,
        }).then(() => {
          history.back();
        });
      } finally {
        this.code = "";
        loading.clear();
      }
    },
    sendSmsCode() {
      if (this.count > 0) {
        return;
      }
      const callback = "iqy_callback";
      const loading = this.$loading();
      jsonp(
        `https://openapi.vip.ptqy.gitv.tv/sms/sendSmsCode.action?mobile=${this.phone}`,
        { name: callback },
        (err, res) => {
          loading.clear();
          if (err) {
            this.$toast("网络请求失败，请稍后再试");
            return;
          }
          if (res.code === "A00000") {
            if (res.data.code === "A00000") {
              this.hasSendSms = true;
              this.count = 60;
              clearInterval(this.timer);
              this.timer = setInterval(() => {
                if (this.count <= 0) {
                  clearInterval(this.timer);
                } else {
                  this.count--;
                }
              }, 1000);
            } else {
              this.$toast(res.data.msg);
              // 获取短信验证码接口失败
              window.DATAFLUX_RUM &&
                window.DATAFLUX_RUM.addError(JSON.stringify(res));
            }
          } else {
            this.$toast("获取验证码失败，请联系客服");
            // 获取短信验证码接口失败
            window.DATAFLUX_RUM &&
              window.DATAFLUX_RUM.addError(JSON.stringify(res));
          }
        }
      );
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.receive-vip {
  background: #fff;
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: 30px 16px;
}

.receive-vip .main-title {
  color: #333;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
  font-weight: 700;
}

.receive-vip .sub-title {
  color: #999;
  font-size: 12px;
  margin-bottom: 20px;
  line-height: 16px;
}

.receive-vip .card-list {
  width: 100%;
  margin-bottom: 30px;
}

.receive-vip .card-list .card-item {
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 20px;
}

.receive-vip .card-list .card-item .icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 6px;
}

.receive-vip .card-list .card-item .vip-name {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}

.receive-vip .card-list .card-item .vip-days {
  font-size: 14px;
}

.receive-vip .card-list .card-item.gold {
  color: #732c12;
  background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20240314a352cf5691013.png);
  background-size: contain;
}

.receive-vip .card-list .card-item.gold .icon {
  background: url(https://common-h5-dev.xiangshuheika.com/static/upload/202403147867f15ef717f.png);
  background-size: contain;
}

.receive-vip .sms-panel {
  width: 100%;
  font-size: 16px;
}

.receive-vip .sms-panel .input-wrap {
  position: relative;
  width: 343px;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid #e3e5e8;
}

.receive-vip .sms-panel .input-wrap .info-title {
  display: inline-block;
  color: #333;
  width: 129px;
}

.receive-vip .sms-panel .input-wrap input {
  border: 0;
  outline: 0;
  font-size: 16px;
  background: transparent;
}

.receive-vip .sms-panel .input-wrap .sms-code-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: inline-block;
  width: 80px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid #0ccc4c;
  color: #0ccc4c;
  font-size: 12px;
}

.receive-vip .sms-panel .input-wrap .sms-code-btn.disable {
  opacity: 0.4;
}

.receive-vip .sms-panel .input-wrap .count-down-tip {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: #999;
  font-size: 12px;
}

.receive-vip .sms-panel .input-wrap.phone input {
  width: 189px;
}

.receive-vip .sms-panel .input-wrap.sms-code input {
  width: 100px;
}

.receive-vip .receive-btn {
  font-size: 16px;
  color: #fff;
  border-radius: 100px;
  width: 343px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 36px;
  background: linear-gradient(#0ccc7f, #0ccc4c);
}

.receive-vip .receive-btn.disable {
  opacity: 0.4;
}

.receive-vip .rules-panel {
  padding: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #999;
  background: #f6f7f9;
}

.receive-vip .rules-panel p {
  margin-top: 12px;
}
</style>