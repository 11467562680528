<template>
  <div v-if="pageshow">
    <ul class="refund-list-title">
      <li
        v-for="(item, index) in title"
        :key="item.type"
        :class="selected_index === index && 'active'"
        @click="changeStatus(index)"
        v-ans="{
          event_id: 'refund_list_status_click',
          namespace,
          refund_status: item.type
        }"
      >
        {{ item.name }}
      </li>
    </ul>
    <div class="refund-list" v-if="refund_list.length > 0 && show_list">
      <div
        v-for="(item, index) in refund_list"
        :key="item.t_created"
        class="refund-item"
      >
        <ul>
          <li v-if="title[selected_index].type === 'waiting'">
            <span>提交时间</span>{{ item.t_submit }}
          </li>
          <li
            v-if="
              title[selected_index].type === 'agreed' ||
              title[selected_index].type === 'rejected'
            "
          >
            <span>审核时间</span>{{ item.t_audited }}
          </li>
          <li v-if="title[selected_index].type === 'waiting'">
            <span>预计审核时间</span>{{ item.t_expect }}前
          </li>
          <li v-if="title[selected_index].type === 'waiting'">
            <span>预计退款金额</span>¥{{ item.estimated_refund_amount }}
          </li>
          <li v-if="title[selected_index].type === 'agreed'">
            <span>实际退款金额</span>¥{{ item.actual_refund_amount }}
          </li>
          <li>
            <span>会员周期</span>{{ item.t_start }}至{{ item.t_expiration }}
          </li>
          <li><span>订单号</span>{{ item.order_number }}</li>
        </ul>
        <div 
          class="refund-item-btn" 
          @click="toDetail(index)"
          v-ans="{
            event_id: 'refund_list_jump_detail_click',
            namespace,
            refund_status: title[selected_index].type,
            order_number: refund_list[index].order_number,
          }"
        >
          查看详情 >
        </div>
      </div>
    </div>
    <div class="empty-order" v-if="refund_list.length === 0">
      <img :src="require('../../assets/images/no-order.png')" alt="" />
      <p>当前没有该分类的订单</p>
      <span 
        class="refund-btn"
        v-if="selected_index === 0"
        @click="toRefundForm"
        v-ans="{
          event_id: 'refund_list_apply_click',
          namespace,
          order_number,
        }"
      >
        发起申请
      </span>
    </div>
    <!-- <div
      class="refund-btn"
      v-if="refund_list.length === 0 && selected_index === 0"
    >
      <span @click="toRefundForm">发起申请</span>
    </div> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { storage } from "@/utils";
import { getrefundList, getRefundOrder } from "../../apis/refund";
import { formatTime } from "../../utils";
export default {
  name: "refundList",
  data() {
    return {
      pageshow: false,
      selected_index: 0,
      title: [
        { name: "审核中", type: "waiting" },
        { name: "已同意", type: "agreed" },
        { name: "已拒绝", type: "rejected" },
      ],
      refund_list: [],
      showBtn: false,
      order_number: "", //可退款订单的订单号
      show_list: false,
    };
  },
  components: {},
  computed: {
    ...mapState(["namespace"]),
  },
  async mounted() {
    const { path, return_url } = this.$route.query;
    if (path && return_url) {
      sessionStorage.setItem("path", path);
      sessionStorage.setItem("return_url", return_url);
    }
    const toast = this.$loading();
    try {
      this.getrefund(this.title[0].type);
      //查询是否有可申请退款的订单
      const res = await getRefundOrder();
      this.changeState({ partner_data: res.data.partner_data });
      this.changeState({ refund_unique_id: res.data.unique_id });
      sessionStorage.setItem(
        "partner_data",
        JSON.stringify(res.data.partner_data)
      );
      sessionStorage.setItem("refund_unique_id", res.data.unique_id);
      if (res.data.is_exist_refund_order) {
        this.order_number = res.data.order_number;
      }
      this.pageshow = true;
    } finally {
      toast.clear();
    }
  },
  methods: {
    ...mapMutations(["changeState"]),
    toDetail(index) {
      let obj = {
        ...this.refund_list[index],
        refund_status: this.title[this.selected_index].type,
      };
      this.changeState({ refund_detail: obj });
      storage.setItem("refund_detail", JSON.stringify(obj));
      this.$router.push(
        `/refundDetail?behavior=view&order_number=${this.refund_list[index].order_number}`
      );
    },
    //申请按钮事件
    toRefundForm() {
      if (this.order_number) {
        //有可退款订单时，判断合作方是否可退款
        const partner_data = JSON.parse(sessionStorage.getItem("partner_data"));
        const img = new Image();
        img.src = `https://cdn.xiangshuheika.com/static/${this.namespace}/offer1.png`;
        img.onload = () => {
          this.$router.push(`/refundApply?order_number=${this.order_number}`);
        };
        img.onerror = () => {
          // 小赢VIP会员显示退款挽留
          if (partner_data.is_retain) {
            this.$router.push(
              `/refundApply?order_number=${this.order_number}&retain=once`
            );
          } else {
            this.$router.push(`/refundForm?order_number=${this.order_number}`);
          }
        };
      } else {
        this.$toast("你当前暂无可申请退款的订单");
      }
    },
    //切换状态，更新列表
    async changeStatus(index) {
      this.show_list = false;
      const toast = this.$loading();
      try {
        await this.getrefund(this.title[index].type);
        this.selected_index = index;
      } finally {
        toast.clear();
      }
    },
    //获取退款列表
    async getrefund(type) {
      const res = await getrefundList({ audit_status: type });
      this.refund_list = res.data.data_list;
      this.refund_list.forEach((v) => {
        v.t_submit = formatTime(v.t_created, true);
        v.t_audited = v.t_audited ? formatTime(v.t_audited, true) : "";
        v.t_expect = formatTime(
          v.t_created +
            Number(this.$getConfigValue("REFUND_DAY")) * 24 * 60 * 60 * 1000,
          true
        );
      });
      this.show_list = true;
      this.showBtn = true;
    },
  },
};
</script>

<style lang='less' scoped>
.refund-list-title {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 11;
}
.refund-list-title li {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color: #999;
  padding: 0 15px;
  position: relative;
}
.refund-list-title li.active {
  font-size: 16px;
  color: #333;
}
.refund-list-title li.active::after {
  content: "";
  position: absolute;
  width: 48px;
  height: 2px;
  background-color: #ff5f4b;
  left: 15px;
  bottom: 0;
}
.refund-list {
  margin-top: 50px;
}
.refund-item {
  margin-bottom: 10px;
  background-color: #fff;
  padding: 15px;
  font-size: 14px;
}
.refund-item ul li {
  line-height: 20px;
  margin-bottom: 12px;
  display: flex;
}
.refund-item ul li span {
  width: 110px;
}
.refund-item-btn {
  font-size: 12px;
  color: #999;
  text-align: right;
}
.empty-order {
  margin-top: 136px;
  text-align: center;
  font-size: 14px;
  color: #333;
}
.empty-order img {
  width: 100px;
  margin-bottom: 20px;
}

.refund-btn {
  display: block;
  height: 49px;
  line-height: 49px;
  text-align: center;
  background-color: #222;
  border-radius: 25px;
  font-size: 18px;
  color: #ffd092;
  font-weight: bold;
  margin: 30px 20px;
}
</style>
