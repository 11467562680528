<template>
  <main class="qr-code" :style="`background-color: ${theme_color}`">
    <van-empty v-if="showEmpty" description="券码不存在，请联系客服" />
    <template v-if="!loading && !showEmpty">
      <div class="showcode">
        <div class="showcode-main">
          <van-image
            lazy-load
            class="showcode-logo"
            fit="cover"
            :src="logo_url"
          />
          <div class="showcode-img">
            <qrcode-vue
              v-if="
                coupon_format === 'qr_code' || coupon_format === 'qr_bar_code'
              "
              :value="coupon_code + ''"
              :size="180"
              level="H"
            />
            <barcode
              v-if="
                coupon_format === 'bar_code' || coupon_format === 'qr_bar_code'
              "
              :value="coupon_code + ''"
              :width="1"
            >
              条形码生成失败，请联系客服
            </barcode>
          </div>
          <div
            class="refresh"
            v-if="item_code === 'ADDOIL'"
            @click="refresh"
            v-ans="{
              event_id: 'code_refresh_button',
              namespace,
            }"
          >
            <img
              src="https://cdn.xiangshuheika.com/static/oil/refresh.png"
              alt=""
            />
            刷新
          </div>
          <div
            class="oil-expire-time"
            v-if="item_code === 'ADDOIL' && expire_time"
          >
            核销码过期时间为{{ expire_time }}，过期后请重新刷新
          </div>
          <div v-else>
            <div class="showcode-name">{{ info.item_name }}</div>
            <div class="showcode-code">{{ coupon_code }}</div>
          </div>
          <div class="showcode-tips" :style="`color: ${theme_color}`">
            <span v-if="item_code === 'ADDOIL'">
              {{ $getConfigValue("SHENGXIN_OIL_TIPS") }}
            </span>
            <span v-else>{{ info.tips }}</span>
          </div>
          <div class="showcode-line">
            <span
              class="semicircle"
              :style="`background: ${theme_color}`"
            ></span>
            <span
              class="semicircle2"
              :style="`background: ${theme_color}`"
            ></span>
          </div>
          <div class="showcode-explain">
            <div class="showcode-explain-title">- 使用条款 -</div>
            <div
              class="showcode-explain-content"
              v-replace.day="order.coupon_days"
              v-html="info.instruction"
            ></div>
          </div>
        </div>
      </div>
    </template>
  </main>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import Barcode from "vue-barcode";

import { getStrFromJsonStr } from "@/utils";
import { getItemInfo } from "@/apis/home";
import { getOilVerifyInfo } from "@/apis/oil";
import dayjs from "dayjs";
import { mapState } from "vuex";
import { getOrder, getOrderDetail } from "@/apis";

export default {
  components: {
    QrcodeVue,
    Barcode,
  },
  data() {
    return {
      info: {},
      order: {},
      showEmpty: false,
      coupon_code: "",
      coupon_format: "",
      loading: false,
      item_code: "",
      verify_expire_time: "",
    };
  },
  computed: {
    ...mapState(["namespace"]),
    theme_color() {
      return this.info.attributes?.theme_color;
    },
    logo_url() {
      return getStrFromJsonStr(this.info.special_info, "coupon_logo_url");
    },
    expire_time() {
      if (this.verify_expire_time) {
        return dayjs(this.verify_expire_time).format("YYYY-MM-DD HH:mm:ss");
      }
      return "";
    },
  },
  async mounted() {
    const { item_code, coupon_code, coupon_format } = this.$route.query;
    if (!coupon_code) {
      this.showEmpty = true;
      return;
    }
    this.loading = true;
    const loading = this.$loading();
    try {
      const res = await getItemInfo({ item_code });
      const order = await getOrderDetail({
        order_number: this.$route.query.order_number,
      });
      this.order = order.data;
      this.item_code = item_code;
      this.coupon_code = coupon_code;
      this.coupon_format = coupon_format;
      this.info = res;
      this.info.logo_url = getStrFromJsonStr(
        res.special_info,
        "coupon_logo_url"
      );
      if (item_code === "ADDOIL") {
        this.refresh();
      }
    } finally {
      loading.clear();
      this.loading = false;
    }
  },
  methods: {
    async refresh() {
      const res = await getOilVerifyInfo({
        order_number: this.$route.query.order_number,
      });
      if (res.data.verify_code) {
        this.coupon_code = res.data.verify_code;
        this.verify_expire_time = res.data.verify_expire_time;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.qr-code {
  box-sizing: border-box;
  min-height: 100vh;
  padding: 40px 0;
}
.showcode-main {
  background-color: #fff;
  margin: 0 15px;
  border-radius: 8px;
  position: relative;
  padding: 1px 0;
}
.showcode-logo {
  position: absolute;
  width: 44px;
  height: 44px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}
.showcode-img {
  margin: 45px auto 0;
  text-align: center;
}
.showcode-name {
  text-align: center;
  font-size: @font-md;
  margin-top: 7px;
  font-weight: bold;
  line-height: 22px;
}
.showcode-code {
  text-align: center;
  font-size: @font-xs;
  color: @text-gray-color;
  margin-top: 2px;
  line-height: 20px;
}
.showcode-tips {
  text-align: center;
  margin-top: 10px;
}
.showcode-line {
  border-bottom: 1px dashed #ccc;
  position: relative;
  margin-top: 20px;
}
.semicircle {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}
.semicircle2 {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.showcode-explain {
  margin: 20px;
}
.showcode-explain-title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
.showcode-explain-content {
  color: @text-gray2-color;
  margin-top: 15px;
  white-space: pre-wrap;
  line-height: 1.5;
}
.refresh {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
  color: #666;
  font-size: 12px;
  img {
    width: 12px;
    margin-right: 2px;
  }
}
.oil-expire-time {
  text-align: center;
  color: #b83835;
  margin-top: 15px;
}
</style>