<template>
   <div class='cash-back' v-if="show">
      <div class='cash-back-header'>
      <img class='cash-bg' :src="require('../../assets/images/cash/cash-bg.png')" />
      <div class='cash-back-header-main'>
            <div class='cash-back-header-tit'>待提现现金(元)</div>
            <div class='cash-back-header-cash'>
               <div class='cash-back-header-number'><span class='symbol'>¥ </span>{{cash_back_success}}</div>
               <div 
                  class='cash-out-btn' 
                  @click="toCashOut"
                  v-ans="{
                     event_id: 'cash_back_button',
                     namespace,
                  }"
               >
                  去提现
               </div>
            </div>
            <div class='cash-back-header-list'>
               <div class='cash-back-header-item'>
                  <div>全部返现</div>
                  <div>¥{{cash_back_all}}</div>
               </div>
               <div class='cash-back-header-item'>
                  <div>已返现</div>
                  <div>¥{{cash_back_end}}</div>
               </div>
               <div class='cash-back-header-item'>
                  <div>待返现</div>
                  <div>¥{{cash_back_confirming}}</div>
               </div>
            </div>
      </div>
      </div>
      <div class='cash-back-list'>
         <div class='cash-back-list-status'>
            <div v-for="(item, index) in status" :key="item.type" @click="selectStatus(index)" :class="selected_status === index ? 'cash-back-list-status-item active' : 'cash-back-list-status-item'">{{item.name}}</div>
         </div>
         <div class='cash-back-list-wrap'>
            <div class='cash-back-list-item' v-for="(item, index) in order_list" :key="item.order_name+index">
               <div>
                  <div class='cash-back-list-item-name'>{{item.order_name}}</div>
                  <div class='cash-back-list-item-date'>{{(item.t_payment || '').replace(/-/g, '.')}}</div>
               </div>
               <div class='cash-back-list-item-price'>+¥{{item.user_commission_amt}}</div>
            </div>
            <div class="cash-no-order" v-if="!order_list.length">暂无该分类订单</div>
         </div>
      </div>
   </div>
</template>

<script>
import { getCashbackOrders, getWithdrawalInfo } from '../../apis/cashback'
import { mapState } from "vuex";
export default {
   name: 'cashBack',
   data() {
      return {
         show: false,
         cash_back_success: null,
         cash_back_all: null,
         cash_back_end: null,
         cash_back_confirming: null,
         status: [
         {name:'待返现',type:'confirming'}, 
         {name:'已返现',type:'success'}, 
         {name:'返现失败',type:'failed'}
      ],
      selected_status: 0,
      order_list: []
      }
   },
   computed: {
      ...mapState(["namespace"]),
   },
   components: {},
   mounted () {
      this.getWithdrawal();
      this.getOrderList(this.status[0].type);
   },

   methods: {
      toCashOut(){
         this.$router.push('/cashOut')
      },

      selectStatus(index){
         this.selected_status = index;
         this.getOrderList(this.status[index].type);
      },

      //获取订单信息
      async getOrderList(status){
         const toast = this.$loading();
         try {
            let opts = {};
            status && (opts['status'] = status);
            const order_list = await getCashbackOrders(opts);
            order_list && (this.show = true);
            if(status){
               this.order_list = order_list.data;
            }
         } finally {
            toast.clear();
         }
         
      },
      async getWithdrawal(){
         const withdrawal = await getWithdrawalInfo();
         this.cash_back_end = withdrawal.data.amt_of_cashback;
         this.cash_back_confirming = withdrawal.data.confirmed_cashback;
         this.cash_back_all = withdrawal.data.total_amt_cashback;
         this.cash_back_success = withdrawal.data.amt_to_be_withdrawn;
      }
   }
}
</script>

<style lang="less" scoped>
   .cash-bg{
    width: 100%;
    height: 199px;
   }
   .cash-back-header{
      height: 208px;
      position: relative;
   }
   .cash-back-header-main{
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      padding: 0 25px;
   }
   .cash-back-header-tit{
      color: #FAD0B7;
      font-size: 13px;
      margin-top: 43px;
      padding-left: 5px;
   }
   .cash-back-header-cash{
      overflow: hidden;
      margin-top: 3px;
   }
   .cash-back-header-number{
      line-height: 40px;
      color: #FAD0B7;
      font-size: 28px;
      font-weight: bold;
      float: left;
      padding-left: 4px;
   }
   .cash-back-header-number .symbol{
      font-size: 14px;
      font-weight: normal;
   }
   .cash-out-btn{
      float: right;
      width: 54px;
      height: 21px;
      border-radius: 10px;
      border: 1PX solid #FAD0B7;
      line-height: 21px;
      text-align: center;
      color: #FAD0B7;
      font-size: 13px;
      margin-top: 8px;
   }
   .cash-back-header-list{
      display: flex;
      text-align: center;
      background-color: #444150;
      height: 65px;
      border-radius: 5px;
      color: #FAD0B7;
      font-size: 13px;
      align-items: center;
      margin-top: 8px;
   }
   .cash-back-header-item{
      width: 33.33%;
      line-height: 22px;
   }
   .cash-back-hint{
      padding: 0 15px;
      height: 40px;
      overflow: hidden;
      background-color: #FFF9D0;
      color: #B27A0B;
      font-size: 13px;
      display: flex;
      align-items: center;
      margin-top: 5px;
   }
   .cash-hint{
      width: 14px;
      height: 14px;
      margin-right: 4px;
   }
   .cash-back-list{
      margin: 15px;
      background-color: #fff;
      border-radius: 8px;
      padding: 0 15px;
   }
   .cash-back-list{
      border-bottom: 1PX solid #F5F5F5;
   }
   .cash-back-list-status{
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #999;
      border-bottom: 1PX solid #F5F5F5;
      padding: 18px 15px 0;
   }
   .cash-back-list-status-item{
      text-align: center;
      line-height: 26px;
      position: relative;
   }
   .cash-back-list-status-item.active{
      font-size: 15px;
      font-weight: bold;
      color: #222;
   }
   .cash-back-list-status-item.active::after{
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      bottom: 0;
      background-color: #FAD0B7;
   }
   .cash-back-list-wrap{
      padding-bottom: 28px;
   }
   .cash-back-list-item{
      margin-top: 17px;
      display: flex;
      justify-content: space-between;
   }
   .cash-back-list-item-name{
      width: 225px;
      font-size: 13px;
      color: #333;
      height: 20px;
      line-height: 20px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
   }
   .cash-back-list-item-date{
      font-size: 12px;
      color: #999;
   }
   .cash-back-list-item-price{
      font-size: 13px;
      color: #F63838;
      padding-top: 7px;
   }
   .cash-no-order{
      text-align: center;
      font-size: 14px;
      color: #999;
      margin-top: 20px;
   }
</style>
