<template>
  <div class="dossen" v-if="pageShow">
    <img class="header-img" :src="`${CDN}dossen.png`" alt="" />
    <div class="header-main">
      <div class="dossen-title">
        <img class="dossen-title-bg" :src="`${CDN}title-bg.png`" alt="" />
        <div class="dossen-title-content">
          {{ benefitInfo.title }}
          <div class="dossen-desc">{{ benefitInfo.subtitle }}</div>
        </div>
      </div>
      <div class="dossen-phone">
        手机号：
        <van-field
          v-model="phone_value"
          type="tel"
          class="dossen-input"
          placeholder="请输入本人手机号"
          :disabled="user_phone ? true : false"
        />
      </div>
      <div
        class="dossen-btn"
        @click="getDossen"
        v-ans="{
          event_id: 'dossen_use_button',
          namespace,
          spu_name: benefitInfo.name,
          spu_id: benefitInfo.id,
        }"
      >
        {{ btnText }}
      </div>
    </div>
    <div class="dossen-bennefit">
      <div class="dossen-bennefit-title">权益介绍</div>
      <van-image
        v-if="!benefitInfo.benefit_instruction"
        lazy-load
        :src="benefitInfo.desc_img_url"
        alt=""
      />
      <div
        v-else
        v-html="benefitInfo.benefit_instruction"
        class="rich-text"
      ></div>
      <div class="dossen-bennefit-title">使用指南</div>
      <van-image
        v-if="benefitInfo.manual_img_url"
        lazy-load
        :src="benefitInfo.manual_img_url"
        alt=""
      />
      <div
        v-if="!benefitInfo.manual_img_url && skuInfo.instruction"
        v-html="skuInfo.instruction"
        class="rich-text"
      ></div>
      <div
        v-if="!benefitInfo.manual_img_url && !skuInfo.instruction"
        v-html="skuInfo.spu_instruction"
        class="rich-text"
      ></div>
      <div class="link" @click="toExtraLink">
        {{ benefitInfo.manual_extra_link_name }}
      </div>
    </div>
    <div class="dossen-height"></div>
  </div>
</template>

<script>
import { CDN } from "../../config";
import {
  activateBenefit,
  getSku,
  matchMemberUserInfo,
  updateUserPhone,
} from "@/apis/home";
import { getBenefitInfo } from "@/apis";
import { verifyForm } from "../../utils";
import { mapState } from "vuex";
import ans from "@/utils/ans";
export default {
  name: "dossen",
  data() {
    return {
      CDN,
      pageShow: false,
      user_phone: "",
      phone_value: null,
      benefitInfo: {},
    };
  },
  components: {},
  computed: {
    ...mapState(["userInfo", "namespace", "return_url"]),
    btnText() {
      if (
        this.benefitInfo.amount_reserved + this.benefitInfo.amount_available ===
        0
      ) {
        return "本权益已用完";
      } else if (this.benefitInfo.amount_available === 0) {
        return "本月权益已使用，请下月再来";
      } else {
        return "立即领取";
      }
    },
  },
  async mounted() {
    const toast = this.$loading();
    try {
      const res = await getBenefitInfo({ benefit_type: "dossen" });
      const res3 = await getSku({
        benefit_type: "dossen",
        namespace: this.namespace,
      });
      this.skuInfo = res3.data[0];
      this.benefitInfo = res.data;
      this.user_phone = this.userInfo.user_phone;
      if (this.userInfo.user_phone) {
        this.phone_value = this.userInfo.user_phone;
      }
      //查询用户是否注册过东呈会员
      const res2 = await matchMemberUserInfo({
        benefit_source: "会员权益",
        benefit_type: "dossen",
        phone_number: this.user_phone,
        namespace: this.namespace,
      });
      this.belle_info = res2.data;
      this.pageShow = true;
    } finally {
      toast.clear();
    }
    ans.pageView(this.$route.meta.page, {
      namespace: this.namespace,
      spu_name: this.benefitInfo.name,
      spu_id: this.benefitInfo.id,
    });
  },
  methods: {
    async getDossen() {
      if (this.benefitInfo.amount_available === 0) {
        return;
      }
      try {
        const verifyPhone = verifyForm.verifyPhone(this.phone_value);
        if (verifyPhone) {
          this.$toast(verifyPhone);
          return;
        }
        if (!this.user_phone && this.phone_value) {
          await updateUserPhone({
            user_phone: this.phone_value,
            is_save: "F",
          });
        }
        if (
          this.belle_info.is_member === "1" &&
          this.belle_info.member_info.level_code === "03"
        ) {
          this.$toast("您已有当前会员等级或高于该等级，无需领取");
          return;
        }
        const toast = this.$loading();
        try {
          const res = await activateBenefit({
            behavior: "register_user",
            benefit_type: "dossen",
            benefit_source: "会员权益",
            namespace: this.namespace,
            phone_number: this.phone_value,
            activation_platform: this.skuInfo.activation_platform,
            item_code: this.skuInfo.item_code,
            is_zero_pay: true,
          });
          this.$confirm({
            message: `<div><img class='dossen-icon' src='${CDN}success.png' />领取成功，可享受会员特权</div>`,
            confirmButtonText: "返回首页",
            cancelButtonColor: "#999",
            className: "dossen-dialog",
          }).then(() => {
            //跳转首页
            this.$jumpUrl("/index");
          });
          this.user_phone = this.phone_value;
          const res2 = await getBenefitInfo({ benefit_type: "dossen" });
          this.benefitInfo = res2.data;
        } finally {
          toast.clear();
        }
      } finally {
      }
    },
    toExtraLink() {
      window.location.href = this.benefitInfo.manual_extra_url;
    },
  },
};
</script>

<style lang='less' scoped>
.dossen {
  background-color: #f8f8f8;
}
.header-img {
  width: 100%;
}
.header-main {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(153, 153, 153, 0.1);
  border-radius: 8px;
  margin: 0 10px;
  position: relative;
  z-index: 9;
  margin-top: -89px;
  padding-bottom: 15px;
  overflow: hidden;
}
.dossen-title {
  box-sizing: border-box;
  padding-top: 15px;
  height: 85px;
  padding-left: 10px;
  color: #202437;
  font-size: 20px;
  font-weight: bold;
}
.dossen-desc {
  font-size: 13px;
  color: #666;
  font-weight: normal;
  margin-top: 7px;
  line-height: 18px;
}
.dossen-phone {
  box-sizing: border-box;
  font-size: 15px;
  color: #333;
  margin: 15px 10px 0;
  height: 47px;
  line-height: 47px;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding-left: 10px;
  display: flex;
}
.dossen-input {
  height: 47px;
  border: none;
  background-color: transparent;
  width: 200px;
}
::-webkit-input-placeholder {
  color: #c6c8d0;
}
:-moz-placeholder {
  color: #c6c8d0;
}
::-moz-placeholder {
  color: #c6c8d0;
}
:-ms-input-placeholder {
  color: #c6c8d0;
}
.dossen-footer {
  box-sizing: border-box;
  background-color: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 65px;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  z-index: 11;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.dossen-height {
  height: 30px;
}
.dossen-bennefit {
  background-color: #fff;
  border-radius: 8px;
  margin: 10px 10px 0;
  padding-top: 1px;
  padding-bottom: 16px;
}
.dossen-bennefit-title {
  font-size: 13px;
  color: #202437;
  font-weight: bold;
  margin: 20px 0 12px 10px;
}
.dossen-btn {
  border-radius: 25px;
  background: linear-gradient(90deg, #ffc360 0%, #ffeba6 100%);
  height: 50px;
  line-height: 50px;
  margin: 20px 25px 0;
  color: #853119;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.dossen-title-bg {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.dossen-title-content {
  position: relative;
  z-index: 9;
  line-height: 30px;
}
.dossen-icon {
  display: block;
  width: 60px;
  margin: 0 auto 26px;
}
.dossen-dialog.van-dialog--confirm .van-dialog__confirm {
  background: #fff;
  font-weight: normal;
  color: #a1a3af;
  border: 1px solid #a1a3af;
  font-size: 16px;
}
.dossen-dialog.van-dialog--confirm .van-dialog__cancel {
  background: linear-gradient(90deg, #ffc360 0%, #ffeba6 100%);
  border: none;
  color: #853119 !important;
  font-weight: bold;
  font-size: 16px;
}
.link {
  font-size: 13px;
  color: #4892f0;
  padding: 0 24px;
}
.rich-text {
  font-size: 28px;
  color: #333;
  line-height: 1.6;
  padding: 0 20px;
}
</style>
