import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import { alert, confirm } from '@/utils/confirm';
// import Waterfall from "vue-waterfall2";
import VueClipboard from 'vue-clipboard2';
import { Toast, Row, Col, Icon, RadioGroup, Image, Divider, Checkbox, Overlay, Radio, CellGroup, Dialog, Skeleton, Empty, Cell, Lazyload, Tabs, Tab, Swipe, SwipeItem, Grid, GridItem, Form, Field, Button, Loading, Picker, Popup, CountDown, ActionSheet, Collapse, CollapseItem, Sticky } from 'vant';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import './directive';
import './filter';
import 'normalize.css/normalize.css';
import '@/styles/index.less'
import IsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import IsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import IsBetween from 'dayjs/plugin/isBetween';
import './icons';
import LoginDialog from './components/loginDialog';
import OakPopup from './components/OakPopup';
import AgreementPopup from './components/AgreementPopup';
import oakvipOverlay from './components/oakvipOverlay/index.vue';
import { jumpUrl, loadJS } from './utils';
import VueCompositionAPI from '@vue/composition-api'

require('dayjs/locale/zh')

dayjs.extend(IsSameOrBefore);
dayjs.extend(IsSameOrAfter);
dayjs.extend(IsBetween);
dayjs.extend(calendar);

if (process.env.NODE_ENV === 'development') {
  require('./mock');
}
Vue.use(Toast).use(Row).use(Col).use(Image).use(Divider).use(Overlay).use(Dialog).use(Empty).use(Lazyload).use(Swipe).use(SwipeItem).use(Skeleton)
  .use(Grid).use(GridItem).use(Form).use(Field).use(Button).use(Loading).use(Picker).use(Popup).use(CountDown).use(ActionSheet).use(Tabs).use(Tab).use(Cell)
  .use(Collapse).use(CollapseItem).use(Sticky).use(CellGroup).use(RadioGroup).use(Radio).use(VueCompositionAPI).use(Checkbox).use(Icon)
// Vue.use(Waterfall);
Vue.use(VueClipboard);
Vue.component('OakPopup', OakPopup);
Vue.component('AgreementPopup', AgreementPopup);
Vue.component(oakvipOverlay.name, oakvipOverlay);

Vue.config.productionTip = false;

Toast.allowMultiple();

Vue.prototype.$loading = function (message = '加载中...', option = {}) {
  return Toast.loading({
    duration: 0,
    forbidClick: true,
    message,
    ...option,
  })
}

Vue.prototype.$jumpUrl = jumpUrl;

Vue.prototype.$cdn = 'https://cdn.xiangshuheika.com';

Vue.prototype.$getConfigValue = (...key) => {
  return store.getters.getConfigValue(...key);
};

if (navigator.userAgent.indexOf('Alipay') > -1) {
  loadJS('https://appx/web-view.min.js', () => {
    console.log('加载支付宝JS成功')
  })
}

Vue.prototype.$confirm = confirm;
Vue.prototype.$alert = alert;
Vue.prototype.$login = LoginDialog;
Vue.prototype.$$store = store;


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')


