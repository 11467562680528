<template>
  <section class="aftermarket-form" v-if="!loading">
    <div class="tips">处理时效预计10分钟，请耐心等待</div>
    <div class="form-item form-item-phone">
      <div class="title">联系方式<span class="required">必填</span></div>
      <van-field
        :border="false"
        v-model="phone"
        name="phone"
        placeholder="输入联系方式"
      />
      <p class="extra-info">
        提供联系方式有利于我们快速响应，我们在审核后会短信通知您，请留意短信查收
      </p>
    </div>
    <div class="form-item form-item-answer">
      <div class="title">申请原因<span class="required">必填</span></div>
      <van-radio-group v-model="answer">
        <van-cell
          v-for="item in answerList"
          :key="item.name"
          :title="item.mark"
          clickable
          @click="answer = item.mark"
        >
          <template #right-icon>
            <van-radio
              :icon-size="16"
              checked-color="#00B25A"
              :name="item.mark"
            />
          </template>
        </van-cell>
      </van-radio-group>
    </div>
    <div class="form-item form-item-desc" v-if="showAddress">
      <div class="title">
        请输入该门店信息<span class="required">必填</span>
      </div>
      <van-field
        v-model="shopAddress"
        name="shopAddress"
        autosize
        rows="2"
        :border="false"
        maxlength="100"
        show-word-limit
        placeholder="城市+区域+门店详细地址"
      />
    </div>
    <div class="form-item form-item-images">
      <div class="title">上传凭证<span class="required">必填</span></div>
      <div class="upload-body">
        <div class="upload-body-tips">
          为保证审核通过，请上传相关凭证（如店员核销界面）
        </div>
        <div class="upload-content">
          <div
            class="upload-demo upload-postion"
            @click="showPopupHandle"
            v-ans="{
              event_id: 'benefit_aftersale_upload-demo',
              order_number: $route.query.order_number,
            }"
          >
            <van-image :src="require('../../assets/images/upload-demo.png')" />
          </div>
          <van-uploader
            v-model="uploader"
            ref="upload"
            :max-count="5"
            :before-read="beforeRead"
            :preview-size="56"
          >
            <div
              class="upload-demo"
              v-ans="{
                event_id: 'benefit_aftersale_upload-btn',
                order_number: $route.query.order_number,
              }"
            >
              <van-image :src="require('../../assets/images/upload-btn.png')" />
            </div>
          </van-uploader>
        </div>
      </div>
    </div>
    <div class="form-item form-item-desc">
      <div class="title">补充凭证<span class="required">选填</span></div>
      <van-field
        v-model="desc"
        name="desc"
        autosize
        rows="2"
        :border="false"
        maxlength="500"
        show-word-limit
        placeholder="请您详细填写申请原因（不少于5个字）"
      />
    </div>
    <van-button
      class="aftermarket-submit"
      block
      round
      v-ans="{
        event_id: 'page_aftersale_list_submit2',
        order_number: $route.query.order_number,
      }"
      @click="submit"
      >提交</van-button
    >
    <van-popup
      round
      get-container="body"
      v-model="showPopup"
      closeable
      position="bottom"
    >
      <div class="upload-demo-popup">
        <h4 class="upload-demo-popup-title">正确示例</h4>
        <div class="rich-text" v-html="rule"></div>
        <van-button
          round
          block
          class="aftermarket-submit"
          v-ans="{
            event_id: 'benefit_aftersale_upload-btn',
            order_number: $route.query.order_number,
          }"
          @click="startUpload"
          >上传凭证</van-button
        >
        <van-button round block @click="showPopup = false">取消</van-button>
      </div>
    </van-popup>
  </section>
</template>

<script>
import { Uploader } from "vant";

import Compressor from "compressorjs";
import throttle from "lodash.throttle";
import {
  checkHasSubmitWorkOrder,
  createWorkerFlow,
  getWorkFlowConfig,
  uploadFlowPicture,
} from "@/apis/product";
import { mapState } from "vuex";
import { getProjectDivision } from "@/utils";

export default {
  components: {
    [Uploader.name]: Uploader,
  },
  data() {
    return {
      phone: "",
      loading: true,
      answer: "",
      rel_config: "",
      uploader: [],
      desc: "",
      shopAddress: "",
      showPopup: false,
      showAddress: false,
      answerList: [],
    };
  },
  computed: {
    ...mapState(["userInfo", "namespace"]),
    rule() {
      return this.answerList.find((item) => item.mark === this.answer)?.rule;
    },
  },
  async mounted() {
    document.title =
      this.$route.query.type === "refund" ? "我要退款" : "我要换券";
    // 获取工单配置
    const loading = this.$loading();
    try {
      const [res, config] = await Promise.all([
        checkHasSubmitWorkOrder({
          user: this.userInfo.id,
        }),
        getWorkFlowConfig({
          product_tag: this.$route.query.product_tag,
        }),
      ]);
      if (Object.keys(config.data)) {
        this.answerList = config.data.problem_type_list;
        this.rel_config = config.data.id;
      }
      this.showAddress = res.data.result;
    } finally {
      loading.clear();
      this.loading = false;
    }
  },
  methods: {
    showPopupHandle() {
      if (!this.answer) {
        this.$toast("请先选择申请原因");
        return;
      }
      this.showPopup = true;
    },
    startUpload() {
      this.showPopup = false;
      this.$refs.upload.chooseFile();
    },
    beforeRead(file) {
      return new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.9,
          maxWidth: 1200,
          maxHeight: 1200,
          success: resolve,
          error() {
            this.$toast("图片上传失败，请重新上传");
          },
        });
      });
    },

    submit: throttle(
      async function () {
        if (!this.phone) {
          this.$toast("请输入手机号");
          return;
        }
        if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
          this.$toast("手机号输入不正确");
          return;
        }
        if (!this.answer) {
          this.$toast("请选择申请原因");
          return;
        }
        if (this.showAddress && !this.shopAddress) {
          this.$toast("请输入门店信息");
          return;
        }
        if (this.desc && this.desc.length < 5) {
          this.$toast("补充凭证信息需大于5个字");
          return;
        }
        if (!this.uploader.length) {
          this.$toast("请上传凭证");
          return;
        }

        const promises = this.uploader.map((item) =>
          uploadFlowPicture({
            order_number: this.$route.query.order_number,
            data: item.content,
          })
        );
        const loading = this.$loading();
        try {
          const uploads = await Promise.all(promises);
          const params = {
            handle_type: this.$route.query.type,
            order_number: this.$route.query.order_number,
            project_division: getProjectDivision(this.namespace),
            problem_type: this.answer,
            desc: this.desc,
            phone: this.phone,
            certificate_list: uploads.map((item) => item.data.url).join(","),
            rel_config: this.rel_config,
            address: this.shopAddress,
          };
          await createWorkerFlow(params);
          this.$router.replace({
            path: "/afterMarket/result",
            query: this.$route.query,
          });
        } finally {
          loading.clear();
        }
      },
      3000,
      { trailing: false }
    ),
  },
};
</script>

<style scoped lang="less">
.aftermarket-form {
  padding: 12px;
}
.tips {
  line-height: 22px;
  padding: 12px;
  font-size: 16px;
  color: #222;
  font-weight: bold;
  text-indent: 28px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px rgba(153, 153, 153, 0.1);
  background: #fff url("../../assets/images/aftermarket-wait.png") no-repeat
    12px center/16px;
}

.form-item {
  margin-top: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px rgba(153, 153, 153, 0.1);
  padding: 16px 12px;
  background-color: #fff;
  .van-radio-group {
    margin-left: -12px;
    margin-right: -12px;
  }
}
.title {
  line-height: 22px;
  font-size: 14px;
  color: #333;
  .required {
    color: #999;
    font-size: 12px;
    padding-left: 5px;
  }
  margin-bottom: 8px;
}
.form-item-phone,
.form-item-desc {
  /deep/ .van-field {
    background-color: #fafafa;
  }
}
.extra-info {
  font-size: 10px;
  color: #c0bebe;
  line-height: 16px;
  margin-top: 8px;
}

.aftermarket-submit {
  margin-top: 24px;
  background-color: #2d3356;
  color: #fff;
}

.upload-body {
  background: #fafafa;
  border-radius: 4px;
  padding: 12px;
  /deep/ .van-uploader__wrapper {
    &::before {
      content: "";
      display: flex;
      width: 56px;
      height: 56px;
      margin-right: 10px;
    }
  }
}
.upload-body-tips {
  color: #999;
  line-height: 16px;
  margin-bottom: 12px;
}
.upload-content {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.upload-demo {
  width: 56px;
  height: 56px;
  margin-right: 10px;
  margin-bottom: 8px;
}

.upload-postion {
  position: absolute;
  left: 0;
  top: 0;
  width: 56px;
  height: 56px;
  z-index: 10;
}

.upload-demo-popup {
  padding: 20px;
}

.upload-demo-popup-title {
  text-align: center;
  font-size: 14px;
}
</style>