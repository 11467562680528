var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('section',{staticClass:"aftermarket-form"},[_c('div',{staticClass:"tips"},[_vm._v("处理时效预计10分钟，请耐心等待")]),_c('div',{staticClass:"form-item form-item-phone"},[_vm._m(0),_c('van-field',{attrs:{"border":false,"name":"phone","placeholder":"输入联系方式"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('p',{staticClass:"extra-info"},[_vm._v(" 提供联系方式有利于我们快速响应，我们在审核后会短信通知您，请留意短信查收 ")])],1),_c('div',{staticClass:"form-item form-item-answer"},[_vm._m(1),_c('van-radio-group',{model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}},_vm._l((_vm.answerList),function(item){return _c('van-cell',{key:item.name,attrs:{"title":item.mark,"clickable":""},on:{"click":function($event){_vm.answer = item.mark}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('van-radio',{attrs:{"icon-size":16,"checked-color":"#00B25A","name":item.mark}})]},proxy:true}],null,true)})}),1)],1),(_vm.showAddress)?_c('div',{staticClass:"form-item form-item-desc"},[_vm._m(2),_c('van-field',{attrs:{"name":"shopAddress","autosize":"","rows":"2","border":false,"maxlength":"100","show-word-limit":"","placeholder":"城市+区域+门店详细地址"},model:{value:(_vm.shopAddress),callback:function ($$v) {_vm.shopAddress=$$v},expression:"shopAddress"}})],1):_vm._e(),_c('div',{staticClass:"form-item form-item-images"},[_vm._m(3),_c('div',{staticClass:"upload-body"},[_c('div',{staticClass:"upload-body-tips"},[_vm._v(" 为保证审核通过，请上传相关凭证（如店员核销界面） ")]),_c('div',{staticClass:"upload-content"},[_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
            event_id: 'benefit_aftersale_upload-demo',
            order_number: _vm.$route.query.order_number,
          }),expression:"{\n            event_id: 'benefit_aftersale_upload-demo',\n            order_number: $route.query.order_number,\n          }"}],staticClass:"upload-demo upload-postion",on:{"click":_vm.showPopupHandle}},[_c('van-image',{attrs:{"src":require('../../assets/images/upload-demo.png')}})],1),_c('van-uploader',{ref:"upload",attrs:{"max-count":5,"before-read":_vm.beforeRead,"preview-size":56},model:{value:(_vm.uploader),callback:function ($$v) {_vm.uploader=$$v},expression:"uploader"}},[_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
              event_id: 'benefit_aftersale_upload-btn',
              order_number: _vm.$route.query.order_number,
            }),expression:"{\n              event_id: 'benefit_aftersale_upload-btn',\n              order_number: $route.query.order_number,\n            }"}],staticClass:"upload-demo"},[_c('van-image',{attrs:{"src":require('../../assets/images/upload-btn.png')}})],1)])],1)])]),_c('div',{staticClass:"form-item form-item-desc"},[_vm._m(4),_c('van-field',{attrs:{"name":"desc","autosize":"","rows":"2","border":false,"maxlength":"500","show-word-limit":"","placeholder":"请您详细填写申请原因（不少于5个字）"},model:{value:(_vm.desc),callback:function ($$v) {_vm.desc=$$v},expression:"desc"}})],1),_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
      event_id: 'page_aftersale_list_submit2',
      order_number: _vm.$route.query.order_number,
    }),expression:"{\n      event_id: 'page_aftersale_list_submit2',\n      order_number: $route.query.order_number,\n    }"}],staticClass:"aftermarket-submit",attrs:{"block":"","round":""},on:{"click":_vm.submit}},[_vm._v("提交")]),_c('van-popup',{attrs:{"round":"","get-container":"body","closeable":"","position":"bottom"},model:{value:(_vm.showPopup),callback:function ($$v) {_vm.showPopup=$$v},expression:"showPopup"}},[_c('div',{staticClass:"upload-demo-popup"},[_c('h4',{staticClass:"upload-demo-popup-title"},[_vm._v("正确示例")]),_c('div',{staticClass:"rich-text",domProps:{"innerHTML":_vm._s(_vm.rule)}}),_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'benefit_aftersale_upload-btn',
          order_number: _vm.$route.query.order_number,
        }),expression:"{\n          event_id: 'benefit_aftersale_upload-btn',\n          order_number: $route.query.order_number,\n        }"}],staticClass:"aftermarket-submit",attrs:{"round":"","block":""},on:{"click":_vm.startUpload}},[_vm._v("上传凭证")]),_c('van-button',{attrs:{"round":"","block":""},on:{"click":function($event){_vm.showPopup = false}}},[_vm._v("取消")])],1)])],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_vm._v("联系方式"),_c('span',{staticClass:"required"},[_vm._v("必填")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_vm._v("申请原因"),_c('span',{staticClass:"required"},[_vm._v("必填")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_vm._v(" 请输入该门店信息"),_c('span',{staticClass:"required"},[_vm._v("必填")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_vm._v("上传凭证"),_c('span',{staticClass:"required"},[_vm._v("必填")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_vm._v("补充凭证"),_c('span',{staticClass:"required"},[_vm._v("选填")])])}]

export { render, staticRenderFns }