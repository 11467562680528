<template>
  <div class="luck-draw-body">
    <van-image
      class="luck-draw-bg"
      :src="activityInfo.special_info.header_img"
    />
    <div class="draw-btn-wrap">
      <van-button
        block
        round
        type="primary"
        class="draw-btn1"
        @click="drawHandleThrottle"
      >
        {{ activityInfo.special_info.start_draw_text || "开始抽奖" }}
        {{ remainingTimes > 0 ? `X${remainingTimes}` : "" }}
      </van-button>
    </div>
    <Dialog
      v-model="showDialog"
      :activityReward="activityReward"
      :type="type"
      :openPrizePopup="openPrizePopup"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";
import throttle from "lodash.throttle";
import Dialog from "./Dialog";
import Vue from "vue";

const vm = Vue.prototype;
export default defineComponent({
  inheritAttrs: false,

  components: {
    Dialog,
  },
  props: {
    activityInfo: Object,
    activityReward: Object,
    beforeDraw: Function,
    openPrizePopup: Function,
    afterDraw: Function,
    type: String,
    remainingTimes: Number,
  },
  setup(props) {
    const showDialog = ref(false);
    const drawHandle = async () => {
      const res = await props.beforeDraw(true);
      if (!res) {
        return;
      }
      const miss_tips = props.activityInfo.special_info.miss_tips;
      if (res.status === 6 && miss_tips) {
        // 未中奖
        vm.$toast(miss_tips);
      } else {
        showDialog.value = true;
      }
      await props.afterDraw();
    };
    const drawHandleThrottle = throttle(drawHandle, 3000, { trailing: false });

    return {
      drawHandleThrottle,
      showDialog,
    };
  },
});
</script>

<style lang="less" scoped>
</style>
