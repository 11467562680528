var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.fecting)?_c('section',{staticClass:"hr-conpon"},[_c('div',{staticClass:"tab-header"},[_vm._l((_vm.products),function(item,index){return _c('div',{key:item.id,class:{ 'tab-item': true, active: index === _vm.active },on:{"click":function($event){_vm.active = index}}},[_c('div',{staticClass:"tab-item-name"},[_vm._v(_vm._s(item.desc))]),_c('div',{staticClass:"tab-item-desc"},[_vm._v(" "+_vm._s(item.amountInfo.total_amount)+"/月"),_c('span',[_vm._v("剩余"+_vm._s(item.amountInfo.total_amount - item.amountInfo.used_amount)+"元")])])])}),_c('div',{class:{ k1: true, active: _vm.active === 0 }}),_c('div',{class:{ k2: true, active: _vm.active === 1 }})],2),_c('ul',{staticClass:"tab-body"},_vm._l((_vm.spu.skus),function(sku){return _c('li',{key:sku.id,class:{ disabled: sku.stock.daily_stocks === 0 }},[_c('div',{staticClass:"facevalue"},[_vm._v(" ¥"),_c('span',[_vm._v(_vm._s(sku.face_value))])]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.getDescFromItemCode(sku.item_code)))]),_c('div',{staticClass:"subdesc ellipsis"},[_vm._v("全品类/除特殊商品")]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-inner",style:(("width:" + (sku.width) + "%"))},[_vm._v(" "+_vm._s(sku.width)+"% ")])]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'benefit_list_click',
          spu_id: sku.id,
          spu_name: sku.item_name,
        }),expression:"{\n          event_id: 'benefit_list_click',\n          spu_id: sku.id,\n          spu_name: sku.item_name,\n        }"}],class:{ receive: true },on:{"click":function($event){return _vm.receive(sku, _vm.spu)}}},[_vm._v(" 领取 ")])])}),0),(_vm.park)?_c('div',{staticClass:"parking",on:{"click":function($event){return _vm.jump(_vm.park.benefit)}}},[_c('van-image',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'benefit_list_click',
        spu_id: _vm.park.id,
        spu_name: _vm.park.name,
      }),expression:"{\n        event_id: 'benefit_list_click',\n        spu_id: park.id,\n        spu_name: park.name,\n      }"}],staticClass:"parking-image",attrs:{"src":_vm.park.display_img_url}}),_c('div',{class:['benefit-spu-btn', _vm.isDisabled(_vm.park.benefit) ? 'disabled' : '']},[_vm._v(" "+_vm._s(_vm.getBenefitText(_vm.park.benefit))+" ")])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }