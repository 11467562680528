var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageshow)?_c('div',{staticClass:"xp-home"},[_c('div',{staticClass:"card"},[_c('img',{attrs:{"src":("https://cdn.xiangshuheika.com/static/" + _vm.namespace + "/card.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('van-image',{staticClass:"img",attrs:{"src":("https://cdn.xiangshuheika.com/static/" + _vm.namespace + "/headimg.png")}}),_c('div',{staticClass:"text"},[_c('h4',[_vm._v(_vm._s(_vm.phone))]),_c('p',[_vm._v("有效期至"+_vm._s(_vm.userBenefit.card_expiry_date.replace(/-/g, ".")))])])],1),_c('van-image',{staticClass:"benefit-logo",attrs:{"fit":"cover","lazy-load":"","src":_vm.firstBenefit.display_img_url}}),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'member_click_others',
        source: _vm.source,
        namespace: _vm.namespace,
      }),expression:"{\n        event_id: 'member_click_others',\n        source,\n        namespace,\n      }"}],staticClass:"other",on:{"click":_vm.toIndex}},[_vm._v(" 其他联合会员 ")])],1),(_vm.master_benefit_info.amount > 0)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"jiao"},[_vm._v("未激活")]),_c('div',{domProps:{"innerHTML":_vm._s(
        JSON.parse(this.$getConfigValue('MASTER_CARD_INFO'))[
          _vm.master_benefit.product_code
        ]
      )}}),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'member_click_jihuo',
        source: _vm.source,
        namespace: _vm.namespace,
      }),expression:"{\n        event_id: 'member_click_jihuo',\n        source,\n        namespace,\n      }"}],staticClass:"btn",on:{"click":function($event){return _vm.$router.push('/xiaopai/activation')}}},[_vm._v(" 去激活 ")])]):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v(" 生活权益免费领 "),_c('span',[_vm._v("("+_vm._s(_vm.lifeBenefits.desc)+")")])]),_c('ul',_vm._l((_vm.lifeBenefits.list),function(item){return _c('li',{key:item.id},[_c('div',{staticClass:"price"},[_c('sub',[_vm._v("¥")]),_vm._v(_vm._s(item.lowest_face_value * _vm.getCoundFromSellpoint(item.selling_points))+" ")]),_c('van-image',{staticClass:"img",attrs:{"src":item.display_img_url}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.desc)+_vm._s(item.lowest_face_value)+"元")]),_c('p',[_vm._v(_vm._s(item.selling_points.split("x")[0]))]),_c('div',{staticClass:"get",on:{"click":function($event){return _vm.toDetail(item)}}},[_vm._v("立即领取")])],1)}),0)]),(_vm.master_benefit_info.amount <= 0)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"jiao"},[_vm._v("已激活")]),_c('div',{domProps:{"innerHTML":_vm._s(
        JSON.parse(this.$getConfigValue('MASTER_CARD_INFO'))[
          _vm.master_benefit.product_code
        ]
      )}})]):_vm._e(),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/order')}}},[_c('van-image',{staticClass:"img",attrs:{"src":require('../../assets/images/icon-order2.png')}}),_vm._v(" 我的订单 ")],1),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push('/service')}}},[_c('van-image',{staticClass:"img",attrs:{"src":require('../../assets/images/server.png')}}),_vm._v(" 在线客服 ")],1)]),_c('van-action-sheet',{model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"top"},[_vm._v(" "+_vm._s(_vm.currentPackage.name)+" "),_c('p',[_c('span',[_vm._v("¥")]),_vm._v(_vm._s(_vm.currentPackage.price))])]),_c('div',{staticClass:"method-tit"},[_vm._v("支付方式")]),_c('div',{staticClass:"methods"},_vm._l((_vm.payMethods),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.changeMethod(index)}}},[_c('div',{staticClass:"left"},[_c('van-image',{staticClass:"pay-item-icon",attrs:{"src":item.icon}}),_vm._v(" "+_vm._s(item.name)+" ")],1),_c('img',{attrs:{"src":require(("../../assets/images/" + (_vm.selected_method.channel === item.channel
                  ? 'selected'
                  : 'select') + ".png")),"alt":""}})])}),0),_c('div',{staticClass:"agreement"},[_vm._v(" 点击支付开通会员即视为同意"),_c('span',{on:{"click":_vm.agreement}},[_vm._v("《会员服务协议》")])]),_c('div',{staticClass:"btn",on:{"click":_vm.open}},[_vm._v("立即开通")]),_c('van-image',{staticClass:"close",attrs:{"src":require('../../assets/images/close.png')},on:{"click":function($event){_vm.show = false}}})],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }