<template>
  <div v-if="methods.length">
    <div class="package-title">支付方式</div>
    <div
      :class="{
        'payment-item': true,
        active: selectedMethod && selectedMethod.channel === item.channel,
      }"
      v-for="item in filterMethods"
      :key="item.channel"
      @click="$emit('update:selectedMethod', item)"
    >
      <img class="item-img" :src="item.icon" />
      <div class="item-name">{{ item.name }}</div>
      <div class="item-checked"></div>
    </div>
    <div v-if="showExpand" class="expand" @click="expand">展开</div>
  </div>
</template>

<script>
export default {
  props: ["methods", "selectedPackage", "selectedMethod"],
  computed: {
    filterMethods() {
      // 选择自动续费时，屏蔽其他支付方式
      if (this.selectedPackage.channel) {
        return this.methods.filter(
          (r) => r.channel === this.selectedPackage.channel
        );
      } else {
        return this.methods.filter((r) => !r.is_fold);
      }
    },
    showExpand() {
      if (!this.selectedPackage.channel) {
        if (this.methods.some((r) => r.is_fold)) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    expand() {
      this.$emit("expand");
    },
  },
};
</script>

<style lang="less" scoped>
.payment-item {
  display: flex;
  align-items: center;
  &:not(&:last-child) {
    margin-bottom: 10px;
  }
  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
  .item-name {
    line-height: 16px;
  }
  .item-checked {
    margin-left: auto;
    width: 16px;
    height: 16px;
    background: url("../../assets/images/select.png") no-repeat;
    background-size: 100%;
  }
  &.active {
    .item-checked {
      background-image: url("../../assets/images/selected.png");
    }
  }
}
.expand {
  color: #797e8c;
  display: inline-block;
  padding-right: 12px;
  background: url("../../assets/images/down.png") no-repeat;
  background-position: right center;
  background-size: 8px;
}
</style>