<template>
  <div class="package" v-if="!loading">
    <div class="package-card">
      <voucher
        :selectedPackage.sync="selectedPackage"
        :packages="packages"
        :type="type"
      />
      <div class="package-content">
        <van-image
          lazy-load
          class="package-content-image"
          :src="`https://cdn.xiangshuheika.com/static/${this.namespace}/spu/package-${selectedPackage.item_code}.png`"
        />
      </div>
    </div>
    <div class="package-card">
      <payment
        :type="type"
        :methods="methods"
        @expand="changeMethods"
        :selectedPackage.sync="selectedPackage"
        :selectedMethod.sync="selectedMethod"
      />
    </div>
    <div class="fixed-height"></div>
    <div class="popup-btn">
      <van-button
        round
        block
        type="info"
        class="buy-btn"
        size="large"
        @click="toBuyMember"
        v-ans="{
          event_id: 'vistor_click_pay',
          namespace,
        }"
      >
        立即支付
      </van-button>
    </div>
  </div>
</template>

<script>
import { getPackageList, getPaymethod } from "@/apis/product";
import payment from "./payment";
import voucher from "./voucher";
import { mapState } from "vuex";
import { toPayV2 } from "@/helper/pay";

export default {
  components: {
    payment,
    voucher,
  },
  data() {
    return {
      type: this.$route.query.type,
      packages: [],
      selectedPackage: {},
      selectedMethod: {},
      methods: [],
      loading: true,
    };
  },
  computed: {
    ...mapState(["namespace", "source", "userInfo"]),
  },
  mounted() {
    this.fetchPackage();
  },
  methods: {
    async toBuyMember() {
      let url =
        (window.location.origin + window.location.pathname).replace(/\/$/, "") +
        `/#/business/home`;
      const return_url = encodeURIComponent(url);

      toPayV2(this.selectedPackage, this.selectedMethod.channel, {
        partner_jump_url: return_url,
        partner_url: return_url,
      });
    },
    changeMethods() {
      this.methods = this.methods.map((r) => ({ ...r, is_fold: false }));
    },
    async fetchPackage() {
      const loading = this.$loading();
      try {
        const res = await getPackageList({
          namespace: this.namespace,
          source: this.source,
        });
        const packages = res.data;
        packages.reverse();
        packages.forEach((item) => {
          // 拼接续费item_code
          if (this.type === "xf") {
            item.item_code = "XF-" + item.item_code;
          }
        });
        const opts = {
          namespace: this.namespace,
          product_type: this.namespace.toUpperCase(),
          source: "default",
        };
        this.packages = packages;
        if (packages.length) {
          this.selectedPackage = packages[0];
        }
        const methods = await getPaymethod(opts);
        if (methods.length) {
          this.selectedMethod = methods[0];
        }
        this.methods = methods;
      } finally {
        loading.clear();
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.package {
  padding: 15px;
  background: #f5f5f5;
  min-height: 100vh;
  box-sizing: border-box;
  /deep/ .package-title {
    padding-bottom: 10px;
    color: #222;
    font-weight: bold;
    font-size: 16px;
  }
}
.package-card {
  padding: 15px 10px;
  background: #fff;
  border-radius: 10px;
  & + .package-card {
    margin-top: 10px;
  }
}
.index-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  padding: 8px 15px;
  z-index: 1000;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
}
.payment-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.item-name {
  height: 30px;
}
.payment-item {
  box-sizing: border-box;
  height: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 4px;
  border: 1px solid #c6c8d0;
  &:not(:last-child) {
    margin-right: 10px;
  }
  &.active {
    background-color: #ffead5;
    border-color: #e4af88;
  }
}
.item-img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.btn-group {
  display: flex;
  height: 50px;
  box-sizing: border-box;
  text-align: center;
}

.member-btn {
  margin-right: 10px;
  width: 36px;
  font-size: 12px;
  color: #515667;
  line-height: 18px;
  img {
    margin: 3px auto 2px;
    display: block;
    width: 24px;
    height: 24px;
  }
}
.popup-btn {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  z-index: 100;
  padding-bottom: calc(10px + constant(safe-area-inset-bottom));
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
}
.btn-price-left {
  text-align: right;
  font-size: 30px;
  font-weight: bold;
}
.btn-price-sub {
  font-size: 15px;
}
.free-btn {
  flex-direction: column;
}
.free-btn-name {
  font-size: @font-lg;
  line-height: 24px;
}
.free-btn-desc {
  opacity: 0.7;
}
.btn-price-right {
  padding-left: 5px;
}
.btn-price-tips {
  width: 60px;
  height: 16px;
  background: url("../../assets/images/open_price_tips.png") no-repeat;
  background-size: 100%;
}
.btn-price-cost {
  margin-top: 2px;
  text-align: left;
  text-decoration: line-through;
  font-weight: 400;
  opacity: 0.7;
  line-height: 10px;
}
.agreement {
  padding-top: 10px;
  color: @text-gray2-color;
}

.unlogin-popup {
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .popup-header {
    display: flex;
    align-items: center;
    padding: 8px 8px 4px;
    .popup-header-close {
      margin-left: auto;
      width: 16px;
      height: 16px;
      background: url("../../assets/images/close.png") no-repeat;
      background-size: 100%;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: -5px;
        top: -5px;
        right: -5px;
        bottom: -5px;
      }
    }
  }
}
.popup-body {
  padding: 0 15px;
  height: calc(100%-150px);
  overflow: auto;
}
.buy-btn-text {
  font-size: 18px;
  span {
    font-size: 0.6em;
    vertical-align: middle;
  }
}

.fixed-height {
  height: 80px;
}
.package-content {
  .package-content-image {
    min-height: 150px;
  }
}
</style>