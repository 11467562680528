<template>
  <van-popup
    :value="show"
    @close="handleClose"
    @click-overlay="handleClose"
    :close-on-click-overlay="showClose"
    class="popup"
  >
    <div>
      <slot></slot>
    </div>
    <div class="close-icon" v-if="showClose" @click="handleClose"></div>
  </van-popup>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    show: {
      require: true,
      type: Boolean,
    },
    wrapClass: {
      require: false,
      type: String,
    },
    showClose: {
      require: false,
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const handleClose = () => {
      if (!props.showClose) return;
      context.emit("update:show", false);
    };

    return {
      handleClose,
    };
  },
});
</script>

<style lang="less" scoped>
.popup {
  background: rgba(0, 0, 0, 0);
}

.close-icon {
  height: 40px;
  width: 40px;
  background: url("https://common-h5-dev.xiangshuheika.com/static/upload/20241011696f237fe2cac.png");
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  margin: 0 auto;
  margin-top: 32px;
}
</style>