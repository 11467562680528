<template>
  <div class="benefit_detail share-benefit" v-if="showPage">
    <van-image
      mode="aspectFill"
      class="benefit-bgd"
      :src="`https://cdn.xiangshuheika.com/static/${source}/${benefit_type}-bg2.png`"
    />
    <div class="benefit-detail-wrap">
      <div class="benefit-card-warp">
        <div class="share-info">
          <div class="share-info-title">超值权益赠好友</div>
          <div class="share-info-expire">
            请在{{ expired | formatDate("YYYY-MM-DD HH:mm") }}前领取
          </div>
        </div>
        <div class="benefit-main">
          <div class="benifit-text">
            <div class="benifit-text-wrap">
              <div class="benefit-title">
                {{ decodeURIComponent(title || "") }}
              </div>
              <div class="benefit-description">
                {{ decodeURIComponent(subtitle || "") }}
              </div>
            </div>
            <div class="benefit-sku-list" v-if="sku_list.length > 1">
              <van-row>
                <van-col
                  :class="{ 'sku-item': true }"
                  :span="sku_list.length === 2 ? 12 : 8"
                  v-for="item in sku_list"
                  :key="item.id"
                >
                  <div class="sku-item-inner">
                    <van-image
                      class="sku-item-inner-image"
                      :src="getImage(item.item_code)"
                      fit="cover"
                    />
                  </div>
                </van-col>
              </van-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="height120"></div>
    <div
      class="use-btn2-share"
      @click="submit"
      v-ans="{
        event_id: 'share_benefit_button_lick',
        namespace: source,
      }"
    >
      <van-button block round class="benefit-btn">立即领取</van-button>
    </div>
    <van-dialog
      v-model="receiveModal"
      theme="confirm common-dialog-confirm"
      confirm-button-text="同意并领取"
      show-cancel-button
      title="领取前请确认"
      @cancel="receiveModal = false"
      @confirm="recevieHandle"
    >
      <div class="jdecard-text">
        <div class="rich" v-html="shareInfo.rules || ''" />
      </div>
      <div class="agreement">
        领取即视为同意<span class="agreement-link" @click="toAgreement"
          >《会员服务协议》</span
        >
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { creatSign, storage } from "@/utils";
import { mapActions } from "vuex";
import {
  createActivityMember,
  getSku,
  goCashing,
  queryRedemptionStatus,
} from "@/apis/home";
import { getActivityInfo } from "@/apis";
import LoginDialog from "@/components/loginDialog";
import { agentConfig } from "@/config";
export default {
  data() {
    return {
      showPage: false,
      benefit_info: {},
      shareInfo: {},
      sku_list: [],
      title: this.$route.query.title,
      subtitle: this.$route.query.subtitle,
      expired: this.$route.query.expired,
      benefit_type: this.$route.query.benefit_type,
      source: this.$route.query.source,
      receiveModal: false,
    };
  },
  computed: {},
  async mounted() {
    const loading = this.$loading();
    const { code, benefit_type } = this.$route.query;
    if (storage.getItem("token")) {
      const res = await queryRedemptionStatus({ redemption_code: code });
      if (res.data.status === 3) {
        loading.clear();
        this.$router.replace(`/benefitDetail?benefit_type=${benefit_type}`);
        return;
      }
    }
    const [sku, activity] = await Promise.all([
      getSku({ benefit_type, namespace: this.source }),
      this.queryActivityInfo(),
    ]);
    this.showPage = true;
    this.sku_list = sku.data;
    this.shareInfo = activity;
    loading.clear();
  },
  methods: {
    ...mapActions(["fetchUserInfo"]),
    toAgreement() {
      location.href =
        "https://cdn.xiangshuheika.com/h5/agreement/sy_register.html";
    },
    async recevieHandle() {
      // 使用兑换码兑换会员
      const loading = this.$loading("正在加载");
      const { code } = this.$route.query;
      try {
        const result = await queryRedemptionStatus({
          redemption_code: code,
        });
        const status = result.data.status;
        if (status === 0) {
          this.$toast("兑换码不存在");
          return;
        }

        if (status === 2) {
          this.$toast("分享已过期");
          return;
        }

        if (status === 4) {
          this.$toast("很抱歉，该福利已被其他人领取，无法重复领取");
          return;
        }

        // 创建免费会员
        const option = {
          item_code: "FS999",
          agent_id: agentConfig("fs"),
          price: 0,
          out_trade_no: `X${new Date().getTime()}`,
        };
        const sign = creatSign("fs", option);
        option.sign = sign;
        try {
          const result = await createActivityMember(option);
          if (!result.ok) {
            this.$toast(result.msg);
            return;
          }
        } catch (e) {
          this.$toast("创建会员失败");
          return;
        }
        const res = await goCashing({ cashing_code: code });
        if (res.ok) {
          await this.fetchUserInfo();
          this.$confirm({
            message: "领取成功，快去使用权益吧",
            confirmButtonText: "去使用",
          }).then(() => {
            this.$router.replace(
              `/benefitDetail?benefit_type=${this.benefit_type}`
            );
          });
          this.receiveModal = false;
        }
      } finally {
        loading.clear();
      }
    },
    async queryActivityInfo() {
      try {
        const res = await getActivityInfo({
          activity_name: `${this.$route.query.from}_benefit_share`,
          hideMsg: true,
        });
        return res.data;
      } catch (e) {
        return {};
      }
    },
    getImage(item_code) {
      return `https://cdn.xiangshuheika.com/static/${this.source}/benefit/${item_code}.png`;
    },
    async submit() {
      if (!storage.getItem("token")) {
        LoginDialog.show({
          ns: this.source,
          title: "若未注册，将自动免费注册",
        }).then(async () => {
          const result = await queryRedemptionStatus({
            redemption_code: this.$route.query.code,
          });
          const status = result.data.status;
          // 已领取，去使用
          if (status === 3) {
            this.$router.replace(
              `/benefitDetail?benefit_type=${this.benefit_type}`
            );
          }
        });
        return;
      }
      const loading = this.$loading();
      try {
        const { code } = this.$route.query;
        const res = await queryRedemptionStatus({
          redemption_code: code,
        });
        const status = res.data.status;
        if (status === 0) {
          this.$toast("兑换码不存在");
          return;
        }

        if (status === 2) {
          this.$toast("分享已过期");
          return;
        }
        if (status === 3) {
          this.$router.replace(
            `/benefitDetail?benefit_type=${this.benefit_type}`
          );
          return;
        }

        if (status === 4) {
          this.$toast("很抱歉，该福利已被其他人领取，无法重复领取");
          return;
        }
        if (status === 1) {
          // 领取权益
          this.receiveModal = true;
        }
      } finally {
        loading.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.benefit-bgd {
  width: 100%;
  height: 223px;
}
.benefit-detail-wrap {
  margin: 0 10px;
  position: relative;
  margin-top: -73px;
}
.benefit-card-warp {
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
}

.share-benefit .use-btn,
.share-benefit .benefit-amount {
  display: none;
}

.share-benefit .benefit-img {
  height: 55px;
  display: block;
}
.share-benefit .benefit-img img {
  object-fit: contain;
  height: 55px;
}
.share-benefit .item {
  border: 1px solid #ddd;
  box-sizing: border-box;
}
.share-benefit .item::before {
  content: none !important;
}

.share-benefit .item .price {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.share-benefit .item .price .face-value {
  margin-left: 5px;
  text-decoration: line-through;
  color: #999;
  font-size: 12px;
  font-weight: normal;
}

.rich {
  word-break: break-all;
  white-space: pre-wrap;
  text-align: left;
}

.agreement {
  margin-top: 10px;
  text-align: left;
  font-size: 13px;
}
.agreement-link {
  color: #0091ff;
}

.share-info {
  height: 57px;
  background-color: #ffd092;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.share-info-title {
  font-size: 18px;
  font-weight: bold;
  color: #8f2e00;
}

.share-info-expire {
  color: #8f2e00;
  font-size: 12px;
  margin-left: 10px;
}
.share-benefit .benefit-main {
  margin-top: -8px;
}
.use-btn2-share {
  background-color: #fff;
  height: 65px;
  padding: 0 8px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -1px 0px 0px #ebecf1;
}

.use-btn2-share .benefit-btn {
  margin-top: 8px;
  line-height: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 16px;
  background: linear-gradient(90deg, #ffc360 0%, #ffeba6 100%);
}

.share-benefit .select-benefit .item {
  height: 80px;
}

.height120 {
  height: 70px;
}

.benifit-text-wrap {
  height: 85px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/images/be-bg.png");
}
.benifit-text {
  color: #ffd092;
}
.benefit-title {
  font-size: 22px;
  font-weight: bold;
  color: #222;
  margin: 0 0 0 10px;
  padding-top: 15px;
  line-height: 30px;
  display: flex;
  align-items: center;
}

.benefit-description {
  font-size: 13px;
  color: #333;
  margin-top: 5px;
  margin-left: 10px;
}

.benefit-sku-list {
  margin: 20px 10px 10px;
  /deep/ .van-row {
    margin: 0 -5px;
  }
}
.sku-item-inner {
  height: 64px;
  box-sizing: border-box;
  border: 1px solid #999;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}
.sku-item-inner-image {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.sku-item {
  padding: 0 5px;
}
</style>