import Vue from 'vue';
import { ImagePreview } from 'vant';

// 给vant的dialog组件加上右上角的关闭按钮
Vue.directive('close', {
  inserted(el, binding) {
    const div = document.createElement('div');
    div.className = 'dialog-close';
    div.onclick = binding.value;
    el.appendChild(div);
  }
});

// 易观埋点
Vue.directive('ans', {
  inserted(el, binding) {
    el.setAttribute('data-ans', JSON.stringify(binding.value));
  },
  update(el, binding) {
    el.setAttribute('data-ans', JSON.stringify(binding.value));
  },
})

Vue.directive('replace', (el, binding) => {
  const modifiersText = Object.keys(binding.modifiers).join('|');
  if (!el || !el.innerHTML || !modifiersText.length) return;
  const reg = new RegExp(`{(${modifiersText})}`, 'g');
  if (!el.dataset.replacetext) {
    el.dataset.replacetext = el.innerHTML || '';
  }
  const html = el.dataset.replacetext.replace(reg, binding.value || 3);
  el.innerHTML = html;
})


Vue.directive('imagelazy', (el, binding) => {
  const imgEl = el.querySelectorAll('img');
  imgEl.forEach(img => {
    const src = img.getAttribute('src');
    if (!src) return
    img.setAttribute('data-href', src);
    img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAV4AAADICAIAAAAvAR6nAAAACXBIWXMAAAsTAAALEwEAmpwYAAACYElEQVR4nO3UsQ0AIRDAsOf3H+2GokZKD4U9QaqsmfkATv/tAOBF1gAEawCCNQDBGoBgDUCwBiBYAxCsAQjWAARrAII1AMEagGANQLAGIFgDEKwBCNYABGsAgjUAwRqAYA1AsAYgWAMQrAEI1gAEawCCNQDBGoBgDUCwBiBYAxCsAQjWAARrAII1AMEagGANQLAGIFgDEKwBCNYABGsAgjUAwRqAYA1AsAYgWAMQrAEI1gAEawCCNQDBGoBgDUCwBiBYAxCsAQjWAARrAII1AMEagGANQLAGIFgDEKwBCNYABGsAgjUAwRqAYA1AsAYgWAMQrAEI1gAEawCCNQDBGoBgDUCwBiBYAxCsAQjWAARrAII1AMEagGANQLAGIFgDEKwBCNYABGsAgjUAwRqAYA1AsAYgWAMQrAEI1gAEawCCNQDBGoBgDUCwBiBYAxCsAQjWAARrAII1AMEagGANQLAGIFgDEKwBCNYABGsAgjUAwRqAYA1AsAYgWAMQrAEI1gAEawCCNQDBGoBgDUCwBiBYAxCsAQjWAARrAII1AMEagGANQLAGIFgDEKwBCNYABGsAgjUAwRqAYA1AsAYgWAMQrAEI1gAEawCCNQDBGoBgDUCwBiBYAxCsAQjWAARrAII1AMEagGANQLAGIFgDEKwBCNYABGsAgjUAwRqAYA1AsAYgWAMQrAEI1gAEawCCNQDBGoBgDUCwBiBYAxCsAQjWAARrAII1AMEagGANQLAGIFgDEKwBCNYABGsAgjUAwRqAYA1AsAYgWAMQrAEI1gAEawCCNQDBGoBgDUCwBiBsMUAD9HFyVLwAAAAASUVORK5CYII='
    const io = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const href = entry.target.getAttribute('data-href');
          if (href) {
            const i = new Image();
            i.src = href;
            i.onload = () => {
              entry.target.src = href;
              io.unobserve(entry.target);
            }
          }
        }
      })
    })
    io.observe(img);
  })
})

// 图片预览
Vue.directive('image-preview', {
  bind(el) {
    el.handler = function (e) {
      if (e.target.nodeName === 'IMG') {
        const src = e.target.getAttribute('src');
        const images = el.querySelectorAll('img');
        const urls = [...images].map(img => img.getAttribute('src')).filter(Boolean);
        ImagePreview({
          startPosition: urls.findIndex(item => item === src),
          images: urls,
          loop: false,
          getContainer: () => document.body,
        })
      }
    }
    el.addEventListener('click', el.handler);
  },

  unbind(el) {
    el.removeEventListener('click', el.handler);
  }
})

// 节流
Vue.directive('throttle', {
  bind: (el, binding) => {
    // 默认节流1.5s
    el.throttleTime = binding.value || 1500;
    el.handler = function (event) {
      if (!el.cbFun) { // 第一次执行
        el.cbFun = setTimeout(() => {
          el.cbFun = null;
        }, el.throttleTime);
      } else {
        event && event.stopImmediatePropagation();
      }
    }
    el.addEventListener('click', el.handler, true);
  },
  unbind(el) {
    el.removeEventListener('click', el.handler);
  }
});


Vue.directive('text-highlight', (el, binding) => {
  try {
    let text = el.innerText;
    const style = binding.value.style;
    const replaceText = binding.value.text.split(',');
    replaceText.forEach(item => {
      const reg = new RegExp(item, 'g');
      text = text.replace(reg, `<span style="${style}">${item}</span>`);
    })
    el.innerHTML = text;
  } catch (e) {
    console.log(e);
  }
})