<template>
  <div class="package-wrap">
    <div class="package-list">
      <div
        v-for="item in package_list"
        :key="item.namespace"
        class="package-item"
        :style="{ backgroundImage: `url(${item.img})` }"
      >
        {{ item.name }}
        <div
          v-if="item.toUse && isLogin"
          class="package-btn"
          @click="toIndex(item)"
          v-ans="{
            event_id: 'gf_package_use_button',
          }"
        >
          去使用
        </div>
        <div
          v-if="!item.toUse && isLogin"
          class="package-btn"
          @click="$router.push('/redeem')"
          v-ans="{
            event_id: 'gf_package_redeem_button',
          }"
        >
          去兑换
        </div>
      </div>
    </div>
    <div v-if="!isLogin" class="login-wrap">
      <div
        class="login-btn"
        @click="login"
        v-ans="{
          event_id: 'gf_package_login_button',
        }"
      >
        登录
      </div>
    </div>
  </div>
</template>

<script>
import { CDN } from "@/config";
import { getMutilCard } from "@/apis/product";
import { storage } from "@/utils";
import LoginDialog from "@/components/loginDialog";
import { mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      isLogin: !!storage.getItem("token"),
      package_list: [
        {
          name: "广发基金亲子见面礼",
          card_type: "GF101",
          img: `${CDN}gffunds01.png`,
          toUse: false,
        },
        {
          name: "广发基金亲子成长礼",
          card_type: "GF201",
          img: `${CDN}gffunds03.png`,
          toUse: false,
        },
      ],
    };
  },
  mounted() {
    if (this.isLogin) {
      this.getCard();
    }
  },
  methods: {
    ...mapMutations(["changeState"]),
    ...mapActions(["fetchUserInfo", "initProjectData"]),
    login() {
      LoginDialog.show({
        ns: "gffunds1",
        title: "若未注册，将自动免费注册",
      }).then(() => {
        this.isLogin = true;
        this.getCard();
      });
    },
    async toIndex(item) {
      this.changeState({ namespace: item.namespace });
      storage.setItem("token", item.token);
      await this.fetchUserInfo();
      await this.initProjectData();
      this.$router.push("/index");
    },
    async getCard() {
      const res = await getMutilCard({
        namespace: this.namespace,
        source: "default",
        hideMsg: true,
      });
      this.package_list.forEach((v) => {
        res.data.forEach((i) => {
          if (v.card_type === i.card_type) {
            v.toUse = true;
            v.token = i.token;
            v.namespace = i.namespace;
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.package-wrap {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #f5f5f5;
  padding-top: 15px;
}
.package-list {
  margin: 0 15px;
}
.package-item {
  height: 80px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 0 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: space-between;
}
.login-wrap {
  position: fixed;
  width: 100%;
  height: 65px;
  background-color: #fff;
  left: 0;
  bottom: 0;
}
.login-btn {
  background-color: #1a1a1a;
  text-align: center;
  height: 49px;
  line-height: 49px;
  font-weight: bold;
  margin: 8px 20px;
  font-size: 18px;
  color: #fff3da;
  border-radius: 49px;
}
.package-btn {
  width: 60px;
  height: 25px;
  background: linear-gradient(90deg, #ffe0c1 0%, #faba7a 100%);
  border-radius: 13px;
  text-align: center;
  line-height: 25px;
  font-size: 13px;
  color: #4e2a03;
  font-weight: normal;
}
</style>