<template>
  <div class="hotel-wrap" v-if="!styleType && pageType !== 'member'">
    <div class="head">
      <div class="title">{{ title }}</div>
      <div class="desc">{{ desc }}</div>
    </div>
    <div class="content">
      <div class="coupon">
        <div class="value"><span>9</span>折</div>
        <div class="foot">
          <span>客房优惠券</span>
          <div class="btn">立即领取</div>
        </div>
      </div>
      <div class="coupon">
        <div class="value"><span>10</span>元</div>
        <div class="foot">
          <span>满200元可用</span>
          <div class="btn">立即领取</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["info", "styleType", "pageType"],
  data() {
    return {
      title: "",
      desc: "",
    };
  },
  mounted() {
    this.desc = this.info.desc;
    this.title = this.info.name;
  },
};
</script>

<style scoped lang="less">
.hotel-wrap {
  box-sizing: border-box;
  height: 125px;
  width: auto;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 12.5px;
  padding: 9px 13px 20px 13px;

  .head {
    display: flex;
    justify-content: space-between;
    font-family: PingFangSC, PingFang SC;

    .title {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
    }

    .desc {
      margin-top: 5px;
      font-size: 11px;
      font-weight: 400;
      color: #afb2b5;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  .coupon {
    border-radius: 8px;
    box-sizing: border-box;
    width: 157.5px;
    height: 60px;
    background: #fffbf8;
    padding-left: 12.5px;
    position: relative;

    .value {
      margin-top: 7.5px;
      font-size: 9px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #333333;
      span {
        font-size: 20px;
      }
    }

    .foot {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 11px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #afb2b5;

      .btn {
        padding: 1px 7px;
        border: 1px solid;
        color: rgba(230, 154, 123, 1);
        border-radius: 10px;
        margin-right: 6px;
      }
    }
  }
}
</style>
