<template>
  <div>
    <van-index-bar :index-list="indexList" :sticky="false">
      <template v-for="item in cityList">
        <van-index-anchor :key="item.groupName" :index="item.groupName">{{
          item.groupName
        }}</van-index-anchor>
        <van-cell
          v-for="city in item.list"
          :key="city"
          :title="city"
          v-ans="{
            event_id: 'destination_click_city',
            destination_name: city,
          }"
          @click="setCity(city)"
        />
      </template>
    </van-index-bar>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { IndexBar, IndexAnchor } from "vant";
Vue.use(IndexBar);
Vue.use(IndexAnchor);
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["akgb_shop"]),
    indexList() {
      let indexList = Object.values(this.akgb_shop).map((r) => r.first_word);
      return [...new Set(indexList)];
    },
    cityList() {
      const res = [];
      this.indexList.forEach((item) => {
        let arr = [];
        this.akgb_shop
          .filter((r) => r.first_word === item)
          .forEach((i) => {
            !arr.includes(i.city) && arr.push(i.city);
          });
        res.push({
          groupName: item,
          list: arr,
        });
      });
      return res;
    },
  },
  methods: {
    setCity(city) {
      this.$router.push(`/akBook?city=${city}`);
    },
  },
};
</script>
<style lang="less" scoped>
.select-city {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 9;
}
</style>