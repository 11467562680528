<template>
  <div class="member-wrap">
    <div @click="handleShowPopup" class="member">会员中心</div>
    <van-popup class="popup" v-model="show" position="bottom">
      <div class="title">我的会员</div>
      <div class="content">
        <div class="info-wrap" v-for="item of cardInfo" :key="item.card_number">
          <div class="info">
            <div class="card-name">{{ item.card_type_cn.slice(2) }}</div>
            <div class="card-msg">开通时间：{{ time(item.t_created) }}</div>
          </div>
          <div class="action" @click="showCard(item)">查看</div>
        </div>
      </div>
      <img
        class="close"
        src="@/assets/images/lc-close.png"
        @click="handleClose"
      />
    </van-popup>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: ["cardInfo"],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    time() {
      return function (value) {
        return dayjs(value).format("YYYY.MM.DD HH:mm:ss");
      };
    },
  },
  methods: {
    handleShowPopup() {
      this.show = true;
    },
    handleClose() {
      this.show = false;
    },

    showCard(card) {
      this.$emit("changeCard", card.namespace, card.token);
      this.show = false;
    },
  },
};
</script>

<style scoped lang="less">
.member-wrap {
  position: fixed;
  right: 0;
  top: 40%;
  z-index: 1;
}

.member {
  writing-mode: vertical-lr;
  background: #fef3ed;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.22);
  border-radius: 8px;
  padding: 11px 10px;
  letter-spacing: 4px;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #e69a7b;
  line-height: 16.5px;
}

.popup {
  box-sizing: border-box;
  border-radius: 10px 10px 0 0;
  height: 50%;
  padding: 0 16px;
  z-index: 99;

  .title {
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #222222;
    margin-top: 36px;
    margin-bottom: 15.5px;
  }

  .content {
    .info-wrap {
      padding: 18px 12px 19px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fffbf8;
      font-family: PingFangSC, PingFang SC;
      margin-bottom: 12px;
    }

    .card-name {
      font-size: 16px;
      font-weight: 400;
      color: #222222;
      line-height: 22.5px;
    }
    .card-msg {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 400;
      color: #afb2b5;
      line-height: 16.5px;
    }

    .action {
      border-radius: 13px;
      border: 1px solid #e69a7b;
      font-size: 10px;
      font-weight: 400;
      color: #e69a7b;
      line-height: 14px;
      padding: 3px 22px;
    }
  }

  .close {
    position: absolute;
    height: 17.5px;
    width: 17.5px;
    top: 13.5px;
    right: 16px;
  }
}
</style>
