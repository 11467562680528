<template>
  <div>
    <div
      class="benefit-desc"
      v-if="benefitInfo.benefit_instruction || benefitInfo.desc_img_url"
    >
      <div class="benefit-desc-label">权益介绍</div>
      <div class="instruction">
        <div
          v-if="benefitInfo.benefit_instruction"
          :class="{ 'rich-text': true, 'rich-text-more': more }"
          :key="selectSku.item_code"
          v-replace.day="benefitInfo.coupon_days"
          v-html="benefitInfo.benefit_instruction"
        ></div>
        <van-image lazy-load v-else :src="benefitInfo.desc_img_url" />
      </div>
    </div>
    <div class="benefit-desc">
      <div>
        <div class="benefit-desc-label">使用指南</div>
        <div class="instruction">
          <van-image
            lazy-load
            v-if="benefitInfo.manual_img_url"
            :src="benefitInfo.manual_img_url"
          />
          <div v-else :class="{ 'rich-text': true, 'rich-text-more': more }">
            <van-image
              v-if="selectSku.instruction_img_url"
              :src="selectSku.instruction_img_url"
            />
            <div
              @click="handleUseGuide"
              v-if="selectSku.instruction || selectSku.spu_instruction"
              :key="selectSku.item_code"
              v-replace.day="benefitInfo.coupon_days"
              v-html="selectSku.instruction"
              class="instruction-html"
            ></div>
          </div>
          <a
            v-if="benefitInfo.manual_extra_url"
            class="airport-list"
            :href="benefitInfo.manual_extra_url"
            className="airport-list"
            >{{ benefitInfo.manual_extra_link_name }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    benefitInfo: {
      type: Object,
      default: () => ({}),
    },
    selectSku: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      more: false,
    };
  },
  methods: {
    handleUseGuide(event) {
      const { special_info, product_name } = this.selectSku;
      if (product_name === "瑞鹏宠物") {
        const target = event.target;
        if (target.localName === "a") {
          // 获取到a标签上的链接
          const url = target.getAttribute("href");
          const template_id = JSON.parse(special_info)?.template_id;
          const handleUrl = `${url}?template_id=${template_id}`;
          target.href = handleUrl;
        }
      }
    },
  },
};
</script>