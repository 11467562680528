<template>
  <section class="cr cr-visitor" v-if="!fecting">
    <section class="header">
      <div class="header-package">
        <van-swipe
          class="header-swipe"
          indicator-color="white"
          :loop="false"
          @change="changePackage"
          :show-indicators="false"
        >
          <van-swipe-item
            v-for="(item, idx) in memberPackage"
            :key="idx"
            :class="{
              'slide-prev': idx + 1 === active,
              'slide-active': idx === active,
              'slide-next': active + 1 === idx,
              [`card-item-${item.item_code}`]: true,
            }"
          >
            <div class="swipe-item">
              <div class="card-savemoney">每年预计可省</div>
              <div class="card-savemoney-text">
                <span>{{ currentPackage.total_value }}</span
                >元
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="header-package-detail">
        <div class="header-package-desc-wrap">
          <van-image
            v-show="currentPackage.item_code === 'CR06'"
            lazy-load
            class="header-package-desc"
            :src="require('../../../assets/images/hr_visitor_carddesc06.png')"
          />
          <van-image
            v-show="currentPackage.item_code === 'CR12'"
            lazy-load
            class="header-package-desc"
            :src="require('../../../assets/images/hr_visitor_carddesc12.png')"
          />
        </div>
        <van-sticky :offset-bottom="-50" position="bottom">
          <div
            class="header-package-bar"
            @click="toBuy"
            v-ans="{ event_id: 'visitor_click_buy' }"
          >
            <div class="header-package-btn run">
              <div class="package-price">
                ¥<span class="price">{{ currentPackage.price }}</span
                >/{{ currentPackage.unit
                }}<span class="facevalue"
                  >¥{{ currentPackage.face_value }}/{{
                    currentPackage.unit
                  }}</span
                >
              </div>
              <div class="package-btn">立即开通</div>
            </div>
          </div>
        </van-sticky>
      </div>
    </section>
    <section class="body">
      <section class="waist-banner" v-if="loops.length">
        <van-swipe
          @click="swipeClick"
          class="waist-banner-swipe"
          :autoplay="3000"
          indicator-color="white"
        >
          <van-swipe-item v-for="(item, idx) in loops" :key="idx">
            <van-image
              class="waist-banner-img"
              fit="cover"
              lazy-load
              :src="item.logo_url"
            />
          </van-swipe-item>
        </van-swipe>
      </section>
      <section class="benefit-list">
        <div
          :class="['benefit-item', `benefit-item-${item.display_code}`]"
          v-for="item in catesgoryList"
          :key="item.display_code"
        >
          <div class="benefit-top">
            <div class="benefit-top-left">
              <div class="benefit-name">{{ item.name }}</div>
              <div class="benefit-desc">{{ item.desc }}</div>
            </div>
            <div class="benefit-top-right">
              <div class="benefit-savemonty">
                ¥<span>{{ item.saveMoney }}</span>
              </div>
              <div class="benefit-savemonty-text">每年预计可省</div>
            </div>
          </div>
          <ul :class="['benefit-spu', `benefit-spu-${item.display_code}`]">
            <li
              :class="['benefit-spu-item', `benefit-spu-${spu.product_code}`]"
              v-for="spu in item.list"
              :key="spu.id"
            >
              <div class="benefit-spu-inner">
                <van-image
                  class="benefit-spu-img"
                  lazy-load
                  fit="cover"
                  :src="spu.display_img_url"
                />
                <div class="benefit-spu-name">{{ spu.desc }}</div>
                <div class="benefit-spu-selling_points">
                  {{ spu.selling_points }}
                </div>
                <div class="benefit-spu-price" v-if="spu.lowest_price">
                  ¥<span>{{ spu.lowest_price }}</span>
                </div>
                <div class="benefit-spu-facevalue" v-if="spu.lowest_face_value">
                  原价<span>¥{{ spu.lowest_face_value }}</span>
                </div>
                <a
                  class="benefit-spu-link"
                  href="https://network.dragonpass.com.cn/airport/list?loungeType=lounge"
                  v-if="spu.product_code === 'train'"
                  >查看列表 ></a
                >
                <router-link
                  class="benefit-spu-link"
                  v-if="spu.product_code === 'hotel'"
                  to="/hotel/list?benefitType=hotel"
                  >查看列表 ></router-link
                >
              </div>
            </li>
          </ul>
        </div>
      </section>
    </section>
    <van-dialog
      :value="!!currentSpu"
      v-if="currentSpu"
      v-close="onClose"
      :show-confirm-button="false"
    >
      <div class="spu-name">{{ currentSpu.desc }}</div>
      <div class="btn">{{ currentPackage.price }}元</div>
    </van-dialog>
  </section>
</template>

<script>
import {
  getIndexCategory,
  getIndexClassList,
  getPackageList,
} from "@/apis/product";
import { useStore } from "@/components/use/base";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import Vue from "vue";
import { queryLoopData } from "@/apis/home";
import { Sticky } from "vant";

const vm = Vue.prototype;
Vue.use(Sticky);

export default defineComponent({
  props: {
    swipeClick: Function,
    toBuy: Function,
  },
  setup() {
    const store = useStore();
    const fecting = ref(true);
    const checked = ref(true);
    const active = ref(0);
    const loops = ref([]);
    const memberPackage = ref({});
    const currentSpu = ref(null);
    const currentPackage = ref({});
    const catesgoryList = ref([]);
    const showRule = () => {
      vm.$alert({
        title: "活动规则",
        message: store.state.namespaceInfo.rule,
        confirmButtonText: "我知道了",
      });
    };

    const changePackage = (index) => {
      active.value = index;
      currentPackage.value = memberPackage.value[index];
    };

    const fetchCategory = async () => {
      const catesgory = await getIndexCategory({
        namespace: store.state.namespace,
        opened_page: "visitor",
        source: store.state.source,
      });
      const catesgorys = catesgory.data.filter((r) => r.status === 1);
      const productList = await getIndexClassList({
        title: catesgorys.map((r) => r.name).join(","),
        opened_page: "visitor",
        namespace: store.state.namespace,
      });
      catesgorys.forEach((item, index) => {
        item.list = productList.data[item.name].filter((r) => r.status === 1);
        [item.name, item.saveMoney] = item.name.split("|");
      });
      catesgoryList.value = catesgorys;
    };

    const fetchPackage = async () => {
      const res = await getPackageList({
        namespace: store.state.namespace,
        source: store.state.source,
      });
      memberPackage.value = res.data;
      if (res.data.length) {
        changePackage(0);
      }
    };

    const fetchLoopData = async () => {
      const res = await queryLoopData({ activity_type: "cr_visitor" });
      loops.value = res.data.data_list;
    };

    const showDialog = (spu) => {
      currentSpu.value = spu;
    };

    const onClose = () => {
      currentSpu.value = null;
    };

    onMounted(() => {
      vm.$alert({
        title: "温馨提示",
        message:
          "感谢您一路以来的支持与厚爱！因华润通经营策略调整，通享卡现已暂停发售。",
        showConfirmButton: false,
        hideClose: true,
        overlayClass: "hrt-overlay",
      });
      document.title = "通享卡会员";
      const loading = vm.$loading();
      try {
        Promise.all([fetchPackage(), fetchCategory(), fetchLoopData()]);
      } finally {
        fecting.value = false;
        loading.clear();
      }
    });
    return {
      changePackage,
      showRule,
      currentSpu,
      showDialog,
      onClose,
      catesgoryList,
      memberPackage,
      fecting,
      checked,
      active,
      loops,
      currentPackage,
    };
  },
});
</script>


<style lang="less" scoped>
.cr {
  background: #fff;
  padding-bottom: 100px;
  .header {
    background: url("../../../assets/images/hr_visitor_bg.png") no-repeat
      top/100%;
  }
  .header-package {
    height: 160px;
    /deep/ .van-swipe-item {
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
    }
    .swipe-item {
      width: 280px !important;
      height: 120px;
      background-repeat: no-repeat;
      background-size: 100%;
      padding-left: 16px;
      color: #222539;

      box-sizing: border-box;
    }
    .slide-prev,
    .slide-next {
      transform: scale(0.9);
    }
    .slide-prev {
      .swipe-item {
        margin-right: -175px;
      }
    }
    .slide-next {
      .swipe-item {
        margin-left: -175px;
      }
    }
  }

  .card-savemoney {
    margin-top: 60px;
    line-height: 17px;
  }
  .card-savemoney-text {
    span {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .card-item-CR06 {
    .swipe-item {
      background-image: url("../../../assets/images/hr_visitor_card06.png");
    }
  }
  .card-item-CR12 {
    .swipe-item {
      background-image: url("../../../assets/images/hr_visitor_card12.png");
    }
  }
  .header-package-detail {
    height: 385px;
    padding-top: 24px;
    box-sizing: border-box;
    /deep/ .van-sticky--fixed {
      box-sizing: border-box;
      top: inherit !important;
      bottom: 20px !important;
      animation: move90 0.3s;
      background-size: cover;
    }
  }

  @keyframes move90 {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .header-package-desc-wrap {
    height: 240px;
    margin: 0 15px;
  }
  .header-package-desc {
    height: 100%;
  }
  .header-package-bar {
    padding: 24px 12px 0;
  }
  .header-package-btn {
    background-color: rgba(0, 0, 0, 0.3);
    height: 52px;
    display: flex;
    align-items: center;
    color: #9d5832;
    border-radius: 26px;
    background: url("../../../assets/images/cr_open_bg.png") no-repeat
      center/100%;
  }
  .package-price {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3;
    line-height: 25px;
    height: 25px;
    align-items: baseline;
    .price {
      font-size: 24px;
      font-weight: bold;
      padding-left: 5px;
      padding-right: 3px;
    }
    .facevalue {
      text-decoration: line-through;
      padding-left: 5px;
    }
  }
  .package-btn {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #efddd4;
    font-size: 18px;
  }

  .body {
    margin-top: -24px;
    background: #fff;
    border-radius: 16px 16px 0 0;
    padding: 24px 16px 0 16px;
  }

  .waist-banner {
    height: 90px;
    margin-bottom: 24px;
  }
  .waist-banner-swipe,
  .waist-banner-img {
    height: 90px;
  }

  .benefit-item {
    margin-bottom: 20px;
  }
  .benefit-top {
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    box-sizing: border-box;
    padding-left: 16px;
    margin-bottom: 8px;
    color: #78360f;
    display: flex;
    align-items: center;
    background-image: url("../../../assets/images/hr_visitor_benefit_top_bg.png");
  }
  .benefit-top-left {
    flex: 12;
  }
  .benefit-top-right {
    flex: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    flex-direction: column;
    line-height: 28px;
    font-size: 10px;
    color: #8c4d28;
    span {
      font-size: 20px;
      padding-left: 2px;
      font-weight: bold;
    }
    .benefit-savemonty {
      line-height: 28px;
      height: 28px;
    }
    .benefit-savemonty-text {
      line-height: 16px;
    }
  }

  .benefit-name {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 4px;
    font-weight: bold;
  }
  .benefit-desc {
    line-height: 16px;
  }

  .benefit-spu {
    display: flex;
    flex-wrap: wrap;
  }

  .benefit-spu-item {
    color: #78360f;
    .benefit-spu-name {
      line-height: 18px;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .benefit-spu-price,
    .benefit-spu-facevalue {
      line-height: 16px;
      font-size: 12px;
    }
  }
  .benefit-spu-inner {
    position: relative;
  }

  .benefit-spu-starbucks,
  .benefit-spu-ximalaya,
  .benefit-spu-shopping,
  .benefit-spu-jingjiang,
  .benefit-spu-music {
    .benefit-spu-name,
    .benefit-spu-price,
    .benefit-spu-selling_points,
    .benefit-spu-facevalue {
      display: none;
    }
  }

  .benefit-spu-phone {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .benefit-spu-item {
      width: 98px;
      height: 106px;
      border: 1px solid #ebc6ad;
      border-radius: 8px;
      margin-right: 8px;
      box-sizing: border-box;
      padding: 2px;
      flex-shrink: 0;
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-top: 8px;
      .benefit-spu-price,
      .benefit-spu-facevalue {
        display: none;
      }
    }
    .benefit-spu-img {
      width: 40px;
      height: 40px;
      margin: 0 auto 8px;
    }
  }

  .benefit-spu-video {
    margin: 0 -4px;
    .benefit-spu-item {
      width: 50%;
      box-sizing: border-box;
      padding: 0 4px;
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 8px;
      }
    }
    .benefit-spu-img {
      height: 136px;
      margin-bottom: 8px;
      border-radius: 8px;
      overflow: hidden;
    }
    .benefit-spu-facevalue {
      text-decoration: line-through;
    }
    .benefit-spu-price {
      display: none;
    }
  }
  .benefit-spu-music {
    .benefit-spu-inner {
      width: 100%;
      height: 100%;
    }
    .benefit-spu-img {
      width: 100%;
      height: 110px;
    }
  }
  .benefit-spu-ximalaya {
    .benefit-spu-inner {
      width: 100%;
      height: 100%;
    }
    .benefit-spu-img {
      width: 100%;
      height: 80px;
    }
  }
  .benefit-spu-shopping {
    margin: 0 -4px;
    .benefit-spu-item {
      width: 50%;
      height: 81px;
      margin-bottom: 8px;
      padding: 0 4px;
      box-sizing: border-box;
      &:not(:last-child) {
        .benefit-spu-btn {
          position: absolute;
          bottom: 8px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:last-child {
        margin-bottom: 0px;
        width: 100%;
        height: 80px;
        .benefit-spu-img {
          height: 80px;
        }
        .benefit-spu-btn {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .benefit-spu-img {
        height: 81px;
      }
    }
  }

  .benefit-spu-travel {
    margin: 0 -4px;
    .benefit-spu-item {
      padding: 0 4px;
      flex: 1;
    }
    .benefit-spu-img {
      height: 80px;
      margin-bottom: 8px;
    }
    .benefit-spu-selling_points {
      margin-bottom: 4px;
    }
    .benefit-spu-price,
    .benefit-spu-facevalue {
      display: inline-block;
    }
    .benefit-spu-price {
      span {
        padding-left: 3px;
      }
    }
    .benefit-spu-facevalue {
      span {
        text-decoration: line-through;
      }
      font-size: 10px;
      margin-left: 5px;
    }
  }

  .benefit-spu-jingjiang {
    .benefit-spu-item {
      flex: 1;
    }
    .benefit-spu-img {
      height: 80px;
    }
  }

  .benefit-spu-jingjiang,
  .benefit-spu-starbucks {
    .benefit-spu-btn {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
  }

  .benefit-spu-hotel {
    .benefit-spu-price,
    .benefit-spu-facevalue {
      span {
        &::after {
          content: "起";
        }
      }
    }
  }
}

.benefit-spu-link {
  color: #78360f;
  display: inline-block;
  padding-top: 5px;
}
</style>

<style lang="less">
.hrt-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}
</style>