<template>
  <div>
    <template>
      <div class="info-wrap" v-if="pageType === 'visitor'">
        <div class="text">
          {{ activeCard === "licheng6" ? "权益价值" : "开通可省"
          }}<span>{{ disValue }}</span
          >元/年
        </div>
        <div class="price">
          <template v-if="activeCard !== 'licheng6'">
            <span class="unit">¥</span
            ><span class="count">{{ faceValue }}</span>
            /年
          </template>
          <template v-else>
            <span class="gold-color">{{ faceValue }}</span>
          </template>
        </div>
      </div>
      <div class="member-wrap" v-else>
        <div class="title">
          <!-- <img
            src="https://common-h5-dev.xiangshuheika.com/static/upload/20243706ad18dbd4504cda8320c15df6abdf0c.png"
          /> -->
          {{ title }}
        </div>
        <div class="time">{{ time }}</div>
      </div>
    </template>
    <template v-if="styleType && activeCard !== 'licheng6'">
      <img :src="info.display_img_url" class="card-img" />
    </template>
  </div>
</template>

<script>
export default {
  props: [
    "info",
    "styleType",
    "activeCard",
    "pageType",
    "cardInfo",
    "packageInfo",
  ],
  data() {
    return {
      disValue: "",
      // faceValue: "",
    };
  },
  computed: {
    title() {
      return {
        licheng3: "尊享卡",
        licheng4: "省钱卡",
        licheng5: "经典卡",
        licheng6: "会员",
      }[this.activeCard];
    },
    time() {
      const value =
        this.cardInfo.filter((item) => item.namespace === this.activeCard)[0]
          ?.t_expiration || "";
      return value ? `有效期至 ${value}` : "";
    },
    faceValue() {
      if (this.styleType) {
        return this.packageInfo.item_price;
      }
      if (this.activeCard === "licheng6") {
        return "免费领取";
      }
      return this.packageInfo.renew_price;
    },
  },

  mounted() {
    const priceArr = this.info.desc.split("｜");
    if (this.styleType && this.activeCard !== "licheng6") {
      const hasPackage = priceArr[1].split("，");
      this.disValue = hasPackage[0];
      return;
    }
    if (this.activeCard === "licheng6") {
      this.disValue = priceArr[0];
      return;
    }
    const notPackage = priceArr[0].split("，");
    this.disValue = notPackage[0];
  },
};
</script>

<style scoped lang="less">
.info-wrap {
  width: auto;
  height: 76px;
  background: url("https://common-h5-dev.xiangshuheika.com/static/upload/2024374ad89a5a707042bbaa7107247509fff9.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;

  .text {
    font-size: 11px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #ddcca3;
    line-height: 22px;
    margin-left: 14px;
    margin-top: 29px;
    > span {
      font-size: 18px;
      margin: 3px;
    }
  }
  .price {
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    margin-right: 31px;
    align-items: center;
    align-content: flex-end;
    .unit {
      color: rgba(240, 85, 37, 1);
    }
    .count {
      font-size: 30px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #f05525;
      margin: 0 5px;
    }

    .gold-color {
      color: rgba(208, 192, 154, 1);
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #d0c09a;
    }
  }
}

.member-wrap {
  overflow: hidden;
  width: auto;
  height: 76px;
  background: url("https://common-h5-dev.xiangshuheika.com/static/upload/2024374ad89a5a707042bbaa7107247509fff9.png");
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #ddcca3;

  .title {
    font-size: 17px;
    margin-top: 11px;
    margin-left: 15px;
    display: flex;
    align-items: center;

    > img {
      margin-right: 4.5px;
      height: 16px;
      width: 17px;
    }
  }
  .time {
    font-size: 10.5px;
    position: absolute;
    bottom: 9.5px;
    right: 11.5px;
  }
}

.card-img {
  width: 100%;
  margin-top: 12px;
}
</style>
