<template>
  <div class="wrap">
    <div class="header" :class="namespace">
      <div class="contacts" @click="toService">
        <van-icon name="phone" class="icon" />联系客服
      </div>
    </div>

    <div class="main" :class="namespace">
      <div class="code-redeem">
        <div class="code-inp-wrap">
          <input
            class="code-inp"
            placeholder="请输入兑换码 "
            v-model="inpValue"
          />
        </div>
        <div class="redeem-btn" @click="handleDraw">立即兑换</div>
      </div>
    </div>

    <div class="content" :class="namespace">
      <div v-if="!benefitList.length" class="empty-wrap">
        <p>空空如也，快去兑换吧！</p>
      </div>
      <div class="product" v-else>
        <div class="tip" :class="namespace" />
        <div>
          <p class="title">{{ cardTypeCn }}</p>
          <p class="time">
            会员有效期 {{ createTime | formatDate("YYYY.MM.DD") }}-{{
              endTime | formatDate("YYYY.MM.DD")
            }}
          </p>
        </div>
        <div @click="toUse" class="use-btn">去使用</div>
      </div>
    </div>
    <CommonPopup :show.sync="showPopup">
      <div class="popup-wrap" :class="namespace">
        <div class="popup-img"></div>
        <div class="popup-content">
          <h5>恭喜你兑换成功</h5>
          <p>{{ memberCardName }}</p>
          <div class="btn" @click="toUse">去使用</div>
        </div>
      </div>
    </CommonPopup>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from "@vue/composition-api";
import Vue from "vue";

import { goCashing } from "@/apis/home";
import { useRouter, useStore } from "@/components/use/base";
import { getUserBenefit } from "@/apis/home";
import LoginDialog from "@/components/loginDialog";
import CommonPopup from "@/components/CommonPopup";

const vm = Vue.prototype;

export default defineComponent({
  components: {
    CommonPopup,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const benefitList = ref([]);
    const inpValue = ref("");
    const showPopup = ref(false);

    const namespace = store.state.namespace;

    const isLogin = computed(() => !!store.state.token);
    const isMember = computed(() => store.state.isMember);
    const createTime = computed(() => store.state.userInfo.t_vip_created);
    const endTime = computed(() => store.state.userInfo?.card_expiry_date?.[0]);
    const cardTypeCn = computed(() => store.state.userInfo.card_type_cn);

    const memberCardName = computed(() => {
      return {
        licheng7: "热门会员优惠购",
        haixin: "热⻔移动端会员6选1季卡",
      }[namespace];
    });

    const initData = async () => {
      const { data } = await getUserBenefit();
      data?.benefit_list?.length
        ? (benefitList.value = data.benefit_list)
        : (benefitList.value = []);
    };

    const handleDraw = async () => {
      if (!inpValue.value) {
        vm.$toast("请输入兑换码");
        return;
      }
      if (!isLogin.value) {
        vm.$toast("请先登录");
        await login();
      }
      const loading = vm.$loading();
      try {
        await goCashing({
          cashing_code: inpValue.value,
        });
        store.dispatch("fetchUserInfo");
        initData();
        inpValue.value = "";
        showPopup.value = true;
      } catch (error) {
        console.log(error);
      } finally {
        loading.clear();
      }
    };

    const toUse = () => {
      const jumpToCommon = vm.$getConfigValue("JUMPTO_COMMON_REDEEM");
      if (jumpToCommon) {
        router.push("/common-redeem");
        return;
      }
      router.push("/dc-member");
    };

    const login = async () => {
      await LoginDialog.show({
        title: "手机号登录后使用",
        className: `__${namespace}-login`,
      });
      isMember.value && (await initData());
    };

    const toService = () => {
      router.push("/service");
    };

    onMounted(() => {
      !isLogin.value ? login() : initData();
    });

    return {
      toService,
      handleDraw,
      benefitList,
      createTime,
      endTime,
      toUse,
      inpValue,
      cardTypeCn,
      namespace,
      showPopup,
      memberCardName,
    };
  },
});
</script>

<style lang="less" scoped>
.wrap {
  background: #ecf9ff;
  min-height: 100vh;
  padding-bottom: 34px;
}
.header {
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  height: 385px;
  position: relative;

  .contacts {
    position: absolute;
    display: flex;
    align-items: center;
    background: linear-gradient(270deg, #f39378 0%, #e35a43 100%);
    border-radius: 12px 0px 0px 12px;
    right: 0;
    top: 15px;
    padding: 1px 8px;

    font-size: 11px;
    color: #ffffff;
    line-height: 23px;
    text-align: center;
    .icon {
      margin-right: 4px;
    }
  }
  &.licheng7 {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/202410181fcfea31d5644.png);
    height: 356px;
    .contacts {
      background: linear-gradient(270deg, #f02140 0%, #f8425c 100%);
    }
  }
  &.haixin {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20241011f97c6afc031ef.png);
    height: 356px;
    .contacts {
      background: linear-gradient(270deg, #f02140 0%, #f8425c 100%);
    }
  }
  &.dc_2407_test {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20240905b3ccaf052b19a.png);
    height: 385px;
  }
}
.main {
  margin: 0 12px;
  margin-bottom: 13px;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  height: 223px;
  position: relative;
  .code-redeem {
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 16px;
    top: 85px;
    left: 50%;
    transform: translateX(-50%);
    .code-inp-wrap {
      margin: 0 auto;
      width: 294px;
      height: 44px;
      border-radius: 22px;
      border: 1px solid #b9b9b9;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .code-inp {
      border: none;
      width: 240px;
      &::placeholder {
        text-align: left;
        color: #999999;
        font-size: 14px;
      }
    }
    .redeem-btn {
      margin: 0 auto;
      margin-top: 16px;
      width: 294px;
      height: 44px;
      background: linear-gradient(270deg, #f39378 0%, #e35a43 100%);
      border-radius: 22px;
      font-family: AppleSystemUIFont;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 44px;
      text-align: center;
    }
  }
  &.haixin,
  &.licheng7 {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/2024101108a309faa40bd.png);
    .redeem-btn {
      background: linear-gradient(270deg, #f02140 0%, #f8425c 100%);
    }
  }
  &.dc_2407_test {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/2024090521ab4c9c8a8ae.png);
  }
}
.content {
  margin: 0 12px;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  height: 183px;
  position: relative;
  overflow: hidden;
  &.dc_2407_test {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20240905b967ab09e9dc4.png);
  }
  &.haixin,
  &.licheng7 {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20241011861ca1f11166.png);
  }
}
.empty-wrap {
  margin: 0 16px;
  margin-top: 105px;
  box-sizing: border-box;
  > p {
    font-weight: 400;
    font-size: 13px;
    color: #999999;
    line-height: 18px;
    text-align: center;
  }
}
.product {
  background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20240905e4329db669c57.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 83px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 72px;
  width: 316px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px 0 27px;
  .title {
    font-weight: 600;
    font-size: 13px;
    color: #333333;
    line-height: 18px;
  }
  .time {
    font-weight: 400;
    font-size: 10px;
    color: #666666;
    line-height: 14px;
    margin-top: 3px;
  }
  .use-btn {
    width: 60px;
    height: 26px;
    background: #fffefa;
    border-radius: 13px;
    text-align: center;
    font-weight: 500;
    font-size: 11px;
    color: #dc4f50;
    line-height: 26px;
  }
  .tip {
    background-repeat: no-repeat !important;
    background-size: 100% !important;
    width: 62px;
    height: 23px;
    position: absolute;
    left: -7px;
    top: -4px;
    &.dc_2407_test {
      background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20240905d4b80bd303de4.png);
    }
    &.haixin {
      background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20241011f92eef53576a9.png);
    }
    &.licheng7 {
      background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20241018e434c84939d1c.png);
    }
  }
}
.popup-wrap {
  padding-top: 49px;
  overflow: hidden;
  position: relative;
  .popup-img {
    width: 103px;
    height: 87px;
    position: absolute;
    background: url("https://common-h5-dev.xiangshuheika.com/static/upload/2024101272fd70091f402.png");
    background-repeat: no-repeat !important;
    background-size: 100% !important;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .popup-content {
    width: 332px;
    height: 264px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    > h5 {
      margin-top: 74px;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      line-height: 22px;
      text-align: center;
    }
    > p {
      margin-top: 4px;
      font-weight: 500;
      font-size: 24px;
      color: #000000;
      line-height: 33px;
      text-align: center;
    }
    > .btn {
      margin: 43px 19px 0 19px;
      height: 44px;
      background: linear-gradient(270deg, #f39378 0%, #e35a43 100%);
      border-radius: 22px;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      line-height: 44px;
      text-align: center;
    }
  }
  &.haixin,
  &.licheng7 {
    .btn {
      background: linear-gradient(270deg, #f02140 0%, #f8425c 100%);
    }
  }
}
</style>

<style lang="less">
.__dc_2407_test-login {
  .van-button {
    background: #e35a43;
    color: #fff;
  }
}
.__haixin-login,
.__licheng7-login {
  .van-button {
    background: linear-gradient(270deg, #f02140 0%, #f8425c 100%);
    color: #fff;
  }
}
</style>
