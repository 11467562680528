import { render, staticRenderFns } from "./foodFree.vue?vue&type=template&id=341e5f70&scoped=true&"
import script from "./foodFree.vue?vue&type=script&lang=js&"
export * from "./foodFree.vue?vue&type=script&lang=js&"
import style0 from "./foodFree.vue?vue&type=style&index=0&id=341e5f70&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "341e5f70",
  null
  
)

export default component.exports