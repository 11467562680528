<template>
  <section class="hotel-list">
    <header class="hotel-list-header">
      <div v-if="showHotelBack" class="hotel-back" @click="$router.back()" />
      <div class="hotel-list-header-inner">
        <div
          class="hotel-list-header-city"
          v-ans="{
            event_id: 'hotel_list_click_destination',
          }"
          @click="toCity"
        >
          {{ hotel.city }}
        </div>
        <div
          class="hotel-list-header-date"
          @click="showCalcader"
          v-ans="{
            event_id: 'hotel_list_click_checkdate',
          }"
        >
          {{ hotel.checkin_date | formatDate("MM月DD日") }} <small>—</small>
          {{ hotel.checkout_date | formatDate("MM月DD日") }}
        </div>
        <div class="hotel-list-header-night">共{{ interNight }}晚</div>
      </div>
      <div class="prompt" v-if="showPrompt">
        <span>试试其他地点</span> <svg-icon @click="close" icon-class="close" />
      </div>
      <div class="hotel-tab" v-if="$getConfigValue('SHOW_HOTEL_LEVEL')">
        <div
          :class="[
            'item',
            hotel.hotel_levels_current === index ? `active${index}` : null,
          ]"
          :key="index"
          @click="hotelTab(index)"
          v-for="(item, index) in hotel_levels"
        >
          <div class="box">
            {{ item }}元
            <span v-if="hotel.hotel_levels_current === index">会员专享价</span>
          </div>
        </div>
      </div>
    </header>

    <div
      :class="[$getConfigValue('SHOW_HOTEL_LEVEL') ? 'height100' : 'height48']"
    ></div>
    <header class="hotel-list-body" v-if="list.length">
      <hotel-item
        v-for="item in list"
        :data="item"
        :iconNames="iconNames"
        :key="item.id"
      />
    </header>
    <van-empty v-else description="暂无酒店" />
  </section>
</template>

<script>
import { getHotelList, getHotelPriceLevels } from "@/apis/hotel";
import { mapGetters, mapMutations, mapState } from "vuex";
import HotelItem from "./HotelItem";
import SelectCalendar from "@/components/SelectCalendar";
import ans from "@/utils/ans";
import dayjs from "dayjs";
import { storage } from "@/utils";
import qs from "qs";

const tagicons = require.context(
  "@/icons",
  false,
  /tag-[\u4e00-\u9fa5]+\.svg$/
);
const iconNames = tagicons.keys().map((item) => {
  const m = item.match(/tag-([\u4e00-\u9fa5]+)\.svg$/);
  if (m) {
    return m[1];
  }
  return "";
});
export default {
  name: "hotelList",
  data() {
    return {
      list: [],
      hotel_levels: [],
      iconNames,
      showPrompt: !storage.getItem("hidePrompt"),
    };
  },
  components: {
    HotelItem,
  },
  computed: {
    ...mapState(["hotel", "namespace", "benefitHotelType"]),
    ...mapGetters([
      "interNight",
      "namespaceGroup",
      "showHotelBack",
      "maxCheckinDate",
    ]),
  },
  async mounted() {
    ans.pageView(this.$route.meta.page, {
      namespace: this.namespace,
      destination_name: this.hotel.city,
      hotel_benefit_type: this.benefitHotelType,
      checkin_date: dayjs(this.hotel.checkin_date).format("YYYY-MM-DD"),
    });
    const { levels_current } = this.$route.query;
    if (levels_current && this.hotel.levels_current === 0) {
      this.changeHotel({
        hotel_levels_current: Number(levels_current),
      });
    }
    this.fetchHotelList(true);
  },
  methods: {
    ...mapMutations(["changeHotel"]),
    close() {
      this.showPrompt = false;
      storage.setItem("hidePrompt", 1);
    },
    async fetchHotelList() {
      const loading = this.$loading();
      if (this.$getConfigValue("SHOW_HOTEL_LEVEL")) {
        const res = await getHotelPriceLevels({
          checkin_date: this.hotel.checkin_date,
        });
        this.hotel_levels = res.data;
      }
      // is_family_hotel 1 亲子酒店
      // is_family_hotel 0 非亲子酒店
      // is_family_hotel None 所有酒店
      const search = qs.parse(location.search, { ignoreQueryPrefix: true });
      const params = {
        namespace: this.namespace || this.namespaceGroup,
        checkin_date: this.hotel.checkin_date,
        checkout_date: this.hotel.checkout_date,
        destination_name: this.hotel.city,
        is_family_hotel:
          search.is_family_hotel ||
          this.$getConfigValue("DEFAULT_FAMILY_HOTEL"),
      };

      if (this.benefitHotelType === "ehotel") {
        params.classification = 3;
        params.namespace = `${params.namespace}-affordable`;
      }
      if (this.$getConfigValue("SHOW_HOTEL_LEVEL")) {
        params.level = `${this.hotel_levels[this.hotel.hotel_levels_current]}`;
      }
      try {
        const res = await getHotelList(params);
        this.list = res.data.hotel_list;
      } finally {
        loading.clear();
      }
    },
    showCalcader() {
      SelectCalendar.show({
        rangeDate: [
          dayjs(this.hotel.checkin_date).startOf("day"),
          dayjs(this.hotel.checkout_date).startOf("day"),
        ],
        type: "hotel",
        minDate: this.hotel.minDate,
        maxDate: this.maxCheckinDate,
        range: this.hotel.hotel_advance_range,
        tips: this.hotel.calendar_choice_tips,
        single: false,
      }).then((rangeDate) => {
        this.changeHotel({
          checkin_date: rangeDate[0].valueOf(),
          checkout_date: rangeDate[1].valueOf(),
        });
        this.fetchHotelList();
      });
    },
    toCity() {
      this.close();
      this.$router.push("/hotel/city");
    },
    hotelTab(index) {
      sessionStorage.setItem("levels_current", index);
      this.changeHotel({
        hotel_levels_current: index,
      });
      this.fetchHotelList();
    },
  },
};
</script>

<style lang="less" scoped>
.hotel-back {
  width: 24px;
  height: 24px;
  background: url("~@/assets/images/hotel_back.png") no-repeat;
  background-size: 100%;
  margin-right: 10px;
}
.height48 {
  height: 62px;
}
.height100 {
  height: 120px;
}
.hotel-list {
  background: #f6f6f6;
}
.hotel-list-header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  background: #fff;
  padding: 12px 12px 0px;
  box-sizing: border-box;
}

.hotel-list-header-inner {
  flex: 1;
  height: 40px;
  background: #f7f7f7;
  border-radius: 24px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 12px;
}
.hotel-list-header-date {
  width: 150px;
  color: #333;
  font-weight: bold;
  line-height: 18px;
  height: 18px;
  padding-left: 14px;
  font-size: 14px;
  span {
    font-weight: normal;
  }
}
.hotel-list-header-city {
  color: #333;
  padding-left: 8px;
  padding-right: 21px;
  border-right: 1px solid #dcdcdc;
  position: relative;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    right: 8px;
    top: 7px;
    border-top: 4px solid #333;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
  }
}
.hotel-list-header-night {
  color: #999;
  font-size: 12px;
  margin-left: auto;
}
.hotel-list-body {
  padding: 14px 12px 20px;
}

.hotel-tab {
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  background-color: #fff;
  padding: 0 12px;
  margin-top: 17px;
}
.hotel-tab .item {
  flex: 1;
  height: 39px;
  background: #f4f4f4;
  border-radius: 9px 9px 0px 0px;
  position: relative;
}
.hotel-tab .item .box {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  font-size: 14px;
  color: #333;
}
.hotel-tab .item .box span {
  font-weight: normal;
  color: #333;
  font-size: 11px;
  line-height: 20px;
}
.hotel-tab .item:nth-child(2) {
  background-color: #ededed;
}
.hotel-tab .active0 {
  height: 51px;
  background: none;
  font-weight: bold;
}
.hotel-tab .active0 .box {
  background: url("https://cdn.xiangshuheika.com/static/oak/hotel-levels-bg0.png")
    no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  width: 104%;
}
.hotel-tab .active1 {
  height: 51px;
  background: none !important;
  font-weight: bold;
}
.hotel-tab .active1 .box {
  background: url("https://cdn.xiangshuheika.com/static/oak/hotel-levels-bg1.png")
    no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  width: 104%;
  left: -2%;
}
.hotel-tab .active2 {
  height: 51px;
  background: none;
  font-weight: bold;
}
.hotel-tab .active2 .box {
  background: url("https://cdn.xiangshuheika.com/static/oak/hotel-levels-bg2.png")
    no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  width: 104%;
  left: auto;
  right: 0;
}

.prompt {
  position: absolute;
  height: 28px;
  left: 2px;
  background: rgba(#000, 0.77);
  color: #fff;
  padding: 0 6px;
  border-radius: 6px;
  top: 50px;
  z-index: 10;
  padding-top: 7px;
  box-sizing: border-box;
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    color: #4a4a4a;
    top: 0;
    border-top-width: 0;
    border-bottom-color: currentColor;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
    left: 50%;
  }
  span {
    font-size: 11px;
    border-right: 1px solid rgba(#fff, 0.3);
    padding-right: 5px;
  }
  .svg-icon {
    font-size: 10px;
    margin-left: 2px;
  }
}
</style>