<template>
  <section></section>
</template>


<script>
import { storage } from "@/utils";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      memberPackage: {},
      loading: true,
      cardInfo: {
        last_time: 0,
      },
    };
  },
  computed: {
    ...mapState(["namespace"]),
  },
  async mounted() {
    if (!storage.getItem("pagj_redirect")) {
      this.$alert({
        hideClose: true,
        title: "温馨提示",
        confirmBtnText: "好的",
        message:
          "即将进入第三方页面，本产品服务均由服务商“橡树黑卡”提供，请知悉",
      }).then(() => {
        storage.setItem("pagj_redirect", 1);
        this.$router.replace(this.$route.query.redirect);
      });
    } else if (this.$route.query.redirect) {
      this.$router.replace(this.$route.query.redirect);
    }
  },
};
</script>

<style lang="less" scoped>
.receive-btn {
  position: relative;
  margin: 30px 20px 0;
  height: 49px;
  line-height: 49px;
  border-radius: 49px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  .count-down {
    position: absolute;
    top: -10px;
    right: 0;
    border: 1px solid #ffffff;
    width: max-content;
    color: rgb(#fff, 0.8);
    line-height: 20px;
    height: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fc6f1a;
    padding: 0 5px;
  }
}

.pagj-agreement {
  margin-top: 8px;
  color: #999;
  padding-left: 24px;
  margin-bottom: 25px;
  a {
    color: #4892f0;
  }
}

.pa-card-receive {
  position: absolute;
  left: 0;
  top: 0;
  height: 26px;
  padding: 0 8px;
  line-height: 26px;
  background: rgba(#fff, 0.4);
  border-bottom-right-radius: 10px;
  color: #222;
  padding-left: 31px;
  background-size: 18px;
  background-position: 10px center;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/pa_gjopen_icon_active.png");
}
</style>