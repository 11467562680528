var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"business-park"},[(_vm.current.namespace)?_c('div',{staticClass:"park-location"},[_c('div',{staticClass:"park-location-title"},[_vm._v("离我最近商圈")]),_c('div',{staticClass:"park-list"},[_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'business_park_click',
          namespace: _vm.namespace,
          park_name: _vm.current.title,
        }),expression:"{\n          event_id: 'business_park_click',\n          namespace,\n          park_name: current.title,\n        }"}],class:{
          'park-list-item': true,
          'park-list-item-active': _vm.current.namespace === _vm.namespace,
        },on:{"click":function($event){return _vm.changePark(_vm.current)}}},[_c('div',{staticClass:"park-col"},[_c('div',[_c('div',{staticClass:"park-name"},[_vm._v(_vm._s(_vm.current.title))]),_c('div',{staticClass:"park-address"},[_vm._v(" "+_vm._s(_vm.current.address)+" ")])]),_c('div',{staticClass:"park-distance"},[_vm._v(" "+_vm._s(_vm.current.distance)+_vm._s(_vm.current.unit)+" ")])])])])]):_vm._e(),(_vm.list.length)?_c('div',{staticClass:"park-location"},[_c('div',{staticClass:"park-location-title"},[_vm._v("更多商圈")]),_c('div',{staticClass:"park-list"},_vm._l((_vm.list),function(item){return _c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'business_park_click',
          namespace: _vm.namespace,
          park_name: item.title,
        }),expression:"{\n          event_id: 'business_park_click',\n          namespace,\n          park_name: item.title,\n        }"}],key:item.namespace,class:{
          'park-list-item': true,
          'park-list-item-active': item.namespace === _vm.namespace,
        },on:{"click":function($event){return _vm.changePark(item)}}},[_c('div',{staticClass:"park-col"},[_c('div',{staticClass:"park-name"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"park-distance"},[_vm._v(_vm._s(item.distance)+_vm._s(item.unit))])]),_c('div',{staticClass:"park-address"},[_vm._v(" "+_vm._s(item.address)+" ")])])}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }