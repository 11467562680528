<template>
  <div class="benefit-detail" v-if="!fetching">
    <div class="benefit-header">
      <van-image
        lazy-load
        class="benefit-header-img"
        fit="cover"
        :src="sku.image_url"
      />
    </div>
    <div class="benefit-info">
      <div class="benefit-info-inner">
        <div class="benefit-name">
          <div class="benefit-icon">
            <van-image
              src="https://common-h5-dev.xiangshuheika.com/static/upload/20238227083ce95b17c441095879812526d97af.png"
            ></van-image>
          </div>
          <div class="benefit-header-info">
            <div class="benefit-title">可免费领取1次</div>
            <div class="benefit-subtitle">{{ sku.item_name }}</div>
          </div>
        </div>
        <div class="benefit-sku">
          <div class="benefit-sku-input">
            <div class="benefit-input-label">充值手机号：</div>
            <van-field
              class="benefit-input"
              :value="phone"
              @input="changeAccount"
            />
          </div>
        </div>
        <div class="benefit-btn-wrap">
          <van-button
            block
            @click="submit"
            :disabled="disabled"
            class="benefit-btn"
            round
            >{{ disabled ? "已领取" : "立即领取" }}</van-button
          >
        </div>
      </div>
    </div>
    <div class="benefit-desc">
      <div class="benefit-desc-label">权益介绍</div>
      <div class="instruction">
        <switch-text :max-height="300">
          <div class="rich-text" v-html="sku.special_tips"></div>
        </switch-text>
      </div>
    </div>
    <div class="benefit-desc">
      <div class="benefit-desc-label">使用指南</div>
      <div class="instruction">
        <switch-text :max-height="300">
          <van-image :src="sku.instruction_img_url" />
          <div class="rich-text" v-html="sku.instruction"></div>
        </switch-text>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import SwitchText from "@/components/SwitchText";
import { getItemInfo, singleBenefitPlaceOrder } from "@/apis/home";
import { useRoute } from "@/components/use/base";
import Vue from "vue";
import { isValidPhone } from "@/helper/validate";
import { jsonSafeParse } from "@/utils";
import qs from "qs";
import { CryptoJS } from "jsrsasign";

export default defineComponent({
  components: {
    SwitchText,
  },
  setup() {
    const route = useRoute();
    const sku = ref({});
    const fetching = ref(true);
    const disabled = ref(false);
    const phone = ref("");
    const vm = Vue.prototype;
    onMounted(async () => {
      const loading = vm.$loading();
      try {
        const res = await getItemInfo({
          item_code: decodeURIComponent(route.query.sku),
        });
        sku.value = res;
      } finally {
        loading.clear();
        fetching.value = false;
      }
    });
    const changeAccount = (value) => {
      phone.value = value;
    };
    const submit = async () => {
      if (!phone.value) {
        vm.$toast("请输入手机号");
        return;
      }
      if (!isValidPhone(phone.value)) {
        vm.$toast("手机号格式不正确");
        return;
      }
      const { key, secret, channels } = jsonSafeParse(
        vm.$getConfigValue("KAISHU_SINGLE_BENEFIT_CONFIG")
      );
      const channel = route.query.channel;
      if (!channel || !channels?.includes(channel)) {
        vm.$toast("渠道不存在");
        return;
      }

      const params = {
        notify_url: "no_callback_domain",
        app_key: key,
        mobile: phone.value,
        order_number: `KAISHU${navigator.productSub}${new Date().getTime()}`,
        product_code: decodeURIComponent(route.query.sku),
        channel: route.query.channel || "",
      };
      const hmacSha256 = CryptoJS.HmacSHA256(
        qs.stringify(params, {
          sort: (a, b) => a.localeCompare(b),
        }),
        secret
      );
      const sign = hmacSha256.toString(CryptoJS.enc.Hex);
      params.sign = sign;
      const loading = vm.$loading();
      try {
        await singleBenefitPlaceOrder(params);
        disabled.value = true;
        vm.$toast("您已领取成功，请按以下页面提示使用权益");
      } finally {
        loading.clear();
      }
    };

    return {
      sku,
      phone,
      changeAccount,
      submit,
      disabled,
      fetching,
    };
  },
});
</script>


<style lang="less" scoped>
.benefit-header {
  min-height: 223px;
}
.benefit-header-img {
  height: 100%;
}

.benefit-info {
  border-radius: 8px;
  overflow: hidden;
  margin: -73px 12px 0;
  position: relative;
  z-index: 1;
}

.benefit-btn {
  font-weight: bold;
  border: none;
  .btn-primary-style();
}

.benefit-btn-wrap {
  margin-top: 14px;
  padding: 0 22px;
}
.benefit-desc {
  margin: 15px 12px;
  border-radius: 8px;
  padding: 18px 16px;
  background: #fff;
}

.benefit-desc-label {
  font-size: 13px;
  color: #333;
  line-height: 24px;
  margin-bottom: 10px;
}
.instruction-text {
  color: #666;
  line-height: 1.5;
}

.benefit-name {
  height: 70px;
  padding: 12px 10px 0 22px;
  box-sizing: border-box;
  background: url("../../assets/images/be-bg.gif") no-repeat center;
  position: relative;
  display: flex;
  margin-bottom: 6px;
}
.benefit-title {
  line-height: 20px;
  color: #6c3e0c;
  font-size: 14px;
  margin-bottom: 2px;
  position: relative;
  z-index: 10;
}
.benefit-amount {
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  height: 18px;
  background: linear-gradient(90deg, #feedd7 0%, #ffffff 100%);
  border-radius: 0px 9px 0px 6px;
  line-height: 18px;
  font-size: 11px;
  padding: 0 9px;
  color: #844f16;
}
.benefit-icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin-right: 11px;
}

.benefit-subtitle {
  line-height: 18px;
  color: #6c3e0c;
  font-size: 12px;
  opacity: 0.8;
  .font-family(PingFangSC-Light);
}

.benefit-sku {
  padding: 0 22px;
  border: 1px solid transparent;
}
.benefit-info-inner {
  background: #fff;
  border-radius: 10px;
  padding-bottom: 20px;
  overflow: hidden;
}
.benefit-info-title {
  font-size: 26px;
  color: #fff;
  line-height: 37px;
  padding-left: 22px;
  margin-bottom: 17px;
  .font-family(PingFangSC-Ultralight);
}

.benefit-sku-input {
  display: flex;
  align-items: center;
  height: 41px;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 11px 13px;
  box-sizing: border-box;
  margin-top: 12px;
  font-size: 13px;
  position: relative;
  /deep/ .van-field__control {
    font-size: 13px;
  }
}

.benefit-input-label {
  flex-shrink: 0;
  padding: 0;
}

.benefit-input {
  flex: 1;
  padding: 0;
  font-size: 15px;
  background: transparent;
  position: relative;
  overflow: visible;
  &::after {
    content: none !important;
  }
}

.rich-text {
  color: #777;
  line-height: 1.5;
  font-size: 12px;
  /deep/ img {
    display: block;
    max-width: 100%;
  }
}
</style>