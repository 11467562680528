<template>
  <main>
    <div class="coupon-btn-wrap" v-if="!available || isAfterMarket">
      <div class="coupon-link">
        <van-button round block disabled class="coupon-button"
          >商品券码：{{ doNotText }}</van-button
        >
      </div>
      <div class="coupon-tips">有效期至：{{ expiredText }}</div>
    </div>
    <div class="coupon-btn-wrap" v-else-if="toCouponUse">
      <div class="coupon-link">
        <van-button
          type="info"
          class="coupon-button"
          round
          block
          @click="toCoupon"
        >
          查看券码</van-button
        >
      </div>
      <div class="coupon-tips">有效期至：{{ expiredText }}</div>
    </div>
    <template v-else>
      <div class="coupon-btn-wrap">
        <div class="coupon-link" v-if="showLinkCard">
          <van-button
            round
            block
            type="info"
            @click="codeBtnHandle"
            v-ans="{
              event_id: 'order_detail_click_use',
              order_id: $route.query.order_number,
            }"
            class="coupon-button"
            >立即使用</van-button
          >
          <div class="coupon-tips">有效期至：{{ expiredText }}</div>
        </div>
      </div>
      <div
        :class="{
          'order-card coupon-code': true,
          single: !payInfo.secret_code,
          unsingle: !!payInfo.secret_code,
        }"
        v-if="
          payInfo.coupon_format === 'single' || payInfo.coupon_format === 'kami'
        "
      >
        <div class="count-up">
          <van-count-down
            class="count-up-text"
            :time="time"
            format="DD天HH时mm分后过期"
          />
        </div>
        <div class="coupon-body">
          <div class="code-item" v-if="payInfo.coupon_code">
            <div class="coupon-label">商品券码</div>
            <div class="coupon-value ellipsis">
              {{ payInfo.coupon_code }}
            </div>
            <van-button
              size="mini"
              round
              block
              v-ans="{
                event_id: 'order_detail_click_use',
                order_id: $route.query.order_number,
              }"
              class="copy"
              v-clipboard:copy="payInfo.coupon_code"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
              >{{ !!payInfo.secret_code ? "复制" : "点击复制" }}</van-button
            >
          </div>
          <div
            class="code-item"
            v-if="payInfo.secret_code && payInfo.coupon_format === 'kami'"
          >
            <div class="coupon-label">密码</div>
            <div class="coupon-value">
              {{ payInfo.secret_code }}
            </div>
            <van-button
              v-if="available"
              size="mini"
              round
              block
              class="copy"
              v-clipboard:copy="payInfo.secret_code"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
              >{{ !!payInfo.secret_code ? "复制" : "点击复制" }}</van-button
            >
          </div>
          <div class="coupon-tips">
            <span class="coupon-label">有效期至</span>{{ expiredText }}
          </div>
        </div>
      </div>
    </template>
    <van-dialog
      class="custom-dialog"
      v-close="close"
      :show-confirm-button="false"
      :value="show"
    >
      <div class="qrcode-title">1. 若您未注册君亭，请先注册后返回</div>
      <div class="qrcode-title">2. 若您已注册，点击下方去兑换</div>
      <van-button block type="info" @click="toRegister"
        >1. 先注册君亭会员</van-button
      >
      <van-button block @click="toActivate"
        >2. 我已注册，去激活会员的</van-button
      >
    </van-dialog>
  </main>
</template>

<script>
import copy from "copy-to-clipboard";
import { mapState } from "vuex";
import * as dayjs from "dayjs";
import { jsonSafeParse, storage } from "@/utils";

export default {
  components: {},
  props: [
    "toCouponUse",
    "detail",
    "payInfo",
    "available",
    "itemInfo",
    "source",
    "isAfterMarket",
  ],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState(["isMiniProgram"]),
    doNotText() {
      if (this.isAfterMarket) {
        return "审核中";
      }
      if (this.payInfo.coupon_used === 1) {
        return "已使用";
      }
      return "已过期";
    },
    expiredText() {
      return `${dayjs(this.detail.coupon_expire_date).format(
        "YYYY.MM.DD HH:mm"
      )}前`;
    },
    showLinkCard() {
      return /^(link|qr_code|bar_code|qr_bar_code)$/.test(
        this.payInfo.coupon_format
      );
    },
    time() {
      return (
        dayjs(this.detail.coupon_expire_date).diff(dayjs(), "second") * 1000
      );
    },
  },
  methods: {
    close() {
      this.show = false;
    },
    copySuccess() {
      this.$toast("复制成功");
      if (this.itemInfo.activation_page) {
        window.location.href = this.itemInfo.activation_page;
      }
    },
    toActivate() {
      location.href =
        "http://www.ssawhotels.com/Views/Activity/upCardByJD.html?sharer_username=gh_9c85a0e34826&scene=126&clicktime=1562044302&from=groupmessage&isappinstalled=0";
    },
    toRegister() {
      location.href =
        "http://zd.zhiketong.cn/NewHome/LoginZhiLian?nobuy=1&register_trigger=memberaccount&backurl=%2FNewHome&buyurl=%2FNewHome&appid=wx78c556d4c909b922";
    },
    copyError() {
      this.$toast("复制失败，请手动复制");
    },
    toCoupon() {
      this.$jumpUrl("/pages/coupon/coupon", "switchTab");
    },
    // 判断小程序是否能够打开此链接
    checkUrl(url) {
      const MINI_PROGRAM_DOMAIN_WHITE_LIST = jsonSafeParse(
        this.$getConfigValue("MINI_PROGRAM_DOMAIN_WHITE_LIST") || "[]"
      );
      return MINI_PROGRAM_DOMAIN_WHITE_LIST.some((item) => url.includes(item));
    },
    codeBtnHandle() {
      // 图片链接形式的券码使用页面加载图片的形式打开
      // 省呗app内图片由于refer的原因被拒绝访问
      if (/.(jpeg|jpg|png|gif)$/.test(this.payInfo.coupon_code)) {
        this.$router.push(
          `/openimage?url=${encodeURIComponent(this.payInfo.coupon_code)}`
        );
        return;
      }
      if (this.payInfo.coupon_format === "link") {
        const wxOpenId = storage.getItem("openid");
        // 因朔桔小程序跳转加openId
        if (
          this.payInfo.coupon_extra_data?.supplier === "insuresmart" &&
          wxOpenId
        ) {
          const url = `${this.payInfo.coupon_code}&wxOpenId=${wxOpenId}`;
          window.location.href = url;
          return;
        }
        if (
          this.isMiniProgram &&
          this.payInfo.benefit_type !== "viproom" &&
          !this.checkUrl(this.payInfo.coupon_code)
        ) {
          copy(this.payInfo.coupon_code);
          this.$toast("使用链接已复制，请打开外部浏览器使用");
          return;
        }
        window.location.href = this.payInfo.coupon_code;
      } else {
        if (!this.payInfo.coupon_code) {
          this.$toast("获取券码失败");
          return;
        }
        if (this.detail.type === "ssaw") {
          // 注册君亭会员
          this.show = true;
        } else {
          this.$router.push(
            `/code?item_code=${this.payInfo.item_code}&coupon_code=${this.payInfo.coupon_code}&coupon_format=${this.payInfo.coupon_format}&order_number=${this.$route.query.order_number}`
          );
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.coupon-btn-wrap {
  margin-bottom: 23px;
}
.coupon-link {
  margin: 0 34px 13px;
  .coupon-button {
    margin-bottom: 13px;
  }
  .coupon-button {
    &:disabled {
      border: none;
      color: #bbbbbb;
      background: #f9f9f9;
      opacity: 1;
    }
  }
}

.coupon-tips {
  font-size: 11px;
  line-height: 16px;
  color: @text-gray2-color;
  text-align: center;
  .coupon-label {
    font-size: 11px !important;
  }
}

.order-card {
  margin: 0 12px 12px;
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #f1f1f1;
}

.coupon-body {
  padding-top: 19px;
  padding-bottom: 21px;
  .coupon-tips {
    margin-top: 14px;
  }
}
.single {
  .code-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .coupon-label {
    line-height: 18px;
    font-size: 13px;
    margin-bottom: 7px;
  }
  .coupon-value {
    line-height: 24px;
    color: #000;
    font-size: 17px;
    margin-bottom: 12px;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
      Segoe UI, Arial, Roboto, "PingFangSC-Semibold", "miui", "Hiragino Sans GB",
      "Microsoft Yahei", sans-serif;
  }
  .copy {
    border-radius: 13px;
    width: 64px;
    height: 23px;
    font-size: 11px;
    padding: 0;
    background: linear-gradient(115deg, #dfbd94 0%, #ffe8c8 100%);
    color: #392715;
    border: none;
  }
  .coupon-tips {
    .coupon-label {
      &::after {
        content: "：";
      }
    }
  }
}
.unsingle {
  .code-item {
    display: flex;
    align-items: center;
    padding: 0 16px;
    & + .code-item {
      margin-top: 13px;
    }
  }
  .coupon-label {
    width: 80px;
    text-align: right;
    line-height: 18px;
    font-size: 13px;
    flex-shrink: 0;
    color: #999;
    &::after {
      content: "：";
    }
  }

  .coupon-value {
    line-height: 24px;
    color: #000;
    font-size: 17px;
    flex: 1;
  }
  .copy {
    border-radius: 13px;
    width: 38px;
    padding: 0;
    flex-shrink: 0;
    font-size: 11px;
    margin-left: 10px;
    height: 21px;
    background: linear-gradient(115deg, #dfbd94 0%, #ffe8c8 100%);
    border: none;
  }
  .coupon-tips {
    display: flex;
    align-items: center;
    padding: 0 16px;
  }
}
.count-up {
  margin: -2px -2px 0 -2px;
  text-align: center;
  height: 26px;
  background: linear-gradient(270deg, #fff8f9 0%, #fdeff1 100%);
  border-radius: 6px 6px 0 0;
  .count-up-text {
    line-height: 26px;
    font-size: 11px;
    color: #f63636;
  }
}
</style>