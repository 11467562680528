<template>
  <floor>
    <template #title>{{ name }}</template>
    <template #tip>{{ desc }}</template>
    <template #main>
      <div class="hotel-benefit">
        <div
          :class="{
            'hotel-card2': index === 2,
            'hotel-card': index < 2,
            isMember: isMember,
          }"
          v-for="(item, index) of productInfo"
          :key="item.name"
        >
          <template v-if="index < 2">
            <img :src="item.icon_img_url" />
            <div class="hotel-name">{{ item.desc }}</div>
            <div class="hotel-content">
              <div>
                ¥<span class="hotel-price">{{
                  price(item.selling_points)[0]
                }}</span
                ><span v-if="item.product_code !== 'railviproom'">起</span>
              </div>
              <div class="hotel-value">
                价值{{ price(item.selling_points)[1] }}
              </div>
              <div
                class="btn"
                @click="handleUse(item.product_code)"
                v-if="isMember || notPay"
              >
                去使用
              </div>
            </div>
          </template>
          <template v-else>
            <img :src="item.icon_img_url" />
            <div>
              <div class="hotel-name">{{ item.desc }}</div>
              <div class="hotel-content">
                <div>
                  ¥<span class="hotel-price">{{
                    price(item.selling_points)[0]
                  }}</span>
                </div>
                <div class="hotel-value">
                  价值{{ price(item.selling_points)[1] }}
                </div>
              </div>
              <div
                class="btn"
                @click="handleUse(item.product_code)"
                v-if="isMember || notPay"
              >
                去使用
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </floor>
</template>

<script>
import { mapState } from "vuex";

import ans from "@/utils/ans";

import floor from "./floor";

export default {
  props: ["desc", "name", "active", "productInfo", "requestZhongAn", "notPay"],
  components: {
    floor,
  },
  computed: {
    ...mapState(["isMember"]),
    price() {
      return function (value = "") {
        return value.split("｜");
      };
    },
  },
  methods: {
    handleUse(product_code) {
      if (!this.isMember) {
        ans.track("chenghua_to_use", {
          namespace: this.namespace,
        });
        this.requestZhongAn();
        return;
      }
      this.$router.push(`benefitDetail?benefit_type=${product_code}`);
    },
  },
};
</script>

<style scoped lang="less">
.btn {
  width: 66px;
  height: 22px;
  background: #f6dab0;
  border-radius: 11px;
  margin-right: 5px;
  margin-left: auto;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 14px;
  color: #7e5c37;
  line-height: 20px;
  text-align: center;
  line-height: 22px;
}
.hotel-benefit {
  display: flex;
  flex-wrap: wrap;
  margin-top: 13px;
  .hotel-card {
    width: 168px;
    background: #fff5e8;
    border-radius: 8px;
    margin-bottom: 8px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;

    img {
      width: 168px;
      height: 94px;
    }
    &:first-child {
      margin-right: 7px;
    }
  }
  .hotel-card2 {
    width: 100%;
    height: 93px;
    background: #fff5e8;
    border-radius: 8px;
    align-items: center;
    display: flex;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    img {
      width: 168px;
      height: 93px;
    }
    > div {
      margin-left: 8px;
      width: 100%;
    }
    .btn {
      margin-top: 4px;
    }
    .hotel-value {
      margin-left: 8px !important;
    }
  }
  .hotel-name {
    font-size: 14px;
    color: #27314a;
    line-height: 20px;
    margin: 8px 0 0 8px;
  }
  .hotel-content {
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-left: 8px;
    font-size: 10px;
    color: #455368;
    line-height: 14px;
    margin-bottom: 7px;
    .hotel-price {
      font-size: 14px;
      margin-left: 1px;
    }
    .hotel-value {
      font-size: 10px;
      font-weight: 400;
      color: #455368;
      line-height: 14px;
      margin-left: 8px;
    }
  }
}
.isMember {
  .hotel-value {
    margin-left: 4px !important;
  }
  .hotel-content {
    margin-top: 8px;
  }
}
</style>
