<template>
  <span ref="countup"></span>
</template>

<script>
import { CountUp } from "countup.js";

export default {
  props: {
    endCount: {
      default: 0,
    },
    startVal: {
      default: 996,
    },
  },
  data() {
    return {
      options: {
        useGrouping: true,
        startVal: this.startVal,
        useEasing: true,
        duration: 3,
        enableScrollSpy: true,
      },
    };
  },
  watch: {
    endCount(val) {
      val && this.initCountUp();
    },
  },
  methods: {
    initCountUp() {
      try {
        let demo = new CountUp(this.$refs.countup, this.endCount, {
          ...this.options,
          formattingFn: this.formattingFn,
        });
        if (!demo.error) {
          demo.start();
        } else {
          console.error(demo.error);
        }
      } catch (e) {
        console.log(e);
      }
    },
   
  },
};
</script>

<style scoped lang="less">
.wrap {
  font-size: 40px;
}
</style>
