var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-popup',{staticClass:"centification-dialog",attrs:{"closeable":"","round":"","value":_vm.value},on:{"click-overlay":_vm.close,"click-close-icon":_vm.close}},[_c('main',{staticClass:"centification"},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"title"},[_vm._v("完成实名认证")]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.subtitle))])]),_c('div',{staticClass:"phone"},[_vm._v(" 手机号："),_c('span',[_vm._v(_vm._s(_vm._f("maskStr")(_vm.phone_number)))])]),_c('van-form',{ref:"form",staticClass:"centification-form",attrs:{"show-error":false,"show-error-message":false},on:{"submit":_vm.onSubmit,"failed":_vm.onFailed}},[_c('van-field',{staticClass:"field-input",attrs:{"label":"姓","border":false,"maxlength":"30","placeholder":"姓","rules":[
          {
            required: true,
            message: '请输入姓',
          } ]},model:{value:(_vm.last_name_cn),callback:function ($$v) {_vm.last_name_cn=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"last_name_cn"}}),_c('van-field',{staticClass:"field-input",attrs:{"label":"名","border":false,"maxlength":"30","placeholder":"名","rules":[
          { required: true, message: '请输入名' },
          {
            pattern: /[\u4e00-\u9fa5]{1,10}/,
            message: '名必须是中文',
          } ]},model:{value:(_vm.first_name_cn),callback:function ($$v) {_vm.first_name_cn=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"first_name_cn"}}),_c('van-field',{staticClass:"field-input",attrs:{"label":"身份证","maxlength":"18","border":false,"placeholder":"身份证","rules":[
          { required: true, message: '请输入身份证号码' },
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: '请输入正确的居民身份证号码',
          } ]},model:{value:(_vm.id_number),callback:function ($$v) {_vm.id_number=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"id_number"}}),_c('div',{staticClass:"centification-button"},[_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({ event_id: 'centification_dialog_submit' }),expression:"{ event_id: 'centification_dialog_submit' }"}],attrs:{"round":"","block":"","type":"info","size":"large","native-type":"submit"}},[_vm._v("确认")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }