import { getHotelBaseInfo, getHotelTips } from "@/apis/hotel";
import { storage } from "@/utils";
import dayjs from "dayjs";
import qs from "qs";

export default {
  state: {
    city: storage.getItem("hotelCity") || "",
    checkin_date: Number(storage.getItem("checkin_date")) || null,
    checkout_date: Number(storage.getItem("checkout_date")) || null,
    city_of_hotels: [],
    calendar_choice_tips: "",
    city_of_hotels_info: [],
    hot_city_of_hotels: [],
    hotel_advance_range: "",
    holidays: [],
    hotel_levels_current: Number(sessionStorage.getItem("levels_current") || 0)
  },
  getters: {
    interNight(state) {
      return (state.checkout_date - state.checkin_date) / (1000 * 60 * 60 * 24);
    },
    maxCheckinDate(state) {
      return dayjs(
        new Date().getTime() +
        Number(state.hotel_advance_range || 60) * 24 * 60 * 60 * 1000
      );
    },
    isHoliday(state) {
      return date => {
        return state.holidays.includes(date.valueOf());
      };
    }
  },
  mutations: {
    holidays(state, payload) {
      state.holidays = payload;
    },
    changeHotel(state, payload) {
      for (let k in payload) {
        state[k] = payload[k];
        if (k === "city") {
          storage.setItem("hotelCity", payload[k]);
        }
        if (k === "checkout_date" || k === "checkin_date") {
          storage.setItem(k, dayjs(payload[k]).valueOf());
        }
      }
    }
  },
  actions: {
    async getHotelInfo({ state, rootState, commit, getters }) {
      const product_code = qs.parse(location.href)?.["product_code"];
      const getConfigValue = getters.getConfigValue;
      const search = qs.parse(location.search, { ignoreQueryPrefix: true });
      // 判断是否为亲子酒店、经济型酒店、5-8折酒店
      let classification;
      const is_family_hotel =
        search.is_family_hotel || getConfigValue("DEFAULT_FAMILY_HOTEL");

      if (product_code === "hotel") {
        classification = "0,1,2,4,5";
      } else if (product_code === "ehotel") {
        classification = "3";
      }
      const params = {
        namespace: rootState.namespace || rootState.tenant,
        classification,
        is_family_hotel
      };
      if (rootState.benefitHotelType === "ehotel") {
        params.classification = 3;
        params.namespace = `${params.namespace}-affordable`;
      }
      const res = await getHotelBaseInfo(params);
      const {
        default_city,
        checkin_date,
        checkout_date,
        hotel_advance_range,
        hot_city_of_hotels,
        calendar_choice_tips,
        city_of_hotels_info
      } = res.data;
      commit("changeHotel", {
        city: state.city || default_city,
        minDate: dayjs(checkin_date),
        checkin_date:
          state.checkin_date &&
            dayjs(state.checkin_date).isSameOrAfter(dayjs(checkin_date))
            ? state.checkin_date
            : checkin_date,
        checkout_date:
          state.checkout_date &&
            dayjs(state.checkout_date).isSameOrAfter(dayjs(checkout_date))
            ? state.checkout_date
            : checkout_date,
        hotel_advance_range,
        hot_city_of_hotels,
        calendar_choice_tips,
        city_of_hotels_info
      });
      return res;
    },
    async getHotelDays({ state, commit }) {
      if (state.holidays.length) {
        return;
      }
      const holidays = await getHotelTips({ name: "holidays" });
      const days = holidays.data.map(item => dayjs(item).valueOf());
      commit("holidays", days);
    }
  }
};
