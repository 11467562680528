<template>
  <div class="wrap">
    <Header
      @changePackage="changePackage"
      :active="active"
      :cardArr="cardArr"
    />
    <Content :active="active" :cardArr="cardArr" />
    <Footer v-if="isMember" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Header from "./header.vue";
import Content from "./content.vue";
import Footer from "./footer.vue";

export default {
  components: {
    Header,
    Content,
    Footer,
  },
  data() {
    return {
      cardArr: [],
      active: 0,
      timer: null,
      pollingTime: 10000,
    };
  },
  computed: {
    ...mapState(["isMember", "namespace"]),
  },
  methods: {
    changePackage(index) {
      this.active = index;
    },

    async polling() {
      try {
        const { is_member } = await this.$store.dispatch("fetchUserInfo");
        if (is_member) {
          clearInterval(this.timer);
        }
      } catch (e) {
        console.log(e);
      }
    },

    async initCard() {
      const CARD_CONFIG = JSON.parse(this.$getConfigValue("ZA_CARD_NAMESPACE"));
      const showOne = this.$route.query.show_one === "true" || false;
      // showOne只展示一张卡
      if (this.isMember || showOne) {
        this.cardArr = CARD_CONFIG.filter(
          (item) => item.namespace === this.namespace
        );
      } else {
        this.cardArr = [...CARD_CONFIG];
      }
    },

    async requestIsMember() {
      this.pollingTime = parseInt(
        this.$getConfigValue("ZA_POLLING_ISMEMBER_TIME")
      );
      if (!this.isMember) {
        this.timer = setInterval(() => {
          this.polling();
        }, this.pollingTime);
      }
    },
  },
  mounted() {
    this.initCard();
    this.requestIsMember();
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: rgba(19, 21, 32, 1);
  position: relative;
}
</style>