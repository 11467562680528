var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buffet-hotel"},[_c('div',{staticClass:"buffet-city",on:{"click":function($event){return _vm.$router.push('/buffet/city')}}},[_c('van-search',{attrs:{"value":_vm.buffet.city,"shape":"round","readonly":"","right-icon":"a"}})],1),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item){return _c('div',{key:item.name,staticClass:"buffet-list-item"},[_c('van-image',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'buffet_list_click',
          namespace: _vm.namespace,
          buffet_id: item.id,
        }),expression:"{\n          event_id: 'buffet_list_click',\n          namespace,\n          buffet_id: item.id,\n        }"}],staticClass:"buffet-list-img",attrs:{"fit":"cover","src":item.image_list_info[0]},on:{"click":function($event){return _vm.$router.push(("/buffet/detail?id=" + (item.id)))}}}),_c('div',{staticClass:"buffet-item-name"},[_vm._v(_vm._s(item.buffet_merchant_info.name))]),_c('div',{staticClass:"buffet-item-address"},[_vm._v(_vm._s(item.address))]),_c('div',{staticClass:"buffet-item-footer"},[_c('div',{staticClass:"buffet-item-tag"},[_vm._v(_vm._s(item.defaultTag))]),_c('div',{staticClass:"buffet-item-price"},[_c('small',[_vm._v("¥")]),_vm._v(_vm._s(item.lowest_price_info.member_price)),_c('span',{staticClass:"buffet-item-qi"},[_vm._v("起")]),_c('span',{staticClass:"buffet-item-facevalue"},[_vm._v("¥"+_vm._s(item.lowest_price_info.market_price))])]),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
            event_id: 'buffet_list_click',
            namespace: _vm.namespace,
            buffet_id: item.id,
          }),expression:"{\n            event_id: 'buffet_list_click',\n            namespace,\n            buffet_id: item.id,\n          }"}],staticClass:"buffet-item-btn",on:{"click":function($event){return _vm.$router.push(("/buffet/detail?id=" + (item.id)))}}},[_vm._v(" 立即预订 ")])])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }