<template>
  <section class="leiniao">
    <div @click="showRule" class="rule">规则</div>
    <div class="content">
      <div class="active-btn" @click="sumbit"></div>
    </div>
  </section>
</template>

<script>
import { useStore } from "@/components/use/base";
import { storage } from "@/utils";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import Vue from "vue";
import loginKeepDialog from "@/components/loginKeepDialog";
import ans from "@/utils/ans";
import { goCashing } from "@/apis/home";
import { getUserCashingCode, validateCashingCodeBatch } from "@/apis/product";
import throttle from "lodash.throttle";

const vm = Vue.prototype;
export default defineComponent({
  setup() {
    const store = useStore();
    const loading = ref(true);
    const checked = ref(true);
    const LEINIAO_CASHCODE_CONFIG = JSON.parse(
      vm.$getConfigValue("LEINIAO_CASHCODE_CONFIG")
    );
    const beforeSendCode = async (phone) => {
      const loading = vm.$loading();
      try {
        const res = await validateCashingCodeBatch({
          phone_number: phone,
          ...LEINIAO_CASHCODE_CONFIG,
        });
        if (!res.data.result) {
          vm.$alert({
            className: "leiniao_verify",
            title: "暂无资格领取",
            confirmButtonText: "好的",
            message:
              "此会员是雷鸟科技2024年年会专享福利，您暂无资格领取，如有疑问请联系：雷鸟科技小叮当",
          });
          return Promise.reject("");
        }
      } catch (e) {
        return Promise.reject("");
      } finally {
        loading.clear();
      }
      return Promise.resolve();
    };

    const showRule = () => {
      vm.$alert({
        title: "活动规则",
        message: store.state.namespaceInfo.rule,
        confirmButtonText: "我知道了",
      });
    };
    onMounted(() => {});
    const sumbit = throttle(
      async () => {
        const token = storage.getItem("token");
        let userInfo = {};
        if (!token) {
          userInfo = await loginKeepDialog.show({
            ns: store.state.namespace,
            beforeSendCode,
          });
        }
        if (!userInfo.is_member) {
          const loading = vm.$loading();
          try {
            const res = await getUserCashingCode({
              ...LEINIAO_CASHCODE_CONFIG,
            });
            if (!res.data.cash_code) {
              vm.$toast("获取激活信息失败");
              return;
            }
            await goCashing({
              cashing_code: res.data.cash_code,
            });
            await store.dispatch("fetchUserInfo");
            vm.$alert({
              className: "leiniao_verify_success",
              title: "激活成功！",
              confirmButtonText: "好的",
              message: "你已成功激活福利礼包<br/>快去使用吧~",
            });
          } finally {
            loading.clear();
          }
        }
      },
      3000,
      { trailing: false }
    );
    return {
      loading,
      checked,
      sumbit,
      showRule,
    };
  },
});
</script>


<style lang="less" scoped>
.leiniao {
  min-height: 100vh;
  box-sizing: border-box;
  background: #432891;
  padding-bottom: 20px;
}
.content {
  height: 722px;
  padding-top: 646px;
  box-sizing: border-box;
  background: url("../../../assets/images/leiniao_visitor_bg.jpg") no-repeat
    top/100%;
}
.rule {
  position: fixed;
  right: 0;
  top: 200px;
  width: 42px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: #fff;
  border-radius: 13px 0px 0px 13px;
  background: rgba(0, 0, 0, 0.7);
}
.active-btn {
  height: 70px;
  width: 270px;
  margin: 0 auto;
  background: url("../../../assets/images/leiniao_visitor_bg_btn.png") no-repeat
    center/100%;
}
</style>

<style lang="less">
.leiniao_verify {
  overflow: initial;
  .van-dialog__header {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -52px;
      width: 64px;
      height: 75px;
      left: 50%;
      margin-left: -32px;
      background: #fff;
      background: url("../../../assets/images/leiniao_verify_icon.png")
        no-repeat center/100%;
    }
  }
}

.leiniao_verify_success {
  overflow: initial;
  .van-dialog__header {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -55px;
      width: 90px;
      height: 90px;
      left: 50%;
      margin-left: -45px;
      background: #fff;
      background: url("../../../assets/images/leiniao_verify_success_icon.png")
        no-repeat center/100%;
    }
  }
}
</style>