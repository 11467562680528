<template>
  <van-dialog
    class="phone-model"
    :show-confirm-button="false"
    v-close="close"
    :value="value"
  >
    <div class="phone-model-content">
      <div class="phone-model-text">
        手机号将会用于所有手机号充值的权益，填写后不可修改，请确认是否继续提交？
      </div>
      <div class="van-hairline--bottom">
        <van-field
          input-align="center"
          class="phone-model-input"
          v-model.trim="phone"
          placeholder="请准确填写手机号，填后不可修改"
        />
      </div>
      <van-button
        class="phone-model-btn"
        round
        block
        type="info"
        :disabled="!phone"
        @click="submit"
        >确定</van-button
      >
    </div>
  </van-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { updateUserPhone } from "@/apis/home";

export default {
  props: ["value", "close", "resolve"],
  data() {
    return {
      phone: "",
    };
  },
  computed: {},
  methods: {
    ...mapMutations(["changeState"]),
    ...mapActions(["fetchUserInfo"]),
    async submit() {
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
        this.$toast("手机号输入不正确");
        return;
      }
      const toast = this.$loading();
      try {
        await updateUserPhone({ user_phone: this.phone });
        const res = await this.fetchUserInfo();
        this.close();
        this.resolve(res);
      } catch (e) {
        this.reject(e);
      } finally {
        toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.phone-model-content {
  padding: 20px;
}
.phone-model-text {
  padding: 20px 10px 0;
  text-align: center;
}
.van-hairline--bottom {
  margin: 0 10px;
}
.phone-model-input {
  margin-top: 20px;
  font-size: @font-md;
}
.phone-model-btn {
  margin-top: 30px;
}
</style>