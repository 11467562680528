export default {
  state: {
    city: "深圳"
  },
  getters: {

  },
  mutations: {
    changeCity(state, payload) {
      state.city = payload
    }
  },
  actions: {

  }
}
