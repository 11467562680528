var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
    event_id: 'seckill_jump_detail_click',
    namespace: _vm.namespace,
    item_code: _vm.item.item_code,
    activity_name: _vm.activityQueryName,
    start_time: _vm.activity.start_time,
    activity_status: _vm.activity.status,
  }),expression:"{\n    event_id: 'seckill_jump_detail_click',\n    namespace,\n    item_code: item.item_code,\n    activity_name: activityQueryName,\n    start_time: activity.start_time,\n    activity_status: activity.status,\n  }"}],key:_vm.item.item_code,staticClass:"product-item",on:{"click":function($event){return _vm.toDetail(_vm.item, _vm.activity.start_time, _vm.activity.start_time, _vm.activity.status)}}},[_c('div',{staticClass:"product-tag"},[_vm._v(_vm._s(_vm.tag))]),_c('div',{staticClass:"product-img-wrap"},[_c('van-image',{staticClass:"product-img",attrs:{"fit":"cover","lazy-load":"","src":_vm.item.image}}),(_vm.activity.status === 'start')?_c('div',{staticClass:"product-sales"},[_c('div',{class:['product-progress']},[_c('div',{staticClass:"product-progress-bar",style:(("width: calc(" + (100 - _vm.getWidth(_vm.item)) + "%)"))})]),_c('div',{staticClass:"product-sales-text"},[_vm._v("剩余"+_vm._s(100 - _vm.getWidth(_vm.item))+"%")])]):_vm._e()],1),_c('div',{staticClass:"product-content"},[_c('div',{staticClass:"product-title"},[_c('p',{staticClass:"product-name ellipsis"},[_vm._v(_vm._s(_vm.item.title))])]),_c('div',{staticClass:"price-wrap"},[_c('div',{staticClass:"price-text"},[_c('div',[_vm._v("已补")]),_c('div',[_vm._v(" ¥"),_c('span',[_vm._v(_vm._s(_vm.getValue(_vm.item)))])])])]),_c('div',{class:['product-context', _vm.getComputedStatus(_vm.item, 'actionClass')]},[_c('div',{staticClass:"spike"},[_c('span',[_vm._v("¥")]),_vm._v(_vm._s(_vm.item.activity_price))]),_c('div',{staticClass:"action-wrap"},[_c('div',{staticClass:"text"},[_c('span',{class:_vm.computedActionIcon(_vm.item)},[_vm._v(_vm._s(_vm.getStartTime)+" ")]),_vm._v(" "+_vm._s(_vm.getBtnText(_vm.item))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }