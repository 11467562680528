<template>
  <main class="oil">
    <div class="header">
      <div class="data">
        <div class="item">
          <p>{{ consumptionInfo.total_consumption }}</p>
          <span>累计消费(元)</span>
        </div>
        <div class="item">
          <p>{{ consumptionInfo.total_savings }}</p>
          <span>累计节省(元)</span>
        </div>
        <div class="item">
          <p>{{ consumptionInfo.total_units }}</p>
          <span>累计加油(升)</span>
        </div>
      </div>
      <van-dropdown-menu active-color="#FF5001">
        <van-dropdown-item
          v-model="station_type"
          :options="station_type_list"
        />
        <van-dropdown-item v-model="oil_name" :options="oil_name_list" />
        <van-dropdown-item v-model="sort_way" :options="sort_way_list" />
      </van-dropdown-menu>
    </div>
    <div class="main">
      <div class="process">
        <img
          src="https://cdn.xiangshuheika.com/static/oil/process.png"
          alt=""
        />
      </div>
      <div class="list" v-if="oil_list.length">
        <div class="item" v-for="item in oil_list" :key="item.id">
          <router-link
            :to="{
              path: '/oil/detail',
              query: {
                id: item.station_id,
                oil_name: encodeURIComponent(item.oil_name),
                longitude,
                latitude,
                phone: phone_number,
                item_code: itemCode,
                wxOpenId: openId,
              },
            }"
          >
            <div class="top">
              <span>{{ item.oil_name }}</span>
              <h4>{{ item.station_name }}</h4>
            </div>
            <div class="address">
              <div>{{ item.address }}</div>
              <div
                class="navigation"
                @click.prevent="
                  toMap(item.station_name, item.latitude, item.longitude)
                "
              >
                <p>{{ item.distance_km }}km</p>
                <span>导航</span>
              </div>
            </div>
            <div class="price">
              <div class="pre-price">
                优惠价 <span>¥</span><b>{{ item.oil_price_yfq }}</b
                ><span>/L</span>
              </div>
              <div class="gb-price">
                <i>国标价</i> ¥ <span>{{ item.oil_price_official }}</span
                >/L
              </div>
            </div>
            <div class="label">
              <span v-for="i in item.preferential_price_info"
                >加{{ i.totalPrice }}省{{ i.promotionAmount }}</span
              >
            </div>
            <div class="btn-wrap">
              <div class="btn">
                <div
                  class="navigate"
                  @click.prevent="
                    toMap(item.station_name, item.latitude, item.longitude)
                  "
                >
                  先导航
                </div>
                <div class="pay">再下单</div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="loading" v-if="loading && !finished">
          <van-loading size="24px">加载更多...</van-loading>
        </div>
      </div>
      <div v-else>
        <van-empty image="search" description="附近没有加油站" />
      </div>
    </div>
    <div class="to-order-btn" @click="$router.push('/order')">我的订单</div>
  </main>
</template>

<script>
import Vue from "vue";
import { DropdownMenu, DropdownItem, List } from "vant";
import {
  onMounted,
  onUnmounted,
  defineComponent,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { getOilList, getUserConsumptionInfo } from "@/apis/oil";
import store from "@/store";
import qs from "qs";
const vm = Vue.prototype;
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(List);

export default defineComponent({
  setup() {
    const show = ref(false);
    const loading = ref(true);
    const finished = ref(false);
    const error = ref(false);
    const longitude = ref("");
    const latitude = ref("");
    const station_type = ref(0);
    const oil_name = ref("92#");
    const sort_way = ref("distance");
    const page = ref(1);
    const phone_number = ref(null);
    const itemCode = ref("");
    const openId = ref(null);
    const station_type_list = [
      { text: "全部油站", value: 0 },
      { text: "中石油", value: 1 },
      { text: "中石化", value: 2 },
      { text: "壳牌", value: 3 },
      { text: "其他", value: 4 },
    ];
    const oil_name_list = [
      { text: "柴油 0#", value: "0#" },
      { text: "汽油 92#", value: "92#" },
      { text: "汽油 95#", value: "95#" },
      { text: "汽油 98#", value: "98#" },
    ];
    const sort_way_list = [
      { text: "离我最近", value: "distance" },
      { text: "价格最低", value: "oil_price_yfq" },
    ];
    const oil_list = ref([]);
    const consumptionInfo = ref({});

    watch([station_type, oil_name, sort_way], async (newVal, oldVal) => {
      if (newVal !== oldVal) {
        page.value = 1;
        finished.value = false;
        listLoad("change");
      }
    });

    onMounted(() => {
      const params = qs.parse(location.href.split("?")[1]);
      const { phone, item_code, wxOpenId } = params;
      getUserConsumptionInfo().then((res) => {
        consumptionInfo.value = res.data;
      });
      phone_number.value = phone;
      itemCode.value = item_code;
      openId.value = wxOpenId;
      listLoad("change");
    });

    const windowScroll = () => {
      let outerHeight = document.documentElement.clientHeight;
      let bodyHeight = document.body.clientHeight;
      let scrollTop = document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
      if (bodyHeight - scrollTop - outerHeight < 100) {
        if (loading.value) {
          return;
        }
        loading.value = true;
        listLoad();
      }
    };

    //获取油站列表
    const oilList = (params) => {
      const {
        longitude,
        latitude,
        station_type,
        oil_name = "92#",
        sort_way = "distance",
      } = params;
      const opts = {
        longitude,
        latitude,
        oil_name,
        sort_way,
        page: page.value,
        price: "100,200",
        phone_number: phone_number.value,
      };
      station_type && (opts.station_type = station_type);
      return getOilList(opts);
    };

    //加载油站列表
    const listLoad = async (type) => {
      const position = await getLocation();
      if (position) {
        try {
          if (finished.value) {
            return;
          }
          let load = null;
          if (type) {
            load = vm.$loading();
          }
          const res = await oilList({
            longitude: position.longitude,
            latitude: position.latitude,
            station_type: station_type.value,
            oil_name: oil_name.value,
            sort_way: sort_way.value,
          });
          if (type) {
            oil_list.value = res.data.data;
            load.clear();
          } else {
            oil_list.value = [...oil_list.value, ...res.data.data];
          }
          longitude.value = position.longitude;
          latitude.value = position.latitude;
          loading.value = false;
          if (!res.data.next) {
            finished.value = true;
          } else {
            page.value++;
          }
        } catch (err) {
          error.value = true;
        }
        window.addEventListener("scroll", windowScroll, false);
      } else {
        vm.$toast("获取定位失败");
      }
    };

    const toMap = async (name, lat, long) => {
      if (store.state.isMiniProgram || store.state.isWebchat) {
        wx.ready(() => {
          wx.openLocation({
            type: "gcj02",
            latitude: parseFloat(lat),
            longitude: parseFloat(long),
          });
        });
        return;
      }
      window.location.href = `http://api.map.baidu.com/marker?location=${latitude},${longitude}&title=${name}&output=html`;
    };

    //获取经纬度
    const getLocation = () => {
      if (store.state.isWebchat) {
        return new Promise((resolve) => {
          wx.ready(() => {
            wx.getLocation({
              type: "gcj02",
              success: ({ latitude, longitude }) => {
                resolve({
                  latitude,
                  longitude,
                });
              },
              error: () => {
                vm.$toast("获取定位失败");
              },
            });
          });
        });
      } else {
        return new Promise((resolve) => {
          const geolocation = new window.BMap.Geolocation();
          if (geolocation) {
            geolocation.enableSDKLocation();
            geolocation.getCurrentPosition(
              (position) => {
                resolve({
                  latitude: parseFloat(position.latitude),
                  longitude: parseFloat(position.longitude),
                });
              },
              () => {
                vm.$toast("获取定位失败");
              }
            );
          } else {
            vm.$toast("获取定位失败");
          }
        });
      }
    };

    onUnmounted(() => {
      window.removeEventListener("scroll");
    });

    return {
      show,
      loading,
      finished,
      error,
      longitude,
      latitude,
      station_type,
      oil_name,
      sort_way,
      page,
      station_type_list,
      oil_name_list,
      sort_way_list,
      oil_list,
      consumptionInfo,
      phone_number,
      itemCode,
      openId,
      toMap,
      listLoad,
    };
  },
});
</script>

<style lang="less" scoped>
.oil {
  background-color: #f5f5f5;
  padding-top: 1px;
}
.header {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 9;
  left: 0;
  top: 0;
  .data {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
    border-bottom: 1px solid #f5f5f5;
    padding: 16px 0;
    text-align: center;
    .item {
      flex: 33.33%;
      p {
        font-size: 22px;
        color: #222;
        font-weight: bold;
        line-height: 30px;
      }
      span {
        font-size: 12px;
        color: #666;
        margin-top: 4px;
        line-height: 18px;
      }
    }
  }
}
.main {
  margin-top: 145px;
  .process {
    margin: 0 16px;
    img {
      width: 100%;
    }
  }
  .list {
    margin: 0 16px;
  }
  .item {
    margin-top: 16px;
    background-color: #fff;
    border-radius: 8px;
    padding: 0 12px 12px;
    .top {
      display: flex;
      span {
        width: 48px;
        height: 32px;
        background-color: rgba(255, 80, 1, 0.8);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        border-radius: 2px;
        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-bottom: 5px solid #fff;
          border-top: 5px solid transparent;
          border-left: 24px solid transparent;
          border-right: 24px solid transparent;
          left: 0;
          bottom: 0;
        }
      }
      h4 {
        font-size: 14px;
        color: #333;
        margin: 12px 0 0 8px;
        line-height: 20px;
      }
    }
    .address {
      margin-top: 12px;
      font-size: 12px;
      color: #666;
      display: flex;
      justify-content: space-between;
    }
    .navigation {
      p {
        box-sizing: border-box;
        height: 18px;
        line-height: 18px;
        background: #f7f8fa
          url("https://cdn.xiangshuheika.com/static/oil/address.png") no-repeat
          10px 3px;
        background-size: 10px 10px;
        border-radius: 9px;
        padding: 0 9px 0 26px;
        text-align: right;
      }
      span {
        display: block;
        font-size: 10px;
        color: #666;
        margin-top: 3px;
        text-align: center;
      }
    }
    .price {
      display: flex;
      align-items: flex-end;
      .pre-price {
        color: #ff5001;
        font-size: 12px;
        margin-right: 24px;
        b {
          font-size: 24px;
        }
      }
      .gb-price {
        font-size: 12px;
        color: #999;
        padding-bottom: 2px;
        i {
          font-style: normal;
          padding-right: 8px;
        }
        span {
          text-decoration: line-through;
        }
      }
    }
    .label {
      display: flex;
      margin-top: 10px;
      span {
        height: 18px;
        line-height: 18px;
        padding: 0 8px;
        background: #ff5001 linear-gradient(136deg, #ff6a00 0%, #f71b00 100%);
        border-radius: 4px;
        color: #fff;
        font-size: 12px;
        margin-right: 8px;
      }
    }
    .btn-wrap {
      display: flex;
      justify-content: flex-end;
    }
    .btn {
      margin-top: 8px;
      width: 155px;
      height: 32px;
      line-height: 32px;
      border-radius: 16px;
      font-size: 14px;
      display: flex;
      overflow: hidden;
      background: url("http://cdn.xiangshuheika.com/static/common/btn-bg.png")
        no-repeat;
      background-size: 100% 100%;
      .navigate {
        flex: 50%;
        height: 32px;
        color: #fff;
        border-radius: 0 16px 16px 0;
        line-height: 32px;
        text-align: center;
      }
      .pay {
        flex: 50%;
        line-height: 32px;
        text-align: center;
        font-weight: bold;
        color: #1a1a1a;
      }
    }
  }
}
.to-order-btn {
  position: fixed;
  width: 20px;
  padding: 5px 4px;
  right: 0;
  top: 60%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 13px;
  text-align: center;
  z-index: 19;
  line-height: 1.2;
  border-radius: 4px 0px 0px 4px;
}
.loading {
  display: flex;
  justify-content: center;
  padding: 20px;
}
</style>