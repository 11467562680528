<template>
  <main class="order-detail" v-if="sku">
    <div class="order-status">
      <svg-icon
        class="order-status-icon"
        :iconClass="'hotel_' + imageEnum[detail.status]"
      />
      <p class="order-status-name">{{ statusEnum[detail.status] }}</p>
    </div>
    <div class="buffet-header">
      <van-image fit="cover" lazy-load :src="sku.image_list_info[0] || ''" />
      <div class="buffet-info">
        <div class="buffet-hotel-name">
          {{ buffet.merchant_name }}
        </div>
        <div class="buffet-room-name">
          {{ sku.buffet_store_info.name
          }}<span class="buffet-room-tag">{{ sku.name }}</span>
        </div>
        <div class="buffet-room-tips">
          注：提前{{ sku.buffet_price_info.reserve_day }}天{{
            sku.buffet_price_info.reserve_time
          }}点之前预定，提前1天电话取消
        </div>
      </div>
    </div>
    <div class="buffet-book-info">
      <div class="buffet-book-info-title">预定信息</div>
      <van-cell-group>
        <van-cell title="预订姓名" :value="buffet.user_name" />
        <van-cell title="预订手机" :value="buffet.phone" />
        <van-cell title="预订日期" :value="buffet.date" />
        <van-cell title="预订时间" :value="buffet.time" />
        <van-cell title="出席人数" :value="buffet.attendance" />
        <van-cell title="备注" :value="buffet.comments" />
      </van-cell-group>
    </div>
    <div class="order-card order-info">
      <div class="order-number order-info-item">
        订单编号：{{ detail.order_number }}
        <span
          round
          size="mini"
          class="copy"
          v-clipboard:copy="detail.order_number"
          v-clipboard:success="copySuccess"
          v-clipboard:error="copyError"
          v-ans="{
            event_id: 'order_detail_click_copyorder',
            order_id: detail.order_number,
          }"
          >复制</span
        >
      </div>
      <div class="order-time order-info-item">下单时间：{{ orderTime }}</div>
    </div>
    <div class="order-card order-notice">
      <div class="order-notice-title">温馨提示</div>
      <div class="notice-content" v-html="sku.content"></div>
    </div>
    <div class="order-footer">
      <template>
        <van-button
          v-if="!isMiniProgram"
          class="order-footer-button"
          size="small"
          round
          @click="toService"
          v-ans="{
            event_id: 'buffet_order_service_button',
            namespace,
          }"
          >联系客服</van-button
        >
      </template>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import moment from "dayjs";
import { ORDER_STATUS } from "@/constants";
import ans from "@/utils/ans";
import dayjs from "dayjs";
import { CellGroup } from "vant";
import { getBuffetDetail, getBuffetStoreDetail } from "@/apis/buffet";

Vue.use(CellGroup);
export default {
  props: ["order", "system_date", "notice"],
  components: {},
  data() {
    return {
      buffet: {},
      sku: null,
      activeNames: [3],
      detail: this.order,
      statusEnum: ORDER_STATUS,
      loading: true,
      count: 0,
      payInfo: JSON.parse(this.order.pay_info),
      itemInfo: {},
      explain: "",
    };
  },

  async mounted() {
    const pay_info = JSON.parse(this.order.pay_info);
    this.buffet = JSON.parse(pay_info.biz_params_info);
    const res = await getBuffetDetail(this.buffet.sku_id);
    const store = await getBuffetStoreDetail(res.data.buffet_store_info.id);
    this.sku = res.data;
    this.sku.content = store.data.content;
    this.buffet.date = dayjs(this.buffet.meal_time).format("YYYY-MM-DD");
    this.buffet.time = dayjs(this.buffet.meal_time).format("HH:mm");
  },

  methods: {
    copySuccess() {
      this.$toast("复制成功");
    },
    copyError() {
      this.$toast("复制失败,请手动复制");
    },
    async toService() {
      this.$router.push("/service");
    },
    // async cancel(){
    //    this.$confirm({
    //         message:"确定要取消自助餐预定吗",
    //         showCancelButton: false,
    //         confirmButtonText: "好的",
    //       });
    // },
  },
  computed: {
    ...mapState(["namespace", "isMiniProgram"]),
    ...mapGetters(["namespaceGroup"]),
    getImageUrl() {
      let iconname = this.payInfo.detail_type || this.detail.type;
      if (this.detail.type === "atour") {
        iconname = this.detail.type;
      }
      return `https://cdn.xiangshuheika.com/static/${this.namespaceGroup}/${iconname}.png`;
    },
    imageEnum() {
      const imageEnum = {
        success: "success",
        failed: "cancel",
        confirming: "success",
        unpaid: "unpay",
        canceled: "cancel",
        refund: "cancel",
      };
      return imageEnum;
    },
    orderTime() {
      return moment(this.detail.t_created).format("YYYY-MM-DD HH:mm:ss");
    },
    showCancelButton() {
      const hour16 = moment(Number(this.payInfo.checkin_date))
        .subtract(1, "day")
        .set("hour", 16)
        .set("minute", 0)
        .set("second", 0);
      const before16 = moment(this.system_date).isBefore(hour16);
      return (
        before16 &&
        this.payInfo.pay_method === "0" &&
        /^(confirming|success)$/.test(this.detail.status)
      );
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>

<style lang="less" scoped>
.order-status {
  height: 60px;
  background: url("../../assets/images/hotel_state_bg.png") no-repeat;
  background-size: 100%;
  display: flex;
  padding-left: 20px;
  color: #fff;
  font-size: 16px;
  align-items: center;
  .order-status-icon {
    margin-right: 10px;
  }
}

.order-product-main {
  display: flex;
}

.buffet-header {
  display: flex;
  padding: 12px;
  background: #fff;
  margin-bottom: 12px;
  .buffet-info {
    display: flex;
    flex-direction: column;
  }
  .van-image {
    flex-basis: 88px;
    height: 88px;
    flex-shrink: 0;
    margin-right: 8px;
    border-radius: 8px;
    overflow: hidden;
  }
  .buffet-hotel-name {
    font-size: 18px;
    color: #333;
    font-weight: bold;
    margin-bottom: 7px;
    line-height: 25px;
  }
  .buffet-room-name {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    line-height: 20px;
  }
  .buffet-room-tips {
    margin-top: auto;
    color: #ff5001;
    line-height: 17px;
    font-size: 12px;
  }
}

.buffet-room-tag {
  margin-left: 15px;
  padding: 2px 6px;
  font-size: 11px;
  vertical-align: 2px;
  color: #f1ccb5;
  font-weight: normal;
  background: #222222;
  border-radius: 8px 0px 8px 0px;
}

.order-product-image {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.order-product-facevalue {
  margin-top: 8px;
  line-height: 18px;
}
.order-product-desc {
  line-height: 22px;
  font-size: 16px;
  font-weight: bold;
}

.order-info-item {
  line-height: 22px;
  color: @text-gray2-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-info {
  padding-top: 10px;
  padding-bottom: 10px;
}
.copy {
  display: block;
  padding: 0 12px;
  line-height: 16px;
  color: @text-gray2-color;
  border: 1px solid currentColor;
  border-radius: 16px;
  box-sizing: border-box;
  font-size: @font-xs;
}

.buffet-book-info {
  margin-top: 12px;
  background: #fff;
  /deep/ .van-cell__title {
    font-size: 12px;
    width: 100px;
    flex: inherit;
  }
  /deep/ .van-cell__value {
    text-align: left;
    color: #333;
  }
}

.buffet-book-info-title {
  font-size: 16px;
  color: #333;
  padding: 12px;
}

.order-card {
  background: #fff;
  padding: 15px;
  margin-top: 12px;
  margin-bottom: 10px;
}

.order-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  z-index: 1000;
  background: #fff;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
}
.order-footer-button {
  margin-left: 10px;
}
.order-detail {
  padding-bottom: 80px;
}

.order-notice-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-content {
  color: #999;
  line-height: 1.5;
}
</style>
