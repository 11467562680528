var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"select-city"},[_c('main',{staticClass:"search-fixed"},[_c('div',{staticClass:"search-city"},[_c('van-field',{staticClass:"input",attrs:{"left-icon":"search","placeholder":"搜索城市"},on:{"input":_vm.searchCityHandle},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1)]),(_vm.search)?_c('div',{staticClass:"search-result"},[_c('div',{staticClass:"search-result-city"},[(_vm.searchCity.length)?[_c('div',{staticClass:"search-result-title"},[_vm._v("搜索结果")]),_vm._l((_vm.searchCity),function(item){return _c('van-cell',{directives:[{name:"ans",rawName:"v-ans",value:({
            event_id: 'buffet_city_select_click',
            namespace: _vm.namespace,
            city_name: item.name,
          }),expression:"{\n            event_id: 'buffet_city_select_click',\n            namespace,\n            city_name: item.name,\n          }"}],key:item.name,on:{"click":function($event){return _vm.setCity(item.name)}}},[_vm._v(_vm._s(item.name))])})]:_c('div',{staticClass:"search-result-empty"},[_c('van-image',{attrs:{"description":"","src":require('../../assets/images/hotel/no-city.png')}}),_c('p',[_vm._v("暂无相关城市")]),_c('p',[_vm._v("可尝试其他关键词进行搜索")])],1)],2)]):_c('van-index-bar',{attrs:{"index-list":['热门' ].concat( _vm.indexList)}},[_c('van-index-anchor',{attrs:{"index":"热门"}},[_vm._v("热门城市")]),_c('van-row',{staticClass:"hot-citys",attrs:{"gutter":10}},_vm._l((_vm.hot_city_of_buffets),function(city){return _c('van-col',{key:city,attrs:{"span":8}},[_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
            event_id: 'buffet_city_select_click',
            namespace: _vm.namespace,
            city_name: city,
          }),expression:"{\n            event_id: 'buffet_city_select_click',\n            namespace,\n            city_name: city,\n          }"}],staticClass:"hot-city-item",on:{"click":function($event){return _vm.setCity(city)}}},[_vm._v(" "+_vm._s(city)+" ")])])}),1),_vm._l((_vm.cityList),function(item){return [_c('van-index-anchor',{key:item.groupName,attrs:{"index":item.groupName}},[_vm._v(_vm._s(item.groupName))]),_vm._l((item.list),function(city){return _c('van-cell',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'buffet_city_select_click',
          namespace: _vm.namespace,
          city_name: city,
        }),expression:"{\n          event_id: 'buffet_city_select_click',\n          namespace,\n          city_name: city,\n        }"}],key:city.en_name,attrs:{"title":city.name},on:{"click":function($event){return _vm.setCity(city.name)}}})})]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }